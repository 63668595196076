interface getNoClientDescriptionInput {
  actionDescription: string;
}

/**
 * This custom hook provides a helper method that checks for the sidebarV2 flag and
 * generates no client description for standard pages
 */
export const useNoClientDescription = () => {
  const getNoClientDescription = ({
    actionDescription,
  }: getNoClientDescriptionInput) =>
    `You can ${actionDescription} from your Copilot Dashboard, but first you need to create a client on the Clients page.`;

  return getNoClientDescription;
};
