import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AmericanFootballIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_23705_73578)">
      <path
        d="M14.7903 14.7907C18.872 10.709 20.0361 5.25526 17.3903 2.60946C14.7445 -0.0363459 9.29075 1.12771 5.20901 5.20945C1.12726 9.29119 -0.0367943 14.7449 2.60901 17.3907C5.25481 20.0365 10.7086 18.8725 14.7903 14.7907Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.048 6.95166L6.95117 13.0485"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8711 10.8711L9.12891 9.12891"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6129 9.129L10.8711 7.38721"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8391 8.258L11.7422 2.16113"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25801 17.8391L2.16113 11.7422"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.12852 12.6129L7.38672 10.8711"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23705_73578">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
