import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Mp3Icon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12.0497V15.6861H5.85582V13.44H5.88601L6.75959 15.663H7.30646L8.18004 13.4524H8.21023V15.6861H9.06605V12.0497H7.97763L7.05433 14.3011H7.01172L6.08842 12.0497H5Z"
      fill="black"
    />
    <path
      d="M9.61426 15.6861H10.4932V14.5568H11.0898C11.9225 14.5568 12.4427 14.0632 12.4427 13.3086C12.4427 12.5611 11.9331 12.0497 11.1164 12.0497H9.61426V15.6861ZM10.4932 13.8643V12.7582H10.9264C11.3206 12.7582 11.5319 12.9712 11.5319 13.3086C11.5319 13.6442 11.3206 13.8643 10.9264 13.8643H10.4932Z"
      fill="black"
    />
    <path
      d="M14.252 15.7358C15.1043 15.7358 15.7098 15.2848 15.708 14.6527C15.7098 14.2053 15.4168 13.8857 14.8557 13.82V13.7915C15.2765 13.7312 15.5748 13.4489 15.5731 13.0405C15.5748 12.4474 15.0386 12 14.2591 12C13.4743 12 12.9026 12.4545 12.8955 13.1009H13.7354C13.7425 12.8523 13.9662 12.6854 14.2591 12.6854C14.5379 12.6854 14.7279 12.854 14.7261 13.0973C14.7279 13.3494 14.5042 13.5234 14.181 13.5234H13.8277V14.1484H14.181C14.5486 14.1484 14.7883 14.3295 14.7847 14.5852C14.7883 14.8427 14.5681 15.022 14.2556 15.022C13.9449 15.022 13.7158 14.8569 13.7069 14.6172H12.8227C12.8298 15.2741 13.4211 15.7358 14.252 15.7358Z"
      fill="black"
    />
  </SvgIcon>
);
