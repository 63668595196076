import React from 'react';
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { SignatureSidebarInputs } from 'src/legacy/components/Signature/SignatureSidebar/SignatureSidebarInputs';
import { InformationCircleIcon } from 'src/legacy/components/Icons';
import { LightGray } from 'src/theme/colors';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { SidebarShadow } from 'src/theme/shadows';
import { InternalUserSignatureSteps } from 'src/store/signaturePage/reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signaturePageSidebar: {
      padding: theme.spacing(1.5),
      boxShadow: SidebarShadow,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    signaturePageSidebarActions: {
      margin: `${theme.spacing(2)}px 0`,
    },
    inputTitle: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    clientInputTitle: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    infoIcon: {
      color: LightGray,
      fontSize: 12,
    },
    infoIconButton: {
      padding: theme.spacing(0),
    },
  }),
);

export const InternalSignatureStepTitle = {
  [InternalUserSignatureSteps.MySignature]: {
    title: 'Your inputs',
    description:
      'Drag the below blocks to the PDF to add your signature, initials, today’s date, and custom text.',
  },
  [InternalUserSignatureSteps.ClientSignature]: {
    title: 'Client inputs',
    description:
      'Drag the below blocks to the PDF to indicate what information your client needs to provide.',
  },
};

export interface BaseSignaturePageSidebarProps {
  selectedClientId?: string;
  signaturePlaceholderComponentElement: HTMLButtonElement | null;
}

export const BaseSignaturePageSidebar: React.FC<
  BaseSignaturePageSidebarProps
> = ({ signaturePlaceholderComponentElement, selectedClientId }) => {
  const classes = useStyles();

  return (
    <div className={classes.signaturePageSidebar}>
      <div>
        <div className={classes.signaturePageSidebarActions}>
          <div>
            <div className={classes.inputTitle}>
              <BaseTypography fontType="13Medium">
                {
                  InternalSignatureStepTitle[
                    InternalUserSignatureSteps.MySignature
                  ].title
                }
              </BaseTypography>
              <Tooltip
                title={
                  InternalSignatureStepTitle[
                    InternalUserSignatureSteps.MySignature
                  ].description
                }
                placement="bottom"
              >
                <IconButton className={classes.infoIconButton}>
                  <InformationCircleIcon className={classes.infoIcon} />
                </IconButton>
              </Tooltip>
            </div>
            <SignatureSidebarInputs
              isClientSignatureButton={false}
              signatureClientId={selectedClientId}
              signaturePlaceholderComponentElement={
                signaturePlaceholderComponentElement
              }
            />
            <div className={classes.clientInputTitle}>
              <BaseTypography fontType="13Medium">
                {
                  InternalSignatureStepTitle[
                    InternalUserSignatureSteps.ClientSignature
                  ].title
                }
              </BaseTypography>
              <Tooltip
                title={
                  InternalSignatureStepTitle[
                    InternalUserSignatureSteps.ClientSignature
                  ].description
                }
                placement="bottom"
              >
                <IconButton className={classes.infoIconButton}>
                  <InformationCircleIcon className={classes.infoIcon} />
                </IconButton>
              </Tooltip>
            </div>
            <SignatureSidebarInputs
              isClientSignatureButton
              signatureClientId={selectedClientId}
              signaturePlaceholderComponentElement={
                signaturePlaceholderComponentElement
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
