import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AttachIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.44043 7.50007V13.1192C8.44692 13.5301 8.6147 13.922 8.90757 14.2103C9.20044 14.4985 9.59491 14.6601 10.0059 14.6601C10.4168 14.6601 10.8113 14.4985 11.1041 14.2103C11.397 13.922 11.5648 13.5301 11.5713 13.1192L11.5764 5.75397C11.5806 5.40277 11.5151 5.05422 11.3837 4.72852C11.2522 4.40282 11.0574 4.10646 10.8106 3.8566C10.5638 3.60674 10.2698 3.40837 9.94569 3.27298C9.62161 3.13759 9.27388 3.06787 8.92266 3.06787C8.57143 3.06787 8.2237 3.13759 7.89962 3.27298C7.57554 3.40837 7.28155 3.60674 7.03471 3.8566C6.78787 4.10646 6.59308 4.40282 6.46163 4.72852C6.33018 5.05422 6.26468 5.40277 6.26895 5.75397V13.1688C6.26179 13.6633 6.35299 14.1542 6.53726 14.613C6.72152 15.0719 6.99516 15.4896 7.34228 15.8418C7.68939 16.1939 8.10306 16.4736 8.55922 16.6645C9.01537 16.8554 9.50493 16.9537 9.99941 16.9537C10.4939 16.9537 10.9835 16.8554 11.4396 16.6645C11.8958 16.4736 12.3094 16.1939 12.6565 15.8418C13.0037 15.4896 13.2773 15.0719 13.4616 14.613C13.6458 14.1542 13.737 13.6633 13.7299 13.1688V6.23991"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
