import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CalculatorIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 1.875H5.625C4.93464 1.875 4.375 2.43464 4.375 3.125V16.875C4.375 17.5654 4.93464 18.125 5.625 18.125H14.375C15.0654 18.125 15.625 17.5654 15.625 16.875V3.125C15.625 2.43464 15.0654 1.875 14.375 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7496 4.375H6.25V6.875H13.7496V4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5625 10.625C7.08027 10.625 7.5 10.2053 7.5 9.6875C7.5 9.16973 7.08027 8.75 6.5625 8.75C6.04473 8.75 5.625 9.16973 5.625 9.6875C5.625 10.2053 6.04473 10.625 6.5625 10.625Z"
      fill="currentColor"
    />
    <path
      d="M10 10.625C10.5178 10.625 10.9375 10.2053 10.9375 9.6875C10.9375 9.16973 10.5178 8.75 10 8.75C9.48223 8.75 9.0625 9.16973 9.0625 9.6875C9.0625 10.2053 9.48223 10.625 10 10.625Z"
      fill="currentColor"
    />
    <path
      d="M13.4375 10.625C13.9553 10.625 14.375 10.2053 14.375 9.6875C14.375 9.16973 13.9553 8.75 13.4375 8.75C12.9197 8.75 12.5 9.16973 12.5 9.6875C12.5 10.2053 12.9197 10.625 13.4375 10.625Z"
      fill="currentColor"
    />
    <path
      d="M6.5625 13.75C7.08027 13.75 7.5 13.3303 7.5 12.8125C7.5 12.2947 7.08027 11.875 6.5625 11.875C6.04473 11.875 5.625 12.2947 5.625 12.8125C5.625 13.3303 6.04473 13.75 6.5625 13.75Z"
      fill="currentColor"
    />
    <path
      d="M10 13.75C10.5178 13.75 10.9375 13.3303 10.9375 12.8125C10.9375 12.2947 10.5178 11.875 10 11.875C9.48223 11.875 9.0625 12.2947 9.0625 12.8125C9.0625 13.3303 9.48223 13.75 10 13.75Z"
      fill="currentColor"
    />
    <path
      d="M6.5625 16.875C7.08027 16.875 7.5 16.4553 7.5 15.9375C7.5 15.4197 7.08027 15 6.5625 15C6.04473 15 5.625 15.4197 5.625 15.9375C5.625 16.4553 6.04473 16.875 6.5625 16.875Z"
      fill="currentColor"
    />
    <path
      d="M10 16.875C10.5178 16.875 10.9375 16.4553 10.9375 15.9375C10.9375 15.4197 10.5178 15 10 15C9.48223 15 9.0625 15.4197 9.0625 15.9375C9.0625 16.4553 9.48223 16.875 10 16.875Z"
      fill="currentColor"
    />
    <path
      d="M14.375 12.8125C14.375 12.2947 13.9553 11.875 13.4375 11.875C12.9197 11.875 12.5 12.2947 12.5 12.8125V15.9375C12.5 16.4553 12.9197 16.875 13.4375 16.875C13.9553 16.875 14.375 16.4553 14.375 15.9375V12.8125Z"
      fill="currentColor"
    />
  </SvgIcon>
);
