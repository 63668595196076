import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import * as Colors from 'src/theme/colors';
import { NoCompanyIcon } from 'src/legacy/components/Icons';
import { CompanyCellData, ClientTableRowModel } from 'src/store/clients/types';
import { Avatar } from 'copilot-design-system';

interface CompanyCellRendererProps extends ICellRendererParams {
  data: ClientTableRowModel;
  value: CompanyCellData;
}

export const CompanyCellRenderer: React.FC<CompanyCellRendererProps> =
  React.memo(({ value: companyData }) => {
    return (
      <div>
        <div className="flex items-center justify-start">
          {!companyData.isPlaceholder ? (
            <div className="flex items-center gap-3">
              <Avatar
                text={companyData.name.toUpperCase().charAt(0)}
                fallbackColor={companyData.fallbackColor}
                size="sm"
                variant="rounded"
                src={companyData.avatarUrl}
              />
              <span className="text-primary">{companyData.name}</span>
            </div>
          ) : (
            <NoCompanyIcon
              style={{
                color: Colors.LightGray,
                width: 20,
                height: 20,
              }}
            />
          )}
        </div>
      </div>
    );
  });
