import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ImagesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 4.375V3.75C16.8736 3.25316 16.6756 2.77709 16.3242 2.42577C15.9729 2.07445 15.4968 1.87644 15 1.875H2.5C2.00316 1.87644 1.52709 2.07445 1.17577 2.42577C0.82445 2.77709 0.626443 3.25316 0.625 3.75V13.75C0.626443 14.2468 0.82445 14.7229 1.17577 15.0742C1.52709 15.4256 2.00316 15.6236 2.5 15.625H3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M17.5785 5H5.54648C4.55431 5 3.75 5.80431 3.75 6.79648V16.3285C3.75 17.3207 4.55431 18.125 5.54648 18.125H17.5785C18.5707 18.125 19.375 17.3207 19.375 16.3285V6.79648C19.375 5.80431 18.5707 5 17.5785 5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M14.5672 9.77295C15.231 9.77295 15.7691 9.23866 15.7691 8.57959C15.7691 7.92052 15.231 7.38623 14.5672 7.38623C13.9034 7.38623 13.3652 7.92052 13.3652 8.57959C13.3652 9.23866 13.9034 9.77295 14.5672 9.77295Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M13.3652 14.5378L9.96094 11.1632C9.74331 10.9482 9.45222 10.8237 9.14642 10.815C8.84062 10.8062 8.54287 10.9137 8.31328 11.1159L3.75 15.1421"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3604 18.1248L14.9928 13.526C15.2059 13.3158 15.4894 13.192 15.7885 13.1785C16.0876 13.165 16.3811 13.2628 16.6123 13.453L19.3748 15.7385"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
