import { IconButton, styled, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { NotificationEventType } from 'src/entities/Notifications';
import { GraySmall, NonHoverBackground, red } from 'src/theme/colors';
import Button from '../Button';
import { Action, BaseMenu } from '../Dropdowns';
import { ExportIcon, MoreActionsIcon, TrashIcon } from '../Icons';
import { InboxSidebarItemData } from './InboxSidebar';

interface Props {
  onDeleteNotificationClick: () => void;
  onExportFormClick: () => void;
  onDownloadAssetClick: () => void;
  selectedNotification: InboxSidebarItemData;
  s3AssetUrl?: string;
}

const StyledIconButton = styled(IconButton)({
  border: `1px solid ${NonHoverBackground}`,
  width: 28,
  height: 28,
});
const notificationsWithDownloadActions: NotificationEventType[] = [
  'esign.completed',
  'invoice.paid',
  'contract.signed',
];

export const NotificationDetailsHeaderActions: React.FC<Props> = ({
  onDeleteNotificationClick,
  onExportFormClick,
  onDownloadAssetClick,
  selectedNotification,
  s3AssetUrl,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileActionsMenuAnchor, setMobileActionsMenuAnchor] =
    React.useState<HTMLElement | null>(null);

  /**
   * Get the actions for the notification details header
   * When it is mobile show the actions in a menu
   * When it is desktop show the actions in a button group.
   */
  const getActions = React.useCallback(() => {
    const mobileActions: Action[] = [
      {
        name: 'Delete notification',
        onClick: onDeleteNotificationClick,
        icon: <TrashIcon />,
        isDelete: true,
      },
    ];

    const desktopActions = [
      <Button
        htmlId="delete-notification-action-btn"
        variant="contained"
        color="secondary"
        onClick={onDeleteNotificationClick}
        style={{
          color: red,
          marginLeft: 14,
        }}
        startIcon={
          <TrashIcon
            style={{
              fontSize: 8,
            }}
          />
        }
      >
        Delete notification
      </Button>,
    ];

    // for other preview-able notifications show the download action
    // 1. when it is form notification show export form actions
    // TODO(backfill-9984): remove the forms.completed case. This was an old name that is not used anymore
    if (
      selectedNotification?.notificationEventType === 'forms.completed' ||
      selectedNotification.notificationEventType === 'formResponse.completed'
    ) {
      mobileActions.unshift({
        name: 'Export form',
        onClick: onExportFormClick,
        icon: <ExportIcon />,
      });

      desktopActions.unshift(
        <Button
          htmlId="download-notification-action-btn"
          variant="contained"
          color="secondary"
          onClick={onExportFormClick}
          startIcon={
            <ExportIcon
              style={{
                fontSize: 8,
              }}
            />
          }
        >
          Export form
        </Button>,
      );
    } else if (
      s3AssetUrl &&
      notificationsWithDownloadActions.includes(
        selectedNotification.notificationEventType,
      )
    ) {
      // todo @akash remove once events are backfilled
      const isContractSignedEvent =
        selectedNotification?.notificationEventType === 'esign.completed' ||
        selectedNotification.notificationEventType === 'contract.signed';
      const notifType = isContractSignedEvent ? 'contract' : 'invoice';
      mobileActions.unshift({
        name: `Download ${notifType}`,
        onClick: onDownloadAssetClick,
        icon: <ExportIcon />,
      });

      desktopActions.unshift(
        <Button
          htmlId="download-notification-action-btn"
          variant="contained"
          color="secondary"
          onClick={onDownloadAssetClick}
          startIcon={
            <ExportIcon
              style={{
                fontSize: 8,
              }}
            />
          }
        >
          Download {notifType}
        </Button>,
      );
    }

    return isMobile ? mobileActions : desktopActions;
  }, [
    selectedNotification,
    s3AssetUrl,
    isMobile,
    onExportFormClick,
    onDownloadAssetClick,
    onDeleteNotificationClick,
  ]);

  return isMobile ? (
    <>
      <StyledIconButton
        aria-label="more actions"
        aria-controls="more-actions-menu"
        aria-haspopup="true"
        data-testid="more-actions-menu"
        onClick={(event) => {
          setMobileActionsMenuAnchor(event.currentTarget);
        }}
        color="secondary"
        size="small"
      >
        <MoreActionsIcon htmlColor={GraySmall} style={{ fontSize: 20 }} />
      </StyledIconButton>
      <BaseMenu
        key="view-notifications-actions-menu"
        menuProps={{
          open: Boolean(mobileActionsMenuAnchor),
          anchorEl: mobileActionsMenuAnchor,
          onClose: () => {
            setMobileActionsMenuAnchor(null);
          },
          getContentAnchorEl: null,
          keepMounted: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        actions={getActions() as Action[]}
      />
    </>
  ) : (
    <>
      {getActions().map((action, index) => {
        return (
          <div key={`notification-details-header-action-btn-${index}`}>
            {action}
          </div>
        );
      })}
    </>
  );
};
