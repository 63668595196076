import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SurveyIcon = (
  <SvgIcon
    enableBackground="new 0 0 512 512"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <circle cx="53.333" cy="74.667" r="10.667" />
          <circle cx="96" cy="74.667" r="10.667" />
          <circle cx="138.667" cy="74.667" r="10.667" />
          <path
            d="M117.333,234.667H352c5.896,0,10.667-4.771,10.667-10.667s-4.771-10.667-10.667-10.667H117.333
				c-5.896,0-10.667,4.771-10.667,10.667S111.438,234.667,117.333,234.667z"
          />
          <path
            d="M245.333,341.333h-128c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h128
				c5.896,0,10.667-4.771,10.667-10.667C256,346.104,251.229,341.333,245.333,341.333z"
          />
          <path
            d="M471.167,149.333c-0.618,0-1.217,0.155-1.833,0.184V64c0-23.531-19.146-42.667-42.667-42.667h-384
				C19.146,21.333,0,40.469,0,64v384c0,23.531,19.146,42.667,42.667,42.667h384c23.521,0,42.667-19.135,42.667-42.667V249.745
				l30.708-30.703c0,0,0,0,0-0.01c7.604-7.604,11.958-18.125,11.958-28.865C512,167.646,493.688,149.333,471.167,149.333z
				 M21.333,64c0-11.76,9.563-21.333,21.333-21.333h384C438.438,42.667,448,52.24,448,64v42.667H21.333V64z M448,448
				c0,11.76-9.563,21.333-21.333,21.333h-384c-11.771,0-21.333-9.573-21.333-21.333V128H448v28.62
				c-2.025,1.392-3.962,2.923-5.708,4.672l-116.06,116.042H117.333c-5.896,0-10.667,4.771-10.667,10.667s4.771,10.667,10.667,10.667
				h191.785l-10.243,51.24c-0.708,3.5,0.396,7.115,2.917,9.635c2.021,2.021,4.75,3.125,7.542,3.125c0.688,0,1.396-0.073,2.083-0.208
				l53.313-10.667c2.083-0.417,3.979-1.427,5.458-2.917L448,271.076V448z M357.396,331.51l-34.458,6.896l6.896-34.5l96.828-96.828
				l27.587,27.587L357.396,331.51z M484.958,203.958l-15.625,15.625l-27.589-27.589l15.63-15.63
				c3.625-3.615,8.646-5.698,13.792-5.698c10.75,0,19.5,8.75,19.5,19.5C490.667,195.292,488.583,200.323,484.958,203.958z"
          />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </SvgIcon>
);
