import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BandageIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_23705_73524)">
      <path
        d="M12.8783 2.25378L2.25431 12.8778C0.910049 14.222 0.910049 16.4015 2.25431 17.7458L2.25458 17.746C3.59884 19.0903 5.77831 19.0903 7.12257 17.746L17.7466 7.12204C19.0908 5.77778 19.0908 3.59831 17.7466 2.25405L17.7463 2.25378C16.402 0.909518 14.2226 0.909518 12.8783 2.25378Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3085 8.78485L11.2149 5.69126C10.7268 5.2031 9.93533 5.2031 9.44717 5.69126L5.80115 9.33727C5.313 9.82543 5.313 10.6169 5.80115 11.105L8.89474 14.1986C9.3829 14.6868 10.1744 14.6868 10.6625 14.1986L14.3085 10.5526C14.7967 10.0645 14.7967 9.273 14.3085 8.78485Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8.75C10.3452 8.75 10.625 8.47018 10.625 8.125C10.625 7.77982 10.3452 7.5 10 7.5C9.65482 7.5 9.375 7.77982 9.375 8.125C9.375 8.47018 9.65482 8.75 10 8.75Z"
        fill="currentColor"
      />
      <path
        d="M11.875 10.625C12.2202 10.625 12.5 10.3452 12.5 10C12.5 9.65482 12.2202 9.375 11.875 9.375C11.5298 9.375 11.25 9.65482 11.25 10C11.25 10.3452 11.5298 10.625 11.875 10.625Z"
        fill="currentColor"
      />
      <path
        d="M8.125 10.625C8.47018 10.625 8.75 10.3452 8.75 10C8.75 9.65482 8.47018 9.375 8.125 9.375C7.77982 9.375 7.5 9.65482 7.5 10C7.5 10.3452 7.77982 10.625 8.125 10.625Z"
        fill="currentColor"
      />
      <path
        d="M10 12.5C10.3452 12.5 10.625 12.2202 10.625 11.875C10.625 11.5298 10.3452 11.25 10 11.25C9.65482 11.25 9.375 11.5298 9.375 11.875C9.375 12.2202 9.65482 12.5 10 12.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_23705_73524">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
