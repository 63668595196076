import { SvgIconProps } from '@material-ui/core';
import React from 'react';
import {
  AviIcon,
  DocIcon,
  GenericIcon,
  GenericSetIcon,
  IsoIcon,
  JpgIcon,
  MovIcon,
  Mp3Icon,
  PdfIcon,
  PngIcon,
  TxtIcon,
  ZipIcon,
  SVGIcon,
  LinkIcon,
  Mp4Icon,
  GifIcon,
  XlxIcon,
  CsvIcon,
} from 'src/legacy/components/Icons';

const fileExtensionToIcon: Record<
  string,
  (props: SvgIconProps) => JSX.Element
> = {
  avi: AviIcon,
  doc: DocIcon,
  docx: DocIcon,
  generic: GenericIcon,
  genericSet: GenericSetIcon,
  iso: IsoIcon,
  jpg: JpgIcon,
  jpeg: JpgIcon,
  mov: MovIcon,
  mp3: Mp3Icon,
  mpeg: Mp3Icon,
  pdf: PdfIcon,
  png: PngIcon,
  txt: TxtIcon,
  zip: ZipIcon,
  svg: SVGIcon,
  link: LinkIcon,
  mp4: Mp4Icon,
  gif: GifIcon,
  csv: CsvIcon,
  xlx: XlxIcon,
  xlsx: XlxIcon,
  xls: XlxIcon,
};

const isExtString = (
  extension: string,
): extension is KeyOf<typeof fileExtensionToIcon> => {
  return !!fileExtensionToIcon[extension];
};

export const useFileIcon = () => {
  const getIcon = (extension: string) => {
    const ext = extension.toLocaleLowerCase();
    if (!isExtString(ext)) {
      return <GenericIcon style={{ fontSize: 27, fill: 'none' }} />;
    }
    const Icon = fileExtensionToIcon[ext] || fileExtensionToIcon.generic;
    return <Icon style={{ fontSize: 27, fill: 'none' }} />;
  };

  return { getIcon };
};
