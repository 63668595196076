import React from 'react';
import { AlertCircleIcon } from 'src/legacy/components/Icons';

import StandardPage from 'src/legacy/components/UI/StandardPage';

const params = {
  title: 'Page cannot be loaded',
  topIcon: <AlertCircleIcon />,
  desc: `Sorry, we're not able to load this page. Please check your connection and disable any browser extensions such as ad blockers. Try refreshing the page in a few seconds.`,
  defaultMode: true,
};

export const NetworkErrorPage: React.FC = () => <StandardPage {...params} />;
