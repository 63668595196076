import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrainIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.4375 1.875H12.5C12.5 1.70924 12.4342 1.55027 12.3169 1.43306C12.1997 1.31585 12.0408 1.25 11.875 1.25H8.125C7.95924 1.25 7.80027 1.31585 7.68306 1.43306C7.56585 1.55027 7.5 1.70924 7.5 1.875H6.5625C5.98285 1.87665 5.4274 2.10765 5.01753 2.51753C4.60765 2.9274 4.37665 3.48285 4.375 4.0625V13.7109C4.375 15.0898 10 16.25 10 16.25C10 16.25 15.625 15.0898 15.625 13.7109V4.0625C15.625 3.48234 15.3945 2.92594 14.9843 2.5157C14.5741 2.10547 14.0177 1.875 13.4375 1.875V1.875ZM10 13.75C9.62916 13.75 9.26665 13.64 8.95831 13.434C8.64996 13.228 8.40964 12.9351 8.26773 12.5925C8.12581 12.2499 8.08868 11.8729 8.16103 11.5092C8.23337 11.1455 8.41195 10.8114 8.67417 10.5492C8.9364 10.287 9.27049 10.1084 9.63421 10.036C9.99792 9.96368 10.3749 10.0008 10.7175 10.1427C11.0601 10.2846 11.353 10.525 11.559 10.8333C11.765 11.1416 11.875 11.5042 11.875 11.875C11.875 12.3723 11.6775 12.8492 11.3258 13.2008C10.9742 13.5525 10.4973 13.75 10 13.75ZM13.75 7.5C13.75 7.66576 13.6842 7.82473 13.5669 7.94194C13.4497 8.05915 13.2908 8.125 13.125 8.125H6.875C6.70924 8.125 6.55027 8.05915 6.43306 7.94194C6.31585 7.82473 6.25 7.66576 6.25 7.5V5C6.25 4.83424 6.31585 4.67527 6.43306 4.55806C6.55027 4.44085 6.70924 4.375 6.875 4.375H13.125C13.2908 4.375 13.4497 4.44085 13.5669 4.55806C13.6842 4.67527 13.75 4.83424 13.75 5V7.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 18.125H14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 16.875L15 18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 16.875L5 18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
