import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CafeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 3.125H16.875C17.0408 3.125 17.1997 3.19085 17.3169 3.30806C17.4342 3.42527 17.5 3.58424 17.5 3.75V5.07812C17.5 5.55469 17.3107 6.01173 16.9737 6.34871C16.6367 6.68569 16.1797 6.875 15.7031 6.875H14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 3.125H14.375V10.625C14.375 11.0354 14.2942 11.4417 14.1371 11.8209C13.9801 12.2 13.7499 12.5445 13.4597 12.8347C13.1695 13.1249 12.825 13.3551 12.4459 13.5121C12.0667 13.6692 11.6604 13.75 11.25 13.75H6.875C6.0462 13.75 5.25134 13.4208 4.66529 12.8347C4.07924 12.2487 3.75 11.4538 3.75 10.625V3.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 16.25H15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
