import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HeartCircleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 10C17.5 5.85938 14.1406 2.5 10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M10.0002 14.0625C9.8748 14.0624 9.75231 14.0246 9.64867 13.954C8.11351 12.9118 7.44867 12.1961 7.08226 11.7504C6.30101 10.7985 5.9268 9.82075 5.93773 8.76216C5.94984 7.54888 6.92289 6.56177 8.10687 6.56177C8.90375 6.56177 9.47406 6.977 9.82953 7.35903C9.85144 7.3822 9.87784 7.40066 9.90712 7.41327C9.93641 7.42589 9.96796 7.43239 9.99984 7.43239C10.0317 7.43239 10.0633 7.42589 10.0926 7.41327C10.1218 7.40066 10.1483 7.3822 10.1702 7.35903C10.526 6.977 11.0959 6.56177 11.8928 6.56177C13.0768 6.56177 14.0498 7.54888 14.062 8.76216C14.0729 9.82075 13.6983 10.7985 12.9174 11.7504C12.551 12.1973 11.8862 12.9118 10.351 13.954C10.2476 14.0245 10.1254 14.0623 10.0002 14.0625Z"
      fill="currentColor"
    />
  </SvgIcon>
);
