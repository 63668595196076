import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const StrikeThroughIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_21016:86876)">
      <path
        d="M17 9H10.0001C7.79401 9 6.00012 7.43005 6.00012 5.49994C6.00012 3.57001 7.79401 2.00006 10.0001 2.00006C12.2061 2.00006 14 3.57001 14 5.49994C14 6.05292 14.4481 6.50006 15.0001 6.50006C15.552 6.50006 16.0001 6.05292 16.0001 5.49994C16.0001 2.46808 13.3091 0 10.0001 0C6.69098 0 4.00006 2.46808 4.00006 5.49994C4.00006 6.828 4.51715 8.04803 5.375 9H3.00012C2.44806 9 2 9.44696 2 9.99994C2 10.5529 2.44806 11.0001 3.00012 11.0001H10.0001C12.2061 11.0001 14 12.57 14 14.4999C14 16.4301 12.2061 18 10.0001 18C7.79401 18 6.00012 16.4301 6.00012 14.4999C6.00012 13.947 5.55206 13.5 5 13.5C4.44812 13.5 4.00006 13.947 4.00006 14.4999C4.00006 17.532 6.69098 20.0001 10.0001 20.0001C13.3091 20.0001 16.0001 17.532 16.0001 14.4999C16.0001 13.1721 15.483 11.952 14.6251 11.0001H17C17.5521 11.0001 18.0001 10.5529 18.0001 9.99994C18.0001 9.44696 17.5521 9 17 9Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_21016:86876">
        <rect width="20" height="20" fill="none" />
      </clipPath>
    </defs>
  </SvgIcon>
);
