export const INDUSTRY_LIST = [
  {
    id: 0,
    label: 'Accounting and bookkeeping',
    value: 'accounting_and_bookkeeping',
  },
  {
    id: 1,
    label: 'Construction',
    value: 'construction',
  },
  {
    id: 2,
    label: 'Consulting',
    value: 'consulting',
  },
  {
    id: 3,
    label: 'Design',
    value: 'design',
  },
  {
    id: 4,
    label: 'Ecommerce',
    value: 'ecommerce',
  },
  {
    id: 5,
    label: 'Education',
    value: 'education',
  },
  {
    id: 6,
    label: 'Engineering',
    value: 'engineering',
  },
  {
    id: 7,
    label: 'Entertainment',
    value: 'entertainment',
  },
  {
    id: 8,
    label: 'Finance',
    value: 'finance',
  },
  {
    id: 9,
    label: 'Healthcare',
    value: 'healthcare',
  },
  {
    id: 10,
    label: 'Insurance',
    value: 'insurance',
  },
  {
    id: 11,
    label: 'Legal',
    value: 'legal',
  },
  {
    id: 12,
    label: 'Logistics and transportation',
    value: 'logistics_and_transportation',
  },
  {
    id: 13,
    label: 'Manufacturing',
    value: 'manufacturing',
  },
  {
    id: 14,
    label: 'Marketing',
    value: 'marketing',
  },
  {
    id: 15,
    label: 'Nonprofit',
    value: 'nonprofit',
  },
  {
    id: 16,
    label: 'Real estate',
    value: 'real_estate',
  },
  {
    id: 17,
    label: 'Recruiting and staffing',
    value: 'recruiting_and_staffing',
  },
  {
    id: 18,
    label: 'Technology',
    value: 'technology',
  },
  {
    id: 19,
    label: 'Travel and tourism',
    value: 'travel_and_tourism',
  },
  {
    id: 20,
    label: 'Other',
    value: 'other',
  },
];

export const COMPANY_SIZE_LIST = [
  {
    id: 0,
    label: 'Just me',
    value: '1',
  },
  {
    id: 1,
    label: '2 - 5',
    value: '5',
  },
  {
    id: 2,
    label: '6 - 10',
    value: '10',
  },
  {
    id: 3,
    label: '11 - 50',
    value: '50',
  },
  {
    id: 4,
    label: '51 - 100',
    value: '100',
  },
  {
    id: 5,
    label: '100+',
    value: '100+',
  },
];

export const FOUND_PORTAL_SOURCES = [
  {
    id: 0,
    label: 'Review site',
    value: 'review_site',
  },
  {
    id: 1,
    label: 'Referral or friend ',
    value: 'referral',
  },
  {
    id: 2,
    label: 'Google',
    value: 'google',
  },
  {
    id: 3,
    label: 'Facebook or Instagram',
    value: 'fb_instragram',
  },
  {
    id: 4,
    label: 'LinkedIn',
    value: 'linkedin',
  },
  {
    id: 5,
    label: 'Twitter',
    value: 'twitter',
  },
  {
    id: 6,
    label: 'Press',
    value: 'press',
  },
  {
    id: 7,
    label: 'Product Hunt',
    value: 'product_hunt',
  },
  {
    id: 8,
    label: 'Reddit',
    value: 'reddit',
  },
  {
    id: 9,
    label: 'Used as client of another business',
    value: 'client_of_another_portal',
  },
  {
    id: 10,
    label: 'Youtube',
    value: 'youtube',
  },
  {
    id: 11,
    label: 'TikTok',
    value: 'tiktok',
  },
  {
    id: 12,
    label: 'Previous user',
    value: 'previous_user',
  },
  {
    id: 13,
    label: 'Event or conference',
    value: 'event_conference',
  },
  {
    id: 14,
    label: 'Podcast',
    value: 'podcast',
  },
  {
    id: 15,
    label: 'Newsletter',
    value: 'newsletter',
  },
  {
    id: 16,
    label: 'Sales email',
    value: 'sales_email',
  },
];

export const CLIENT_TYPE_COMPANY = 'company';
export const CLIENT_TYPE_INDIVIDUAL = 'individual';

export const CLIENT_TYPES = [
  {
    id: 0,
    label: 'Companies',
    value: CLIENT_TYPE_COMPANY,
  },
  {
    id: 1,
    label: 'Individuals',
    value: CLIENT_TYPE_INDIVIDUAL,
  },
] as const;

export type ClientType = (typeof CLIENT_TYPES)[number]['value'];

export const OnboardingTrackEventName = {
  Lead: 'Lead',
  CompleteRegistration: 'CompleteRegistration',
  StartTrial: 'StartTrial',
};

export const CLIENT_COUNT_LIST = [
  {
    id: 0,
    label: `I don't have clients yet`,
    value: '0',
  },
  {
    id: 1,
    label: '1 - 10',
    value: '10',
  },
  {
    id: 2,
    label: '11 - 50',
    value: '50',
  },
  {
    id: 3,
    label: '51 - 100',
    value: '100',
  },
  {
    id: 4,
    label: '101 - 250',
    value: '250',
  },
  {
    id: 5,
    label: '251 - 1,000',
    value: '1000',
  },
  {
    id: 6,
    label: '1,001 - 10,000',
    value: '10000',
  },
  {
    id: 7,
    label: '10,001+',
    value: '10001+',
  },
];
