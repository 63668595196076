import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HelpIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.25 6.40622C6.25 6.40622 6.30625 5.11716 7.56016 4.08356C8.30469 3.46989 9.19883 3.29216 10 3.28122C10.7316 3.27224 11.3855 3.39606 11.7766 3.58669C12.4449 3.91403 13.75 4.71091 13.75 6.40622C13.75 8.1902 12.6102 8.9988 11.3145 9.88981C10.0187 10.7808 9.6875 11.6547 9.6875 12.6562"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5625"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.6875 16.8748C10.3779 16.8748 10.9375 16.3151 10.9375 15.6248C10.9375 14.9344 10.3779 14.3748 9.6875 14.3748C8.99714 14.3748 8.4375 14.9344 8.4375 15.6248C8.4375 16.3151 8.99714 16.8748 9.6875 16.8748Z"
      fill="currentColor"
    />
  </SvgIcon>
);
