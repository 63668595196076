import { ProductSchema } from 'src/legacy/components/Products/ProductEditPage/ProductEditPage';
import { ApiMethods, ApiTags, appAPI } from '.';
import { notify } from 'src/clients/ApiService';
import { ProductDetailsSchema } from 'src/legacy/components/Products/ProductDetailPage/EditProductDetail';
import { convertAmountToCents } from 'src/utils/InvoiceUtils';
import moment from 'moment';
import { ensureApiError } from 'src/utils/Errors';
import { SubscriptionInterval } from 'src/types/subscriptions';
import { recursiveLoad } from '../recursiveLoad';
import { API } from '../../utils/AmplifyApiUtils';
import { AppAPIName } from '../../constants';

export enum ProductStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum PriceType {
  OneTime = 'oneTime',
  Recurring = 'recurring',
}

export type Price = {
  id: string;
  amount: number;
  currency: string;
  type: PriceType;
  productId: string;
  createdAt: string;
  ref: string;
  interval?: SubscriptionInterval;
  isUsed: boolean;
};

export type CreatePriceInput = {
  productId: string;
  amount: string;
  type: PriceType;
  interval?: SubscriptionInterval;
  currency?: string;
};

export type CreateProductInput = {
  name: string;
  description?: string;
  imageUrls: string[];
  prices: Omit<Price, 'id' | 'createdAt'>[];
};

export type Product = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  description?: string;
  imageUrls: string[];
  status: ProductStatus;
  taxPercentage: number;
  priceIds: string[];
  timesUsed?: number;
  price?: Price;
  isUsed: boolean;
};

export const BillingPeriodMapper: { [key: string]: string } = {
  day: 'day',
  week: 'week',
  month: 'month',
  quarterly: 'quarter',
  biannually: 'biannual',
  year: 'year',
  semiannually: '2 years',
};

export const BILLING_PERIODS = [
  {
    value: 'day',
    label: 'Daily',
  },
  {
    value: 'week',
    label: 'Weekly',
  },
  {
    value: 'month',
    label: 'Monthly',
  },
  {
    value: 'quarterly',
    label: 'Quarterly',
  },
  {
    value: 'biannually',
    label: 'Biannually',
  },
  {
    value: 'year',
    label: 'Yearly',
  },
  {
    value: 'semiannually',
    label: 'Every 2 years',
  },
] as const;

type GetProductsResponse = {
  data: Product[];
};

type GetPricesResponse = {
  data: Price[];
};

async function loadPricesForPortal(): Promise<{ data: Price[] }> {
  return recursiveLoad<Price>(
    (currentQueryOptions) =>
      API.get(AppAPIName, '/prices', currentQueryOptions),
    {
      limit: 1000,
    },
  );
}

export const productsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    addProduct: build.mutation<Product, ProductSchema>({
      query: (product) => ({
        path: '/products',
        method: ApiMethods.post,
        options: {
          body: {
            ...product,
            prices: product.prices.map((price) => ({
              ...price,
              amount: convertAmountToCents(Number(price.amount)), // amount in cents
              interval:
                price.type === PriceType.Recurring ? price.interval : undefined,
            })),
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            productsApi.util.updateQueryData(
              'getProducts',
              undefined,
              (draft) => {
                if (result.data) {
                  draft.push(result.data);
                }
              },
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Product has been added',
            dispatch,
          });
        } catch (error) {
          const err = ensureApiError(error);
          notify({
            status: 'error',
            errorMessage: err.message || 'This product could not be added.',
            error,
            dispatch,
          });
        }
      },
    }),
    deleteProduct: build.mutation<string, string>({
      query: (id) => ({
        path: `/products/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted(productId, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          productsApi.util.updateQueryData('getProducts', undefined, (draft) =>
            draft.filter((product) => product.id !== productId),
          ),
        );
        try {
          const result = await queryFulfilled;
          dispatch(
            productsApi.util.updateQueryData(
              'getProducts',
              undefined,
              (draft) => draft.filter((product) => product.id !== result.data),
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Product deleted successfully.',
            dispatch,
          });
        } catch (err) {
          patch.undo();
          const { message: errorMessage } = ensureApiError(err);
          notify({
            status: 'error',
            errorMessage:
              errorMessage || 'A problem occurred while deleting product.',
            dispatch,
          });
        }
      },
    }),
    getProductById: build.query<Product, string>({
      query: (id) => ({
        path: `/products/${id}`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    getPricesByProductId: build.query<Price[], string>({
      query: (productId) => ({
        path: `/prices?productId=${productId}`,
        method: ApiMethods.get,
        options: {},
      }),
      transformResponse: (response: GetPricesResponse) => response.data || [],
      providesTags: [ApiTags.prices],
    }),
    getPricesByPortal: build.query<Price[], void>({
      queryFn: async () => {
        // fetch prices with pagination
        const response = await loadPricesForPortal();
        return { data: response.data || [] };
      },
      providesTags: [ApiTags.prices],
    }),
    getProducts: build.query<Product[], void>({
      query: () => ({
        path: '/products',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            limit: 1000,
          },
        },
      }),
      transformResponse: (response: GetProductsResponse) => response.data || [],
      providesTags: [ApiTags.products],
    }),
    addPriceByProductId: build.mutation<Price, CreatePriceInput>({
      query: (price) => ({
        path: `/prices`,
        method: ApiMethods.post,
        options: {
          body: {
            ...price,
            amount: convertAmountToCents(Number(price.amount)), // in cents
          },
        },
      }),
      async onQueryStarted(input, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            productsApi.util.updateQueryData(
              'getPricesByProductId',
              input.productId,
              (draft) => {
                if (result.data) {
                  draft.push(result.data);
                }
              },
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Price has been added',
            dispatch,
          });
        } catch (err) {
          const error = ensureApiError(err);
          notify({
            status: 'error',
            errorMessage: error.message || 'This price could not be added.',
            error,
            dispatch,
          });
        }
      },
    }),
    updateProduct: build.mutation<Product, ProductDetailsSchema>({
      query: (product) => ({
        path: `/products/${product.id}`,
        method: ApiMethods.put,
        options: {
          body: {
            ...product,
          },
        },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const currentDate = moment().toISOString();
        const productsListDraft = dispatch(
          productsApi.util.updateQueryData('getProducts', undefined, (draft) =>
            draft.map((product) =>
              product.id === payload.id
                ? { ...product, ...payload, updatedAt: currentDate }
                : product,
            ),
          ),
        );

        const productDetailsDraft = dispatch(
          productsApi.util.updateQueryData(
            'getProductById',
            payload.id,
            (draft) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              draft = {
                ...draft,
                ...payload,
                updatedAt: currentDate,
              };
            },
          ),
        );

        try {
          await queryFulfilled;

          notify({
            status: 'success',
            successMessage: 'Product has been updated',
            dispatch,
          });
        } catch (error) {
          productsListDraft.undo();
          productDetailsDraft.undo();
          const err = ensureApiError(error);
          notify({
            status: 'error',
            errorMessage: err.message || 'This product could not be updated.',
            error,
            dispatch,
          });
        }
      },
    }),
    deletePrice: build.mutation<string, { priceId: string; productId: string }>(
      {
        query: (data) => ({
          path: `/prices/${data.priceId}`,
          method: ApiMethods.del,
          options: {},
        }),
        async onQueryStarted(request, { dispatch, queryFulfilled }) {
          const patch = dispatch(
            productsApi.util.updateQueryData(
              'getPricesByProductId',
              request.productId,
              (draft) => draft.filter((price) => price.id !== request.priceId),
            ),
          );
          try {
            await queryFulfilled;
            notify({
              status: 'success',
              successMessage: 'Price deleted successfully.',
              dispatch,
            });
          } catch (err) {
            patch.undo();
            const error = ensureApiError(err);
            const errorMessage = error?.message;
            notify({
              status: 'error',
              errorMessage:
                errorMessage || 'A problem occurred while deleting price.',
              error,
              dispatch,
            });
          }
        },
      },
    ),
  }),
});

export const {
  useAddProductMutation,
  useGetProductByIdQuery,
  useGetPricesByProductIdQuery,
  useGetProductsQuery,
  useGetPricesByPortalQuery,
  useAddPriceByProductIdMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useDeletePriceMutation,
} = productsApi;
