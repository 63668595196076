import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HandRightIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 12.5V5.625C16.875 5.29348 16.7433 4.97554 16.5089 4.74112C16.2745 4.5067 15.9565 4.375 15.625 4.375V4.375C15.2935 4.375 14.9755 4.5067 14.7411 4.74112C14.5067 4.97554 14.375 5.29348 14.375 5.625V10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 10V3.125C14.375 2.79348 14.2433 2.47554 14.0089 2.24112C13.7745 2.0067 13.4565 1.875 13.125 1.875C12.7935 1.875 12.4755 2.0067 12.2411 2.24112C12.0067 2.47554 11.875 2.79348 11.875 3.125V9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 9.41406V3.75C9.375 3.41848 9.2433 3.10054 9.00888 2.86612C8.77446 2.6317 8.45652 2.5 8.125 2.5V2.5C7.79348 2.5 7.47554 2.6317 7.24112 2.86612C7.0067 3.10054 6.875 3.41848 6.875 3.75V12.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 9.375V1.875C11.875 1.54348 11.7433 1.22554 11.5089 0.991117C11.2745 0.756696 10.9565 0.625 10.625 0.625C10.2935 0.625 9.97554 0.756696 9.74112 0.991117C9.5067 1.22554 9.375 1.54348 9.375 1.875V9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 12.5001C16.875 17.086 14.375 19.3751 10.9375 19.3751C7.5 19.3751 6.10508 17.8282 5.3125 15.9376L3.25508 10.3126C2.99492 9.6075 3.11289 8.95359 3.71875 8.60945C4.325 8.26492 5.12149 8.44227 5.44961 9.0657L6.875 12.5001"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
