import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PaymentsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.5769 3.75H3.42308C2.08485 3.75 1 4.72938 1 5.9375V14.0625C1 15.2706 2.08485 16.25 3.42308 16.25H16.5769C17.9152 16.25 19 15.2706 19 14.0625V5.9375C19 4.72938 17.9152 3.75 16.5769 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 7.5H19H1ZM4.46154 11.7188H6.53846V12.5H4.46154V11.7188Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
