import React from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Loader, LoaderProps } from 'src/legacy/components/Loading/Loader';

interface StyleProps {
  isLoading: boolean;
  wrapperProps?: React.CSSProperties;
  showMask?: boolean;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: (props: StyleProps) => (props.isLoading ? 'relative' : 'inherit'),
  },
  defaultHeight: {
    height: (props: StyleProps) =>
      props.isLoading ? '100%' : props.wrapperProps?.height || 'inherit',
  },
  fullHeight: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  loadableContent: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: (props: StyleProps) =>
      props.showMask ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
  },
}));

type LoadableContentProps = LoaderProps & StyleProps;

export interface LoadingWrapperProps extends LoadableContentProps {
  hideContentWhileLoading?: boolean;
  fullHeight?: boolean;
}

// Component to wrap an area that will have a loading state
export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  children,
  isLoading,
  showMask = false,
  hideContentWhileLoading = false,
  wrapperProps = {},
  fullHeight = false,
  ...loaderProps
}) => {
  const classes = useStyles({ isLoading, showMask, wrapperProps });
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.fullHeight]: fullHeight,
        [classes.defaultHeight]: !fullHeight,
      })}
      style={wrapperProps}
    >
      {hideContentWhileLoading && isLoading ? null : children}
      {isLoading ? (
        <div className={classes.loadableContent}>
          <Loader {...loaderProps} />
        </div>
      ) : null}
    </div>
  );
};
