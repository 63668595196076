import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PhoneIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <rect width="20" height="20" rx="3" fill="currentColor" />
    <path
      d="M12.6549 15.6892C12.3872 15.6892 12.1177 15.653 11.8517 15.5801C8.25543 14.5931 5.40817 11.7476 4.42059 8.15365C4.196 7.3364 4.31906 6.48473 4.76822 5.75673C5.21914 5.02523 5.96059 4.50898 6.80234 4.34098C7.36234 4.22898 7.91592 4.47865 8.20001 4.95757L9.11175 6.49756C9.5545 7.24539 9.33451 8.20848 8.61059 8.69031L7.95092 9.12898C8.56751 10.3948 9.60458 11.4349 10.8652 12.0509L11.3098 11.3876C11.7945 10.6649 12.7576 10.4485 13.5048 10.8936L15.0466 11.8129C15.5238 12.0976 15.7705 12.6546 15.662 13.1983C15.494 14.0401 14.9777 14.7815 14.2468 15.2324C13.7557 15.5346 13.2091 15.6892 12.6549 15.6892ZM7.07008 5.18798C7.0415 5.18798 7.01235 5.1909 6.98435 5.19674C6.36952 5.31982 5.83693 5.69022 5.51377 6.21581C5.19352 6.73497 5.10542 7.34106 5.26525 7.92148C6.17117 11.2191 8.78391 13.8306 12.0832 14.736C12.6642 14.8952 13.2685 14.8066 13.7871 14.4869C14.3121 14.1631 14.6832 13.63 14.8039 13.0256C14.8401 12.8442 14.7579 12.6581 14.598 12.5631L13.0568 11.6437C12.7097 11.4372 12.2617 11.5381 12.0365 11.8736L11.3861 12.8448C11.2695 13.0186 11.0444 13.0851 10.8542 13.0046C9.12578 12.2819 7.71936 10.8726 6.99603 9.13772C6.91553 8.94406 6.98311 8.72123 7.15753 8.60514L8.12652 7.96055C8.46252 7.73714 8.56457 7.28972 8.35866 6.94264L7.44691 5.40322C7.367 5.26789 7.22233 5.18798 7.07008 5.18798Z"
      fill="white"
    />
  </SvgIcon>
);
