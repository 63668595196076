import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BookmarksIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 3.125V2.5C5.00144 2.00316 5.19945 1.52709 5.55077 1.17577C5.90209 0.82445 6.37816 0.626443 6.875 0.625H15.625C16.1218 0.626443 16.5979 0.82445 16.9492 1.17577C17.3006 1.52709 17.4986 2.00316 17.5 2.5V16.875L14.375 14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 3.75H4.375C3.87816 3.75144 3.40209 3.94945 3.05077 4.30077C2.69945 4.65209 2.50144 5.12816 2.5 5.625V19.375L8.4375 14.375L14.375 19.375V5.625C14.3736 5.12816 14.1756 4.65209 13.8242 4.30077C13.4729 3.94945 12.9968 3.75144 12.5 3.75V3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
