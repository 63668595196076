import * as Colors from 'src/theme/colors';

export default {
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
      padding: '0px 4.5px',
    },
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: Colors.HoverNonBorderBackground,
    },
  },
};
