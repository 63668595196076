import React, { useContext } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { makeStyles, createStyles, AppBar } from '@material-ui/core';
import { RouteContext } from 'src/context';
import { RootState } from 'src/store/reduxTypes';
import * as Colors from 'src/theme/colors';
import {
  togglePrimarySidebarMobile,
  updateSearchValueAction,
} from 'src/store/ui/actions';
import { ShortcutsSidebar } from 'src/legacy/components/ShortcutsDrawer';
// external-global styles must be imported in your JS.
import s from 'src/legacy/components/Layout/Layout.css';
import {
  DESKTOP_APP_BAR_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
  BANNER_HEIGHT,
} from 'src/constants/uiConsts';
import Navbar from 'src/legacy/components/Navbar';
import Sidebar from 'src/legacy/components/Sidebar';
import AuthModal from 'src/legacy/components/Auth/AuthModal';
import { HelpCenterModal } from 'src/legacy/components/Layout/HelpCenterModal';
import { CompleteExternalActionModal } from 'src/legacy/components/Modals';
import { CommandBar } from 'src/legacy/components/CommandBar';
import { GeneralCommandbarActionsInitializer } from 'src/legacy/components/CommandBar/GeneralCommandbarActionsInitializer';
import { useImportProgressNotifier } from 'src/hooks/useImportProgressNotifier';
import { CLIENT_DETAILS_PAGE, CRM_CLIENTS_PAGE } from 'src/constants';
import { Banner } from 'src/legacy/components/Banner';
import { drawerWidth } from 'src/legacy/components/Sidebar/SidebarContainer';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import { shallowEqual } from 'react-redux';
import { WhatsNew } from 'src/legacy/components/WhatsNew/WhatsNew';

interface SpacingProps {
  disableGutters: boolean;
  showBanner: boolean;
}

export const SCROLLBAR_SIZE = 10;

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      boxShadow: 'none',
      zIndex: theme.zIndex.drawer + 1,
      left: '50%',
      transform: 'translateX(-50%)',
      borderBottom: `1px solid ${Colors.DividersAndCardBorders}`,
      [theme.breakpoints.down('xs')]: {
        height: (props: SpacingProps) =>
          `${
            (props.disableGutters ? 0 : MOBILE_APP_BAR_HEIGHT) +
            (props.showBanner ? BANNER_HEIGHT : 0)
          }px`,
      },
      [theme.breakpoints.up('md')]: {
        height: (props: SpacingProps) =>
          `${
            (props.disableGutters ? 0 : DESKTOP_APP_BAR_HEIGHT) +
            (props.showBanner ? BANNER_HEIGHT : 0)
          }px`,
        paddingLeft: drawerWidth,
      },
    },
    root: {
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      overflowY: 'auto',
      boxSizing: 'border-box',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: (props: SpacingProps) =>
          props.disableGutters
            ? // when page is full space, we also need to consider
              // leaving spacing for the trial banner.
              `${props.showBanner ? BANNER_HEIGHT : 0}px`
            : `${
                DESKTOP_APP_BAR_HEIGHT + (props.showBanner ? BANNER_HEIGHT : 0)
              }px`,
      },
      [theme.breakpoints.down('xs')]: {
        overflowY: 'hidden',
        '@supports ( -moz-appearance:none )': {
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        },
        paddingTop: (props: SpacingProps) =>
          props.disableGutters
            ? `${props.showBanner ? BANNER_HEIGHT : 0}px`
            : `${
                MOBILE_APP_BAR_HEIGHT + (props.showBanner ? BANNER_HEIGHT : 0)
              }px`,
      },
    },
  }),
);

export interface LayoutProps {
  title?: string;
  hasNavbar?: boolean;
  showSearchbar?: boolean;
  showBreadCrumb?: boolean;
  BreadCrumbComponent?: React.FC;
  disableGutters?: boolean;
  hasSidebar?: boolean;
  showNavbarAuthActions?: boolean;
  headerComponent?: React.ReactNode;
}

const PAGES_WITH_RETAINED_SEARCH = [
  CRM_CLIENTS_PAGE.path,
  CLIENT_DETAILS_PAGE.path,
];

const Layout: React.FC<LayoutProps> = (props) => {
  const {
    title,
    hasNavbar = true,
    showSearchbar = false,
    showBreadCrumb = false,
    BreadCrumbComponent,
    hasSidebar = true,
    children,
    disableGutters = false,
    showNavbarAuthActions = true,
    headerComponent,
  } = props;
  const layoutWrapperRef = React.useRef<HTMLDivElement>(null);
  const { pathname } = useContext(RouteContext);
  const dispatch = useAppDispatch();
  const {
    loaded: userLoaded,
    loading: userLoading,
    isClient,
  } = useAppSelector(
    (state: RootState) => ({
      loaded: state.user.loaded,
      loading: state.user.loading,
      isClient: state.user.isClient,
    }),
    shallowEqual,
  );
  const isPrimarySideBarMobileOpen = useAppSelector(
    (state: RootState) => state.ui.primarySideBarMobile.isOpen,
  );

  const bannerOptions = useAppSelector(
    (state: RootState) => state.ui.bannerOptions,
  );

  const classes = useStyles({
    disableGutters,
    showBanner: Boolean(bannerOptions),
  });

  const showPrimarySidebarMobile = React.useCallback(() => {
    dispatch(togglePrimarySidebarMobile({ isOpen: true }));
  }, []);

  const hidePrimarySidebarMobile = React.useCallback(() => {
    dispatch(togglePrimarySidebarMobile({ isOpen: false }));
  }, []);

  // this hook is used to notify user about import progress
  useImportProgressNotifier();
  /**
   * Search bar should persist the search query when navigating between client => client details and vice versa.
   * Resets the search bar if history stack does not indicate that navigation occurred between client and client detail.
   * Logic resides in Layout component because `Searchbox` component isn't always preset in DOM
   */
  React.useEffect(() => {
    if (!PAGES_WITH_RETAINED_SEARCH.includes(pathname)) {
      dispatch(updateSearchValueAction(''));
    }
    return () => {
      if (!PAGES_WITH_RETAINED_SEARCH.includes(pathname)) {
        dispatch(updateSearchValueAction(''));
      }
    };
  }, [pathname]);

  React.useEffect(() => {
    if (layoutWrapperRef.current) {
      layoutWrapperRef.current.scrollTop = 0;
    }
  }, [children]);

  return (
    <div className={classes.root} ref={layoutWrapperRef}>
      <CommandBar>
        <AppBar position="fixed" className={classes.appBar}>
          {bannerOptions ? <Banner {...bannerOptions} /> : null}
          {hasNavbar && (
            <Navbar
              title={title}
              userLoaded={userLoaded}
              isClient={isClient}
              userLoading={userLoading}
              onOpenSidebarMobile={showPrimarySidebarMobile}
              showSearchbar={showSearchbar}
              showBreadCrumb={showBreadCrumb}
              BreadCrumbComponent={BreadCrumbComponent}
              showNavbarAuthActions={showNavbarAuthActions}
              headerContent={headerComponent}
            />
          )}
        </AppBar>
        <HelpCenterModal />
        {hasSidebar && (
          <Sidebar
            onMobileClose={hidePrimarySidebarMobile}
            openMobile={isPrimarySideBarMobileOpen}
          />
        )}
        {children}
        <AuthModal />
        {userLoaded && (
          <>
            <CompleteExternalActionModal />
            <GeneralCommandbarActionsInitializer />
            <WhatsNew />
          </>
        )}
        <ShortcutsSidebar />
      </CommandBar>
    </div>
  );
};

export default withStyles(s)(Layout);
