import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SidebarIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.3021 2.25V17.75M1 6.125C1 3.9849 2.7349 2.25 4.875 2.25H15.2083C17.3484 2.25 19.0833 3.9849 19.0833 6.125V13.875C19.0833 16.0151 17.3484 17.75 15.2083 17.75H4.875C2.7349 17.75 1 16.0151 1 13.875V6.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
    />
  </SvgIcon>
);
