import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BalloonIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.1758 6.00843C16.7832 10.328 13.5094 13.9487 11.0364 14.2967C8.56331 14.6448 4.41604 12.0506 3.80979 7.74671C3.69568 6.93485 3.74259 6.10846 3.94786 5.31473C4.15312 4.521 4.5127 3.77547 5.00609 3.1207C5.49947 2.46594 6.11699 1.91477 6.82338 1.49866C7.52978 1.08255 8.31121 0.809646 9.12307 0.695535C9.93494 0.581423 10.7613 0.628337 11.5551 0.833598C12.3488 1.03886 13.0943 1.39845 13.7491 1.89183C14.4038 2.38521 14.955 3.00273 15.3711 3.70912C15.7872 4.41552 16.0601 5.19695 16.1742 6.00882L16.1758 6.00843Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M9.22148 12.0331C7.93906 11.5253 6.60117 10.3495 6.00781 8.69482"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.3633 19.3664C13.0809 18.8585 11.8902 17.8015 11.2969 16.1472"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.4187 14.3833L10.0605 16.3212L12.5336 15.9735L11.6551 14.2095L10.4187 14.3833Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
