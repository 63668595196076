import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FlameIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M4.375 12.5C4.375 8.86719 9.21875 6.05469 8.125 1.875C10.7031 1.875 15.625 5.625 15.625 12.5C15.625 13.9918 15.0324 15.4226 13.9775 16.4775C12.9226 17.5324 11.4918 18.125 10 18.125C8.50816 18.125 7.07742 17.5324 6.02252 16.4775C4.96763 15.4226 4.375 13.9918 4.375 12.5V12.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 14.375C12.5 16.6293 11.25 17.5 10 17.5C8.75 17.5 7.5 16.6293 7.5 14.375C7.5 12.1207 9.0625 11.0156 8.75 9.375C10.3906 9.375 12.5 12.1207 12.5 14.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
