export const copilotDotCom = {
  prod: 'copilot.com',
  staging: 'copilot-staging.com',
};

export const copilotApp = {
  prod: 'copilot.app',
  staging: 'copilot-staging.app',
};

export const reservedSubdomains = [
  'dashboard',
  'notification',
  'notifications',
  'mail',
  'link',
  'links',
  'docs',
  'blog',
  'api',
  // We are not splitting with a dash because the portal URL is already removing any separator.
  // Therefore, we would check the words without splitting them. This will catch the following cases:
  // api-admin, app-api, api-internal ...etc.
  'appapi',
  'apiadmin',
  'apiclient',
  'apiinternal',
  'secure',
  'status',
  'support',
  'www',
  'try',
  'auth',
  'internalauth',
  'clientauth',
  'get',
  'http',
  'https',
  'admin',
  'administrator',
];
