import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SubscriptionIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 0C4.48606 0 0 4.48654 0 10C0 15.5221 4.47788 20 10 20C15.5139 20 20 15.5139 20 10C20 4.4774 15.5226 0 10 0ZM10 15.0481C8.80143 15.0481 7.65194 14.5719 6.80442 13.7244C5.9569 12.8769 5.48077 11.7274 5.48077 10.5288C5.48077 9.33027 5.9569 8.18079 6.80442 7.33327C7.65194 6.48575 8.80143 6.00962 10 6.00962H10.2024L9.52404 5.33173C9.39781 5.2055 9.32689 5.03429 9.32689 4.85577C9.32689 4.67725 9.39781 4.50604 9.52404 4.37981C9.65027 4.25358 9.82148 4.18266 10 4.18266C10.1785 4.18266 10.3497 4.25358 10.476 4.37981L12.399 6.30289C12.4615 6.36539 12.5111 6.43959 12.545 6.52126C12.5788 6.60292 12.5962 6.69045 12.5962 6.77885C12.5962 6.86724 12.5788 6.95477 12.545 7.03644C12.5111 7.1181 12.4615 7.19231 12.399 7.25481L10.476 9.17788C10.3497 9.30412 10.1785 9.37503 10 9.37503C9.82148 9.37503 9.65027 9.30412 9.52404 9.17788C9.39781 9.05165 9.32689 8.88044 9.32689 8.70192C9.32689 8.5234 9.39781 8.35219 9.52404 8.22596L10.3894 7.36058C10.275 7.35577 10.1442 7.35577 10 7.35577C9.37243 7.35577 8.75894 7.54187 8.23713 7.89053C7.71532 8.23919 7.30862 8.73476 7.06846 9.31456C6.8283 9.89437 6.76546 10.5324 6.88789 11.1479C7.01033 11.7634 7.31253 12.3288 7.7563 12.7725C8.20006 13.2163 8.76545 13.5185 9.38096 13.641C9.99648 13.7634 10.6345 13.7006 11.2143 13.4604C11.7941 13.2202 12.2897 12.8135 12.6383 12.2917C12.987 11.7699 13.1731 11.1564 13.1731 10.5288C13.1731 10.3503 13.244 10.1791 13.3702 10.0529C13.4964 9.92668 13.6676 9.85577 13.8462 9.85577C14.0247 9.85577 14.1959 9.92668 14.3221 10.0529C14.4483 10.1791 14.5192 10.3503 14.5192 10.5288C14.5178 11.727 14.0413 12.8757 13.194 13.7229C12.3468 14.5701 11.1981 15.0467 10 15.0481Z"
      fill="currentColor"
    />
  </SvgIcon>
);
