import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BodyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 3.75C10.8629 3.75 11.5625 3.05044 11.5625 2.1875C11.5625 1.32456 10.8629 0.625 10 0.625C9.13706 0.625 8.4375 1.32456 8.4375 2.1875C8.4375 3.05044 9.13706 3.75 10 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M7.78516 11.5476L6.59766 18.2742C6.57631 18.3955 6.57908 18.5198 6.60581 18.6401C6.63255 18.7604 6.68272 18.8742 6.75346 18.975C6.8242 19.0759 6.91413 19.1618 7.01809 19.2279C7.12206 19.2939 7.23803 19.3389 7.35937 19.3601C7.48026 19.3816 7.6042 19.3789 7.72403 19.3522C7.84386 19.3254 7.9572 19.2752 8.05747 19.2044C8.15775 19.1335 8.24297 19.0435 8.3082 18.9395C8.37344 18.8354 8.41738 18.7195 8.4375 18.5984L9.25781 13.9148V13.9226C9.25781 13.9226 9.46094 12.6531 9.94141 12.6531H10.0625C10.5508 12.6531 10.7461 13.9226 10.7461 13.9226V13.9187L11.5664 18.6023C11.6099 18.8463 11.7486 19.063 11.9519 19.2047C12.1551 19.3465 12.4064 19.4017 12.6504 19.3582C12.8944 19.3146 13.1111 19.176 13.2528 18.9727C13.3946 18.7694 13.4498 18.5182 13.4063 18.2742L12.2187 11.5476L12.0273 10.3875C11.9141 9.68042 11.8633 8.52808 12.0469 8.05542C12.2031 7.64917 12.5988 7.50073 12.9531 7.50073H16.5625C16.8111 7.50073 17.0496 7.40196 17.2254 7.22615C17.4012 7.05033 17.5 6.81187 17.5 6.56323C17.5 6.31459 17.4012 6.07614 17.2254 5.90032C17.0496 5.7245 16.8111 5.62573 16.5625 5.62573H3.4375C3.18886 5.62573 2.9504 5.7245 2.77459 5.90032C2.59877 6.07614 2.5 6.31459 2.5 6.56323C2.5 6.81187 2.59877 7.05033 2.77459 7.22615C2.9504 7.40196 3.18886 7.50073 3.4375 7.50073H7.05078C7.41133 7.50073 7.80078 7.64917 7.95703 8.05542C8.14062 8.52808 8.08984 9.68042 7.97656 10.3875L7.78516 11.5476Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
