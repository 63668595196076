import UniversalRouter from 'universal-router';
import routes from 'src/routes';

// TODO: Establish a pattern for per-route param typing.
//
//       In the meantime, make all route params global
//       and (probably) optional.
interface GlobalRouteParams {
  description?: string;
  id?: string;
  redirect?: string;
  title?: string;
}

export default new UniversalRouter<GlobalRouteParams>(routes, {
  async resolveRoute(context, params) {
    if ('load' in context.route && typeof context.route.load === 'function') {
      const action = await context.route.load();
      return action.default(context, params);
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});
