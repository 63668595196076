import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CarSportIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M18.3479 9.16406C18.0616 8.78398 16.9979 8.52227 16.5479 7.83828C16.0979 7.1543 15.7296 5.67305 14.5843 5.10391C13.439 4.53477 11.2499 4.375 9.9999 4.375C8.7499 4.375 6.5624 4.53125 5.41553 5.10273C4.26865 5.67422 3.90186 7.1543 3.45186 7.83711C3.00186 8.51992 1.93818 8.78398 1.65186 9.16406C1.36553 9.54414 1.16396 11.9469 1.28662 13.125C1.40928 14.3031 1.63818 15 1.63818 15H4.99756C5.54756 15 5.72646 14.7934 6.85146 14.6875C8.08584 14.5703 9.29678 14.5312 9.9999 14.5312C10.703 14.5312 11.953 14.5703 13.1866 14.6875C14.3116 14.7941 14.4964 15 15.0405 15H18.3608C18.3608 15 18.5897 14.3031 18.7124 13.125C18.8351 11.9469 18.6327 9.54414 18.3479 9.16406Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8125 15H15.625V15.625H17.8125V15Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 15H2.1875V15.625H4.375V15Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2371 12.0766C14.0062 11.8098 13.2539 11.5871 12.2578 11.4379C11.2617 11.2887 10.8984 11.25 10.0078 11.25C9.11717 11.25 8.71209 11.3141 7.7574 11.4379C6.80271 11.5617 6.08592 11.782 5.77849 12.0766C5.31717 12.5234 5.99295 13.025 6.52342 13.0859C7.03748 13.1445 8.06521 13.123 10.0121 13.123C11.959 13.123 12.9867 13.1445 13.5008 13.0859C14.0304 13.0215 14.6578 12.5547 14.2371 12.0766Z"
      fill="currentColor"
    />
    <path
      d="M16.8582 9.49403C16.856 9.46301 16.8424 9.43389 16.8201 9.41226C16.7977 9.39063 16.7682 9.37802 16.7371 9.37684C16.2758 9.36044 15.8074 9.39325 14.9765 9.63817C14.5527 9.76192 14.1546 9.96121 13.8015 10.2265C13.7125 10.296 13.7441 10.4839 13.8558 10.5038C14.5407 10.5841 15.2296 10.6246 15.9191 10.6249C16.3328 10.6249 16.7597 10.5077 16.839 10.139C16.8795 9.92634 16.8859 9.70867 16.8582 9.49403V9.49403Z"
      fill="currentColor"
    />
    <path
      d="M3.1419 9.49403C3.14409 9.46301 3.15766 9.43389 3.18001 9.41226C3.20236 9.39063 3.23191 9.37802 3.26299 9.37684C3.72432 9.36044 4.19268 9.39325 5.02354 9.63817C5.44743 9.76192 5.84548 9.96121 6.19854 10.2265C6.2876 10.296 6.25596 10.4839 6.14424 10.5038C5.45941 10.5841 4.77049 10.6246 4.08096 10.6249C3.66729 10.6249 3.24033 10.5077 3.16104 10.139C3.12061 9.92634 3.11415 9.70867 3.1419 9.49403V9.49403Z"
      fill="currentColor"
    />
    <path
      d="M16.875 7.5H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 7.5H3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.04688 8.24219C3.04688 8.24219 4.85742 7.77344 10 7.77344C15.1426 7.77344 16.9531 8.24219 16.9531 8.24219"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
