import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SaveIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.8801 2.24102C14.764 2.12495 14.6262 2.03289 14.4745 1.97008C14.3228 1.90728 14.1603 1.87497 13.9961 1.875H3.68047C3.44336 1.87495 3.20856 1.92161 2.98949 2.01233C2.77041 2.10304 2.57136 2.23603 2.4037 2.4037C2.23603 2.57136 2.10304 2.77041 2.01233 2.98949C1.92161 3.20856 1.87495 3.44336 1.875 3.68047V16.3195C1.87495 16.5566 1.92161 16.7914 2.01233 17.0105C2.10304 17.2296 2.23603 17.4286 2.4037 17.5963C2.57136 17.764 2.77041 17.897 2.98949 17.9877C3.20856 18.0784 3.44336 18.1251 3.68047 18.125H16.3195C16.7979 18.1236 17.2563 17.9329 17.5946 17.5946C17.9329 17.2563 18.1236 16.7979 18.125 16.3195V6.00391C18.125 5.83974 18.0927 5.67718 18.0299 5.52551C17.9671 5.37383 17.8751 5.23602 17.759 5.11992L14.8801 2.24102ZM10 16.25C9.50555 16.25 9.0222 16.1034 8.61107 15.8287C8.19995 15.554 7.87952 15.1635 7.6903 14.7067C7.50108 14.2499 7.45157 13.7472 7.54804 13.2623C7.6445 12.7773 7.8826 12.3319 8.23223 11.9822C8.58186 11.6326 9.02732 11.3945 9.51227 11.298C9.99723 11.2016 10.4999 11.2511 10.9567 11.4403C11.4135 11.6295 11.804 11.95 12.0787 12.3611C12.3534 12.7722 12.5 13.2555 12.5 13.75C12.5004 14.0784 12.436 14.4037 12.3105 14.7072C12.185 15.0107 12.0009 15.2865 11.7687 15.5187C11.5365 15.7509 11.2607 15.935 10.9572 16.0605C10.6537 16.186 10.3284 16.2504 10 16.25V16.25ZM11.875 7.5H4.375C4.20924 7.5 4.05027 7.43415 3.93306 7.31694C3.81585 7.19973 3.75 7.04076 3.75 6.875V4.375C3.75 4.20924 3.81585 4.05027 3.93306 3.93306C4.05027 3.81585 4.20924 3.75 4.375 3.75H11.875C12.0408 3.75 12.1997 3.81585 12.3169 3.93306C12.4342 4.05027 12.5 4.20924 12.5 4.375V6.875C12.5 7.04076 12.4342 7.19973 12.3169 7.31694C12.1997 7.43415 12.0408 7.5 11.875 7.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
