import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ShareSocialIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 11.875C6.03553 11.875 6.875 11.0355 6.875 10C6.875 8.96447 6.03553 8.125 5 8.125C3.96447 8.125 3.125 8.96447 3.125 10C3.125 11.0355 3.96447 11.875 5 11.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 6.25C16.0355 6.25 16.875 5.41053 16.875 4.375C16.875 3.33947 16.0355 2.5 15 2.5C13.9645 2.5 13.125 3.33947 13.125 4.375C13.125 5.41053 13.9645 6.25 15 6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 17.5C16.0355 17.5 16.875 16.6605 16.875 15.625C16.875 14.5895 16.0355 13.75 15 13.75C13.9645 13.75 13.125 14.5895 13.125 15.625C13.125 16.6605 13.9645 17.5 15 17.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.63379 10.9192L13.3658 14.7059"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3658 5.29419L6.63379 9.08091"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
