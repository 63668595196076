import { API } from 'src/utils/AmplifyApiUtils';

interface UpdateItemsInputOptions {
  entityType: 'dashboard';
  entityItems: Array<any>;
}

export default class EntityClient {
  static GetItems(entityType: 'dashboard') {
    return API.get('AppAPI', `/entities/${entityType}`, {});
  }

  static CreateItems(input: UpdateItemsInputOptions) {
    return API.post('AppAPI', `/entities/${input.entityType}`, {
      body: { data: input.entityItems },
    });
  }

  static UpdateItems(input: UpdateItemsInputOptions) {
    return API.put('AppAPI', `/entities/${input.entityType}`, {
      body: { data: input.entityItems },
    });
  }

  static DeleteItems(input: UpdateItemsInputOptions) {
    return API.del('AppAPI', `/entities/${input.entityType}`, {
      body: { data: input.entityItems },
    });
  }
}
