import {
  useInvalidateRecommendedResourcesMutation,
  useSetVerifiedDomainsMutation,
  useInvalidateReferralsMutation,
} from 'src/services/api';

export const useWebSocketMutations = () => {
  const [setVerifiedDomains] = useSetVerifiedDomainsMutation();
  const [invalidateRecommendedResources] =
    useInvalidateRecommendedResourcesMutation();
  const [invalidateReferrals] = useInvalidateReferralsMutation();

  return {
    setVerifiedDomains,
    invalidateRecommendedResources,
    invalidateReferrals,
  };
};

export type WebSocketMutations = ReturnType<typeof useWebSocketMutations>;
