import React from 'react';
import { Box, createStyles, Divider, makeStyles } from '@material-ui/core';
import { NonHoverBorder } from 'src/theme/colors';

const useStyles = makeStyles(() =>
  createStyles({
    textSeparatorWrapper: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
    },
    textSeparatorLine: {
      width: '-webkit-fill-available',
      background: NonHoverBorder,
      height: '1px',
    },
  }),
);
export interface RowDividerProps {
  noLine?: boolean;
  mt?: number | string;
  mb?: number | string;
  orientation?: 'horizontal' | 'vertical';
  className?: string;
  children?: React.ReactNode;
}

const UnMemoRowDivider: React.FC<RowDividerProps> = ({
  mt = 3,
  mb = 3,
  noLine = false,
  orientation = 'horizontal',
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box mt={mt} mb={mb} width={1} className={className || ''}>
      {!noLine && !children && <Divider orientation={orientation} />}
      {/**
       * When text is defined, render divider with text.
       * Alternative solution for divider @mui-v5
       * https://mui.com/material-ui/react-divider/#dividers-with-text
       */}
      {children && (
        <div className={classes.textSeparatorWrapper}>
          <div className={classes.textSeparatorLine} />
          {children}
          <div className={classes.textSeparatorLine} />
        </div>
      )}
    </Box>
  );
};

const RowDivider = React.memo(UnMemoRowDivider);

export default RowDivider;
