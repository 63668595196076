import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CreditCardFilledIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M0 15.2143C0 15.8773 0.263392 16.5132 0.732233 16.9821C1.20107 17.4509 1.83696 17.7143 2.5 17.7143H17.5C18.163 17.7143 18.7989 17.4509 19.2678 16.9821C19.7366 16.5132 20 15.8773 20 15.2143V8.33929H0V15.2143ZM2.94643 11.8214C2.94643 11.4662 3.08753 11.1256 3.3387 10.8744C3.58986 10.6232 3.93051 10.4821 4.28571 10.4821H6.42857C6.78377 10.4821 7.12442 10.6232 7.37559 10.8744C7.62675 11.1256 7.76786 11.4662 7.76786 11.8214V12.7143C7.76786 13.0695 7.62675 13.4101 7.37559 13.6613C7.12442 13.9125 6.78377 14.0536 6.42857 14.0536H4.28571C3.93051 14.0536 3.58986 13.9125 3.3387 13.6613C3.08753 13.4101 2.94643 13.0695 2.94643 12.7143V11.8214ZM17.5 2H2.5C1.83696 2 1.20107 2.26339 0.732233 2.73223C0.263392 3.20107 0 3.83696 0 4.5V5.66071H20V4.5C20 3.83696 19.7366 3.20107 19.2678 2.73223C18.7989 2.26339 18.163 2 17.5 2Z"
      fill="#212B36"
    />
  </SvgIcon>
);
