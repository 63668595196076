import { Events } from '../constants/webSocketConsts/events';
import { Dispatch } from 'redux';
import { ensureUnreachable } from '../utils/common_utils';
import { productsApi, Product } from '../services/api/productsApi';

const updateCache = (event: Events, items: Product[], draft?: Product[]) => {
  console.info('updateCache', { draft, event, items });
  const [productObj] = items;

  if (!draft) {
    return;
  }

  const upcomingProductIndex = draft.findIndex(
    (item: Product) => item.id === productObj.id,
  );

  switch (event) {
    case Events.Insert: {
      if (upcomingProductIndex !== -1) {
        return;
      }

      draft.unshift(productObj);
      break;
    }
    case Events.Modify:
      if (upcomingProductIndex === -1) {
        return;
      }
      draft.splice(
        0,
        draft.length,
        ...draft.map((item: Product) =>
          item.id === productObj.id ? productObj : item,
        ),
      );
      break;
    case Events.Remove:
      if (upcomingProductIndex === -1) {
        return;
      }
      draft.splice(upcomingProductIndex, 1);
      break;
    case Events.Refresh:
    case Events.DomainVerified:
      return;
    default:
      ensureUnreachable(event);
  }
};

export function handleProductUpdate(
  event: Events,
  items: Product[],
  dispatch: Dispatch<any>,
) {
  const [item] = items;
  dispatch(
    productsApi.util.updateQueryData(
      'getProducts',
      undefined,
      (draft?: Product[]) => {
        updateCache(event, items, draft);
      },
    ),
  );
  dispatch(
    productsApi.util.updateQueryData(
      'getProductById',
      item.id,
      (draft: Product) => {
        Object.assign(draft, item);
      },
    ),
  );
}
