import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PintIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5.17303 6.32812C4.92928 5 4.99764 4.54883 5.05311 3.65664L5.16678 1.83594C5.1767 1.67744 5.24659 1.52867 5.36224 1.41984C5.47789 1.31101 5.63063 1.25029 5.78944 1.25H14.2101C14.3689 1.25029 14.5217 1.31101 14.6373 1.41984C14.753 1.52867 14.8229 1.67744 14.8328 1.83594L14.9465 3.65664C15.0023 4.54961 15.0711 5 14.8269 6.33047C14.5953 7.5918 13.1195 11.5363 13.1195 15.6398V18.4375C13.1195 18.5204 13.0866 18.5999 13.028 18.6585C12.9694 18.7171 12.8899 18.75 12.807 18.75H7.19217C7.10929 18.75 7.0298 18.7171 6.9712 18.6585C6.91259 18.5999 6.87967 18.5204 6.87967 18.4375V15.6395C6.88006 12.0273 5.41639 7.65898 5.17303 6.32812Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.28125 3.75H14.718"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
