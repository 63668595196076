import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BarcodeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15 15.6379L16.3723 15.625C16.6709 15.6237 16.957 15.5044 17.1682 15.2932C17.3794 15.082 17.4987 14.7959 17.5 14.4973V5.50273C17.4987 5.20405 17.3794 4.91798 17.1682 4.70678C16.957 4.49558 16.6709 4.37634 16.3723 4.375L15 4.38789"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 4.375L3.5625 4.38789C2.94219 4.38789 2.5 4.8957 2.5 5.51562V14.5105C2.5 15.1305 2.94219 15.6379 3.5625 15.6379L5 15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 7.5V12.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 6.25V13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.875V13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 6.25V13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7.5V12.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
