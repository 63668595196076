import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CheckboxIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="3.5"
      stroke="currentColor"
      fill="none"
    />
  </SvgIcon>
);
