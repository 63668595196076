import { Dispatch } from 'react';
import { ApiTags } from 'src/services/api';
import { analyticsApi } from 'src/services/api/analyticsApi';

export const AnalyticsEventsAggregateHandler = (
  items: Array<any>,
  dispatch: Dispatch<any>,
) => {
  if (items.length === 0) {
    return;
  }

  const split = items.at(0)?.data?.split('#');
  if (!split || split.length < 2) return;
  const event = split.at(1);

  let tagToInvalidate: ApiTags;
  switch (event) {
    case 'CLIENT':
      tagToInvalidate = ApiTags.analyticsClients;
      break;
    case 'CLIENT_ACTIVATION':
      tagToInvalidate = ApiTags.analyticsActiveClients;
      break;
    case 'SUBSCRIPTION':
      tagToInvalidate = ApiTags.analyticsSubscriptions;
      break;
    default:
      return;
  }

  return dispatch(analyticsApi.util.invalidateTags([tagToInvalidate]));
};

export const AnalyticsRecentlyVisitedHandler = (dispatch: Dispatch<any>) => {
  return dispatch(
    analyticsApi.util.invalidateTags([ApiTags.analyticsRecentlyVisited]),
  );
};
