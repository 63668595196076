import { copilotApp } from 'src/constants/hostnameConsts';

export const baseApiUrl = `https://app-api.${copilotApp.prod}/`;
/**
 * returns the api endpoint url based on the
 * api path/name.
 * @param path: api name (e.g. companies)
 * @returns full endpoint url
 */
export const getApiEndpointUrl = (path: string) =>
  new URL(path, baseApiUrl).toString();
