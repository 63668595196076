import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AviIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.02879 16L6.28986 15.1602H7.65707L7.91814 16H8.94693L7.61066 12H6.33627L5 16H6.02879ZM6.51805 14.4258L6.95703 13.0078H6.98797L7.42888 14.4258H6.51805Z"
      fill="black"
    />
    <path
      d="M9.74076 12H8.66942L10.0057 16H11.2801L12.6164 12H11.545L10.6593 14.9297H10.6265L9.74076 12Z"
      fill="black"
    />
    <path d="M14 12H13.0428V16H14V12Z" fill="black" />
  </SvgIcon>
);
