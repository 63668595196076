import React from 'react';
import { makeStyles, createStyles, Theme, IconButton } from '@material-ui/core';
import { BlackHeadings, white } from 'src/theme/colors';
import { PlayIcon } from 'src/legacy/components/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    thumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    playBackdrop: {
      background: 'rgba(15, 15, 15, 0.2)',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      borderRadius: theme.shape.borderRadius,
    },
    playIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-25px',
      marginLeft: '-25px',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      background: white,
      color: BlackHeadings,
      '&:hover': {
        background: white,
        color: BlackHeadings,
      },
    },
  }),
);

interface ResourceCardThumbnailProps {
  thumbnailSrc: string;
  isVideoThumbnail: boolean;
  onPlayVideo: () => void;
}
export const ResourceCardThumbnail: React.FC<ResourceCardThumbnailProps> = ({
  thumbnailSrc,
  isVideoThumbnail,
  onPlayVideo,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {isVideoThumbnail && (
        /*  eslint-disable-next-line jsx-a11y/click-events-have-key-events */
        <div
          className={classes.playBackdrop}
          onClick={onPlayVideo}
          role="button"
          tabIndex={0}
          data-testid="play-btn-wrapper"
        >
          <IconButton
            className={classes.playIcon}
            color="secondary"
            size="small"
          >
            <PlayIcon style={{ fontSize: 14, marginLeft: 2 }} />
          </IconButton>
        </div>
      )}
      <img
        data-testid="resource-thumbnail"
        className={classes.thumbnail}
        src={thumbnailSrc}
        alt="resource"
      />
    </div>
  );
};
