import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MaleFemaleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.4375 13.125C11.3715 13.125 13.75 10.7465 13.75 7.8125C13.75 4.87849 11.3715 2.5 8.4375 2.5C5.50349 2.5 3.125 4.87849 3.125 7.8125C3.125 10.7465 5.50349 13.125 8.4375 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.4375 13.75V18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 16.25H6.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 4.375V1.25H13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0967 5.02812L16.8748 1.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
