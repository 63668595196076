import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FlashOffIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.8754 17.4999C16.7933 17.5 16.712 17.4839 16.6362 17.4525C16.5604 17.421 16.4915 17.3749 16.4336 17.3167L2.68361 3.56671C2.57134 3.44855 2.50968 3.29121 2.51177 3.12823C2.51386 2.96525 2.57953 2.80954 2.69478 2.69429C2.81003 2.57904 2.96574 2.51337 3.12872 2.51128C3.29169 2.5092 3.44904 2.57086 3.5672 2.68312L17.3172 16.4331C17.4046 16.5205 17.464 16.6319 17.4881 16.7531C17.5122 16.8743 17.4998 16.9999 17.4526 17.1141C17.4053 17.2282 17.3252 17.3258 17.2225 17.3945C17.1198 17.4632 16.999 17.4999 16.8754 17.4999Z"
      fill="currentColor"
    />
    <path
      d="M11.4974 3.29222L10.6349 8.01254C10.6139 8.12712 10.6253 8.2453 10.6679 8.35374C10.7105 8.46217 10.7825 8.55655 10.8759 8.62621C10.9884 8.70834 11.1245 8.75179 11.2638 8.75004H14.9404L13.4415 10.6024C13.4173 10.6324 13.405 10.6703 13.407 10.7088C13.409 10.7473 13.4252 10.7837 13.4525 10.811L14.1193 11.4778C14.1348 11.4933 14.1533 11.5054 14.1738 11.5132C14.1942 11.5211 14.2161 11.5245 14.238 11.5234C14.2599 11.5223 14.2813 11.5165 14.3008 11.5066C14.3203 11.4966 14.3375 11.4827 14.3513 11.4657L16.7263 8.52972C16.7901 8.45281 16.835 8.36199 16.8574 8.26458C16.8798 8.16716 16.8791 8.06585 16.8552 7.96879C16.8206 7.83457 16.7423 7.71569 16.6327 7.63083C16.5231 7.54598 16.3884 7.49997 16.2497 7.50004H11.9994L13.1013 1.46996C13.1033 1.45824 13.1052 1.44691 13.1064 1.43519C13.1263 1.27883 13.0944 1.12026 13.0155 0.983773C12.9367 0.847284 12.8153 0.740403 12.6699 0.679503C12.5245 0.618603 12.3632 0.607042 12.2106 0.646591C12.058 0.686141 11.9226 0.774619 11.8251 0.898473L8.20873 5.36957C8.18438 5.39959 8.17202 5.43756 8.17404 5.47616C8.17606 5.51476 8.19232 5.55124 8.21966 5.57855L8.88646 6.24496C8.90194 6.26046 8.92051 6.27254 8.94096 6.2804C8.96142 6.28826 8.98329 6.29174 9.00517 6.29059C9.02706 6.28945 9.04845 6.28372 9.06797 6.27377C9.0875 6.26382 9.10471 6.24988 9.11849 6.23285L11.4974 3.29222Z"
      fill="currentColor"
    />
    <path
      d="M8.50695 16.7019L9.36632 11.9866C9.38717 11.872 9.37558 11.7539 9.33285 11.6455C9.29013 11.5372 9.21797 11.4429 9.12452 11.3734C9.01201 11.2916 8.87611 11.2483 8.73702 11.2499H5.06124L6.55968 9.39759C6.58392 9.3676 6.59621 9.3297 6.59419 9.29119C6.59217 9.25269 6.57598 9.21629 6.54874 9.18899L5.88195 8.5222C5.86647 8.50676 5.84792 8.49474 5.82751 8.48691C5.8071 8.47909 5.78527 8.47563 5.76343 8.47678C5.7416 8.47792 5.72025 8.48363 5.70077 8.49354C5.68128 8.50345 5.66409 8.51734 5.6503 8.53431L3.27335 11.4702C3.20952 11.5472 3.16461 11.638 3.14222 11.7354C3.11983 11.8328 3.1206 11.9341 3.14445 12.0312C3.1791 12.1654 3.25739 12.2843 3.36699 12.3691C3.4766 12.454 3.6113 12.5 3.74991 12.4999H8.00109L6.90382 18.5296V18.5339C6.87674 18.691 6.90276 18.8527 6.97778 18.9934C7.0528 19.1341 7.17254 19.2458 7.3181 19.3109C7.46366 19.376 7.62675 19.3907 7.78162 19.3528C7.93649 19.3149 8.07432 19.2265 8.17335 19.1015L11.7909 14.6304C11.8153 14.6004 11.8276 14.5624 11.8256 14.5238C11.8236 14.4852 11.8073 14.4487 11.78 14.4214L11.1132 13.755C11.0977 13.7395 11.0791 13.7274 11.0587 13.7196C11.0382 13.7117 11.0164 13.7082 10.9945 13.7094C10.9726 13.7105 10.9512 13.7162 10.9317 13.7262C10.9122 13.7361 10.8949 13.7501 10.8812 13.7671L8.50695 16.7019Z"
      fill="currentColor"
    />
  </SvgIcon>
);
