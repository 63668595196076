import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BicycleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.1563 11.25C14.5691 11.25 13.9951 11.4241 13.5069 11.7503C13.0187 12.0765 12.6382 12.5402 12.4135 13.0827C12.1888 13.6251 12.13 14.222 12.2445 14.7979C12.3591 15.3738 12.6418 15.9028 13.057 16.318C13.4722 16.7332 14.0012 17.0159 14.5771 17.1305C15.153 17.245 15.7499 17.1862 16.2923 16.9615C16.8348 16.7368 17.2985 16.3563 17.6247 15.8681C17.9509 15.3799 18.125 14.8059 18.125 14.2187C18.1225 13.4321 17.809 12.6785 17.2527 12.1223C16.6965 11.566 15.9429 11.2525 15.1563 11.25V11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M4.84375 11.25C4.25659 11.25 3.68261 11.4241 3.1944 11.7503C2.70619 12.0765 2.32568 12.5402 2.10098 13.0827C1.87629 13.6251 1.8175 14.222 1.93205 14.7979C2.04659 15.3738 2.32934 15.9028 2.74453 16.318C3.15972 16.7332 3.6887 17.0159 4.26458 17.1305C4.84046 17.245 5.43737 17.1862 5.97984 16.9615C6.52231 16.7368 6.98597 16.3563 7.31218 15.8681C7.63839 15.3799 7.8125 14.8059 7.8125 14.2187C7.81003 13.4321 7.49646 12.6785 6.94024 12.1223C6.38403 11.566 5.63035 11.2525 4.84375 11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M10 14.0625V10.7031L7.5 9.0625L10.625 5.625L12.1875 8.4375H14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 5.31254C12.6646 5.31311 12.8278 5.28104 12.9799 5.21818C13.1321 5.15532 13.2703 5.06292 13.3865 4.94631C13.5028 4.82971 13.5948 4.69122 13.6571 4.53886C13.7195 4.38649 13.7511 4.22327 13.75 4.05863C13.7518 3.89482 13.721 3.73228 13.6592 3.58053C13.5975 3.42878 13.5061 3.29086 13.3905 3.17484C13.2748 3.05881 13.1372 2.96701 12.9856 2.90481C12.8341 2.8426 12.6716 2.81123 12.5078 2.81254C12.3437 2.81203 12.181 2.84385 12.0292 2.90619C11.8773 2.96854 11.7392 3.06018 11.6228 3.17589C11.3876 3.40958 11.2549 3.72711 11.2539 4.05863C11.2529 4.39015 11.3836 4.70851 11.6173 4.94366C11.851 5.17881 12.1685 5.3115 12.5 5.31254Z"
      fill="currentColor"
    />
  </SvgIcon>
);
