import * as Colors from 'src/theme/colors';

export default {
  root: {
    width: 30,
    height: 20,
    padding: 0,
    display: 'flex',
    position: 'relative',
    '& .MuiButtonBase-root': {
      '&:hover': {
        color: Colors.white,
      },
    },
  },
  switchBase: {
    padding: 0,
    color: Colors.white,
    zIndex: 5,
    marginTop: '3px',
    marginLeft: '3px',
    '&$checked': {
      transform: 'translateX(10px)',
      color: Colors.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: Colors.green,
      },
    },
    '&.Mui-disabled': {
      '&.MuiSwitch-colorSecondary.Mui-disabled': {
        color: '#fff',
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: Colors.NonHoverBorder,
  },
  checked: {},
};
