import { Box, makeStyles, createStyles, BoxProps } from '@material-ui/core';
import React from 'react';

import * as Colors from 'src/theme/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    iconWrapper: {
      backgroundColor: Colors.HoverNonBorderBackground,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      '&>svg': {
        color: Colors.GraySmall,
      },
    },
  }),
);

type IconTileProps = {
  icon?: React.ReactNode;
} & BoxProps;

const IconTile = (props: IconTileProps) => {
  const classes = useStyles();
  const { icon, ...others } = props;
  return (
    <Box
      width={40}
      height={40}
      mb={1.5}
      className={classes.iconWrapper}
      {...others}
    >
      {icon}
    </Box>
  );
};

export default IconTile;
