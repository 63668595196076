import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BusIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.625 4.375H4.375C3.68464 4.375 3.125 4.93464 3.125 5.625V10.625C3.125 11.3154 3.68464 11.875 4.375 11.875H15.625C16.3154 11.875 16.875 11.3154 16.875 10.625V5.625C16.875 4.93464 16.3154 4.375 15.625 4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 11.875H4.375C3.68464 11.875 3.125 12.4346 3.125 13.125V15.625C3.125 16.3154 3.68464 16.875 4.375 16.875H15.625C16.3154 16.875 16.875 16.3154 16.875 15.625V13.125C16.875 12.4346 16.3154 11.875 15.625 11.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 4.375H4.375C4.04376 4.37407 3.72636 4.24208 3.49214 4.00786C3.25792 3.77364 3.12593 3.45624 3.125 3.125V3.125C3.12593 2.79376 3.25792 2.47636 3.49214 2.24214C3.72636 2.00792 4.04376 1.87593 4.375 1.875H15.625C15.9562 1.87593 16.2736 2.00792 16.5079 2.24214C16.7421 2.47636 16.8741 2.79376 16.875 3.125V3.125C16.8741 3.45624 16.7421 3.77364 16.5079 4.00786C16.2736 4.24208 15.9562 4.37407 15.625 4.375V4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 16.875V17.7344C5.625 17.838 5.58385 17.9373 5.51059 18.0106C5.43733 18.0838 5.33798 18.125 5.23438 18.125H4.14062C4.03702 18.125 3.93767 18.0838 3.86441 18.0106C3.79116 17.9373 3.75 17.838 3.75 17.7344V16.875H5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 16.875V17.7344C16.25 17.838 16.2088 17.9373 16.1356 18.0106C16.0623 18.0838 15.963 18.125 15.8594 18.125H14.7656C14.662 18.125 14.5627 18.0838 14.4894 18.0106C14.4162 17.9373 14.375 17.838 14.375 17.7344V16.875H16.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 15C14.7202 15 15 14.7202 15 14.375C15 14.0298 14.7202 13.75 14.375 13.75C14.0298 13.75 13.75 14.0298 13.75 14.375C13.75 14.7202 14.0298 15 14.375 15Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 15C5.97018 15 6.25 14.7202 6.25 14.375C6.25 14.0298 5.97018 13.75 5.625 13.75C5.27982 13.75 5 14.0298 5 14.375C5 14.7202 5.27982 15 5.625 15Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.375V11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 3.125V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 3.125V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
