import { User } from 'src/constants';
import {
  AddressCustomFieldValues,
  Client,
  CustomFieldOption,
} from 'src/store/clients/types';

export interface VisibilityDropdownProps {
  clientCompanyId: string;
  assignees: User[];
  lead: User;
}

export interface DetailSectionProps {
  currentClient: Client;
}

export interface CustomPropertiesListProps {
  setPropertiesMenuAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  openUpdateCustomFieldMenu: (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    updatingField: CustomFieldOption,
  ) => void;
  anchoredCustomFieldId: string | null;
  clientUser: Client;
  customFields: CustomFieldOption[] | null;
  onSaveProperty: (customField: CustomFieldOption | null) => void;
}

export interface ClientDetailsQueryParams {
  clientUserId: string;
  channelId: string;
  activeTabKey?: string;
}

export type CustomFieldValue = string | string[] | AddressCustomFieldValues;

export const isAddressCustomFieldValues = (
  customFieldValue: CustomFieldValue,
): customFieldValue is AddressCustomFieldValues =>
  (customFieldValue as AddressCustomFieldValues).fullAddress !== undefined;

export interface CustomFieldValueData {
  fieldId: string;
  value?: CustomFieldValue;
  updatingClient?: Client;
}
