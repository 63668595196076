import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Drawer,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import { RootState } from 'src/store';
import { DRAWER_WIDTH, BANNER_HEIGHT } from 'src/constants/uiConsts';
import { RouteContext, SidebarThemeContext, SidebarTheme } from 'src/context';
import { GrayThinBorder } from 'src/theme/borders';

export const drawerWidth = DRAWER_WIDTH;

interface DrawerStyleProps {
  showBanner: boolean;
  drawerTheme: SidebarTheme;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileDrawerContainer: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    desktopDrawerContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mobileDrawer: {
      backgroundColor: (props) => props.drawerTheme.sidebarBackgroundColor,
      width: drawerWidth,
      flexShrink: 0,
    },
    desktopDrawer: {
      boxSizing: 'border-box',
      backgroundColor: (props) => props.drawerTheme.sidebarBackgroundColor,
      width: drawerWidth,
      flexShrink: 0,
      marginTop: (props: DrawerStyleProps) =>
        props.showBanner ? `${BANNER_HEIGHT}px` : 0,
    },
    sidebarContainer: {
      zIndex: theme.zIndex.drawer + 2,
    },
    paperAnchorDockedLeft: {
      border: 'none',
    },
    desktopBorderRight: {
      borderRight: GrayThinBorder,
    },
  }),
);

interface DrawerSectionProps {
  open: boolean;
  onClose: () => void;
}

const DrawerSection: React.FC<DrawerSectionProps> = ({
  open,
  onClose,
  children,
}) => {
  const bannerOptions = useSelector(
    (state: RootState) => state.ui.bannerOptions,
  );
  const isClient = useSelector((state: RootState) => state.user.isClient);
  const drawerTheme = React.useContext(SidebarThemeContext);
  const { pathname } = React.useContext(RouteContext);
  const classes = useStyles({
    showBanner: Boolean(bannerOptions),
    drawerTheme,
  });

  return (
    <Box className={classes.sidebarContainer}>
      <Box className={classes.mobileDrawerContainer}>
        <Drawer
          variant="temporary"
          classes={{
            paper: classes.mobileDrawer,
          }}
          anchor="left"
          onClose={onClose}
          open={open}
        >
          {children}
        </Drawer>
      </Box>
      <Box className={classes.desktopDrawerContainer}>
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.desktopDrawer,
            paperAnchorDockedLeft: classNames({
              [classes.paperAnchorDockedLeft]:
                isClient || pathname.includes('/settings'), // removing default Paper border for client and setting page sidebar
              [classes.desktopBorderRight]:
                !isClient && !pathname.includes('/settings'), // add border right on internal user sidebar for non setting pages
            }),
          }}
          anchor="left"
          open
        >
          {children}
        </Drawer>
      </Box>
    </Box>
  );
};

export default DrawerSection;
