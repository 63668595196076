import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TitleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M0 3.75C0 1.67893 1.67893 0 3.75 0H16.25C18.3211 0 20 1.67893 20 3.75V16.25C20 18.3211 18.3211 20 16.25 20H3.75C1.67893 20 0 18.3211 0 16.25V3.75Z"
      fill="#212B36"
    />
    <path
      d="M5.1017 5H14.9068L15 7.56826H14.5847C14.3814 6.76138 14.1215 6.23739 13.8051 5.99631C13.4944 5.75031 12.8362 5.62731 11.8305 5.62731H10.8644V13.3616C10.8644 13.9471 10.9689 14.3112 11.178 14.4539C11.387 14.5916 11.8446 14.6827 12.5508 14.7269V15H7.49153V14.7269C8.22599 14.6777 8.68362 14.572 8.86441 14.4096C9.0452 14.2472 9.13559 13.8389 9.13559 13.1845V5.62731H8.16949C7.20904 5.62731 6.55367 5.74785 6.20339 5.98893C5.85876 6.23001 5.59887 6.75646 5.42373 7.56826H5L5.1017 5Z"
      fill="white"
    />
  </SvgIcon>
);
