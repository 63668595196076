import { useSelector } from 'react-redux';
import React from 'react';
import { Action } from 'kbar';
import { RouteContext } from 'src/context/routeContext';
import { CLIENT_DETAILS_PAGE } from 'src/constants/pathConsts';
import { useRouteAccess } from 'src/routes/routeAccess';
import { useKeyboardShortcuts } from 'src/hooks/useKeyboardShortcuts';
import { RootState } from 'src/store/reduxTypes';
import history from 'src/history';

export const useClientsCommandActions = () => {
  const routeAccess = useRouteAccess();
  const { pathname } = React.useContext(RouteContext);
  const isClientDetailsPage = pathname.includes(CLIENT_DETAILS_PAGE.path);
  const availableClients = useSelector(
    (state: RootState) => state.clients.activeClients,
  );

  const companies = useSelector((state: RootState) => state.clients.companies);

  const goToClientDetails = (clientId: string) => {
    history.push(`${CLIENT_DETAILS_PAGE.path}?clientUserId=${clientId}`);
  };

  /**
   * This transform the loaded client into actions
   * to be registered as kbar actions.
   */
  const clientListActions: Array<Action> = React.useMemo(
    () =>
      availableClients.map((client) => {
        const {
          fallbackColor,
          givenName: firstName,
          familyName: lastName,
          email,
          avatarImageUrl,
        } = client.fields;

        const clientCompany = companies.find(
          (company) => company.id === client.fields.companyId,
        );
        const companyName = clientCompany?.fields.name;

        const clientAction = {
          id: client.id,
          name: `${firstName} ${lastName}`,
          // clients actions are registered under open-client action
          // that means they only render when open-client action is clicked
          parent: 'open-client',
          keywords: `${firstName} ${lastName}  ${companyName || ''} ${email}`,
          perform: () => goToClientDetails(client.id),
          // this holds the additional user data to be rendered in the action menu
          additionalData: {
            pictureUrl: avatarImageUrl,
            companyName: companyName !== 'No company' ? companyName : '',
            fallbackColor,
          },
        };

        return clientAction;
      }),
    [availableClients, companies],
  );
  useKeyboardShortcuts(
    // register open client command/shortcut only when user has permission to access the page
    // and there are clients available in the portal.
    routeAccess.allowClientDetailsPage && availableClients.length > 0
      ? [
          {
            id: 'open-client',
            name: 'Open client...',
            section: isClientDetailsPage ? 'General' : undefined,
            keywords: 'view open client details',
            shortcut: ['o'],
          },
          ...clientListActions,
        ]
      : [],
  );
};
