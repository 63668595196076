export const HELP_CENTER_DESC_FOR_PORTAL_GUIDE =
  'Read more about Copilot’s features';
export const HELP_CENTER_DESC_FOR_JOIN_DISCOURSE_COMMUNITY =
  'Ask questions and help others';
export const HELP_CENTER_DESC_FOR_CONTACT_SUPPORT =
  'Let us know how we can help';
export const HELP_CENTER_DESC_FOR_UPDATES =
  'New updates and improvements to Copilot';
export const HELP_CENTER_DESC_KEYBOARD_SHORTCUTS =
  'Save time and improve efficiency';
