import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BoldIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_21016:86873)">
      <path
        d="M15.097 9.26092C16.25 8.29706 17 6.867 17 5.25C17 2.35492 14.6451 0 11.75 0H3.00012C2.44806 0 2 0.44696 2 0.999939C2 1.55292 2.44806 2.00006 3.00012 2.00006H4.00006V18H3.00012C2.44806 18 2 18.447 2 18.9999C2 19.5529 2.44806 20.0001 3.00012 20.0001H12.2501C15.42 20.0001 18.0001 17.421 18.0001 14.25C18.0001 12.115 16.8281 10.253 15.097 9.26092ZM11.75 2.00006C13.5421 2.00006 15.0001 3.45795 15.0001 5.25C15.0001 7.04205 13.5421 8.49994 11.75 8.49994H6.00012V2.00006H11.75ZM12.2501 18H6.00012V10.5H12.2501C14.3181 10.5 16.0001 12.1829 16.0001 14.25C16.0001 16.3171 14.3181 18 12.2501 18Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_21016:86873">
        <rect width="20" height="20" fill="none" />
      </clipPath>
    </defs>
  </SvgIcon>
);
