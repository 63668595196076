import React from 'react';
import moment from 'moment';
import { FormModal } from 'src/legacy/components/Modals';
import {
  ApiTags,
  ClientId,
  ContractTemplate,
  FieldType,
  InputType,
  ShareContractTemplateInput,
  appAPI,
  useShareContractTemplateMutation,
} from 'src/services/api';
import { processRecipientIds } from 'src/legacy/components/FormsV2/ShareFormModal';
import { UserProfile, getProfileFromUser } from 'src/utils/UserUtils';
import { notify } from 'src/clients/ApiService';
import { RootState } from 'src/store';
import { useShareContract } from 'src/hooks/useShareContract';
import { VariableContractField } from 'src/entities/Contract';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

type ShareContractModalProps = {
  open: boolean;
  selectedTemplate: ContractTemplate | undefined;
  onClose: () => void;
};

/**
 * Generates an error message when the share contract request fails.
 * Depending on the number of users, the error message is updated.
 * @param users - Array of user profiles
 * @returns Error message indicating failed contract sharing
 */
function generateErrorMessageForShareContract(users: UserProfile[]) {
  const userNames = users.map((user) => `${user.firstName} ${user.lastName}`);

  if (userNames.length === 1) {
    return `Something went wrong sending the contract to ${userNames.at(0)}.`;
  }

  const limitedUsers = userNames.slice(0, 2).join(' and ');

  if (userNames.length > 2) {
    return `Something went wrong sending the contract to ${limitedUsers} and ${
      userNames.length - 2
    } more.`;
  }

  return `Something went wrong sending the contract to ${limitedUsers}.`;
}

function isVariableInput(
  field: ContractTemplate['fields'][0],
): field is VariableContractField {
  return field.inputType === InputType.Variable;
}

export const ShareContractModal = ({
  open,
  selectedTemplate,
  onClose,
}: ShareContractModalProps) => {
  const [shareContractTemplate] = useShareContractTemplateMutation();

  const dispatch = useAppDispatch();
  const clients = useAppSelector((state: RootState) => state.clients.clients);

  const variableInputs = selectedTemplate?.fields?.filter(isVariableInput);

  const initialFormValue = {
    recipientIds: [],
    fields: variableInputs
      ? variableInputs.reduce<Record<string, string>>(
          (curr, acc) => ({
            ...curr,
            [acc.label]:
              acc.type === FieldType.Date ? moment().format('MM/DD/YYYY') : '',
          }),
          {},
        )
      : {},
  };

  /**
   * Shares the template with selected clients.
   * @param values - Form values containing recipient IDs and input field values
   */
  const handleShareContract = async (values: {
    recipientIds: ClientId[];
    fields: Record<string, string>;
  }) => {
    if (!selectedTemplate?.id) return;

    const recipientIds = processRecipientIds(values.recipientIds);

    const queries = recipientIds.map((recipientId) => {
      const requestBody: ShareContractTemplateInput = {
        contractTemplateId: selectedTemplate.id,
        recipientId,
        creationMode: 'template',
      };

      // Add variable input values in the request body only when there are variable inputs
      if (values.fields && Object.keys(values.fields).length > 0) {
        requestBody.variableValues = values.fields;
      }
      return shareContractTemplate(requestBody)
        .unwrap()
        .catch((e) => ({
          error: e,
          recipientId,
        }));
    });

    const results = await Promise.all(queries);

    // Filter the results to find requests that resulted in errors
    const erroredRequests = results.filter((r) => 'error' in r);

    // If there are failed requests, identify the clients associated with these failed requests
    if (erroredRequests.length > 0) {
      const failedRequestClients: UserProfile[] = erroredRequests
        .map((request) => {
          const client = clients.find(
            (c) => 'recipientId' in request && c.id === request.recipientId,
          );
          if (!client) return null;
          return getProfileFromUser(client);
        })
        .filter((c): c is UserProfile => c !== null);

      // Notify with an error status and generate an error message based on failed request clients
      notify({
        status: 'error',
        errorMessage:
          generateErrorMessageForShareContract(failedRequestClients),
        dispatch,
      });
      return;
    }

    // invalidate "GET: /contracts-template" to fetch the updated data
    dispatch(
      appAPI.util.invalidateTags([
        ApiTags.contract_templates,
        ApiTags.contracts,
      ]),
    );
    notify({
      status: 'success',
      successMessage: 'Contract template shared successfully.',
      dispatch,
    });
  };

  return (
    <FormModal
      initialFormValue={initialFormValue}
      useFormHook={() =>
        useShareContract({
          variableInputs: variableInputs || [],
          selectedTemplateRef: selectedTemplate?.ref || '',
        })
      }
      open={open}
      title="Share contract"
      onClose={onClose}
      buttonLabel="Send contract"
      cancelButtonLabel="Dismiss"
      handleSave={handleShareContract}
    />
  );
};
