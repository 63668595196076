import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CameraReverseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.693 5.80781L12.6531 4.16484C12.434 3.90938 12.1336 3.75 11.7969 3.75H8.20312C7.86641 3.75 7.56602 3.90938 7.34688 4.16484L6.30703 5.80781C6.08789 6.06367 5.80547 6.25 5.46875 6.25H3.125C2.79348 6.25 2.47554 6.3817 2.24112 6.61612C2.0067 6.85054 1.875 7.16848 1.875 7.5V15C1.875 15.3315 2.0067 15.6495 2.24112 15.8839C2.47554 16.1183 2.79348 16.25 3.125 16.25H16.875C17.2065 16.25 17.5245 16.1183 17.7589 15.8839C17.9933 15.6495 18.125 15.3315 18.125 15V7.5C18.125 7.16848 17.9933 6.85054 17.7589 6.61612C17.5245 6.3817 17.2065 6.25 16.875 6.25H14.5703C14.2324 6.25 13.9121 6.06367 13.693 5.80781Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.84375 6.17188V5.3125H3.90625V6.17188"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1156 11.1413V10.6214C13.1154 10.0285 12.9464 9.44789 12.6285 8.94739C12.3107 8.44689 11.857 8.04716 11.3204 7.79485C10.7839 7.54255 10.1866 7.4481 9.59838 7.52252C9.01016 7.59694 8.45525 7.83717 7.99844 8.21515M6.875 10.1085V10.6284C6.87543 11.2201 7.04383 11.7995 7.3606 12.2992C7.67738 12.799 8.12952 13.1985 8.66443 13.4514C9.19935 13.7042 9.79506 13.8001 10.3823 13.7277C10.9695 13.6553 11.5241 13.4177 11.9816 13.0425"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.65625 10.625L6.875 9.84375L6.09375 10.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9062 10.625L13.125 11.4062L12.3438 10.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
