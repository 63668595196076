import { TablePropertyFields } from 'src/constants';
import {
  LOAD_CRM_PROPERTIES_FIELDS,
  LOAD_CRM_PROPERTIES_ID,
} from 'src/store/tableProperties/types';

export const setCrmTablePropertiesFieldsAction = (
  fields: TablePropertyFields,
) => ({
  type: LOAD_CRM_PROPERTIES_FIELDS,
  payload: fields,
});

export const setCrmTablePropertiesIdAction = (id: string) => ({
  type: LOAD_CRM_PROPERTIES_ID,
  payload: id,
});
