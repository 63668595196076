import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WineIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.5693 3.125H4.43105V3.75C4.43105 3.75 3.41855 10.625 10.0002 10.625C16.5818 10.625 15.5693 3.75 15.5693 3.75V3.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.625V16.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 16.875H6.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 6.25H15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
