import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AppsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5.625 4.0625C5.625 3.19956 4.92544 2.5 4.0625 2.5C3.19956 2.5 2.5 3.19956 2.5 4.0625C2.5 4.92544 3.19956 5.625 4.0625 5.625C4.92544 5.625 5.625 4.92544 5.625 4.0625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M11.5625 4.0625C11.5625 3.19956 10.8629 2.5 10 2.5C9.13706 2.5 8.4375 3.19956 8.4375 4.0625C8.4375 4.92544 9.13706 5.625 10 5.625C10.8629 5.625 11.5625 4.92544 11.5625 4.0625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M17.5 4.0625C17.5 3.19956 16.8004 2.5 15.9375 2.5C15.0746 2.5 14.375 3.19956 14.375 4.0625C14.375 4.92544 15.0746 5.625 15.9375 5.625C16.8004 5.625 17.5 4.92544 17.5 4.0625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M5.625 10C5.625 9.13706 4.92544 8.4375 4.0625 8.4375C3.19956 8.4375 2.5 9.13706 2.5 10C2.5 10.8629 3.19956 11.5625 4.0625 11.5625C4.92544 11.5625 5.625 10.8629 5.625 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M11.5625 10C11.5625 9.13706 10.8629 8.4375 10 8.4375C9.13706 8.4375 8.4375 9.13706 8.4375 10C8.4375 10.8629 9.13706 11.5625 10 11.5625C10.8629 11.5625 11.5625 10.8629 11.5625 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M17.5 10C17.5 9.13706 16.8004 8.4375 15.9375 8.4375C15.0746 8.4375 14.375 9.13706 14.375 10C14.375 10.8629 15.0746 11.5625 15.9375 11.5625C16.8004 11.5625 17.5 10.8629 17.5 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M5.625 15.9375C5.625 15.0746 4.92544 14.375 4.0625 14.375C3.19956 14.375 2.5 15.0746 2.5 15.9375C2.5 16.8004 3.19956 17.5 4.0625 17.5C4.92544 17.5 5.625 16.8004 5.625 15.9375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M11.5625 15.9375C11.5625 15.0746 10.8629 14.375 10 14.375C9.13706 14.375 8.4375 15.0746 8.4375 15.9375C8.4375 16.8004 9.13706 17.5 10 17.5C10.8629 17.5 11.5625 16.8004 11.5625 15.9375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M17.5 15.9375C17.5 15.0746 16.8004 14.375 15.9375 14.375C15.0746 14.375 14.375 15.0746 14.375 15.9375C14.375 16.8004 15.0746 17.5 15.9375 17.5C16.8004 17.5 17.5 16.8004 17.5 15.9375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
