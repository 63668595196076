export type ExtensionType = 'local' | 'global';
export type ModuleType = 'module' | ExtensionType;
export enum ModuleTypeValue {
  MODULE = 'module',
  LOCAL_EXTENSION = 'local',
  GLOBAL_EXTENSION = 'global',
}

export type ModuleIconNameType =
  | 'messages'
  | 'files'
  | 'billing'
  | 'forms'
  | 'knowledge'
  | 'payments'
  | 'contracts';

export type ExtensionIconNameType =
  | 'dashboard'
  | 'keywords'
  | 'backlinks'
  | 'extension'
  | 'linegraph'
  | 'piechart'
  | 'shapes'
  | 'visible'
  | 'cube'
  | 'venn';

export type IconNameType = ExtensionIconNameType | ModuleIconNameType;
