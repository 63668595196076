import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SnackbarProvider } from 'notistack';
import { PersistGate, PersistGateProps } from 'redux-persist/integration/react';
import {
  RouteContext,
  PortalConfigContext,
  FlagsContext,
  PortalConfig,
  SessionData,
} from 'src/context';
import { ChoosePlanAlertDialog } from 'src/legacy/components/ChoosePlanAlertDialog';
import Notifier from 'src/legacy/components/AlertSnackbar/Notifier';
import { ConfigureAuth } from 'src/legacy/components/Auth/ConfigureAuth';
import { AnalyticsTracker } from 'src/legacy/components/Analytics/Tracker';
import { WithUserInitialization } from 'src/legacy/components/App/WithUserInitialization';
import { AppProviders } from 'src/legacy/components/App/AppProviders';

interface AppProps {
  context: {
    insertCss: (...styles: any[]) => () => void;
    pathname: string;
    query: Record<string, any>;
    isMobile: boolean;
    store: any;
    stripeContext: { publishableKey: string };
    portalConfig: any;
    flags: any;
    sessionData: SessionData;
    title?: string;
    description?: string;
    flexibleAuth: boolean;
  };
  children: React.ReactNode;
}
/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */

/**
 * This is the persister gate provider wrapper.
 * When the persistor is not used then it will return the children.
 * Otherwise, it wraps the apps children with the persistor gate.
 * The persistor gate is used to rehydrate the redux store from the local storage.
 * It shows a loading page while the store is being rehydrated.
 */
const PersistGateProvider: React.FC<{
  // the persistor can be undefined when the web app do not use persistency
  // e.g. unauth experience, or the app component is rendered in the server side.
  persistor?: PersistGateProps['persistor'] | undefined;
}> = ({ persistor, children }) => {
  if (!persistor) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <PersistGate persistor={persistor}>{children}</PersistGate>;
};
const App: React.FC<AppProps> = ({ context, children }) => {
  // NOTE: If you need to add or modify header, footer etc. of the app,
  // please do that inside the Layout component.
  const {
    pathname,
    query,
    isMobile,
    insertCss,
    store,
    stripeContext,
    portalConfig,
    flags,
    sessionData,
    title,
    description,
    flexibleAuth,
  } = context;

  const routeContext = React.useMemo(
    () => ({ pathname, query, isMobile, sessionData, title, description }),
    [pathname, query, isMobile, sessionData, title, description],
  );

  const [appConfigurations, setAppConfigurations] =
    React.useState<PortalConfig>(portalConfig);

  const [stripePromise] = React.useState(
    loadStripe(stripeContext.publishableKey),
  );

  const chatClient = React.useMemo(() => {
    const client = StreamChat.getInstance(portalConfig.Chat.ClientId, {
      timeout: 10000,
      baseURL: 'https://chat.stream-io-api.com',
    });
    return client;
  }, []);

  /**
   *
   * @param config {PortalConfig}
   * @description updates the portal configurations
   */
  const updateAppConfigurations = (config: {
    id: string;
    sendEmailsToUninvitedClients: boolean;
    clientExperienceEnabled: boolean;
    clientInvitesDisabled: boolean;
    disableCompanies: boolean;
    disableGoogleSignIn: boolean;
    gaMeasurementId: string;
    signupDisabled: boolean;
    signoutRedirectUrl: string;
  }) => {
    setAppConfigurations((previous) => ({
      ...previous,
      clientExperienceEnabled: config.clientExperienceEnabled,
      id: config.id,
      sendEmailsToUninvitedClients: config.sendEmailsToUninvitedClients,
      metadata: {
        ...previous.metadata,
        gaMeasurementId: config.gaMeasurementId,
      },
      SignoutRedirectURL: config.signoutRedirectUrl,
      features: {
        ...previous.features,
        disableCompanies: config.disableCompanies,
        disableGoogleSignIn: config.disableGoogleSignIn,
        signupDisabled: config.signupDisabled,
        clientInvitesDisabled: config.clientInvitesDisabled,
      },
    }));
  };

  const configurations = React.useMemo(
    () => ({
      ...appConfigurations,
      updatePortalConfig: updateAppConfigurations,
    }),
    [appConfigurations],
  );

  return (
    <PortalConfigContext.Provider value={configurations}>
      <AppProviders insertCss={insertCss}>
        <ReduxProvider store={store}>
          <PersistGateProvider persistor={store.persistor}>
            <Elements stripe={stripePromise}>
              <Chat client={chatClient} theme="portal-chat">
                <FlagsContext.Provider value={flags}>
                  <RouteContext.Provider value={routeContext}>
                    <AnalyticsTracker />
                    <ChoosePlanAlertDialog />
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      maxSnack={2}
                    >
                      <Notifier />
                      <ConfigureAuth />
                      <WithUserInitialization flexibleAuth={flexibleAuth}>
                        {React.Children.only(children)}
                      </WithUserInitialization>
                    </SnackbarProvider>
                  </RouteContext.Provider>
                </FlagsContext.Provider>
              </Chat>
            </Elements>
          </PersistGateProvider>
        </ReduxProvider>
      </AppProviders>
    </PortalConfigContext.Provider>
  );
};

export default App;
