import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SnowIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 1.25V18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2545 3.125C11.574 3.53513 10.7943 3.75127 9.9998 3.75C9.2053 3.75127 8.42558 3.53513 7.74512 3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.74512 16.875C8.42609 16.466 9.20547 16.25 9.9998 16.25C10.7941 16.25 11.5735 16.466 12.2545 16.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5773 5.625L2.42188 14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0811 8.51562C16.3864 8.1303 15.8095 7.5632 15.4124 6.87507C15.0153 6.18695 14.8129 5.40374 14.8269 4.60938"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91895 11.4844C3.61374 11.8697 4.19061 12.4368 4.58772 13.1249C4.98484 13.8131 5.18724 14.5963 5.17324 15.3906"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.42188 5.625L17.5773 14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91895 8.51562C3.61374 8.1303 4.19061 7.5632 4.58772 6.87507C4.98484 6.18695 5.18724 5.40374 5.17324 4.60938"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0811 11.4844C16.3864 11.8697 15.8095 12.4368 15.4124 13.1249C15.0153 13.8131 14.8129 14.5963 14.8269 15.3906"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
