import * as React from 'react';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';

// Note: We were doing a rounding to the nearest multiple of 5
// here that made the data display incorrectly. See this PR
// https://github.com/copilot-platforms/copilot/pull/3209/
export function YAxisLabel({
  labels,
}: {
  labels: { max: number; mid: number; min: number };
}) {
  const getPositionClassname = (idx: number) => {
    // positions the labels at the top, middle, and bottom of the chart
    if (idx === 0) return 'top-0 translate-y-[-50%] right-0';
    if (idx === 1) return 'top-1/2 translate-y-[-50%] right-0';
    if (idx === 2) return 'bottom-0 translate-y-[50%] right-0';
    return '';
  };

  return (
    <div className="relative w-full h-full text-gray-500 align-right">
      <div key="y-label-max" className={`absolute ${getPositionClassname(0)}`}>
        <BaseTypography fontType="12Regular" className="text-right">
          {Intl.NumberFormat('en-us').format(labels.max)}
        </BaseTypography>
      </div>
      <div key="y-label-mid" className={`absolute ${getPositionClassname(1)}`}>
        <BaseTypography fontType="12Regular" className="text-right">
          {Intl.NumberFormat('en-us').format(labels.mid)}
        </BaseTypography>
      </div>
      <div key="y-label-min" className={`absolute ${getPositionClassname(2)}`}>
        <BaseTypography fontType="12Regular" className="text-right">
          {Intl.NumberFormat('en-us').format(labels.min)}
        </BaseTypography>
      </div>
    </div>
  );
}
