import axios from 'axios';

export interface BatchResult<T> {
  succeededIds: string[];
  failedIds: string[];
  createdItems?: Array<T>;
  updatedItems?: Array<T>;
}

export default class ApiUtils {
  static IsBatchCreateResultSuccessful<T>(result: BatchResult<T>) {
    if (
      result &&
      result.succeededIds &&
      result.succeededIds.length > 0 &&
      result.createdItems &&
      result.createdItems.length &&
      (!result.failedIds || result.failedIds.length < 1)
    ) {
      return true;
    }
    return false;
  }

  static IsBatchUpdateResultSuccessful<T>(result: BatchResult<T>) {
    if (
      result &&
      result.succeededIds &&
      result.succeededIds.length &&
      (!result.failedIds || result.failedIds.length < 1)
    ) {
      return true;
    }
    return false;
  }
}

interface getAxiosInstanceInput {
  apiDomain: string;
  apiUrl?: string; // override url to use instead of setting url based on stage
}

/**
 * When input has an api url use that to create axios instance (helpful for local dev)
 * otherwise use shared api urls based on stage
 * @param input params to configure axios instance
 * @returns baseURL based on the input
 */
export const getBaseURL = (input: getAxiosInstanceInput) => {
  const { apiDomain, apiUrl } = input;
  let baseURL = apiUrl;
  if (!baseURL) {
    // if api url from input is empty, construct api url based on apiDomain
    baseURL = `https://app-api.${apiDomain}`;
  }
  return baseURL;
};

/**
 * When input has an api url use that to create axios instance (helpful for local dev)
 * otherwise use shared api urls based on stage
 * @param input params to get axios instance
 * @returns axios instance to call api
 */
export const getAxiosInstance = (input: getAxiosInstanceInput) => {
  const baseURL = getBaseURL(input);
  return axios.create({ baseURL });
};
