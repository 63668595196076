import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArrowRightIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.7279 1.66187L19.066 10M19.066 10L10.7279 18.3382M19.066 10L1 10"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
