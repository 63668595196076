import * as React from 'react';
import {
  makeStyles,
  Theme,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import clsx from 'clsx';
import InfoTooltip from 'src/legacy/components/Tooltip';

interface ActivatorProps {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  label?: React.ReactNode;
  name?: string;
  disabled?: boolean;
  isFixedWidth?: boolean;
  tooltip?: string;
  className?: string;
}

interface StyleProps {
  isFixedWidth: boolean;
  label?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    flexDirection: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit',
  },
  formControl: {
    width: (props: StyleProps) => (props.isFixedWidth ? 200 : 'initial'),
    marginLeft: 0,
    marginRight: 0,
    justifyContent: 'space-between',
  },
  label: {
    display: (props: StyleProps) => (props.label ? 'flex' : 'none'),
    marginRight: theme.spacing(2),
    justifyContent: 'space-around',
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
}));

const Activator: React.FC<ActivatorProps> = ({
  checked,
  label,
  name,
  onChange,
  onBlur,
  disabled = false,
  isFixedWidth = true,
  tooltip = '',
  className,
}) => {
  const classes = useStyles({ isFixedWidth, label });

  return (
    <InfoTooltip label={tooltip}>
      <FormGroup className={clsx(classes.formGroup, className)}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={onChange}
              onBlur={onBlur}
              color={disabled ? 'secondary' : 'default'}
              name={name || 'activator'}
              disabled={disabled}
              inputProps={{
                'data-testid': `${name}Toggle`,
              }}
            />
          }
          label={label}
          labelPlacement="start"
          classes={{
            labelPlacementStart: classes.formControl,
            label: `${classes.label}`,
          }}
        />
      </FormGroup>
    </InfoTooltip>
  );
};

export default Activator;
