import { useEffect } from 'react';
import { KeyboardKeys } from 'src/constants/shortcutConsts';
import useNavigate from 'src/hooks/useNavigate';
import { useAppSelector } from 'src/hooks/useStore';

interface Options {
  disabled?: boolean;
}

export const useEscNavigation = (path: string, options?: Options) => {
  const { navigate } = useNavigate();
  const isFormPageChanged = useAppSelector(
    (state) => state.ui.isFormPageChanged,
  );

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === KeyboardKeys.Esc) {
        if (options?.disabled) return;
        navigate(path);
      }
    };
    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isFormPageChanged, options]);
};
