import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ChatboxEllipsesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.9375 2.5H4.0625C3.48285 2.50165 2.9274 2.73265 2.51753 3.14253C2.10765 3.5524 1.87665 4.10785 1.875 4.6875V12.1875C1.87665 12.7672 2.10765 13.3226 2.51753 13.7325C2.9274 14.1424 3.48285 14.3734 4.0625 14.375H5.625V17.5L9.28594 14.4477C9.34216 14.4007 9.41308 14.375 9.48633 14.375H15.9375C16.5172 14.3734 17.0726 14.1424 17.4825 13.7325C17.8924 13.3226 18.1234 12.7672 18.125 12.1875V4.6875C18.1234 4.10785 17.8924 3.5524 17.4825 3.14253C17.0726 2.73265 16.5172 2.50165 15.9375 2.5V2.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 9.6875C6.94036 9.6875 7.5 9.12786 7.5 8.4375C7.5 7.74714 6.94036 7.1875 6.25 7.1875C5.55964 7.1875 5 7.74714 5 8.4375C5 9.12786 5.55964 9.6875 6.25 9.6875Z"
      fill="currentColor"
    />
    <path
      d="M10 9.6875C10.6904 9.6875 11.25 9.12786 11.25 8.4375C11.25 7.74714 10.6904 7.1875 10 7.1875C9.30964 7.1875 8.75 7.74714 8.75 8.4375C8.75 9.12786 9.30964 9.6875 10 9.6875Z"
      fill="currentColor"
    />
    <path
      d="M13.75 9.6875C14.4404 9.6875 15 9.12786 15 8.4375C15 7.74714 14.4404 7.1875 13.75 7.1875C13.0596 7.1875 12.5 7.74714 12.5 8.4375C12.5 9.12786 13.0596 9.6875 13.75 9.6875Z"
      fill="currentColor"
    />
  </SvgIcon>
);
