import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DollarIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.1982 3.30469C12.9587 4.00781 13.3779 4.90625 13.4561 6H13.9561H14.9733H15.4733C15.4212 5.17708 15.192 4.44531 14.7858 3.80469C14.3795 3.16406 13.8301 2.66146 13.1374 2.29688C12.4923 1.95736 11.7501 1.77593 10.9108 1.75259V1.99967C11.159 2.07956 11.3955 2.17863 11.6201 2.29688C11.6392 2.30693 11.6582 2.31709 11.6771 2.32735C12.4853 2.4662 13.1648 2.79198 13.7155 3.30469C14.3719 3.91162 14.7741 4.66408 14.9221 5.56207H13.9104C13.8152 4.91514 13.6013 4.32935 13.2686 3.80469C12.8735 3.18173 12.343 2.68928 11.6771 2.32735C11.4334 2.28548 11.1779 2.26061 10.9108 2.25274V2.5233C11.3983 2.69902 11.8275 2.95949 12.1982 3.30469Z"
      fill="currentColor"
    />
    <path
      d="M8.89355 9.48707V8.96749C8.70022 8.88569 8.51847 8.80018 8.3483 8.71094C7.92642 8.48177 7.57486 8.22656 7.29361 7.94531C7.01757 7.65885 6.81184 7.34375 6.67642 7C6.541 6.65625 6.4733 6.28125 6.4733 5.875C6.4733 5.17188 6.65819 4.54948 7.02798 4.00781C7.39778 3.46094 7.90298 3.03125 8.54361 2.71875C8.65754 2.66317 8.77419 2.61254 8.89355 2.56685V2.25792C8.64565 2.27067 8.4062 2.29879 8.17521 2.3423C7.51023 2.6965 6.98198 3.17094 6.59048 3.76563C6.17903 4.38542 5.9733 5.08854 5.9733 5.875C5.9733 6.80208 6.28319 7.59115 6.90298 8.24219C7.33842 8.69577 7.9268 9.0874 8.66812 9.41711L8.89355 9.48707Z"
      fill="currentColor"
    />
    <path
      d="M10.9108 10.1366C11.0745 10.1983 11.2317 10.2619 11.3823 10.3274L11.5358 10.375C12.4004 10.6406 13.1009 10.9401 13.6374 11.2734C14.1738 11.6068 14.567 11.9922 14.817 12.4297C15.067 12.862 15.192 13.3646 15.192 13.9375C15.192 14.6927 14.9993 15.3568 14.6139 15.9297C14.2337 16.5026 13.6973 16.9505 13.0045 17.2734C12.6547 17.4351 12.2752 17.5557 11.866 17.6351C11.8262 17.6555 11.7859 17.6756 11.7451 17.6953C11.4815 17.8226 11.2034 17.928 10.9108 18.0115V18.244C11.7928 18.2094 12.5767 18.0265 13.2624 17.6953C14.028 17.3255 14.6243 16.8151 15.0514 16.1641C15.4785 15.513 15.692 14.7708 15.692 13.9375C15.692 13.4427 15.6113 12.9948 15.4499 12.5938C15.2884 12.1875 15.041 11.8203 14.7077 11.4922C14.3795 11.1589 13.9629 10.8594 13.4577 10.5938C12.9525 10.3281 12.3535 10.0885 11.6608 9.875L10.9108 9.64224V10.1366Z"
      fill="currentColor"
    />
    <path
      d="M13.1904 11.4922C12.8623 11.1589 12.4456 10.8594 11.9404 10.5938C11.7656 10.5018 11.5796 10.413 11.3823 10.3274L10.9108 10.181V10.6837C11.379 10.8669 11.7821 11.0634 12.1201 11.2734C12.6566 11.6068 13.0498 11.9922 13.2998 12.4297C13.5498 12.862 13.6748 13.3646 13.6748 13.9375C13.6748 14.6927 13.4821 15.3568 13.0967 15.9297C12.7165 16.5026 12.18 16.9505 11.4873 17.2734C11.3033 17.3585 11.1112 17.4321 10.9108 17.4944V17.7437C11.2454 17.7299 11.5638 17.6937 11.866 17.6351C12.5738 17.2708 13.1298 16.7804 13.5342 16.1641C13.9613 15.513 14.1748 14.7708 14.1748 13.9375C14.1748 13.4427 14.0941 12.9948 13.9326 12.5938C13.7712 12.1875 13.5238 11.8203 13.1904 11.4922Z"
      fill="currentColor"
    />
    <path
      d="M9.8483 9.3125C9.69124 9.26396 9.53965 9.21365 9.39355 9.16158V9.64224L10.1436 9.875C10.2342 9.90296 10.3233 9.93136 10.4108 9.96021V9.48707L9.8483 9.3125Z"
      fill="currentColor"
    />
    <path
      d="M9.39355 10.181L10.0186 10.375C10.1533 10.4164 10.284 10.4586 10.4108 10.5016V10.0259L9.7233 9.8125C9.61085 9.77704 9.50094 9.74056 9.39355 9.70305V10.181Z"
      fill="currentColor"
    />
    <path
      d="M9.96236 1.7944C9.77799 1.77194 9.58839 1.75801 9.39355 1.75259V1.89257C9.57818 1.85056 9.76778 1.81784 9.96236 1.7944Z"
      fill="currentColor"
    />
    <path
      d="M10.4108 1.86796V1.75722C10.2585 1.76434 10.109 1.77673 9.96236 1.7944C10.1154 1.81305 10.2649 1.83757 10.4108 1.86796Z"
      fill="currentColor"
    />
    <path
      d="M10.9108 1.99967V1.75259C10.8488 1.75086 10.7863 1.75 10.7233 1.75C10.6178 1.75 10.5137 1.75241 10.4108 1.75722V1.86796C10.5825 1.90373 10.7491 1.94763 10.9108 1.99967Z"
      fill="currentColor"
    />
    <path
      d="M11.6201 2.29688C11.3955 2.17863 11.159 2.07956 10.9108 1.99967V2.25274C11.1779 2.26061 11.4334 2.28548 11.6771 2.32735C11.6582 2.31709 11.6392 2.30693 11.6201 2.29688Z"
      fill="currentColor"
    />
    <path
      d="M5.51074 4.00781C5.88053 3.46094 6.38574 3.03125 7.02637 2.71875C7.38272 2.54492 7.76567 2.41943 8.17521 2.3423C8.20911 2.32424 8.24337 2.3065 8.27798 2.28906C8.47587 2.19012 8.68106 2.1048 8.89355 2.0331V1.75722C8.10727 1.79399 7.39633 1.97127 6.76074 2.28906C6.0472 2.64844 5.4847 3.14063 5.07324 3.76563C4.66178 4.38542 4.45605 5.08854 4.45605 5.875C4.45605 6.80208 4.76595 7.59115 5.38574 8.24219C6.01074 8.89323 6.95085 9.41667 8.20605 9.8125L8.89355 10.0259V9.51352C8.8169 9.48199 8.74176 9.44986 8.66812 9.41711L8.33105 9.3125C7.75814 9.13542 7.25814 8.9349 6.83105 8.71094C6.40918 8.48177 6.05762 8.22656 5.77637 7.94531C5.50033 7.65885 5.2946 7.34375 5.15918 7C5.02376 6.65625 4.95605 6.28125 4.95605 5.875C4.95605 5.17188 5.14095 4.54948 5.51074 4.00781Z"
      fill="currentColor"
    />
    <path
      d="M8.17521 2.3423C8.4062 2.29879 8.64565 2.27067 8.89355 2.25792V2.0331C8.68106 2.1048 8.47587 2.19012 8.27798 2.28906C8.24337 2.3065 8.20911 2.32424 8.17521 2.3423Z"
      fill="currentColor"
    />
    <path
      d="M10.4108 2.25792C10.2601 2.26567 10.1125 2.2791 9.96801 2.29822C10.1199 2.31833 10.2675 2.34473 10.4108 2.37741V2.25792Z"
      fill="currentColor"
    />
    <path
      d="M9.96801 2.29822C9.78295 2.27373 9.59147 2.25857 9.39355 2.25274V2.40838C9.57921 2.36106 9.77069 2.32434 9.96801 2.29822Z"
      fill="currentColor"
    />
    <path
      d="M11.7451 17.6953C11.7859 17.6756 11.8262 17.6555 11.866 17.6351C11.5638 17.6937 11.2454 17.7299 10.9108 17.7437V18.0115C11.2034 17.928 11.4815 17.8226 11.7451 17.6953Z"
      fill="currentColor"
    />
    <path
      d="M9.84294 17.7106C10.0265 17.7306 10.2158 17.7429 10.4108 17.7477V17.6226C10.2276 17.6603 10.0383 17.6897 9.84294 17.7106Z"
      fill="currentColor"
    />
    <path
      d="M9.8379 18.2132C10.0237 18.2318 10.2147 18.2434 10.4108 18.2479V18.1301C10.2251 18.1657 10.0341 18.1934 9.8379 18.2132Z"
      fill="currentColor"
    />
    <path
      d="M9.39355 18.1534V18.244C9.54455 18.238 9.69266 18.2278 9.8379 18.2132C9.68638 18.1979 9.53826 18.178 9.39355 18.1534Z"
      fill="currentColor"
    />
    <path
      d="M9.39355 17.7437C9.54674 17.7374 9.69654 17.7264 9.84294 17.7106C9.68914 17.694 9.53935 17.672 9.39355 17.6447V17.7437Z"
      fill="currentColor"
    />
    <path
      d="M6.52637 17.7422C7.20836 18.0588 7.99742 18.2274 8.89355 18.2479V18.0459C8.59384 17.9674 8.31052 17.8661 8.04361 17.7422C7.97116 17.708 7.90021 17.6726 7.83075 17.636C7.45197 17.5617 7.10051 17.4513 6.77637 17.3047C6.12533 17.0078 5.6097 16.5964 5.22949 16.0703C4.91239 15.6316 4.70941 15.1367 4.62055 14.5857H5.63281C5.72332 15.2311 5.94879 15.8119 6.30923 16.3281C6.69389 16.8676 7.20106 17.3035 7.83075 17.636C8.16389 17.7012 8.51815 17.7385 8.89355 17.7477V17.5257C8.68413 17.4646 8.48415 17.3909 8.29361 17.3047C7.64257 17.0078 7.12694 16.5964 6.74673 16.0703C6.36653 15.5443 6.15038 14.9375 6.0983 14.25H4.08105C4.13314 15.0312 4.37012 15.724 4.79199 16.3281C5.21908 16.9271 5.7972 17.3984 6.52637 17.7422Z"
      fill="currentColor"
    />
    <path
      d="M8.04361 17.7422C8.31052 17.8661 8.59384 17.9674 8.89355 18.0459V17.7477C8.51815 17.7385 8.16389 17.7012 7.83075 17.636C7.90021 17.6726 7.97116 17.708 8.04361 17.7422Z"
      fill="currentColor"
    />
    <path
      d="M9.96236 1.7944C9.76778 1.81784 9.57818 1.85056 9.39355 1.89257V2.25274C9.59147 2.25857 9.78295 2.27373 9.96801 2.29822C10.1125 2.2791 10.2601 2.26567 10.4108 2.25792V1.86796C10.2649 1.83757 10.1154 1.81305 9.96236 1.7944Z"
      fill="currentColor"
    />
    <path
      d="M10.4108 17.7477C10.2158 17.7429 10.0265 17.7306 9.84294 17.7106C9.69654 17.7264 9.54674 17.7374 9.39355 17.7437V18.1534C9.53826 18.178 9.68638 18.1979 9.8379 18.2132C10.0341 18.1934 10.2251 18.1657 10.4108 18.1301V17.7477Z"
      fill="currentColor"
    />
    <path
      d="M14.9221 5.56207C14.7741 4.66408 14.3719 3.91162 13.7155 3.30469C13.1648 2.79198 12.4853 2.4662 11.6771 2.32735C12.343 2.68928 12.8735 3.18173 13.2686 3.80469C13.6013 4.32935 13.8152 4.91514 13.9104 5.56207H14.9221Z"
      fill="currentColor"
    />
    <path
      d="M7.02637 2.71875C6.38574 3.03125 5.88053 3.46094 5.51074 4.00781C5.14095 4.54948 4.95605 5.17188 4.95605 5.875C4.95605 6.28125 5.02376 6.65625 5.15918 7C5.2946 7.34375 5.50033 7.65885 5.77637 7.94531C6.05762 8.22656 6.40918 8.48177 6.83105 8.71094C7.25814 8.9349 7.75814 9.13542 8.33105 9.3125L8.66812 9.41711C7.9268 9.0874 7.33842 8.69577 6.90298 8.24219C6.28319 7.59115 5.9733 6.80208 5.9733 5.875C5.9733 5.08854 6.17903 4.38542 6.59048 3.76563C6.98198 3.17094 7.51023 2.6965 8.17521 2.3423C7.76567 2.41943 7.38272 2.54492 7.02637 2.71875Z"
      fill="currentColor"
    />
    <path
      d="M11.9404 10.5938C12.4456 10.8594 12.8623 11.1589 13.1904 11.4922C13.5238 11.8203 13.7712 12.1875 13.9326 12.5938C14.0941 12.9948 14.1748 13.4427 14.1748 13.9375C14.1748 14.7708 13.9613 15.513 13.5342 16.1641C13.1298 16.7804 12.5738 17.2708 11.866 17.6351C12.2752 17.5557 12.6547 17.4351 13.0045 17.2734C13.6973 16.9505 14.2337 16.5026 14.6139 15.9297C14.9993 15.3568 15.192 14.6927 15.192 13.9375C15.192 13.3646 15.067 12.862 14.817 12.4297C14.567 11.9922 14.1738 11.6068 13.6374 11.2734C13.1009 10.9401 12.4004 10.6406 11.5358 10.375L11.3823 10.3274C11.5796 10.413 11.7656 10.5018 11.9404 10.5938Z"
      fill="currentColor"
    />
    <path
      d="M5.22949 16.0703C5.6097 16.5964 6.12533 17.0078 6.77637 17.3047C7.10051 17.4513 7.45197 17.5617 7.83075 17.636C7.20106 17.3035 6.69389 16.8676 6.30923 16.3281C5.94879 15.8119 5.72332 15.2311 5.63281 14.5857H4.62055C4.70941 15.1367 4.91239 15.6316 5.22949 16.0703Z"
      fill="currentColor"
    />
    <path
      d="M10.0186 10.375L9.39355 10.181V17.6447C9.53935 17.672 9.68914 17.694 9.84294 17.7106C10.0383 17.6897 10.2276 17.6603 10.4108 17.6226V10.5016C10.284 10.4586 10.1533 10.4164 10.0186 10.375Z"
      fill="currentColor"
    />
    <path
      d="M10.4108 18.2479C10.2147 18.2434 10.0237 18.2318 9.8379 18.2132C9.69266 18.2278 9.54455 18.238 9.39355 18.244V20H10.4108V18.2479Z"
      fill="currentColor"
    />
    <path
      d="M9.39355 9.16158C9.53965 9.21365 9.69124 9.26396 9.8483 9.3125L10.4108 9.48707V2.37741C10.2675 2.34473 10.1199 2.31833 9.96801 2.29822C9.77069 2.32434 9.57921 2.36106 9.39355 2.40838V9.16158Z"
      fill="currentColor"
    />
    <path
      d="M9.39355 1.75259C9.58839 1.75801 9.77799 1.77194 9.96236 1.7944C10.109 1.77673 10.2585 1.76434 10.4108 1.75722V0H9.39355V1.75259Z"
      fill="currentColor"
    />
    <path
      d="M9.39355 9.64224V9.70305C9.50094 9.74056 9.61085 9.77704 9.7233 9.8125L10.4108 10.0259V9.96021C10.3233 9.93136 10.2342 9.90296 10.1436 9.875L9.39355 9.64224Z"
      fill="currentColor"
    />
    <path
      d="M11.3823 10.3274C11.2317 10.2619 11.0745 10.1983 10.9108 10.1366V10.181L11.3823 10.3274Z"
      fill="currentColor"
    />
    <path
      d="M8.89355 9.48707L8.66812 9.41711C8.74176 9.44986 8.8169 9.48199 8.89355 9.51352V9.48707Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4108 17.6226V17.7477V18.1301V18.2479C10.4728 18.2493 10.5353 18.25 10.5983 18.25C10.7038 18.25 10.808 18.248 10.9108 18.244V18.0115V17.7437V17.4944C10.7494 17.5445 10.5828 17.5873 10.4108 17.6226ZM10.9108 10.181V10.6837C10.7517 10.6215 10.585 10.5608 10.4108 10.5016V10.0259V9.96021V9.48707L10.9108 9.64224V10.1366V10.181ZM10.9108 2.25274V2.5233C10.7504 2.4655 10.5838 2.41687 10.4108 2.37741V2.25792V1.86796C10.5825 1.90373 10.7491 1.94763 10.9108 1.99967V2.25274Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.39355 18.1534V18.244C9.29072 18.248 9.18656 18.25 9.08105 18.25C9.01804 18.25 8.95554 18.2493 8.89355 18.2479V18.0459V17.7477V17.5257C9.05464 17.5728 9.2213 17.6124 9.39355 17.6447V17.7437V18.1534ZM8.89355 10.0259L9.39355 10.181V9.70305V9.64224V9.16158C9.21907 9.0994 9.05241 9.0347 8.89355 8.96749V9.48707V9.51352V10.0259ZM9.39355 2.40838C9.2219 2.45214 9.05523 2.50496 8.89355 2.56685V2.25792V2.0331V1.75722C8.99643 1.75241 9.1006 1.75 9.20605 1.75C9.26908 1.75 9.33158 1.75086 9.39355 1.75259V1.89257V2.25274V2.40838Z"
      fill="currentColor"
    />
  </SvgIcon>
);
