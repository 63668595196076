import React from 'react';
import { TabNavigationShortcutsValues } from 'src/legacy/components/ShortcutsDrawer/shortcutsSidebarConsts';
import { ChannelOptionData } from 'src/legacy/components/Channels/ChannelSidebarItem';
import { getModKeyState } from 'src/utils/KeyEventUtils';
import { useOSDetector } from 'src/hooks/useOSDetector';

/**
 * This hook handles the channel navigation shortcut.
 * @param channels: channels array
 * @param currentChannelIndex: current active channel index
 * @param selectChannelHandler: function to select channel
 * @param deleteChannelHandler: function to delete channel
 */

export const useChannelNavigationShortcut = <T = ChannelOptionData>(
  channels: Array<T>,
  currentChannelIndex: number,
  selectChannelHandler: (channel: T) => void,
  deleteChannelHandler?: (channel: T) => void,
) => {
  const { isOSDeleteKeyPressed } = useOSDetector();
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      let destinationChannel;
      let tabNavTrigger = false;
      if (getModKeyState(event, 'Control') || getModKeyState(event, 'Meta')) {
        if (event.key === TabNavigationShortcutsValues.SELECT_NEXT_KEY) {
          // For `Notification Inbox`, no item is selected by default. In this case, we select the first item
          if (currentChannelIndex === -1) {
            const firstChannel = channels.at(0);
            if (firstChannel) {
              selectChannelHandler(firstChannel);
            }
          }
          tabNavTrigger = true;
          const nextChannel = channels[currentChannelIndex + 1];
          // go to next channel
          if (nextChannel) {
            destinationChannel = nextChannel;
            selectChannelHandler(destinationChannel);
          }
        }

        if (event.key === TabNavigationShortcutsValues.SELECT_PREVIOUS_KEY) {
          tabNavTrigger = true;
          // go to previous channel
          const previousChannel = channels[currentChannelIndex - 1];
          if (previousChannel) {
            destinationChannel = previousChannel;
            selectChannelHandler(destinationChannel);
          }
        }
      }

      // if tab nav trigger is true, prevent default behavior of browser back/next navigation
      if (tabNavTrigger) {
        event.preventDefault();
      }

      if (destinationChannel) {
        selectChannelHandler(destinationChannel);
      }

      if (isOSDeleteKeyPressed(event) && deleteChannelHandler) {
        deleteChannelHandler(channels[currentChannelIndex]);
      }
    },
    [currentChannelIndex, channels],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, false);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);
};
