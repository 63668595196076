import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ErrorCloseMarkIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="10" fill="#CC0000" />
    <path
      d="M14 6L6 14M14 14L6 6L14 14Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
