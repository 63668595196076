import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BeerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.75 7.8125V17.1875C13.7488 17.6015 13.5837 17.9982 13.291 18.291C12.9982 18.5837 12.6015 18.7488 12.1875 18.75H5.3125C4.89848 18.7488 4.50177 18.5837 4.20901 18.291C3.91625 17.9982 3.75124 17.6015 3.75 17.1875V8.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 8.75H15.3125C15.8922 8.75165 16.4476 8.98265 16.8575 9.39253C17.2674 9.8024 17.4984 10.3578 17.5 10.9375V14.0625C17.4984 14.6422 17.2674 15.1976 16.8575 15.6075C16.4476 16.0174 15.8922 16.2484 15.3125 16.25H13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.75 10V16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 10V16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 10V16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 4.375C12.9973 4.375 13.4742 4.57254 13.8258 4.92417C14.1775 5.27581 14.375 5.75272 14.375 6.25C14.375 6.74728 14.1775 7.22419 13.8258 7.57583C13.4742 7.92746 12.9973 8.125 12.5 8.125C11.9824 8.125 11.3551 7.83945 11.0156 7.5H6.25C5.9375 8.35938 5.19531 8.75 4.375 8.75C3.87772 8.75 3.40081 8.55246 3.04917 8.20083C2.69754 7.84919 2.5 7.37228 2.5 6.875C2.5 6.37772 2.69754 5.90081 3.04917 5.54917C3.40081 5.19754 3.87772 5 4.375 5C4.74063 5.01537 5.09374 5.1376 5.39062 5.35156"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.58876 5.17295C3.43554 5.02115 3.31542 4.83928 3.23594 4.63878C3.15646 4.43828 3.11935 4.2235 3.12695 4.00796C3.13454 3.79242 3.18668 3.58078 3.28007 3.38638C3.37347 3.19197 3.5061 3.019 3.66962 2.87837C3.83313 2.73773 4.02399 2.63247 4.23019 2.56921C4.43638 2.50595 4.65343 2.48606 4.86768 2.5108C5.08194 2.53554 5.28875 2.60436 5.4751 2.71294C5.66145 2.82152 5.82331 2.9675 5.95047 3.1417C5.95047 3.1417 6.25047 3.55459 6.25047 3.74991"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5.69629 2.52773C6.37559 1.75352 7.32676 1.25 8.4373 1.25C10.4834 1.25 12.1092 2.92344 12.1092 4.96914C12.1093 5.20232 12.0875 5.43499 12.0439 5.66406"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
