import * as Colors from 'src/theme/colors';

export default {
  button: {
    '&:hover': {
      backgroundColor: Colors.HoverNonBorderBackground,
    },
    height: '35.5px',
    paddingLeft: '16px',
  },
};
