import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ColorWandIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.70535 6.96461C8.22452 6.48378 7.44499 6.48372 6.96424 6.96447C6.48348 7.44523 6.48354 8.22475 6.96438 8.70559L15.6695 17.4107C16.1504 17.8916 16.9299 17.8916 17.4106 17.4109C17.8914 16.9301 17.8913 16.1506 17.4105 15.6698L8.70535 6.96461Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M6.96755 6.96804C6.85276 7.08281 6.7617 7.21907 6.69957 7.36903C6.63744 7.519 6.60547 7.67974 6.60547 7.84206C6.60547 8.00439 6.63744 8.16512 6.69957 8.31509C6.7617 8.46506 6.85276 8.60132 6.96755 8.71609L8.72028 10.4688L10.4683 8.72077L8.7156 6.96804C8.60083 6.85325 8.46457 6.76219 8.3146 6.70006C8.16463 6.63793 8.0039 6.60596 7.84157 6.60596C7.67925 6.60596 7.51851 6.63793 7.36855 6.70006C7.21858 6.76219 7.08232 6.85325 6.96755 6.96804V6.96804Z"
      fill="currentColor"
    />
    <path
      d="M1.875 7.5H3.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.52246 3.52246L4.84824 4.84824"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.5 1.875V3.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.4771 3.52246L10.1514 4.84824"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.84824 10.1514L3.52246 11.4771"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
