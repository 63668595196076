import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LockIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 7 10" {...props}>
    <path
      d="M1.00599 10H5.99401C6.66991 10 7 9.67196 7 8.95438V5.19221C7 4.54639 6.72754 4.21322 6.16692 4.15684V2.8652C6.16692 0.932855 4.87275 0 3.5 0C2.12725 0 0.833084 0.932855 0.833084 2.8652V4.18247C0.319611 4.25935 0 4.58739 0 5.19221V8.95438C0 9.67196 0.33009 10 1.00599 10ZM1.67665 2.75756C1.67665 1.47104 2.52021 0.789339 3.5 0.789339C4.47979 0.789339 5.32335 1.47104 5.32335 2.75756V4.15172L1.67665 4.15684V2.75756Z"
      fill="currentColor"
    />
  </SvgIcon>
);
