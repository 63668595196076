import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PhoneLandscapeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M0.625 6.875L0.625 13.125C0.625 14.1605 1.46447 15 2.5 15L17.5 15C18.5355 15 19.375 14.1605 19.375 13.125V6.875C19.375 5.83947 18.5355 5 17.5 5L2.5 5C1.46447 5 0.625 5.83947 0.625 6.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.625 13.125V12.1875C0.625 12.1046 0.657924 12.0251 0.716529 11.9665C0.775134 11.9079 0.85462 11.875 0.9375 11.875V11.875C1.10326 11.875 1.26223 11.8092 1.37944 11.6919C1.49665 11.5747 1.5625 11.4158 1.5625 11.25V8.75C1.5625 8.58424 1.49665 8.42527 1.37944 8.30806C1.26223 8.19085 1.10326 8.125 0.9375 8.125V8.125C0.85462 8.125 0.775134 8.09208 0.716529 8.03347C0.657924 7.97487 0.625 7.89538 0.625 7.8125V6.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
