import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reduxTypes';
import * as UserUtils from 'src/utils/UserUtils';

export const useUserAccess = (
  permissionName: string,
  operationName: string,
): boolean => {
  const [state, setState] = useState(false);

  const permissions = useSelector(
    (store: RootState) => store.user.data?.permissions,
  );
  const loaded = useSelector((store: RootState) => store.user.loaded);

  useEffect(() => {
    if (loaded) {
      if (permissionName && operationName) {
        const hasOperations = UserUtils.hasOperation(
          permissionName,
          operationName,
          permissions,
        );
        setState(hasOperations);
      } else {
        const hasPermission = UserUtils.hasPermission(
          permissionName,
          permissions,
        );
        setState(hasPermission);
      }
    }
  }, [loaded]);

  return state;
};
