import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ExtensionsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.125 13.75H5.625C4.63044 13.75 3.67661 13.3549 2.97335 12.6517C2.27009 11.9484 1.875 10.9946 1.875 10C1.875 9.00544 2.27009 8.05161 2.97335 7.34835C3.67661 6.64509 4.63044 6.25 5.625 6.25H8.125M11.875 6.25H14.375C15.3696 6.25 16.3234 6.64509 17.0267 7.34835C17.7299 8.05161 18.125 9.00544 18.125 10C18.125 10.9946 17.7299 11.9484 17.0267 12.6517C16.3234 13.3549 15.3696 13.75 14.375 13.75H11.875M6.37852 10H13.6996"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
