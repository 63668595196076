import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const QuestionShortIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <rect width="20" height="20" rx="3" fill="#212B36" />
    <rect x="3" y="8" width="6" height="1" fill="white" />
    <rect x="10" y="8" width="4" height="1" fill="white" />
    <rect x="16" y="8" width="1" height="1" fill="white" />
    <rect x="3" y="11" width="3" height="1" fill="white" />
    <rect x="8" y="11" width="9" height="1" fill="white" />
  </SvgIcon>
);
