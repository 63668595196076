import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ZipIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15.6364H8.87642V14.9226H7.11151L8.8782 12.5007V12H6.00178V12.7138H7.76669L6 15.1357V15.6364Z"
      fill="black"
    />
    <path d="M10.2947 12H9.41575V15.6364H10.2947V12Z" fill="black" />
    <path
      d="M10.8464 15.6364H11.7253V14.5071H12.3219C13.1547 14.5071 13.6749 14.0135 13.6749 13.2589C13.6749 12.5114 13.1653 12 12.3485 12H10.8464V15.6364ZM11.7253 13.8146V12.7085H12.1586C12.5527 12.7085 12.764 12.9215 12.764 13.2589C12.764 13.5945 12.5527 13.8146 12.1586 13.8146H11.7253Z"
      fill="black"
    />
  </SvgIcon>
);
