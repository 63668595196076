import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ReceiptIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.25 13.125V1.875L7.5 2.5L8.75 1.875L9.99766 2.5L11.2621 1.875L12.5 2.5L13.7418 1.875L14.9891 2.5L16.25 1.875L17.5004 2.5L18.75 1.875V10.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 10.625V15C18.75 15.8288 18.4208 16.6237 17.8347 17.2097C17.2487 17.7958 16.4538 18.125 15.625 18.125V18.125C14.7962 18.125 14.0014 17.7958 13.4153 17.2097C12.8293 16.6237 12.5 15.8288 12.5 15V13.125H1.87502C1.79275 13.1243 1.71114 13.1399 1.63499 13.1711C1.55883 13.2022 1.48964 13.2482 1.43146 13.3064C1.37327 13.3646 1.32726 13.4338 1.29611 13.51C1.26496 13.5861 1.24929 13.6677 1.25002 13.75C1.25002 16.25 1.51331 18.125 4.37502 18.125H15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 5.625H16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 8.75H16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
