import { Drawer, makeStyles, createStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ESignatureFlowStatus } from 'src/store/signaturePage/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 4),
    },
  }),
);

/**
 * Displays a bottom sheet component for mobile devices.
 * Holds the signature preview component.
 * @returns {JSX.Element}
 */
export const AddSignatureDrawer: React.FC = ({ children }) => {
  const signatureFlowStatus = useSelector(
    (state: RootState) => state.signaturePage.signatureFlowStatus,
  );
  const classes = useStyles();
  if (signatureFlowStatus !== ESignatureFlowStatus.FILL_SIGNATURE) return null;

  return (
    <Drawer
      anchor="bottom"
      open
      variant="persistent"
      role="menu"
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.root}>{children}</div>
    </Drawer>
  );
};
