import { Events } from 'src/constants/webSocketConsts/events';
import {
  deleteNotifications,
  insertFormsNotifications,
  updateNotifications,
} from 'src/store/notifications/actions';
import { NotificationGroups } from 'src/store/notifications/types';
import { ensureUnreachable } from 'src/utils/common_utils';

const formRequestNotificationHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
      return insertFormsNotifications(
        items,
        NotificationGroups.FormRequestNotifications,
      );
    case Events.Modify:
      return updateNotifications(
        items,
        NotificationGroups.FormRequestNotifications,
      );
    case Events.Remove:
      return deleteNotifications(
        items,
        NotificationGroups.FormRequestNotifications,
      );
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default formRequestNotificationHandler;
