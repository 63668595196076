import React from 'react';
import { ProviderContext } from 'notistack';
import { UnAuthAPIName } from 'src/constants';
import { API } from 'src/utils/AmplifyApiUtils';
import { AlertSnackbar } from 'src/legacy/components/AlertSnackbar/AlertSnackbar';
import { AlertVariant } from 'src/store/ui/types';
import { GetVersionResponse } from 'src/entities/GetVersionResponse';

const VERSION_POLL_INTERVAL = 5 * 60 * 1000; // poll every 2 minutes

export const useTrackAppVersion = (
  enqueueSnackbar: ProviderContext['enqueueSnackbar'],
) => {
  const previousVersions = React.useRef<string[]>([]);
  const versionCheckTimer = React.useRef(0);
  const toastDisplayedRef = React.useRef(false);

  const newVersionSnackbarContent = React.useCallback(
    (key: string) => (
      <div key={key}>
        <AlertSnackbar
          variant={AlertVariant.WARNING}
          alertMessage="A new update is ready"
          onAction={() => {
            window.location.reload();
          }}
          actionLabel="Refresh page to update"
        />
      </div>
    ),
    [],
  );

  /**
   * Initialize the app version. Call the backend to return the current version.
   * If the version is different from the previous version, notify the user.
   */
  const trackVersion = async () => {
    try {
      const result: GetVersionResponse = await API.get(
        UnAuthAPIName,
        '/version',
        {},
      );

      console.info('version response: ', result);
      const { version } = result;
      // if the version returned is not in the list of previous versions
      // a new version is available and we need to notify the user
      if (!previousVersions.current.includes(version)) {
        // add the current version to the list of previous versions
        previousVersions.current.push(version);
        // only notify the user if the current version is set and the version is different
        // from the previous version. Length > 1 is to prevent a snackbar from showing
        // for the first time the app is loaded
        if (previousVersions.current.length > 1) {
          // Only show a toast if one isn't already displayed
          if (!toastDisplayedRef.current) {
            toastDisplayedRef.current = true;
            enqueueSnackbar('New version available', {
              persist: true,
              content: (key: string) => newVersionSnackbarContent(key),
            });
          }
        }
      }
    } catch (ex) {
      console.error('error getting version', ex);
    }

    // keep track of current version
    versionCheckTimer.current = window.setTimeout(() => {
      trackVersion();
    }, VERSION_POLL_INTERVAL);
  };

  React.useEffect(() => {
    trackVersion();

    return () => {
      if (versionCheckTimer.current) {
        clearTimeout(versionCheckTimer.current);
      }
    };
  }, []);
};
