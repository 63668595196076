import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ChatbubbleEllipsesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.41723 14.8438C3.4641 14.6727 3.36137 14.4348 3.26332 14.2633C3.23293 14.2121 3.19981 14.1625 3.1641 14.1148C2.32319 12.8396 1.87499 11.3455 1.87504 9.81797C1.86098 5.4332 5.49692 1.875 9.99301 1.875C13.9141 1.875 17.1875 4.59141 17.952 8.19727C18.0667 8.73186 18.1247 9.27706 18.125 9.82383C18.125 14.2148 14.6293 17.8289 10.1332 17.8289C9.41801 17.8289 8.45356 17.6492 7.92738 17.502C7.40121 17.3547 6.87582 17.1594 6.74028 17.107C6.60151 17.0537 6.45415 17.0263 6.30551 17.0262C6.14342 17.0256 5.98289 17.0579 5.83363 17.1211L3.18363 18.0773C3.12573 18.1024 3.06435 18.1184 3.0016 18.125C2.95197 18.1248 2.90287 18.1149 2.85713 18.0956C2.81138 18.0764 2.76991 18.0482 2.73511 18.0128C2.70031 17.9775 2.67287 17.9355 2.65438 17.8895C2.63589 17.8434 2.62671 17.7942 2.62738 17.7445C2.63064 17.701 2.6385 17.6579 2.65082 17.616L3.41723 14.8438Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.25 11.25C6.94036 11.25 7.5 10.6904 7.5 10C7.5 9.30964 6.94036 8.75 6.25 8.75C5.55964 8.75 5 9.30964 5 10C5 10.6904 5.55964 11.25 6.25 11.25Z"
      fill="currentColor"
    />
    <path
      d="M10 11.25C10.6904 11.25 11.25 10.6904 11.25 10C11.25 9.30964 10.6904 8.75 10 8.75C9.30964 8.75 8.75 9.30964 8.75 10C8.75 10.6904 9.30964 11.25 10 11.25Z"
      fill="currentColor"
    />
    <path
      d="M13.75 11.25C14.4404 11.25 15 10.6904 15 10C15 9.30964 14.4404 8.75 13.75 8.75C13.0596 8.75 12.5 9.30964 12.5 10C12.5 10.6904 13.0596 11.25 13.75 11.25Z"
      fill="currentColor"
    />
  </SvgIcon>
);
