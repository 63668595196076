import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SignatureTextIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.25 16.2305L5.9375 3.73047L10.625 16.2305M8.98438 11.8555H2.89062M12.7344 9.35547C13.2105 8.23477 14.3359 7.48047 15.625 7.48047C17.4219 7.48047 18.75 8.73047 18.75 10.6055V16.2305"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.5 14.0039C12.5 15.4102 13.5492 16.2695 14.8438 16.2695C16.9531 16.2695 18.75 15.2148 18.75 12.1289V11.543C17.9688 11.543 16.4844 11.582 15.1562 11.7383C13.8762 11.8891 12.5 12.4805 12.5 14.0039Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
