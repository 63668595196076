import { BaseEntity } from 'src/constants';
import { Tag } from 'src/store/tags/types';

export const LOAD_ARTICLES_REQUEST = 'LOAD_ARTICLES_REQUEST';
export const LOAD_ARTICLES_DONE = 'LOAD_ARTICLES_DONE';
export const LOAD_ARTICLES_FAIL = 'LOAD_ARTICLES_FAIL';
export const CREATE_ARTICLE_REQUEST = 'CREATE_ARTICLE_REQUEST';
export const CREATE_ARTICLE_DONE = 'CREATE_ARTICLE_DONE';
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL';
export const UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST';
export const UPDATE_ARTICLE_DONE = 'UPDATE_ARTICLE_DONE';
export const UPDATE_ARTICLE_FAIL = 'UPDATE_ARTICLE_FAIL';
export const DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST';
export const DELETE_ARTICLES_DONE = 'DELETE_ARTICLES_DONE';
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL';
export const UPSERT_ARTICLES = 'UPSERT_ARTICLES';
// knowledge base settings
export const GET_KNOWLEDGE_BASE_SETTINGS_REQUEST =
  'GET_KNOWLEDGE_BASE_SETTINGS_REQUEST';
export const GET_KNOWLEDGE_BASE_SETTINGS_DONE =
  'GET_KNOWLEDGE_BASE_SETTINGS_DONE';
export const GET_KNOWLEDGE_BASE_SETTINGS_FAIL =
  'GET_KNOWLEDGE_BASE_SETTINGS_FAIL';
export const UPDATE_KNOWLEDGE_BASE_SETTINGS_DONE =
  'UPDATE_KNOWLEDGE_BASE_SETTINGS_DONE';
export const UPDATE_KNOWLEDGE_BASE_SETTINGS_FAIL =
  'UPDATE_KNOWLEDGE_BASE_SETTINGS_FAIL';
export const ON_NEW_TAG_ADD_SUCCCESS = 'ON_NEW_TAG_ADD_SUCCCESS';

export interface KnowledgeBaseSettings extends BaseEntity {
  structFields: KnowledgeBaseSettingsStructFields;
}

export interface KnowledgeBaseSettingsStructFields {
  articles?: string[];
  tags?: string[];
}

export interface Article extends BaseEntity {
  structFields: ArticleStructFields;
}

export interface ArticleStructFields {
  title: string;
  content: string;
  contentHtml?: string;
  previewText: string;
  tags?: string[];
}

export interface KnowledgeBaseState {
  articles: Article[];
  articlesLoading: boolean;
  articlesLoaded: boolean;
  settings?: KnowledgeBaseSettingsStructFields;
  settingsLoading: boolean;
  settingsLoaded: boolean;
  error?: string;
}

export interface ArticleDetailInfo {
  article: Article;
  tags: Tag[];
}

export interface UpdateKnowledgeBaseSettingsInput {
  fieldType: string;
  values: string[];
}

interface StartLoadArticlesAction {
  type: typeof LOAD_ARTICLES_REQUEST;
}

interface LoadArticlesDoneAction {
  type: typeof LOAD_ARTICLES_DONE;
  payload: Article[];
}

interface LoadArticlesFailAction {
  type: typeof LOAD_ARTICLES_FAIL;
  error: string;
}

interface CreateArticleAction {
  type: typeof CREATE_ARTICLE_REQUEST;
}

interface CreateArticleDoneAction {
  type: typeof CREATE_ARTICLE_DONE;
  payload: Article;
}

interface CreateArticleFailAction {
  type: typeof CREATE_ARTICLE_FAIL;
  error: string;
}

interface UpdateArticleRequestAction {
  type: typeof UPDATE_ARTICLE_REQUEST;
}

interface UpdateArticleDoneAction {
  type: typeof UPDATE_ARTICLE_DONE;
  payload: Article;
}

interface UpdateArticleFailAction {
  type: typeof UPDATE_ARTICLE_FAIL;
  error: string;
}

interface DeleteArticleRequestAction {
  type: typeof DELETE_ARTICLE_REQUEST;
}

interface DeleteArticlesDoneAction {
  type: typeof DELETE_ARTICLES_DONE;
  payload: Article[];
}

interface DeleteArticleFailAction {
  type: typeof DELETE_ARTICLE_FAIL;
  error: string;
}

interface GetKnowledgeBaseSettingsRequestAction {
  type: typeof GET_KNOWLEDGE_BASE_SETTINGS_REQUEST;
}

interface GetKnowledgeBaseSettingsRequestDoneAction {
  type: typeof GET_KNOWLEDGE_BASE_SETTINGS_DONE;
  payload: KnowledgeBaseSettings;
}

interface GetKnowledgeBaseSettingsRequestFailAction {
  type: typeof GET_KNOWLEDGE_BASE_SETTINGS_FAIL;
  error: string;
}

interface UpdateKnowledgeBaseSettingsRequestDoneAction {
  type: typeof UPDATE_KNOWLEDGE_BASE_SETTINGS_DONE;
  payload: UpdateKnowledgeBaseSettingsInput;
}

interface UpdateKnowledgeBaseSettingsRequestFailAction {
  type: typeof UPDATE_KNOWLEDGE_BASE_SETTINGS_FAIL;
  error: string;
}

interface UpdateKnowledgeBaseSettingsNewTagAddAction {
  type: typeof ON_NEW_TAG_ADD_SUCCCESS;
  tagId: string;
}

interface UpsertArticleAction {
  type: typeof UPSERT_ARTICLES;
  payload: Article[];
}

export type KnowledgeBaseActionTypes =
  | StartLoadArticlesAction
  | LoadArticlesDoneAction
  | LoadArticlesFailAction
  | CreateArticleAction
  | CreateArticleDoneAction
  | CreateArticleFailAction
  | UpdateArticleRequestAction
  | UpdateArticleDoneAction
  | UpdateArticleFailAction
  | DeleteArticleRequestAction
  | DeleteArticlesDoneAction
  | DeleteArticleFailAction
  | GetKnowledgeBaseSettingsRequestAction
  | GetKnowledgeBaseSettingsRequestDoneAction
  | GetKnowledgeBaseSettingsRequestFailAction
  | UpdateKnowledgeBaseSettingsRequestDoneAction
  | UpdateKnowledgeBaseSettingsRequestFailAction
  | UpdateKnowledgeBaseSettingsNewTagAddAction
  | UpsertArticleAction;
