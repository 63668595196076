import React from 'react';

import {
  FiEdit as EditIcon,
  FiNavigation as NavigationIcon,
} from 'react-icons/fi';
import { CopyIcon, EyeIcon, TrashIcon } from 'src/legacy/components/Icons';
import { BaseActionsMenuButton, Action } from 'src/legacy/components/Dropdowns';
import { MemberActionsRendererProps } from 'src/legacy/components/AgGrid/Renderer/types';
import {
  PERM_MOD_SETTINGS_MEMBERS,
  UserRoles,
  USER_OPERATIONS,
} from 'src/constants';
import { useUserAccess } from 'src/hooks/useUserAccess';
import { usePlanInfo } from 'src/hooks/usePlanInfo';
import { PortalConfigContext } from 'src/context';

export const MemberActionRenderer = ({
  data,
  handleChangeRole,
  handleChangeClientAccess,
  handleDelete,
  handleResendInvite,
  handleCopyInvite,
  currentUser,
  onActionMenuClick,
}: MemberActionsRendererProps) => {
  const [actions, setActions] = React.useState<Action[]>([]);
  const { userRoles, userId: tableRowUserId, clientAccess } = data;
  const { userId: currentUserId } = currentUser || {};
  const portalConfig = React.useContext(PortalConfigContext);
  const canDeleteTeamMember = useUserAccess(
    PERM_MOD_SETTINGS_MEMBERS,
    USER_OPERATIONS.delete,
  );

  const { cancelAtPeriodEnd } = usePlanInfo();
  // for cancelled plans, disable team member delete option so that plan can not be modified
  const isDeleteButtonDisabled = cancelAtPeriodEnd;

  React.useEffect(() => {
    const actionsStaged: Action[] = [];

    if (clientAccess) {
      actionsStaged.push({
        name: 'Change client access',
        icon: <EyeIcon />,
        onClick: () => handleChangeClientAccess(data),
      });
    }

    if (userRoles && userRoles.length > 0 && tableRowUserId !== currentUserId) {
      switch (userRoles.at(0)) {
        case UserRoles.InternalAdmin:
          actionsStaged.unshift({
            name: 'Set as Staff',
            icon: <EditIcon />,
            onClick: () => handleChangeRole(data, UserRoles.InternalStaff),
          });
          break;
        case UserRoles.InternalStaff:
          actionsStaged.unshift({
            name: 'Set as Admin',
            icon: <EditIcon />,
            onClick: () => handleChangeRole(data, UserRoles.InternalAdmin),
          });
          break;
        default:
          break;
      }

      if (data.status === 'Inactive' && handleResendInvite) {
        actionsStaged.push({
          name: 'Resend invite',
          icon: <NavigationIcon />,
          onClick: () => handleResendInvite(data),
        });
      }

      if (
        data.status !== 'Active' &&
        handleCopyInvite &&
        !portalConfig?.authPreferences.enableMagicLinks
      ) {
        actionsStaged.push({
          name: 'Copy invite link',
          onClick: () => handleCopyInvite(data),
          icon: <CopyIcon />,
        });
      }

      if (canDeleteTeamMember && handleDelete) {
        actionsStaged.push({
          name: 'Delete',
          icon: <TrashIcon />,
          onClick: () => handleDelete(data),
          isDelete: true,
          isDisabled: isDeleteButtonDisabled,
        });
      }
    }

    setActions(actionsStaged);
  }, [canDeleteTeamMember, data]);

  return (
    <BaseActionsMenuButton
      onActionMenuClick={onActionMenuClick}
      actions={actions}
    />
  );
};
