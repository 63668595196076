import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MapIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.2371 4.86876L7.76289 2.00626C7.58492 1.91736 7.38829 1.87222 7.18936 1.8746C6.99043 1.87698 6.79494 1.9268 6.61914 2.01994L2.20742 4.98009C2.10705 5.03326 2.02307 5.11278 1.9645 5.2101C1.90593 5.30741 1.87499 5.41885 1.875 5.53244V17.0871C1.87499 17.1949 1.90286 17.3009 1.95589 17.3947C2.00892 17.4885 2.08532 17.5671 2.17767 17.6226C2.27001 17.6782 2.37517 17.709 2.48291 17.7119C2.59066 17.7149 2.69733 17.6899 2.79258 17.6395L6.61367 15.1477C6.79091 15.0538 6.98813 15.0039 7.1887 15.0023C7.38926 15.0006 7.58728 15.0473 7.76602 15.1383L12.1355 17.9899C12.3127 18.0801 12.5089 18.1268 12.7077 18.1261C12.9066 18.1253 13.1024 18.0772 13.2789 17.9856L17.7879 15.0192C17.8895 14.9664 17.9747 14.8868 18.0341 14.789C18.0935 14.6912 18.125 14.5789 18.125 14.4645V2.9129C18.125 2.80512 18.0971 2.69916 18.0441 2.60533C17.9911 2.51149 17.9147 2.43297 17.8223 2.37738C17.73 2.32179 17.6248 2.29103 17.5171 2.28808C17.4093 2.28514 17.3027 2.3101 17.2074 2.36056L13.3793 4.85509C13.2037 4.94796 13.0085 4.99765 12.8099 5.00002C12.6112 5.0024 12.4149 4.95741 12.2371 4.86876Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8125 5V18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.1875 1.875V15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
