import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RainyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M4.47695 6.36133C4.60751 6.34108 4.72835 6.28015 4.82227 6.18721C4.91618 6.09428 4.97838 5.97409 5 5.84375C5.47227 2.97539 7.56367 1.25 10 1.25C12.2629 1.25 13.7742 2.72461 14.3828 4.28672C14.4219 4.38684 14.4864 4.47505 14.57 4.54265C14.6536 4.61026 14.7533 4.65493 14.8594 4.67227C16.8125 4.99063 18.4375 6.29492 18.4375 8.5625C18.4375 10.8828 16.5391 12.5 14.2188 12.5H5.07812C3.14453 12.5 1.5625 11.5352 1.5625 9.40625C1.5625 7.51289 3.07305 6.58516 4.47695 6.36133Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 15L4.375 16.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 15L6.25 18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 15L10.625 16.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 15L12.5 18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
