import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const IdCardIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 1.25H5.625C4.58947 1.25 3.75 2.08947 3.75 3.125V16.875C3.75 17.9105 4.58947 18.75 5.625 18.75H14.375C15.4105 18.75 16.25 17.9105 16.25 16.875V3.125C16.25 2.08947 15.4105 1.25 14.375 1.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 3.125H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0266 11.1136C12.8792 10.9565 12.7006 10.832 12.5024 10.7478C12.3041 10.6637 12.0904 10.6218 11.875 10.6249C11.4219 10.6249 11.0122 10.7972 10.7211 11.1105C10.4301 11.4237 10.2836 11.8573 10.3172 12.3214C10.384 13.2386 11.0829 13.9843 11.875 13.9843C12.6672 13.9843 13.3649 13.2386 13.4325 12.3222C13.4668 11.862 13.3223 11.4327 13.0266 11.1136Z"
      fill="currentColor"
    />
    <path
      d="M14.519 17.5H9.23068C9.16184 17.5011 9.09359 17.4871 9.03076 17.4589C8.96792 17.4308 8.91202 17.3892 8.86701 17.3371C8.8182 17.2786 8.78317 17.2099 8.76451 17.1361C8.74584 17.0623 8.74403 16.9852 8.7592 16.9105C8.88615 16.2246 9.28185 15.6484 9.90373 15.2441C10.4561 14.8852 11.1561 14.6875 11.8748 14.6875C12.5936 14.6875 13.2936 14.8852 13.8459 15.2441C14.4678 15.6484 14.8635 16.2246 14.9904 16.9105C15.0056 16.9852 15.0038 17.0623 14.9851 17.1361C14.9665 17.2099 14.9314 17.2786 14.8826 17.3371C14.8376 17.3892 14.7817 17.4308 14.7189 17.4589C14.656 17.4871 14.5878 17.5011 14.519 17.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
