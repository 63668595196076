import {
  Tag,
  LOAD_TAGS_DONE,
  LOAD_TAGS_FAIL,
  LOAD_TAGS_REQUEST,
  CREATE_TAGS_REQUEST,
  CREATE_TAGS_DONE,
  UPDATE_TAGS_REQUEST,
  UPDATE_TAGS_DONE,
  UPDATE_TAGS_FAIL,
  DELETE_TAGS_DONE,
  TagsActionTypes,
  DELETE_TAGS_REQUEST,
  TagsState,
} from 'src/store/tags/types';

const initialState: TagsState = {
  tags: [],
  isLoaded: false,
  isLoading: false,
  isUpdating: false,
};

/**
 * do an "upsert" to ensure tags are added/updated in state
 * @param newTags up-to-date tags that should be added into state as they are
 * @param currentTags current tags in the state
 */
const getUpdatedInsertedTags = (newTags: Tag[], currentTags: Tag[]) => {
  // we keep track of a list of updated IDs so that we know which ones are new
  const updatedTagIDs: string[] = [];
  // iterate through currently existing tags to check for updated tags (as opposed to inserted tags)
  const finalTags: Tag[] = currentTags.map((t: Tag) => {
    const payloadUpdatedTagIndex: number = newTags.findIndex(
      (el: Tag) => el.id === t.id,
    );
    if (payloadUpdatedTagIndex > -1) {
      // if index is 0 or positive integer, newTags has this tag and we write the updated version into finalTags
      updatedTagIDs.push(newTags[payloadUpdatedTagIndex].id);
      return newTags[payloadUpdatedTagIndex];
    }
    return t;
  });

  // tags from newTags that were not added to finalTags are appended to the end, as they are new
  return finalTags.concat(newTags.filter((t) => !updatedTagIDs.includes(t.id)));
};

/* eslint-disable-next-line default-param-last */
const tags = (state = initialState, action: TagsActionTypes) => {
  switch (action.type) {
    case LOAD_TAGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case LOAD_TAGS_DONE: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        tags: payload,
        error: '',
      };
    }
    case LOAD_TAGS_FAIL: {
      const { error } = action;
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error,
      };
    }
    case CREATE_TAGS_REQUEST: {
      const { payload } = action;
      if (payload.isOptimistic) {
        // optimistically insert tags into state
        return {
          ...state,
          tags: getUpdatedInsertedTags(payload.tags || [], state.tags),
        };
      }
      return state;
    }
    case CREATE_TAGS_DONE: {
      const { payload } = action;
      // even if optimistically inserted, new tags have new information from API and will be updated in state with more data
      return {
        ...state,
        tags: getUpdatedInsertedTags(payload, state.tags),
      };
    }
    case UPDATE_TAGS_REQUEST: {
      const { payload } = action;
      if (payload.isOptimistic) {
        // in this flow we update before the API responds
        const updatedTags = state.tags.map(
          (t) =>
            payload.tags.find((updatedTag: Tag) => updatedTag.id === t.id) || t,
        );
        return {
          ...state,
          tags: updatedTags,
          isUpdating: true,
        };
      }
      return {
        ...state,
        isUpdating: true,
      };
    }
    case UPDATE_TAGS_DONE: {
      const { payload } = action;
      const updatedTags = state.tags.map(
        (t) => payload.find((updatedTag: Tag) => updatedTag.id === t.id) || t,
      );
      return {
        ...state,
        isUpdating: false,
        tags: updatedTags,
      };
    }
    case UPDATE_TAGS_FAIL: {
      const { error } = action;
      return {
        ...state,
        isUpdating: false,
        error,
      };
    }
    case DELETE_TAGS_REQUEST: {
      const { payload } = action;
      if (payload.isOptimistic) {
        const deletedIDs = (payload.tags || []).map((t) => t.id);
        const updatedTagsList: Tag[] = [];
        state.tags.forEach((t) => {
          if (!deletedIDs.includes(t.id)) updatedTagsList.push(t);
        });
        return {
          ...state,
          tags: updatedTagsList,
        };
      }
      return state;
    }
    case DELETE_TAGS_DONE: {
      const { payload } = action;
      const deletedIDs = payload.map((t) => t.id);
      const updatedTagsList: Tag[] = [];
      state.tags.forEach((t) => {
        if (!deletedIDs.includes(t.id)) updatedTagsList.push(t);
      });
      return {
        ...state,
        tags: updatedTagsList,
      };
    }
    default:
      return state;
  }
};

export default tags;
