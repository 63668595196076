import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const InformationIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.65625 8.59375H10.1562V15.3125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.30469 15.4688H12.6953"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5625"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 6.25C9.75277 6.25 9.5111 6.17669 9.30554 6.03934C9.09998 5.90199 8.93976 5.70676 8.84515 5.47836C8.75054 5.24995 8.72579 4.99861 8.77402 4.75614C8.82225 4.51366 8.9413 4.29093 9.11612 4.11612C9.29093 3.9413 9.51366 3.82225 9.75614 3.77402C9.99861 3.72579 10.2499 3.75054 10.4784 3.84515C10.7068 3.93976 10.902 4.09998 11.0393 4.30554C11.1767 4.5111 11.25 4.75277 11.25 5C11.25 5.33152 11.1183 5.64946 10.8839 5.88388C10.6495 6.1183 10.3315 6.25 10 6.25V6.25Z"
      fill="currentColor"
    />
  </SvgIcon>
);
