import React from 'react';
import { PortalConfigContext, RouteContext } from 'src/context';
import { SidebarColors, white } from 'src/theme/colors';
import ColorUtils from 'src/utils/ColorUtils';

export const useSidebarTheme = () => {
  const portalConfig = React.useContext(PortalConfigContext);
  const isClient = portalConfig.viewMode === 'client';
  const { pathname } = React.useContext(RouteContext);
  const {
    clientSidebarBackgroundColor: portalConfigClientSidebarBackgroundColor,
    clientSidebarTextColor: portalConfigClientSidebarTextColor,
  } = portalConfig.brand;

  /**
   * in client experience we have different color scheme for settings page sidebar
   * this method will return color based on the pathname
   * @param defaultSidebarColor color needs to be applied on pages other than /settings
   * @param settingSidebarColor color needs to be applied on settings page
   * */
  const getColorBasedOnPathname = (
    defaultSidebarColor: string,
    settingSidebarColor: string,
  ) => {
    if (pathname.includes('/settings')) return settingSidebarColor;

    return defaultSidebarColor;
  };

  // portal primary color
  const primaryMainColor = portalConfig.theme.palette.primary.main;

  // background color for clients sidebar
  const clientSidebarBackgroundColor = getColorBasedOnPathname(
    portalConfigClientSidebarBackgroundColor || primaryMainColor, // use client sidebar color from portalconfig if color value is set, if color is not available fallback to primary color
    SidebarColors.InternalSidebarBackground, // background color for settings page is same in internal and client experience
  );

  // text color for clients sidebar
  const clientSidebarTextColor = getColorBasedOnPathname(
    portalConfigClientSidebarTextColor || white, // use client sidebar text color from portalconfig if color value is set, else fallback to white
    SidebarColors.InternalSidebarText, // text color for settings page is same in internal and client experience
  );

  const sidebarTheme = React.useMemo(() => {
    // background color  based on user type ( client or internal)
    const sidebarBackgroundColor = isClient
      ? clientSidebarBackgroundColor
      : SidebarColors.InternalSidebarBackground;

    // text color based on user type ( client or internal)
    const sidebarTextColor = isClient
      ? clientSidebarTextColor
      : SidebarColors.InternalSidebarText;

    // hover or active state background color
    const sidebarItemHoverBackground = isClient
      ? ColorUtils.GetColorDarknessShades(clientSidebarBackgroundColor).midDark
      : SidebarColors.InternalSidebarItemHover;

    return {
      sidebarTextColor,
      sidebarBackgroundColor,
      sidebarItemHoverBackground,
    };
  }, [
    isClient,
    pathname,
    clientSidebarBackgroundColor,
    clientSidebarTextColor,
  ]);

  return { sidebarTheme };
};
