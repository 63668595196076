import {
  Notification,
  NotificationGroups,
  Notifications,
  NotificationsActionTypes,
} from 'src/store/notifications/types';
import { AppThunkAction } from 'src/store/reduxTypes';
import NotificationsClient from 'src/clients/NotificationsClient';
import { ENTITIES } from 'src/constants';
import { isUnreadNotification } from 'src/utils/NotificationsUtils';

/**
 * This method allows to set loaded user notifications to the redux store
 * @param notifications
 */
export const setNotificationsAction = (notifications: Notifications) => {
  // we prevent that fileNotifications value in the redux store to be null
  const fileNotifications = Array.isArray(notifications.fileNotifications)
    ? notifications.fileNotifications
    : [];

  const esigRequestNotifications = Array.isArray(
    notifications.esigRequestNotifications,
  )
    ? notifications.esigRequestNotifications
    : [];

  const esigRequestCompleteNotifications = Array.isArray(
    notifications.esigRequestCompleteNotifications,
  )
    ? notifications.esigRequestCompleteNotifications
    : [];

  const formRequestNotifications = Array.isArray(
    notifications.formRequestNotifications,
  )
    ? notifications.formRequestNotifications
    : [];

  const formCompletedNotifications = Array.isArray(
    notifications.formCompletedNotifications,
  )
    ? notifications.formCompletedNotifications
    : [];

  const newInvoiceNotifications = Array.isArray(
    notifications.newInvoiceNotifications,
  )
    ? notifications.newInvoiceNotifications
    : [];

  const stagedNotifications: Notifications = {
    ...notifications,
    fileNotifications,
    esigRequestNotifications,
    esigRequestCompleteNotifications,
    formCompletedNotifications,
    formRequestNotifications,
    newInvoiceNotifications,
  };
  return {
    type: NotificationsActionTypes.SET_NOTIFICATIONS,
    payload: stagedNotifications,
  };
};

/**
 * For all notifications in the current store that match channel and type should be marked as read
 * @param unreadNotifications notification entities to mark as read
 * @param notificationType what is the type of notification
 */
export const markNotificationAsRead =
  (
    unreadNotifications: Notification[],
    notificationType: string,
  ): AppThunkAction =>
  async (dispatch) => {
    let notificationEntityName = '';
    switch (notificationType) {
      case NotificationGroups.FileNotifications:
        notificationEntityName = ENTITIES.NOTIFY_FILE_UPLOADED;

        break;
      case NotificationGroups.EsigRequestCompleteNotifications:
        notificationEntityName = ENTITIES.NOTIFY_FILE_ESIGN_REQUEST_COMPLETE;

        break;
      case NotificationGroups.FormCompleteNotifications:
        notificationEntityName = ENTITIES.NOTIFY_FORM_COMPLETE;
        break;
      default:
        break;
    }

    const result = await NotificationsClient.markFileNotificationAsRead(
      unreadNotifications,
      notificationEntityName,
    );

    function success(payload: Array<Notification>) {
      return {
        type: NotificationsActionTypes.MARK_NOTIFICATION_READ_SUCCESS,
        payload,
        notificationType,
      };
    }

    function failure() {
      return { type: NotificationsActionTypes.MARK_NOTIFICATION_READ_FAILURE };
    }

    if (result?.succeededIds?.length > 0) {
      dispatch(success(result.succeededIds));
    } else {
      dispatch(failure());
    }
  };

/**
 * For all notifications in the current store that match channel and type should be marked as read
 * @param fileChannelId which channel notifications belong to
 * @param notificationType what is the type of notification
 */
export const markStoreNotificationsAsRead =
  (channelId: string, notificationType: NotificationGroups): AppThunkAction =>
  async (dispatch, getState) => {
    const {
      fileNotifications,
      esigRequestCompleteNotifications,
      formCompletedNotifications,
    } = getState().notifications;

    // unread notifications are notifications that are going
    // to be marked as read.
    let unreadNotifications: Notification[] = [];
    switch (notificationType) {
      case NotificationGroups.FileNotifications:
        unreadNotifications = fileNotifications
          .filter((notif) => notif.fields.channelId === channelId)
          .filter(isUnreadNotification);
        break;
      case NotificationGroups.EsigRequestCompleteNotifications:
        unreadNotifications = esigRequestCompleteNotifications
          .filter((notif) => notif.fields.channelId === channelId)
          .filter(isUnreadNotification);
        break;
      case NotificationGroups.FormCompleteNotifications:
        unreadNotifications = formCompletedNotifications
          .filter((notif) => notif.ownerId.split('/')[2] === channelId)
          .filter(isUnreadNotification);
        break;
      default:
        break;
    }

    if (!unreadNotifications || unreadNotifications.length < 1) {
      return;
    }

    dispatch(markNotificationAsRead(unreadNotifications, notificationType));
  };

/**
 * Given a channelId mark all file related notifications as read
 * @param fileChannelId which channel notifications are read for
 */
export const markFileChannelNotificationAsRead =
  (fileChannelId: string): AppThunkAction =>
  async (dispatch) => {
    dispatch(
      markStoreNotificationsAsRead(
        fileChannelId,
        NotificationGroups.EsigRequestCompleteNotifications,
      ),
    );
    dispatch(
      markStoreNotificationsAsRead(
        fileChannelId,
        NotificationGroups.FileNotifications,
      ),
    );
  };

/**
 * When new notifications are received, this method action
 * will check if there is some that correspond to the current selected
 * file channel. If so, it will directly mark them as read by dispatching
 * markStoreNotificationsAsRead action. Otherwise it will pass the new notifications items
 * to the reducer to append them to files notifications store.
 * @param filesNotifications: new incoming file related (upload, esign) notifications
 */
export const insertFilesNotifications =
  (
    filesNotifications: Array<Notification>,
    notificationType: NotificationGroups,
    allowMarkingNotificationAsRead?: boolean,
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const { selectedChannel } = getState().files;
    // We are using existing e-sigRequestNotifications for contract file
    // As there is no channel involved for contracts, so we can ignore channel matching for those notifications
    const selectedChannelNotifications =
      notificationType === NotificationGroups.EsigRequestNotifications
        ? filesNotifications
        : filesNotifications.filter(
            (notification) =>
              notification.fields?.channelId === selectedChannel?.id,
          );
    if (
      selectedChannelNotifications.length > 0 &&
      allowMarkingNotificationAsRead
    ) {
      dispatch(
        markNotificationAsRead(selectedChannelNotifications, notificationType),
      );
    }

    // filter selected file channel notification
    const stagedNewNotifications =
      notificationType === NotificationGroups.FileNotifications ||
      notificationType === NotificationGroups.EsigRequestCompleteNotifications
        ? filesNotifications.filter(
            (notification) =>
              notification.fields?.channelId !== selectedChannel?.id,
          )
        : filesNotifications;

    // to prevent unnecessary dispatch calls here, we need to check if there are
    // other channels which get notified other than the selected channels. If so, we can
    // pass them to be added to the store. Otherwise, updating file notifications store
    // is not needed.
    if (stagedNewNotifications.length > 0) {
      dispatch({
        type: NotificationsActionTypes.INSERT_NOTIFICATIONS,
        payload: stagedNewNotifications,
        notificationType,
      });
    }
  };

export const updateNotifications = (
  updatedNotifications: Array<Notification>,
  notificationType: NotificationGroups,
) => ({
  type: NotificationsActionTypes.UPDATE_NOTIFICATIONS,
  payload: updatedNotifications,
  notificationType,
});

export const deleteNotifications = (
  deletedNotifications: Array<Notification>,
  notificationType: NotificationGroups,
) => ({
  type: NotificationsActionTypes.DELETE_NOTIFICATIONS,
  payload: deletedNotifications,
  notificationType,
});

/**
 * Dispatch action to update state of form notifications
 * If notification is form complete and currently viewing that form
 * response then mark notification as read and don't surface
 * @param notifications form notifications being added
 * @param notificationType what type for form notification this is
 */
export const insertFormsNotifications =
  (
    notifications: Array<Notification>,
    notificationType:
      | NotificationGroups.FormRequestNotifications
      | NotificationGroups.FormCompleteNotifications,
  ): AppThunkAction =>
  async (dispatch) => {
    const unreadNotifications: Array<Notification> = [];

    const selectedChannelNotifications: Array<Notification> = [];
    notifications.forEach((notification) => {
      unreadNotifications.push(notification);
    });

    if (selectedChannelNotifications.length) {
      dispatch(
        markNotificationAsRead(selectedChannelNotifications, notificationType),
      );
    }

    if (unreadNotifications.length) {
      dispatch({
        type: NotificationsActionTypes.INSERT_NOTIFICATIONS,
        payload: unreadNotifications,
        notificationType,
      });
    }
  };

export const insertInvoiceNotifications =
  (
    notifications: Array<Notification>,
    notificationType: NotificationGroups.NewInvoiceNotifications,
  ): AppThunkAction =>
  async (dispatch) => {
    dispatch({
      type: NotificationsActionTypes.INSERT_NOTIFICATIONS,
      payload: notifications,
      notificationType,
    });
  };
