import {
  LOAD_ARTICLES_REQUEST,
  LOAD_ARTICLES_DONE,
  LOAD_ARTICLES_FAIL,
  Article,
  ArticleStructFields,
  DELETE_ARTICLE_REQUEST,
  DELETE_ARTICLES_DONE,
  DELETE_ARTICLE_FAIL,
  CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_DONE,
  CREATE_ARTICLE_FAIL,
  UPDATE_ARTICLE_REQUEST,
  UPDATE_ARTICLE_DONE,
  UPDATE_ARTICLE_FAIL,
  UPSERT_ARTICLES,
  GET_KNOWLEDGE_BASE_SETTINGS_REQUEST,
  KnowledgeBaseSettings,
  GET_KNOWLEDGE_BASE_SETTINGS_DONE,
  GET_KNOWLEDGE_BASE_SETTINGS_FAIL,
  UPDATE_KNOWLEDGE_BASE_SETTINGS_DONE,
  UPDATE_KNOWLEDGE_BASE_SETTINGS_FAIL,
  ON_NEW_TAG_ADD_SUCCCESS,
  UpdateKnowledgeBaseSettingsInput,
} from 'src/store/knowledgeBase/types';
import { AppThunkAction, AppThunkDispatch } from 'src/store/reduxTypes';
import KnowledgeBaseClient from 'src/clients/KnowledgeBaseClient';
import { FilterAllowedTagIdSetForUser } from 'src/utils/TagUtils';
import {
  ApiWithNotificationResponseType,
  CallApiWithNotification,
} from 'src/clients/ApiService';
import { ensureError } from 'src/utils/Errors';

/**
 * get articles for a user
 */
export const listArticles = (): AppThunkAction => async (dispatch) => {
  function request() {
    return { type: LOAD_ARTICLES_REQUEST };
  }

  function success(result: Article[]) {
    return { type: LOAD_ARTICLES_DONE, payload: result || [] };
  }

  function failure(error: string) {
    return { type: LOAD_ARTICLES_FAIL, error };
  }

  dispatch(request());

  try {
    const result = await KnowledgeBaseClient.getArticles();
    dispatch(success(result));
  } catch (e) {
    const { message } = ensureError(e);
    dispatch(failure(message));
  }
};

/**
 * create article
 */
export const createArticle =
  (articleStruct: ArticleStructFields) =>
  async (
    dispatch: AppThunkDispatch,
  ): Promise<ApiWithNotificationResponseType<Article>> => {
    function request() {
      return { type: CREATE_ARTICLE_REQUEST };
    }

    function success(result: Article) {
      return {
        type: CREATE_ARTICLE_DONE,
        payload: result,
      };
    }

    function failure(error: string) {
      return { type: CREATE_ARTICLE_FAIL, error };
    }

    dispatch(request());

    const errorMessage = 'Unable to create article.';

    const result = await CallApiWithNotification<Article>({
      executeFunction: KnowledgeBaseClient.createArticle,
      params: articleStruct,
      successMessage: 'Article created.',
      errorMessage,
      dispatch,
    });
    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(errorMessage));
    }

    return result;
  };

/**
 * Add or update articles (based on ID) into state directly without calling API
 */
export const upsertArticlesToState = (articles: Article[]) => ({
  type: UPSERT_ARTICLES,
  payload: articles,
});

/**
 * Remove a list of tags based on ID in state directly without calling API
 */
export const removeArticlesFromState = (articles: Article[]) => ({
  type: DELETE_ARTICLES_DONE,
  payload: articles,
});

/**
 * given a knowledge base settings entity, write it into state directly without calling API
 * @param settings a knowledge base settings entity (it is a singleton, not array, overwrites preexisting settings)
 */
export const loadKnowledgeBaseSettings = (settings: KnowledgeBaseSettings) => ({
  type: GET_KNOWLEDGE_BASE_SETTINGS_DONE,
  payload: settings,
});

/**
 * refresh articles in state based on tags allowed for current user
 * when article has tag that is allowed upsert it into state
 * otherwise remove it from state
 */
export const refreshArticles =
  (articles: Article[]): AppThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    // filter tags based on current user
    const userId = state.user.id;
    const currentUser = state.users.users.find((user) => user.id === userId);
    if (!currentUser) {
      return;
    }

    const allowedTagIdSet = FilterAllowedTagIdSetForUser({
      tags: state.tags.tags,
      userId,
      userFields: currentUser.fields,
    });

    const upsertArticles: Article[] = [];
    const removeArticles: Article[] = [];
    articles.forEach((article) => {
      const articleTags = article.structFields.tags;
      // article is allowed when article has a tag that is allowed
      if (!articleTags || articleTags.some((tag) => allowedTagIdSet[tag])) {
        upsertArticles.push(article);
      } else {
        removeArticles.push(article);
      }
    });

    if (upsertArticles.length > 0) {
      dispatch(upsertArticlesToState(upsertArticles));
    }
    if (removeArticles.length > 0) {
      dispatch(removeArticlesFromState(removeArticles));
    }
  };

/**
 * update article
 */
export const updateArticle =
  (article: Article) => async (dispatch: AppThunkDispatch) => {
    function request() {
      return { type: UPDATE_ARTICLE_REQUEST };
    }

    function success(result: Article) {
      return { type: UPDATE_ARTICLE_DONE, payload: result };
    }

    function failure(error: string) {
      return { type: UPDATE_ARTICLE_FAIL, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: KnowledgeBaseClient.updateArticle,
      params: article,
      successMessage: 'Article updated.',
      errorMessage: 'Unable to update article.',
      dispatch,
    });
    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };

export const deleteArticle =
  (
    article: Article,
  ): AppThunkAction<Promise<ApiWithNotificationResponseType<void>>> =>
  async (dispatch) => {
    function request() {
      return { type: DELETE_ARTICLE_REQUEST };
    }

    function success(deletedArticle: Article) {
      return { type: DELETE_ARTICLES_DONE, payload: [deletedArticle] };
    }

    function failure(error: string) {
      return { type: DELETE_ARTICLE_FAIL, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: KnowledgeBaseClient.deleteArticle,
      params: article.id,
      successMessage: 'Article deleted',
      errorMessage: 'Unable to delete article.',
      dispatch,
    });
    if (result.status) {
      dispatch(success(article));
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };

/**
 * get knowledge base settings
 */
export const getKnowledgeBaseSettings =
  (): AppThunkAction => async (dispatch) => {
    function request() {
      return { type: GET_KNOWLEDGE_BASE_SETTINGS_REQUEST };
    }

    function success(settings: KnowledgeBaseSettings) {
      return { type: GET_KNOWLEDGE_BASE_SETTINGS_DONE, payload: settings };
    }

    function failure(error: string) {
      return { type: GET_KNOWLEDGE_BASE_SETTINGS_FAIL, error };
    }

    dispatch(request());

    try {
      const result = await KnowledgeBaseClient.getKnowledgeBaseSettings();
      dispatch(success(result));
    } catch (e) {
      const { message } = ensureError(e);
      dispatch(failure(message));
    }
  };

/**
 * update knowledge base settings
 */
export const updateKnowledgebaseSettings =
  (input: UpdateKnowledgeBaseSettingsInput): AppThunkAction =>
  async (dispatch) => {
    function success(payload: UpdateKnowledgeBaseSettingsInput) {
      return { type: UPDATE_KNOWLEDGE_BASE_SETTINGS_DONE, payload };
    }

    function failure(error: string) {
      return { type: UPDATE_KNOWLEDGE_BASE_SETTINGS_FAIL, error };
    }

    try {
      await KnowledgeBaseClient.updateKnowledgebaseSettings(input);
      dispatch(success(input));
    } catch (e) {
      const { message } = ensureError(e);
      dispatch(failure(message));
    }
  };

/**
 * Update knowledge base settings's tags list with newly added tags id
 */
export const updateKnowledgeBaseSettingsWithNewTags = (tagId: string) => ({
  type: ON_NEW_TAG_ADD_SUCCCESS,
  tagId,
});
