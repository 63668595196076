import React from 'react';
import { BaseMenu } from 'src/legacy/components/Dropdowns/BaseMenu';
import { BaseActionsMenuButton } from 'src/legacy/components/Dropdowns/BaseActionsMenuButton';
import {
  BaseActionsMenuProps as ActionMenuProps,
  Action as ActionType,
} from 'src/constants';
import { makeStyles } from '@material-ui/core';
import { NonHoverBorder, white } from 'src/theme/colors';
import clsx from 'clsx';

export type Action = ActionType;

type BaseActionMenuProps = ActionMenuProps & {
  parentComponentType?: 'toolbar' | 'tableAction';
  preventClickEventPropagation?: boolean;
  contained?: boolean;
};
const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '4px',
    border: `1px solid ${NonHoverBorder}`,
    backgroundColor: white,
    display: 'flex',
    alignItems: 'center',
  },
}));
export const BaseActionsMenu: React.FC<BaseActionMenuProps> = ({
  actions,
  parentComponentType = 'tableAction',
  onActionButtonAnchorElSet,
  preventClickEventPropagation = false,
  contained = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (menuOptions: ActionMenuProps) => {
    const anchorElValue =
      (menuOptions?.menuProps?.anchorEl as HTMLElement) || null;

    setAnchorEl(anchorElValue);

    if (onActionButtonAnchorElSet) {
      onActionButtonAnchorElSet(anchorElValue);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!actions.length) {
    return <div />;
  }

  return (
    <div
      className={clsx({
        [classes.root]: contained,
      })}
    >
      <BaseActionsMenuButton
        parentComponentType={parentComponentType}
        onActionMenuClick={handleClick}
        actions={actions}
      />
      <BaseMenu
        actions={actions}
        preventClickEventPropagation={preventClickEventPropagation}
        menuProps={{
          id: 'actions-menu',
          anchorEl,
          getContentAnchorEl: null,
          keepMounted: true,
          open,
          onClose: handleClose,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
      />
    </div>
  );
};
