import { useContext } from 'react';
import {
  openAuthModal,
  closeAuthModal,
  setFormPageChanged,
  clearAlertMessage,
} from 'src/store/ui/actions';
import { RootState } from 'src/store';
import { RouteContext } from 'src/context';
import * as Constants from 'src/constants';
import { AUTH_STATES } from 'src/constants/authConsts';

import history from 'src/history';
import { setSelectedItemAction } from 'src/store/reduxTypes';
import { UrlUtils } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

const useNavigate = () => {
  const nextPath = useAppSelector((state: RootState) => state.ui.nextPath);
  const forceCustomAppReload = (path: string) => {
    history.replace('/apps');
    setTimeout(() => {
      history.replace(path);
    }, 0);
  };
  const isFormPageChanged = useAppSelector(
    (state: RootState) => state.ui.isFormPageChanged,
  );

  const { isMobile, pathname, query } = useContext(RouteContext);
  const dispatch = useAppDispatch();

  const openDesktopOnlyModal = () =>
    dispatch(openAuthModal(AUTH_STATES.DESKTOP_ONLY_MODAL));

  const handleRouting = (path: string) => {
    if (isMobile && Constants.ONLY_DESKTOP_SUPPORT_PAGES.has(path)) {
      openDesktopOnlyModal();
    } else {
      history.push(path);
    }
  };

  const handleClose = () => {
    dispatch(closeAuthModal());
  };

  const clearPendingStates = () => {
    dispatch(setSelectedItemAction(''));
    dispatch(clearAlertMessage());
  };

  const navigate = (path: string, options?: { forceReload: boolean }) => {
    // determine the full current path by combining the pathname and query string
    let currentPath = `${pathname}`;
    const queryString = UrlUtils.FormatUrlQueryToString(query);
    if (queryString) {
      currentPath += `?${queryString}`;
    }

    if (options?.forceReload && path === currentPath) {
      forceCustomAppReload(path);
    }

    if (path === currentPath) {
      // when path is not changed do nothing
      return;
    }

    if (isFormPageChanged) {
      dispatch(openAuthModal(AUTH_STATES.CONFIRM_PAGE_CHANGE_MODAL, path));
      return;
    }

    // clear any pending states
    clearPendingStates();

    handleRouting(path);
  };

  const navigateBack = () => {
    history.goBack();
  };

  /**
   * use history.push to navigate to the next path and clear pending state if form changed
   * @param path where to navigate to
   */
  const forceNavigate = (path: string) => {
    if (isFormPageChanged) {
      dispatch(setFormPageChanged(false));
    }
    handleRouting(path);
  };

  const handleConfirm = () => {
    if (isFormPageChanged) {
      dispatch(setFormPageChanged(false));
    }
    handleRouting(nextPath);

    handleClose();
  };

  return {
    nextPath,
    navigate,
    handleClose,
    handleConfirm,
    navigateBack,
    forceNavigate,
  };
};

export default useNavigate;
