import React from 'react';
import { makeStyles, Theme, Tooltip, TooltipProps } from '@material-ui/core';
import * as Colors from 'src/theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  tooltipContent: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px ${theme.spacing(
      1,
    )}px ${theme.spacing(1)}px`,
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.DividersAndCardBorders}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const DescriptionTooltip: React.FC<TooltipProps> = ({
  title,
  placement = 'bottom-start',
  children,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={title}
      placement={placement}
      classes={{
        tooltip: classes.tooltipContent,
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
