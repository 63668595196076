import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ThunderstormIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M4.6875 13.75L3.75 15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.3125 16.875L4.6875 18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 13.75L14.6875 15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 16.875L15.625 18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 11.875L7.5 15.625H9.375V18.75L12.5 14.375H10.625L11.25 11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7941 5.97227H15.3203C15.0277 3.31446 12.7398 1.25 10 1.25C8.89919 1.24894 7.82509 1.58889 6.92534 2.22311C6.0256 2.85733 5.3444 3.75468 4.97539 4.7918H4.79688C2.84609 4.7918 1.25 6.38555 1.25 8.33321C1.25 10.2813 2.84609 11.875 4.79688 11.875H15.7941C17.4219 11.875 18.75 10.5469 18.75 8.92344C18.75 7.30039 17.4219 5.97227 15.7941 5.97227Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
