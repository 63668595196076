import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const UploadIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <rect width="20" height="20" rx="3" />
    <path
      d="M15.8334 11.9443V14.5369C15.8334 14.8807 15.6968 15.2104 15.4537 15.4535C15.2106 15.6967 14.8809 15.8332 14.5371 15.8332H5.46304C5.11925 15.8332 4.78953 15.6967 4.54642 15.4535C4.30332 15.2104 4.16675 14.8807 4.16675 14.5369V11.9443"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2408 7.40749L10 4.16675L6.75928 7.40749"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.16675V11.9445"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
