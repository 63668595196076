import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ToggleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 15C17.1364 15 19.375 12.7614 19.375 10C19.375 7.23858 17.1364 5 14.375 5C11.6136 5 9.375 7.23858 9.375 10C9.375 12.7614 11.6136 15 14.375 15Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 5H5.625C2.86358 5 0.625 7.23858 0.625 10C0.625 12.7614 2.86358 15 5.625 15H14.375C17.1364 15 19.375 12.7614 19.375 10C19.375 7.23858 17.1364 5 14.375 5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
