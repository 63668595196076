import React from 'react';
import moment from 'moment';
import { shallowEqual } from 'react-redux';
import { Grid } from '@material-ui/core';
import { v4 } from 'uuid';
import { useAddMySignatureForm } from 'src/legacy/components/Signature/SignatureSidebar/useAddMySignatureForm';
import { useAddDateForm } from '../../UI/Date/useAddDateForm';
import { useAddTextForm } from 'src/legacy/components/Signature/SignatureSidebar/useAddTextForm';
import { SignatureSidebarButton } from 'src/legacy/components/Signature/SignatureComponents/SignatureSidebarButton';
import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  CONTRACT_COMPONENT_DEFAULT_WIDTH,
  CONTRACT_COMPONENT_MINIMUM_WIDTH,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TEXT_SIGNATURE_LABEL,
  SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_WIDTH,
  SignatureComponentType,
} from 'src/constants';
import {
  AddComponentAction,
  AddSignatureFormSubmitType,
  AddPlaceholderComponentAction,
  RemovePlaceholderComponentAction,
  SetSelectedComponent,
  DisableComponentClickaway,
  EnableComponentClickAway,
} from 'src/store/signaturePage/actions';
import {
  ClientTextComponentFields,
  ComponentCoordinatesType,
} from 'src/store/signaturePage/types';
import { SignaturePopoverForm } from 'src/legacy/components/Signature/SignatureComponents';
import { RootState } from 'src/store';
import {
  SignatureDateIcon,
  SignatureIcon,
  SignatureTextIcon,
  SignatureInitialIcon,
} from 'src/legacy/components/Icons';
import { useClientInputLabelForm } from 'src/legacy/components/Signature/SignatureSidebar/useClientInputLabelForm';
import { ensureUnreachable } from 'src/utils/common_utils';
import { InputType } from 'src/services/api';
import {
  getFieldTypeUsingComponentType,
  hasSignatureCompnentsWithDuplicateLabels,
} from 'src/utils/ContractUtils';
import { alertSnackbar } from 'src/store/ui/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

interface SignatureSidebarInputsProps {
  isClientSignatureButton?: boolean;
  signatureClientId?: string;
  signaturePlaceholderComponentElement: HTMLButtonElement | null;
}

interface SignaturePopoverFormValues {
  componentType: SignatureComponentType;
  inputType: InputType;
  isAgree: boolean;
  name: string;
  value: string;
}

export const SignatureSidebarInputs: React.FC<SignatureSidebarInputsProps> = ({
  isClientSignatureButton = false,
  signatureClientId = '',
  signaturePlaceholderComponentElement,
}) => {
  const dispatch = useAppDispatch();
  const [mySignatureModalOpen, setMySignatureModalOpen] = React.useState(false);
  const [myInitialsModalOpen, setMyInitialsModalOpen] = React.useState(false);
  const [dateModalOpen, setDateModalOpen] = React.useState(false);
  const [textModalOpen, setTextModalOpen] = React.useState(false);
  const [clientInputLabelModalOpen, setClientInputLabelModalOpen] =
    React.useState(false);
  const [receiverUserId, setReceiverUserId] = React.useState('');
  const [selectedComponentType, setSelectedComponentType] =
    React.useState<SignatureComponentType | null>(null);
  const [componentDropCoordinates, setComponentDropCoordinates] =
    React.useState({
      xPosition: 0,
      yPosition: 0,
    });
  const [placeholderComponent, setPlaceholderComponent] =
    React.useState<HTMLButtonElement | null>();

  const { pageComponents } = useAppSelector(
    (state: RootState) => ({
      activeClients: state.clients.activeClients,
      selectedChannel: state.files.selectedChannel,
      pageComponents: state.signaturePage.pageComponents,
    }),
    shallowEqual,
  );

  React.useEffect(() => {
    const selectedReceiverUser = pageComponents?.filter(
      (component) => component.receiverUserId,
    );
    if (signatureClientId) {
      setReceiverUserId(signatureClientId);
    } else if (selectedReceiverUser.at(0)?.receiverUserId) {
      setReceiverUserId(selectedReceiverUser.at(0)?.receiverUserId || '');
    }
  }, [signatureClientId, pageComponents]);

  // when client label form is open disable the clickaway listner for signature draggable components
  // this will prevent text signature component from de-selecting when clicked anywhere on the client label form modal
  React.useEffect(() => {
    if (clientInputLabelModalOpen) {
      dispatch(DisableComponentClickaway());
    } else {
      dispatch(EnableComponentClickAway());
    }
  }, [clientInputLabelModalOpen]);

  const addClientComponent = (
    componentType: SignatureComponentType,
    key: string,
    coordinates: ComponentCoordinatesType,
  ) => {
    // add a placeholder for  client text component
    dispatch(
      AddPlaceholderComponentAction({
        componentType,
        key,
        fields: { key, value: '' }, // the fields are a temp object
        ...coordinates,
        height: SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
        width: SIGNATURE_COMPONENT_DEFAULT_WIDTH,
      }),
    );
  };

  const addPlaceholderComponent = (
    componentType: SignatureComponentType,
    key: string,
    coordinates: ComponentCoordinatesType,
  ) => {
    dispatch(
      AddPlaceholderComponentAction({
        componentType,
        key,
        fields: { key, value: '' },
        ...coordinates,
        height: 0,
        width: 0,
      }),
    );
  };

  const saveComponent = async (data: AddSignatureFormSubmitType) => {
    const stagedComponent = { ...data };
    stagedComponent.height = SIGNATURE_COMPONENT_DEFAULT_HEIGHT;
    stagedComponent.width = SIGNATURE_COMPONENT_DEFAULT_WIDTH;
    stagedComponent.fieldType = getFieldTypeUsingComponentType(
      stagedComponent.componentType,
    );
    stagedComponent.height = CONTRACT_COMPONENT_DEFAULT_HEIGHT;
    const isComponentTypeInitial =
      stagedComponent.componentType === SignatureComponentType.OWNER_INITIAL ||
      stagedComponent.componentType === SignatureComponentType.REQUEST_INITIAL;
    stagedComponent.width = isComponentTypeInitial
      ? CONTRACT_COMPONENT_MINIMUM_WIDTH
      : CONTRACT_COMPONENT_DEFAULT_WIDTH;
    dispatch(AddComponentAction(stagedComponent));

    dispatch(SetSelectedComponent(stagedComponent.fields.key));
  };

  const handleComponentDropped = (
    coordinates: ComponentCoordinatesType & ClientTextComponentFields,
    requestComponentType: SignatureComponentType,
    ownerComponentType: SignatureComponentType,
    openModalFunc: (state: boolean) => void,
  ) => {
    setComponentDropCoordinates({ ...coordinates });
    if (isClientSignatureButton) {
      setSelectedComponentType(requestComponentType);
      // go straight to AddComponent
      const key = v4();

      addClientComponent(requestComponentType, key, {
        ...coordinates,
      });
    } else {
      // Add placeholder component
      const key = v4();
      addPlaceholderComponent(ownerComponentType, key, {
        ...coordinates,
      });
      openModalFunc(true);
    }
  };

  // Opens a modal to collect input label and determine if it's a required field when placing a client component.
  const openClientLabelModal = () => {
    if (isClientSignatureButton) {
      setClientInputLabelModalOpen(true);
    }
  };

  const handleSignatureButtonDrop = (coordinates: ComponentCoordinatesType) => {
    // In contract module, we simply add a component if it's a client signature component as we do not need to collect client label
    if (isClientSignatureButton) {
      const totalSignatureComponents = pageComponents.filter(
        (x) =>
          x.componentType === SignatureComponentType.REQUEST_SIGN &&
          x.inputType === InputType.Client,
      ).length;
      const key = v4();
      const formValues = {
        label: `signature_${totalSignatureComponents + 1}`,
        name: 'Signature',
        isOptional: false,
        componentType: SignatureComponentType.REQUEST_SIGN,
        inputType: InputType.Client,
        height: SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
        width: SIGNATURE_COMPONENT_DEFAULT_WIDTH,
        key,
        fields: {
          key,
          value: '',
        },
        ...coordinates,
      };
      saveComponent(formValues);
      setMySignatureModalOpen(false);
    } else {
      handleComponentDropped(
        coordinates,
        SignatureComponentType.REQUEST_SIGN,
        SignatureComponentType.OWNER_SIGN,
        setMySignatureModalOpen,
      );
      openClientLabelModal();
    }
  };

  const handleInitialsButtonDrop = (coordinates: ComponentCoordinatesType) => {
    // In contract module, we simply add a component if it's a client initial component as we do not need to collect client label
    if (isClientSignatureButton) {
      const totalInitialComponents = pageComponents.filter(
        (x) =>
          x.componentType === SignatureComponentType.REQUEST_INITIAL &&
          x.inputType === InputType.Client,
      ).length;
      const key = v4();
      const formValues = {
        label: `initial_${totalInitialComponents + 1}`,
        name: 'Initials',
        isOptional: false,
        componentType: SignatureComponentType.REQUEST_INITIAL,
        inputType: InputType.Client,
        height: SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
        width: SIGNATURE_COMPONENT_DEFAULT_WIDTH,
        key,
        fields: {
          key,
          value: '',
        },
        ...coordinates,
      };
      saveComponent(formValues);
      setMyInitialsModalOpen(false);
    } else {
      handleComponentDropped(
        coordinates,
        SignatureComponentType.REQUEST_INITIAL,
        SignatureComponentType.OWNER_INITIAL,
        setMyInitialsModalOpen,
      );
      openClientLabelModal();
    }
  };

  const handleDateButtonDrop = (coordinates: ComponentCoordinatesType) => {
    // In contract module, we simply add a component if it's a client date component as we do not need to collect client label
    if (isClientSignatureButton) {
      const totalDateComponents = pageComponents.filter(
        (x) =>
          x.componentType === SignatureComponentType.REQUEST_DATE &&
          x.inputType === InputType.Client,
      ).length;
      const key = v4();
      const formValues = {
        label: `date_${totalDateComponents + 1}`,
        name: 'Date',
        isOptional: false,
        componentType: SignatureComponentType.REQUEST_DATE,
        inputType: InputType.Client,
        height: SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
        width: SIGNATURE_COMPONENT_DEFAULT_WIDTH,
        key,
        fields: {
          key,
          value: '',
        },
        ...coordinates,
      };
      saveComponent(formValues);
      setMySignatureModalOpen(false);
    } else {
      handleComponentDropped(
        coordinates,
        SignatureComponentType.REQUEST_DATE,
        SignatureComponentType.OWNER_DATE,
        setDateModalOpen,
      );
      openClientLabelModal();
    }
  };

  const handleTextButtonDrop = (coordinates: ComponentCoordinatesType) => {
    handleComponentDropped(
      coordinates,
      SignatureComponentType.REQUEST_TEXT,
      SignatureComponentType.OWNER_TEXT,
      setTextModalOpen,
    );

    openClientLabelModal();
  };

  const handleCreateComponent = (formValues: any) => {
    const componentData = {
      ...formValues,
      ...componentDropCoordinates,
    };
    saveComponent(componentData);
    dispatch(RemovePlaceholderComponentAction());
  };

  const handleSaveComponent = (
    formValues: any,
    callbackFunction: (state: boolean) => void,
  ) => {
    callbackFunction(false);
    handleCreateComponent(formValues);
  };

  const handleCloseClientInputLabelModal = () => {
    // remove last added client text component key
    setClientInputLabelModalOpen(false);
    // remove the component from page
    dispatch(RemovePlaceholderComponentAction());
  };

  // Update client signature button label
  const handleUpdateClientInputLabel = (formValues: any) => {
    const trimmedLabel =
      formValues.label.trim() || DEFAULT_TEXT_SIGNATURE_LABEL;
    const data = {
      label: trimmedLabel, // if no label is provided by client fallback to default 'Text'
      receiverUserId,
      ...formValues,
    };

    if (isClientSignatureButton) {
      // Check for duplicate labels
      const hasDuplicateLabels = hasSignatureCompnentsWithDuplicateLabels(
        pageComponents,
        trimmedLabel,
      );
      if (hasDuplicateLabels) {
        dispatch(
          alertSnackbar({
            errorMessage: 'This label is already in use. Please try again.',
          }),
        );
        return;
      }
      handleSaveComponent(data, setClientInputLabelModalOpen);
    } else {
      handleSaveComponent(data, setClientInputLabelModalOpen);
    }
  };

  const handleCloseComponent = (callbackFunction: (state: boolean) => void) => {
    callbackFunction(false);
    dispatch(RemovePlaceholderComponentAction());
  };

  React.useEffect(() => {
    setPlaceholderComponent(signaturePlaceholderComponentElement);
  }, [signaturePlaceholderComponentElement]);

  const getEditModalTitleByComponentType = (
    componentType: SignatureComponentType | null,
  ) => {
    if (!componentType) return '';

    switch (componentType) {
      case SignatureComponentType.REQUEST_DATE:
        return 'Client date field';
      case SignatureComponentType.REQUEST_SIGN:
        return 'Client signature field';
      case SignatureComponentType.REQUEST_TEXT:
        return 'Client textfield';
      case SignatureComponentType.REQUEST_INITIAL:
        return 'Client initial field';
      case SignatureComponentType.OWNER_DATE:
      case SignatureComponentType.OWNER_SIGN:
      case SignatureComponentType.OWNER_INITIAL:
      case SignatureComponentType.OWNER_TEXT:
        return '';
      default:
        return ensureUnreachable(componentType);
    }
  };

  const fixedSignatureInputType = {
    inputType: InputType.Fixed,
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SignatureSidebarButton
            label="Signature"
            onDrop={handleSignatureButtonDrop}
            buttonIcon={SignatureIcon}
            isClientSignatureButton={isClientSignatureButton}
          />
        </Grid>
        <Grid item xs={12}>
          <SignatureSidebarButton
            label="Initials"
            onDrop={handleInitialsButtonDrop}
            buttonIcon={SignatureInitialIcon}
            isClientSignatureButton={isClientSignatureButton}
          />
        </Grid>
        <Grid item xs={12}>
          <SignatureSidebarButton
            label="Date"
            onDrop={handleDateButtonDrop}
            buttonIcon={SignatureDateIcon}
            isClientSignatureButton={isClientSignatureButton}
          />
        </Grid>
        <Grid item xs={12}>
          <SignatureSidebarButton
            label="Text"
            onDrop={handleTextButtonDrop}
            buttonIcon={SignatureTextIcon}
            isClientSignatureButton={isClientSignatureButton}
          />
        </Grid>
      </Grid>
      <SignaturePopoverForm
        key="client-textlabel"
        initialFormValue={{
          label: '',
          isOptional: false,
          componentType: selectedComponentType,
          inputType: InputType.Client,
        }}
        title={getEditModalTitleByComponentType(selectedComponentType)}
        actionLabel="Add"
        handleSave={handleUpdateClientInputLabel}
        onClose={handleCloseClientInputLabelModal}
        useFormHook={() => useClientInputLabelForm({ isRequired: true })}
        open={clientInputLabelModalOpen && !!placeholderComponent}
        anchorEl={signaturePlaceholderComponentElement}
      />
      <SignaturePopoverForm
        initialFormValue={{
          name: '',
          isAgree: true,
          componentType: SignatureComponentType.OWNER_SIGN,
          ...fixedSignatureInputType,
        }}
        title="Add your signature"
        actionLabel="Add signature"
        handleSave={(formValues: SignaturePopoverFormValues) =>
          handleSaveComponent(formValues, setMySignatureModalOpen)
        }
        onClose={() => handleCloseComponent(setMySignatureModalOpen)}
        useFormHook={() => useAddMySignatureForm('signature')}
        open={mySignatureModalOpen && !!placeholderComponent}
        anchorEl={signaturePlaceholderComponentElement}
      />

      <SignaturePopoverForm
        initialFormValue={{
          name: '',
          componentType: SignatureComponentType.OWNER_INITIAL,
          ...fixedSignatureInputType,
        }}
        title="Add the initials"
        actionLabel="Add initials"
        handleSave={(formValues: any) =>
          handleSaveComponent(formValues, setMyInitialsModalOpen)
        }
        onClose={() => handleCloseComponent(setMyInitialsModalOpen)}
        useFormHook={() => useAddMySignatureForm('initial')}
        open={myInitialsModalOpen && !!placeholderComponent}
        anchorEl={signaturePlaceholderComponentElement}
      />
      <SignaturePopoverForm
        initialFormValue={{
          value: moment().format(DEFAULT_DATE_FORMAT),
          componentType: SignatureComponentType.OWNER_DATE,
          ...fixedSignatureInputType,
        }}
        title="Add the date"
        actionLabel="Add date"
        handleSave={(formValues: any) =>
          handleSaveComponent(formValues, setDateModalOpen)
        }
        onClose={() => handleCloseComponent(setDateModalOpen)}
        useFormHook={useAddDateForm}
        open={dateModalOpen && !!placeholderComponent}
        anchorEl={signaturePlaceholderComponentElement}
      />
      <SignaturePopoverForm
        initialFormValue={{
          value: '',
          componentType: SignatureComponentType.OWNER_TEXT,
          ...fixedSignatureInputType,
        }}
        title="Add text"
        actionLabel="Add text"
        handleSave={(formValues: any) =>
          handleSaveComponent(formValues, setTextModalOpen)
        }
        onClose={() => handleCloseComponent(setTextModalOpen)}
        useFormHook={() => useAddTextForm({})}
        open={textModalOpen && !!placeholderComponent}
        anchorEl={signaturePlaceholderComponentElement}
      />
    </>
  );
};
