import { isEqual, omit } from 'lodash';
import {
  ClientsState,
  ClientsActionTypes,
  LOAD_CLIENTS_SUCCESS,
  LOAD_ASSIGNED_CLIENTS,
  LOAD_COMPANIES_REQUEST,
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANIES_FAILURE,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENTS_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  INVITE_CLIENT_REQUEST,
  INVITE_CLIENT_SUCCESS,
  INVITE_CLIENT_FAILURE,
  DELETE_CLIENTS_REQUEST,
  DELETE_CLIENTS_SUCCESS,
  DELETE_CLIENTS_FAILED,
  UPDATE_ASSIGNED_CLIENTS,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANIES_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANY_FAILED,
  CLEAR_CLIENTS,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  Company,
  Client,
  SET_CLIENT_CUSTOM_FIELDS,
  LOAD_CLIENT_CUSTOM_FIELDS_REQUEST,
} from 'src/store/clients/types';
import * as ClientStoreTypes from 'src/store/clients/types';
import { User } from 'src/constants';

/**
 * This method is used to get the company names map
 * @param companies - list of companies
 * @returns company names map
 * @example: if we lookup company name for company id 1234
 * companyNamesMap[1234] will return the company name and image url
 * */
const getCompaniesMetaDataMap = (companies: Company[]) =>
  companies.reduce((acc, company) => {
    const { id, fields } = company;
    const {
      name,
      avatarImageURL: avatarUrl,
      fallbackColor,
      isPlaceholder,
    } = fields;
    return {
      ...acc,
      [id]: {
        name,
        avatarUrl,
        avatarFallbackColor: fallbackColor,
        isPlaceholder,
      },
    };
  }, {});

export const initialState: ClientsState = {
  clients: [],
  activeClients: [],
  companies: [],
  companyDataMap: {},
  activeCompanies: [],
  archivedCompanies: [],
  assignedClients: [],
  isLoading: false,
  loadedClients: false,
  isCreating: false,
  createdClient: false,
  isRemoving: false,
  isUpdating: false,
  updatedClient: false,
  isInviting: false,
  invitedClient: false,
  clientCustomFields: null,
  error: '',
  isClientCustomFieldsLoading: false,
  isClientCustomFieldsLoaded: false,
  crmTableSettings: { columnSettings: {} },
  updatingClientsIds: {},
  lastVisitedTab: '',
};

/**
 * Separate companies into:
 * activeCompanies - representing companies that are not placeholders
 * and have at least 1 user that is able to access the Portal
 * @param activeClients - Clients that are not "ARCHIVED"
 * @param companies - All companies returned from backend
 * @returns Object with activeCompanies
 */
const getActiveCompanies = (
  companies: Company[],
): {
  activeCompanies: Company[];
  archivedCompanies: Company[];
} => {
  const activeCompanies: Company[] = [];
  const archivedCompanies: Company[] = [];

  companies.forEach((c) => {
    const company = c;
    if (company.fields.isPlaceholder) {
      return;
    }
    if (company.entityStatus === 'ARCHIVED') {
      archivedCompanies.push(company);
      return;
    }
    activeCompanies.push(company);
  });

  return { activeCompanies, archivedCompanies };
};

const getIntersectingFields = (
  currentFields: Record<string, ClientStoreTypes.CustomFieldOption>,
  deletedFields: Record<string, ClientStoreTypes.CustomFieldOption>,
) => {
  const nonDeletedFields = Object.keys(currentFields)
    .filter((key) => deletedFields[key])
    .reduce<Record<string, ClientStoreTypes.CustomFieldOption>>((obj, key) => {
      obj[key] = currentFields[key];
      return obj;
    }, {});

  return { ...nonDeletedFields };
};

/**
 * This method updates the custom fields state according
 * to the update action type whether it is an delete/add.
 * When it is a delete update action, it calls the getIntersectingFields
 * function to calculate the non-deleted field by doing the
 * intersection between the current fields and their updated state
 * coming from ws event or optimistic change event.
 * @param currentCustomFieldsState : current custom fields state
 * @param payload : updated custom fields state
 * @param isDeleteAction : determines whether it is a delete action
 * @returns
 */
const getUpdatedCustomFields = (
  currentCustomFieldsState: ClientStoreTypes.CustomFieldEntity | null,
  payload: ClientStoreTypes.CustomFieldEntity | null,
  isDeleteAction: boolean,
) =>
  isDeleteAction
    ? {
        ...payload,
        additionalFields: getIntersectingFields(
          currentCustomFieldsState?.additionalFields || {},
          payload?.additionalFields || {},
        ),
      }
    : {
        ...(currentCustomFieldsState || {}),
        ...(payload || {}),
        additionalFields: {
          ...(currentCustomFieldsState?.additionalFields || {}),
          ...(payload?.additionalFields || {}),
        },
      };

export const getUpdatedClients = (
  updatingClients: Client[],
  existingClients: Client[],
) => {
  const existingClientIDsInState: string[] = existingClients.map((c) => c.id);
  const newClients: Client[] = updatingClients.filter(
    (updatingClient) => !existingClientIDsInState.includes(updatingClient.id),
  );
  // we consolidate non-existing upserted clients with existing clients and check the full list against updating clients
  return [...existingClients, ...newClients].map((client) => {
    const clientWithUpdates = updatingClients.find((uc) => uc.id === client.id);
    if (clientWithUpdates) {
      // skip set custom fields when new entity is before the
      // the existing entity
      const updatedClientCustomFields =
        new Date(clientWithUpdates.updatedDate) > new Date(client.updatedDate)
          ? clientWithUpdates.fields.customFields
          : client.fields.customFields;
      const updatedClient = {
        ...client,
        updatedDate: clientWithUpdates.updatedDate,
        fields: {
          ...client.fields,
          ...clientWithUpdates.fields,
          companyId: clientWithUpdates.fields.companyId,
          customFields: { ...updatedClientCustomFields },
        },
        additionalFields: clientWithUpdates.additionalFields,
      };
      return updatedClient;
    }
    return client;
  });
};

const clientsReducer = (state = initialState, action: ClientsActionTypes) => {
  switch (action.type) {
    case CLEAR_CLIENTS: {
      return initialState;
    }
    case LOAD_CLIENTS_SUCCESS: {
      const { clients, activeClients, assignedClients } = action;

      return {
        ...state,
        activeClients,
        isLoading: false,
        loadedClients: true,
        clients,
        assignedClients,
        error: '',
      };
    }
    case LOAD_ASSIGNED_CLIENTS: {
      const { assignedClients } = action;
      return {
        ...state,
        assignedClients,
      };
    }
    case LOAD_COMPANIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case LOAD_COMPANIES_SUCCESS: {
      const companies = action.payload;
      const { activeCompanies, archivedCompanies } =
        getActiveCompanies(companies);

      return {
        ...state,
        isLoading: false,
        loadedClients: true,
        companies,
        activeCompanies,
        archivedCompanies,
        companyDataMap: getCompaniesMetaDataMap(companies),
        error: '',
      };
    }
    case LOAD_COMPANIES_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isLoading: false,
        loadedClients: false,
        error,
      };
    }
    case ADD_CLIENT_REQUEST:
      return {
        ...state,
        isCreating: true,
        createdClient: false,
        error: '',
      };
    case ADD_CLIENT_SUCCESS: {
      const { isWsEvent } = action;
      const newClients: User[] = action.payload;
      const { clients } = state;
      const { activeClients, assignedClients } = state;

      const currentClientIds = clients.map((client) => client.id);
      const nonExistingNewClients = newClients.filter(
        (c) => !currentClientIds.includes(c.id),
      );

      const activeClientIds = activeClients.map((client) => client.id);
      const nonExistingNewActiveClients = newClients.filter(
        (c) => !activeClientIds.includes(c.id),
      );
      const assigneeClientIds = assignedClients.map((client) => client.id);
      const nonExistingNewAssigneeClients = newClients.filter(
        (c) => !assigneeClientIds.includes(c.id),
      );

      return {
        ...state,
        isCreating: false,
        createdClient: isWsEvent ? state.createdClient : true,
        clients: [...state.clients, ...nonExistingNewClients],
        activeClients: [...state.activeClients, ...nonExistingNewActiveClients],
        assignedClients: [
          ...state.assignedClients,
          ...nonExistingNewAssigneeClients,
        ],
        error: isWsEvent ? state.error : '',
      };
    }
    case ADD_CLIENT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreating: false,
        createdClient: false,
        error,
      };
    }
    case DELETE_CLIENTS_REQUEST:
      return {
        ...state,
        isRemoving: true,
        error: null,
      };

    case DELETE_CLIENTS_SUCCESS: {
      const { payload, isWsEvent } = action;
      const updatedData = state.activeClients.filter(
        (client) => !payload.includes(client.id),
      );
      const updatedAssigneeData = state.assignedClients.filter(
        (client) => !payload.includes(client.id),
      );

      // update all clients with new entity status
      const updatedAllClients = state.clients.map((client) => {
        if (payload.includes(client.id)) {
          return { ...client, entityStatus: 'DELETED' };
        }
        return client;
      });

      return {
        ...state,
        activeClients: updatedData,
        clients: updatedAllClients,
        assignedClients: updatedAssigneeData,
        isRemoving: false,
        error: isWsEvent ? state.error : '',
      };
    }

    case DELETE_CLIENTS_FAILED: {
      return {
        ...state,
        isRemoving: false,
        error: action.error,
      };
    }
    case DELETE_COMPANY_REQUEST:
      return {
        ...state,
        isRemoving: true,
        error: null,
      };

    case DELETE_COMPANIES_SUCCESS: {
      const { payload: deleteCompanyIds } = action;
      const updatedActiveCompanies = state.activeCompanies.filter(
        (c) => !deleteCompanyIds.includes(c.id),
      );

      return {
        ...state,
        activeCompanies: updatedActiveCompanies,
        isRemoving: false,
        error: null,
      };
    }

    case DELETE_COMPANY_FAILED: {
      return {
        ...state,
        isRemoving: false,
        error: action.error,
      };
    }

    case ADD_COMPANY_REQUEST:
      return {
        ...state,
        isCreating: true,
        createdClient: false,
        error: '',
      };
    case ADD_COMPANY_SUCCESS: {
      const newCompanies: Company[] = action.payload;
      const { companies } = state;
      const currentCompanyIDs = companies.map((company) => company.id);
      const nonExistingNewCompanies = newCompanies.filter(
        (c) => !currentCompanyIDs.includes(c.id),
      );
      const allCompanies = [...nonExistingNewCompanies, ...companies];
      const { activeCompanies, archivedCompanies } =
        getActiveCompanies(allCompanies);
      return {
        ...state,
        isCreating: false,
        createdClient: true,
        companies: allCompanies,
        activeCompanies,
        archivedCompanies,
        companyDataMap: getCompaniesMetaDataMap(allCompanies),
        error: '',
      };
    }
    case ADD_COMPANY_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreating: false,
        createdClient: false,
        error,
      };
    }

    case UPDATE_CLIENT_REQUEST: {
      return {
        ...state,
        isUpdating: true,
        updatedClient: false,
        updatingClientsIds: {
          ...state.updatingClientsIds,
          [action.updatingClientId]: true,
        },
        error: null,
      };
    }
    case UPDATE_CLIENTS_SUCCESS: {
      const { isWsEvent } = action;
      const updatingClients: Client[] = action.payload;
      const updatingClientId = updatingClients.at(0)?.id;
      if (!updatingClientId) {
        return state;
      }

      return {
        ...state,
        activeClients: getUpdatedClients(updatingClients, state.activeClients),
        isUpdating: false,
        updatingClientsIds: {
          ...state.updatingClientsIds,
          [updatingClientId]: false,
        },
        updatedClient: isWsEvent ? state.updatedClient : true,
        error: isWsEvent ? state.error : null,
      };
    }
    case UPDATE_ASSIGNED_CLIENTS: {
      const { assignedCompanies, clients } = action.payload;
      let assignedClients = getUpdatedClients(clients, state.assignedClients);
      const allClients = getUpdatedClients(clients, state.clients);
      if (assignedCompanies) {
        // if an assignedCompanies list is provided, use it as a filter for calculating assigned clients
        assignedClients = assignedClients.filter((c) =>
          assignedCompanies.includes(c.fields.companyId || ''),
        );
      }
      return {
        ...state,
        assignedClients,
        clients: allClients,
      };
    }
    case UPDATE_CLIENT_FAILURE: {
      const { updatingClientsIds } = state;
      // when update fails, we should set the updating client
      // id map object to false
      updatingClientsIds[action.updatingClientId] = false;
      return {
        ...state,
        isUpdating: false,
        updatedClient: false,
        error: action.error,
        updatingClientsIds: { ...updatingClientsIds },
      };
    }

    case INVITE_CLIENT_REQUEST:
      return {
        ...state,
        isInviting: true,
        invitedClient: false,
        error: null,
      };

    case INVITE_CLIENT_SUCCESS: {
      const { invitedUserId, invitedByUserId } = action;
      let updatedClient = state.activeClients.find(
        (client) => client.id === invitedUserId,
      );

      if (updatedClient) {
        const { fields } = updatedClient;
        updatedClient = {
          ...updatedClient,
          fields: {
            ...fields,
            invitedBy: invitedByUserId,
          },
        };
      }

      let updatedData = state.activeClients.filter(
        (client) => client.id !== invitedUserId,
      );
      if (updatedClient) {
        updatedData = updatedData.concat([updatedClient]);
      }

      return {
        ...state,
        activeClients: updatedData,
        isUpdating: false,
        updatedClient: true,
        error: null,
      };
    }

    case INVITE_CLIENT_FAILURE: {
      return {
        ...state,
        isInviting: false,
        invitedClient: false,
        error: action.error,
      };
    }
    case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        isUpdating: true,
        updatedClient: false,
        error: null,
      };

    case UPDATE_COMPANIES_SUCCESS: {
      let updatedCompanies: Company[] = [];
      const updatingCompanies: Company[] = action.payload;
      const updatingCompanyIDs: string[] = updatingCompanies.map((c) => c.id);
      state.companies.forEach((c) => {
        const updatedCompanyItem: Company | undefined =
          updatingCompanyIDs.includes(c.id)
            ? updatingCompanies.find(
                (updatingCompany) => updatingCompany.id === c.id,
              )
            : c;
        if (updatedCompanyItem) {
          updatedCompanies = updatedCompanies.concat(updatedCompanyItem);
        }
      });

      const { activeCompanies, archivedCompanies } =
        getActiveCompanies(updatedCompanies);

      return {
        ...state,
        companies: updatedCompanies,
        activeCompanies,
        archivedCompanies,
        isUpdating: false,
        updatedClient: true,
        error: null,
      };
    }
    case UPDATE_COMPANY_SUCCESS: {
      const { payload } = action;
      const companyToUpdate = state.companies.find(
        (company) => company.id === payload.companyId,
      );

      let updatedData = state.companies.filter(
        (company) => company.id !== payload.companyId,
      );

      if (companyToUpdate) {
        const updatedCompany = {
          ...companyToUpdate,
          fields: {
            ...companyToUpdate.fields,
            ...(payload?.companyName && { name: payload.companyName }),
            ...(payload?.isPlaceholder !== undefined && {
              isPlaceholder: payload.isPlaceholder,
            }),
            ...(payload?.customerId && { customerId: payload.customerId }),
            ...(payload?.leadUserID && { leadUserID: payload.leadUserID }),
          },
          ...(payload?.additionalFields && {
            additionalFields: payload?.additionalFields,
          }),
        };

        updatedData = updatedData.concat(updatedCompany);
      }
      const { activeCompanies, archivedCompanies } =
        getActiveCompanies(updatedData);

      return {
        ...state,
        companies: updatedData,
        activeCompanies,
        archivedCompanies,
        isUpdating: false,
        updatedClient: true,
        error: null,
      };
    }

    case UPDATE_COMPANY_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        updatedClient: false,
        error: action.error,
      };
    }

    case SET_CLIENT_CUSTOM_FIELDS: {
      const { payload, error, isRemove } = action;
      // skip set client custom fields if new entity updatedDate is before
      // existing custom entity updatedData
      if (state.clientCustomFields && payload) {
        const isCustomFieldsEqual = isEqual(
          omit(state.clientCustomFields, 'updatedDate'),
          omit(payload, 'updatedDate'),
        );
        if (isCustomFieldsEqual) {
          return state;
        }
      }

      return {
        ...state,
        // when action is removal, replace entire store field
        // otherwise apply an update to replace existing fields
        clientCustomFields: getUpdatedCustomFields(
          state.clientCustomFields,
          payload,
          isRemove,
        ),
        isClientCustomFieldsLoading: false,
        isClientCustomFieldsLoaded: true,
        error,
      };
    }

    case LOAD_CLIENT_CUSTOM_FIELDS_REQUEST: {
      return {
        ...state,
        isClientCustomFieldsLoading: true,
        isClientCustomFieldsLoaded: false,
      };
    }

    case ClientStoreTypes.UPDATE_CRM_TABLE_COLUMN_SETTINGS: {
      return {
        ...state,
        crmTableSettings: {
          ...state.crmTableSettings,
          columnSettings: action.columnSettings,
        },
      };
    }

    case ClientStoreTypes.SET_CRM_LAST_VISITED_TAB: {
      return {
        ...state,
        lastVisitedTab: action.payload,
      };
    }

    default:
      return state;
  }
};

export default clientsReducer;
