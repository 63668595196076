import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DesktopIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 2.5H2.5C1.80964 2.5 1.25 3.05964 1.25 3.75V13.75C1.25 14.4404 1.80964 15 2.5 15H17.5C18.1904 15 18.75 14.4404 18.75 13.75V3.75C18.75 3.05964 18.1904 2.5 17.5 2.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 17.5L11.5625 15H8.4375L8.125 17.5H11.875Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 17.5H5.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 11.875V13.75C1.25093 14.0812 1.38292 14.3986 1.61714 14.6329C1.85136 14.8671 2.16876 14.9991 2.5 15H17.5C17.8312 14.9991 18.1486 14.8671 18.3829 14.6329C18.6171 14.3986 18.7491 14.0812 18.75 13.75V11.875H1.25ZM10 14.375C9.87639 14.375 9.75555 14.3383 9.65277 14.2697C9.54999 14.201 9.46988 14.1034 9.42258 13.9892C9.37527 13.875 9.36289 13.7493 9.38701 13.6281C9.41112 13.5068 9.47065 13.3955 9.55806 13.3081C9.64547 13.2206 9.75683 13.1611 9.87807 13.137C9.99931 13.1129 10.125 13.1253 10.2392 13.1726C10.3534 13.2199 10.451 13.3 10.5197 13.4028C10.5883 13.5055 10.625 13.6264 10.625 13.75C10.625 13.9158 10.5592 14.0747 10.4419 14.1919C10.3247 14.3092 10.1658 14.375 10 14.375Z"
      fill="currentColor"
    />
  </SvgIcon>
);
