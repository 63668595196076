import React from 'react';
import {
  makeStyles,
  createStyles,
  MenuItemProps,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import { BlackHeadings, GraySmall, HoverBackground } from 'src/theme/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2.5),
      height: 48,
      color: GraySmall,
      display: 'flex',
      alignItems: 'center',
    },
    selectedItem: {
      backgroundColor: HoverBackground,
      color: BlackHeadings,
    },
  }),
);

export const BaseResultItem: React.FC<MenuItemProps & { active: boolean }> = ({
  children,
  active,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, { [classes.selectedItem]: active })}>
      {children}
    </div>
  );
};
