import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { InboxContent } from 'src/legacy/components/Inbox/InboxContent';
import { InboxSidebar } from 'src/legacy/components/Inbox/InboxSidebar';
import { InboxPageContext } from 'src/context/inboxPageContext';
import { LoadingWrapper } from 'src/legacy/components/Loading';
import { useGetInboxNotificationsQuery } from 'src/services/api/inboxApi';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      overflow: 'hidden',
    },
  }),
);

export const InboxPage = () => {
  const classes = useStyles();

  const { isLoading: areAllNotificationsLoading } =
    useGetInboxNotificationsQuery();

  const [selectedNotification, setSelectedNotification] = React.useState(null);

  const inboxPageContextValue = React.useMemo(
    () => ({
      selectedNotification,
      setSelectedNotification,
    }),
    [selectedNotification, setSelectedNotification],
  );

  return (
    <InboxPageContext.Provider value={inboxPageContextValue}>
      <LoadingWrapper
        hideContentWhileLoading
        isLoading={areAllNotificationsLoading}
      >
        <div className={classes.container}>
          <InboxSidebar />
          <InboxContent />
        </div>
      </LoadingWrapper>
    </InboxPageContext.Provider>
  );
};
