import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PrintIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15 14.375H15.9375C16.3515 14.3738 16.7482 14.2087 17.041 13.916C17.3337 13.6232 17.4988 13.2265 17.5 12.8125V6.5625C17.4988 6.14848 17.3337 5.75177 17.041 5.45901C16.7482 5.16625 16.3515 5.00124 15.9375 5H4.0625C3.64848 5.00124 3.25177 5.16625 2.95901 5.45901C2.66625 5.75177 2.50124 6.14848 2.5 6.5625V12.8125C2.50124 13.2265 2.66625 13.6232 2.95901 13.916C3.25177 14.2087 3.64848 14.3738 4.0625 14.375H5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M14.05 9.375H5.95C5.42533 9.375 5 9.80033 5 10.325V16.55C5 17.0747 5.42533 17.5 5.95 17.5H14.05C14.5747 17.5 15 17.0747 15 16.55V10.325C15 9.80033 14.5747 9.375 14.05 9.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M15 5V4.0625C14.9988 3.64848 14.8337 3.25177 14.541 2.95901C14.2482 2.66625 13.8515 2.50124 13.4375 2.5H6.5625C6.14848 2.50124 5.75177 2.66625 5.45901 2.95901C5.16625 3.25177 5.00124 3.64848 5 4.0625V5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M15.3125 8.125C15.8303 8.125 16.25 7.70527 16.25 7.1875C16.25 6.66973 15.8303 6.25 15.3125 6.25C14.7947 6.25 14.375 6.66973 14.375 7.1875C14.375 7.70527 14.7947 8.125 15.3125 8.125Z"
      fill="currentColor"
    />
  </SvgIcon>
);
