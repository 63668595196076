import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const QrCodeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.9375 13.125H13.4375C13.2649 13.125 13.125 13.2649 13.125 13.4375V15.9375C13.125 16.1101 13.2649 16.25 13.4375 16.25H15.9375C16.1101 16.25 16.25 16.1101 16.25 15.9375V13.4375C16.25 13.2649 16.1101 13.125 15.9375 13.125Z"
      fill="currentColor"
    />
    <path
      d="M12.8125 10.625H10.9375C10.7649 10.625 10.625 10.7649 10.625 10.9375V12.8125C10.625 12.9851 10.7649 13.125 10.9375 13.125H12.8125C12.9851 13.125 13.125 12.9851 13.125 12.8125V10.9375C13.125 10.7649 12.9851 10.625 12.8125 10.625Z"
      fill="currentColor"
    />
    <path
      d="M18.4375 16.25H16.5625C16.3899 16.25 16.25 16.3899 16.25 16.5625V18.4375C16.25 18.6101 16.3899 18.75 16.5625 18.75H18.4375C18.6101 18.75 18.75 18.6101 18.75 18.4375V16.5625C18.75 16.3899 18.6101 16.25 18.4375 16.25Z"
      fill="currentColor"
    />
    <path
      d="M18.4375 10.625H17.1875C17.0149 10.625 16.875 10.7649 16.875 10.9375V12.1875C16.875 12.3601 17.0149 12.5 17.1875 12.5H18.4375C18.6101 12.5 18.75 12.3601 18.75 12.1875V10.9375C18.75 10.7649 18.6101 10.625 18.4375 10.625Z"
      fill="currentColor"
    />
    <path
      d="M12.1875 16.875H10.9375C10.7649 16.875 10.625 17.0149 10.625 17.1875V18.4375C10.625 18.6101 10.7649 18.75 10.9375 18.75H12.1875C12.3601 18.75 12.5 18.6101 12.5 18.4375V17.1875C12.5 17.0149 12.3601 16.875 12.1875 16.875Z"
      fill="currentColor"
    />
    <path
      d="M15.9375 3.75H13.4375C13.2649 3.75 13.125 3.88991 13.125 4.0625V6.5625C13.125 6.73509 13.2649 6.875 13.4375 6.875H15.9375C16.1101 6.875 16.25 6.73509 16.25 6.5625V4.0625C16.25 3.88991 16.1101 3.75 15.9375 3.75Z"
      fill="currentColor"
    />
    <path
      d="M17.5 1.875H11.875C11.5298 1.875 11.25 2.15482 11.25 2.5V8.125C11.25 8.47018 11.5298 8.75 11.875 8.75H17.5C17.8452 8.75 18.125 8.47018 18.125 8.125V2.5C18.125 2.15482 17.8452 1.875 17.5 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5625 3.75H4.0625C3.88991 3.75 3.75 3.88991 3.75 4.0625V6.5625C3.75 6.73509 3.88991 6.875 4.0625 6.875H6.5625C6.73509 6.875 6.875 6.73509 6.875 6.5625V4.0625C6.875 3.88991 6.73509 3.75 6.5625 3.75Z"
      fill="currentColor"
    />
    <path
      d="M8.125 1.875H2.5C2.15482 1.875 1.875 2.15482 1.875 2.5V8.125C1.875 8.47018 2.15482 8.75 2.5 8.75H8.125C8.47018 8.75 8.75 8.47018 8.75 8.125V2.5C8.75 2.15482 8.47018 1.875 8.125 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5625 13.125H4.0625C3.88991 13.125 3.75 13.2649 3.75 13.4375V15.9375C3.75 16.1101 3.88991 16.25 4.0625 16.25H6.5625C6.73509 16.25 6.875 16.1101 6.875 15.9375V13.4375C6.875 13.2649 6.73509 13.125 6.5625 13.125Z"
      fill="currentColor"
    />
    <path
      d="M8.125 11.25H2.5C2.15482 11.25 1.875 11.5298 1.875 11.875V17.5C1.875 17.8452 2.15482 18.125 2.5 18.125H8.125C8.47018 18.125 8.75 17.8452 8.75 17.5V11.875C8.75 11.5298 8.47018 11.25 8.125 11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
