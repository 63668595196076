import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FemaleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 13.125C13.2792 13.125 15.9375 10.4667 15.9375 7.1875C15.9375 3.90831 13.2792 1.25 10 1.25C6.72081 1.25 4.0625 3.90831 4.0625 7.1875C4.0625 10.4667 6.72081 13.125 10 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.125V18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2656 16.25H7.73438"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
