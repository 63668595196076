import { makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import * as React from 'react';
import MemoBaseTypography from 'src/legacy/components/Text/BaseTypography';

type TimelineItemData = {
  icon: React.ReactNode;
  id: string;
  title: string;
  subTitle: string;
};
interface Props {
  items: TimelineItemData[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    separator: {
      marginRight: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '18px',
    },
    title: {
      height: '36px',
      display: 'flex',
      flexDirection: 'column',
    },
    connector: {
      height: '36px',
      borderLeft: '1px dashed #ccc',
      marginTop: '18px',
    },
  }),
);

export const Timeline: React.FC<Props> = ({ items }) => {
  const classes = useStyles();
  return (
    <>
      {items.map((item, index) => (
        // care should be taken to not pass duplicate ids.
        // If there are duplicated ids,
        // it will create orphan nodes and the timeline events may leak into unrelated notification type
        <div className={classes.root} key={item.id}>
          <div className={classes.separator}>
            {item.icon}
            {index < items.length - 1 && <div className={classes.connector} />}
          </div>
          <div>
            <MemoBaseTypography fontType="13Medium">
              {item.title}
            </MemoBaseTypography>
            <MemoBaseTypography fontType="13Regular">
              {item.subTitle}
            </MemoBaseTypography>
          </div>
        </div>
      ))}
    </>
  );
};
