import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HamburgerIcon = (props: SvgIconProps) => (
  <SvgIcon fill="none" viewBox="0 0 20 20" {...props}>
    <path
      d="M1 10H19"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 4H19"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 16H19"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
