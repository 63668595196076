import React from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  backgroundColor?: string;
  checkMarkColor?: string;
  className?: string;
}

export const CheckedCircleIcon = (props?: Props) => {
  const {
    backgroundColor = 'currentColor',
    checkMarkColor,
    className,
  } = props || {};
  return (
    <SvgIcon viewBox="0 0 20 20" className={className}>
      <circle cx="10" cy="10" r="10" fill={`${backgroundColor}`} />
      <path
        fill={checkMarkColor ?? ''}
        d="M8.26558 11.9657L5.98099 9.5968L5 10.614L8.26558 14L15 7.01717L14.019 6L8.26558 11.9657Z"
      />
    </SvgIcon>
  );
};
