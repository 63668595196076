import * as React from 'react';
import { SetFieldHandler } from 'src/constants/fieldsTypes';
import { ComboBoxOption } from 'src/legacy/components/Select/ComboBox';
import { ChannelMemberSelect } from 'src/legacy/components/MultiSelect/ChannelMemberSelect';
import { useAppSelector } from 'src/hooks/useStore';
interface RecipientSelectionProps {
  value: string;
  fieldName: string;
  setFieldValue: SetFieldHandler;
  disabled?: boolean;
  label?: string;
  hideCompanies?: boolean;
  onChange?: (value: string) => void;
  openOnFocus?: boolean;
  error?: boolean;
  helperText?: string;
  popupIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
}
const RecipientSelection: React.FC<RecipientSelectionProps> = ({
  value,
  fieldName,
  setFieldValue,
  disabled,
  label,
  hideCompanies = false,
  onChange,
  openOnFocus = false,
  error,
  helperText,
  popupIcon,
  closeIcon = null,
}) => {
  const clients = useAppSelector((state) => state.clients.activeClients);
  const companies = useAppSelector((state) => state.clients.companies);

  React.useEffect(() => {
    if (!value) {
      setFieldValue(fieldName, '');
    }
  }, []);

  const handleRecipientChange = async (_: any, vals: any[] | any) => {
    const newValue = vals as ComboBoxOption;
    if (newValue && newValue.id) {
      // if we don't add "await" here
      // formik errors will include "recipientId" required error
      // even after setting value for "recipientId"
      // For more info :https://github.com/jaredpalmer/formik/issues/2059
      await setFieldValue(fieldName, newValue.id);
      const selectedClient = clients.find(
        (client) => client.id === newValue.id,
      );

      const selectedCompany = companies.find(
        (company) => company.id === newValue.id,
      );

      if (selectedClient) {
        setFieldValue('fields.clientUserId', selectedClient.id);
        setFieldValue('fields.companyId', selectedClient.fields.companyId);
      }

      if (selectedCompany) {
        setFieldValue('fields.clientUserId', '');
        setFieldValue('fields.companyId', selectedCompany.id);
      }
    } else {
      setFieldValue(fieldName, '');
      setFieldValue('fields.clientUserId', '');
      setFieldValue('fields.companyId', '');
    }
    onChange?.(newValue.id);
  };

  return (
    <ChannelMemberSelect
      error={error}
      helperText={helperText}
      fieldName={fieldName}
      label={label}
      blurOnSelect
      memberIds={value}
      onChange={handleRecipientChange}
      id="recipient-selection"
      multiple={false}
      disabled={disabled}
      excludeEmptyCompany
      allowMultipleCompanies={false}
      hideCompanies={hideCompanies}
      additionalComboBoxProps={{
        inputProps: {
          autoFocus: true,
        },
        openOnFocus: openOnFocus,
      }}
      closeIcon={closeIcon}
      popupIcon={popupIcon}
    />
  );
};

export default RecipientSelection;
