import {
  LOAD_CRM_PROPERTIES_FIELDS,
  LOAD_CRM_PROPERTIES_ID,
  TablePropertiesActionTypes,
  TablePropertiesState,
} from 'src/store/tableProperties/types';

const initialState: TablePropertiesState = {};

const tableProperties = (
  /* eslint-disable-next-line default-param-last */
  state = initialState,
  action: TablePropertiesActionTypes,
) => {
  switch (action.type) {
    case LOAD_CRM_PROPERTIES_FIELDS:
      return {
        ...state,
        crmProperties: action.payload,
      };
    case LOAD_CRM_PROPERTIES_ID:
      return {
        ...state,
        crmPropertiesId: action.payload,
      };
    default:
      return state;
  }
};

export default tableProperties;
