import { ApiMethods, appAPI } from '.';
import {
  ImporterPreviewData,
  BulkImportEntityData,
  ImportEntityType,
  ValidationResultType,
  MappedColumnsType,
} from 'src/legacy/components/ClientsImportPage/clientImportTypes';

export enum ImporterStatus {
  INITIALIZED = 'initialized',
  STARTED = 'started',
  COMPLETED = 'completed',
  FAILED = 'failed',
  PROGRESS = 'progress',
  MAPPED = 'mapped',
}

export type ImportProgressDataType = {
  Status: string;
  Progress: {
    Total: number;
    Done: number;
  };
};

type CompanyDetailsType = {
  name: string;
  leadUserID: string;
  assignedUserIDs: Array<string>;
  fallbackColor: string;
  avatarImageUrl: string;
};
export type PreviewClientType = {
  givenName: string;
  familyName: string;
  email: string;
  companyDetails: CompanyDetailsType;
  customFields?: {
    items: any; // TODO add type
  };
};
export type SaveMappingResponse = {
  new: Array<PreviewClientType>;
  skipped: Array<PreviewClientType>;
  updated: Array<PreviewClientType>;
};

type BulkValidateInput = {
  id: string;
  mappedColumns: MappedColumnsType;
};

export const BULK_IMPORT_DATA_PREVIEW_LIMIT = 4;
const importerApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createBulkImport: build.mutation<
      BulkImportEntityData,
      { s3FileKey: string; importType: ImportEntityType }
    >({
      query: ({ s3FileKey, importType }) => ({
        path: `/importer/create/${importType}`,
        method: ApiMethods.post,
        options: {
          body: {
            s3FileKey,
          },
        },
      }),
    }),
    getBulkImportPreviewData: build.query<
      ImporterPreviewData,
      { importerId: string }
    >({
      query: ({ importerId }) => ({
        path: `/importer/${importerId}/data`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: { limit: BULK_IMPORT_DATA_PREVIEW_LIMIT },
        },
      }),
    }),
    validateColumn: build.query<
      ValidationResultType,
      { importerId: string; columnIndex: number; matchFieldName: string }
    >({
      query: ({ importerId, columnIndex, matchFieldName }) => ({
        path: `/importer/${importerId}/validate/${columnIndex}/${matchFieldName}`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: {},
        },
      }),
    }),
    saveMapping: build.mutation<
      SaveMappingResponse,
      { importerId: string; mapping: Record<string, string> }
    >({
      query: ({ importerId, mapping }) => ({
        path: `/importer/${importerId}/map`,
        method: ApiMethods.post,
        options: {
          body: {
            Mapping: mapping,
          },
        },
      }),
    }),
    runImporterJob: build.mutation<
      { status: ImporterStatus }, // todo: add type
      { importerId: string; inviteClients: boolean }
    >({
      query: ({ importerId, inviteClients }) => ({
        path: `/importer/${importerId}/run`,
        method: ApiMethods.post,
        options: {
          body: {
            inviteClients,
          },
        },
      }),
    }),
    getImporterStatus: build.query<
      ImportProgressDataType,
      { importerId: string }
    >({
      query: ({ importerId }) => ({
        path: `/importer/${importerId}/status`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: {},
        },
      }),
    }),
    bulkValidate: build.mutation<
      Record<string, ValidationResultType>,
      BulkValidateInput
    >({
      query: ({ id, mappedColumns }) => ({
        path: `/importer/${id}/validate`,
        method: ApiMethods.post,
        options: {
          body: {
            mappedColumns,
          },
        },
      }),
    }),
  }),
});

export const {
  useCreateBulkImportMutation,
  useGetBulkImportPreviewDataQuery,
  useValidateColumnQuery,
  useSaveMappingMutation,
  useRunImporterJobMutation,
  useGetImporterStatusQuery,
  useBulkValidateMutation,
} = importerApi;
