import { copilotDotCom, copilotApp } from 'src/constants/hostnameConsts';

export const internalCopilotDomainProd = copilotDotCom.prod;
export const internalCopilotDomainStaging = copilotDotCom.staging;

export const copilotDashboardSubdomain = 'dashboard';

// copilot hostnames
export const copilotDashboardHostnameProd = `${copilotDashboardSubdomain}.${copilotDotCom.prod}`;
export const copilotDashboardHostnameStaging = `${copilotDashboardSubdomain}.${copilotDotCom.staging}`;
export const copilotAppDashboardHostnameProd = `${copilotDashboardSubdomain}.${copilotApp.prod}`;
export const copilotAppDashboardHostnameStaging = `${copilotDashboardSubdomain}.${copilotApp.staging}`;

export const prodClientUserDomain = copilotApp.prod;
export const devClientUserDomain = copilotApp.staging;

export const copilotClientUserDomains = [
  prodClientUserDomain,
  devClientUserDomain,
  '127.0.0.1',
];
