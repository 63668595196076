import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BoatIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M18.0445 10.1973C17.9663 10.0113 17.7824 9.8914 17.5886 9.82656L10.2769 6.91719C10.0967 6.86094 9.9036 6.86094 9.72338 6.91719L2.41869 9.82812C2.22338 9.88906 2.03471 10.0145 1.95736 10.2004C1.88002 10.3863 1.84252 10.5664 1.9183 10.7895L3.73588 15.4437C3.75905 15.501 3.79979 15.5494 3.85223 15.5819C3.90468 15.6145 3.96611 15.6296 4.02768 15.625C5.07025 15.5594 5.99291 15.0301 6.69877 14.3555C6.75425 14.3033 6.82752 14.2743 6.90365 14.2743C6.97978 14.2743 7.05305 14.3033 7.10854 14.3555C7.86322 15.0781 8.86752 15.625 10.0003 15.625C11.1331 15.625 12.1316 15.0781 12.8863 14.3539C12.9418 14.3017 13.0152 14.2727 13.0913 14.2727C13.1675 14.2727 13.2409 14.3017 13.2964 14.3539C14.0023 15.0289 14.9249 15.5594 15.9675 15.625C16.0289 15.6297 16.0902 15.6147 16.1425 15.5824C16.1949 15.55 16.2356 15.5019 16.2589 15.4449L18.0831 10.7902C18.1605 10.6035 18.1218 10.3836 18.0445 10.1973Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M16.2498 18.482C16.2498 18.434 16.2369 18.387 16.2124 18.3457C16.188 18.3045 16.1529 18.2706 16.1108 18.2476C15.053 17.6793 14.0885 16.8093 13.6643 16.3726C13.6013 16.3065 13.5172 16.2645 13.4265 16.2537C13.3358 16.2429 13.2442 16.2641 13.1674 16.3136C11.2014 17.58 8.80765 17.5808 6.84125 16.3156C6.76382 16.2656 6.67132 16.2443 6.57983 16.2554C6.48834 16.2666 6.40364 16.3094 6.34046 16.3765C5.91976 16.8172 4.96586 17.6773 3.90179 18.2418C3.85958 18.2631 3.82349 18.2949 3.79691 18.334C3.77033 18.3732 3.75413 18.4185 3.74984 18.4656C3.74667 18.5057 3.75277 18.5461 3.76766 18.5835C3.78256 18.6209 3.80585 18.6544 3.83575 18.6814C3.86565 18.7084 3.90135 18.7282 3.94009 18.7392C3.97884 18.7502 4.01959 18.7521 4.05921 18.7449C4.87367 18.5816 5.66078 18.2105 6.41078 17.814C6.45889 17.7887 6.51237 17.7753 6.56674 17.7749C6.62111 17.7744 6.67479 17.7871 6.72328 17.8117C7.73899 18.3285 8.86246 18.598 10.0021 18.5981C11.1417 18.5982 12.2653 18.329 13.2811 17.8125C13.3301 17.7876 13.3844 17.7749 13.4394 17.7753C13.4944 17.7757 13.5484 17.7892 13.5971 17.8148C14.3432 18.2054 15.1291 18.58 15.9409 18.7445C15.9791 18.7516 16.0184 18.7502 16.056 18.7402C16.0936 18.7303 16.1285 18.7121 16.1582 18.687C16.188 18.662 16.2117 18.6306 16.2278 18.5952C16.244 18.5598 16.252 18.5213 16.2514 18.4824L16.2498 18.482Z"
      fill="currentColor"
    />
    <path
      d="M12.5 3.75V2.8125C12.4993 2.56408 12.4003 2.32604 12.2246 2.15038C12.049 1.97473 11.8109 1.87572 11.5625 1.875H8.4375C8.18908 1.87572 7.95104 1.97473 7.77538 2.15038C7.59972 2.32604 7.50072 2.56408 7.5 2.8125V3.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 9.10156V5.625C16.2486 5.12816 16.0506 4.65209 15.6992 4.30077C15.3479 3.94945 14.8718 3.75144 14.375 3.75H5.625C5.12816 3.75144 4.65209 3.94945 4.30077 4.30077C3.94945 4.65209 3.75144 5.12816 3.75 5.625V9.21875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 7.17188V15.4863"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
