import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FlagIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.125 18.125V2.66172C3.12519 2.60705 3.13972 2.55339 3.16714 2.50609C3.19456 2.4588 3.23391 2.41952 3.28125 2.39219C3.58633 2.21328 4.41094 1.875 6.25 1.875C8.75 1.875 11.9141 3.75 13.75 3.75C14.7866 3.74731 15.8122 3.53789 16.7668 3.13398C16.7787 3.12903 16.7916 3.12708 16.8044 3.12833C16.8172 3.12957 16.8296 3.13397 16.8403 3.14112C16.851 3.14827 16.8598 3.15796 16.8658 3.16932C16.8719 3.18069 16.875 3.19337 16.875 3.20625V11.775C16.8749 11.8053 16.866 11.8348 16.8495 11.8601C16.8329 11.8854 16.8093 11.9054 16.7816 11.9176C16.4598 12.0586 15.3254 12.5 13.75 12.5C11.875 12.5 8.75 11.25 6.25 11.25C3.75 11.25 3.125 11.875 3.125 11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
