import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ReaderIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 1.875H5.625C4.58947 1.875 3.75 2.71447 3.75 3.75V16.25C3.75 17.2855 4.58947 18.125 5.625 18.125H14.375C15.4105 18.125 16.25 17.2855 16.25 16.25V3.75C16.25 2.71447 15.4105 1.875 14.375 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 5H13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 8.125H13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 11.25H10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
