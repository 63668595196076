import moment from 'moment';
import { Company, DEFAULT_DATE_FORMAT, User, UserFields } from 'src/constants';
import {
  Contract,
  ContractField,
  PredefinedAutofillKeys,
} from 'src/entities/Contract';
import { InputType } from 'src/services/api';
import { CustomFieldOption } from 'src/store/clients/types';
import { ensureUnreachable } from 'src/utils/common_utils';

function isPredefinedAutofillKey(key: string): key is PredefinedAutofillKeys {
  return Object.values(PredefinedAutofillKeys).includes(
    key as PredefinedAutofillKeys,
  );
}

/**
 * Custom hook to get the contract input value based on the contract and user information.
 * @param {Contract | undefined} contract - The contract object.
 * @param {User | undefined} user - user related to the contract.
 * @returns {(field: ContractField) => string | null} - Function to get the contract input value for a given contract field.
 */
export const useGetContractInputValue = (
  contract: Contract | undefined,
  user: User | undefined,
  companies: Company[],
  customFields: Record<string, CustomFieldOption> | undefined,
) => {
  const getPredefinedAutofillInputValue = (
    key: PredefinedAutofillKeys,
    clientInfoFields: UserFields,
  ) => {
    switch (key) {
      case PredefinedAutofillKeys.FirstName:
        return clientInfoFields.givenName;
      case PredefinedAutofillKeys.FullName:
        return `${clientInfoFields.givenName} ${clientInfoFields.familyName}`;
      case PredefinedAutofillKeys.LastName:
        return clientInfoFields.familyName;
      case PredefinedAutofillKeys.Email:
        return clientInfoFields.email;
      case PredefinedAutofillKeys.ShareDate: {
        return contract?.createdAt
          ? moment(contract.createdAt).format(DEFAULT_DATE_FORMAT)
          : null;
      }
      case PredefinedAutofillKeys.Company: {
        const userCompany = companies.find(
          (c) => c.id === clientInfoFields.companyId,
        );
        return userCompany?.fields?.name || null;
      }
      default:
        return ensureUnreachable(key);
    }
  };

  /**
   * Gets the autofill input value for a given contract field.
   * @param {ContractField} field - The contract field containing the autoFillField property.
   * @returns {string | null} - The autofill input value or null if not found.
   */
  const findAutofillInputValue = (field: ContractField) => {
    if (!contract || !user) {
      return null;
    }
    if (!field?.autoFillField) return null;

    const key = field.autoFillField;
    const clientInfoFields = user.fields;

    if (!clientInfoFields || !key) return null;

    if (isPredefinedAutofillKey(key)) {
      return getPredefinedAutofillInputValue(key, clientInfoFields);
    }
    if (!customFields) return null;
    const customField = customFields[key];
    if (!customField || !clientInfoFields?.customFields) return null;
    // Handle the case when the custom field type is 'multiSelect'.
    // Here we will separate multiple options using ","
    if (customField.type === 'multiSelect' && customField?.options?.length) {
      const tagOptions = customField.options;
      const tags = clientInfoFields.customFields[customField.id];
      const tagLabels: string[] = [];
      // For clients without multi-select custom fields value assigned, tags will be null, so we need to return early.
      if (!tagOptions || !tags) return '';
      tags.forEach((tag: string) => {
        const tagOption = tagOptions.find((i) => i.id === tag);
        if (tagOption) tagLabels.push(tagOption.label);
      });
      return tagLabels.join(', ');
    }

    return clientInfoFields.customFields[customField.id];
  };

  const getContractInputValue = (field: ContractField) => {
    const isOneOffContract = !contract?.contractTemplateId;
    // For one off contract
    // we already have the value for the autofill field saved during the creation of the contract.
    // So we can directly use the value from the contract.
    if (!isOneOffContract && field.inputType === InputType.AutoFill) {
      return findAutofillInputValue(field);
    }

    return field.value;
  };

  return getContractInputValue;
};
