import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BarChartIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.25 1.25V18.125C1.25 18.2908 1.31585 18.4497 1.43306 18.5669C1.55027 18.6842 1.70924 18.75 1.875 18.75H18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.09375 8.75H4.53125C4.09978 8.75 3.75 9.09978 3.75 9.53125V15.4688C3.75 15.9002 4.09978 16.25 4.53125 16.25H6.09375C6.52522 16.25 6.875 15.9002 6.875 15.4688V9.53125C6.875 9.09978 6.52522 8.75 6.09375 8.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7188 6.875H10.1562C9.72478 6.875 9.375 7.22478 9.375 7.65625V15.4688C9.375 15.9002 9.72478 16.25 10.1562 16.25H11.7188C12.1502 16.25 12.5 15.9002 12.5 15.4688V7.65625C12.5 7.22478 12.1502 6.875 11.7188 6.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3301 4.375H15.7676C15.3361 4.375 14.9863 4.72478 14.9863 5.15625V15.4688C14.9863 15.9002 15.3361 16.25 15.7676 16.25H17.3301C17.7615 16.25 18.1113 15.9002 18.1113 15.4688V5.15625C18.1113 4.72478 17.7615 4.375 17.3301 4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
