import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { RootState } from 'src/store';
import { NonHoverBorder, SidebarColors } from 'src/theme/colors';
import { useSidebarTheme } from 'src/legacy/components/Sidebar/useSidebarTheme';
import { formatNotificationCount } from 'src/utils/NotificationsUtils';

interface NotificationBadgeStyleProps {
  isSidebar: boolean;
  isPreview: boolean;
  sidebarBadgeTextColor: string;
  sidebarBadgeBackground: string;
}
const useStyles = makeStyles<Theme, NotificationBadgeStyleProps>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: ({ isPreview }) =>
        isPreview ? '2px' : theme.shape.borderRadius,
      lineHeight: 1,
      padding: ({ isPreview }) => (isPreview ? '0px 4px' : '0px 8px'),
      height: ({ isPreview }) => (isPreview ? '12px' : '18px'),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: ({ isSidebar, sidebarBadgeBackground }) =>
        isSidebar ? sidebarBadgeBackground : theme.palette.primary.main,
      color: ({ isSidebar, sidebarBadgeTextColor }) =>
        isSidebar
          ? `${sidebarBadgeTextColor}`
          : `${theme.palette.primary.contrastText}`,
      '&:hover': {
        color: ({ isSidebar, sidebarBadgeTextColor }) =>
          isSidebar
            ? `${sidebarBadgeTextColor}`
            : `${theme.palette.primary.contrastText}`,
      },
    },
    hidden: {
      opacity: 0,
    },
  }),
);

interface NotificationBadgeProps {
  content: number;
  isSidebar?: boolean;
  showZero?: boolean;
  isPreview?: boolean;
  htmlId?: string;
  previewClass?: string;
}

export const NotificationBadge: React.FC<NotificationBadgeProps> = React.memo(
  ({
    content,
    isSidebar = false,
    showZero = false,
    isPreview = false,
    htmlId,
    previewClass = '',
  }) => {
    const { sidebarTheme } = useSidebarTheme();
    const isClient = useSelector((state: RootState) => state.user.isClient);

    // for advanced branding
    // background color for sidebar notification badge will be text color of sidebar
    // text color for sidebar notification badge will be sidebar background color
    const clientSidebarBadgeBackground = sidebarTheme.sidebarTextColor;
    const clientSidebarBadgeTextColor = sidebarTheme.sidebarBackgroundColor;

    const classes = useStyles({
      isSidebar,
      isPreview,
      sidebarBadgeBackground: isClient // sidebar notification badge background color based on user type ( internal or client)
        ? clientSidebarBadgeBackground
        : NonHoverBorder,
      sidebarBadgeTextColor: isClient // sidebar notification badge text color based on user type ( internal or client)
        ? clientSidebarBadgeTextColor
        : SidebarColors.InternalSidebarText,
    });

    return (
      <span
        data-testid={htmlId}
        className={classNames(classes.root, {
          [previewClass]: isPreview, // on customization page for client view preivew use previewClass
          [classes.hidden]: content < 1 && !showZero,
        })}
      >
        {!isPreview ? (
          <BaseTypography fontType="10Regular" component="span">
            {formatNotificationCount(content)}
          </BaseTypography>
        ) : (
          <BaseTypography
            fontType="9Regular"
            style={{ fontSize: 6 }}
            component="span"
          >
            {content}
          </BaseTypography>
        )}
      </span>
    );
  },
);
