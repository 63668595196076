import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const KeypadIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 18.75C10.6904 18.75 11.25 18.1904 11.25 17.5C11.25 16.8096 10.6904 16.25 10 16.25C9.30964 16.25 8.75 16.8096 8.75 17.5C8.75 18.1904 9.30964 18.75 10 18.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M10 13.75C10.6904 13.75 11.25 13.1904 11.25 12.5C11.25 11.8096 10.6904 11.25 10 11.25C9.30964 11.25 8.75 11.8096 8.75 12.5C8.75 13.1904 9.30964 13.75 10 13.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M11.25 7.5C11.25 7.74723 11.1767 7.9889 11.0393 8.19446C10.902 8.40002 10.7068 8.56024 10.4784 8.65485C10.2499 8.74946 9.99861 8.77421 9.75614 8.72598C9.51366 8.67775 9.29093 8.5587 9.11612 8.38388C8.9413 8.20907 8.82225 7.98634 8.77402 7.74386C8.72579 7.50139 8.75054 7.25005 8.84515 7.02165C8.93976 6.79324 9.09998 6.59801 9.30554 6.46066C9.5111 6.32331 9.75277 6.25 10 6.25C10.3315 6.25 10.6495 6.3817 10.8839 6.61612C11.1183 6.85054 11.25 7.16848 11.25 7.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M10 3.75C10.6904 3.75 11.25 3.19036 11.25 2.5C11.25 1.80964 10.6904 1.25 10 1.25C9.30964 1.25 8.75 1.80964 8.75 2.5C8.75 3.19036 9.30964 3.75 10 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M15 13.75C15.6904 13.75 16.25 13.1904 16.25 12.5C16.25 11.8096 15.6904 11.25 15 11.25C14.3096 11.25 13.75 11.8096 13.75 12.5C13.75 13.1904 14.3096 13.75 15 13.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M15 8.75C15.6904 8.75 16.25 8.19036 16.25 7.5C16.25 6.80964 15.6904 6.25 15 6.25C14.3096 6.25 13.75 6.80964 13.75 7.5C13.75 8.19036 14.3096 8.75 15 8.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M15 3.75C15.6904 3.75 16.25 3.19036 16.25 2.5C16.25 1.80964 15.6904 1.25 15 1.25C14.3096 1.25 13.75 1.80964 13.75 2.5C13.75 3.19036 14.3096 3.75 15 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M5 13.75C5.69036 13.75 6.25 13.1904 6.25 12.5C6.25 11.8096 5.69036 11.25 5 11.25C4.30964 11.25 3.75 11.8096 3.75 12.5C3.75 13.1904 4.30964 13.75 5 13.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M5 8.75C5.69036 8.75 6.25 8.19036 6.25 7.5C6.25 6.80964 5.69036 6.25 5 6.25C4.30964 6.25 3.75 6.80964 3.75 7.5C3.75 8.19036 4.30964 8.75 5 8.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M5 3.75C5.69036 3.75 6.25 3.19036 6.25 2.5C6.25 1.80964 5.69036 1.25 5 1.25C4.30964 1.25 3.75 1.80964 3.75 2.5C3.75 3.19036 4.30964 3.75 5 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
