import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MultiSelectIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M0.799805 8H15.1998"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.799805 3.19995H15.1998"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.799805 12.8H15.1998"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
