import React from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { QRCodeCanvas } from 'qrcode.react';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import OtpInput from 'src/legacy/components/Auth/OtpInput';
import { FormBodyAPI, MFA_SETUP_DESCRIPTION } from 'src/constants';
import {
  MFA_CODE_LENGTH,
  MFA_INPUT_DESCRIPTION,
} from 'src/constants/authConsts';
import { Body } from 'copilot-design-system';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '20px',
    },
  }),
);

// mfa setup from can be in two states
// in SHOW_QR_CODE we render the qr code to be scanned
// in OTP_INPUT we show the otp input field
// the state is transition by submitting the form
export type SetupMfaState = 'SHOW_QR_CODE' | 'OTP_INPUT';

export interface VerifyMfaValues {
  challengeResponse: string;
  mfaSetupState: SetupMfaState;
  qrCode: string;
}

export const useMfaEnforcementForm = () => {
  const validationScheme = Yup.object().shape({
    challengeResponse: Yup.string().length(6, 'Code must be exactly 6 digits'),
  });

  // In the form rendered, we first show the qr code
  // when the user presses Next, then we show the otp input
  // the state to be shown is passed as one of the values in `values.mfaSetupState`
  const FormRenderer: React.FC<
    FormBodyAPI & FormikHelpers<VerifyMfaValues>
  > = ({ values, touched, errors, setFieldValue, submitForm }) => {
    const classes = useStyles();

    // renders the uri passed in `values.qrCode`
    const qrCode = values.qrCode ? (
      <QRCodeCanvas value={values.qrCode} />
    ) : (
      <CircularProgress />
    );

    const otpInput = (
      <OtpInput
        value={values.challengeResponse}
        onChange={async (otp) => {
          await setFieldValue('challengeResponse', otp, true);

          // auto-verify otp when last digit is filled
          if (otp.length === MFA_CODE_LENGTH) {
            await submitForm();
          }
        }}
        error={touched.challengeResponse && errors.challengeResponse}
      />
    );

    return (
      <div className={classes.root}>
        <Body className="mb-4">
          This workspace requires multi-factor authentication.
        </Body>
        <Body className="mb-4">
          We recommend using an application like Google Authenticator, Duo
          Mobile, or the token authentication functionality built into your
          password manager, like 1Password or LastPass.
        </Body>
        <Body>
          {values.mfaSetupState === 'SHOW_QR_CODE'
            ? MFA_SETUP_DESCRIPTION
            : MFA_INPUT_DESCRIPTION}
        </Body>
        <div className={classes.formContainer}>
          {values.mfaSetupState === 'SHOW_QR_CODE' ? qrCode : otpInput}
        </div>
      </div>
    );
  };

  return { FormRenderer, validationScheme };
};
