import InboxClient from 'src/clients/InboxClient';
import { appAPI, ApiTags, ApiMethods } from '.';
import { InboxNotificationDetailsResponse } from 'src/entities/Notifications';
import { NOTIFICATIONS_STATUSES } from 'src/constants';

export interface GetNotificationDetailsBody {
  id: string;
}

export interface UpdateNotificationReadStatusBody {
  isRead: boolean;
}
export interface UpdateNotificationStatusBody {
  notificationId: string;
  status: string;
}

export interface UpdateNotificationBody
  extends UpdateNotificationReadStatusBody {
  id: string;
}

export interface DeleteNotificationBody {
  id: string;
}

export enum DeleteAllNotificationsType {
  ALL = 'ALL',
  READ = 'READ',
  UNREAD = 'UNREAD',
}
export interface DeleteAllNotificationsBody {
  type: DeleteAllNotificationsType;
}

/**
 * This function handles updating the notification status in the rtk query cache.
 * @param notificationsDraft - the current notifications draft (cache)
 * @param notificationId - the notification id to update
 * @param status - the status to update the notification to
 * @returns the updated notifications draft
 */
export const updateNotificationStatus = (
  notificationsDraft: InboxNotificationDetailsResponse[],
  notificationId: string,
  isRead: boolean,
) =>
  notificationsDraft.map((notification) => {
    // lookup the notification to update and update its data attribute since
    // it is used to determine the notification status. We will replace this
    // with the isRead property.
    if (notification.id === notificationId) {
      // eslint-disable-next-line no-param-reassign
      notification.isRead = isRead;
    }
    return notification;
  });

export const inboxApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getInboxNotifications: build.query<
      InboxNotificationDetailsResponse[],
      void
    >({
      queryFn: async () => {
        const allNotifications =
          await InboxClient.loadAllPaginatedNotifications([]);
        return { data: allNotifications };
      },
      providesTags: [ApiTags.inbox_notifications],
    }),
    getInboxNotificationDetails: build.query<
      InboxNotificationDetailsResponse,
      GetNotificationDetailsBody
    >({
      query: ({ id }) => ({
        path: `/notifications/${id}/details`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    updateInboxNotificationStatus: build.mutation<
      any,
      UpdateNotificationStatusBody
    >({
      query: ({ notificationId, status }) => ({
        path: `/notifications?groupID=${notificationId}`,
        method: ApiMethods.put,
        options: {
          body: {
            status,
          },
        },
      }),
      async onQueryStarted(
        { status, notificationId: updatedNotificationId },
        { dispatch, queryFulfilled },
      ) {
        // optimistically update notification status to read.
        const patchResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxNotifications',
            undefined,
            (notificationsDraft) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-param-reassign
              notificationsDraft = updateNotificationStatus(
                notificationsDraft,
                updatedNotificationId,
                status === NOTIFICATIONS_STATUSES.READ,
              );
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    deleteAllNotifications: build.mutation<any, DeleteAllNotificationsBody>({
      query: ({ type }) => ({
        path: `/notifications?type=${type}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        // optimistically remove the deleted notifications from list
        const delResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxNotifications',
            undefined,
            (draft) => {
              if (type === DeleteAllNotificationsType.ALL) {
                draft.splice(0);
              } else if (type === DeleteAllNotificationsType.READ) {
                const newDraft = draft.filter((d) => !d.isRead);
                draft.splice(0, draft.length, ...newDraft);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          delResult.undo();
        }
      },
    }),
    deleteNotification: build.mutation<any, DeleteNotificationBody>({
      query: ({ id }) => ({
        path: `/notifications/${encodeURIComponent(id)}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        // optimistically remove notification from list
        const delResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxNotifications',
            undefined,
            (draft) => {
              const index = draft.findIndex((n) => n.id === id);
              draft.splice(index, 1);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          delResult.undo();
        }
      },
    }),
    updateNotification: build.mutation<any, UpdateNotificationBody>({
      query: ({ id, isRead }) => ({
        path: `/notifications/${encodeURIComponent(id)}?isRead=${isRead}`,
        method: ApiMethods.put,
        options: {},
      }),
      async onQueryStarted({ isRead, id }, { dispatch, queryFulfilled }) {
        // optimistically update notification status
        const patchResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxNotifications',
            undefined,
            (notificationsDraft) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-param-reassign
              notificationsDraft = updateNotificationStatus(
                notificationsDraft,
                id,
                isRead,
              );
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    updateAllNotifications: build.mutation<
      any,
      UpdateNotificationReadStatusBody
    >({
      query: ({ isRead }) => ({
        path: `/notifications/bulk-update?isRead=${isRead}`,
        method: ApiMethods.put,
        options: {},
      }),
      async onQueryStarted({ isRead }, { dispatch, queryFulfilled }) {
        // optimistically update all notifications from list
        const updateResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxNotifications',
            undefined,
            (draft) => {
              draft.map((notification) => {
                // eslint-disable-next-line no-param-reassign
                notification.isRead = isRead;
                return notification;
              });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          updateResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetInboxNotificationsQuery,
  useUpdateInboxNotificationStatusMutation,
  useGetInboxNotificationDetailsQuery,
  useDeleteAllNotificationsMutation,
  useDeleteNotificationMutation,
  useUpdateNotificationMutation,
  useUpdateAllNotificationsMutation,
} = inboxApi;
