import React, { useContext, useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import flagsmith from 'flagsmith';
import * as Sentry from '@sentry/react';
import { useAppSelector } from 'src/hooks/useStore';
import { initializeOnboardingTestData } from 'src/store/user/actions';
import { useAnonymousId } from 'src/hooks/useAnonymousId';
import { selectCurrentUser } from 'src/store/storeUtils';
import { PortalConfigContext } from 'src/context';
import { useGetUserDataQueries } from 'src/hooks/useGetUserDataQueries';
import useWebSocket from 'src/hooks/useWebSocket';

const CLIENT_UNAUTH_PAGE_FLAGSMITH_ID = 'unAuth';

export const WithUserInitialization = ({
  flexibleAuth,
  children,
}: {
  flexibleAuth: boolean;
  children: React.ReactNode;
}) => {
  // this ref is used to keep track of when the init is done.
  // In the use-effect for init side-effects we have a dependency
  // for the userData. When userData is setup we can trigger the init
  // However we don't want the side-effects to run
  // every time something on user data changes, e.g. if updatedAt
  // is modified that should not trigger the init side-effects again.
  const isUserInitialized = useRef(false);
  const { userLoaded, userLoading, isClient, userLoggedIn } = useAppSelector(
    (state) => ({
      userLoaded: state.user.loaded,
      userLoading: state.user.loading,
      userLoggedIn: state.user.instance,
      isClient: state.user.isClient,
    }),
    shallowEqual,
  );
  const userData = useAppSelector(selectCurrentUser);
  const {
    portalHeader: portalId,
    name: portalName,
    AWS: {
      API: { endpoints },
    },
  } = useContext(PortalConfigContext);

  const savedAnonymousId = useAnonymousId();
  const { startWebsocket } = useWebSocket(endpoints?.at(0) ?? null);

  useGetUserDataQueries(userLoaded, flexibleAuth);

  useEffect(() => {
    if (userLoaded && userData?.id && !isUserInitialized.current) {
      isUserInitialized.current = true;
      const { id } = userData;
      // initialize onboarding test data
      if (!isClient) {
        initializeOnboardingTestData();
      }

      startWebsocket(id);

      // trigger identify calls
      if (analytics && analytics.identify && analytics.group) {
        if (savedAnonymousId) {
          analytics.setAnonymousId(savedAnonymousId);
        }

        analytics.identify(
          id,
          {
            name: `${userData.fields.givenName} ${userData.fields.familyName}`,
            email: userData.fields.email,
            userType: isClient ? 'client' : 'internal',
            portalURL: window.location.origin,
          },
          {
            integrations: {
              'Customer.io': !isClient,
            },
          },
        );
        analytics.group(
          portalId,
          {
            groupType: 'Portal',
            name: portalName,
          },
          {
            integrations: {
              'Customer.io': !isClient,
              'Customer.io Actions': !isClient,
            },
          },
        );
      }

      flagsmith.identify(id, {
        portal_id: portalId,
        email: userData.fields.email,
      });

      Sentry.setUser({
        id,
        email: userData.fields.email,
        segment: portalId,
      });
    } else if (!userLoading && !userLoggedIn) {
      flagsmith.identify(`${CLIENT_UNAUTH_PAGE_FLAGSMITH_ID}-${portalId}`, {
        portal_id: portalId,
      });
    }
  }, [isClient, userLoaded, userData, userLoading, userLoggedIn]);
  return <>{children}</>;
};
