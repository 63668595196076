import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BriefcaseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 5H3.125C2.08947 5 1.25 5.83947 1.25 6.875V15.625C1.25 16.6605 2.08947 17.5 3.125 17.5H16.875C17.9105 17.5 18.75 16.6605 18.75 15.625V6.875C18.75 5.83947 17.9105 5 16.875 5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 5V3.75C5.625 3.41848 5.7567 3.10054 5.99112 2.86612C6.22554 2.6317 6.54348 2.5 6.875 2.5H13.125C13.4565 2.5 13.7745 2.6317 14.0089 2.86612C14.2433 3.10054 14.375 3.41848 14.375 3.75V5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 9.375H1.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 9.375V10.3125C12.5 10.3954 12.4671 10.4749 12.4085 10.5335C12.3499 10.5921 12.2704 10.625 12.1875 10.625H7.8125C7.72962 10.625 7.65013 10.5921 7.59153 10.5335C7.53292 10.4749 7.5 10.3954 7.5 10.3125V9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
