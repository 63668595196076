import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NotificationIcon = (props?: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.7067 13.7277C15.7036 12.5 14.9954 11.875 14.9954 8.49023C14.9954 5.39063 13.4125 4.28633 12.1098 3.75C11.9368 3.67891 11.7739 3.51562 11.7211 3.33789C11.4926 2.56016 10.852 1.875 10.0004 1.875C9.14887 1.875 8.50786 2.56055 8.28169 3.33867C8.22895 3.51836 8.06606 3.67891 7.89302 3.75C6.58872 4.28711 5.00747 5.3875 5.00747 8.49023C5.00552 11.875 4.29731 12.5 3.29419 13.7277C2.87856 14.2363 3.24262 15 3.96958 15H16.0352C16.7582 15 17.12 14.234 16.7067 13.7277Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.5 15V15.625C12.5 16.288 12.2366 16.9239 11.7678 17.3928C11.2989 17.8616 10.663 18.125 10 18.125C9.33696 18.125 8.70107 17.8616 8.23223 17.3928C7.76339 16.9239 7.5 16.288 7.5 15.625V15"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
