import { FormControl, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { Field, getIn, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { red, HoverBorder, NonHoverBorder, DarkFont } from 'src/theme/colors';
import {
  InputsTypes,
  OptionsInfo,
  OtherOptionKeyword,
  QuestionAnswer,
} from 'src/legacy/components/FormsV2/formsTypes';
import { FormBuilderPreviewContext } from 'src/legacy/components/FormsV2/FormBuilderPreview';
import { typography13MediumStyle } from 'src/legacy/components/Text';
import { BaseTextField } from 'src/legacy/components/TextField';
import BaseTypography from '../Text/BaseTypography';

interface SelectStylesProps {
  disabled: boolean;
}

export const SelectStyles = makeStyles<Theme, SelectStylesProps>((theme) => ({
  input: {
    height: '24px',
    width: '24px',
    appearance: 'none',
    flexShrink: 0,
    '&:hover': {
      cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    },
  },
  radio: {
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: '50%',
    transition: 'all 0.2s ease-in-out',
    // customize radio checked state
    '&:checked': {
      backgroundColor: ({ disabled }) =>
        disabled ? HoverBorder : theme.palette.primary.main,
      border: `4px solid white`,
      outline: ({ disabled }) =>
        `1px solid ${disabled ? HoverBorder : theme.palette.primary.main}`,
      borderRadius: '50%',
    },
  },
  checkbox: {
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: 2,
    transition: 'all 0.2s ease-in-out',
    '& ~ .checked-box': {
      display: 'block',
      position: 'absolute',
      borderRadius: 1,
      width: 16,
      height: 16,
      backgroundColor: theme.palette.primary.main,
      opacity: 0,
      transition: 'all 0.2s ease-in-out',
    },
    // customize checkbox checked state
    '&:checked': {
      outline: ({ disabled }) =>
        `1px solid ${disabled ? HoverBorder : theme.palette.primary.main}`,
      '& ~ .checked-box': {
        opacity: 1,
      },
    },
    '&[disabled]': {},
  },
  readOnlyCheckbox: {
    '&:checked': {
      backgroundColor: HoverBorder,
      border: '4px solid #fff',
    },
  },
  readOnlyChoice: {
    marginRight: '10px',
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginRight: '10px',
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  optionContainerForOtherOptionOnly: {
    width: '100%',
  },
  inputContainer: {
    padding: '6px',
    fontSize: typography13MediumStyle.fontSize,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: '4px',
    '&:hover': {
      cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
      borderColor: ({ disabled }) => (disabled ? NonHoverBorder : HoverBorder),
    },
  },
  choicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
}));

type Props = {
  options: OptionsInfo[];
  type?: InputsTypes;
  name?: string;
  value: string[] | string; // store the selected options
  readOnly: boolean;
  onChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  questionIndex: number;
};
export const MultiChoicesSelectInput: React.FC<Props> = ({
  options,
  name,
  value,
  readOnly,
  onChange,
  type: answerType,
  questionIndex,
}) => {
  const formikContext = useFormikContext<{
    questionAnswers: QuestionAnswer[];
  }>();
  const otherInputRef = React.useRef<HTMLInputElement | null>(null);
  const classes = SelectStyles({ disabled: readOnly });
  const fieldType =
    answerType === InputsTypes.SingleSelect ? 'radio' : 'checkbox';
  /**
   * This functions handles the click event of input When the choice
   * input is radio type, the onChange event is not triggered when
   * unselecting a radio option. This is a workaround to solve that issue.
   */
  const handleInputClick: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    // for single select question
    if (answerType === InputsTypes.SingleSelect) {
      // if prev value and current value is same
      if (value.includes(e.target.value)) {
        // deselect the radio option
        e.target.value = '';

        // passing event in formik change handler
        // to update the form value
        if (onChange) onChange(e);
      }
    }

    if (
      answerType === InputsTypes.SingleSelect ||
      answerType === InputsTypes.MultiSelect
    ) {
      // if the option is other, focus on the input field
      if (
        e.target.value === OtherOptionKeyword &&
        otherInputRef.current &&
        !value.includes(e.target.value)
      ) {
        otherInputRef.current?.focus();
      }
    }
  };

  const { isFormBuilderPreview } = useContext(FormBuilderPreviewContext);

  const otherOptionHelperText =
    formikContext &&
    getIn(
      formikContext.errors,
      `questionAnswers[${questionIndex}].otherOptionValue`,
    ) &&
    getIn(
      formikContext.touched,
      `questionAnswers[${questionIndex}].otherOptionValue`,
    )
      ? getIn(
          formikContext.errors,
          `questionAnswers[${questionIndex}].otherOptionValue`,
        )
      : '';

  /**
   * This function is responsible of getting the option label
   * considering the form builder preview mode.
   * When it is form builder preview, we need to show the option placeholder
   * if the option label value is undefined.
   * If the option label is defined, show it.
   * @param optionIndex index of the option
   * @returns option label to be displayed
   */
  const getOptionLabel = (optionIndex: number) => {
    const { value: option, isOther } = options[optionIndex];

    // when it is form builder preview, we need to show the option placeholder
    // instead of the option value.
    if (isFormBuilderPreview) {
      return option || (isOther ? 'Other' : `Choice ${optionIndex + 1}`);
    }

    return option;
  };

  const getCheckboxField = (
    index: number,
    input: React.InputHTMLAttributes<HTMLInputElement>,
  ) => {
    const { isOther } = options[index];
    return (
      <div
        className={classNames(classes.optionContainer, {
          [classes.optionContainerForOtherOptionOnly]: isOther,
        })}
      >
        <div className={classes.checkContainer}>
          <Field {...input} onClick={handleInputClick} />
          <div className="checked-box" />
        </div>
        {isOther && formikContext && formikContext.values && (
          <BaseTextField
            className={`[&_.MuiInputBase-input::placeholder]:!text-${DarkFont} [&_.MuiInputBase-input::placeholder]:!opacity-100`}
            placeholder={'Other'}
            fullWidth
            name={`questionAnswers[${questionIndex}].otherOptionValue`}
            value={
              formikContext.values.questionAnswers?.[questionIndex]
                ?.otherOptionValue
            }
            multiline
            inputRef={otherInputRef}
            onChange={onChange}
            onFocus={() => {
              // If use focuses on the other option input field then we auto-check the other option checkbox
              if (
                (typeof value === 'string' && value === '') ||
                (Array.isArray(value) && !value.includes(OtherOptionKeyword))
              ) {
                formikContext.setFieldValue(
                  `questionAnswers[${questionIndex}].responseValue`,
                  Array.isArray(value)
                    ? [...value, OtherOptionKeyword]
                    : OtherOptionKeyword,
                );
              }
            }}
            onBlur={formikContext.handleBlur}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 250 }}
            error={Boolean(
              getIn(
                formikContext.errors,
                `questionAnswers[${questionIndex}].otherOptionValue`,
              ) &&
                getIn(
                  formikContext.touched,
                  `questionAnswers[${questionIndex}].otherOptionValue`,
                ),
            )}
          ></BaseTextField>
        )}
      </div>
    );
  };

  return (
    <div
      role="group"
      aria-labelledby="select-group"
      className={classes.choicesContainer}
    >
      {options.map((item, index) => {
        // There are two cases we need to consider
        // When form is being submitted: value array will contain OtherOptionKeyword on selected/de-selection and not what user has actually typed
        // When form is being viewed after submission: value array will contain actual value user has typed
        const optionLabel =
          item.isOther && !readOnly ? OtherOptionKeyword : item.value;

        const props = {
          type: fieldType,
          name,
          value: optionLabel,
          checked: Array.isArray(value)
            ? value?.includes(optionLabel)
            : value === optionLabel,
          className: classNames(
            classes.input,
            fieldType === 'radio' ? classes.radio : classes.checkbox,
            {
              [classes.readOnlyCheckbox]: fieldType === 'checkbox' && readOnly, // disabled checkbox styling
              [classes.readOnlyChoice]: readOnly, // adding margin right on readonly inputs
            },
          ),
          disabled: readOnly, // disable input if readonly
        };

        const { isOther } = options[index];

        return (
          <FormControl key={`option-${index.toString()}`}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={classes.inputContainer}>
              {/** Field is only available in formik context. For readonly purposes, we do not need it */}
              {readOnly ? <input {...props} /> : getCheckboxField(index, props)}
              {isOther && !readOnly ? '' : getOptionLabel(index)}
            </label>
            {otherOptionHelperText && isOther && (
              <BaseTypography
                textColor={red}
                fontType="13Medium"
                className="text-right"
              >
                {otherOptionHelperText}
              </BaseTypography>
            )}
          </FormControl>
        );
      })}
    </div>
  );
};
