import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FlaskIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.875 1.875H13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.60938 11.875H15.3906"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.12501 1.875V5.52656C8.12495 5.99912 7.99115 6.46201 7.73907 6.86172L2.85978 14.5895C1.89063 16.1242 2.99337 18.125 4.80782 18.125H15.1922C17.0067 18.125 18.1094 16.1242 17.1402 14.5895L12.2606 6.86172C12.0086 6.46197 11.875 5.99908 11.875 5.52656V1.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
