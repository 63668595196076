import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GifIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.47514 13.2251H9.25639C9.15696 12.506 8.52308 12 7.70099 12C6.74041 12 6 12.6925 6 13.8714C6 15.022 6.69247 15.7358 7.71697 15.7358C8.63494 15.7358 9.29013 15.1552 9.29013 14.1982V13.7401H7.76847V14.3189H8.54972C8.53906 14.7699 8.23189 15.0558 7.72053 15.0558C7.14347 15.0558 6.77947 14.6243 6.77947 13.8643C6.77947 13.108 7.15767 12.68 7.71342 12.68C8.10938 12.68 8.37749 12.8842 8.47514 13.2251Z"
      fill="black"
    />
    <path d="M10.6365 12.0497H9.86763V15.6861H10.6365V12.0497Z" fill="black" />
    <path
      d="M11.269 15.6861H12.0378V14.1839H13.5169V13.5501H12.0378V12.6836H13.6767V12.0497H11.269V15.6861Z"
      fill="black"
    />
  </SvgIcon>
);
