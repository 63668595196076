export interface InboxNotificationResponseFields {
  channelId: string;
  senderUserId: string;
  count?: number;
  groupingEntityType?: string;
  notificationGroup?: string;
  itemName?: string;
}

// todo remove deprecated types once backfilled
export type NotificationEventType =
  | 'forms.completed' // deprecated type
  | 'forms.created' // deprecated type
  | 'forms.requested' // deprecated type
  | 'files.new' // deprecated type
  | 'esign.requested' // todo @akash remove this once backfill for events is done
  | 'esign.completed' // todo remove once backfill is done
  | 'invoice.paid'
  | 'invoice.requested'
  | 'autoChargePayment.requested'
  | 'contract.signed'
  | 'contract.requested'
  | 'formResponse.completed'
  | 'formResponse.requested'
  | 'form.created'
  | 'files.created'
  | 'customApp.action'; // add more types here

export interface InProductDeliveryTarget {
  title: string;
  body?: string;
  isRead: boolean;
  ctaParams?: Record<string, string>;
}

export interface EmailDeliveryTarget {
  subject: string;
  header: string;
  body: string;
  title: string;
}

export interface NotificationDeliveryTargets {
  inProduct?: InProductDeliveryTarget;
  email?: EmailDeliveryTarget;
}

export interface InboxNotificationDetailsResponse {
  id: string;
  object?: string;
  data: string;
  createdAt: string;
  updatedAt?: string;
  fields: InboxNotificationResponseFields;
  senderId: string;
  senderType: string;
  recipientId: string;
  isGroup: boolean;
  resourceId: string;
  isRead: boolean;
  items: InboxNotificationDetailItem[];
  assetS3Key?: string;
  identityId: string;
  timeline: InboxNotificationDetailTimelineEvent[];
  event: NotificationEventType;
  appId: string;
  ref: string;
  channelId?: string;
  defaultListIndexPkey: string;
  additionalFields: InboxNotificationAdditionalFields;
  deliveryTargets?: NotificationDeliveryTargets;
}

export function isInboxNotificationDetailsResponse(
  item: unknown,
): item is InboxNotificationDetailsResponse {
  return (
    typeof item === 'object' &&
    item !== null &&
    'id' in item &&
    'object' in item &&
    'data' in item &&
    'createdAt' in item &&
    'updatedAt' in item &&
    'fields' in item &&
    'senderId' in item &&
    'senderType' in item &&
    'recipientId' in item &&
    'isGroup' in item &&
    'resourceId' in item &&
    'isRead' in item &&
    'event' in item &&
    'ref' in item &&
    'defaultListIndexPkey' in item &&
    'additionalFields' in item
  );
}

export interface InboxNotificationAdditionalFields {
  invoiceNumber: string;
}
export interface InboxNotificationDetailItem {
  type: string;
  filePath: string;
  fileKey: string;
  fileSize: number;
  resourceId: string;
  isDeleted: boolean;
  identityId: string;
}

export interface InboxNotificationDetailTimelineEvent {
  label: string;
  date: string;
  eventId: string;
  eventType: NotificationEventType;
}
