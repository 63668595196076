import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CloudIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.625 9.375C15.2777 5.87734 12.8516 3.75 10 3.75C7.30469 3.75 5.56875 5.63281 5 7.5C2.65625 7.73438 0.625 9.20273 0.625 11.875C0.625 14.4531 2.73438 16.25 5.3125 16.25H15.4688C17.6172 16.25 19.375 15.1781 19.375 12.8125C19.375 10.4758 17.3047 9.4625 15.625 9.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
