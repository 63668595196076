import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MagnetIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.4775 11.4771C17.5324 10.4222 18.1251 8.99147 18.1251 7.4996C18.1251 6.00774 17.5324 4.57697 16.4775 3.52206C15.4226 2.46715 13.9919 1.87451 12.5 1.87451C11.0081 1.87451 9.57737 2.46715 8.52246 3.52206"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M13.8254 8.82573C14.1723 8.47313 14.3658 7.99774 14.3638 7.50311C14.3618 7.00848 14.1644 6.53468 13.8146 6.18492C13.4649 5.83516 12.9911 5.63777 12.4964 5.63576C12.0018 5.63374 11.5264 5.82726 11.1738 6.17416"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M7.5 18.125V16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.52246 16.4771L4.84824 15.1514"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.875 12.5H3.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.1742 6.17393L6.75469 10.5931C6.52028 10.8274 6.20238 10.9591 5.8709 10.9591C5.53943 10.9591 5.22153 10.8274 4.98712 10.5931L4.10157 9.70947C3.98547 9.5934 3.89337 9.45558 3.83053 9.30391C3.76769 9.15223 3.73535 8.98966 3.73535 8.82549C3.73535 8.66131 3.76769 8.49874 3.83053 8.34707C3.89337 8.19539 3.98547 8.05758 4.10157 7.9415L8.52266 3.52197"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M16.4774 11.4772L12.0578 15.8983C11.9417 16.0144 11.8039 16.1065 11.6523 16.1694C11.5006 16.2322 11.338 16.2646 11.1738 16.2646C11.0097 16.2646 10.8471 16.2322 10.6954 16.1694C10.5437 16.1065 10.4059 16.0144 10.2899 15.8983L9.40626 15.0144C9.29015 14.8983 9.19805 14.7605 9.13522 14.6088C9.07238 14.4571 9.04004 14.2945 9.04004 14.1304C9.04004 13.9662 9.07238 13.8036 9.13522 13.6519C9.19805 13.5003 9.29015 13.3625 9.40626 13.2464L13.8254 8.82568"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.45312 6.63965L8.10469 9.2916"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M10.7559 11.9434L13.4078 14.5949"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
