import React from 'react';
import { useSelector } from 'react-redux';
import { Logger } from 'aws-amplify';
import { NotificationBadge } from 'src/legacy/components/UI/Badges';
import { RootState } from 'src/store';
import { isUnreadNotification } from 'src/utils/NotificationsUtils';
import {
  FormCompleteNotification,
  FormRequestNotification,
} from 'src/store/notifications/types';

const logger = new Logger('FormsNotificationsBadge');

// FormsNotificationsBadge notifications for forms events
export const FormsNotificationsBadge = () => {
  const isClient = useSelector((state: RootState) => state.user.isClient);
  let notifications: FormCompleteNotification[] | FormRequestNotification[] =
    [];
  // Get the notifications from the store according to the user role
  if (!isClient) {
    notifications =
      useSelector(
        (state: RootState) => state.notifications.formCompletedNotifications,
      ) || [];
  } else {
    notifications =
      useSelector(
        (state: RootState) => state.notifications.formRequestNotifications,
      ) || [];
  }

  const [notificationsCounter, setNotificationsCounter] = React.useState(0);

  React.useEffect(() => {
    logger.debug('Setting primary forms sidebar notification');
    // filter unread notification
    logger.debug('Total notifications:', notifications.length);
    const unreadNotifications = notifications.filter((notif) =>
      isUnreadNotification(notif),
    );

    logger.debug('Unread notifications:', unreadNotifications.length);

    setNotificationsCounter(unreadNotifications.length);
  }, [notifications]);

  return (
    <NotificationBadge
      showZero={false}
      isSidebar
      content={notificationsCounter}
    />
  );
};
