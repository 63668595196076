import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RatingStar = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0007 2.41357L12.4465 7.40774L17.9173 8.21357L13.959 12.0986L14.8932 17.5869L10.0007 14.9944L5.10815 17.5869L6.04232 12.0986L2.08398 8.21357L7.55398 7.40774L10.0007 2.41357Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
