import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EmailIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" className="!fill-none" {...props}>
    <rect width="20" height="20" rx="3" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.75 6.96602V6.96602C4.75 7.31602 4.925 7.60768 5.21667 7.84102L8.71667 10.2327C9.53333 10.7577 10.525 10.7577 11.3417 10.2327L14.8417 7.89935C15.075 7.60768 15.25 7.31602 15.25 6.96602V6.96602C15.25 6.38268 14.7833 5.91602 14.2 5.91602H5.8C5.21667 5.91602 4.75 6.38268 4.75 6.96602Z"
      stroke="white"
      stroke-width="0.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.75 7.08203V12.9154C4.75 13.557 5.275 14.082 5.91667 14.082H14.0833C14.725 14.082 15.25 13.557 15.25 12.9154V7.08203"
      stroke="white"
      stroke-width="0.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
