import React from 'react';

export interface SettingsPageToolbarContextInterface {
  setToolbarActions: (actions: React.ReactNode) => React.ReactNode;
  toolbarActions: React.ReactNode;
}

export const SettingsPageToolbarContext =
  React.createContext<SettingsPageToolbarContextInterface>({
    setToolbarActions: () => null,
    toolbarActions: null,
  });

const { Provider } = SettingsPageToolbarContext;

export const SettingsPageToolbarContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [toolbarActions, setToolbarActions] =
    React.useState<React.ReactNode>(null);

  return (
    <Provider value={{ toolbarActions, setToolbarActions }}>
      {children}
    </Provider>
  );
};
