import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const UnderlineIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_21016:86874)">
      <path
        d="M18.0152 0H14.0153C13.4632 0 13.0151 0.44696 13.0151 0.999939C13.0151 1.55292 13.4632 2.00006 14.0153 2.00006H15.0152V9.99994C15.0152 12.757 12.7722 15 10.0151 15C7.25812 15 5.01526 12.757 5.01526 9.99994V2.00006H6.0152C6.56726 2.00006 7.01514 1.55292 7.01514 0.999939C7.01514 0.44696 6.56726 0 6.0152 0H2.01526C1.4632 0 1.01514 0.44696 1.01514 0.999939C1.01514 1.55292 1.4632 2.00006 2.01526 2.00006H3.0152V9.99994C3.0152 13.8591 6.15527 17.0001 10.0151 17.0001C13.8752 17.0001 17.0153 13.8591 17.0153 9.99994V2.00006H18.0152C18.5673 2.00006 19.0151 1.55292 19.0151 0.999939C19.0151 0.44696 18.5673 0 18.0152 0Z"
        fill="currentColor"
      />
      <path
        d="M17.0151 18H3.01508C2.46301 18 2.01514 18.447 2.01514 18.9999C2.01514 19.5529 2.46301 20.0001 3.01508 20.0001H17.0151C17.567 20.0001 18.0151 19.5529 18.0151 18.9999C18.0151 18.447 17.567 18 17.0151 18Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_21016:86874">
        <rect width="20" height="20" fill="none" />
      </clipPath>
    </defs>
  </SvgIcon>
);
