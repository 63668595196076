import { ApiMethods, appAPI } from './index';

export const mfaEnforcementApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    // This route is exemt from MFA enforcement, it's used
    // when a user that does not have MFA setup logs into a
    // workspace where MFA is required.
    setMfaPreference: build.mutation<
      void,
      { mfaPreference: 'SOFTWARE_TOKEN_MFA' }
    >({
      query: (body) => ({
        method: ApiMethods.put,
        options: { body },
        path: `/users/mfa`,
      }),
    }),
  }),
});

export const { useSetMfaPreferenceMutation } = mfaEnforcementApi;
