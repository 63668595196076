import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FileTrayStackedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.875 13.125V16.875C1.87644 17.3718 2.07445 17.8479 2.42577 18.1992C2.77709 18.5506 3.25316 18.7486 3.75 18.75H16.25C16.7468 18.7486 17.2229 18.5506 17.5742 18.1992C17.9256 17.8479 18.1236 17.3718 18.125 16.875V13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 13.125H7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 13.125H18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 13.125C7.5 13.788 7.76339 14.4239 8.23223 14.8928C8.70107 15.3616 9.33696 15.625 10 15.625C10.663 15.625 11.2989 15.3616 11.7678 14.8928C12.2366 14.4239 12.5 13.788 12.5 13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 1.25H5C3.98438 1.25 3.32031 1.79688 3.125 2.8125L1.875 7.5V11.25C1.87644 11.7468 2.07445 12.2229 2.42577 12.5742C2.77709 12.9256 3.25316 13.1236 3.75 13.125H16.25C16.7468 13.1236 17.2229 12.9256 17.5742 12.5742C17.9256 12.2229 18.1236 11.7468 18.125 11.25V7.5L16.875 2.8125C16.6797 1.75781 15.9766 1.25 15 1.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 7.5H7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 7.5H18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 7.5C7.5 8.16304 7.76339 8.79893 8.23223 9.26777C8.70107 9.73661 9.33696 10 10 10C10.663 10 11.2989 9.73661 11.7678 9.26777C12.2366 8.79893 12.5 8.16304 12.5 7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
