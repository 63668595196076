import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CubeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 13.3346V6.66425C17.4997 6.44593 17.4423 6.23148 17.3334 6.04226C17.2245 5.85304 17.0679 5.69564 16.8793 5.58573L10.9418 2.13026C10.6557 1.96372 10.3306 1.87598 9.99961 1.87598C9.66859 1.87598 9.34349 1.96372 9.05742 2.13026L3.1207 5.58573C2.93207 5.69564 2.77551 5.85304 2.66661 6.04226C2.55771 6.23148 2.50027 6.44593 2.5 6.66425V13.3346C2.50013 13.553 2.55751 13.7676 2.66641 13.957C2.77532 14.1464 2.93195 14.3039 3.1207 14.4139L9.0582 17.8693C9.34435 18.0356 9.66942 18.1232 10.0004 18.1232C10.3314 18.1232 10.6564 18.0356 10.9426 17.8693L16.8801 14.4139C17.0687 14.3038 17.2252 14.1462 17.3339 13.9568C17.4427 13.7675 17.4999 13.5529 17.5 13.3346Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.69531 6.01489L10 10.3118M10 10.3118L17.3047 6.01489M10 10.3118V18.1243"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
