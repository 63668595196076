import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import { CheckboxProps, Checkbox } from '@material-ui/core';
import { LiveCheckRendererProps } from 'src/legacy/components/AgGrid/Renderer/types';

const GreenCheckbox = withStyles({
  root: {
    color: green.A200,
    '&$checked': {
      color: green.A400,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const LiveCheckRenderer: React.FC<LiveCheckRendererProps> = ({
  value = false,
  handleClick,
}: LiveCheckRendererProps) => {
  const [checked, setChecked] = useState(value);

  const handleCheckboxClicked = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      handleClick();
    }
  };

  return <GreenCheckbox checked={checked} onChange={handleCheckboxClicked} />;
};

export default LiveCheckRenderer;
