import React, { useContext, useEffect, useMemo } from 'react';
import { ColDef } from '@ag-grid-community/core';
import Button from 'src/legacy/components/Button';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { Theme, makeStyles } from '@material-ui/core';
import AgGrid from 'src/legacy/components/AgGrid';
import { BaseChip, SeverityLevel } from 'src/legacy/components/UI';
import { ContractStatus, useGetContractsQuery } from 'src/services/api';
import { ensureUnreachable } from 'src/utils/common_utils';
import { UserAvatar } from '../User';
import { Contract } from 'src/entities/Contract';
import { LoadingWrapper } from '../Loading';
import history from 'src/history';
import { CONTRACT_SUBMIT_PATH } from 'src/constants';
import StandardPage from 'src/legacy/components/UI/StandardPage';
import { RouteContext } from 'src/context';
import { contractCustomSort } from './contract_helpers';
import { useModuleIcon } from 'src/hooks/useModuleIcon';
import { DateUtils } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 4),
    marginTop: theme.spacing(3),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 0,
    },
  },
  standardPage: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
}));

const clientContractColumns: ColDef[] = [
  {
    headerName: 'Name',
    field: 'name',
    minWidth: 200,
    sortable: true,
  },
  {
    headerName: 'Status',
    field: 'status',
    minWidth: 200,
    cellRenderer: 'ClientContractStatusRenderer',
    sortable: true,
  },
  {
    headerName: 'From',
    field: 'owner',
    minWidth: 200,
    sortable: true,
    cellRenderer: 'OwnerCellRenderer',
  },
  {
    headerName: 'Submitted on',
    field: 'submissionDate',
    minWidth: 200,
    valueFormatter: ({ value }: { value: string }) =>
      value ? DateUtils.formatDate(value) : '',
    sortable: true,
  },
  {
    colId: 'actions',
    width: 150,
    headerName: 'Actions',
    suppressSizeToFit: true,
    pinned: 'right',
    cellRenderer: 'ContractActionButton',
  },
];

export function getContractStatusInfo(status: ContractStatus): {
  severity: SeverityLevel;
  label: string;
} {
  switch (status) {
    case ContractStatus.Pending:
      return { severity: SeverityLevel.medium, label: 'Submission requested' };
    case ContractStatus.Signed:
      return { severity: SeverityLevel.low, label: 'Completed' };
    default:
      return ensureUnreachable(status);
  }
}

const OwnerCellRenderer = ({ data: contract }: { data: Contract }) => {
  const { avatarImageUrl } = contract.owner?.metadata || {};
  return (
    <UserAvatar
      avatarUrl={avatarImageUrl}
      name={`${contract.owner?.metadata.givenName} ${contract.owner?.metadata.familyName} `}
      description={contract.owner?.metadata.email}
      fallbackColor={contract.owner?.metadata.fallbackColor}
      primaryTextVariant="tableMain"
      secondaryTextVariant="tableSubtitle"
      shape="circle"
    />
  );
};

const ClientContractStatusRenderer = ({
  data: contract,
}: {
  data: Contract;
}) => {
  const { status } = contract;
  const { severity, label } = getContractStatusInfo(status);
  return <BaseChip severity={severity} label={label} />;
};

const ContractActionButton = ({ data }: { data: Contract }) => {
  const { status } = data;

  /**
   * This function returns the label for the action button
   * @returns {string} The label for the action button
   */
  const getActionLabel = () => {
    switch (status) {
      case ContractStatus.Pending:
        return 'Review';
      case ContractStatus.Signed:
        return 'View';
      default:
        return ensureUnreachable(status);
    }
  };

  const handleActionClick = () => {
    history.push(`${CONTRACT_SUBMIT_PATH}?contractId=${data.id}`);
  };

  return (
    <Button
      onClick={handleActionClick}
      htmlId="view-contract"
      variant="contained"
      color={data.status === ContractStatus.Pending ? 'primary' : 'secondary'}
    >
      {getActionLabel()}
    </Button>
  );
};

interface Props {
  clientUserId: string;
}

export const ClientContractsTable = (props: Props) => {
  const { clientUserId } = props;
  const searchKey = useSelector((state: RootState) => state.ui.searchValue);
  const { query } = useContext(RouteContext);
  const { openShortLink } = query as { openShortLink?: string };
  const Icon = useModuleIcon('contracts');

  const classes = useStyles();

  const { data: contracts, isLoading } = useGetContractsQuery({
    recipientId: clientUserId,
  });

  const shouldShowStandardPage = !contracts?.length;

  // when open short link query param is
  // present in the url, we need to redirect to
  // link api middleware to get the contract submit
  // redirection link to open submit page.
  useEffect(() => {
    if (openShortLink) {
      window.location.href = openShortLink;
    }
  }, [openShortLink]);

  const sortedContracts = useMemo(
    () => (contracts ? [...contracts].sort(contractCustomSort) : []),
    [contracts],
  );

  return (
    <LoadingWrapper
      isLoading={isLoading || Boolean(openShortLink)}
      hideContentWhileLoading
    >
      {shouldShowStandardPage ? (
        <div className={classes.standardPage}>
          <StandardPage
            topIcon={<Icon style={{ fontSize: 24 }} />}
            titleForClient="No contracts have been shared yet."
            descForClient="They will appear here once they are shared with you."
          />
        </div>
      ) : (
        <div className={classes.root}>
          <AgGrid
            className="agGridRowHover"
            columnDefs={clientContractColumns}
            rowData={sortedContracts}
            searchKey={searchKey}
            frameworkComponents={{
              ClientContractStatusRenderer,
              ContractActionButton,
              OwnerCellRenderer,
            }}
          />
        </div>
      )}
    </LoadingWrapper>
  );
};
