import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ContractInputsContainer } from './ContractInputsContainer';
import { SignatureSidebarInputs } from 'src/legacy/components/Signature/SignatureSidebar';
import {
  BANNER_HEIGHT,
  CONTRACT_DETAILS_PAGE,
  DESKTOP_APP_BAR_HEIGHT,
} from 'src/constants';
import { NonHoverBorder, white } from 'src/theme/colors';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';
import { RootState } from 'src/store';
import { AdvancedInputs } from './AdvancedInputs';
import { RouteContext } from 'src/context';
import { TABS_CONTAINER_HEIGHT } from 'src/legacy/components/UI/BaseTab';

interface StyleProps {
  topSpacing: number;
  height: number;
}

export const CONTRACT_SIDEBAR_WIDTH = 300;

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: (props: StyleProps) => props.topSpacing,
    right: 0,
    width: CONTRACT_SIDEBAR_WIDTH,
    height: (props: StyleProps) => props.height,
    borderLeft: `1px solid ${NonHoverBorder}`,
    overflow: 'auto',
    backgroundColor: white,
  },
  sidebarTopBordar: {
    borderTop: `1px solid ${NonHoverBorder}`,
  },
}));

/**
 *  Renders the right sidebar for creating contract templates.
 *  This sidebar includes different types of draggable inputs.
 *
 * @returns {JSX.Element} The JSX element rendering the contract template builder sidebar.
 */
export const ContractBuilderSidebar = () => {
  const { pathname } = useContext(RouteContext);
  const bannerOptions = useSelector(
    (state: RootState) => state.ui.bannerOptions,
  );
  const isContractEditPage = pathname === CONTRACT_DETAILS_PAGE.path;

  // Calculate the top spacing and height of the sidebar based on the page type (Edit or Create Contract):
  // - On the Edit page: Include the page header, tabs toggle container, and trail banner (if any).
  // - On the Create Contract page: Include the page header and trail banner (if any).
  const sidebarStyles = useMemo(() => {
    const topSpacing = isContractEditPage
      ? DESKTOP_APP_BAR_HEIGHT +
        TABS_CONTAINER_HEIGHT +
        (bannerOptions ? BANNER_HEIGHT : 0)
      : DESKTOP_APP_BAR_HEIGHT + (bannerOptions ? BANNER_HEIGHT : 0);

    const height = window.innerHeight - topSpacing;

    return { topSpacing, height };
  }, [isContractEditPage, bannerOptions]);

  const classes = useStyles(sidebarStyles);
  const { placeholderElement } = useContext(ContractBuilderContext);

  // List of draggable input sections for the contract sidebar
  const contractSidebarSections = [
    {
      title: 'Client inputs',
      tooltip:
        'Your client will be prompted to provide these inputs when they complete the contract.',
      Component: (
        <SignatureSidebarInputs
          isClientSignatureButton
          signaturePlaceholderComponentElement={placeholderElement}
        />
      ),
      defaultExanded: true,
    },
    {
      title: 'My inputs',
      tooltip:
        'Add your signature, initials, date or custom text. These values will always be used every time you share the contract with a client.',
      Component: (
        <SignatureSidebarInputs
          signaturePlaceholderComponentElement={placeholderElement}
        />
      ),
      defaultExanded: false,
    },
    {
      title: 'Advanced',
      Component: <AdvancedInputs />,
      defaultExanded: false,
    },
  ];

  return (
    <div
      className={classNames(classes.root, {
        [classes.sidebarTopBordar]: isContractEditPage,
      })}
    >
      {contractSidebarSections.map(
        ({ title, tooltip, defaultExanded, Component }) => (
          <ContractInputsContainer
            summary={title}
            tooltip={tooltip}
            defaultExpanded={defaultExanded}
          >
            {Component}
          </ContractInputsContainer>
        ),
      )}
    </div>
  );
};
