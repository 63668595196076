import * as React from 'react';
import { useCallback, useState, useRef } from 'react';

type Elements = HTMLInputElement | HTMLTextAreaElement;
type DebounceEvent<Els extends Elements> = React.ChangeEvent<Els>;

/**
 * This was dialed in based on the feel of the UI. It's a balance
 * between input lag and UI side effects. If the debounce time is
 * too low, slower typers might trigger the underlying callback more
 * often, leading to input lag. If the debounce time is too high,
 * there will be a long delay after typing before form validation
 * errors are shown and the save button is activated.
 */
const DEBOUNCE_TIME_MS = 500;

/**
 * Designed for use with text input fields. Firing an onChange
 * callback for each keystroke can cause input lag. This hook
 * takes an initialValue in order to immediately update the
 * rendered value of a field instantly, but doesn't fire the the
 * callback until the user has stopped typing for 500ms.
 *
 * Example usage:
 * ```tsx
 * const [titleValue, handleTitleChange] = useDebounceInput(
 *  values.questions[questionIndex].title,
 *  handleChange,
 * );
 * ```
 */
export function useDebounceInput<El extends Elements = HTMLInputElement>(
  initialVal: string,
  callback: (e: DebounceEvent<El>) => void,
): [string, (e: DebounceEvent<El>) => void] {
  const [val, setVal] = useState(initialVal);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const handleChange = useCallback(
    (e: DebounceEvent<El>) => {
      setVal(e.target.value);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        callback(e);
      }, DEBOUNCE_TIME_MS);
      return () => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
      };
    },
    [callback],
  );
  return [val, handleChange];
}
