import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as Colors from 'src/theme/colors';
import FormPageHeaderBase from 'src/legacy/components/Page/FormPageAction/FormPageHeaderBase';
import { FormPageHeaderProps } from 'src/legacy/components/Page/formPageTypes';
import { DividersAndCardBorders } from 'src/theme/colors';

const useStyles = makeStyles(() =>
  createStyles({
    containedPageHeader: {
      borderBottom: `1px solid ${DividersAndCardBorders}`,
    },
    unsavedChanges: {
      backgroundColor: Colors.HoverBackground,
    },
  }),
);
const ContainedFormPageHeader: React.FC<FormPageHeaderProps> = (props) => {
  const classes = useStyles();
  const { showActions } = props;
  return (
    <FormPageHeaderBase
      className={clsx(classes.containedPageHeader, {
        [classes.unsavedChanges]: showActions,
      })}
      {...props}
    />
  );
};

export default ContainedFormPageHeader;
