import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const VideocamIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.6402 12.0617L17.8711 14.3359C17.9663 14.3782 18.0705 14.396 18.1743 14.3878C18.2781 14.3796 18.3782 14.3456 18.4655 14.2889C18.5529 14.2321 18.6246 14.1545 18.6743 14.0629C18.724 13.9714 18.75 13.8689 18.75 13.7648V6.2351C18.75 6.13096 18.724 6.02848 18.6743 5.93695C18.6246 5.84543 18.5529 5.76777 18.4655 5.71103C18.3782 5.65429 18.2781 5.62027 18.1743 5.61205C18.0705 5.60384 17.9663 5.6217 17.8711 5.66401L14.6402 7.93823C14.5583 7.9959 14.4914 8.07242 14.4453 8.16134C14.3991 8.25026 14.375 8.34897 14.375 8.44916V11.5507C14.375 11.6509 14.3991 11.7496 14.4453 11.8385C14.4914 11.9275 14.5583 12.004 14.6402 12.0617Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4688 15H3.28125C2.743 14.9985 2.22725 14.784 1.84665 14.4034C1.46605 14.0228 1.25155 13.507 1.25 12.9688V7.03125C1.25155 6.493 1.46605 5.97725 1.84665 5.59665C2.22725 5.21605 2.743 5.00155 3.28125 5H10.4875C11.0207 5.00165 11.5317 5.21421 11.9087 5.59127C12.2858 5.96833 12.4984 6.47926 12.5 7.0125V12.9688C12.4985 13.507 12.284 14.0228 11.9034 14.4034C11.5228 14.784 11.007 14.9985 10.4688 15Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
