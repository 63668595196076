import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EarIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.1141 12.9203C14.9113 11.6992 16.25 9.81797 16.25 7.5C16.25 5.8424 15.5915 4.25269 14.4194 3.08058C13.2473 1.90848 11.6576 1.25 10 1.25C8.3424 1.25 6.75269 1.90848 5.58058 3.08058C4.40848 4.25269 3.75 5.8424 3.75 7.5V15.5691C3.75 17.298 5.14609 18.75 6.875 18.75C8.60391 18.75 9.48555 17.7367 9.89414 17.1875C10.4711 16.4137 11.5234 14.0016 13.1141 12.9203Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 11.875V7.1875C6.25 5.29687 7.9375 3.75 10 3.75C12.0625 3.75 13.75 5.29687 13.75 7.1875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 9.33594C7.22656 8.63281 9.36797 8.75 9.36797 8.75C10.3836 8.75 10.9762 9.89922 10.3836 10.7266C10.3836 10.7266 8.94375 12.3828 8.74844 13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
