import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Download = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M1 13.5001V14.6251C1 15.5202 1.35558 16.3786 1.98851 17.0116C2.62145 17.6445 3.47989 18.0001 4.375 18.0001H15.625C16.5201 18.0001 17.3786 17.6445 18.0115 17.0116C18.6444 16.3786 19 15.5202 19 14.6251V13.5001M14.5 8.99997L10 13.5M10 13.5L5.5 8.99997M10 13.5V2"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
