import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AsteriskIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M6.0002 1.19995V5.99995M6.0002 10.8V5.99995M6.0002 5.99995H10.8002M6.0002 5.99995H1.2002M6.0002 5.99995L9.0002 2.99995M6.0002 5.99995L3.0002 2.99995M6.0002 5.99995L9.0002 8.99995M6.0002 5.99995L3.0002 8.99995"
      stroke="#CC0000"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </SvgIcon>
);
