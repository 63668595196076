import { AppAPIName, BaseEntity, CONTRACTS_PAGE } from 'src/constants';
import { ApiMethods, ApiTags, appAPI } from '.';
import { notify } from 'src/clients/ApiService';
import {
  Contract,
  ContractField,
  ListContractResponse,
} from 'src/entities/Contract';
import history from 'src/history';
import { API } from 'src/utils/AmplifyApiUtils';
import { ensureApiError } from '../../utils/Errors';

interface CreateContractFilesResponse {
  fileKey: string;
  pageKeys: string[];
  identityId: string;
}

export enum FieldType {
  Signature = 'signature',
  Initials = 'initials',
  Date = 'date',
  Text = 'text',
}

export enum InputType {
  Client = 'client',
  Fixed = 'fixed',
  AutoFill = 'autoFill',
  Variable = 'variable',
}
export interface AddContractTemplateInput {
  name: string;
  fileKey: string;
  identityId: string;
  fields: ContractField[];
}

export type ClientId = string;

export type VariableInputLabel = string;
export type VariableInputValue = string;

export interface ShareContractTemplateInput {
  contractTemplateId: string;
  recipientId: ClientId;
  variableValues?: Record<VariableInputLabel, VariableInputValue>;
  creationMode: 'template';
}

interface CreateContractInput extends AddContractTemplateInput {
  recipientId: ClientId;
  creationMode: 'oneOff';
}

export enum ContractStatus {
  Pending = 'pending',
  Signed = 'signed',
}

export interface ContractTemplate extends BaseEntity {
  id: string;
  name: string;
  fileKey: string;
  fields: ContractField[];
  defaultListIndexPkey: string;
  requestsCount: number;
  submissionsCount: number;
  pageKeys: string[];
  latestSubmissionDate?: string | null;
  createdAt: string;
}

type InternalGetContractParams = {
  contractTemplateId: string;
};

type ClientGetContractParams = {
  recipientId: string;
};

type GetContractParams =
  | InternalGetContractParams
  | ClientGetContractParams
  | object;

async function loadContracts(
  params: GetContractParams,
  previousItems: Contract[],
  nextToken?: string,
): Promise<Contract[]> {
  const response: ListContractResponse = await API.get(
    AppAPIName,
    `/contracts`,
    {
      queryStringParameters: {
        ...params,
        limit: 100,
        nextToken,
      },
    },
  );

  const responseItems = response && response.data ? response.data : [];
  const updatedItems = [...previousItems, ...responseItems];

  if (!response.nextToken) {
    return updatedItems;
  }

  return loadContracts(params, updatedItems, response.nextToken);
}

export const contractsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createContractFiles: build.mutation<CreateContractFilesResponse, string>({
      query: (fileKey: string) => ({
        path: `/contract-files`,
        method: ApiMethods.post,
        options: {
          body: {
            fileKey,
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const error = ensureApiError(err);
          notify({
            status: 'error',
            error,
            dispatch,
          });
        }
      },
    }),
    addContractTemplate: build.mutation<
      ContractTemplate,
      AddContractTemplateInput
    >({
      query: (body) => ({
        path: `/contract-templates`,
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
      invalidatesTags: [ApiTags.contract_templates],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Contract template created.',
            dispatch,
          });
        } catch (error) {
          notify({
            status: 'error',
            errorMessage: 'The contract template cannot be created.',
            error,
            dispatch,
          });
        }
      },
    }),
    updateContractTemplate: build.mutation<
      ContractTemplate,
      { id: string; body: ContractTemplate }
    >({
      query: ({ id, body }) => ({
        path: `/contract-templates/${id}`,
        method: ApiMethods.put,
        options: {
          body,
        },
      }),
      invalidatesTags: [ApiTags.contract_templates],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Contract template updated.',
            dispatch,
          });
        } catch (error) {
          notify({
            status: 'error',
            errorMessage: 'The contract template cannot be updated.',
            error,
            dispatch,
          });
        }
      },
    }),
    archiveContractTemplate: build.mutation<
      ContractTemplate,
      { contractTemplateId: string }
    >({
      query: ({ contractTemplateId }) => ({
        path: `/contract-templates/${contractTemplateId}`,
        method: ApiMethods.del,
        options: {},
      }),
      invalidatesTags: [ApiTags.contract_templates],
      async onQueryStarted(
        { contractTemplateId },
        { dispatch, queryFulfilled },
      ) {
        const patch = dispatch(
          contractsApi.util.updateQueryData(
            'getContractTemplates',
            undefined,
            (draft) =>
              draft.filter(
                (contractTemplate: ContractTemplate) =>
                  contractTemplate.id !== contractTemplateId,
              ),
          ),
        );

        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Contract template archived.',
            dispatch,
          });
        } catch (error) {
          patch.undo();
          notify({
            status: 'error',
            errorMessage: 'The contract template cannot be archived.',
            error,
            dispatch,
          });
        }
      },
    }),
    getContractTemplates: build.query<ContractTemplate[], void>({
      query: () => {
        return {
          path: `/contract-templates`,
          method: ApiMethods.get,
          options: {},
        };
      },
      providesTags: [ApiTags.contract_templates],
    }),
    getContracts: build.query<Contract[], GetContractParams>({
      queryFn: async (params: GetContractParams) => {
        // fetch contracts with pagination
        const response = await loadContracts(params, []);
        return {
          data: response,
        };
      },
      providesTags: [ApiTags.contracts],
    }),
    shareContractTemplate: build.mutation<
      ContractTemplate,
      ShareContractTemplateInput | CreateContractInput
    >({
      query: (body) => ({
        path: `/contracts`,
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
    }),
    signContract: build.mutation<Contract, { id: string; body: Contract }>({
      query: ({ id, body }) => ({
        path: `/contracts/${id}`,
        method: ApiMethods.put,
        options: {
          body,
        },
      }),
      invalidatesTags: [ApiTags.contracts],
      async onQueryStarted(_unusedArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Contract signed successfully.',
            dispatch,
          });
          history.push(CONTRACTS_PAGE.path);
        } catch (error) {
          notify({
            status: 'error',
            errorMessage: 'Failed to sign the contract',
            error,
            dispatch,
          });
        }
      },
    }),
    cancelContract: build.mutation<Contract, { id: string }>({
      query: ({ id }) => ({
        path: `/contracts/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      invalidatesTags: [ApiTags.contracts],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          contractsApi.util.updateQueryData('getContracts', {}, (draft) =>
            draft.filter((contract: Contract) => contract.id !== id),
          ),
        );

        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Contract request cancelled.',
            dispatch,
          });
        } catch (error) {
          patch.undo();
          notify({
            status: 'error',
            errorMessage: 'The contract request cannot be cancelled.',
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const {
  useCreateContractFilesMutation,
  useAddContractTemplateMutation,
  useUpdateContractTemplateMutation,
  useArchiveContractTemplateMutation,
  useGetContractTemplatesQuery,
  useGetContractsQuery,
  useShareContractTemplateMutation,
  useSignContractMutation,
  useCancelContractMutation,
} = contractsApi;
