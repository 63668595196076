export function removeSpaceFromString(value: string) {
  return value.replace(/ /gm, '');
}

export function pascalToSnakeCase(value: string) {
  const trimmedValue = value
    .replace(/\s/g, '')
    .replace(/(?:^|\.?)([A-Z])/g, (_, y) => `_${y.toLowerCase()}`);

  if (trimmedValue.at(0) === '_') {
    return trimmedValue.slice(1);
  }
  return trimmedValue;
}

export function convertTitleCase(value: string) {
  return value.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),
  );
}

export function removeSpaceToLowerCase(value: string) {
  return removeSpaceFromString(value).toLowerCase();
}

export function startWithVowel(word: string) {
  const vowelRegex = /^[aeiou]/i;
  return word.match(vowelRegex) !== null;
}

export function removeTrailingPeriod(value: string) {
  return value[value.length - 1] === '.' ? value.slice(0, -1) : value;
}

export function filterNonAlphanumericCharacters(value: string) {
  const lettersRegex = /[a-zA-Z0-9]+/g;
  return (value.match(lettersRegex) || []).join('');
}

export function isEmptyString(text: string) {
  const trimmedString = text.trim();
  return (
    trimmedString === '' ||
    trimmedString === '>' ||
    trimmedString === '``````' ||
    trimmedString === '``' ||
    trimmedString === '**' ||
    trimmedString === '____' ||
    trimmedString === '__' ||
    trimmedString === '****'
  );
}

/**
 * This function takes a string and capitalizes the first word's letter.
 * if the string has a space, it will capitalize the first word
 * and the rest will be lowercase.
 * @param word: string to capitalize
 * @returns string
 */
export function capitalize(word: string) {
  // if the word has a space, split it into an array of words
  // capitalize the first word and others should be lowercase
  if (word.includes(' ')) {
    const strParts = word.split(' ');
    const capitalized = strParts.map((str, index) => {
      if (index === 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return str.toLowerCase();
    });
    return capitalized.join(' ');
  }

  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function replaceUnderscoreWithSpace(str: string) {
  return str.replace(/_/g, ' ');
}

export function getFileNameWithoutExtension(name: string) {
  return name.split('.')[0];
}

export function beautifyFileName(fileName: string) {
  const fileNameWithoutExtension = getFileNameWithoutExtension(fileName);

  const capitalizedFileName = capitalize(fileNameWithoutExtension);

  return replaceUnderscoreWithSpace(capitalizedFileName);
}

/**
 * Converts a normal text to camel case.
 * @param str - The input text to be converted.
 * @returns The camel case representation of the input text.
 */
export function toCamelCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s(.)/g, (_, match) => match.toUpperCase()) // Convert first character after spaces to uppercase
    .replace(/\s/g, '') // Remove remaining spaces
    .replace(/^(.)/, (_, match) => match.toLowerCase()); // Convert the first character to lowercase
}

export const getPluralDisplayNameFromCount = (label: string, count: number) => {
  return count > 1 ? `${count} ${label}s` : label;
};

/**
 * Formats the description of a rich text by removing empty <p> tags with class and replacing them with a <br/>.
 * This allows the description to display empty lines.
 * @param description  - The description to be formatted.
 * @returns The formatted description. If the description is empty, an empty string is returned.
 */
export const formatRichTextDescription = (description: string) => {
  if (!description) return '';

  const cleanedDescription = description.replace(
    /<p class="[^"]*"><\/p>/g,
    '<br/>',
  );

  return cleanedDescription;
};
