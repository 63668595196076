import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FlashIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.3152 1.28906L3.75 11.875H8.75L7.51914 18.6418C7.5168 18.6551 7.5174 18.6687 7.52089 18.6817C7.52439 18.6947 7.5307 18.7068 7.53938 18.7172C7.54806 18.7275 7.55889 18.7358 7.57112 18.7414C7.58335 18.7471 7.59667 18.75 7.61016 18.75V18.75C7.6245 18.75 7.63864 18.7466 7.65146 18.7402C7.66428 18.7337 7.67541 18.7244 7.68398 18.7129L16.25 8.125H11.25L12.4867 1.35742C12.4884 1.34394 12.4872 1.33026 12.4831 1.31728C12.4791 1.30431 12.4723 1.29235 12.4633 1.28219C12.4543 1.27202 12.4432 1.2639 12.4308 1.25835C12.4184 1.2528 12.405 1.24995 12.3914 1.25V1.25C12.3765 1.25006 12.3619 1.25363 12.3486 1.26042C12.3354 1.2672 12.324 1.27702 12.3152 1.28906V1.28906Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
