import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { zIndex } from 'src/theme/zindex';
import { Loader } from 'src/legacy/components/Loading/Loader';

export * from './Loader';
export * from './LoadingWrapper';

export const LoaderContainer = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height={1}>
    <Loader />
  </Box>
);
type LoadingPropTypes = {
  message: string | null;
  cover: boolean;
};

const loadingContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

const coverLoadingContainerStyle: React.CSSProperties = {
  ...loadingContainerStyle,
  background: 'rgba(0, 0, 0, 0.4)',
  height: '100%',
  left: 0,
  padding: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: zIndex.loadingSpinner,
};

const LoadingContainer: React.FC<LoadingPropTypes> = ({
  children,
  message = null,
  cover = false,
}) => (
  <div style={cover ? coverLoadingContainerStyle : loadingContainerStyle}>
    {message && <div style={{ paddingBottom: '10px' }}>{message}</div>}
    {children}
  </div>
);

export const SpinnerContainer = ({
  message = null,
  cover = false,
}: LoadingPropTypes) => (
  <LoadingContainer message={message} cover={cover}>
    <Loader />
  </LoadingContainer>
);

const RadioOptionContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '6px',
  width: '140px',
};

type RadioGroupLoadingPropTypes = {
  numRows: number;
};

export const RadioGroupLoading: React.FC<RadioGroupLoadingPropTypes> = ({
  numRows = 3,
}) => (
  <div>
    {[...Array(numRows).keys()].map((k) => (
      <div key={k} style={RadioOptionContainerStyle}>
        <Skeleton variant="circle" width={28} height={28} />
        <Skeleton variant="text" width={100} height={28} />
      </div>
    ))}
  </div>
);
