import { SignatureComponentType } from 'src/constants';
import { ContractTemplate } from 'src/services/api';

export function sortContractTemplatesByCreatedDate(
  templates?: ContractTemplate[],
): ContractTemplate[] {
  if (!templates) return [];
  const copyOfTemplates = [...templates];
  copyOfTemplates.sort((a, b) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return bDate.getTime() - aDate.getTime();
  });
  return copyOfTemplates;
}

export function isContractEntity(entity: string) {
  // contract's entityType has two formats
  // Templated contract: CONTRACT:<contract_template_id>:<unqiue_id>
  // OneOff contract: CONTRACT:<unqiue_id>
  // We need to check for both the formats in regex
  return (
    /^CONTRACT:(\w+(?:-\w+)*):(\w+(?:-\w+)*)$/.test(entity) || // for templated contracts
    /^CONTRACT:(\w+(?:-\w+)*)$/.test(entity) // for oneOff contract
  );
}

interface ContractSortElement {
  status: string;
  submissionDate?: string;
  shareDate: string;
}
export function contractCustomSort(
  a: ContractSortElement,
  b: ContractSortElement,
) {
  // sort by status, followed by submission date, followed by share date
  const statusPriority: { [k: string]: number } = {
    pending: 1,
    signed: 2,
  };
  if (a.status !== b.status) {
    return statusPriority[a.status] - statusPriority[b.status];
  }

  if (a.submissionDate && b.submissionDate) {
    const aSubmissionDate = new Date(a.submissionDate);
    const bSubmissionDate = new Date(b.submissionDate);
    if (aSubmissionDate.getTime() !== bSubmissionDate.getTime()) {
      return aSubmissionDate.getTime() - bSubmissionDate.getTime();
    }
  }

  const aShareDate = new Date(a.shareDate);
  const bShareDate = new Date(b.shareDate);
  return aShareDate.getTime() - bShareDate.getTime();
}

export function isRequestComponent(
  componentType: SignatureComponentType,
): boolean {
  return (
    componentType === SignatureComponentType.REQUEST_SIGN ||
    componentType === SignatureComponentType.REQUEST_DATE ||
    componentType === SignatureComponentType.REQUEST_INITIAL ||
    componentType === SignatureComponentType.REQUEST_TEXT
  );
}
