import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Button } from '@material-ui/core';
import { HamburgerIcon } from 'src/legacy/components/Icons';
import { useAppDispatch } from 'src/hooks/useStore';
import { togglePrimarySidebarMobile } from 'src/store/ui/actions';

interface MenuLogoProps {
  menuClickCallback?: () => void;
  Icon?: React.ElementType;
}

export const MENU_LOGO_WIDTH = 46;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButtonContainer: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuButton: {
      paddingLeft: 0,
    },
    hamburgerMenu: {
      minWidth: 0,
    },
    logo: {
      cursor: 'pointer',
      fontSize: '35px',
      color: theme.palette.primary.contrastText,
    },
  }),
);

const MenuLogo = (props: MenuLogoProps) => {
  const {
    menuClickCallback,
    // by default we use the hamburger icon, but we can pass in a custom icon
    // e.g. toggle the secondary sidebar menu needs to have a different icon
    Icon = HamburgerIcon,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const handleClick = () => {
    // if we have a custom menu click callback, call it first
    // in all places the menu logo component is used,
    // it uses the menuClickCallback. To not break existing functionality,
    // we need to call the menuClickCallback first.
    if (menuClickCallback) {
      menuClickCallback();
      return;
    }

    // The menu hamburger button by default opens the primary sidebar
    dispatch(togglePrimarySidebarMobile({ isOpen: true }));
  };

  return (
    <div className={classes.menuButtonContainer}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        className={classes.hamburgerMenu}
      >
        <Icon />
      </Button>
    </div>
  );
};

export default MenuLogo;
