import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PaymentFailedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      {...props}
    >
      <path
        d="M13.2283 7.30908V14.4581"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0896 14.4586V7.30957"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7734 17.1174H18.864V16.2311L17.9776 14.4585H11.7734"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M5.12514 10.0269C7.32763 10.0269 9.11356 11.8128 9.11356 14.0153C9.11356 16.2178 7.32763 18.0037 5.12514 18.0037C2.92264 18.0037 1.13672 16.2178 1.13672 14.0153C1.13672 11.8128 2.92264 10.0269 5.12514 10.0269"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9082 7.30929H18.8619V4.64502L10.885 1.16357L2.9082 4.64502V7.30929Z"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M6.09551 13.0454L4.15625 14.9847"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.15625 13.0454L6.09551 14.9847"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
