import React from 'react';
import { IconButton, makeStyles, ClickAwayListener } from '@material-ui/core';
import { MoreActionsIcon } from 'src/legacy/components/Icons';
import * as Colors from 'src/theme/colors';
import { Action } from '.';
import { BaseActionsMenuProps } from 'src/constants';

const useStyles = makeStyles(() => ({
  menuItemActive: {
    backgroundColor: Colors.HoverNonBorderBackground,
  },
}));

interface BaseActionsMenuButtonProps {
  actions?: Action[];
  onActionMenuClick: (event: BaseActionsMenuProps) => void;
  parentComponentType?: 'toolbar' | 'tableAction';
}

export const BaseActionsMenuButton: React.FC<BaseActionsMenuButtonProps> = ({
  actions,
  onActionMenuClick,
  parentComponentType = 'tableAction',
}) => {
  const [isActive, setActiveStatus] = React.useState(false);

  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setActiveStatus(true);
    onActionMenuClick({
      actions: actions && actions.length > 0 ? actions : [],
      menuProps: {
        anchorEl: event.currentTarget,
        getContentAnchorEl: null,
        keepMounted: true,
        open: true,
        anchorOrigin: {
          vertical: 32,
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
    });
  };

  const removeActiveState = () => {
    setActiveStatus(false);
  };

  return actions?.length || parentComponentType === 'toolbar' ? (
    <ClickAwayListener onClickAway={removeActiveState} disableReactTree>
      <IconButton
        aria-label="more actions"
        aria-controls="more-actions-menu"
        aria-haspopup="true"
        data-testid="more-actions-menu"
        onClickCapture={handleClick}
        color="secondary"
        size="small"
        className={isActive ? classes.menuItemActive : ''}
      >
        <MoreActionsIcon
          htmlColor={Colors.GraySmall}
          style={{ fontSize: 20 }}
        />
      </IconButton>
    </ClickAwayListener>
  ) : (
    <div />
  );
};
