import * as _ from 'lodash';

export const removeDuplicates = (array: any, fieldName?: string): any[] =>
  _.uniqBy(array, (c: any) => (fieldName ? c[fieldName] : c));

/**
 * Compare if two objects are equal or not
 * @returns true if objects are equal
 */
const areObjectsEqual = <T extends Record<string, any>>(o1: T, o2: T) =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every((p) => o1[p] === o2[p]);

/**
 * Compare if two arrays are equal or not
 * @returns true if arrays are equal
 */
export const areArraysEqual = <T extends Record<string, any>>(
  a1: T[],
  a2: T[],
) =>
  a1.length === a2.length && a1.every((o, idx) => areObjectsEqual(o, a2[idx]));

export const arrayToMap = <T extends Record<string, any>, K extends keyof T>(
  array: T[],
  key: K,
) => {
  return new Map(array.map((item) => [item[key], item]));
};

export function partition<T>(
  arr: T[],
  condition: (item: T) => boolean,
): [T[], T[]] {
  const trueArray: T[] = [];
  const falseArray: T[] = [];

  arr.forEach((item) => {
    if (condition(item)) {
      trueArray.push(item);
    } else {
      falseArray.push(item);
    }
  });

  return [trueArray, falseArray];
}
