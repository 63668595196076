export const ONBOARDING_LAST_STEP_TITLE = 'Last step';
export const ONBOARDING_LAST_STEP_SUBTITLE =
  'You can connect an existing marketing website or create a new one with our marketing website builder.';
export const ONBOARDING_LAST_STEP_BUTTON_LABEL = 'Launch my Portal';
export const ONBOARDING_MARKETING_WEBSITE_TITLE =
  'I already have a marketing website';
export const ONBOARDING_MARKETING_WEBSITE_DESCRIPTION =
  'Select this option if you plan to add a client login button to an existing marketing website.';
export const ONBOARDING_NEW_MARKETING_WEBSITE_TITLE = `I don’t have a marketing website`;
export const ONBOARDING_NEW_MARKETING_WEBSITE_DESCRIPTION =
  'Select this option if you want to use our marketing website builder.';
export const ONBOARDING_LAST_STEP_BUTTON_HINT =
  'If you change your mind later, you can switch this selection in your settings.';
export const ONBOARDING_MODULE_SETTINGS_TITLE = 'You’re almost there';
export const ONBOARDING_MODULE_SETTINGS_SUBTITLE =
  'Select the modules you want to use in your Portal.';
export const ONBOARDING_MODULE_SETTINGS_BUTTON_LABEL = 'Continue';
export const ONBOARDING_MODULE_SETTINGS_BUTTON_HINT =
  'If you change your mind later, you can update the modules you have turned on in your settings.';
