import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ShirtIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.2875 1.875C12.2875 1.875 11.3977 2.1875 10 2.1875C8.60234 2.1875 7.7125 1.875 7.7125 1.875C7.57189 1.875 7.43229 1.89878 7.29961 1.94531L1.25 4.0625L1.89961 7.5L3.80898 7.71563C4.04281 7.74209 4.25814 7.85552 4.4122 8.03339C4.56627 8.21126 4.6478 8.44057 4.64063 8.67578L4.375 18.125H15.625L15.3594 8.67578C15.3522 8.44057 15.4337 8.21126 15.5878 8.03339C15.7419 7.85552 15.9572 7.74209 16.191 7.71563L18.1004 7.5L18.75 4.0625L12.7004 1.94531C12.5677 1.89878 12.4281 1.875 12.2875 1.875V1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0203 2.05713C12.8432 2.72282 12.4511 3.31133 11.9049 3.73115C11.3588 4.15097 10.6892 4.37858 10.0004 4.37858C9.31154 4.37858 8.64199 4.15097 8.09585 3.73115C7.54972 3.31133 7.1576 2.72282 6.98047 2.05713"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
