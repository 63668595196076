import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SignatureDateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.25 3.125H3.75C2.71447 3.125 1.875 3.96447 1.875 5V16.25C1.875 17.2855 2.71447 18.125 3.75 18.125H16.25C17.2855 18.125 18.125 17.2855 18.125 16.25V5C18.125 3.96447 17.2855 3.125 16.25 3.125Z"
      stroke="currentColor"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5 1.875V3.125M15 1.875V3.125M18.125 6.25H1.875M11.875 10.1562L13.5711 8.90625H13.75V15.4688M6.01484 9.48945C6.18398 9.20352 6.65742 8.75 7.49336 8.75C8.48164 8.75 9.36406 9.28828 9.36406 10.3086C9.3663 10.5061 9.32898 10.7021 9.25432 10.885C9.17965 11.0678 9.06914 11.2339 8.9293 11.3734C8.50234 11.8105 7.85078 11.9777 7.49492 11.9777C7.89727 11.9777 8.63516 12.1668 9.11797 12.6605C9.42734 12.9758 9.57031 13.4203 9.57031 13.9133C9.57031 15.0023 8.65117 15.625 7.53438 15.625C6.59023 15.625 6.01094 14.9387 5.82031 14.6156"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
