import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TabletLandscapeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M0.625 5L0.625 15C0.625 16.0355 1.46447 16.875 2.5 16.875H17.5C18.5355 16.875 19.375 16.0355 19.375 15V5C19.375 3.96447 18.5355 3.125 17.5 3.125L2.5 3.125C1.46447 3.125 0.625 3.96447 0.625 5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
