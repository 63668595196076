import { Theme } from '@material-ui/core';
import * as Shadows from 'src/theme/shadows';
import ColorUtils from 'src/utils/ColorUtils';

export default {};

export const overrideButtonGroup = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiButton) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiButtonGroup = {
      grouped: {
        minWidth: 'auto',
      },
      contained: {
        boxShadow: Shadows.PrimaryButtonShadow,
      },
      groupedContainedPrimary: {
        border: `1px solid ${
          ColorUtils.GetColorDarknessShades(theme.palette.primary.main).dark
        }`,
      },
      groupedHorizontal: {
        '&:not(:last-child)': {
          marginRight: 1,
        },
        '&:not(:first-child)': {
          borderLeft: 'none',
        },
      },
      groupedContainedHorizontal: {
        '&:not(:last-child)': {
          borderRight: 'none',
        },
      },
    };
  }
};
