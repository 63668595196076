import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SidebarOpenIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.2083 2.25H12.3021V17.75H15.2083C17.3484 17.75 19.0833 16.0151 19.0833 13.875V6.125C19.0833 3.9849 17.3484 2.25 15.2083 2.25Z"
      fill="currentColor"
    />
    <path
      d="M15.2083 2.25H4.875C2.7349 2.25 1 3.9849 1 6.125V13.875C1 16.0151 2.7349 17.75 4.875 17.75H15.2083M15.2083 2.25C17.3484 2.25 19.0833 3.9849 19.0833 6.125V13.875C19.0833 16.0151 17.3484 17.75 15.2083 17.75M15.2083 2.25H12.3021V17.75H15.2083"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
    />
  </SvgIcon>
);
