import { makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import { uniqueId } from 'lodash';
import { StepperPageContext } from 'src/context';
import { LightGray } from 'src/theme/colors';
import { StepperNode } from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    connector: {
      height: '1px',
      width: theme.spacing(3),
      backgroundColor: LightGray,
    },
  }),
);

const StepperConnector = () => {
  const classes = useStyles();
  return <div className={classes.connector} />;
};

export const StepperProgress: React.FC = () => {
  const classes = useStyles();
  const { steps } = useContext(StepperPageContext);
  const showConnector = (index: number) => index < steps.length - 1;
  return (
    <div className={classes.root}>
      {steps.map((step, stepIndex) => (
        <React.Fragment key={uniqueId()}>
          <StepperNode step={step} />
          {showConnector(stepIndex) && <StepperConnector />}
        </React.Fragment>
      ))}
    </div>
  );
};
