import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const UploadIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1 13.5001V14.6251C1 15.5202 1.35558 16.3786 1.98851 17.0116C2.62145 17.6445 3.47989 18.0001 4.375 18.0001H15.625C16.5201 18.0001 17.3786 17.6445 18.0115 17.0116C18.6444 16.3786 19 15.5202 19 14.6251V13.5001M14.5 6.49998L10 2M10 2L5.5 6.49998M10 2V13.5"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
