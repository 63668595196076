import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MegaphoneIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.9352 2.0399C15.9352 2.0399 12.5508 6.25005 9.375 6.25005H3.125C2.95924 6.25005 2.80027 6.3159 2.68306 6.43311C2.56585 6.55032 2.5 6.70929 2.5 6.87505V10.6251C2.5 10.7908 2.56585 10.9498 2.68306 11.067C2.80027 11.1842 2.95924 11.2501 3.125 11.2501H9.375C12.5508 11.2501 15.9352 15.4778 15.9352 15.4778C16.1719 15.7903 16.875 15.5762 16.875 15.0938V2.42193C16.875 1.94107 16.2109 1.68677 15.9352 2.0399Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 10C2.5 10 1.875 9.76562 1.875 8.75C1.875 7.73438 2.5 7.5 2.5 7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 9.60938C17.5 9.60938 18.125 9.44023 18.125 8.75C18.125 8.05977 17.5 7.89062 17.5 7.89062"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.25V11.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 6.25V11.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 11.25V17.8125C5.625 17.8954 5.65792 17.9749 5.71653 18.0335C5.77513 18.0921 5.85462 18.125 5.9375 18.125H8.00781C8.10572 18.125 8.20226 18.1021 8.28966 18.0579C8.37707 18.0138 8.45288 17.9498 8.511 17.871C8.56911 17.7922 8.6079 17.7009 8.62425 17.6043C8.64059 17.5078 8.63402 17.4088 8.60508 17.3152C8.27773 16.2652 7.5 15.0813 7.5 13.125H8.125C8.29076 13.125 8.44973 13.0592 8.56694 12.9419C8.68415 12.8247 8.75 12.6658 8.75 12.5V11.875C8.75 11.7092 8.68415 11.5503 8.56694 11.4331C8.44973 11.3158 8.29076 11.25 8.125 11.25H7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
