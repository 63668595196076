import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PartnerAppsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <circle cx="9.99984" cy="9.99984" r="3.33333" fill="currentColor" />
    <circle
      cx="9.99973"
      cy="9.99973"
      r="8.05556"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.66667"
    />
    <circle cx="4.44488" cy="3.33306" r="2.22222" fill="currentColor" />
    <circle cx="7.77789" cy="17.7779" r="2.22222" fill="currentColor" />
    <circle cx="17.7779" cy="7.77789" r="2.22222" fill="currentColor" />
  </SvgIcon>
);
