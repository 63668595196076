import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <g clip-path="url(#clip0_7429_7865)">
      <path
        d="M14.375 8.125C14.375 6.4674 13.7165 4.87769 12.5444 3.70558C11.3723 2.53348 9.7826 1.875 8.125 1.875C6.4674 1.875 4.87769 2.53348 3.70558 3.70558C2.53348 4.87769 1.875 6.4674 1.875 8.125C1.875 9.7826 2.53348 11.3723 3.70558 12.5444C4.87769 13.7165 6.4674 14.375 8.125 14.375C9.7826 14.375 11.3723 13.7165 12.5444 12.5444C13.7165 11.3723 14.375 9.7826 14.375 8.125ZM13.168 14.4961C11.7852 15.5938 10.0312 16.25 8.125 16.25C3.63672 16.25 0 12.6133 0 8.125C0 3.63672 3.63672 0 8.125 0C12.6133 0 16.25 3.63672 16.25 8.125C16.25 10.0312 15.5938 11.7852 14.4961 13.168L19.7266 18.3984C20.0938 18.7656 20.0938 19.3594 19.7266 19.7227C19.3594 20.0859 18.7656 20.0898 18.4023 19.7227L13.168 14.4961Z"
        fill="currnetColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7429_7865">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
