import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import {
  SidebarListItem,
  SidebarListItemProps,
} from 'src/legacy/components/Sidebar/SidebarListItem';
import { TooltipWrapper } from 'src/legacy/components/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapse: {
      '& .MuiCollapse-wrapper': {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
      },
    },
  }),
);

interface CollapseItemProps {
  show: boolean;
}

export const CollapseItem: React.FC<
  SidebarListItemProps & CollapseItemProps
> = ({
  htmlId,
  label,
  handleClick,
  IconElement,
  show,
  children,
  selected,
  disabled,
  tooltipTitle,
}) => {
  const classes = useStyles();

  return (
    <TooltipWrapper title={tooltipTitle}>
      <SidebarListItem
        selected={selected && !show}
        htmlId={htmlId}
        label={label}
        handleClick={handleClick}
        IconElement={IconElement}
        disabled={disabled}
      />
      <Collapse
        className={classes.collapse}
        appear // enables animation on first mount
        enter // enable enter animation
        exit={false} // disable exit animation
        in={show}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </TooltipWrapper>
  );
};
