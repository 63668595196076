import { useMediaQuery, useTheme } from '@material-ui/core';
import { useContext, useMemo } from 'react';
import { NOTIFICATIONS_STATUSES } from 'src/constants';
import { InboxPageContext } from 'src/context/inboxPageContext';
import {
  InboxNotificationDetailsResponse,
  InboxNotificationResponseFields,
  isInboxNotificationDetailsResponse,
} from 'src/entities/Notifications';
import history from 'src/history';
import { useAppSelector } from 'src/hooks/useStore';
import {
  useDeleteNotificationMutation,
  useGetInboxNotificationsQuery,
  useUpdateInboxNotificationStatusMutation,
} from 'src/services/api/inboxApi';
import { NotificationGroups } from 'src/store/notifications/types';
import { ensureUnreachable } from 'src/utils/common_utils';
import { getPluralDisplayNameFromCount } from 'src/utils/StringUtils';
import {
  InboxNotificationStatus,
  InboxSidebarItemData,
  hasInProductNotification,
  isInboxSidebarItemData,
} from './InboxSidebar';

/**
 * @description Shared hook responsible for formatting inbox notifications and deleting a notification from the inbox. Deletion trigger is handled through delete button in toolbar, action menu and keyboard shortcut (delete key)
 * @returns { deleteNotification, formattedNotifications } function that deletes the selected notification
 */
export const useInboxNotification = () => {
  const clients = useAppSelector((state) => state.clients.clients);
  const internalUsers = useAppSelector((state) => state.users.internalUsers);
  const companyDataMap = useAppSelector(
    (state) => state.clients.companyDataMap,
  );
  const allUsers = new Map(
    [...clients, ...internalUsers].map((c) => [c.id, c]),
  );

  const { selectedNotification, setSelectedNotification } =
    useContext(InboxPageContext);
  const { data: inboxNotifications = [] } = useGetInboxNotificationsQuery();
  const [deleteNotificationMutation] = useDeleteNotificationMutation();
  const [markNotificationAsRead] = useUpdateInboxNotificationStatusMutation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getNotificationItemsData = (
    notification: InboxNotificationDetailsResponse,
  ): InboxSidebarItemData | null => {
    const { fields } = notification;
    const sender =
      allUsers.get(notification.senderId) || allUsers.get(fields.senderUserId);

    const senderCompanyName =
      companyDataMap[sender?.fields.companyId || notification.senderId || '']
        ?.name || '';
    const isCompanyUser = !!senderCompanyName;

    if ((!sender && !isCompanyUser) || sender?.entityStatus === 'DELETED') {
      return null; // means we shouldn't show notification
    }

    // when user is deleted show sender name as 'Deleted User'
    const senderName = sender
      ? `${sender.fields.givenName} ${sender.fields.familyName}`
      : isCompanyUser
      ? ''
      : 'Deleted User';

    // the description info holds the notification details header title
    const description = getDescriptionFromNotification(
      notification,
      senderName,
      senderCompanyName,
      fields,
    );

    // the avatar info data is used to render the notification sender avatar
    // and the sender name.
    const avatarInfo = {
      email: sender?.fields.email || '',
      isCompanyUser,
      // if the senderName is not empty then the
      // name = senderName & companyName = senderCompanyName
      // there are notifications where a senderName is empty eg. invoice paid by a company
      // in this case:
      // name = senderCompanyName
      // companyName = '' since the sender is the company itself
      name: senderName || senderCompanyName,
      companyName: isCompanyUser && senderName != '' ? senderCompanyName : '',
      imageUrl: isCompanyUser
        ? companyDataMap[
            sender?.fields.companyId || notification.senderId || ''
          ]?.avatarUrl || ''
        : sender?.fields.avatarImageUrl || '',
      fallbackColor: isCompanyUser
        ? companyDataMap[
            sender?.fields.companyId || notification.senderId || ''
          ]?.avatarFallbackColor
        : sender?.fields.fallbackColor,
    };

    const notificationGroup = (fields?.groupingEntityType ||
      fields?.notificationGroup ||
      '') as NotificationGroups;

    return {
      id: notification.id,
      title:
        (notification.deliveryTargets?.inProduct &&
          notification.deliveryTargets?.inProduct?.title) ||
        '',
      label: '',
      notificationStatus: notification.isRead
        ? InboxNotificationStatus.Read
        : InboxNotificationStatus.Sent,
      isRead: notification.isRead,
      description,
      avatarInfo,
      notificationGroup,
      channelId: fields.channelId ?? notification.channelId ?? '',
      data: notification.data,
      timestamp: new Date(notification.createdAt),
      resourceId: notification.resourceId,
      refId: notification.ref,
      notificationEventType: notification.event,
    };
  };

  const formattedNotifications = useMemo(() => {
    const notifications: InboxSidebarItemData[] = inboxNotifications
      .slice()
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )
      .filter(
        (n) =>
          isInboxNotificationDetailsResponse(n) && hasInProductNotification(n),
      )
      .map(getNotificationItemsData)
      .filter(isInboxSidebarItemData);

    return notifications;
  }, [inboxNotifications, allUsers]);

  const deleteNotification = async () => {
    if (!selectedNotification) return;
    await deleteNotificationMutation({
      id: selectedNotification.id || '',
    });
    // For mobile experience, we just need to redirect to the notifications page once a notification is deleted because selecting next notification at this point is a weird experience
    if (isMobile) {
      history.replace('/notifications', {
        search: '',
      });
      setSelectedNotification(null);
      return;
    }

    // For desktop experience, we need to find the next notification to select

    // find index of the deleted channel from all channels
    const deletedChannelIndex = formattedNotifications.findIndex(
      (item) => item.id === selectedNotification.id,
    );
    // check that deleted channel is not the last channel in the list
    if (deletedChannelIndex !== -1) {
      const nextItem = formattedNotifications.at(deletedChannelIndex + 1);
      if (nextItem?.id !== undefined) {
        setSelectedNotification(nextItem);
        markNotificationAsRead({
          notificationId: nextItem.id,
          status: NOTIFICATIONS_STATUSES.READ,
        });
      } else {
        setSelectedNotification(null);
      }
    }
  };

  return { deleteNotification, formattedNotifications };
};

/**
 * this formats the title based on the following rules:
 * - If `Title`  starts with a number then Description = Title + `by`  + `Sender`
 * - If `Title`  starts with a vowel then Description = `An` + title + `by`  + `Sender`
 * - If `Title`  starts with a consonant then Description = `A` + title + `by`  + `Sender`
 *
 * @param title the title set in the api
 * @param senderName the senderName as calculated on the webapp
 * @example
 * formatCustomAppNotificationTitle("Enquiry was completed", "Jhon Doe")
 * // returns An Enquiry was completed by Jhon Doe
 * @returns formatted title as per custom app title rules
 */
function formatCustomAppNotificationTitle(
  inProductNotificationTitle: string,
  senderName: string,
): string {
  const StartsWithNumberRegex = /^\d/;
  const StartsWithVowelRegex = /^[aeiou]/i;

  // lowercase the title so that it the full title
  // is formatted as a proper sentence.
  // E.g. A task was completed by John Smith
  const title = inProductNotificationTitle.toLowerCase();

  if (StartsWithNumberRegex.test(title)) {
    return `${title} by ${senderName}`;
  }
  if (StartsWithVowelRegex.test(title)) {
    return `An ${title} by ${senderName}`;
  }
  return `A ${title} by ${senderName}`;
}

function getDescriptionFromNotification(
  notification: InboxNotificationDetailsResponse,
  senderName: string,
  senderCompanyName: string,
  fields: InboxNotificationResponseFields,
): string {
  switch (notification.event) {
    case 'forms.completed':
    case 'formResponse.completed':
      return `A form was submitted by ${senderName}`;
    case 'forms.created':
    case 'form.created':
      return 'A form was created';
    case 'forms.requested':
    case 'formResponse.requested':
      return 'A form was requested';
    case 'files.created':
    case 'files.new': {
      const uploadedFileCount = fields.count || 0;
      return `${senderName} ${
        senderCompanyName ? `from ${senderCompanyName}` : ''
      } uploaded ${getPluralDisplayNameFromCount('file', uploadedFileCount)}`;
    }
    case 'esign.requested':
      return 'A contract was requested';
    case 'esign.completed':
      return 'A contract was signed';
    case 'invoice.paid':
      return `An invoice was paid by ${senderName || senderCompanyName}`;
    case 'invoice.requested':
      return 'An invoice was requested';
    case 'autoChargePayment.requested':
      return 'An auto charge payment was requested';
    case 'contract.signed':
      return `A contract was signed by ${senderName}`;
    case 'contract.requested':
      return 'A contract was requested';
    case 'customApp.action':
      return formatCustomAppNotificationTitle(
        notification.deliveryTargets?.inProduct?.title ?? '',
        senderName || senderCompanyName,
      );
    default:
      return ensureUnreachable(notification.event);
  }
}
