import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CopilotBrandingIcon = (props: SvgIconProps) => (
  <SvgIcon width="85" height="19" viewBox="0 0 85 19" fill="none" {...props}>
    <path
      d="M12.5529 0.973665C14.3644 0.969459 16.1271 1.55836 17.5739 2.65204L16.1153 4.11167C15.0642 3.39574 13.8233 3.01211 12.5529 3.01211C11.2826 3.01211 10.0416 3.3949 8.99052 4.11167L7.53027 2.65204C8.97708 1.55752 10.7406 0.968617 12.5529 0.973665Z"
      fill="currentColor"
    />
    <path
      d="M12.5534 15.6612C11.1763 15.6654 9.8354 15.2153 8.73812 14.3799L7.28711 15.8319C8.7776 17.0409 10.6369 17.7005 12.5534 17.7005C14.4699 17.7005 16.3292 17.0409 17.8206 15.8319L16.3696 14.3799C15.2723 15.2153 13.9313 15.6654 12.5534 15.6612Z"
      fill="currentColor"
    />
    <path
      d="M6.2335 9.33301C6.2293 7.95498 6.67796 6.61397 7.51058 5.51861L6.06294 4.06738C4.88919 5.51524 4.23217 7.3156 4.19772 9.18158C4.16327 11.0476 4.75224 12.8715 5.87138 14.3622L7.33246 12.9009C6.61326 11.851 6.23014 10.6059 6.23434 9.33217L6.2335 9.33301Z"
      fill="currentColor"
    />
    <path
      d="M2.1894 9.33229C2.18268 6.87825 3.05143 4.50329 4.63939 2.63563L3.1951 1.18945C1.26603 3.40457 0.186387 6.23635 0.149418 9.1775C0.11245 12.1186 1.12152 14.9765 2.99514 17.2387L4.44279 15.7892C2.9775 13.9585 2.18268 11.6795 2.18856 9.33229H2.1894Z"
      fill="currentColor"
    />
    <path
      d="M24.5011 9.33243C24.5045 6.33828 23.4232 3.44424 21.458 1.19043L20.0137 2.63661C21.5613 4.46641 22.4275 6.77827 22.4637 9.17763C22.4998 11.577 21.705 13.9149 20.2136 15.791L21.6546 17.249C23.4963 15.0254 24.502 12.2239 24.4969 9.33327H24.5011V9.33243Z"
      fill="currentColor"
    />
    <path
      d="M84.104 14.1272C83.4261 14.1272 83.0543 13.7999 83.0543 13.0582V7.93158H84.9788V6.62265H83.0543V4.0266H80.9766V6.62265H79.3145V7.93158H80.9766V13.0582C80.9766 14.6726 81.7202 15.807 83.4917 15.807C84.0822 15.807 84.5196 15.7197 85.0007 15.567V14.0399C84.8039 14.0836 84.4977 14.1272 84.104 14.1272ZM69.7573 11.1166C69.7573 13.7345 71.5944 15.807 74.3719 15.807C77.1494 15.807 78.9865 13.7345 78.9865 11.1166C78.9865 8.49878 77.1494 6.42631 74.3719 6.42631C71.5944 6.42631 69.7573 8.49878 69.7573 11.1166ZM74.3719 14.3017C72.8848 14.3017 71.8787 13.1455 71.8787 11.1166C71.8787 9.0878 72.8848 7.93158 74.3719 7.93158C75.8591 7.93158 76.8651 9.0878 76.8651 11.1166C76.8651 13.1455 75.8591 14.3017 74.3719 14.3017ZM66.3238 15.5234H68.4014V3.11035H66.3238V15.5234ZM62.3216 5.18282H64.4867V3.11035H62.3216V5.18282ZM62.3653 15.5234H64.4429V6.62265H62.3653V15.5234ZM52.0208 19.0357H54.0985V13.6036C54.6015 14.978 55.7387 15.807 57.0728 15.807C59.391 15.807 61.0312 13.8436 61.0312 11.1166C61.0312 8.3897 59.391 6.42631 57.0946 6.42631C55.52 6.42631 54.5359 7.36437 54.0985 8.73875V6.62265H52.0208V19.0357ZM56.4604 14.3453C54.8858 14.3453 54.0328 13.0582 54.0328 11.1166C54.0328 9.17506 54.8858 7.88795 56.4604 7.88795C58.1225 7.88795 58.9098 9.34959 58.9098 11.1166C58.9098 12.8837 58.1225 14.3453 56.4604 14.3453ZM41.4795 11.1166C41.4795 13.7345 43.3166 15.807 46.0941 15.807C48.8715 15.807 50.7086 13.7345 50.7086 11.1166C50.7086 8.49878 48.8715 6.42631 46.0941 6.42631C43.3166 6.42631 41.4795 8.49878 41.4795 11.1166ZM46.0941 14.3017C44.6069 14.3017 43.6009 13.1455 43.6009 11.1166C43.6009 9.0878 44.6069 7.93158 46.0941 7.93158C47.5812 7.93158 48.5872 9.0878 48.5872 11.1166C48.5872 13.1455 47.5812 14.3017 46.0941 14.3017Z"
      fill="currentColor"
    />
    <path
      d="M35.0009 15.8064C31.3705 15.8064 28.7461 13.2322 28.7461 9.28358C28.7461 5.31316 31.3705 2.76074 35.0009 2.76074C38.3689 2.76074 40.4028 5.05137 40.6652 7.16747H38.347C37.9533 5.72765 36.7724 4.48417 34.9572 4.48417C32.5296 4.48417 30.955 6.38212 30.9768 9.28358C30.9987 12.185 32.5515 14.083 34.9353 14.083C36.9692 14.083 38.0846 12.7959 38.4345 11.3779H40.7308C40.4246 13.6903 38.3033 15.8064 35.0009 15.8064Z"
      fill="currentColor"
    />
  </SvgIcon>
);
