import {
  FILES_PAGE,
  FORMS_PAGE,
  HELPDESK_PAGE,
  MESSAGES_PAGE,
  MODULE_MANAGEMENT_LIST,
  PAYMENTS_PAGE,
  CONTRACTS_PAGE,
  ModuleListItem,
  ModuleSettingsItem,
  ExtensionConfig,
  EXTENSIONS_LEARN_MORE_LINK,
} from 'src/constants';
import { useContentfulApps } from 'src/legacy/components/Settings/AppSetupPage/useContentfulApps';
import { ContentfulAppType } from 'src/services/api/contentfulApi';
import { toCamelCase } from 'src/utils/StringUtils';
import { getIconElement, getIconName } from 'src/legacy/components/Icons';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';

interface UseAppsResult {
  allApps: ModuleListItem[];
  clientApps: ModuleListItem[];
  internalApps: ModuleListItem[];
}

/**
 * An ordered list of the default apps in a workspace.
 */
export const DEFAULT_APPS: ModuleListItem[] = [
  // TODO(@foley): Where is the source of truth for default apps?
  {
    id: 'messages',
    type: 'module',
    title: MESSAGES_PAGE.label,
    Icon: MESSAGES_PAGE.icon,
    fieldName: 'messages',
    iconName: 'messages',
    learnMoreLink: MODULE_MANAGEMENT_LIST.messages.learnMoreLink,
    path: MESSAGES_PAGE.path,
  },
  {
    id: 'files',
    type: 'module',
    title: FILES_PAGE.label,
    Icon: FILES_PAGE.icon,
    fieldName: 'files',
    iconName: 'files',
    learnMoreLink: MODULE_MANAGEMENT_LIST.files.learnMoreLink,
    path: FILES_PAGE.path,
  },
  {
    id: 'contracts',
    type: 'module',
    title: CONTRACTS_PAGE.label,
    Icon: CONTRACTS_PAGE.icon,
    fieldName: 'contracts',
    iconName: 'contracts',
    learnMoreLink: MODULE_MANAGEMENT_LIST.contracts.learnMoreLink,
    path: CONTRACTS_PAGE.path,
  },
  {
    id: 'forms',
    type: 'module',
    title: FORMS_PAGE.label,
    Icon: FORMS_PAGE.icon,
    fieldName: 'forms',
    iconName: 'forms',
    learnMoreLink: MODULE_MANAGEMENT_LIST.forms.learnMoreLink,
    path: FORMS_PAGE.path,
  },
  {
    id: 'payments',
    type: 'module',
    title: 'Billing',
    Icon: PAYMENTS_PAGE.icon,
    fieldName: 'payments',
    iconName: 'payments',
    learnMoreLink: MODULE_MANAGEMENT_LIST.payments.learnMoreLink,
  },
  {
    id: 'knowledgeBase',
    type: 'module',
    title: HELPDESK_PAGE.label,
    Icon: HELPDESK_PAGE.icon,
    fieldName: 'knowledgeBase',
    iconName: 'knowledge',
    learnMoreLink: MODULE_MANAGEMENT_LIST.knowledgeBase.learnMoreLink,
    path: HELPDESK_PAGE.path,
  },
];

export type DefaultModule = (typeof DEFAULT_APPS)[number];

function getLearnMoreLink(module: ModuleSettingsItem): string {
  const defaultModule = DEFAULT_APPS.find(({ id }) => id === module.id);
  return defaultModule?.learnMoreLink ?? EXTENSIONS_LEARN_MORE_LINK;
}

function getModuleListItem(
  module: ModuleSettingsItem,
  extension?: ExtensionConfig,
): Prettify<ModuleListItem> {
  const iconName = getIconName(extension?.icon ?? module.icon ?? '');
  return {
    id: module.id,
    type: extension?.type ?? module.type,
    label: module.label,
    title: extension?.name ?? module.label,
    fieldName: module.id,
    iconName,
    Icon: getIconElement(iconName),
    learnMoreLink: getLearnMoreLink(module),
    isAuthenticated: extension?.isAuthenticated,
    appId: extension?.appId,
    visibilityConfig: module.visibilityConfig,
  };
}

export function useApps(
  {
    includeDisabled,
  }: {
    includeDisabled: boolean;
  } = { includeDisabled: false },
): UseAppsResult {
  const { isLoading, data: { moduleSettings, extensionsSettings } = {} } =
    useGetInstallsQuery();
  const { allApps } = useContentfulApps({ includeInstalledApps: true });
  const contentfulAppsMap = new Map(
    (allApps ?? []).map((app) => [
      app.copilotAppId ?? toCamelCase(app.name),
      app,
    ]),
  );

  const result: UseAppsResult = {
    allApps: [],
    clientApps: [],
    internalApps: [],
  };

  if (isLoading) {
    return result;
  }

  if (!moduleSettings?.length) {
    /** If there are no module settings, defaultModules are all clientApps. */
    return {
      allApps: DEFAULT_APPS,
      clientApps: DEFAULT_APPS,
      internalApps: [],
    };
  }

  moduleSettings
    .filter(({ disabled }) => includeDisabled || !disabled)
    .forEach((module) => {
      const extension = (extensionsSettings ?? {})[module.id];
      const contentfulApp = contentfulAppsMap.get(
        extension?.appId ?? module.id,
      );
      const item = getModuleListItem(module, extension);

      result.allApps.push(item);

      if (contentfulApp) {
        if (contentfulApp.appsType === ContentfulAppType.Client) {
          result.clientApps.push(item);
        } else if (contentfulApp.appsType === ContentfulAppType.Internal) {
          result.internalApps.push(item);
        }
      } else {
        // This is the weakest part of this implementation. If there's no contentful app.
        // Today these are all client apps, but that won't necessarily be the case in
        // the future.
        result.clientApps.push(item);
      }
    });

  return result;
}
