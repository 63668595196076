import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FlowerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.40121 6.12973C8.2102 5.19223 7.98051 3.93168 7.98051 3.26918C7.9788 3.00355 8.02986 2.74022 8.13072 2.49447C8.23159 2.24872 8.38025 2.02545 8.56809 1.83762C8.75592 1.64978 8.97919 1.50112 9.22494 1.40025C9.47068 1.29939 9.73402 1.24833 9.99965 1.25004V1.25004C11.1149 1.25004 12.0188 2.17543 12.0188 3.26918C12.0188 3.91371 11.7903 5.18129 11.5981 6.12973"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.40121 13.8704C8.20942 14.8102 7.98051 16.0641 7.98051 16.7309C7.9788 16.9965 8.02986 17.2599 8.13072 17.5056C8.23159 17.7514 8.38025 17.9746 8.56809 18.1625C8.75592 18.3503 8.97919 18.499 9.22494 18.5998C9.47068 18.7007 9.73402 18.7518 9.99965 18.75V18.75C11.1149 18.75 12.0188 17.8247 12.0188 16.7309C12.0188 16.0848 11.7903 14.8196 11.5981 13.8704"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.8701 8.40146C14.81 8.20966 16.0639 7.98075 16.7307 7.98075C16.9963 7.97904 17.2596 8.0301 17.5054 8.13096C17.7511 8.23183 17.9744 8.38049 18.1622 8.56833C18.3501 8.75617 18.4987 8.97944 18.5996 9.22518C18.7005 9.47093 18.7515 9.73426 18.7498 9.9999V9.9999C18.7498 11.1151 17.8244 12.019 16.7307 12.019C16.0861 12.019 14.8186 11.7905 13.8701 11.5983"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.12973 8.40107C5.19223 8.21005 3.93246 7.98075 3.26918 7.98075C3.00355 7.97904 2.74022 8.0301 2.49447 8.13096C2.24872 8.23183 2.02545 8.38049 1.83762 8.56833C1.64978 8.75617 1.50112 8.97944 1.40025 9.22518C1.29939 9.47093 1.24833 9.73426 1.25004 9.9999V9.9999C1.25004 11.1151 2.17543 12.019 3.26918 12.019C3.91371 12.019 5.18129 11.7905 6.12973 11.5983"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.5986 6.12956C12.1279 5.32956 12.8604 4.28386 13.3318 3.81276C13.5184 3.62368 13.7407 3.47354 13.9858 3.37106C14.2309 3.26859 14.4939 3.21582 14.7596 3.21582C15.0252 3.21582 15.2882 3.26859 15.5333 3.37106C15.7784 3.47354 16.0007 3.62368 16.1873 3.81276V3.81276C16.976 4.60143 16.9607 5.89479 16.1873 6.66823C15.7318 7.12409 14.6768 7.86628 13.8705 8.40143"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.12956 11.5984C5.32956 12.1277 4.28386 12.8601 3.81276 13.3316C3.62368 13.5182 3.47354 13.7405 3.37106 13.9856C3.26859 14.2307 3.21582 14.4937 3.21582 14.7593C3.21582 15.025 3.26859 15.288 3.37106 15.5331C3.47354 15.7782 3.62368 16.0005 3.81276 16.1871C4.60143 16.9757 5.89479 16.9605 6.66823 16.1871C7.12409 15.7316 7.86628 14.6765 8.40144 13.8703"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.8705 11.5984C14.6705 12.1277 15.7162 12.8601 16.1873 13.3316C16.3764 13.5182 16.5265 13.7405 16.629 13.9856C16.7315 14.2307 16.7842 14.4937 16.7842 14.7593C16.7842 15.025 16.7315 15.288 16.629 15.5331C16.5265 15.7782 16.3764 16.0005 16.1873 16.1871V16.1871C15.3986 16.9757 14.1053 16.9605 13.3318 16.1871C12.8752 15.7304 12.1342 14.6773 11.5986 13.8703"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.40202 6.12956C7.8735 5.33151 7.13717 4.28112 6.66881 3.81276C6.48222 3.62368 6.25992 3.47354 6.01483 3.37106C5.76974 3.26859 5.50673 3.21582 5.24108 3.21582C4.97543 3.21582 4.71242 3.26859 4.46733 3.37106C4.22223 3.47354 3.99994 3.62368 3.81334 3.81276V3.81276C3.02467 4.60143 3.03991 5.89479 3.81334 6.66823C4.26686 7.12175 5.32506 7.86667 6.13014 8.40143"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
