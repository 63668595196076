import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GlassesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.75 9.0625C8.75 8.73098 8.8817 8.41304 9.11612 8.17862C9.35054 7.9442 9.66848 7.8125 10 7.8125C10.3315 7.8125 10.6495 7.9442 10.8839 8.17862C11.1183 8.41304 11.25 8.73098 11.25 9.0625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 7.8125H18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 7.8125H1.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 7.8125C2.5 11.5625 3.125 12.8125 5.625 12.8125C8.125 12.8125 8.75 11.5625 8.75 7.8125C8.75 7.8125 8.125 7.1875 5.625 7.1875C3.125 7.1875 2.5 7.8125 2.5 7.8125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 7.8125C17.5 11.5625 16.875 12.8125 14.375 12.8125C11.875 12.8125 11.25 11.5625 11.25 7.8125C11.25 7.8125 11.875 7.1875 14.375 7.1875C16.875 7.1875 17.5 7.8125 17.5 7.8125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
