import * as React from 'react';
import { IconWrapper } from 'src/legacy/components/AgGrid/Renderer/FileCellRendererV2';
import { FolderIcon } from 'src/legacy/components/Icons';
import { FilesTableData } from 'src/store/files/types';
import { ensureUnreachable } from 'src/utils/common_utils';

type Props = {
  file: FilesTableData;
};

export const FileIconRenderer = ({ file }: Props) => {
  switch (file.type) {
    case 'file':
      const extension = file.fileUrl
        ? 'link'
        : (file.fileKey && file.fileKey.split('.').pop()) || '';
      const icon = <IconWrapper extension={extension.toLowerCase()} />;
      return icon || null;
    case 'folder':
      return <FolderIcon style={{ fontSize: 20 }} />;
    default:
      return ensureUnreachable(file.type);
  }
};
