import { Logger } from 'aws-amplify';
import {
  FileNotification,
  EsigRequestNotification,
  EsigRequestCompleteNotification,
  initialNotificationsState,
  NotificationsActions,
  NotificationsActionTypes,
  NotificationGroups,
  NotificationsState,
  Notification,
  Notifications,
  FormRequestNotification,
  NewInvoiceNotification,
  FormCompleteNotification,
} from 'src/store/notifications/types';

const logger = new Logger('NotificationReducer');

/**
 * Compare the new notifications with the existing notifications in store to determine
 * unchanged notifications. Then add the new notifications or leave them out if this
 * is a removal to get the updated notification items property
 * @param state full notification state
 * @param notificationProperty which property in notifcation state to update
 * @param newNotifications the notifcations that have been inserted, updated, deleted
 * @param removeNewNotifcation if this is a remove operation or not
 */
const getUpdatedNotificationItems = (
  state: NotificationsState,
  notificationProperty: keyof Notifications,
  newNotifications: Array<Notification>,
  removeNewNotifcation?: boolean,
) => {
  const updatedNotificationsIds = newNotifications.map(
    (updatedFileNotification) => updatedFileNotification.id,
  );
  const unchangedFileNotifications =
    state[notificationProperty]?.filter(
      (notifcation: Notification) =>
        !updatedNotificationsIds.includes(notifcation.id),
    ) || [];

  return [
    ...unchangedFileNotifications,
    ...(removeNewNotifcation ? [] : newNotifications),
  ];
};

/**
 * Based on the notification type determine which property in the notificationState needs
 * be modified and call function to get updated notifications
 * @param state full notification state
 * @param notificationType which notification type is being updated used to determine property in state to update
 * @param newNotifications the notifcations that have been inserted, updated, deleted
 * @param removeNewNotifcation if this is a remove operation or not
 * @returns updated state with corresponding notifications property updated
 */
const getNotificationItemsStateByType = (
  state: NotificationsState,
  notificationType: NotificationGroups,
  newNotifications: Array<Notification>,
  removeNewNotifcation?: boolean,
) => {
  const newState = { ...state };
  switch (notificationType) {
    case NotificationGroups.FileNotifications: {
      logger.debug('Getting updated file notification state');
      newState.fileNotifications = getUpdatedNotificationItems(
        state,
        'fileNotifications',
        newNotifications,
        removeNewNotifcation,
      ) as Array<FileNotification>;
      break;
    }
    case NotificationGroups.EsigRequestNotifications: {
      logger.debug('Getting updated esign request notification state');
      newState.esigRequestNotifications = getUpdatedNotificationItems(
        state,
        'esigRequestNotifications',
        newNotifications,
        removeNewNotifcation,
      ) as Array<EsigRequestNotification>;
      break;
    }
    case NotificationGroups.EsigRequestCompleteNotifications: {
      logger.debug('Getting updated esign request complete notification state');
      newState.esigRequestCompleteNotifications = getUpdatedNotificationItems(
        state,
        'esigRequestCompleteNotifications',
        newNotifications,
        removeNewNotifcation,
      ) as Array<EsigRequestCompleteNotification>;
      break;
    }
    case NotificationGroups.FormRequestNotifications: {
      logger.debug('Getting updated form request complete notification state');
      newState.formRequestNotifications = getUpdatedNotificationItems(
        state,
        'formRequestNotifications',
        newNotifications,
        removeNewNotifcation,
      ) as Array<FormRequestNotification>;
      break;
    }
    case NotificationGroups.FormCompleteNotifications: {
      logger.debug('Getting updated form request complete notification state');
      newState.formCompletedNotifications = getUpdatedNotificationItems(
        state,
        'formCompletedNotifications',
        newNotifications,
        removeNewNotifcation,
      ) as Array<FormCompleteNotification>;
      break;
    }
    case NotificationGroups.NewInvoiceNotifications: {
      logger.debug('Getting updated new invoice notification state');
      newState.newInvoiceNotifications = getUpdatedNotificationItems(
        state,
        'newInvoiceNotifications',
        newNotifications,
        removeNewNotifcation,
      ) as Array<NewInvoiceNotification>;
      break;
    }
    default:
      logger.debug(
        'Notification type',
        notificationType,
        'did not match expected notifications',
      );
      break;
  }
  return newState;
};

const notificationsReducer = (
  /* eslint-disable-next-line default-param-last */
  state = initialNotificationsState,
  action: NotificationsActions,
) => {
  switch (action.type) {
    case NotificationsActionTypes.SET_NOTIFICATIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case NotificationsActionTypes.INSERT_NOTIFICATIONS:
    case NotificationsActionTypes.UPDATE_NOTIFICATIONS: {
      const { payload: newNotifications, notificationType } = action;
      const newState = getNotificationItemsStateByType(
        state,
        notificationType,
        newNotifications,
      );
      return newState;
    }

    case NotificationsActionTypes.DELETE_NOTIFICATIONS: {
      const { payload: newNotifications, notificationType } = action;
      const newState = getNotificationItemsStateByType(
        state,
        notificationType,
        newNotifications,
        true, // this will remove the notifications only and not add new ones
      );
      return newState;
    }

    case NotificationsActionTypes.MARK_NOTIFICATION_READ_SUCCESS: {
      const { payload: markedIds, notificationType } = action;
      let newState = state;
      switch (notificationType) {
        case NotificationGroups.FormCompleteNotifications:
          newState.formCompletedNotifications =
            state.formCompletedNotifications.filter(
              (n) => !(markedIds ?? []).includes(n.id),
            );
          break;
        default:
          newState = state;
      }
      return newState;
    }

    default:
      return state;
  }
};

export default notificationsReducer;
