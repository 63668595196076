import React from 'react';
import { createStyles, Theme, makeStyles, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { SignaturePageComponent } from 'src/store/signaturePage/types';
import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  CONTRACT_COMPONENT_DEFAULT_WIDTH,
  CONTRACT_COMPONENT_MINIMUM_WIDTH,
  DEFAULT_TEXT_SIGNATURE_LABEL,
  SignatureComponentType,
} from 'src/constants';
import { RootState } from 'src/store';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import ColorUtils from 'src/utils/ColorUtils';
import { ensureUnreachable } from 'src/utils/common_utils';

interface StyleProps {
  isComponentTypeInitial: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      cursor: 'default',
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: ColorUtils.GetColorDarknessShades(
        theme.palette.primary.main,
      ).light,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: ColorUtils.GetColorDarknessShades(
          theme.palette.primary.main,
        ).light,
      },
      height: () => CONTRACT_COMPONENT_DEFAULT_HEIGHT,
      // For contract "Initial" component the default width will be smaller compared to other components
      width: ({ isComponentTypeInitial }: StyleProps) =>
        isComponentTypeInitial
          ? CONTRACT_COMPONENT_MINIMUM_WIDTH
          : CONTRACT_COMPONENT_DEFAULT_WIDTH,
    },
  }),
);

export interface SignaturePlaceholderComponentProps {
  component: SignaturePageComponent;
  setPlaceholderElement: (element: HTMLButtonElement | null) => void;
}

export const SignaturePlaceholderComponent: React.FC<
  SignaturePlaceholderComponentProps
> = ({ component, setPlaceholderElement }) => {
  const isEsignPopoverOpen = useSelector(
    (state: RootState) => state.signaturePage.isEsignPopoverOpen,
  );

  const isComponentTypeInitial =
    component.componentType === SignatureComponentType.OWNER_INITIAL ||
    component.componentType === SignatureComponentType.REQUEST_INITIAL;

  const classes = useStyles({
    isComponentTypeInitial,
  });
  const placeholderButtonRef = React.useRef(null);

  const renderComponentValue = () => {
    let textValue;
    switch (component.componentType) {
      case SignatureComponentType.OWNER_SIGN:
      case SignatureComponentType.REQUEST_SIGN:
        textValue = 'Signature';
        break;
      case SignatureComponentType.OWNER_INITIAL:
      case SignatureComponentType.REQUEST_INITIAL:
        textValue = 'Initials';
        break;
      case SignatureComponentType.OWNER_DATE:
      case SignatureComponentType.REQUEST_DATE:
        textValue = 'Date';
        break;
      case SignatureComponentType.OWNER_TEXT:
      case SignatureComponentType.REQUEST_TEXT:
        textValue = DEFAULT_TEXT_SIGNATURE_LABEL;
        break;
      default:
        ensureUnreachable(component.componentType);
    }

    return <BaseTypography fontType={'12Medium'}>{textValue}</BaseTypography>;
  };

  React.useEffect(() => {
    setPlaceholderElement(placeholderButtonRef.current);
  }, []);

  return (
    <Draggable
      bounds="parent"
      defaultPosition={{ x: component.xPosition, y: component.yPosition }}
      disabled={isEsignPopoverOpen}
    >
      <Button
        className={`${classes.root}`}
        id={component.key}
        data-testid={component.key}
        ref={placeholderButtonRef}
      >
        {renderComponentValue()}
      </Button>
    </Draggable>
  );
};
