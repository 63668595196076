import React from 'react';
import { v4 } from 'uuid';
import { SvgIconProps } from '@material-ui/core';
import { BaseMenu } from 'src/legacy/components/Dropdowns/BaseMenu';
import {
  AlertCircleIcon,
  LinkIcon,
  PhoneIcon,
  EmailIcon,
  TextIcon,
  NumberIcon,
  LocationIcon,
  MultiSelectIcon,
} from 'src/legacy/components/Icons';
import { DELETE_CUSTOM_FIELD_CONFIRMATION } from 'src/constants';
import { CustomFieldOption } from 'src/store/clients/types';
import { AlertModal } from 'src/legacy/components/Modals';
import { CUSTOM_FIELDS } from 'src/legacy/components/CustomFieldsMenu/CustomFieldsMenuConsts';

export type NewCustomFieldOption = Omit<CustomFieldOption, 'id' | 'order'>;
interface FieldValidation {
  invalidText: string;
}
interface FieldDefaultProps {
  icon: (props: SvgIconProps) => JSX.Element;
  placeholder: string;
  validation?: FieldValidation;
}

export const MapFieldTypeToDefaultProps: Record<string, FieldDefaultProps> = {
  phoneNumber: {
    icon: PhoneIcon,
    placeholder: 'Add a phone number',
    validation: {
      invalidText: 'Phone number is invalid',
    },
  },
  email: {
    icon: EmailIcon,
    placeholder: 'Add an email',
    validation: {
      invalidText: 'Please enter a valid email',
    },
  },
  url: {
    icon: LinkIcon,
    placeholder: 'Add a URL',
    validation: {
      invalidText: 'Please enter a valid URL',
    },
  },
  text: {
    icon: TextIcon,
    placeholder: 'Add text',
  },
  number: {
    icon: NumberIcon,
    placeholder: 'Add a number',
  },
  address: {
    icon: LocationIcon,
    placeholder: 'Add an address',
  },
  multiSelect: {
    icon: MultiSelectIcon,
    placeholder: 'Add a tag',
  },
};

interface CustomFieldsMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSave: (customField: CustomFieldOption) => void;
  updatingCustomFieldOption: CustomFieldOption | null;
  onDelete: (customField: CustomFieldOption) => void;
}
export const CustomFieldsMenu: React.FC<CustomFieldsMenuProps> = ({
  anchorEl,
  onClose,
  onSave,
  onDelete,
  updatingCustomFieldOption,
}) => {
  const [isAddingCustomField, setIsAddingCustomField] =
    React.useState<boolean>(false);

  const [deletePropertyModalOpen, setDeletePropertyModalOpen] =
    React.useState(false);
  const [menuInputPlaceholder, setMenuInputPlaceholder] = React.useState('');
  const [selectedCustomFieldOption, setSelectedCustomFieldOption] =
    React.useState<CustomFieldOption | null>(null);
  const menuInputRef = React.useRef<HTMLInputElement>();
  const handleCloseMenu = () => {
    setIsAddingCustomField(false);
    onClose();
    setSelectedCustomFieldOption(null);
  };

  const onSaveClicked = () => {
    // check if selected field option name is defined, if so
    // perform the save otherwise do nothing
    if (!selectedCustomFieldOption || !selectedCustomFieldOption.name.trim())
      return;
    setIsAddingCustomField(true);
    onSave(selectedCustomFieldOption);
    handleCloseMenu();
  };

  const handleFieldNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = ev.target.value;
    setSelectedCustomFieldOption({
      ...selectedCustomFieldOption,
      name: fieldName,
    } as CustomFieldOption);
  };

  const handleFieldOptionSelected = (option: NewCustomFieldOption) => {
    setMenuInputPlaceholder(option.name);
    const fieldId = v4();
    const newCustomField = {
      id: fieldId,
      ...selectedCustomFieldOption,
      ...option,
    } as CustomFieldOption;
    setSelectedCustomFieldOption(newCustomField);
  };

  React.useEffect(() => {
    // since custom field property input
    // is hidden when selected custom field option
    // is falsy, we cannot use the autoFocus prop
    // to keep it focused when it shows up. As alternative
    // solution, we can use the input ref to handle do that.
    if (selectedCustomFieldOption) {
      if (menuInputRef.current) {
        menuInputRef.current.focus();
      }
    }
  }, [selectedCustomFieldOption]);

  // on edit custom field property, set updating custom field
  // option as selected option.
  React.useEffect(() => {
    if (updatingCustomFieldOption) {
      setSelectedCustomFieldOption(updatingCustomFieldOption);
    }
  }, [updatingCustomFieldOption]);

  const handleConfirmDeleteProperty = () => {
    if (selectedCustomFieldOption) {
      onDelete(selectedCustomFieldOption);
      setDeletePropertyModalOpen(false);
      handleCloseMenu();
    }
  };

  const handleDeleteProperty = () => {
    if (isAddingCustomField && selectedCustomFieldOption) {
      handleConfirmDeleteProperty();
      return;
    }

    if (!updatingCustomFieldOption) {
      handleCloseMenu();
      return;
    }

    setDeletePropertyModalOpen(true);
  };

  // When a custom field option is selected show menu with input
  // otherwise we show custom fields options items.
  const menuActions = React.useMemo(
    () =>
      !selectedCustomFieldOption
        ? CUSTOM_FIELDS.map((option: NewCustomFieldOption) => {
            const FieldIcon = MapFieldTypeToDefaultProps[option.type].icon;
            return {
              name: option.name,
              onClick: () => handleFieldOptionSelected(option),
              icon: FieldIcon && (
                <FieldIcon style={{ fontSize: '14px', fill: 'none' }} />
              ),
            };
          })
        : [
            {
              name: 'Save',
              onClick: onSaveClicked,
            },
            {
              name: 'Delete',
              isDelete: true,
              onClick: handleDeleteProperty,
            },
          ],
    // menu actions memoized value should also listen
    // for OnSave method change since OnSave uses a
    // redux state (custom fields).
    [selectedCustomFieldOption, onSave],
  );

  return (
    <>
      <BaseMenu
        menuInputProps={{
          placeholder: menuInputPlaceholder,
          // short circuit if falsy to avoid unctrolled component changed warning
          // https://reactjs.org/docs/uncontrolled-components.html#the-short-circuit
          value: selectedCustomFieldOption?.name || '',
          onChange: handleFieldNameChange,
          // should hide menu input when there is no custom field option selected.
          hidden: !selectedCustomFieldOption,
          inputRef: menuInputRef,
        }}
        menuProps={{
          // disable transition when menu is closing (anchor element is null)
          // so that we dont see menu actions changing state when menu closes.
          transitionDuration: !anchorEl ? 0 : 'auto',
          onClose: (_ev, reason) => {
            if (reason === 'escapeKeyDown') {
              handleCloseMenu();
            }
          },
          anchorEl,
          open: Boolean(anchorEl),
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
          getContentAnchorEl: null,
          onBackdropClick: handleCloseMenu,
        }}
        actions={menuActions}
        variant={(selectedCustomFieldOption && 'customField') ?? undefined}
      />

      <AlertModal
        title="Are you sure you want to delete this property?"
        open={deletePropertyModalOpen}
        onSuccess={handleConfirmDeleteProperty}
        onClose={() => setDeletePropertyModalOpen(false)}
        successButtonLabel="Delete"
        Icon={AlertCircleIcon}
        noCancelButton={false}
      >
        {DELETE_CUSTOM_FIELD_CONFIRMATION}
      </AlertModal>
    </>
  );
};
