import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LogoutIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M13.25 13.75V15.625C13.25 16.1223 13.0525 16.5992 12.7008 16.9508C12.3492 17.3025 11.8723 17.5 11.375 17.5H3.875C3.37772 17.5 2.90081 17.3025 2.54917 16.9508C2.19754 16.5992 2 16.1223 2 15.625V4.375C2 3.87772 2.19754 3.40081 2.54917 3.04917C2.90081 2.69754 3.37772 2.5 3.875 2.5H11C12.0355 2.5 13.25 3.33953 13.25 4.375V6.25M16 13L19 10L16 7M7 10H18.25"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
