import { appAPI, ApiTags, ApiMethods } from '.';
import { Referral } from 'src/constants';

export const referralApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getReferrals: build.query<Referral[], string>({
      query: (ref) => ({
        path: `/entities/referrals?ref=${ref}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.referrals],
    }),
    sendReferralInvites: build.mutation<
      void,
      { emails: string[]; senderName: string }
    >({
      query: (body) => ({
        path: '/entities/referrals',
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
      invalidatesTags: [ApiTags.referrals],
    }),
    invalidateReferrals: build.mutation<any, void>({
      queryFn: () => ({ data: [] }),
      invalidatesTags: [ApiTags.referrals],
    }),
  }),
});

export const {
  useGetReferralsQuery,
  useSendReferralInvitesMutation,
  useInvalidateReferralsMutation,
} = referralApi;
