import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ErrorIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <circle cx="8" cy="8" r="8" fill="#CC0000" />
    <path
      d="M11.0001 4L7.99965 6.99974L4.99991 4L4 4.99991L6.99973 7.99965L4 10.9994L4.99991 11.9993L7.99965 8.99956L11.0001 11.9993L12 10.9994L9.00027 7.99965L12 4.99991L11.0001 4Z"
      fill="white"
    />
  </SvgIcon>
);
