export enum TablePropertiesEntityTypes {
  Invoice = 'STRIPE_INVOICE_TABLE_PROPERTIES',
  Subscription = 'STRIPE_SUBSCRIPTION_TABLE_PROPERTIES',
  CRM = 'CRM_TABLE_PROPERTIES',
  Form = 'FORM_TABLE_PROPERTIES',
  Product = 'PRODUCT_TABLE_PROPERTIES',
  Invoices = 'INVOICES_TABLE_PROPERTIES',
}

export * from './crmColumnConsts';
export * from './invoiceColumnConsts';
export * from './subscriptionsColumnConsts';
export * from './formColumnConsts';
export * from './productsColumnConsts';
