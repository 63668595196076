import React from 'react';
import ReactDOM from 'react-dom';
import { Auth, API, Storage, Logger, Amplify } from 'aws-amplify';
import deepForceUpdate from 'react-deep-force-update';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import queryString from 'query-string';
import isMobile from 'is-mobile';
import { createPath } from 'history';
import { ThemeProvider } from '@material-ui/core';
import { H } from 'highlight.run';
import flagsmith from 'flagsmith';
import App, { UnauthApp } from 'src/legacy/components/App';
import { configureStore } from 'src/store/configureStore';
import history from 'src/history';
import { updateMeta } from 'src/DOMUtils';
import router from 'src/router';
import {
  GetCustomTheme,
  DefaultFlagsContext,
  getClientFlagsContextValue,
} from 'src/context';
import { loadState } from 'src/utils/StorageUtils';
import { UseUnAuthApp } from 'src/utils/AuthUtils';
import { atPolyfill } from 'src/polyfills/at';

const initialState = loadState();

// Safari < 15.4 doesnt have support for the Array.prototype.at or
// String.prototype.at method. This polyfill will add support for it.

if (!Array.prototype.at) {
  Object.defineProperty(Array.prototype, 'at', {
    value: function (index) {
      return atPolyfill(this, index);
    },
    configurable: true,
    writable: true,
  });
}

if (!String.prototype.at) {
  Object.defineProperty(String.prototype, 'at', {
    value: function (index) {
      return atPolyfill(this, index);
    },
    configurable: true,
    writable: true,
  });
}

// check if session data is present and if so override userId
// in initialStore and local storage based on session
const { portalConfig, sessionData } = window.App;
const { currentPortalSession, portalIdToSession } = window.App.sessionData;
// we need to get the current portal session related email to set it as LastAuthUser
const { userId } = portalIdToSession[currentPortalSession] || {};
if (userId) {
  window.localStorage.setItem(
    `CognitoIdentityServiceProvider.${portalConfig.AWS.Auth.userPoolWebClientId}.LastAuthUser`,
    userId,
  );
  if (initialState?.user) {
    initialState.user.id = userId;
  }
}

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map((x) => x._insertCss());
    return () => {
      removeCss.forEach((f) => f());
    };
  },
  isMobile: isMobile(),
  // Initialize a new Redux store
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store: configureStore(
    {
      ...initialState,
      user: {
        ...initialState?.user,
        isClient: portalConfig.viewMode === 'client',
      },
    },
    { history },
    true,
    {
      portalId: portalConfig.portalHeader,
      userId,
    },
  ),
  storeSubscription: null,
  stripeContext: window.App.stripeContext,
  portalConfig,
  flags: window.App.flags,
  sessionData,
  flexibleAuth: window.App.flexibleAuth,
};

const customTheme = GetCustomTheme(context.isMobile, context.portalConfig);

API.configure(context.portalConfig.AWS.API);
Storage.configure(context.portalConfig.AWS.Storage);
Logger.LOG_LEVEL = window.App.logLevel;

const container = document.getElementById('app');
let currentLocation = history.location;
let appInstance;

const scrollPositionsHistory = {};

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;
  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve(context);

    context.title = route.title;
    context.description = route.description;

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key && action !== 'FLAG_UPDATE') {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    if (window.gtag) {
      // when gtag is defined we need to call page_view
      window.gtag('event', 'page_view');
    }

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    let AppComponent = App;
    if (UseUnAuthApp(context.pathname)) {
      // copilot brandded application for login/signup/
      AppComponent = UnauthApp;
    }

    // When mobile client is detected and not loading an unauth path
    // render the mobile page.
    const app = React.createElement(ThemeProvider, {
      theme: customTheme,
      children: React.createElement(AppComponent, {
        context,
        children: route.component,
      }),
    });
    appInstance = renderReactApp(app, container, () => {
      if (isInitialRender) {
        // Switch off the native scroll restoration behavior and handle it manually
        // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
        if (window.history && 'scrollRestoration' in window.history) {
          window.history.scrollRestoration = 'manual';
        }

        const elem = document.getElementById('css');
        if (elem) elem.parentNode.removeChild(elem);

        const materialStyles = document.querySelector('#material-css');
        if (materialStyles) {
          materialStyles.parentElement.removeChild(materialStyles);
        }

        return;
      }

      updateMeta('description', route.description);
      // Update necessary tags in <head> at runtime here, ie:
      // updateMeta('keywords', route.keywords);
      // updateCustomMeta('og:url', route.canonicalUrl);
      // updateCustomMeta('og:image', route.imageUrl);
      // updateLink('canonical', route.canonicalUrl);
      // etc.

      let scrollX = 0;
      let scrollY = 0;
      const pos = scrollPositionsHistory[location.key];
      if (pos) {
        scrollX = pos.scrollX;
        scrollY = pos.scrollY;
      } else {
        const targetHash = location.hash.substr(1);
        if (targetHash) {
          const target = document.getElementById(targetHash);
          if (target) {
            scrollY = window.pageYOffset + target.getBoundingClientRect().top;
          }
        }
      }

      // Restore the scroll position if it was saved into the state
      // or scroll to the given #hash anchor
      // or scroll to top of the page
      window.scrollTo(scrollX, scrollY);

      // Google Analytics tracking. Don't send 'pageview' event after
      // the initial rendering, as it was already sent
      if (window.ga) {
        window.ga('send', 'pageview', createPath(location));
      }
    });
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);

// In lieu of having portalConfig in our localStorage or Cookie,
// the back button loses the current portalHeader from the config,
// and we won't know which portal you belong to anymore.
if (window.history && window.history.pushState) {
  window.onpopstate = (evt) => {
    if (
      portalConfig.portalHeader === 'workspace' &&
      !window.location.pathname.startsWith('/onboarding')
    ) {
      window.location.reload();
    }
  };
}

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}

if (window.App.sentryDSN) {
  Sentry.init({
    dsn: window.App.sentryDSN,
    integrations: [new BrowserTracing()],
    release: window.App.appVersion,
    environment: 'production',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

  if (window.App.highlightOrgId) {
    H.getSessionURL().then((url) => Sentry.setTag('highlight-session', url));
  }
}

if (window.App.flagEnvId) {
  flagsmith.init({
    environmentID: window.App.flagEnvId,
    identity: userId,
    // when flag-smith client initializes it will call
    // get the default environment flags regardless of the
    // user identity. This will cause a flags stale state
    // on the client side when the default env flags are
    // different from the user's identity flags which will
    // create a ui flicker.
    preventFetch: true,
    onChange: () => {
      // Occurs whenever flags are changed
      // Determines if the update came from the server or local cached storage
      // const { isFromServer } = params;
      context.flags = getClientFlagsContextValue(flagsmith);

      onLocationChange(currentLocation, 'FLAG_UPDATE');
    },
  });
}
