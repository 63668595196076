import React from 'react';

/**
 * Hook to get the window size
 * @param {number} comparableScreenWidth - The width of the screen to compare against
 * @returns {object} - The window size and difference between the window size and the comparable screen width in ratio
 */
export const useWindowSize = (comparableScreenWidth: number) => {
  const [[width, height], setSize] = React.useState([0, 0]);
  const differentialRatio = width / comparableScreenWidth;
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { width, height, differentialRatio };
};
