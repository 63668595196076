import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

type ChevronDownIconProps = SvgIconProps;

export const ChevronDownIcon = (props: ChevronDownIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M18 6L10 14L2 6"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
