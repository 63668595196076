import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArrowNEIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M14.5174 13.0415C14.3709 13.0415 14.2374 12.9823 14.1411 12.8859C14.0447 12.7896 13.985 12.6566 13.9855 12.5096L13.9855 6.76901L5.86133 14.8932C5.65358 15.1009 5.31637 15.1009 5.10863 14.8932C4.90088 14.6854 4.90088 14.3482 5.10863 14.1405L13.2323 6.0168L7.49172 6.01682C7.19767 6.01682 6.95929 5.77845 6.9593 5.48439C6.9593 5.19034 7.19767 4.95196 7.49173 4.95196L14.517 4.95194C14.5862 4.95194 14.6553 4.966 14.7206 4.9931C14.8505 5.04679 14.9544 5.15067 15.0081 5.28063C15.0352 5.34587 15.0494 5.41512 15.0494 5.48437L15.0494 12.5096C15.0494 12.8036 14.8115 13.0415 14.5174 13.0415Z"
      fill="currentColor"
    />
  </SvgIcon>
);
