import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DefaultCompanyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.33333 17.5H11.6667M15.8333 17.5V4.16667C15.8333 3.72464 15.6577 3.30072 15.3452 2.98816C15.0326 2.67559 14.6087 2.5 14.1667 2.5H5.83333C5.39131 2.5 4.96738 2.67559 4.65482 2.98816C4.34226 3.30072 4.16667 3.72464 4.16667 4.16667V17.5H15.8333ZM15.8333 17.5H17.5H15.8333ZM15.8333 17.5H11.6667H15.8333ZM4.16667 17.5H2.5H4.16667ZM4.16667 17.5H8.33333H4.16667ZM7.5 5.83333H8.33333H7.5ZM7.5 9.16667H8.33333H7.5ZM11.6667 5.83333H12.5H11.6667ZM11.6667 9.16667H12.5H11.6667ZM8.33333 17.5V13.3333C8.33333 13.1123 8.42113 12.9004 8.57741 12.7441C8.73369 12.5878 8.94565 12.5 9.16667 12.5H10.8333C11.0543 12.5 11.2663 12.5878 11.4226 12.7441C11.5789 12.9004 11.6667 13.1123 11.6667 13.3333V17.5H8.33333Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
