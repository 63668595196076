import { automationsApi } from 'src/services/api/automationsApi';
import { Events } from '../constants/webSocketConsts/events';
import { Dispatch } from 'redux';
import { AutomationRun } from 'src/types/automations';

const updateCache = (
  event: Events,
  runs: AutomationRun[],
  draft?: AutomationRun[],
) => {
  if (!draft) {
    return;
  }
  runs.forEach((run) => {
    const index = draft.findIndex((item) => item.id === run.id);
    switch (event) {
      case Events.Insert: {
        if (index !== -1) {
          return;
        }
        draft.unshift(run);
        break;
      }
      case Events.Modify:
        if (index === -1) {
          draft.unshift(run);
          return;
        }
        draft.splice(
          0,
          draft.length,
          ...draft.map((item) => (item.id === run.id ? run : item)),
        );
        break;
    }
  });
};

export function handleAutomationRunsUpdate(
  event: Events,
  items: AutomationRun[],
  dispatch: Dispatch<any>,
) {
  dispatch(
    automationsApi.util.updateQueryData(
      'getAllWorkspaceAutomationRuns',
      undefined,
      (draft?: AutomationRun[]) => {
        updateCache(event, items, draft);
      },
    ),
  );
  dispatch(
    automationsApi.util.updateQueryData(
      'getAutomationRuns',
      { id: items.at(0)?.automationId || '' },
      (draft?: AutomationRun[]) => {
        updateCache(event, items, draft);
      },
    ),
  );
}
