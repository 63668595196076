import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DashboardIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.7383 9.05836L10.8828 12.0076C10.8069 12.1128 10.7146 12.2051 10.6094 12.281C10.3554 12.4582 10.0418 12.5281 9.73662 12.4755C9.43145 12.4229 9.15933 12.252 8.97934 12C8.79935 11.748 8.72599 11.4352 8.7752 11.1295C8.82442 10.8237 8.99222 10.5497 9.24219 10.367L12.1914 8.51148C12.2584 8.46475 12.3382 8.4397 12.4199 8.4397C12.5016 8.4397 12.5814 8.46475 12.6484 8.51148C12.7326 8.57227 12.7893 8.66391 12.8062 8.76639C12.823 8.86886 12.7986 8.97383 12.7383 9.05836Z"
      fill="currentColor"
    />
    <path
      d="M10 2.5C5.16798 2.5 1.25001 6.41406 1.25001 11.2461C1.24704 13.3839 2.02971 15.4483 3.44923 17.0469C3.4922 17.0937 3.53126 17.1406 3.57423 17.1836C3.66666 17.2837 3.77888 17.3635 3.90376 17.4179C4.02864 17.4724 4.16346 17.5003 4.29969 17.4999C4.43593 17.4996 4.5706 17.4709 4.69519 17.4158C4.81977 17.3607 4.93155 17.2803 5.02345 17.1797C5.65685 16.4913 6.42616 15.9419 7.28275 15.566C8.13934 15.1902 9.06459 14.9962 10 14.9962C10.9354 14.9962 11.8607 15.1902 12.7173 15.566C13.5739 15.9419 14.3432 16.4913 14.9766 17.1797C15.0685 17.2803 15.1802 17.3607 15.3048 17.4158C15.4294 17.4709 15.5641 17.4996 15.7003 17.4999C15.8366 17.5003 15.9714 17.4724 16.0963 17.4179C16.2211 17.3635 16.3334 17.2837 16.4258 17.1836L16.5508 17.0469C17.9703 15.4483 18.753 13.3839 18.75 11.2461C18.75 6.41406 14.832 2.5 10 2.5Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.5355 7.71445L14.4195 6.83047M10 5V6.25V5ZM16.25 11.25H15H16.25ZM5 11.25H3.75H5ZM6.46445 7.71445L5.58047 6.83047L6.46445 7.71445Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);
