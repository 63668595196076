export function updateTag(
  tagName: string,
  keyName: string,
  keyValue: string,
  attrName: string,
  attrValue: string,
) {
  const node = document.head.querySelector(
    `${tagName}[${keyName}="${keyValue}"]`,
  );
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node && node.parentNode) {
    node.parentNode.removeChild(node);
  }
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);
    document.head.appendChild(nextNode);
  }
}

export function updateMeta(name: string, content: string) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property: string, content: string) {
  updateTag('meta', 'property', property, 'content', content);
}

export function updateLink(rel: string, href: string) {
  updateTag('link', 'rel', rel, 'href', href);
}

const getFbPixelScriptBody = () => `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${window.App.fbPixel}');
`;

const gTagScriptBody = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NBSLSZT');
`;

const gTagIFrameBody = `
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NBSLSZT"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

export function createScriptElement(
  scriptSrc: string | null,
  scriptBody?: string,
  isAsync?: boolean,
) {
  const script = document.createElement('script');
  if (scriptSrc) {
    script.src = scriptSrc;
  }

  if (scriptBody) {
    script.innerHTML = scriptBody;
  }

  if (isAsync) {
    script.async = true;
  }

  return script;
}

function insertFbPixel() {
  const fbPixelScript = createScriptElement(null, getFbPixelScriptBody());
  document.head.appendChild(fbPixelScript);

  const fbPixel = document.createElement('noscript');
  const fbPixelImg = document.createElement('img');
  fbPixelImg.height = 1;
  fbPixelImg.width = 1;
  fbPixelImg.style.display = 'none';
  fbPixelImg.src = `https://www.facebook.com/tr?id=${window.App.fbPixel}&ev=PageView&noscript=1`;
  fbPixel.appendChild(fbPixelImg);
  document.head.appendChild(fbPixel);
}

function insertGTag() {
  const gTagScript = createScriptElement(null, gTagScriptBody);
  document.head.appendChild(gTagScript);

  const gTagIFrame = document.createElement('noscript');
  gTagIFrame.innerHTML = gTagIFrameBody;
  document.body.appendChild(gTagIFrame);
}

/**
 * insert capterra pixel
 * <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2154255&vkey=792cea0936a89a7d0222ac7548a2bce0">
 */
function insertCapterraPixel() {
  const capteraPixelImg = document.createElement('img');
  capteraPixelImg.src =
    'https://ct.capterra.com/capterra_tracker.gif?vid=2154255&vkey=792cea0936a89a7d0222ac7548a2bce0';
  document.body.appendChild(capteraPixelImg);
}

export function insertConversionPixels() {
  insertGTag();
  insertCapterraPixel();
}

export function insertFBTrackingScript(trackEventName: string) {
  if (window.App.fbPixel) {
    if (!('fbq' in window) || typeof window.fbq === 'undefined') {
      insertFbPixel();
    }

    if (trackEventName === 'StartTrial' || trackEventName === 'Subscribe') {
      // when start trial or subscribe, we need to track the conversion
      insertConversionPixels();
    }

    const fbExecutionScript = createScriptElement(
      null,
      `fbq('track', '${trackEventName}');`,
    );
    document.head.appendChild(fbExecutionScript);
  }
}

export function insertFrontAppScript() {
  const frontAppScript = createScriptElement(
    'https://dl.frontapp.com/libs/frontjs.min.js',
  );
  document.head.appendChild(frontAppScript);
}
