import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EmbedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.25 14.375L1.25 10L6.25 5.625M13.75 14.375L18.75 10L13.75 5.625M11.875 3.75L8.125 16.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
