import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BugIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.4531 14.7656C15.5816 15.6844 16.25 16.5652 16.25 18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.54688 14.7656C4.41836 15.6844 3.75 16.5648 3.75 18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 8.125C16.1285 7.20625 16.25 5.93477 16.25 4.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 8.04688C3.87148 7.12812 3.75 5.93516 3.75 4.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.125 11.2549H15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 11.2549H1.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 7.5V17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 17.4998C7.25 17.4998 5 15.2498 5 12.4998V8.74707C5 6.20527 7.25 4.99707 10 4.99707C12.75 4.99707 15 5.99707 15 8.74707V12.4998C15 15.2498 12.75 17.4998 10 17.4998Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00898 5.60625C6.93081 5.4102 6.88553 5.20259 6.875 4.9918C6.87717 4.16584 7.20624 3.37432 7.79028 2.79028C8.37432 2.20624 9.16584 1.87717 9.9918 1.875H10.0082C10.8342 1.87717 11.6257 2.20624 12.2097 2.79028C12.7938 3.37432 13.1228 4.16584 13.125 4.9918C13.1175 5.1837 13.0762 5.37278 13.0031 5.55039"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
