import { Events } from 'src/constants/webSocketConsts/events';
import { loadKnowledgeBaseSettings } from 'src/store/knowledgeBase/actions';
import { ensureUnreachable } from 'src/utils/common_utils';

/**
 *
 * @param event websocket event type for message
 * @param items an array of knowledge base settings entities (but should always have just one entity)
 */
const KnowledgeBaseSettingsHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
    case Events.Modify:
      return loadKnowledgeBaseSettings(items.at(0)); // should always have just a single entity
    case Events.Remove:
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default KnowledgeBaseSettingsHandler;
