import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ClientsIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" {...props}>
    <path
      d="M14.0841 17.22V15.5845C14.0841 14.717 13.7395 13.885 13.1261 13.2715C12.5126 12.6581 11.6806 12.3135 10.8131 12.3135H4.27103C3.4035 12.3135 2.5715 12.6581 1.95806 13.2715C1.34463 13.885 1 14.717 1 15.5845V17.22"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.54227 9.04207C9.34882 9.04207 10.8133 7.57758 10.8133 5.77103C10.8133 3.96449 9.34882 2.5 7.54227 2.5C5.73573 2.5 4.27124 3.96449 4.27124 5.77103C4.27124 7.57758 5.73573 9.04207 7.54227 9.04207Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9904 17.2192V15.5837C18.9898 14.8589 18.7486 14.1549 18.3046 13.5821C17.8605 13.0093 17.2389 12.6001 16.5371 12.4189"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2668 2.60571C13.9705 2.78587 14.5941 3.19507 15.0395 3.76882C15.4848 4.34257 15.7265 5.04822 15.7265 5.77453C15.7265 6.50084 15.4848 7.20649 15.0395 7.78023C14.5941 8.35398 13.9705 8.76319 13.2668 8.94334"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
