import {
  automationsApi,
  GetAutomationsResponse,
} from 'src/services/api/automationsApi';
import { Events } from '../constants/webSocketConsts/events';
import { Dispatch } from 'redux';
import { Automation } from 'src/types/automations';
import { isEqual, omit } from 'lodash';
import { current } from '@reduxjs/toolkit';

const updateCache = (
  event: Events,
  automation: Automation,
  draft?: GetAutomationsResponse,
) => {
  if (!draft) {
    return;
  }

  const index = draft.data.findIndex((item) => item.id === automation.id);
  switch (event) {
    case Events.Insert: {
      if (index !== -1) {
        return;
      }
      draft.data.unshift(automation);
      break;
    }
    case Events.Modify:
      if (index === -1) {
        draft.data.unshift(automation);
        return;
      }
      if (
        !isEqual(
          omit(automation, ['updatedAt']),
          omit(current(draft.data[index]), ['updatedAt']),
        )
      ) {
        // only update cache if we find a difference in the items (omitting updatedAt since that will always be different)
        draft.data.splice(
          0,
          draft.data.length,
          ...draft.data.map((item) =>
            item.id === automation.id ? automation : item,
          ),
        );
      }
      break;
  }
};

export function handleAutomationUpdate(
  event: Events,
  item: Automation,
  dispatch: Dispatch<any>,
) {
  dispatch(
    automationsApi.util.updateQueryData(
      'getAutomations',
      undefined,
      (draft?: GetAutomationsResponse) => {
        updateCache(event, item, draft);
      },
    ),
  );
}
