import { isEmpty } from 'lodash';
import { ClientFormData, CompanyFormData } from 'src/store/clients/types';
import {
  Company,
  Client,
  CreatedItem,
  UsersAPIName,
  UnAuthAPIName,
} from 'src/constants';
import { API } from 'src/utils/AmplifyApiUtils';

export interface NewClientSignUpResponse {
  succeededIds: Array<string>;
  failedIds: Array<string>;
  unauthorizedIds: Array<string>;
  createdItems: CreatedItem[];
}

export default class ClientsClient {
  static addClient(clientData: ClientFormData, isUnauth = false) {
    const {
      cognitoEmail,
      cognitoFirstName,
      cognitoLastName,
      companyName,
      companyId,
      sendInvite,
      noPasswordSet,
      externalAuthProviders,
      avatarImageUrl,
    } = clientData;

    const clientUser: any = {
      givenName: cognitoFirstName,
      familyName: cognitoLastName,
      email: cognitoEmail,
      companyName,
    };

    if (companyId) {
      delete clientUser.companyName;
    }
    const APIName = isUnauth ? UnAuthAPIName : UsersAPIName;
    const path = isUnauth ? `/users/signup` : `/v1/users/clients`;
    // when create client from unauth page, this means
    // this is a sign up process, so we need to set
    // password: set when user sign up with email
    // externalAuthProviders: set when user sign up with federated identity
    // noPasswordSet: set when user sign up with federated identity used to
    // indicate that user has not set password yet.
    // avatarImageUrl: set when user sign up with federated identity
    if (isUnauth) {
      clientUser.password = clientData.password;
      clientUser.externalAuthProviders = externalAuthProviders;
      clientUser.noPasswordSet = noPasswordSet;
      clientUser.avatarImageUrl = avatarImageUrl;
    }

    return API.post(APIName, path, {
      body: {
        data: [
          {
            additionalFields: {
              sendInvite,
            },
            fields: {
              companyId,
              ...clientUser,
            },
          },
        ],
      },
    });
  }

  static resetPassword(params: any) {
    return API.post(
      UsersAPIName,
      `/v1/users/clients/${params.userId}/resetPassword`,
      {
        body: {
          newPassword: params.newPW,
        },
      },
    );
  }

  static inviteClient(userId: string) {
    return API.post(UsersAPIName, `/v1/users/clients/${userId}/invite`, {
      body: {},
    });
  }

  static inviteInternalUser(userId: string) {
    return API.post(UsersAPIName, `/v1/users/internal/${userId}/invite`, {
      body: {},
    });
  }

  static copyClientInvite(userId: string) {
    return API.get(UsersAPIName, `/v1/users/clients/${userId}/invite`, {});
  }

  static deleteUser(user: Client) {
    return API.del(UsersAPIName, `/v1/users/clients`, {
      body: {
        data: [
          {
            id: user.id,
          },
        ],
      },
    });
  }

  static createCompany(clientData: ClientFormData) {
    const { companyName } = clientData;
    return API.post('AppAPI', `/entities/companies`, {
      body: {
        data: [
          {
            fields: {
              name: companyName,
            },
          },
        ],
      },
    });
  }

  static deleteCompany(company: Company) {
    return API.del('AppAPI', `/entities/companies`, {
      body: {
        data: [
          {
            id: company.id,
          },
        ],
      },
    });
  }

  static updateCompany(clientData: CompanyFormData) {
    const {
      companyName,
      companyId,
      isPlaceholder,
      customerId,
      leadUserID,
      additionalFields,
      avatarImageURL,
    } = clientData;
    const fields = {
      ...(companyName && { name: companyName }),
      ...(avatarImageURL && { avatarImageURL }),
      ...(String(isPlaceholder).length > 0 && { isPlaceholder }),
      ...(customerId && { customerId }),
      ...(leadUserID && { leadUserID }),
    };
    const updateData = {
      id: companyId,
      ...(!isEmpty(fields) && { fields }),
      ...(additionalFields && {
        additionalFields,
      }),
    };
    return API.put('AppAPI', `/entities/companies`, {
      body: {
        data: [
          {
            ...updateData,
          },
        ],
      },
    });
  }

  static loadClientCustomFields() {
    return API.get('AppAPI', '/entities/custom-fields', {});
  }

  static updateClientCustomFields(customFieldData: any) {
    return API.put('AppAPI', '/entities/custom-fields', {
      body: customFieldData,
    });
  }
}
