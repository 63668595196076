import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FastFoodIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.5781 16.25C12.5781 17.6309 11.7715 18.75 10.3906 18.75H5.23438C3.85352 18.75 3.04688 17.6309 3.04688 16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.125 13.125C13.8152 13.125 14.375 13.8246 14.375 14.6875C14.375 15.5504 13.8152 16.25 13.125 16.25H2.5C1.80977 16.25 1.25 15.5504 1.25 14.6875C1.25 13.8246 1.80977 13.125 2.5 13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.4375 13.125H7.0043C6.92153 13.125 6.84215 13.1579 6.78359 13.2164L5.73555 14.2645C5.72104 14.279 5.7038 14.2905 5.68483 14.2984C5.66587 14.3062 5.64553 14.3103 5.625 14.3103C5.60447 14.3103 5.58413 14.3062 5.56517 14.2984C5.5462 14.2905 5.52896 14.279 5.51445 14.2645L4.46641 13.2164C4.40785 13.1579 4.32847 13.125 4.2457 13.125H2.1875C1.93886 13.125 1.7004 13.0262 1.52459 12.8504C1.34877 12.6746 1.25 12.4361 1.25 12.1875V12.1875C1.25 11.9389 1.34877 11.7004 1.52459 11.5246C1.7004 11.3488 1.93886 11.25 2.1875 11.25H13.4375C13.6861 11.25 13.9246 11.3488 14.1004 11.5246C14.2762 11.7004 14.375 11.9389 14.375 12.1875C14.375 12.4361 14.2762 12.6746 14.1004 12.8504C13.9246 13.0262 13.6861 13.125 13.4375 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.5 10.7812V10.7727C2.5 8.62422 4.25781 7.5 6.40625 7.5H9.21875C11.3672 7.5 13.125 8.63281 13.125 10.7812V10.7727"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.41406 4.375L9.70469 6.87383"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 18.75H15.4418C15.7572 18.75 16.0609 18.6308 16.292 18.4163C16.5232 18.2018 16.6647 17.9078 16.6883 17.5934L18.0859 4.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.375 4.375L15 1.875L16.8359 1.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 4.375H18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
