import React from 'react';
import {
  useGetFormsQuery,
  useShareFormMutation,
} from 'src/services/api/formsApi';
import { getUserIdFromFullId } from 'src/utils/UserUtils';
import { FormModal } from 'src/legacy/components/Modals';
import { useShareForm } from 'src/legacy/components/FormsV2/useShareForm';

interface SendFormData {
  fields: {
    recipientIds: string[];
  };
}

const initialSendFormValues = {
  fields: {
    recipientIds: [],
  },
};

/**
 *
 * @param recipientIds string[]
 * processRecipientIds takes recipientIds as a combination of <portalId>_<user-id>
 * and splits the last part out by grabbing the last instance of '_'.
 * PortalIds can include this character, so we need to always assume the last instance
 * @returns processedIds
 */
export const processRecipientIds: (recipientIds: string[]) => string[] = (
  recipientIds: string[],
) => recipientIds.map(getUserIdFromFullId);

type Props = {
  open: boolean;
  selectedFormId: string;
  handleCloseSendFormModal: () => void;
};

export const ShareFormModal = ({
  open,
  selectedFormId,
  handleCloseSendFormModal,
}: Props) => {
  const [shareForm] = useShareFormMutation();
  const { data: formsTemplate = [] } = useGetFormsQuery();

  const selectedForm = formsTemplate?.find(
    (form) => form.id === selectedFormId,
  );

  const sendForm = async (formData: SendFormData) => {
    const { recipientIds } = formData.fields;
    const idsFromSelectOptionIds = processRecipientIds(recipientIds);
    const shareFormData = {
      formId: selectedFormId,
      clientIds: idsFromSelectOptionIds,
    };

    await shareForm(shareFormData);
  };

  return (
    <FormModal
      key="send-form"
      initialFormValue={initialSendFormValues}
      title="Share form"
      buttonLabel="Send form"
      actionLabel="Send"
      handleSave={sendForm}
      onClose={handleCloseSendFormModal}
      useFormHook={() => useShareForm(selectedFormId, selectedForm?.ref)}
      open={open}
      isOuterBackdrop={false}
    />
  );
};
