import {
  Avatar as MuiAvatar,
  AvatarProps,
  makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import Typography from 'src/legacy/components/Text/BaseTypography';
import { NonHoverBorder } from 'src/theme/colors';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    border: `1px solid ${NonHoverBorder}`,
    backgroundColor: (props: { backgroundColor: string }) =>
      props.backgroundColor,
  },
  // when the avatar image has transparent,
  // the background color should be white
  avatarImg: {
    backgroundColor: 'white',
  },
}));

interface BaseAvatarProps {
  fallbackColor: string;
  fallbackLetters: string;
}

/**
 * This component is the base avatar component. It will be replacing
 * the UserAvatar component in the future. For now it is only used
 * in the ChannelListItem component.
 * @todo - set initial letter font size dynamically based on the avatar size
 * @param props - AvatarProps & BaseAvatarProps
 * @returns JSX.Element
 */
export const Avatar: React.FC<AvatarProps & BaseAvatarProps> = ({
  className,
  fallbackColor,
  fallbackLetters,
  ...otherProps
}) => {
  const classes = useStyles({ backgroundColor: fallbackColor });
  return (
    <MuiAvatar
      classes={{
        img: classes.avatarImg,
      }}
      className={classNames(classes.root, className)}
      {...otherProps}
    >
      <Typography fontType="13Regular">{fallbackLetters}</Typography>
    </MuiAvatar>
  );
};
