import React from 'react';
import { Theme, makeStyles, SvgIcon } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import {
  ModalWrapper,
  ModalWrapperProps,
} from 'src/legacy/components/Modals/ModalWrapper';
import RowDivider from 'src/legacy/components/RowDivider';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    padding: theme.spacing(0, 3.5),
  },
  successModalText: {
    color: Colors.GraySmall,
  },

  icon: {
    color: Colors.GraySmall,
    margin: 'auto',
  },
}));

interface ContentWrapperProps {
  id: string;
}

interface AlertModalProps {
  Icon?: typeof SvgIcon;
  dividerPosition?: 'top-bottom' | 'bottom' | 'top';
  contentWrapperProps?: ContentWrapperProps;
}

const AlertModal: React.FC<ModalWrapperProps & AlertModalProps> = ({
  children,
  Icon,
  dividerPosition = 'bottom',
  contentWrapperProps = {},
  ...other
}) => {
  const classes = useStyles();

  const iconElementProps = Icon
    ? { alertIcon: <Icon className={classes.icon} /> }
    : {};

  return (
    <ModalWrapper
      closeButton={false}
      hasBackdrop
      hideDividers
      modalType="warning"
      positiveAction
      noCancelButton
      denseContent
      width={540}
      {...iconElementProps}
      {...other}
    >
      <div className={classes.contentWrapper} {...contentWrapperProps}>
        {dividerPosition.startsWith('top') && <RowDivider mt={0} mb={2.5} />}
        <BaseTypography
          fontType="15Regular"
          className={classes.successModalText}
        >
          {children}
        </BaseTypography>
        {dividerPosition.endsWith('bottom') && <RowDivider mt={2.5} mb={0} />}
      </div>
    </ModalWrapper>
  );
};

export default AlertModal;
