import { Body, Icon } from 'copilot-design-system';
import React from 'react';

export type CalloutType = 'success' | 'fail' | 'warning' | 'info' | 'neutral';

export interface CalloutProps {
  label: string;
  status: CalloutType;
  className: string;
  iconSizeClassName?: string;
}

const colorMap = {
  success: 'bg-[#DFFFEB]',
  fail: 'bg-[#FFEDE8]',
  warning: 'bg-[#FEF6D0]',
  info: 'bg-[#E6F0FF]',
  neutral: 'bg-[#F8F9FB]',
};

export const Callout = ({
  status,
  label,
  className,
  iconSizeClassName = 'w-6 h-6',
}: CalloutProps) => {
  const color = colorMap[status];
  return (
    <div
      className={`text-black-heading p-3 flex items-start gap-2 rounded-4 ${color} ${className}`}
    >
      {getIcon(status, iconSizeClassName)}
      <Body className="font-medium">{label}</Body>
    </div>
  );
};

function getIcon(status: CalloutType, iconSizeClassName: string) {
  let icon;
  switch (status) {
    case 'success':
      icon = (
        <Icon icon="Check" className={`${iconSizeClassName} text-[#115B3B]`} />
      );
      break;
    case 'fail':
      icon = (
        <Icon icon="Failed" className={`${iconSizeClassName} text-[#991A00]`} />
      );
      break;
    case 'warning':
      icon = (
        <Icon
          icon="Warning"
          className={`${iconSizeClassName} text-[#863805]`}
        />
      );
      break;
    case 'info':
      icon = <Icon icon="Info" className={iconSizeClassName} />;
      break;
    default:
      return null;
  }

  return <div>{icon}</div>;
}
