import { appAPI } from '.';
import { notify } from 'src/clients/ApiService';
import { LatestReleaseDismissData } from 'src/constants';
import { ApiMethods, ApiTags } from 'src/services/api/appApi';

const latestReleaseApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getDismissedLatestRelease: build.query<LatestReleaseDismissData, null>({
      query: () => ({
        path: `/latest-releases/`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.latest_releases],
    }),
    updateLatestReleaseDismissal: build.mutation<
      LatestReleaseDismissData,
      unknown
    >({
      query: (body) => ({
        path: `/latest-releases/dismiss`,
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
      async onQueryStarted({ structFields }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          latestReleaseApi.util.updateQueryData(
            'getDismissedLatestRelease',
            null,
            (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft.structFields.latest_release_dismissed =
                structFields.latest_release_dismissed;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          notify({
            status: 'error',
            errorMessage: 'This latest release card could not be deleted.',
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const {
  useUpdateLatestReleaseDismissalMutation,
  useGetDismissedLatestReleaseQuery,
} = latestReleaseApi;
