import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EaselIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 3.125H3.125C2.43464 3.125 1.875 3.68464 1.875 4.375V12.5C1.875 13.1904 2.43464 13.75 3.125 13.75H16.875C17.5654 13.75 18.125 13.1904 18.125 12.5V4.375C18.125 3.68464 17.5654 3.125 16.875 3.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M10 16.25V13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.125V1.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 18.125L14.375 13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 18.125L5.625 13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
