import { UnAuthAPIName } from 'src/constants';
import { amplifyBaseQuery, ApiMethods } from 'src/services/api/appApi';
import { createApi } from '@reduxjs/toolkit/query/react';

type SendAccessLinkResponse = {
  message: string;
};

export type SendAccessLinkRequest = {
  email: string;
  fullName?: string;
  companyName?: string;
  accessType: 'login' | 'signup' | 'resend';
  previousLinkId?: string;
};
export const unAuthApi = createApi({
  baseQuery: amplifyBaseQuery({ apiName: UnAuthAPIName }),
  reducerPath: 'unAuthAPI',
  endpoints: (build) => ({
    sendAccessLink: build.mutation<
      SendAccessLinkResponse,
      SendAccessLinkRequest
    >({
      query: (input) => ({
        path: '/users/access',
        method: ApiMethods.post,
        options: {
          body: {
            ...input,
          },
        },
      }),
    }),
  }),
});

export const { useSendAccessLinkMutation } = unAuthApi;
