import React from 'react';
import { PERM_MOD_SETTINGS_MEMBERS, USER_OPERATIONS } from 'src/constants';
import { PortalConfigContext } from 'src/context';
import { useAppSelector } from 'src/hooks/useStore';
import { useUserAccess } from 'src/hooks/useUserAccess';

export const useUserCanInvite = () => {
  const portalConfig = React.useContext(PortalConfigContext);
  const isClient = useAppSelector((state) => state.user.isClient);
  const isInviteConfigDisabled = Boolean(
    isClient && portalConfig.features.clientInvitesDisabled,
  );
  const isUserCanInvite =
    !isInviteConfigDisabled &&
    useUserAccess(PERM_MOD_SETTINGS_MEMBERS, USER_OPERATIONS.Invite);

  return { isUserCanInvite, isInviteConfigDisabled };
};
