import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LockClosedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.125 8.125V4.41406C13.125 3.58526 12.7958 2.79041 12.2097 2.20435C11.6237 1.6183 10.8288 1.28906 10 1.28906C9.1712 1.28906 8.37634 1.6183 7.79029 2.20435C7.20424 2.79041 6.875 3.58526 6.875 4.41406V8.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 8.125H5.625C4.58947 8.125 3.75 8.96447 3.75 10V16.875C3.75 17.9105 4.58947 18.75 5.625 18.75H14.375C15.4105 18.75 16.25 17.9105 16.25 16.875V10C16.25 8.96447 15.4105 8.125 14.375 8.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
