import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrendingUpIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.75 5.625H18.125V10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 14.375L6.61602 9.63398C6.73209 9.51788 6.8699 9.42578 7.02158 9.36294C7.17326 9.30011 7.33582 9.26777 7.5 9.26777C7.66418 9.26777 7.82674 9.30011 7.97842 9.36294C8.1301 9.42578 8.26791 9.51788 8.38398 9.63398L10.366 11.616C10.4821 11.7321 10.6199 11.8242 10.7716 11.8871C10.9233 11.9499 11.0858 11.9822 11.25 11.9822C11.4142 11.9822 11.5767 11.9499 11.7284 11.8871C11.8801 11.8242 12.0179 11.7321 12.134 11.616L17.5 6.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
