import { useGetClientUsersQuery } from 'src/services/api';
import { useGetCompaniesQuery } from 'src/services/api/companiesApi';

export const useFetchClientAccessData = (skip: boolean) => {
  const { isFetching: clientsLoading, data: clients } = useGetClientUsersQuery(
    { ignoreAccess: true },
    { skip },
  );

  const { isFetching: companiesLoading, data: companies } =
    useGetCompaniesQuery({ ignoreAccess: true }, { skip });

  return {
    isLoading: clientsLoading || companiesLoading,
    clients: clients || [],
    companies: companies || [],
  };
};
