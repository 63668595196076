import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SpeedometerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.7378 9.0586L10.8823 12.0078C10.8064 12.113 10.7141 12.2053 10.6089 12.2813C10.3549 12.4584 10.0413 12.5283 9.73613 12.4757C9.43096 12.4231 9.15885 12.2523 8.97885 12.0003C8.79886 11.7483 8.72551 11.4355 8.77472 11.1297C8.82393 10.824 8.99173 10.55 9.24171 10.3672L12.1909 8.51173C12.258 8.465 12.3377 8.43994 12.4194 8.43994C12.5012 8.43994 12.5809 8.465 12.648 8.51173C12.7322 8.57251 12.7889 8.66416 12.8057 8.76663C12.8225 8.8691 12.7981 8.97407 12.7378 9.0586Z"
      fill="currentColor"
    />
    <path
      d="M10 2.5C5.16798 2.5 1.25001 6.41406 1.25001 11.2461C1.24704 13.3839 2.02971 15.4483 3.44923 17.0469C3.4922 17.0938 3.53126 17.1406 3.57423 17.1836C3.66666 17.2837 3.77888 17.3635 3.90376 17.4179C4.02864 17.4724 4.16346 17.5003 4.29969 17.4999C4.43593 17.4996 4.5706 17.4709 4.69519 17.4158C4.81977 17.3607 4.93155 17.2803 5.02345 17.1797C5.65685 16.4913 6.42616 15.9419 7.28275 15.5661C8.13934 15.1902 9.06459 14.9962 10 14.9962C10.9354 14.9962 11.8607 15.1902 12.7173 15.5661C13.5739 15.9419 14.3432 16.4913 14.9766 17.1797C15.0685 17.2803 15.1802 17.3607 15.3048 17.4158C15.4294 17.4709 15.5641 17.4996 15.7003 17.4999C15.8366 17.5003 15.9714 17.4724 16.0963 17.4179C16.2211 17.3635 16.3334 17.2837 16.4258 17.1836L16.5508 17.0469C17.9703 15.4483 18.753 13.3839 18.75 11.2461C18.75 6.41406 14.832 2.5 10 2.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 5V6.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.25 11.25H15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5 11.25H3.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.46406 7.71455L5.58008 6.83057"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.5352 7.71455L14.4191 6.83057"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
