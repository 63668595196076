import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { getInitials } from 'src/utils/UserUtils';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { AvatarTextShadow } from 'src/theme/shadows';
import { AvatarSizeType } from 'src/legacy/components/User/UserAvatar';

type AvatarContentProps = {
  avatarSize: AvatarSizeType;
  initialLetters: string;
  name: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    fallbackTypographyVariant: {
      color: '#F4F6F8',
      textShadow: AvatarTextShadow,
    },
  }),
);

export const AvatarContentRenderer = ({
  avatarSize,
  initialLetters,
  name,
}: AvatarContentProps) => {
  const classes = useStyles({ avatarSize });

  const getInitialValues = () => {
    if (initialLetters) {
      return initialLetters;
    }

    return name;
  };

  if (avatarSize === 'large') {
    return (
      <BaseTypography
        fontType="65Regular"
        className={classes.fallbackTypographyVariant}
      >
        {getInitials(getInitialValues())}
      </BaseTypography>
    );
  }
  if (avatarSize === 'mini') {
    return (
      <BaseTypography fontType="10Regular">
        {getInitials(getInitialValues())}
      </BaseTypography>
    );
  }

  if (avatarSize === '18small') {
    return (
      <BaseTypography fontType="9Regular">
        {getInitials(getInitialValues())}
      </BaseTypography>
    );
  }
  return (
    <BaseTypography className={classes.fallbackTypographyVariant}>
      {getInitials(getInitialValues())}
    </BaseTypography>
  );
};
