import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CallIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.6174 14.6094C16.9971 13.9844 15.4948 13.0723 14.7658 12.7047C13.8166 12.2266 13.7385 12.1875 12.9924 12.7418C12.4948 13.1118 12.1639 13.4422 11.5815 13.318C10.9991 13.1938 9.73343 12.4934 8.62522 11.3887C7.51702 10.284 6.776 8.98167 6.6514 8.4012C6.52679 7.82074 6.86272 7.49378 7.22913 6.99495C7.74554 6.29183 7.70647 6.17464 7.26507 5.22542C6.92093 4.48714 5.98225 2.99886 5.35491 2.38167C4.68382 1.71878 4.68382 1.83597 4.2514 2.01566C3.89935 2.16378 3.56161 2.34383 3.24241 2.55355C2.61741 2.96878 2.27054 3.3137 2.02796 3.83206C1.78538 4.35042 1.6764 5.56566 2.92913 7.84144C4.18186 10.1172 5.06077 11.2809 6.87991 13.095C8.69905 14.909 10.0979 15.8844 12.1428 17.0313C14.6725 18.4481 15.6428 18.1719 16.1627 17.9297C16.6826 17.6875 17.0291 17.3438 17.4451 16.7188C17.6554 16.4001 17.8359 16.0628 17.9842 15.711C18.1643 15.2801 18.2815 15.2801 17.6174 14.6094Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
