export default {
  toolbar: {
    minHeight: 52,
    '@media (min-width:0px) and (orientation: landscape)': {
      minHeight: 52,
    },
    '@media (min-width:600px)': {
      minHeight: 61,
    },
  },
};
