import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PodiumIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.25 6.25V17.8125C1.25 17.8954 1.28292 17.9749 1.34153 18.0335C1.40013 18.0921 1.47962 18.125 1.5625 18.125H6.875V6.25C6.875 6.08424 6.80915 5.92527 6.69194 5.80806C6.57473 5.69085 6.41576 5.625 6.25 5.625H1.875C1.70924 5.625 1.55027 5.69085 1.43306 5.80806C1.31585 5.92527 1.25 6.08424 1.25 6.25V6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 1.875H7.5C7.33424 1.875 7.17527 1.94085 7.05806 2.05806C6.94085 2.17527 6.875 2.33424 6.875 2.5V18.125H13.125V2.5C13.125 2.33424 13.0592 2.17527 12.9419 2.05806C12.8247 1.94085 12.6658 1.875 12.5 1.875V1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.125 8.125H13.75C13.5842 8.125 13.4253 8.19085 13.3081 8.30806C13.1908 8.42527 13.125 8.58424 13.125 8.75V18.125H18.4375C18.5204 18.125 18.5999 18.0921 18.6585 18.0335C18.7171 17.9749 18.75 17.8954 18.75 17.8125V8.75C18.75 8.58424 18.6842 8.42527 18.5669 8.30806C18.4497 8.19085 18.2908 8.125 18.125 8.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
