import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NavigateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 2.5L2.5 9.38047H10.3125C10.3954 9.38047 10.4749 9.41339 10.5335 9.472C10.5921 9.5306 10.625 9.61009 10.625 9.69297V17.5L17.5 2.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
