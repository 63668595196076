import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ExtensionPuzzleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.1586 9.61319H15.0781C15.0574 9.61319 15.0375 9.60496 15.0229 9.59031C15.0082 9.57566 15 9.55578 15 9.53506V6.51788C15 6.11529 14.8401 5.72919 14.5554 5.44451C14.2707 5.15984 13.8846 4.99991 13.482 4.99991H10.4648C10.4441 4.99991 10.4243 4.99168 10.4096 4.97703C10.3949 4.96237 10.3867 4.9425 10.3867 4.92178V3.84131C10.3867 2.78116 9.54687 1.89366 8.48672 1.8753C8.22986 1.87079 7.97468 1.91747 7.73606 2.01263C7.49743 2.10779 7.28015 2.24952 7.09689 2.42955C6.91362 2.60958 6.76805 2.8243 6.66865 3.06119C6.56926 3.29808 6.51803 3.55239 6.51797 3.80928V4.92178C6.51797 4.9425 6.50974 4.96237 6.49509 4.97703C6.48044 4.99168 6.46056 4.99991 6.43984 4.99991H3.42266C3.01257 5.00114 2.61964 5.1646 2.32966 5.45457C2.03969 5.74455 1.87624 6.13748 1.875 6.54756V9.29678C1.875 9.3175 1.88323 9.33737 1.89788 9.35203C1.91253 9.36668 1.9324 9.37491 1.95312 9.37491H3.0043C4.15156 9.37491 5.10117 10.3702 5.11719 11.5175C5.13359 12.6843 4.19883 13.7499 3.03555 13.7499H1.95312C1.9324 13.7499 1.91253 13.7581 1.89788 13.7728C1.88323 13.7874 1.875 13.8073 1.875 13.828V16.5773C1.87624 16.9873 2.03969 17.3803 2.32966 17.6702C2.61964 17.9602 3.01257 18.1237 3.42266 18.1249H6.17188C6.1926 18.1249 6.21247 18.1167 6.22712 18.102C6.24177 18.0874 6.25 18.0675 6.25 18.0468V17.2292C6.25 16.0464 7.2168 15.028 8.39844 15.0003C9.57422 14.973 10.625 15.7937 10.625 16.9644V18.0468C10.625 18.0675 10.6332 18.0874 10.6479 18.102C10.6625 18.1167 10.6824 18.1249 10.7031 18.1249H13.482C13.8846 18.1249 14.2707 17.965 14.5554 17.6803C14.8401 17.3956 15 17.0095 15 16.6069V13.5601C15 13.5393 15.0082 13.5195 15.0229 13.5048C15.0375 13.4902 15.0574 13.4819 15.0781 13.4819H16.1906C17.2699 13.4819 18.125 12.5964 18.125 11.5132C18.125 10.43 17.2188 9.61319 16.1586 9.61319Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
