import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RestaurantIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M2.2454 1.86475L16.6372 16.2565C16.9103 16.5296 17.0637 16.9 17.0637 17.2862C17.0637 17.6724 16.9103 18.0428 16.6372 18.3159V18.3159C16.3641 18.5889 15.9937 18.7422 15.6075 18.7422C15.2213 18.7422 14.851 18.5889 14.5778 18.3159L11.0622 14.7397C10.8321 14.5061 10.7031 14.1915 10.7028 13.8636V13.6476C10.7029 13.482 10.67 13.3181 10.6062 13.1653C10.5423 13.0126 10.4488 12.874 10.331 12.7577L9.87704 12.3386C9.72296 12.1964 9.53558 12.0953 9.33216 12.0445C9.12874 11.9937 8.91582 11.9949 8.71298 12.0479V12.0479C8.39313 12.1314 8.057 12.1298 7.73796 12.0433C7.41892 11.9567 7.12806 11.7883 6.89423 11.5546L3.55712 8.21709C1.57743 6.2374 0.848919 3.24795 2.2454 1.86475Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 1.25L12.6074 4.26758C12.3752 4.49973 12.191 4.77536 12.0653 5.07871C11.9397 5.38206 11.875 5.70719 11.875 6.03555V6.61602C11.875 6.69814 11.8588 6.77947 11.8274 6.85534C11.796 6.93122 11.7499 7.00015 11.6918 7.0582L11.25 7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 8.75L12.9418 8.3082C12.9999 8.25011 13.0688 8.20403 13.1447 8.17259C13.2205 8.14116 13.3019 8.12499 13.384 8.125H13.9645C14.2928 8.125 14.6179 8.06032 14.9213 7.93465C15.2246 7.80898 15.5003 7.62478 15.7324 7.39258L18.75 4.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1875 2.8125L14.0625 5.9375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.81217 14.375L3.91685 18.2922C3.62384 18.5851 3.22649 18.7497 2.81217 18.7497C2.39785 18.7497 2.00049 18.5851 1.70748 18.2922V18.2922C1.41456 17.9992 1.25 17.6018 1.25 17.1875C1.25 16.7732 1.41456 16.3758 1.70748 16.0828L4.99967 12.8125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
