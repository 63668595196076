import Stripe from 'stripe';
import { ApiMethods, ApiTags, appAPI } from '.';
import { OnboardingStatus } from 'src/entities/OnboardingStatus';
import { notify } from 'src/clients/ApiService';
import { setTrialState } from 'src/store/user/actions';

export interface PatchDismissalStateParams {
  fields: Partial<OnboardingStatus['fields']>;
}

export interface GetOnboardingTaskStatusParams {
  subscriptions?: Stripe.Subscription[];
}

export const improvedOnboardingApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    patchDismissalState: build.mutation<
      OnboardingStatus,
      PatchDismissalStateParams
    >({
      query: (entity) => ({
        path: `/onboarding-task-status`,
        method: ApiMethods.patch,
        options: {
          body: {
            ...entity,
          },
        },
      }),
      async onQueryStarted(input, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          improvedOnboardingApi.util.updateQueryData(
            'getOnboardingTaskStatus',
            {},
            (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft.fields.dismissed = Boolean(input.fields.dismissed);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          // if there is an error, we need to revert the state
          patch.undo();
          notify({
            status: 'error',
            errorMessage: `Card can not be \`${
              input.fields.dismissed ? 'dismissed' : 'un-dismissed'
            }\``,
            error,
            dispatch,
          });
        }
      },
    }),
    getOnboardingTaskStatus: build.query<
      OnboardingStatus,
      GetOnboardingTaskStatusParams
    >({
      query: () => ({
        path: '/onboarding-task-status',
        method: ApiMethods.get,
        options: {},
      }),
      async onQueryStarted(input, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setTrialState(input.subscriptions, result.data));
        } catch (error) {
          // we want to fail silently because it means that query failed which will happen only if user doesn't have permission
          // and if user doesn't have permission then we don't want to show onboarding tasks to them any way
          console.warn(error);
        }
      },
      providesTags: [ApiTags.onboarding_task_status],
    }),
  }),
});

export const {
  usePatchDismissalStateMutation,
  useLazyGetOnboardingTaskStatusQuery,
  useGetOnboardingTaskStatusQuery,
} = improvedOnboardingApi;
