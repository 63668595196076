import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EditIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M9.16675 3.33325H3.33341C2.89139 3.33325 2.46746 3.50885 2.1549 3.82141C1.84234 4.13397 1.66675 4.55789 1.66675 4.99992V16.6666C1.66675 17.1086 1.84234 17.5325 2.1549 17.8451C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8451C16.4912 17.5325 16.6667 17.1086 16.6667 16.6666V10.8333"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4167 2.08344C15.7483 1.75192 16.1979 1.56567 16.6667 1.56567C17.1356 1.56567 17.5852 1.75192 17.9167 2.08344C18.2483 2.41496 18.4345 2.8646 18.4345 3.33344C18.4345 3.80228 18.2483 4.25192 17.9167 4.58344L10.0001 12.5001L6.66675 13.3334L7.50008 10.0001L15.4167 2.08344Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
