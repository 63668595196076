import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NumberedListIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.25 14.375H17.5M6.25 5.625H17.5H6.25ZM6.25 10H17.5H6.25Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0 13H4V12.6782C3.16484 12.6449 3.10623 12.6338 2.9304 12.5229C2.72528 12.3897 2.66667 12.1678 2.66667 11.4244V8.12899C2.66667 6.81969 2.71062 5.87656 2.78388 5C2.06593 5.13315 1.11355 5.23301 0 5.28849V5.61026H0.556777C0.923077 5.61026 1.0989 5.63245 1.21612 5.71012C1.33333 5.78779 1.33333 5.78779 1.36264 6.75312V11.4244C1.36264 12.2233 1.28938 12.4563 0.967033 12.5673C0.776557 12.6338 0.630037 12.6449 0 12.6782V13Z"
      fill="currentColor"
    />
  </SvgIcon>
);
