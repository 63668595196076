import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CustomizationIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M0.75 10.2087H4.60417M15.3958 4.04199H19.25H15.3958ZM0.75 4.04199H12.3125H0.75ZM15.3958 16.3753H19.25H15.3958ZM0.75 16.3753H12.3125H0.75ZM7.6875 10.2087H19.25H7.6875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8542 5.58333C14.7056 5.58333 15.3958 4.89311 15.3958 4.04167C15.3958 3.19023 14.7056 2.5 13.8542 2.5C13.0027 2.5 12.3125 3.19023 12.3125 4.04167C12.3125 4.89311 13.0027 5.58333 13.8542 5.58333Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.14616 11.7503C6.9976 11.7503 7.68782 11.0601 7.68782 10.2087C7.68782 9.35722 6.9976 8.66699 6.14616 8.66699C5.29472 8.66699 4.60449 9.35722 4.60449 10.2087C4.60449 11.0601 5.29472 11.7503 6.14616 11.7503Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8542 17.9163C14.7056 17.9163 15.3958 17.2261 15.3958 16.3747C15.3958 15.5232 14.7056 14.833 13.8542 14.833C13.0027 14.833 12.3125 15.5232 12.3125 16.3747C12.3125 17.2261 13.0027 17.9163 13.8542 17.9163Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
