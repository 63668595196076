import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { BlackHeadings, HoverNonBorderBackground } from 'src/theme/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: HoverNonBorderBackground,
      color: BlackHeadings,
      borderRadius: theme.shape.borderRadius,
      minWidth: 23,
      boxSizing: 'border-box',
      width: 'auto',
      padding: theme.spacing(0, 0.5),
    },
  }),
);

const ShortcutKey: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default ShortcutKey;
