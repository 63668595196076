import { Note, notesApi } from 'src/services/api/notesApi';
import { Events } from '../constants/webSocketConsts/events';
import { Dispatch } from 'redux';
import { ApiTags } from 'src/services/api';

export function isNote(item: any): item is Note {
  return 'content' in item && 'entityId' in item && 'entityType' in item;
}

export function handleInternalNotesUpdate(
  _: Events,
  items: Note[],
  dispatch: Dispatch<any>,
) {
  items.forEach((item) => {
    dispatch(
      notesApi.util.invalidateTags([
        { type: ApiTags.notes, id: item.id },
        { type: ApiTags.notes, id: 'LIST' },
      ]),
    );
  });
}
