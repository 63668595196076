import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DocsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09963 1.39149C2.26933 0.58074 2.9842 0 3.81252 0H11.0002C11.2228 0 11.434 0.09897 11.5765 0.27011C11.719 0.44125 11.7781 0.66686 11.7377 0.88587L9.9877 10.3859C9.9134 10.7895 9.5288 11.0577 9.1255 10.9896C9.0847 10.9964 9.0428 11 9 11H2.25C1.83579 11 1.5 11.3358 1.5 11.75C1.5 12.1642 1.83579 12.5 2.25 12.5H9.595C10.1952 12.5 10.7106 12.0735 10.8229 11.4839L12.5132 2.60968C12.5908 2.20278 12.9834 1.93576 13.3903 2.01326C13.7972 2.09077 14.0643 2.48345 13.9868 2.89035L12.2964 11.7646C12.0494 13.0616 10.9154 14 9.595 14H2.25C1.00736 14 0 12.9927 0 11.75C0 11.6966 0.00185999 11.6437 0.00551999 11.5912C-0.00422501 11.5117 -0.00127701 11.4292 0.01606 11.3464L2.09963 1.39149ZM4.35954 2C4.14433 2 3.95326 2.13772 3.8852 2.34189L3.71853 2.84189C3.61061 3.16565 3.85159 3.5 4.19287 3.5H8.13878C8.354 3.5 8.54507 3.36228 8.61312 3.15811L8.77979 2.65811C8.88771 2.33435 8.64673 2 8.30545 2H4.35954Z"
      fill="#212B36"
    />
  </SvgIcon>
);
