import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CaretRight = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M7.29475 19.643L17.2511 11.1113C17.4115 10.9738 17.5402 10.8032 17.6285 10.6112C17.7168 10.4193 17.7625 10.2105 17.7625 9.9992C17.7625 9.78791 17.7168 9.57913 17.6285 9.38717C17.5402 9.19522 17.4115 9.02462 17.2511 8.8871L7.29475 0.355357C6.3444 -0.458876 4.87646 0.216193 4.87646 1.46745V18.5334C4.87646 19.7846 6.3444 20.4597 7.29475 19.643Z"
      fill="currentColor"
    />
  </SvgIcon>
);
