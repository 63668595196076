import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MessagesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 21 20" {...props}>
    <path
      d="M17.0714 1H3.5C2.83754 1.00188 2.20275 1.26588 1.73431 1.73431C1.26588 2.20275 1.00188 2.83754 1 3.5V12.0714C1.00188 12.7339 1.26588 13.3687 1.73431 13.8371C2.20275 14.3055 2.83754 14.5695 3.5 14.5714H5.28571V18.1429L9.46964 14.6545C9.53389 14.6008 9.61495 14.5714 9.69866 14.5714H17.0714C17.7339 14.5695 18.3687 14.3055 18.8371 13.8371C19.3055 13.3687 19.5695 12.7339 19.5714 12.0714V3.5C19.5695 2.83754 19.3055 2.20275 18.8371 1.73431C18.3687 1.26588 17.7339 1.00188 17.0714 1Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
