import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Heading1Icon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M3.98 8.3175H10.1225V12.2175C10.1225 13.7125 10.025 13.8425 8.855 13.875V14.2812H12.8525V13.875C11.6988 13.8425 11.6012 13.7125 11.6012 12.2175V4.56375C11.6012 3.0525 11.6825 2.955 12.8525 2.90625V2.5H8.855V2.90625C10.0413 2.955 10.1225 3.0525 10.1225 4.56375V7.7975H3.98V4.56375C3.98 3.0525 4.06125 2.955 5.2475 2.90625V2.5H1.25V2.90625C2.42 2.955 2.50125 3.0525 2.50125 4.56375V12.2175C2.50125 13.7288 2.40375 13.8425 1.25 13.875V14.2812H5.2475V13.875C4.0775 13.8425 3.98 13.7125 3.98 12.2175V8.3175Z"
      fill="currentColor"
    />
    <path
      d="M15 17.5H18.75V17.1481C17.967 17.1117 17.9121 17.0995 17.7473 16.9782C17.5549 16.8325 17.5 16.5898 17.5 15.7767V12.1723C17.5 10.7403 17.5412 9.70874 17.6099 8.75C16.9368 8.89563 16.044 9.00485 15 9.06553V9.41747H15.522C15.8654 9.41747 16.0302 9.44175 16.1401 9.5267C16.25 9.61165 16.25 9.61165 16.2775 10.6675V15.7767C16.2775 16.6505 16.2088 16.9053 15.9066 17.0267C15.728 17.0995 15.5907 17.1117 15 17.1481V17.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
