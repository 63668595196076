export enum MessagesActionTypes {
  INTIALIZE_CLIENT_START = 'INTIALIZE_CLIENT_START',
  INITIALIZE_CLIENT_DONE = 'INITIALIZE_CLIENT_DONE',
  SET_CREATED_CHANNEL = 'SET_CREATED_CHANNEL',
  RESET_CLIENT = 'RESET_CLIENT',
  SET_UNREAD_MESSAGES_COUNT = 'SET_UNREAD_MESSAGES_COUNT',
  SET_NO_CHANNEL_LOADED = 'SET_NO_CHANNEL_LOADED',
  SET_LOADING_CHANNEL_ERROR = 'SET_LOADING_CHANNEL_ERROR',
  ADD_DELETED_CHANNEL = 'ADD_DELETED_CHANNEL',
}

export interface MessageChannelEntity {
  fields: {
    name: string;
    members: string[];
    getstreamMembers: string[];
    companyID: string;
  };
}

export interface MessagesState {
  clientInitialized: boolean;
  clientInitializing: boolean;
  createdChannelId?: string; // used to set the active channel after creation using the channel id
  currentUserId?: string;
  totalUnreadMessages?: number;
  noChannelLoaded: boolean;
  loadingError: string;
  deletedChannels?: string[];
}

interface IntializeClientStartAction {
  type: typeof MessagesActionTypes.INTIALIZE_CLIENT_START;
}

interface IntializeClientStartDone {
  type: typeof MessagesActionTypes.INITIALIZE_CLIENT_DONE;
  currentUserId?: string;
}

interface SetCreatedChannelAction {
  type: typeof MessagesActionTypes.SET_CREATED_CHANNEL;
  createdChannelId: string;
}

interface SetNoChannelLoadedAction {
  type: typeof MessagesActionTypes.SET_NO_CHANNEL_LOADED;
  payload: boolean;
}

interface SetChannelLoadingErrorAction {
  type: typeof MessagesActionTypes.SET_LOADING_CHANNEL_ERROR;
  payload: string;
}

interface ResetClientAction {
  type: typeof MessagesActionTypes.RESET_CLIENT;
}

interface SetUnreadMessagesAction {
  type: typeof MessagesActionTypes.SET_UNREAD_MESSAGES_COUNT;
  payload: number;
}

interface AddDeletedChannelAction {
  type: typeof MessagesActionTypes.ADD_DELETED_CHANNEL;
  payload: string;
}

export type MessagesActions =
  | IntializeClientStartAction
  | IntializeClientStartDone
  | SetCreatedChannelAction
  | ResetClientAction
  | SetUnreadMessagesAction
  | SetNoChannelLoadedAction
  | SetChannelLoadingErrorAction
  | AddDeletedChannelAction;
