export type SidebarNavToEntityNameMapKey = keyof typeof SidebarNavToEntityName;
export const SidebarNavToEntityName = {
  'sidebar-crm': 'Client',
  'sidebar-forms': 'Form',
  'sidebar-subscriptions': 'Subscription',
  'sidebar-invoices': 'Invoice',
  'sidebar-knowledgeBase': 'Article',
};

export const ShortcutActionIdsToMenuTitleMap = {
  'open-client': 'Open client',
  'create-entity-command': 'Create',
};

export type ShortcutActionIdsToMenuTitleMapType =
  typeof ShortcutActionIdsToMenuTitleMap;

export enum KeyboardKeys {
  Esc = 'Escape',
}
