import React, { useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { SignaturePageImageData } from 'src/store/signaturePage/types';
import { RegularCardShadow } from 'src/theme/shadows';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';
import { ESIGNATURE_PAGE_MARGIN } from 'src/constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      pointerEvents: 'none',
      marginBottom: (props: { pageMargin: number }) => props.pageMargin,
      borderRadius: 4,
      overflow: 'hidden',
      boxShadow: RegularCardShadow,
      '& >img': {
        width: '100%',
      },
    },
  }),
);

interface SignaturePageImageProps {
  pageImage: SignaturePageImageData;
  handlePageImageLoad: (event: React.SyntheticEvent<HTMLImageElement>) => void;
}

export const SignaturePageImage = React.forwardRef<
  HTMLDivElement,
  SignaturePageImageProps
>((props, ref) => {
  const { pageImage, handlePageImageLoad } = props;

  const { pageScalerFactor } = useContext(ContractBuilderContext);

  const classes = useStyles({
    // the pixel density on mobile devices is higher than on desktop
    // so we need to adjust the page margin accordingly using the page scaler factor
    // This is to ensure the y position of the signature component is consistent across devices
    pageMargin: ESIGNATURE_PAGE_MARGIN / pageScalerFactor,
  });

  return (
    <div className={classes.root} ref={ref}>
      <img
        src={pageImage.url}
        data-testid={`page-image-${pageImage.key}`}
        crossOrigin="anonymous"
        alt={`document_page_${pageImage.pageNumber}`}
        onLoad={handlePageImageLoad}
      />
    </div>
  );
});
