import React from 'react';
import { Action } from 'kbar';
import {
  CLIENT_DETAILS_PAGE,
  FORMS_PAGE,
  SUBSCRIPTION_PAGE,
  CRM_CLIENTS_PAGE,
  INVOICES_PAGE,
  HELPDESK_PAGE,
  CONTRACTS_PAGE,
  PRODUCTS_PAGE,
  COMPANIES_PAGE,
} from 'src/constants/pathConsts';
import { RouteContext } from 'src/context/routeContext';
import {
  SidebarNavToEntityName,
  SidebarNavToEntityNameMapKey,
} from 'src/constants/shortcutConsts';
import { useKeyboardShortcuts } from 'src/hooks/useKeyboardShortcuts';
import { useSidebarItems } from 'src/legacy/components/Sidebar/useSidebarItems';
import history from 'src/history';
import { PortalConfigContext } from 'src/context';
import { useCreateClientPermission } from 'src/features/crm/hooks/useCreateClientPermission';
export const useCreateCommandAction = () => {
  const { pathname } = React.useContext(RouteContext);
  const isClientDetailsPage = pathname.includes(CLIENT_DETAILS_PAGE.path);
  const sidebarItems = useSidebarItems('main').flatMap((x) => x.navItems);
  const isCreateClientDisabled = useCreateClientPermission();
  const portalConfigurations = React.useContext(PortalConfigContext);
  const areCompaniesDisabled = Boolean(
    portalConfigurations.features?.disableCompanies,
  );

  const handleNavigateToCreateEntityPage = (navId: string) => {
    switch (navId) {
      case 'sidebar-crm':
        history.push(`${CRM_CLIENTS_PAGE.path}?openCreateModal=true`);
        break;
      case 'sidebar-forms':
        history.push(`${FORMS_PAGE.path}/edit`);
        break;
      case 'sidebar-invoices':
        history.push(`${INVOICES_PAGE.path}/edit`);
        break;
      case 'sidebar-subscriptions':
        history.push(`${SUBSCRIPTION_PAGE.path}/edit`);
        break;
      case 'sidebar-knowledgeBase':
        history.push(`${HELPDESK_PAGE.path}/article/new`);
        break;
      case 'sidebar-products':
        history.push(`${PRODUCTS_PAGE.path}/edit`);
        break;
      case 'sidebar-contracts':
        history.push(`${CONTRACTS_PAGE.path}/edit`);
        break;
      default:
        break;
    }
  };

  /**
   * Convert each sidebar item to an create entity action.
   */
  const entityActions = React.useMemo(() => {
    const actions: Action[] = sidebarItems
      .filter((navItem) => navItem.canAccess)
      .reduce((currentActions: Action[], navItem) => {
        if (navItem.htmlId === 'sidebar-crm') {
          if (isCreateClientDisabled) {
            return currentActions;
          }
          if (!areCompaniesDisabled) {
            currentActions.push({
              id: 'create-company-action',
              name: 'Create company',
              parent: 'create-entity-command',
              perform: () =>
                history.push(`${COMPANIES_PAGE.path}?createCompany=true`),
            });
          }
        }
        // explicity define actions for payments sidebar nav sub-items
        if (navItem.htmlId === 'sidebar-payments') {
          currentActions.push({
            id: 'create-invoice-action',
            name: 'Create invoice',
            parent: 'create-entity-command',
            perform: () => handleNavigateToCreateEntityPage('sidebar-invoices'),
          });

          currentActions.push({
            id: 'create-product-action',
            name: 'Create product',
            parent: 'create-entity-command',
            perform: () => handleNavigateToCreateEntityPage('sidebar-products'),
          });

          currentActions.push({
            id: 'create-subscription-action',
            name: 'Create subscription',
            parent: 'create-entity-command',
            perform: () =>
              handleNavigateToCreateEntityPage('sidebar-subscriptions'),
          });
        }
        if (navItem.htmlId === 'sidebar-contracts') {
          currentActions.push({
            id: 'create-contract-template-action',
            name: 'Create contract template',
            parent: 'create-entity-command',
            perform: () =>
              handleNavigateToCreateEntityPage('sidebar-contracts'),
          });
        }

        if (
          SidebarNavToEntityName[navItem.htmlId as SidebarNavToEntityNameMapKey]
        ) {
          currentActions.push({
            id: `create-${navItem.htmlId}`,
            name: `Create ${SidebarNavToEntityName[
              navItem.htmlId as SidebarNavToEntityNameMapKey
            ].toLowerCase()}`,
            parent: 'create-entity-command',
            perform: () => {
              handleNavigateToCreateEntityPage(navItem.htmlId);
            },
          });
        }

        return currentActions;
      }, []);

    return actions;
  }, [sidebarItems, isCreateClientDisabled, areCompaniesDisabled]);

  useKeyboardShortcuts([
    {
      id: 'create-entity-command',
      name: 'Create...',
      // in client details page, create command should appear under 'General' section
      // otherwise, it should appear without a section
      section: isClientDetailsPage ? 'General' : undefined,
      shortcut: ['c'],
      keywords: 'create new invoice client',
    },
    ...entityActions,
  ]);
};
