import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core';
import { green, yellow } from 'src/theme/colors';
import { zIndex } from 'src/theme/zindex';
import { DescriptionTooltip } from 'src/legacy/components/Tooltip';

interface BaseStatusIndicatorProps {
  title: React.ElementType | React.ReactFragment | string;
  customSpacings?: CSSProperties;
  isSuccess?: boolean;
  hasTooltip?: boolean;
}

const useStyles = makeStyles(() => ({
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  baseStatusIndicator: {
    position: 'absolute',
    height: 8,
    width: 8,
    borderRadius: '50%',
    zIndex: zIndex.tooltip,
  },
  baseStatusIndicatorSuccess: {
    backgroundColor: green,
  },
  baseStatusIndicatorDefault: {
    backgroundColor: yellow,
  },
}));

export const BaseStatusIndicator = (props: BaseStatusIndicatorProps) => {
  const classes = useStyles();
  const { hasTooltip = true, title, isSuccess = false, customSpacings } = props;

  const getBaseStatusIndicatorClass = () => {
    if (isSuccess) {
      return classes.baseStatusIndicatorSuccess;
    }

    return classes.baseStatusIndicatorDefault;
  };
  return (
    <DescriptionTooltip disableHoverListener={!hasTooltip} title={title}>
      <div className={classes.centeredContainer}>
        <div
          className={`${
            classes.baseStatusIndicator
          } ${getBaseStatusIndicatorClass()}`}
          style={{ ...customSpacings }}
        />
      </div>
    </DescriptionTooltip>
  );
};
