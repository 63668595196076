import classNames from 'classnames';
import { Icon } from 'copilot-design-system';
import React from 'react';

type ErrorMessageProps = {
  message: string;
  hideIcon?: boolean;
  className?: string;
};

export const ErrorMessage = ({
  hideIcon,
  message,
  className,
}: ErrorMessageProps) => {
  return (
    <div
      className={classNames(
        'text-error-primary inline-flex items-start gap-1',
        className,
      )}
    >
      {!hideIcon && <Icon icon="Failed" className="h-3 w-3 mt-1 shrink-0" />}
      {message}
    </div>
  );
};
