import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EmailIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M13.1559 9.91513C12.934 12.43 11.1775 13.86 9.18382 13.86C7.19016 13.86 5.86319 12.0937 6.05501 9.91513C6.24683 7.73655 7.88545 5.97021 9.87912 5.97021C11.8728 5.97021 13.3472 7.74543 13.1559 9.91513V9.91513Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.1438 17.879C11.7355 18.4707 10.8124 18.594 9.2344 18.594C4.44131 18.594 0.900744 14.7082 1.32039 9.91516C1.74003 5.12208 5.97096 1.23633 10.764 1.23633C16.1632 1.23633 19.1312 4.77345 18.7141 9.50736C18.4029 13.0415 16.1444 14.0598 14.9619 13.8502C13.8495 13.653 12.9313 12.646 13.1009 10.7189L13.5191 5.9727"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
