import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const IceCreamIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.75 10L10 18.75L7.57812 13.0859"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6961 8.72969C11.3961 9.36523 10.7492 9.84375 10 9.84375C9.48828 9.84375 8.94531 9.64844 8.68633 9.4625C8.44805 9.2918 8.125 9.375 8.125 9.76562V12.1875C8.12428 12.4359 8.02528 12.674 7.84962 12.8496C7.67396 13.0253 7.43592 13.1243 7.1875 13.125V13.125C6.93908 13.1243 6.70104 13.0253 6.52538 12.8496C6.34972 12.674 6.25072 12.4359 6.25 12.1875V10H6.17188C4.79102 10 3.75 8.88086 3.75 7.5C3.75 6.83696 4.01339 6.20107 4.48223 5.73223C4.95107 5.26339 5.58696 5 6.25 5H6.5625V4.6875C6.5625 3.77582 6.92466 2.90148 7.56932 2.25682C8.21398 1.61216 9.08832 1.25 10 1.25C10.9117 1.25 11.786 1.61216 12.4307 2.25682C13.0753 2.90148 13.4375 3.77582 13.4375 4.6875V5H13.75C14.413 5 15.0489 5.26339 15.5178 5.73223C15.9866 6.20107 16.25 6.83696 16.25 7.5C16.25 8.16304 15.9866 8.79893 15.5178 9.26777C15.0489 9.73661 14.413 10 13.75 10C12.8992 10 12.1094 9.49219 11.6957 8.73008L11.6961 8.72969Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
