import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import * as Weights from 'src/theme/weights';

export const typography9RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '9px',
  lineHeight: '20px',
};

export const typography9MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '9px',
  lineHeight: '20px',
};

export const typography12RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '12px',
  lineHeight: '18px',
};

export const typography12MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '12px',
  lineHeight: '19px',
};

export const typography13MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '13px',
  lineHeight: '24px',
};

export const typography13RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '13px',
  lineHeight: '20px',
};

export const typographybodyMdStyle = {
  fontWeight: Weights.regular,
  fontSize: '14px',
  lineHeight: '22px',
};

export const typography14RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '14px',
  lineHeight: '22px',
};

export const typography15RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '15px',
  lineHeight: '24px',
};

export const typography15MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '15px',
  lineHeight: '24px',
};

export const typeography16RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '16px',
  lineHeight: '24px',
};

export const typeography16MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '16px',
  lineHeight: '24px',
};

export const typography18MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '18px',
  lineHeight: '24px',
};

export const typography18RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '18px',
  lineHeight: '26px',
};

export const typography24MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '24px',
  lineHeight: '30px',
};

export const typography10MediumStyle = {
  fontWeight: Weights.medium,
  fontSize: '10px',
  lineHeight: '20px',
};

export const typography10RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '10px',
  lineHeight: '20px',
};

export const typography65RegularStyle = {
  fontWeight: Weights.regular,
  fontSize: '65px',
  lineHeight: '150px',
};

export const typography15SemiBoldStyle = {
  fontWeight: Weights.semiBold,
  fontSize: '15px',
  lineHeight: '24px',
};
export const typography24SemiBoldStyle = {
  fontWeight: Weights.semiBold,
  fontSize: '24px',
  lineHeight: '30px',
};
export const typography24BoldStyle = {
  fontWeight: Weights.bold,
  fontSize: '24px',
  lineHeight: '29px',
};

export const typography36BoldStyle = {
  fontWeight: Weights.bold,
  fontSize: '36px',
  lineHeight: '44px',
};

export const typographyHeading2XL = {
  fontWeight: Weights.medium,
  fontSize: '24px',
  lineHeight: '32px',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '9Regular': typography9RegularStyle,
    '9Medium': typography9MediumStyle,
    '10Medium': typography10MediumStyle,
    '10Regular': typography10RegularStyle,
    '12Regular': typography12RegularStyle,
    '12Medium': typography12MediumStyle,
    '13Medium': typography13MediumStyle,
    '13Regular': typography13RegularStyle,
    '14Regular': typography14RegularStyle,
    '15Regular': typography15RegularStyle,
    '15Medium': typography15MediumStyle,
    '16Regular': typeography16RegularStyle,
    '16Medium': typeography16MediumStyle,
    '18Medium': typography18MediumStyle,
    '24Medium': typography24MediumStyle,
    '65Regular': typography65RegularStyle,
    '15semiBold': typography15SemiBoldStyle,
    '36Bold': typography36BoldStyle,
    '24semiBold': typography24SemiBoldStyle,
    '24Bold': typography24BoldStyle,
    '18Regular': typography18RegularStyle,
    bodyMd: typographybodyMdStyle,
    Heading2XL: typographyHeading2XL,
    base: {
      fontFamily: theme.typography.fontFamily,
    },
  }),
);
export type FontType =
  | '9Regular'
  | '9Medium'
  | '10Medium'
  | '10Regular'
  | '12Regular'
  | '12Medium'
  | '13Medium'
  | '13Regular'
  | '14Regular'
  | '15Regular'
  | '15Medium'
  | '16Regular'
  | '16Medium'
  | '18Medium'
  | '18Regular'
  | '24Medium'
  | '12Regular'
  | '36Bold'
  | '65Regular'
  | '15semiBold'
  | '24semiBold'
  | '24Bold'
  | 'bodyMd'
  | 'Heading2XL';

export interface BaseTypographyProps {
  fontType?: FontType;
  // adding this component as an optional prop to allow the use for component
  // prop override in <Typography /> component.
  // By default TypographyProps that is exported from @material-ui/core
  // does not have component as a prop. Doing `type TypographyProps = ComponentProps<typeof Typography>`
  // also did not work. Hence, adding this prop as an optional prop.
  // Despite the workarounds not working when you pass component as a prop to Typography
  // it does not throw an error. Hence, this prop should be definable.
  component?: React.ElementType;
  textColor?: string;
  opacity?: string;
}

const BaseTypography: React.FC<BaseTypographyProps & TypographyProps> = ({
  children,
  fontType = '13Regular',
  className,
  component = 'div',
  textColor,
  ...props
}) => {
  const classes = useStyles();
  const classNames = [classes.base, classes[fontType]];
  if (className) {
    classNames.push(className);
  }

  return (
    <Typography
      component={component}
      variant="inherit"
      className={classNames.join(' ')}
      style={{ color: textColor }}
      {...props}
    >
      {children}
    </Typography>
  );
};

const MemoBaseTypography = React.memo(BaseTypography);

export default MemoBaseTypography;
