import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PieChartIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.0021 3.15039C10.2076 3.13346 10.4153 3.125 10.6252 3.125C14.7658 3.125 18.1252 6.48438 18.1252 10.625C18.1252 14.7656 14.7658 18.125 10.6252 18.125C9.03632 18.1249 7.48848 17.6205 6.20466 16.6844C4.92084 15.7483 3.96731 14.4288 3.48145 12.916"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 1.875C5.51251 1.875 1.87501 5.5125 1.87501 10C1.8733 11.1443 2.11414 12.2759 2.58165 13.3203L10 10V1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
