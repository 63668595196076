import React from 'react';
import { LoadingWrapper } from 'src/legacy/components/Loading';
import { StripeConnectForm } from 'src/legacy/components/StripeConnectForm';
import { useAppSelector } from 'src/hooks/useStore';

export const BankingPage: React.FC = () => {
  const isLoading = useAppSelector((state) => state.settings.loading);

  return (
    <LoadingWrapper isLoading={isLoading} hideContentWhileLoading>
      <StripeConnectForm />
    </LoadingWrapper>
  );
};
