import { Theme } from '@material-ui/core';

export default {};

export const overrideFormControlLabel = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiFormControlLabel) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiFormControlLabel.root = {
      marginLeft: -theme.spacing(1),
    };
  }
};
