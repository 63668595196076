import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SadIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.1875 10C7.70527 10 8.125 9.58027 8.125 9.0625C8.125 8.54473 7.70527 8.125 7.1875 8.125C6.66973 8.125 6.25 8.54473 6.25 9.0625C6.25 9.58027 6.66973 10 7.1875 10Z"
      fill="currentColor"
    />
    <path
      d="M9.99981 11.25C11.774 11.25 13.2662 12.4035 13.7385 13.9777C13.7517 14.0247 13.7538 14.0742 13.7445 14.1222C13.7352 14.1701 13.7148 14.2152 13.6849 14.2538C13.655 14.2924 13.6164 14.3235 13.5724 14.3446C13.5283 14.3656 13.4799 14.376 13.4311 14.375H6.56817C6.5195 14.3757 6.47134 14.3651 6.42751 14.3439C6.38369 14.3227 6.34541 14.2916 6.31575 14.253C6.28608 14.2144 6.26583 14.1695 6.25662 14.1217C6.24741 14.0739 6.24949 14.0246 6.2627 13.9777C6.73106 12.4035 8.22364 11.25 9.99981 11.25Z"
      fill="currentColor"
    />
    <path
      d="M12.8125 10C13.3303 10 13.75 9.58027 13.75 9.0625C13.75 8.54473 13.3303 8.125 12.8125 8.125C12.2947 8.125 11.875 8.54473 11.875 9.0625C11.875 9.58027 12.2947 10 12.8125 10Z"
      fill="currentColor"
    />
    <path
      d="M10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
