import React, { useState } from 'react';
import { Column } from '@ag-grid-community/core';
import {
  Box,
  Tooltip,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ArrowDownIcon, QuestionIcon } from 'src/legacy/components/Icons';
import * as Colors from 'src/theme/colors';
import Typography from 'src/legacy/components/Text/BaseTypography';

export interface HeaderCompParamsModel {
  column: Column;
  displayName: string;
  enableSorting: boolean;
  enableMenu: boolean;
  setSort(sort: string, multiSort?: boolean): void;
  showColumnMenu(menuButton: HTMLElement): void;
}

interface HeaderRenderProps extends HeaderCompParamsModel {
  tooltipDescription?: (props: { isClient?: boolean }) => React.ReactNode;
  menuIcon: string;
  tooltipBgColor?: string;
  isClient?: boolean;
}

interface SortStatusModel {
  ascSort: string;
  descSort: string;
  noSort: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      maxWidth: 'none',
      borderRadius: 8,
      backgroundColor: (props: Pick<HeaderRenderProps, 'tooltipBgColor'>) =>
        props.tooltipBgColor,
    },
    tooltipIcon: {
      fontSize: 13,
      color: Colors.GraySmall,
      '& path': {
        strokeWidth: 1,
      },
    },
    sortingIcon: {
      color: Colors.BlackHeadings,
      fontSize: 11,
    },
    reversedIcon: {
      transform: 'rotate(180deg)',
    },
    headerText: {
      marginRight: theme.spacing(0.5),
    },
  }),
);

const HeaderRender: React.FC<HeaderRenderProps> = (props) => {
  const {
    column,
    displayName,
    enableSorting,
    enableMenu,
    setSort,
    showColumnMenu,
    tooltipDescription,
    tooltipBgColor,
    menuIcon,
    isClient,
  } = props;

  const initialSortStatus = {
    ascSort: 'inactive',
    descSort: 'inactive',
    noSort: 'inactive',
  };

  const classes = useStyles({ tooltipBgColor });

  const [sortStatus, setSortStatus] =
    useState<SortStatusModel>(initialSortStatus);

  const [menuRef, setMenuRef] = useState<null | HTMLElement>();

  const onSortChanged = () => {
    setSortStatus({
      ascSort: column.isSortAscending() ? 'active' : 'inactive',
      descSort: column.isSortDescending() ? 'active' : 'inactive',
      noSort:
        !column.isSortAscending() && !column.isSortDescending()
          ? 'active'
          : 'inactive',
    });
  };

  React.useEffect(() => {
    column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
  }, []);

  const onMenuClicked = () => {
    if (menuRef) {
      showColumnMenu(menuRef);
    }
  };

  const menuContent = (
    <div
      ref={(menuButton) => {
        setMenuRef(menuButton);
      }}
      className="customHeaderMenuButton"
      onClick={onMenuClicked}
      onKeyDown={onMenuClicked}
      role="button"
      tabIndex={0}
    >
      <i className={`fa ${menuIcon}`} />
    </div>
  );

  const sortContent = (
    <Box mr={0.5}>
      {sortStatus.ascSort === 'active' && (
        <ArrowDownIcon
          className={`${classes.sortingIcon} ${classes.reversedIcon}`}
        />
      )}
      {sortStatus.descSort === 'active' && (
        <ArrowDownIcon className={classes.sortingIcon} />
      )}
    </Box>
  );

  const handleSort = (event: React.MouseEvent<HTMLElement>) => {
    if (column.isSortAscending()) {
      setSort('desc', event.shiftKey);
      return;
    }
    if (column.isSortDescending()) {
      setSort('', event.shiftKey);
      return;
    }
    if (!column.isSortDescending() && !column.isSortAscending()) {
      setSort('asc', event.shiftKey);
    }
  };

  const tooltipContent = tooltipDescription && tooltipDescription({ isClient });

  return (
    <Box onClick={handleSort} display="flex" alignItems="center">
      {enableMenu && menuContent}
      <Typography className={classes.headerText}>{displayName}</Typography>
      {enableSorting && sortContent}
      {tooltipContent ? (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipContent}>
          <Box display="flex">
            <QuestionIcon className={classes.tooltipIcon} />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default HeaderRender;
