import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CheckboxCheckedIcon = (props?: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <rect width="16" height="16" rx="4" />
    <path
      d="M6.40228 9.96565L4.02752 7.5968L3.00781 8.61397L6.40228 12L13.4025 5.01717L12.3828 4L6.40228 9.96565Z"
      fill="white"
    />
  </SvgIcon>
);
