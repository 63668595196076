import { Events } from '../constants/webSocketConsts/events';
import { Dispatch } from 'redux';
import { Contract } from '../entities/Contract';
import { ensureUnreachable } from '../utils/common_utils';
import { contractsApi } from '../services/api';

const updateCache = (event: Events, items: Contract[], draft?: Contract[]) => {
  console.info('updateCache', { draft, event, items });
  const [contract] = items;

  if (!draft) {
    return;
  }

  const upcomingContractIndex = draft.findIndex(
    (item: Contract) => item.ref === contract.ref,
  );

  switch (event) {
    case Events.Insert: {
      // if the contract already exists, we don't need to add that again
      if (upcomingContractIndex !== -1) {
        return;
      }

      draft.unshift(contract);
      break;
    }
    case Events.Modify:
      if (upcomingContractIndex === -1) {
        return;
      }
      draft.splice(
        0,
        draft.length,
        ...draft.map((item: Contract) =>
          item.ref === contract.ref ? contract : item,
        ),
      );
      break;
    case Events.Remove:
      if (upcomingContractIndex === -1) {
        return;
      }
      draft.splice(upcomingContractIndex, 1);
      break;
    case Events.Refresh:
    case Events.DomainVerified:
      return;
    default:
      ensureUnreachable(event);
  }
};

export function handleContractUpdate(
  event: Events,
  items: Contract[],
  dispatch: Dispatch<any>,
) {
  // for contracts showing to client
  const contractItem = items.at(0);
  if (!contractItem) {
    console.warn('contractItem is undefined');
    return;
  }
  // for contracts showing to client
  dispatch(
    contractsApi.util.updateQueryData(
      'getContracts',
      { recipientId: contractItem.recipientId },
      (draft?: Contract[]) => {
        updateCache(event, items, draft);
      },
    ),
  );

  // for contracts showing in contract template detail page
  dispatch(
    contractsApi.util.updateQueryData(
      'getContracts',
      { contractTemplateId: contractItem.contractTemplateId },
      (draft?: Contract[]) => {
        updateCache(event, items, draft);
      },
    ),
  );

  // for contracts showing on submissions/requests page
  dispatch(
    contractsApi.util.updateQueryData(
      'getContracts',
      {},
      (draft?: Contract[]) => {
        updateCache(event, items, draft);
      },
    ),
  );
}
