import App from 'src/legacy/components/App/App';
import UnauthApp from 'src/legacy/components/App/UnauthApp';

declare global {
  interface Window {
    App: any;
    createAccountData_email: string;
  }
}

export default App;

export { UnauthApp };
