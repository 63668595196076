import { useCallback, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { isSupportedCountry } from 'src/legacy/components/Address/utils/countryHelpers';
import { AddressCustomFieldValues } from 'src/store/clients/types';

const commonAddressFieldsSchema = Yup.object().shape({
  country: Yup.string().required('Country is required'),
  addressLine2: Yup.string(),
});

const useAddressValidation = (initialAddressLine1: string) => {
  const initialAddressLine1Ref = useRef(initialAddressLine1);

  /**
   * Used to determine if only the fullAddress input field should be shown.
   * Happens when the selected country isn't supported for google maps autocomplete
   * or a fullAddress exists without addressLine1 (possible through csv import)
   */
  const isOnlyFullAddress = useCallback(
    ({
      country = '',
      fullAddress = '',
    }: {
      country?: string;
      fullAddress?: string;
    }) =>
      !isSupportedCountry(country) ||
      (!initialAddressLine1Ref.current && fullAddress !== ''),
    [],
  );

  const addressFieldSchema = useMemo(
    () =>
      Yup.lazy<AddressCustomFieldValues>(({ country, fullAddress }) => {
        if (
          isOnlyFullAddress({
            country,
            fullAddress,
          })
        ) {
          return Yup.object()
            .shape({
              region: Yup.string(),
              postalCode: Yup.string(),
              addressLine1: Yup.string(),
              city: Yup.string(),
              fullAddress: Yup.string().required('Address is required'),
            })
            .concat(commonAddressFieldsSchema);
        }
        return Yup.object()
          .shape({
            region: Yup.string().when('country', {
              is: 'GB',
              then: Yup.string(),
              otherwise: Yup.string().required('State is required'),
            }),
            postalCode: Yup.string().when('country', {
              is: 'US',
              then: Yup.string().required('Zip code is required'),
              otherwise: Yup.string().required('Postal code is required'),
            }),
            addressLine1: Yup.string().required('Address line 1 is required'),
            city: Yup.string().required('City is required'),
            fullAddress: Yup.string(),
          })
          .concat(commonAddressFieldsSchema);
      }),
    [],
  );

  return { isOnlyFullAddress, addressFieldSchema } as const;
};

export default useAddressValidation;
