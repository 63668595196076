import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrendingDownIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.75 14.375H18.125V10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 5.625L6.61602 10.366C6.73209 10.4821 6.8699 10.5742 7.02158 10.6371C7.17326 10.6999 7.33582 10.7322 7.5 10.7322C7.66418 10.7322 7.82674 10.6999 7.97842 10.6371C8.1301 10.5742 8.26791 10.4821 8.38398 10.366L10.366 8.38398C10.4821 8.26788 10.6199 8.17578 10.7716 8.11294C10.9233 8.05011 11.0858 8.01777 11.25 8.01777C11.4142 8.01777 11.5767 8.05011 11.7284 8.11294C11.8801 8.17578 12.0179 8.26788 12.134 8.38398L17.5 13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
