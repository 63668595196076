import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LocateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 3.75V2.1875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 17.8125V16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.375C8.88748 4.375 7.79995 4.7049 6.87492 5.32298C5.94989 5.94107 5.22892 6.81957 4.80318 7.84741C4.37744 8.87524 4.26604 10.0062 4.48309 11.0974C4.70013 12.1885 5.23586 13.1908 6.02253 13.9775C6.8092 14.7641 7.81148 15.2999 8.90262 15.5169C9.99376 15.734 11.1248 15.6226 12.1526 15.1968C13.1804 14.7711 14.0589 14.0501 14.677 13.1251C15.2951 12.2001 15.625 11.1125 15.625 10C15.625 8.50816 15.0324 7.07742 13.9775 6.02252C12.9226 4.96763 11.4918 4.375 10 4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M16.25 10H17.8125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.1875 10H3.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
