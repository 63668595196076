import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const StarHalfIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M18.75 8.125H12.0312L10 1.875L7.96875 8.125H1.25L6.71875 11.875L4.60938 18.125L10 14.2188L15.3906 18.125L13.2812 11.875L18.75 8.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.875V14.2188L4.60938 18.125L6.71875 11.875L1.25 8.125H7.96875L10 1.875Z"
      fill="currentColor"
    />
  </SvgIcon>
);
