import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrashBinIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 5.625L15.7551 16.3961C15.7193 16.7002 15.5731 16.9806 15.3443 17.1841C15.1155 17.3876 14.8199 17.5 14.5137 17.5H5.48672C5.1805 17.5 4.88494 17.3876 4.65611 17.1841C4.42728 16.9806 4.2811 16.7002 4.24531 16.3961L3.125 5.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.125 2.5H1.875C1.52982 2.5 1.25 2.77982 1.25 3.125V5C1.25 5.34518 1.52982 5.625 1.875 5.625H18.125C18.4702 5.625 18.75 5.34518 18.75 5V3.125C18.75 2.77982 18.4702 2.5 18.125 2.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1875 9.375L7.8125 13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1875 13.75L7.8125 9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
