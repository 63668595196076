import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NumberIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M4.13835 16.7758L8.02723 1.22021M9.97168 16.7758L13.8606 1.22021M3.16612 6.08133H16.7772M1.22168 11.9147H14.8328"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
