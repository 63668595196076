import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ScaleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 1.875H5.625C3.55393 1.875 1.875 3.55393 1.875 5.625V14.375C1.875 16.4461 3.55393 18.125 5.625 18.125H14.375C16.4461 18.125 18.125 16.4461 18.125 14.375V5.625C18.125 3.55393 16.4461 1.875 14.375 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M15.1933 5.92041C14.2379 5.0501 12.509 3.9126 10.0004 3.9126C7.49178 3.9126 5.76287 5.0501 4.8074 5.92041C4.54783 6.15686 4.39027 6.4849 4.36797 6.83531C4.34567 7.18572 4.46037 7.53109 4.68787 7.79853L5.98943 9.33017C6.14766 9.51553 6.36711 9.638 6.60794 9.67535C6.84877 9.71269 7.095 9.66243 7.30193 9.53369C7.92381 9.14971 8.89998 8.68838 10.0011 8.68838C11.1023 8.68838 12.0785 9.14971 12.7004 9.53369C12.9073 9.66243 13.1535 9.71269 13.3944 9.67535C13.6352 9.638 13.8546 9.51553 14.0129 9.33017L15.3129 7.79853C15.5404 7.53109 15.6551 7.18572 15.6328 6.83531C15.6105 6.4849 15.4529 6.15686 15.1933 5.92041V5.92041Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
