import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ExportIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.9265 16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9283 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561V9.54945M10.0002 1.34184V6.53665C10.0002 6.90405 10.1462 7.2564 10.406 7.51619C10.6658 7.77598 11.0181 7.92193 11.3855 7.92193H16.5803M14.704 11.1652L17.0387 13.4999M17.0387 13.4999L14.704 15.8346M17.0387 13.4999H9.50022"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
