import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const UpdatesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M10.3923 11.8411V1.42614C10.3918 1.23352 10.315 1.04895 10.1788 0.912748C10.0426 0.776544 9.85802 0.699778 9.6654 0.699219H1.42694C1.23432 0.699778 1.04974 0.776544 0.913541 0.912748C0.777338 1.04895 0.700572 1.23352 0.700012 1.42614V12.0877C0.700971 12.4087 0.828923 12.7163 1.05592 12.9433C1.28292 13.1703 1.59053 13.2983 1.91155 13.2992H11.8462"
      stroke="#212B36"
      strokeWidth="1.25"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.846 13.298C11.4604 13.298 11.0906 13.1448 10.818 12.8722C10.5453 12.5995 10.3922 12.2298 10.3922 11.8442V3.12109H12.5729C12.7657 3.12109 12.9506 3.19768 13.0869 3.334C13.2233 3.47033 13.2998 3.65522 13.2998 3.84802V11.8442C13.2998 12.2298 13.1467 12.5995 12.874 12.8722C12.6014 13.1448 12.2316 13.298 11.846 13.298Z"
      stroke="#212B36"
      strokeWidth="1.25"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.51523 3.12109H8.45369H6.51523ZM6.51523 5.05956H8.45369H6.51523ZM2.63831 6.99802H8.45369H2.63831ZM2.63831 8.93648H8.45369H2.63831ZM2.63831 10.8749H8.45369H2.63831Z"
      fill="white"
    />
    <path
      d="M2.63831 10.8749H8.45369M6.51523 3.12109H8.45369H6.51523ZM6.51523 5.05956H8.45369H6.51523ZM2.63831 6.99802H8.45369H2.63831ZM2.63831 8.93648H8.45369H2.63831Z"
      stroke="#212B36"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.57695 5.54441H2.63848C2.50996 5.54441 2.38669 5.49335 2.29581 5.40247C2.20493 5.31159 2.15387 5.18832 2.15387 5.0598V3.12133C2.15387 2.99281 2.20493 2.86954 2.29581 2.77866C2.38669 2.68778 2.50996 2.63672 2.63848 2.63672H4.57695C4.70547 2.63672 4.82874 2.68778 4.91962 2.77866C5.0105 2.86954 5.06156 2.99281 5.06156 3.12133V5.0598C5.06156 5.18832 5.0105 5.31159 4.91962 5.40247C4.82874 5.49335 4.70547 5.54441 4.57695 5.54441Z"
      fill="#212B36"
    />
  </SvgIcon>
);
