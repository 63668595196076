import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BankCardIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M18.3333 2H1.66667C0.746528 2 0 2.76786 0 3.71429V16.2857C0 17.2321 0.746528 18 1.66667 18H18.3333C19.2535 18 20 17.2321 20 16.2857V3.71429C20 2.76786 19.2535 2 18.3333 2Z"
      fill="#6B6F76"
    />
    <g clip-path="url(#clip0_52552_77655)">
      <path
        d="M10.4201 4.06836C10.2743 3.97721 10.0961 3.97721 9.95023 4.06836L5.35648 6.91895C5.13571 7.05566 5 7.31543 5 7.59798C5 8.02865 5.30787 8.375 5.69068 8.375H14.6817C15.0625 8.375 15.3724 8.02865 15.3724 7.59798C15.3724 7.31543 15.2367 7.05566 15.0159 6.91895L10.4201 4.06836ZM9.62413 7.28125H6.78646L10.1852 5.17122L13.5839 7.28125H10.7462C10.8009 7.17415 10.8333 7.04883 10.8333 6.91667C10.8333 6.51335 10.5437 6.1875 10.1852 6.1875C9.82668 6.1875 9.53704 6.51335 9.53704 6.91667C9.53704 7.04883 9.56945 7.17415 9.62413 7.28125ZM6.2963 9.10417V12.75C6.02691 12.75 5.81019 12.9938 5.81019 13.2969C5.81019 13.5999 6.02691 13.8438 6.2963 13.8438H14.2361C14.5055 13.8438 14.7222 13.5999 14.7222 13.2969C14.7222 12.9938 14.5055 12.75 14.2361 12.75H14.0741V9.10417H13.1019V12.75H11.8056V9.10417H10.8333V12.75H9.53704V9.10417H8.56482V12.75H7.26852V9.10417H6.2963ZM5.64815 14.5729C5.37876 14.5729 5.16204 14.8167 5.16204 15.1198C5.16204 15.4229 5.37876 15.6667 5.64815 15.6667H14.8843C15.1536 15.6667 15.3704 15.4229 15.3704 15.1198C15.3704 14.8167 15.1536 14.5729 14.8843 14.5729H5.64815Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_52552_77655">
        <rect
          width="10.3704"
          height="11.6667"
          fill="currentColor"
          transform="translate(5 4)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
