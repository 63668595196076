import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MovIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.2002 12.0497V15.6861H5.05602V13.44H5.0862L5.95978 15.663H6.50666L7.38024 13.4524H7.41042V15.6861H8.26625V12.0497H7.17782L6.25453 14.3011H6.21191L5.28862 12.0497H4.2002Z"
      fill="black"
    />
    <path
      d="M12.2466 13.8679C12.2466 12.6658 11.4867 12 10.5101 12C9.53001 12 8.77539 12.6658 8.77539 13.8679C8.77539 15.0646 9.53001 15.7358 10.5101 15.7358C11.4867 15.7358 12.2466 15.07 12.2466 13.8679ZM11.3482 13.8679C11.3482 14.5799 11.0357 14.9652 10.5101 14.9652C9.98633 14.9652 9.67205 14.5799 9.67205 13.8679C9.67205 13.1559 9.98633 12.7706 10.5101 12.7706C11.0357 12.7706 11.3482 13.1559 11.3482 13.8679Z"
      fill="black"
    />
    <path
      d="M13.3706 12.0497H12.3869L13.6138 15.6861H14.7839L16.0108 12.0497H15.0272L14.214 14.7131H14.1838L13.3706 12.0497Z"
      fill="black"
    />
  </SvgIcon>
);
