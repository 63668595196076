import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ChatbubbleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.41762 14.8438C3.4641 14.6727 3.36137 14.4348 3.26332 14.2633C3.23279 14.2121 3.19967 14.1626 3.1641 14.1148C2.32319 12.8396 1.87498 11.3455 1.87504 9.81797C1.86137 5.4332 5.4973 1.875 9.9934 1.875C13.9145 1.875 17.1875 4.59141 17.9524 8.19727C18.067 8.73187 18.1248 9.27708 18.125 9.82383C18.125 14.2148 14.6293 17.8289 10.1332 17.8289C9.4184 17.8289 8.45355 17.6492 7.92738 17.502C7.40121 17.3547 6.87582 17.1594 6.74027 17.107C6.60164 17.0537 6.45442 17.0263 6.3059 17.0262C6.14368 17.0256 5.98302 17.0578 5.83363 17.1211L3.18402 18.0773C3.12598 18.1024 3.06447 18.1184 3.0016 18.125C2.95199 18.1249 2.9029 18.1149 2.85718 18.0956C2.81146 18.0763 2.77001 18.0482 2.73524 18.0128C2.70047 17.9774 2.67307 17.9355 2.65462 17.8894C2.63618 17.8434 2.62705 17.7941 2.62777 17.7445C2.63103 17.701 2.63889 17.6579 2.65121 17.616L3.41762 14.8438Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
