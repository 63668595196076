import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ItalicIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_21016:86875)">
      <path
        d="M13.9995 0H10.9995C10.4476 0 9.99957 0.44696 9.99957 0.999939C9.99957 1.55292 10.4476 2.00006 10.9995 2.00006H11.1846L6.73956 18H5.99963C5.44757 18 4.99951 18.447 4.99951 18.9999C4.99951 19.5529 5.44757 20.0001 5.99963 20.0001H8.99963C9.55151 20.0001 9.99957 19.5529 9.99957 18.9999C9.99957 18.447 9.55151 18 8.99963 18H8.81451L13.2596 2.00006H13.9995C14.5516 2.00006 14.9996 1.55292 14.9996 0.999939C14.9996 0.44696 14.5516 0 13.9995 0V0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_21016:86875">
        <rect width="20" height="20" fill="none" />
      </clipPath>
    </defs>
  </SvgIcon>
);
