export interface UniqueIdMapping {
  id: string;
  index: number;
  // only used for testing
  value?: string;
}

export function ensureUnreachable(value: never, message?: string): never {
  throw new Error(
    message || `Unreachable code hit with value ${String(value)}`,
  );
}

// use this function to determine whether or not a call to localStorage will succeed
export function storageAvailable() {
  let storage;
  try {
    storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

// Clamps number within the lower bound min and the upper bound max
export function clamp(number: number, min: number, max: number) {
  return Math.max(min, Math.min(number, max));
}

export function generateUniqueId() {
  return Math.random().toString(36).substring(2, 10);
}

export function createUniqueIdMapping(
  strings: string[],
): Map<string, UniqueIdMapping> {
  const stringToUniqueId = new Map<string, UniqueIdMapping>();
  strings.forEach((_, index) => {
    stringToUniqueId.set(`${index}`, { id: generateUniqueId(), index: index });
  });
  return stringToUniqueId;
}

export function moveElementAndUpdateIds(
  stringToUniqueId: Map<string, UniqueIdMapping>,
  fromIndex: number,
  toIndex: number,
) {
  const newMap = new Map<string, UniqueIdMapping>();
  // Retrieve the moving element's unique ID
  const movingElementKey = `${fromIndex}`;
  const movingElement = stringToUniqueId.get(movingElementKey);
  if (!movingElement) {
    console.error('Element to move not found.');
    return newMap;
  }

  // Adjust the indices for elements that are shifted
  const increment = fromIndex < toIndex ? -1 : 1;
  const start = fromIndex < toIndex ? fromIndex + 1 : toIndex;
  const end = fromIndex < toIndex ? toIndex + 1 : fromIndex;
  for (let i = start; i < end; i++) {
    const key = `${i}`;
    if (stringToUniqueId.has(key)) {
      const element = stringToUniqueId.get(key);
      if (element) {
        newMap.set(`${i + increment}`, {
          id: element.id,
          index: i + increment,
        });
      }
    }
  }

  newMap.set(`${toIndex}`, {
    id: movingElement.id,
    index: toIndex,
  });

  if (newMap.size !== stringToUniqueId.size) {
    for (const key of stringToUniqueId.keys()) {
      if (!newMap.has(key)) {
        const element = stringToUniqueId.get(key);
        if (element) {
          newMap.set(key, element);
        }
      }
    }
  }
  return newMap;
}

export function removeElementAndUpdateIds(
  stringToUniqueId: Map<string, UniqueIdMapping>,
  index: number,
): void {
  // Remove the element from the array and update the map
  const removedElementKey = `${index}`;
  const removedElement = stringToUniqueId.get(removedElementKey);
  if (!removedElement) {
    console.error('Element to remove not found.');
    return;
  }
  stringToUniqueId.delete(removedElementKey);

  // Update the indices for subsequent elements in the map
  for (let i = index; i < stringToUniqueId.size; i++) {
    const key = `${i + 1}`;
    if (stringToUniqueId.has(key)) {
      const element = stringToUniqueId.get(key);
      if (element) {
        stringToUniqueId.delete(key);
        stringToUniqueId.set(`${i}`, {
          id: element.id,
          index: i,
        });
      }
    }
  }
}
