import React, { useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { HTMLInputEvent } from 'src/constants/dataTypes';
import { FileUploadResponse } from 'src/store/files/types';
import S3Utils from 'src/utils/S3Utils';
import { UploadButton } from 'src/legacy/components/Buttons/UploadButton';
import FileUtils from 'src/utils/FileUtils';
import { useAppDispatch } from 'src/hooks/useStore';

interface FileUploaderProps {
  prePath: string;
  fileKey: string;
  onChangeFileKey: (key: string) => void;
  label?: string;
  startIcon?: React.ReactElement;
  useUploadPortalFile?: boolean;
  setS3FilePath?: (path: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    keyContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    noPadding: {
      padding: 0,
    },
    removeIcon: {
      flexGrow: 1,
    },
  }),
);

const FileUploader = (props: FileUploaderProps) => {
  const classes = useStyles();
  const {
    prePath,
    fileKey,
    onChangeFileKey,
    label,
    startIcon,
    useUploadPortalFile = false,
    setS3FilePath,
  } = props;
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState(false);

  const onchange = async (e: HTMLInputEvent) => {
    const { files } = e.target;

    if (files && files.length) {
      const isoDate = new Date().toISOString();

      const path = `${prePath}/${isoDate}/${files.item(0)?.name}`;
      setIsUploading(true);
      const file = files.item(0);
      if (!file) {
        setIsUploading(false);
        return;
      }
      try {
        if (useUploadPortalFile) {
          const result = await S3Utils.uploadPortalFile(file, path);
          if (setS3FilePath) {
            setS3FilePath(result.Location);
          }
          onChangeFileKey(path);
        } else {
          const result: FileUploadResponse = await S3Utils.uploadFile(
            path,
            file,
            { level: 'protected' },
            dispatch,
          );
          onChangeFileKey(result.key);
        }
      } catch {
        onChangeFileKey('');
      }
      setIsUploading(false);
    }
  };

  const handleRemoveFileKey = () => {
    onChangeFileKey('');
    if (useUploadPortalFile && setS3FilePath) {
      setS3FilePath('');
    }
  };

  if (!fileKey)
    return (
      <UploadButton
        handleChange={onchange}
        isUploading={isUploading}
        label={label || 'Select'}
        htmlId="upload-btn"
        color="secondary"
        variant="contained"
        hasStartIcon={false}
        startIcon={startIcon}
      />
    );

  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.keyContainer} mr={1}>
        <Typography>{FileUtils.getFileNameFromPath(fileKey)}</Typography>
      </Box>

      <Box className={classes.removeIcon}>
        <IconButton
          className={classes.noPadding}
          size="small"
          onClick={handleRemoveFileKey}
        >
          <SvgIcon>
            <Clear />
          </SvgIcon>
        </IconButton>
      </Box>
    </Box>
  );
};

export default FileUploader;
