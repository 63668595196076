import {
  LOAD_TAGS_REQUEST,
  LOAD_TAGS_DONE,
  LOAD_TAGS_FAIL,
  CREATE_TAGS_REQUEST,
  CREATE_TAGS_DONE,
  CREATE_TAGS_FAIL,
  Tag,
  UPDATE_TAGS_REQUEST,
  UPDATE_TAGS_DONE,
  UPDATE_TAGS_FAIL,
  DELETE_TAGS_REQUEST,
  DELETE_TAGS_DONE,
  DELETE_TAGS_FAIL,
} from 'src/store/tags/types';
import { AppThunkAction } from 'src/store/reduxTypes';

import TagsClient from 'src/clients/TagsClient';
import { updateKnowledgeBaseSettingsWithNewTags } from 'src/store/knowledgeBase/actions';
import { ensureError } from 'src/utils/Errors';
import { CallApiWithNotification } from 'src/clients/ApiService';

interface TagsApiError {
  status: boolean;
  errorMessage: string;
  data: Error;
}

/**
 * retrieve unfiltered list of tags in the portal
 */
export const listTags = (): AppThunkAction => async (dispatch) => {
  function request() {
    return { type: LOAD_TAGS_REQUEST };
  }

  function success(result: { tags: Tag[] }) {
    return { type: LOAD_TAGS_DONE, payload: result.tags || [] };
  }

  function failure(error: string) {
    return { type: LOAD_TAGS_FAIL, error };
  }

  dispatch(request());

  try {
    const result = await TagsClient.getTags();
    dispatch(success(result));
  } catch (e) {
    const { message } = ensureError(e);
    dispatch(failure(message));
  }
};

/**
 * Create new tag which is immediately returned by the API in entity format
 * @param newTagStruct the struct fields of a new tag
 * @param selectNewTag boolean to auto-select the newly created tag
 */
export const createTag =
  (newTagStruct: Partial<Tag>): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return {
        type: CREATE_TAGS_REQUEST,
        payload: { tags: [newTagStruct], isOptimistic: true },
      };
    }

    function success(result: Tag) {
      return { type: CREATE_TAGS_DONE, payload: [result] };
    }

    function failure(error: string) {
      return { type: CREATE_TAGS_FAIL, error };
    }

    dispatch(request());
    if (newTagStruct.id) {
      dispatch(updateKnowledgeBaseSettingsWithNewTags(newTagStruct.id));
    }

    try {
      const result = await TagsClient.createTag(newTagStruct);
      dispatch(success(result));
    } catch (e) {
      const { message } = ensureError(e);
      dispatch(failure(message));
    }
  };

/**
 * Add or replace tags based on ID into state directly without calling API
 */
export const upsertTagsToState = (tags: Tag[]) => ({
  type: CREATE_TAGS_DONE,
  payload: tags,
});

/**
 * Remove a list of tags based on ID in state directly without calling API
 */
export const removeTagsFromState = (tags: Tag[]) => ({
  type: DELETE_TAGS_DONE,
  payload: tags,
});

/**
 * Update existing tag
 * @param updatedTag full entity of the updated tag to be persisted
 */
export const updateTag =
  (updatedTag: Tag): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return {
        type: UPDATE_TAGS_REQUEST,
        payload: { tags: [updatedTag], isOptimistic: true },
      };
    }

    function success(result: Tag) {
      return { type: UPDATE_TAGS_DONE, payload: [result] };
    }

    function failure(error: string) {
      return { type: UPDATE_TAGS_FAIL, error };
    }

    dispatch(request());

    try {
      const result = await CallApiWithNotification<Tag>({
        executeFunction: TagsClient.updateTag,
        params: updatedTag,
        successMessage: 'Tag updated.',
        errorMessage: 'Unable to update tag.',
        dispatch,
      });
      dispatch(success(result.data));
    } catch (e) {
      // TODO(@foley): How pervasive is this errorMessage format?
      const error = e as TagsApiError;
      dispatch(failure(error.errorMessage));
    }
  };

/**
 * Remove existing tag
 * @param deletedTag full entity of the deleted tag
 */
export const deleteTag =
  (deletedTag: Tag): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return {
        type: DELETE_TAGS_REQUEST,
        payload: { tags: [deletedTag], isOptimistic: true },
      };
    }

    function success(result: Tag) {
      return { type: DELETE_TAGS_DONE, payload: [result] };
    }

    function failure(error: string) {
      return { type: DELETE_TAGS_FAIL, error };
    }

    dispatch(request());

    try {
      const result = await CallApiWithNotification<Tag>({
        executeFunction: TagsClient.deleteTag,
        params: deletedTag.id,
        successMessage: 'Tag deleted.',
        errorMessage: 'Unable to delete tag.',
        dispatch,
      });
      dispatch(success(result.data));
    } catch (e) {
      // TODO(@foley): How pervasive is this errorMessage format?
      const error = e as TagsApiError;
      dispatch(failure(error.errorMessage as string));
    }
  };
