import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BulbIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M11.875 15V14.0625C11.875 12.9297 13.1071 11.8582 13.9063 11.0938C15.0328 10.0168 15.625 8.56994 15.625 6.87502C15.625 3.75002 13.1356 1.25002 10 1.25002C9.26076 1.24796 8.52838 1.39205 7.84499 1.674C7.1616 1.95595 6.54069 2.37021 6.01795 2.89295C5.49521 3.41569 5.08095 4.0366 4.799 4.71999C4.51705 5.40338 4.37296 6.13576 4.37502 6.87502C4.37502 8.5094 4.9926 10.0543 6.09377 11.0938C6.88869 11.8442 8.12502 12.918 8.12502 14.0625V15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 18.75H11.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 16.875H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15V10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4844 9.375C11.4844 9.375 10.6441 10 10 10C9.35586 10 8.51562 9.375 8.51562 9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
