import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import {
  CardContent,
  CardHeader,
  FormControl,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormikHelpers } from 'formik';
import { shallowEqual, useSelector } from 'react-redux';

import {
  CONTRACT_SIDEBAR_WIDTH,
  ContractBuilderSidebar,
} from 'src/legacy/components/Contracts/ContractBuilderSidebar';
import {
  signaturePageContentWidth,
  SignaturePageContent,
} from 'src/legacy/components/Signature/SignaturePageContent';
import { GraySmall, NonHoverBorder } from 'src/theme/colors';
import { BoxDragPreview } from 'src/legacy/components/Signature/BoxDragPreview';
import MemoBaseTypography from 'src/legacy/components/Text/BaseTypography';
import {
  BANNER_HEIGHT,
  DESKTOP_APP_BAR_HEIGHT,
  FormBodyAPI,
  MOBILE_APP_BAR_HEIGHT,
} from 'src/constants';
import { ContractFileDropzone } from 'src/legacy/components/Contracts/ContractFileDropzone';
import { BaseTextField } from 'src/legacy/components/TextField';
import {
  ContractDetailsPageQueryParams,
  ContractTemplateForm,
} from 'src/legacy/components/Contracts';
import { RegularCardBox } from 'src/legacy/components/Cards/RegularCardBox';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';
import { RootState } from 'src/store';
import { SpinnerContainer } from 'src/legacy/components/Loading';
import { useSignatureRequestComponent } from 'src/legacy/components/Signature/SignatureSidebar/useSignatureRequestComponent';
import { FlagsContext, RouteContext } from 'src/context';
import { hasValidClientContractComponents } from 'src/utils/ContractUtils';
import { TABS_CONTAINER_HEIGHT } from 'src/legacy/components/UI/BaseTab';
import { SCROLLBAR_SIZE } from 'src/legacy/components/Layout';
import Activator from 'src/legacy/components/Activator';
import { InformationCircleIcon } from 'src/legacy/components/Icons';
import RowDivider from 'src/legacy/components/RowDivider';
import RecipientSelection from 'src/legacy/components/Client/RecipientSelection';
import { getSelectedRecipientAutoFieldValue } from 'src/legacy/components/Contracts/ContractBuilderSidebar/SelectedRecipientInfoInputs';
import { InputType } from 'src/services/api';
import { useAppDispatch } from 'src/hooks/useStore';
import { setPageComponents } from 'src/store/signaturePage/actions';
import { isContractField } from 'src/legacy/components/Contracts/PendingContract';

interface StyleProps {
  contentAreaHeight: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    width: '100%',
    // Define the maximum width for displaying the PDF area.
    // Subtract the contract sidebar width and scrollbar thumb width (10px) from the available width
    maxWidth: `calc(100% - ${CONTRACT_SIDEBAR_WIDTH - SCROLLBAR_SIZE}px)`,
    height: (props: StyleProps) => props.contentAreaHeight,
    overflowY: 'auto',
  },
  clientContentContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingTop: MOBILE_APP_BAR_HEIGHT,
    },
  },
  content: {
    width: '100%',
    margin: '0 auto',
    maxWidth: signaturePageContentWidth,
    paddingTop: theme.spacing(5),
  },
  card: {
    boxShadow: 'none',
    borderColor: NonHoverBorder,
    marginBottom: theme.spacing(5),
  },
  cardHeader: {
    borderBottom: `1px solid ${NonHoverBorder}`,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  pdfContainer: {
    position: 'relative',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  oneOffContractTooltip: {
    height: 14,
    width: 14,
    color: GraySmall,
    marginLeft: theme.spacing(0.5),
  },
}));

const CREATE_TEMPLATE_DISABLE_TOOLTIP_TEXT =
  'To create a contract template you have to upload a file, set a name, and add at least 1 client input';

const CREATE_CONTRACT_DISABLE_TOOLTIP_TEXT =
  'To create a contract you have to upload a file, set a name, select a recipient and add at least 1 client input';

const EDIT_TEMPLATE_DISABLE_TOOLTIP_TEXT =
  'To edit a contract template you have to set a name and add at least 1 client input';

const ONEOFF_CONTRACT_TOOLTIP_TEXT =
  'Enable this option if the contract is designed to be a reusable template. Contract templates can be reused multiple times and shared with different clients. If disabled, the contract will be treated as a one-off contract, and it will be directly shared with the specified client.';

/**
 * Hook for the contract template creation "FormPage" component.
 *
 * @returns {object} An object containing:
 *   - {object} validationScheme: Form validation rules.
 *   - {JSX.Element} FormRenderer: The form's UI components, including the configuration form, PDF, and draggable signature components.
 */
export const useContractBuilderAndSignerForm = ({
  onPageSave,
}: {
  onPageSave: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { EnableOneOffContract } = useContext(FlagsContext);
  const isClient = useSelector((state: RootState) => state.user.isClient);
  const { setPlaceholderElement, isOneOffContract, setIsOneOffContract } =
    useContext(ContractBuilderContext);
  const { SignatureComponentsForm } = useSignatureRequestComponent();
  const { query } = useContext(RouteContext);
  const { templateId } = query as ContractDetailsPageQueryParams;
  const [submitDisabledTooltip, setSubmitDisabledTooltip] =
    useState<React.ReactNode>(null);

  // For clients, no validation scheme is applied because no inline errors are shown.
  // Instead, validation will be handled in the save callback.
  const validationScheme = isClient
    ? Yup.object({})
    : Yup.object().shape({
        title: Yup.string().required('Contract must have a name'),
        fileKey: Yup.string(),
        pageComponents: Yup.array().of(Yup.mixed()),
        ...(isOneOffContract
          ? {
              recipientId: Yup.string().required('Recipient is required.'),
            }
          : {}),
      });

  const FormRenderer = ({
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
  }: FormBodyAPI & FormikHelpers<ContractTemplateForm>) => {
    const isEditFlow = Boolean(templateId);
    const {
      signContractInProgress,
      pageComponents,
      pageLoading,
      pageImages,
      bannerOptions,
      clients,
      companies,
      customFieldsMap,
    } = useSelector(
      (state: RootState) => ({
        signContractInProgress: state.signaturePage.saving,
        pageComponents: state.signaturePage.pageComponents,
        pageLoading: state.signaturePage.loading,
        pageImages: state.signaturePage.pageImages,
        bannerOptions: state.ui.bannerOptions,
        clients: state.clients.activeClients,
        companies: state.clients.companies,
        customFieldsMap:
          state.clients?.clientCustomFields?.additionalFields || {},
      }),
      shallowEqual,
    );

    const styleProps = useMemo(() => {
      const contentAreaHeight = isEditFlow
        ? window.innerHeight -
          (DESKTOP_APP_BAR_HEIGHT +
            TABS_CONTAINER_HEIGHT +
            (bannerOptions ? BANNER_HEIGHT : 0))
        : window.innerHeight -
          (DESKTOP_APP_BAR_HEIGHT + (bannerOptions ? BANNER_HEIGHT : 0));

      return {
        contentAreaHeight,
      };
    }, [isEditFlow, bannerOptions]);
    const classes = useStyles(styleProps);

    const getDisableButtonTooltipText = () => {
      if (isEditFlow) return EDIT_TEMPLATE_DISABLE_TOOLTIP_TEXT;
      if (isOneOffContract) return CREATE_CONTRACT_DISABLE_TOOLTIP_TEXT;
      return CREATE_TEMPLATE_DISABLE_TOOLTIP_TEXT;
    };

    /**
     * For create and edit template flow, display the disabled button tooltip under the following scenarios:
     * 1. The template should have a title.
     * 2. An uploaded file should be associated with the template.
     * 3. At least one client component should be added to the template.
     */
    useEffect(() => {
      if (isClient) return;

      const hasTitle = Boolean(values.title);
      const hasFileKey = Boolean(values.fileKey);
      const hasValidClientComponent =
        hasValidClientContractComponents(pageComponents);
      const recipientIsMissing = isOneOffContract && !values.recipientId;

      const shouldDisplayTooltip =
        !hasTitle ||
        !hasFileKey ||
        !hasValidClientComponent ||
        recipientIsMissing;

      setSubmitDisabledTooltip(
        shouldDisplayTooltip ? (
          <MemoBaseTypography>
            {getDisableButtonTooltipText()}
          </MemoBaseTypography>
        ) : null,
      );
    }, [values, pageComponents, isEditFlow, isClient]);

    /**
     * Save the page components placed on the PDF in Formik.
     * This is done to seamlessly integrate with existing logic for `isFormChanged`.
     */
    useEffect(() => {
      if (isClient) return;
      setFieldValue('pageComponents', pageComponents);
    }, [pageComponents, isClient]);

    /**
     * When selected recipient changes, update the already added autofill fields
     */
    const handleRecipientChange = (value: string) => {
      if (!isOneOffContract) return;

      const selectedClient = clients.find((client) => client.id === value);

      const selectedCompany = companies.find((company) => company.id === value);

      const updatedPageComponents = pageComponents
        .map((component) => {
          const isOneOffContractAutofillField =
            component.inputType === InputType.AutoFill && component.value;

          if (isOneOffContractAutofillField) {
            const updatedValue = getSelectedRecipientAutoFieldValue({
              key: component.autoFillField || '',
              recipient: selectedClient,
              recipientCompany: selectedCompany,
              customFieldsMap,
            });

            if (!updatedValue) return null;

            return {
              ...component,
              value: updatedValue,
            };
          }

          return component;
        })
        .filter(isContractField);

      dispatch(setPageComponents(updatedPageComponents));
    };

    return (
      <DndProvider backend={HTML5Backend}>
        <BoxDragPreview />
        <div>
          <div
            className={classNames({
              [classes.contentContainer]: !isClient,
              [classes.clientContentContainer]: isClient,
            })}
          >
            <div
              className={classNames({
                [classes.content]: !isClient,
              })}
            >
              {!isClient && (
                <RegularCardBox className={classes.card}>
                  <CardHeader
                    className={classes.cardHeader}
                    title={
                      <MemoBaseTypography fontType="15Medium">
                        Contract setup
                      </MemoBaseTypography>
                    }
                  />

                  <CardContent className={classes.cardContent}>
                    <ContractFileDropzone name="fileKey" />
                    <BaseTextField
                      label="Contract name"
                      variant="outlined"
                      sizeVariant="medium"
                      value={values.title}
                      fullWidth
                      name="title"
                      error={Boolean(touched?.title && errors?.title)}
                      helperText={(touched.title && errors.title) || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* 1. Only show one off contract toggle if the feature flag is enabled
                        2. Don't show the one off contract toggle during the edit flow. */}
                    {EnableOneOffContract && !isEditFlow && (
                      <>
                        <RowDivider mt={0} mb={0} />
                        <div className={classes.flexContainer}>
                          <div className={classes.flexContainer}>
                            <MemoBaseTypography fontType="13Regular">
                              This contract is a reusable template
                            </MemoBaseTypography>
                            <Tooltip title={ONEOFF_CONTRACT_TOOLTIP_TEXT}>
                              <div>
                                <InformationCircleIcon
                                  className={classes.oneOffContractTooltip}
                                />
                              </div>
                            </Tooltip>
                          </div>
                          <Activator
                            checked={!isOneOffContract}
                            onChange={(_unusedEvent, checked) => {
                              setIsOneOffContract(!checked);
                            }}
                          />
                        </div>
                        {isOneOffContract && (
                          <FormControl fullWidth>
                            <RecipientSelection
                              value={values.recipientId}
                              fieldName="recipientId"
                              setFieldValue={setFieldValue}
                              hideCompanies
                              label="Select a recipient"
                              onChange={handleRecipientChange}
                            />
                          </FormControl>
                        )}
                      </>
                    )}
                  </CardContent>
                </RegularCardBox>
              )}

              {signContractInProgress && (
                <SpinnerContainer cover message={null} />
              )}
              <div className={classes.pdfContainer}>
                {!pageLoading && pageImages.length > 0 && (
                  <SignaturePageContent
                    onSavePlaceholderElementReference={setPlaceholderElement}
                    onPageSave={onPageSave}
                    isEditFlow={isEditFlow}
                  >
                    <SignatureComponentsForm />
                  </SignaturePageContent>
                )}
              </div>
            </div>
          </div>
          {!isClient && <ContractBuilderSidebar />}
        </div>
      </DndProvider>
    );
  };

  return {
    validationScheme,
    FormRenderer,
    submitDisabledTooltip,
  };
};
