import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PencilIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.2238 4.89258L3.39844 15.7422L2.5 17.5L4.25742 16.6016L15.1074 5.77617L14.2238 4.89258Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4334 2.68316L15.5498 3.56675L16.4334 4.45074L17.317 3.56675C17.4341 3.44955 17.4999 3.29064 17.4999 3.12495C17.4999 2.95927 17.4341 2.80036 17.317 2.68316V2.68316C17.1998 2.56604 17.0409 2.50024 16.8752 2.50024C16.7095 2.50024 16.5506 2.56604 16.4334 2.68316V2.68316Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
