import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DocumentsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.125 10.3176V17.0312C13.125 17.9805 12.3809 18.75 11.4473 18.75H4.17969C3.24414 18.75 2.5 17.9805 2.5 17.0312V6.71875C2.50267 6.26373 2.6846 5.82811 3.00636 5.50636C3.32811 5.1846 3.76373 5.00267 4.21875 5H7.89531C8.02257 5.00019 8.14852 5.02571 8.26581 5.07507C8.38311 5.12443 8.48941 5.19664 8.57852 5.2875L12.8422 9.62344C13.0236 9.80894 13.1251 10.0581 13.125 10.3176V10.3176Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M7.8125 5V9.21875C7.81595 9.50776 7.93229 9.78396 8.13666 9.98834C8.34104 10.1927 8.61724 10.3091 8.90625 10.3125H13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 5V2.96875C6.87767 2.51373 7.0596 2.07811 7.38136 1.75636C7.70311 1.4346 8.13873 1.25267 8.59375 1.25H12.2656C12.3936 1.25018 12.5202 1.27567 12.6383 1.32501C12.7564 1.37436 12.8635 1.44657 12.9535 1.5375L17.2172 5.87344C17.3985 6.05696 17.5001 6.30454 17.5 6.5625V13.2812C17.5 14.2305 16.7559 15 15.8223 15H13.4375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M12.1875 1.25V5.46875C12.1909 5.75776 12.3073 6.03396 12.5117 6.23834C12.716 6.44271 12.9922 6.55905 13.2812 6.5625H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
