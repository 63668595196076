import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DiamondIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.87494 6.875H18.1249M1.38354 7.35195L9.49877 17.8777C9.55775 17.9546 9.63363 18.0169 9.72053 18.0598C9.80744 18.1026 9.90304 18.1249 9.99994 18.1249C10.0968 18.1249 10.1924 18.1026 10.2794 18.0598C10.3663 18.0169 10.4421 17.9546 10.5011 17.8777L18.6163 7.35195C18.6961 7.24807 18.7424 7.12235 18.7491 6.99153C18.7557 6.8607 18.7223 6.73095 18.6535 6.61953L15.9007 2.17617C15.8441 2.08422 15.7648 2.00828 15.6705 1.95557C15.5763 1.90286 15.4701 1.87512 15.3621 1.875H4.63783C4.52982 1.87512 4.42363 1.90286 4.32935 1.95557C4.23507 2.00828 4.15583 2.08422 4.09916 2.17617L1.34643 6.61953C1.27754 6.73095 1.2442 6.8607 1.25083 6.99153C1.25746 7.12235 1.30374 7.24807 1.38354 7.35195V7.35195Z"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 17.5L13.75 6.875M15.625 2.5L13.75 6.875L10 1.875L15.625 2.5ZM4.375 2.5L6.25 6.875L10 1.875L4.375 2.5ZM10 17.5L6.25 6.875L10 17.5Z"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
