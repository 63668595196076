import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const StatusIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" fill="none" {...props} htmlColor="#FFA500">
    <g filter="url(#filter0_i_30315_165662)">
      <circle cx="6" cy="6" r="6" fill="white" />
    </g>
    <circle cx="6" cy="6" r="5.5" stroke="white" />
    <defs>
      <filter
        id="filter0_i_30315_165662"
        x="0"
        y="0"
        width="15"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_30315_165662"
        />
      </filter>
    </defs>
  </SvgIcon>
);
