import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PawIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.8804 6.64454C17.7416 6.58993 17.5937 6.56209 17.4445 6.5625H17.4289C16.641 6.57422 15.7574 7.31211 15.2937 8.42813C14.7378 9.76329 14.9937 11.1293 15.8695 11.4805C16.0082 11.5351 16.156 11.5629 16.305 11.5625C17.0968 11.5625 17.9925 10.8203 18.4601 9.69688C19.0121 8.36172 18.7523 6.99571 17.8804 6.64454Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M12.797 11.8547C11.711 10.0527 11.2423 9.375 10.0001 9.375C8.75789 9.375 8.28523 10.057 7.19929 11.8547C6.26961 13.3922 4.3907 13.5203 3.92195 14.8262C3.82685 15.0654 3.77909 15.3207 3.78132 15.5781C3.78132 16.6402 4.59382 17.5 5.59382 17.5C6.83601 17.5 8.52742 16.5082 10.004 16.5082C11.4805 16.5082 13.1641 17.5 14.4063 17.5C15.4063 17.5 16.2149 16.6406 16.2149 15.5781C16.2158 15.3205 16.1667 15.0651 16.0704 14.8262C15.6016 13.5156 13.7266 13.3922 12.797 11.8547Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M7.52005 7.65625C7.57235 7.65629 7.62459 7.65238 7.6763 7.64453C8.58294 7.51289 9.14896 6.25664 8.94349 4.83789C8.75013 3.50039 7.93841 2.5 7.08958 2.5C7.03728 2.49996 6.98504 2.50388 6.93333 2.51172C6.02669 2.64336 5.46068 3.89961 5.66614 5.31836C5.8595 6.65195 6.67122 7.65625 7.52005 7.65625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M14.3329 5.31836C14.5384 3.89961 13.9724 2.64336 13.0658 2.51172C13.014 2.50388 12.9618 2.49996 12.9095 2.5C12.0607 2.5 11.2505 3.50039 11.0568 4.83789C10.8513 6.25664 11.4173 7.51289 12.324 7.64453C12.3757 7.65238 12.4279 7.65629 12.4802 7.65625C13.329 7.65625 14.1408 6.65195 14.3329 5.31836Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M4.13157 11.4805C5.00618 11.1289 5.26165 9.76172 4.70657 8.42813C4.23938 7.30469 3.34446 6.5625 2.55383 6.5625C2.40477 6.56209 2.25699 6.58993 2.11829 6.64454C1.24368 6.9961 0.98821 8.36329 1.54329 9.69688C2.01048 10.8203 2.9054 11.5625 3.69602 11.5625C3.84508 11.5629 3.99287 11.5351 4.13157 11.4805V11.4805Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
