import React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
// We have to import from the /input subdirectory because the
// the main module component includes a country flag selector
// that we don't include in our product.
import PhoneInput from 'react-phone-number-input/input';
import * as PhoneInputStyles from 'react-phone-number-input/style.css';
import withStyles from 'isomorphic-style-loader/withStyles';
import { BaseTextField } from 'src/legacy/components/TextField';

const PhoneInputRef = React.forwardRef((props, ref) => (
  <BaseTextField
    inputRef={ref}
    fullWidth
    id="phone"
    type="text"
    key="phone"
    name="phone"
    variant="outlined"
    {...props}
  />
));

const getPhoneError = (value: string) => {
  if (!value) {
    return 'Phone number required';
  }

  try {
    if (isValidPhoneNumber(value)) {
      return undefined;
    }
  } catch (ex) {
    console.error('Phone validation error', ex);
  }

  return 'Invalid phone number';
};

interface PhoneNumberInputProps {
  value: string;
  touched?: boolean;
  onChange: (value: string) => void;
  onBlur: React.FocusEventHandler<string> | undefined;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  touched,
  onChange,
  onBlur,
  ...other
}) => {
  const errorMessage = getPhoneError(value);

  return (
    <PhoneInput
      defaultCountry="US"
      onChange={onChange}
      value={value}
      onBlur={(e) => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        onBlur?.(e);
      }}
      error={Boolean(touched && errorMessage) ? errorMessage : undefined}
      inputComponent={PhoneInputRef}
      {...other}
    />
  );
};

export default withStyles(PhoneInputStyles)(PhoneNumberInput);
