import React, { useMemo } from 'react';
import { Grid, Theme, Tooltip, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import {
  InformationCircleIcon,
  NoCompanyIcon,
  SignatureDateIcon,
} from 'src/legacy/components/Icons';
import { SignatureSidebarButton } from 'src/legacy/components/Signature/SignatureComponents';
import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  CONTRACT_COMPONENT_DEFAULT_WIDTH,
  SignatureComponentType,
} from 'src/constants';
import { InputType, useGetPortalConfigQuery } from 'src/services/api';
import { RootState } from 'src/store';
import {
  AddComponentAction,
  SetSelectedComponent,
} from 'src/store/signaturePage/actions';
import { ComponentCoordinatesType } from 'src/store/signaturePage/types';
import { MapFieldTypeToDefaultProps } from 'src/legacy/components/CustomFieldsMenu';
import { PredefinedAutofillKeys } from 'src/entities/Contract';
import { GraySmall } from 'src/theme/colors';
import MemoBaseTypography from 'src/legacy/components/Text/BaseTypography';
import { toCamelCase } from 'src/utils/StringUtils';
import { getFieldTypeUsingComponentType } from 'src/utils/ContractUtils';

const SHARE_DATE_AUTOFILL_INPUT_KEY = `{{contract.${PredefinedAutofillKeys.ShareDate}}}`;
const AUTOFILL_INPUT_TOOLTIP_TEXT = `The values will automatically be set based on the client you share a contract with.`;

const useStyles = makeStyles((theme: Theme) => ({
  autofillSignatureButton: {
    '& svg': {
      height: 16,
    },
  },
  inputTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  tooltipIcon: {
    height: 12,
    width: 12,
    color: GraySmall,
  },
}));

/**
 * This component renders the contract sidebar auto-fill inputs.
 */
export const AutofillInputs = () => {
  const dispatch = useDispatch();
  const { data: portalConfigEntity } = useGetPortalConfigQuery();

  const classes = useStyles();
  const customFieldsMap = useSelector(
    (state: RootState) =>
      state.clients?.clientCustomFields?.additionalFields || {},
  );

  /**
   * Handles the component drop action.
   * @param {ComponentCoordinatesType} coords - The coordinates where the component is dropped.
   * @param {SignatureComponentType} componentType - The type of the dropped component.
   * @param {string} autoFillFieldKey - The auto-fill field key.
   */
  const handleComponentDropped = (
    coords: ComponentCoordinatesType,
    componentType: SignatureComponentType,
    autoFillFieldKey: string,
    fieldId: string,
  ) => {
    const key = v4();
    const stagedComponent = {
      componentType,
      inputType: InputType.AutoFill,
      label: autoFillFieldKey,
      name: autoFillFieldKey,
      fieldType: getFieldTypeUsingComponentType(componentType),
      key,
      fields: { key, value: '' },
      ...coords,
      height: CONTRACT_COMPONENT_DEFAULT_HEIGHT,
      width: CONTRACT_COMPONENT_DEFAULT_WIDTH,
      autoFillField: fieldId,
    };

    dispatch(AddComponentAction(stagedComponent));
    dispatch(SetSelectedComponent(stagedComponent.fields.key));
  };

  // List of autofillable contract signature inputs
  const autofillFieldsInfo = useMemo(() => {
    const defaultFields = [
      {
        label: `{{client.${PredefinedAutofillKeys.FullName}}}`,
        type: 'text',
        id: PredefinedAutofillKeys.FullName,
      },
      {
        label: `{{client.${PredefinedAutofillKeys.FirstName}}}`,
        type: 'text',
        id: PredefinedAutofillKeys.FirstName,
      },
      {
        label: `{{client.${PredefinedAutofillKeys.LastName}}}`,
        type: 'text',
        id: PredefinedAutofillKeys.LastName,
      },
      {
        label: `{{client.${PredefinedAutofillKeys.Email}}}`,
        type: 'email',
        id: PredefinedAutofillKeys.Email,
      },
      ...(!portalConfigEntity?.structFields?.disableCompanies
        ? [
            {
              label: `{{client.${PredefinedAutofillKeys.Company}}}`,
              type: 'text',
              id: PredefinedAutofillKeys.Company,
            },
          ]
        : []),
    ];

    // Using client's custom properties list to list autofill inputs
    const customFields = Object.values(customFieldsMap).map((f) => ({
      label: `{{client.${toCamelCase(f.name)}}}`,
      type: f.type,
      id: f.id,
    }));

    return [...defaultFields, ...customFields];
  }, [customFieldsMap, portalConfigEntity]);

  return (
    <>
      <div className={classes.inputTitle}>
        <MemoBaseTypography fontType="12Medium">
          Autofill fields
        </MemoBaseTypography>
        <Tooltip title={AUTOFILL_INPUT_TOOLTIP_TEXT}>
          <div>
            <InformationCircleIcon className={classes.tooltipIcon} />
          </div>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        {autofillFieldsInfo.map(({ label, type, id }) => (
          <Grid item xs={12} key={label}>
            <SignatureSidebarButton
              className={classes.autofillSignatureButton}
              label={label}
              onDrop={(coordinates) =>
                handleComponentDropped(
                  coordinates,
                  SignatureComponentType.REQUEST_TEXT,
                  label,
                  id,
                )
              }
              buttonIcon={
                id === PredefinedAutofillKeys.Company
                  ? NoCompanyIcon
                  : MapFieldTypeToDefaultProps[type].icon
              }
              isClientSignatureButton
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <SignatureSidebarButton
            label={SHARE_DATE_AUTOFILL_INPUT_KEY}
            onDrop={(coordinates) =>
              handleComponentDropped(
                coordinates,
                SignatureComponentType.REQUEST_DATE,
                SHARE_DATE_AUTOFILL_INPUT_KEY,
                PredefinedAutofillKeys.ShareDate,
              )
            }
            buttonIcon={SignatureDateIcon}
            isClientSignatureButton
          />
        </Grid>
      </Grid>
    </>
  );
};
