import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MusicalNoteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M9.37501 13.4105V16.5625C9.37575 16.8264 9.29368 17.0839 9.14034 17.2987C8.98701 17.5134 8.77015 17.6747 8.52032 17.7597L7.68165 18.0422C6.66993 18.3824 5.62501 17.6222 5.62501 16.5453C5.62422 16.2649 5.71145 15.9913 5.87438 15.7632C6.03732 15.535 6.26775 15.3637 6.53321 15.2734L8.52032 14.6047C8.77006 14.5196 8.98684 14.3584 9.14016 14.1437C9.29347 13.929 9.37561 13.6716 9.37501 13.4078V3.59373C9.37456 3.45395 9.4206 3.318 9.50588 3.20726C9.59116 3.09651 9.71085 3.01727 9.8461 2.98201L14.0816 1.88279C14.1163 1.87378 14.1525 1.87283 14.1876 1.87999C14.2226 1.88716 14.2556 1.90225 14.2839 1.92412C14.3122 1.94599 14.3351 1.97406 14.3509 2.00617C14.3667 2.03827 14.375 2.07357 14.375 2.10935V4.3656C14.3754 4.50575 14.3291 4.64204 14.2433 4.7529C14.1576 4.86376 14.0373 4.94287 13.9016 4.97771L10.3219 5.88123C10.0503 5.95086 9.80967 6.10912 9.63816 6.33094C9.46665 6.55275 9.37404 6.82545 9.37501 7.10584V8.64451"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
