import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { BaseTextField } from 'src/legacy/components/TextField/BaseTextField';

export const NumberTextField: React.FC<TextFieldProps> = ({
  onChange,
  ...textFieldProps
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (onChange && event.target.value.match(/^\d*\.?\d*$/)) {
      onChange(event);
    }
  };

  return <BaseTextField onChange={handleChange} {...textFieldProps} />;
};
