/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type entityImportSlice = {
  id: string;
  isStarted: boolean;
  isCompleted: boolean;
};

const initialState: entityImportSlice = {
  id: '',
  isStarted: false,
  isCompleted: false,
};

export const entityImportSlice = createSlice({
  name: 'entityImports',
  initialState,
  reducers: {
    setImportStarted: (
      state,
      action: PayloadAction<{ id: string; started: boolean }>,
    ) => {
      const { id, started } = action.payload;
      state.id = id;
      state.isStarted = started;
    },
    setImportComplete: (state, action: PayloadAction<boolean>) => {
      state.id = ''; // when import is complete, reset the id
      state.isCompleted = action.payload;
      state.isStarted = false;
    },
  },
});

export const { setImportStarted, setImportComplete } =
  entityImportSlice.actions;

export const entityImportReducer = entityImportSlice.reducer;
