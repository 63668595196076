import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const useSavePageUnload = () => {
  const { pageChanges, isFormPageChanged } = useSelector(
    (state: RootState) => state.ui,
  );

  /**
   * This hook is used to alert about page changes before the page unloads.
   */
  const pageUnloadHandler = useCallback(
    (e: BeforeUnloadEvent) => {
      if ((pageChanges && pageChanges.length) || isFormPageChanged) {
        e.returnValue =
          'You have unsaved changes. Are you sure you want leave this page?';
        e.preventDefault();
      }
    },
    [pageChanges, isFormPageChanged],
  );

  /**
   * This hook is keeps the beforeunload event handler updated as the store state changes
   */
  useEffect(() => {
    window.addEventListener('beforeunload', pageUnloadHandler);

    return () => window.removeEventListener('beforeunload', pageUnloadHandler);
  }, [pageUnloadHandler]);

  /**
   * this hook is used to clear the beforeunload listener
   */
  const removeUnloadListener = useCallback(() => {
    window.removeEventListener('beforeunload', pageUnloadHandler);
  }, [pageUnloadHandler]);

  return removeUnloadListener;
};

export default useSavePageUnload;
