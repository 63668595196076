import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ColorFillIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.3711 13.1815C16.3567 13.1638 16.3385 13.1496 16.3179 13.1398C16.2973 13.1301 16.2748 13.125 16.252 13.125C16.2292 13.125 16.2066 13.1301 16.186 13.1398C16.1654 13.1496 16.1472 13.1638 16.1328 13.1815C15.7227 13.6659 14.375 14.9998 14.375 16.2151C14.375 17.2698 15.2148 18.1248 16.25 18.1248C17.2852 18.1248 18.125 17.2655 18.125 16.2151C18.125 14.9998 16.7852 13.6659 16.3711 13.1815Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M15.1172 11.2462L6.07852 2.27982C5.94788 2.14872 5.79264 2.0447 5.62171 1.97372C5.45077 1.90275 5.26751 1.86621 5.08243 1.86621C4.89735 1.86621 4.71409 1.90275 4.54315 1.97372C4.37222 2.0447 4.21698 2.14872 4.08634 2.27982L3.88516 2.48099C3.75406 2.61164 3.65004 2.76688 3.57907 2.93781C3.50809 3.10874 3.47156 3.292 3.47156 3.47709C3.47156 3.66217 3.50809 3.84543 3.57907 4.01636C3.65004 4.18729 3.75406 4.34253 3.88516 4.47318L5.95118 6.5392L8.17774 4.31263L2.2004 10.2814C2.09722 10.384 2.01556 10.5062 1.96019 10.6407C1.90483 10.7753 1.87687 10.9196 1.87796 11.0651C1.87905 11.2106 1.90917 11.3544 1.96655 11.4882C2.02393 11.6219 2.10741 11.7428 2.21212 11.8439L7.33712 16.7658C7.54177 16.9617 7.81435 17.0707 8.09767 17.07C8.38099 17.0693 8.65301 16.9588 8.85665 16.7619C10.3332 15.3322 13.4777 12.2892 13.8059 11.9611C14.0324 11.7345 14.5168 11.6837 14.927 11.6837H14.9387C14.9894 11.6838 15.039 11.6687 15.0811 11.6404C15.1232 11.6122 15.1559 11.572 15.1751 11.525C15.1942 11.4781 15.199 11.4265 15.1887 11.3768C15.1783 11.3271 15.1535 11.2817 15.1172 11.2462V11.2462Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
