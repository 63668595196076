import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ManIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.12506 8.125V18.4375C8.12365 18.561 8.14693 18.6836 8.19354 18.7979C8.24016 18.9123 8.30916 19.0162 8.3965 19.1036C8.48384 19.1909 8.58775 19.2599 8.70213 19.3065C8.81651 19.3531 8.93905 19.3764 9.06256 19.375V19.375C9.18607 19.3764 9.30861 19.3531 9.42299 19.3065C9.53737 19.2599 9.64128 19.1909 9.72862 19.1036C9.81596 19.0162 9.88496 18.9123 9.93158 18.7979C9.97819 18.6836 10.0015 18.561 10.0001 18.4375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.0001 13.125V18.4375C9.99865 18.561 10.0219 18.6836 10.0685 18.7979C10.1152 18.9123 10.1842 19.0162 10.2715 19.1036C10.3588 19.1909 10.4627 19.2599 10.5771 19.3065C10.6915 19.3531 10.8141 19.3764 10.9376 19.375C11.0611 19.3764 11.1836 19.3531 11.298 19.3065C11.4124 19.2599 11.5163 19.1909 11.6036 19.1036C11.691 19.0162 11.76 18.9123 11.8066 18.7979C11.8532 18.6836 11.8765 18.561 11.8751 18.4375V8.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.12507 7.5V10.9375C8.12653 11.061 8.10328 11.1836 8.05669 11.298C8.0101 11.4124 7.9411 11.5163 7.85375 11.6037C7.7664 11.691 7.66246 11.76 7.54806 11.8066C7.43365 11.8532 7.31109 11.8765 7.18757 11.875V11.875C7.06404 11.8765 6.94148 11.8532 6.82707 11.8066C6.71267 11.76 6.60873 11.691 6.52138 11.6037C6.43404 11.5163 6.36503 11.4124 6.31844 11.298C6.27185 11.1836 6.2486 11.061 6.25007 10.9375V7.5C6.25007 7.00272 6.44761 6.52581 6.79924 6.17417C7.15087 5.82254 7.62778 5.625 8.12507 5.625H11.8751C12.3723 5.625 12.8493 5.82254 13.2009 6.17417C13.5525 6.52581 13.7501 7.00272 13.7501 7.5V10.9375C13.7515 11.061 13.7283 11.1836 13.6817 11.298C13.6351 11.4124 13.5661 11.5163 13.4787 11.6037C13.3914 11.691 13.2875 11.76 13.1731 11.8066C13.0587 11.8532 12.9361 11.8765 12.8126 11.875V11.875C12.689 11.8765 12.5665 11.8532 12.4521 11.8066C12.3377 11.76 12.2337 11.691 12.1464 11.6037C12.059 11.5163 11.99 11.4124 11.9434 11.298C11.8968 11.1836 11.8736 11.061 11.8751 10.9375V7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 3.75C10.8629 3.75 11.5625 3.05044 11.5625 2.1875C11.5625 1.32456 10.8629 0.625 10 0.625C9.13706 0.625 8.4375 1.32456 8.4375 2.1875C8.4375 3.05044 9.13706 3.75 10 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
