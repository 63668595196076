import { getCountryDataList } from 'countries-list';
import { ModuleFormValuesType } from 'src/legacy/components/Settings/AppSetupPage/SetupApp/appSetupTypes';
import {
  BusinessAddress,
  ExtensionsSettings,
  ModuleSettingsItem,
  ModuleType,
} from 'src/constants/dataTypes/index';
import { DEFAULT_APPS } from 'src/hooks/useApps';

/**
 * This function gets the module icon from the extensions/default
 * module array.
 * @param moduleType
 * @param moduleId
 */
export const getModuleIcon = (
  moduleType: ModuleType,
  moduleId: string,
  extensionsSettings?: ExtensionsSettings,
) => {
  if (moduleType === 'module') {
    const module = DEFAULT_APPS.find((mod) => mod.id === moduleId);
    return module?.iconName;
  }

  const extensionConfigEntry = Object.entries(extensionsSettings || {}).find(
    ([extId]) => extId === moduleId,
  );

  if (extensionConfigEntry) {
    const extensionConfig = extensionConfigEntry.at(1);
    return typeof extensionConfig === 'object'
      ? extensionConfig.icon
      : undefined;
  }

  return '';
};

/**
 * This function gets the module label from the extensions/default
 * module array.
 * @param moduleType
 * @param moduleId
 */
export const getModuleName = (
  moduleType: ModuleType,
  moduleId: string,
  extensionsSettings?: ExtensionsSettings,
): string => {
  if (moduleType === 'module') {
    const module = DEFAULT_APPS.find((mod) => mod.id === moduleId);
    return module?.label || '';
  }

  const extensionConfigEntry = Object.entries(extensionsSettings || {}).find(
    ([extId]) => extId === moduleId,
  );

  if (extensionConfigEntry) {
    if (extensionConfigEntry) {
      const extensionConfig = extensionConfigEntry.at(1);
      return typeof extensionConfig === 'object' ? extensionConfig.name : '';
    }
  }

  return '';
};

/**
 * Method to generate the updated module list
 * @param moduleSettings list of all the user modules saved
 * @param currentModule this is the original module value before upating
 * @param updatedModuel these are the new values to which module will be updated
 */
export const getUpdatedModuleSettings = ({
  moduleSettings,
  currentModule,
  updatedModule,
}: {
  moduleSettings: ModuleSettingsItem[] | undefined;
  currentModule: ModuleFormValuesType;
  updatedModule: ModuleSettingsItem;
}): ModuleSettingsItem[] => {
  // module settings
  // incase user have no saved module settings
  // fallback to default module settings
  const stagedModuleSettings =
    moduleSettings && moduleSettings.length
      ? moduleSettings
      : DEFAULT_APPS.map((module) => ({
          id: module.id,
          label: module.title,
          type: module.type,
          icon: module.iconName,
          disabled: false,
          visibilityConfig: undefined,
        }));

  const updatedModuleSettings = stagedModuleSettings.map((module) => {
    if (module.id === currentModule.id) {
      return {
        id: module.id,
        icon: updatedModule.icon,
        label: updatedModule.label,
        // if module is already disabled then enable the module
        // otherwise pick the updated module value
        disabled: currentModule?.disabled ? false : updatedModule?.disabled,
        type: module.type,
        visibilityConfig: module.visibilityConfig,
      };
    }

    return module;
  });

  return updatedModuleSettings as ModuleSettingsItem[];
};

/**
 * This function formats the business address based on the country
 * If the country is not supported then it returns the address as it is
 * otherwise it formats the "fullAddress" field with the address line, city, state, zipCode and country
 */
export const formatBusinessAddressBasedOnCountry = (
  address: BusinessAddress,
  supportedCountries: string[],
): BusinessAddress => {
  const isSupportedCountry = supportedCountries.includes(address.country);

  if (!isSupportedCountry) {
    return address;
  }

  const countries = getCountryDataList();

  const countryInfo = countries.find((c) => c.iso2 === address.country);

  // adding \n to the address fields to make it multiline
  // Desired format:
  // {addressLine}
  // {City}, {State}, {Zipcode}
  // {Country}

  const addressLines = [
    address.address_line_1 || '',
    address.address_line_2 || '',
    [address.city, address.state, address.zipCode].filter(Boolean).join(', '), // Join city, state, and zip code with commas
    countryInfo?.name || '',
  ];

  // Join non-empty address lines with a newline character and trim any extra whitespace
  const fullAddress = addressLines.filter(Boolean).join('\n').trim();

  return {
    ...address,
    fullAddress,
  };
};
