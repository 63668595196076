import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PlanetIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.1517 11.1117C18.4513 12.957 19.7302 14.5883 19.2888 15.352C18.5915 16.557 13.8677 15.1379 8.73799 12.182C3.6083 9.22619 0.01455 5.85314 0.711425 4.64845C1.14736 3.89532 3.15596 4.1672 5.85361 5.19884"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
