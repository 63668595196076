import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ShieldCheckmarkIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.125 6.875L8.79687 11.875L6.875 9.99219"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0898 4.38945C14.5968 3.76289 13.1527 3.29883 9.99997 1.875C6.84723 3.29883 5.40309 3.76289 1.91012 4.38945C1.27731 14.4191 9.39762 17.8824 9.99997 18.125C10.6023 17.8824 18.7226 14.4191 18.0898 4.38945Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
