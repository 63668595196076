// permissions for top-level navigation
export const PERM_MOD_CRM = 'module_CRM';
export const PERM_MOD_CUSTOM_FIELDS = 'module_customFields';
export const PERM_MOD_MESSAGES = 'module_messages';
export const PERM_MOD_FILES = 'module_files';
export const PERM_MOD_PAYMENTS = 'module_payments';
export const PERM_MOD_KNOWLEDGE_BASE = 'module_knowledgeBase';
export const PERM_MOD_FORMS = 'module_forms';
export const PERM_MOD_FORMS_RESPONSE = 'module_forms_response';
export const PERM_MOD_AUTOMATIONS = 'module_automations';
export const PERM_MOD_ANALYTICS = 'module_analytics';

// permissions for settings page
export const PERM_MOD_SETTINGS_CUSTOMIZATION = 'module_settingsCustomization';
export const PERM_MOD_SETTINGS_BILLING = 'module_settingsBilling';
export const PERM_MOD_SETTINGS_MODULE_MANAGEMENT =
  'module_settingsModuleManagement';
export const PERM_MOD_SETTINGS_FILES = 'module_settingsFiles';
export const PERM_MOD_SETTINGS_MESSAGING = 'module_settingsMessagingSettings';
export const PERM_MOD_SETTINGS_MODULE_PAYMENTS = 'module_settingsPayments';
export const PERM_MOD_SETTINGS_MAIN_PG = 'module_settingsMainSettings';
export const PERM_MOD_SETTINGS_DOMAINS = 'module_settingsDomains';
export const PERM_MOD_SETTINGS_MEMBERS = 'module_settingsMembers';
export const PERM_MOD_SETTINGS_API = 'module_settingsAPI';
export const PERM_MOD_SETTINGS_APPS = 'module_settingsApps';
export const PERM_MOD_SETTINGS_EXTENSIONS = 'module_settingsExtensions';
export const PERM_MOD_SETTINGS_PLANS = 'module_settingsPlans';
export const PERM_MOD_SETTINGS_REFERRAL = 'module_settingsReferral';

// permissions for articles and tags
export const PERM_MOD_ARTICLES = 'module_articles';
export const PERM_MOD_TAGS = 'module_tags';

export const USER_OPERATIONS = {
  AdjustInternalRole: 'adjustInternalRole',
  AdjustClientRole: 'adjustClientRole',
  Invite: 'invite',
  ViewBillingInvoices: 'view_BilledInvoices',
  delete: 'delete',
};

export const BILLING_OPERATIONS = {
  ViewAllInvoices: 'view_AllInvoices',
  ViewAllSubscriptions: 'view_AllSubscriptions',
};

export const PLANS_OPERATIONS = {
  UpgradePlan: 'upgradePlan',
  DowngradePlane: 'downgradePlan',
};

export const MAIN_SETTINGS_OPERATIONS = {
  MODIFY_GOOGLE_ANALYTICS: 'modifyGoogleAnalytics',
  MODIFY_MFA_ENFORCEMENT: 'modifyMFAEnforcement',
};

export const CRM_OPERATIONS = {
  CHANGE_ASSIGNEE: 'changeAssignee',
};

export const CUSTOM_FIELD_OPERATIONS = {
  MODIFY_VISIBILITY: 'modifyVisibility',
  CHANGE_TEMPLATE: 'changeTemplate',
};

export const GENERAL_OPERATIONS = {
  CREATE: 'create',
  UPDATE: 'update',
};

export const APP_OPERATIONS = {
  UpdateVisibility: 'updateVisibility',
};

export enum DisabledReason {
  LimitReached = 'limitReached',
}
