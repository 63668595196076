import React from 'react';
import { makeStyles } from '@material-ui/core';
import RowDivider, { RowDividerProps } from 'src/legacy/components/RowDivider';
import { LoadingWrapper } from 'src/legacy/components/Loading';

// this ensures the loading wrapper takes up the remaining space
// in the row
const loadingWrapperProps = { minHeight: 40, flexGrow: 1 };

interface StyleProps {
  fullHeight?: boolean;
  moreInfo?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    // this component usually shows a description row,
    // followed be a divider and then the actual content.
    // The items are stacked vertically where the content should take the
    // remaining space which is why we use flexbox here.
    width: '100%',
    height: (props: StyleProps) => (props.fullHeight ? '100%' : 'auto'),
    display: 'flex',
    flexDirection: 'column',
  },
  descriptionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: (props: StyleProps) =>
      props.moreInfo ? 'space-between' : 'flex-start',
  },
}));

interface PageRowProps extends StyleProps {
  description?: React.ReactNode;
  children?: React.ReactNode;
  hideDivider?: boolean;
  dividerProps?: RowDividerProps;
  isLoading?: boolean;
  loadingProps?: React.CSSProperties;
}

export const SettingsPageRow: React.FC<PageRowProps> = ({
  description,
  children,
  hideDivider = false,
  moreInfo,
  fullHeight = false,
  dividerProps = {},
  isLoading = false,
  loadingProps = loadingWrapperProps,
}) => {
  const classes = useStyles({ fullHeight, moreInfo });

  return (
    <div className={classes.root}>
      {description && (
        <div className={classes.descriptionContainer}>
          {description}
          {moreInfo}
        </div>
      )}
      {!hideDivider && <RowDivider {...dividerProps} />}
      <LoadingWrapper
        isLoading={isLoading}
        wrapperProps={loadingProps}
        hideContentWhileLoading
      >
        {children}
      </LoadingWrapper>
    </div>
  );
};
