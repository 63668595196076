import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RoseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.25 5C15.5117 5.16602 14.8125 5.34922 14.1523 5.54492C12.5703 6.01367 11.2031 6.60547 10.0352 7.17188C9.28125 7.53789 9.01172 7.69961 8.02344 8.27461C5.74219 9.59063 4.375 11.298 4.375 13.8531C4.375 16.7402 6.54687 18.75 10 18.75C13.4531 18.75 15.625 16.5699 15.625 13.6828C15.625 10.7957 13.2422 8.79844 16.25 5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3125 7.03867C9.54336 5.98398 8.82031 5.52734 8.25391 5.21875C6.35156 4.18359 3.75 3.75 3.75 3.75C5.37109 5.45703 5.20312 7.26953 5 8.75C5 8.75 4.84883 10.0344 5.07461 11.0316"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5309 5.43554C13.9273 4.00781 13.1246 2.5 13.1246 2.5C13.1246 2.5 10.6512 2.5 7.82422 5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.90137 3.4207C8.64238 1.78945 6.8748 1.25 6.8748 1.25C6.27715 2.0625 5.7502 3.26484 5.5127 4.14727"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
