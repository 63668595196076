import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Heading2Icon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M3.98 8.3175H10.1225V12.2175C10.1225 13.7125 10.025 13.8425 8.855 13.875V14.2812H12.8525V13.875C11.6988 13.8425 11.6012 13.7125 11.6012 12.2175V4.56375C11.6012 3.0525 11.6825 2.955 12.8525 2.90625V2.5H8.855V2.90625C10.0413 2.955 10.1225 3.0525 10.1225 4.56375V7.7975H3.98V4.56375C3.98 3.0525 4.06125 2.955 5.2475 2.90625V2.5H1.25V2.90625C2.42 2.955 2.50125 3.0525 2.50125 4.56375V12.2175C2.50125 13.7288 2.40375 13.8425 1.25 13.875V14.2812H5.2475V13.875C4.0775 13.8425 3.98 13.7125 3.98 12.2175V8.3175Z"
      fill="currentColor"
    />
    <path
      d="M18.4716 17.5L18.75 15.4L18.4407 15.3155C18.2345 16.3293 17.9768 16.5948 17.2345 16.5948H14.2552C14.4407 15.9552 14.6469 15.581 15.1005 15.0621C15.5541 14.5431 15.8325 14.3017 17.1211 13.3724C18.2655 12.5517 18.6469 11.9362 18.6469 10.9345C18.6469 10.3793 18.5438 10.0052 18.317 9.65517C17.9253 9.07586 17.2036 8.75 16.3376 8.75C14.9562 8.75 13.9253 9.67931 13.9253 10.9224C13.9253 11.2724 14.0696 11.4172 14.451 11.4172C14.8119 11.4172 14.9253 11.3086 14.9459 10.8983C14.9665 10.4276 14.9871 10.2586 15.049 10.0172C15.2242 9.41379 15.6675 9.07586 16.2758 9.07586C17.1108 9.07586 17.6778 9.76379 17.6778 10.7776C17.6778 11.1397 17.6057 11.4776 17.451 11.7914C17.3067 12.069 17.0902 12.3345 16.6675 12.7328C15.3479 13.9879 14.9149 14.4707 14.5129 15.1466C14.0902 15.8345 13.8737 16.5224 13.75 17.5H18.4716Z"
      fill="currentColor"
    />
  </SvgIcon>
);
