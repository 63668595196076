import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CarIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.125 8.75L4.60078 5.30664C4.84102 4.74609 5.45313 4.375 6.13711 4.375H13.8629C14.5469 4.375 15.159 4.74609 15.3992 5.30664L16.875 8.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 8.75H3.125V14.375H16.875V8.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 14.375V15.625H3.125V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 14.375V15.625H15.625V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 11.875C5.97018 11.875 6.25 11.5952 6.25 11.25C6.25 10.9048 5.97018 10.625 5.625 10.625C5.27982 10.625 5 10.9048 5 11.25C5 11.5952 5.27982 11.875 5.625 11.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 11.875C14.7202 11.875 15 11.5952 15 11.25C15 10.9048 14.7202 10.625 14.375 10.625C14.0298 10.625 13.75 10.9048 13.75 11.25C13.75 11.5952 14.0298 11.875 14.375 11.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
