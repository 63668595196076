import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const JustifyLeftIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 20" fill="none" {...props}>
    <path
      d="M16.1538 3H3.84615C2.82655 3 2 3.85473 2 4.90909V15.0909C2 16.1453 2.82655 17 3.84615 17H16.1538C17.1734 17 18 16.1453 18 15.0909V4.90909C18 3.85473 17.1734 3 16.1538 3Z"
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 8C14.3284 8 15 7.32843 15 6.5C15 5.67157 14.3284 5 13.5 5C12.6716 5 12 5.67157 12 6.5C12 7.32843 12.6716 8 13.5 8Z"
      stroke="currentColor"
      fill="none"
      strokeMiterlimit="10"
    />
    <path
      d="M11.8462 13.0745L8.35923 9.38231C8.13735 9.14698 7.83912 9.01043 7.5255 9.00057C7.21188 8.99071 6.90655 9.10829 6.67192 9.32927L2 13.7359M8.76923 17L13.5131 11.9675C13.73 11.7369 14.0204 11.6007 14.3272 11.5856C14.634 11.5705 14.935 11.6776 15.1712 11.8859L18 14.3887"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 15H28M20 5H28H20ZM20 10H28H20Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
