import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LibraryIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.125 3.75H1.875C1.52982 3.75 1.25 4.02982 1.25 4.375V17.5C1.25 17.8452 1.52982 18.125 1.875 18.125H3.125C3.47018 18.125 3.75 17.8452 3.75 17.5V4.375C3.75 4.02982 3.47018 3.75 3.125 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 8.75H9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 15.625H9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 6.25H5C4.65482 6.25 4.375 6.52982 4.375 6.875V17.5C4.375 17.8452 4.65482 18.125 5 18.125H8.75C9.09518 18.125 9.375 17.8452 9.375 17.5V6.875C9.375 6.52982 9.09518 6.25 8.75 6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 1.875H10.625C10.2798 1.875 10 2.15482 10 2.5V17.5C10 17.8452 10.2798 18.125 10.625 18.125H13.125C13.4702 18.125 13.75 17.8452 13.75 17.5V2.5C13.75 2.15482 13.4702 1.875 13.125 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M16.5023 3.75421L14.9241 3.92023C14.4898 3.96593 14.1749 4.37218 14.2237 4.82257L15.5878 17.3847C15.637 17.8351 16.0319 18.1659 16.4663 18.1206L18.0444 17.9546C18.4788 17.9089 18.7937 17.5027 18.7448 17.0523L17.3827 4.49211C17.3316 4.03976 16.9366 3.70851 16.5023 3.75421Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
