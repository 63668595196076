import { FormikErrors } from 'formik';
import {
  CONTROL_LOGIN_MODAL,
  CLEAR_SNACKBAR_ALERT,
  SNACKBAR_ALERT,
  AlertMessageModel,
  AlertVariant,
  PrimarySideBarMobileType,
  SecondarySideBarType,
  TOGGLE_PRIMARY_SIDEBAR_MOBILE,
  TOGGLE_SECONDARY_SIDEBAR,
  CLEAR_PAGE_CHANGES,
  SET_PAGE_CHANGES,
  ChangeModel,
  PageChangeForm,
  SET_PAGE_CHANGE_ERRORS,
  UN_SET_PAGE_CHANGES,
  PAGE_RELOADING,
  SET_FORM_PAGE_CHANGED,
  SET_PROGRESS_VALUE,
  COMPLETE_PROGRESS,
  RESET_PROGRESS_VALUE,
  TOGGLE_AUTO_SIGNIN,
  RightSideBarType,
  TOGGLE_RIGHT_SIDEBAR,
  UPDATE_SEARCH_VALUE,
  UpdateSearchValueActionType,
  SET_BANNER_DATA,
  ShowBannerActionType,
  ClearBannerActionType,
  CLEAR_BANNER_DATA,
  TOGGLE_HELP_CENTER_MODAL,
  ToggleHelpCenterModalType,
  SET_CAN_SUBMIT_FORM_PAGE,
  SET_CAN_RESET_SETTINGS_FORM_PAGE,
  SET_SECONDARY_SIDEBAR_HIDDEN,
  SAVE_BACK_TO_PORTAL_PAGE,
  SaveBackToPortalPageAction,
  SET_AGREE_PLAN_CANCEL_TERMS,
  EnableNativeFindValueActionType,
  ENABLE_NATIVE_FIND,
  SET_HAS_RECENTLY_VISITED,
} from 'src/store/ui/types';
import { AuthStateTypes } from 'src/constants/authConsts';
import { PLAN_PAGE } from 'src/constants/pathConsts';
import { AppThunkAction } from 'src/store/reduxTypes';
import { PortalConfigUtils } from 'src/utils/PortalUtils';
import UiClient from 'src/clients/UiClients';
import { CallApiWithNotification } from 'src/clients/ApiService';

export const clearAlertMessage = () => ({
  type: CLEAR_SNACKBAR_ALERT,
});

/**
 * This action is used to show snackbar alerts which can
 * be grouped into two categories:
 * Messages alerts: shows simple messages (error/success/info)
 * Progress alerts: shows an async action progress (ex. file upload)
 * @param alertMessage
 * @param isProgress
 */
export const callSnackbar = (
  alertMessage: AlertMessageModel,
  isProgress = false,
) => {
  if (alertMessage && !!alertMessage.successMessage) {
    return {
      type: SNACKBAR_ALERT,
      alertMessage: alertMessage.successMessage,
      alertMessageType: AlertVariant.SUCCESS,
    };
  }
  if (alertMessage && alertMessage.errorMessage) {
    return {
      type: SNACKBAR_ALERT,
      alertMessage: alertMessage.errorMessage,
    };
  }

  if (isProgress) {
    return {
      type: SNACKBAR_ALERT,
      alertMessageType: AlertVariant.PROGRESS,
      alertMessage: alertMessage?.progressMessage,
    };
  }

  const { axiosError } = alertMessage;
  if (axiosError) {
    const { data, status } = axiosError;
    if (data && status) {
      const { message } = data;
      if (message) {
        return { type: SNACKBAR_ALERT, alertMessage: message };
      }
      // List available status
      if (status === 403) {
        return {
          type: SNACKBAR_ALERT,
          alertMessage: `Sorry, you don't have permission to ${
            alertMessage && alertMessage.actionMessage
              ? alertMessage.actionMessage
              : 'do this action'
          }`,
        };
      }
      return {
        type: SNACKBAR_ALERT,
        alertMessage: `Sorry, there was a problem to ${
          alertMessage && alertMessage.actionMessage
            ? alertMessage.actionMessage
            : 'do this action'
        }`,
      };
    }
  }
  return {
    type: SNACKBAR_ALERT,
    alertMessage: 'There was a problem to do this action',
  };
};

/**
 * This action is used to show snackbar alerts which can
 * be grouped into two categories:
 * Messages alerts: shows simple messages (error/success/info)
 * Progress alerts: shows an async action progress (ex. file upload)
 * @param alertMessage
 * @param isProgress
 */
export const alertSnackbar =
  (alertMessage: AlertMessageModel, isProgress = false): AppThunkAction =>
  async (dispatch) => {
    dispatch(clearAlertMessage());
    setTimeout(() => dispatch(callSnackbar(alertMessage, isProgress)), 100);
  };

export const openAuthModal = (authType: AuthStateTypes, nextPath?: string) => ({
  type: CONTROL_LOGIN_MODAL,
  payload: {
    authType,
    nextPath,
  },
});

export const closeAuthModal = () => ({
  type: CONTROL_LOGIN_MODAL,
  payload: {},
});

export const togglePrimarySidebarMobile = (
  params: PrimarySideBarMobileType,
) => ({
  type: TOGGLE_PRIMARY_SIDEBAR_MOBILE,
  payload: params,
});

export const toggleSecondarySidebar = (params: SecondarySideBarType) => ({
  type: TOGGLE_SECONDARY_SIDEBAR,
  payload: params,
});

export const setSecondarySidebarHidden = (hidden: boolean) => ({
  type: SET_SECONDARY_SIDEBAR_HIDDEN,
  payload: hidden,
});

export const toggleRightSidebar = (params: RightSideBarType) => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: params,
});

export const clearPageChanges = () => ({
  type: CLEAR_PAGE_CHANGES,
});

export const setPageReloading = () => ({
  type: PAGE_RELOADING,
});

export const setPageChanges = (changes: ChangeModel[]) => ({
  type: SET_PAGE_CHANGES,
  payload: changes,
});

export const unSetPageChanges = (fields: string[]) => ({
  type: UN_SET_PAGE_CHANGES,
  payload: fields,
});

export const setPageChangeErrors = (errors: FormikErrors<PageChangeForm>) => ({
  type: SET_PAGE_CHANGE_ERRORS,
  payload: errors,
});

export const setAgreePlanCancelTermsAction = (agree: boolean) => ({
  type: SET_AGREE_PLAN_CANCEL_TERMS,
  payload: agree,
});

export const uploadPageChanges =
  (config: any, changedValues: any): AppThunkAction =>
  async (dispatch) => {
    function clear() {
      return { type: CLEAR_PAGE_CHANGES };
    }

    const result = await CallApiWithNotification({
      executeFunction: UiClient.uploadPortalConfig,
      params: {
        config: PortalConfigUtils.MapConfigProperty(changedValues, config),
        source: 'customization',
      },
      successMessage: `Changes have been saved`,
      errorMessage: `These changes could not be saved.`,
      dispatch,
    });

    if (result.status) {
      dispatch(setPageReloading());
      dispatch(clear());
    } else {
      dispatch(clear());
    }
  };

export const setFormPageChanged = (isChanged: boolean) => ({
  type: SET_FORM_PAGE_CHANGED,
  payload: isChanged,
});

export const setCanSubmitFormPage = (canSubmit: boolean) => ({
  type: SET_CAN_SUBMIT_FORM_PAGE,
  payload: canSubmit,
});

export const setCanResetSettingsFormPage = (canReset: boolean) => ({
  type: SET_CAN_RESET_SETTINGS_FORM_PAGE,
  payload: canReset,
});

export const setProgressValue = (fileProgress: number) => ({
  type: SET_PROGRESS_VALUE,
  payload: fileProgress,
});

export const completeProgress = () => ({
  type: COMPLETE_PROGRESS,
});

export const resetProgressValue = () => ({
  type: RESET_PROGRESS_VALUE,
});

export const toggleAutoSignIn =
  (): AppThunkAction => async (dispatch, getState) => {
    const { ui } = getState();
    const toggle = () => ({
      type: TOGGLE_AUTO_SIGNIN,
      payload: !ui.isAutoSignIn,
    });

    dispatch(toggle());
  };

export const updateSearchValueAction = (
  searchValue: string,
): UpdateSearchValueActionType => ({
  type: UPDATE_SEARCH_VALUE,
  payload: searchValue,
});

export const enableNativeFind = (
  enable: boolean,
): EnableNativeFindValueActionType => {
  return {
    type: ENABLE_NATIVE_FIND,
    payload: enable,
  };
};

export const showTrialBannerAction = (
  message: string,
): ShowBannerActionType => ({
  type: SET_BANNER_DATA,
  payload: {
    message,
    buttonOptions: {
      label: 'Select a plan',
      path: PLAN_PAGE.path,
    },
  },
});

export const clearBannerAction = (): ClearBannerActionType => ({
  type: CLEAR_BANNER_DATA,
});

export const toggleHelpCenterModalAction = (
  isOpen: boolean,
): ToggleHelpCenterModalType => ({
  type: TOGGLE_HELP_CENTER_MODAL,
  payload: isOpen,
});

export const saveBackToPortalPage = (
  pagePath: string,
): SaveBackToPortalPageAction => ({
  type: SAVE_BACK_TO_PORTAL_PAGE,
  payload: pagePath,
});

export const setHasRecentlyVisited = (payload: {
  apps: boolean;
  crm: boolean;
}) => ({
  type: SET_HAS_RECENTLY_VISITED,
  payload,
});
