import { useClientsCommandActions } from 'src/hooks/useClientsCommandActions';
import { useCreateCommandAction } from 'src/hooks/useCreateCommandAction';
import { useInviteMemberCommandAction } from 'src/hooks/useInviteCommandAction';

export const GeneralCommandbarActionsInitializer = () => {
  // setup create entity command actions
  useCreateCommandAction();
  /** register view client details command actions */
  useClientsCommandActions();
  // setup invite member command actions
  useInviteMemberCommandAction();

  return null;
};
