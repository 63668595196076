import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Mp4Icon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12V15.6364H5.74574V13.2607H5.77592L6.71697 15.6186H7.22479L8.16584 13.2695H8.19602V15.6364H8.94176V12H7.99361L6.99219 14.4432H6.94957L5.94815 12H5Z"
      fill="black"
    />
    <path
      d="M9.5752 15.6364H10.344V14.4574H10.9903C11.8266 14.4574 12.3131 13.9585 12.3131 13.2322C12.3131 12.5096 11.8355 12 11.0099 12H9.5752V15.6364ZM10.344 13.8413V12.6286H10.8625C11.3064 12.6286 11.5212 12.87 11.5212 13.2322C11.5212 13.5927 11.3064 13.8413 10.866 13.8413H10.344Z"
      fill="black"
    />
    <path
      d="M12.7362 14.9972H14.4815V15.6364H15.2166V14.9972H15.6676V14.381H15.2166V12H14.2543L12.7362 14.3917V14.9972ZM14.4957 14.381H13.5103V14.3526L14.4673 12.8381H14.4957V14.381Z"
      fill="black"
    />
  </SvgIcon>
);
