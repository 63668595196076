/* eslint-disable no-param-reassign */
import { ApiMethods, ApiTags, appAPI } from '.';
import { notify } from 'src/clients/ApiService';
import { DomainVerificationStatus } from 'src/constants';
import { DomainModel, DomainSettingEntity } from 'src/store/domains/types';
import { DomainSettings } from 'src/entities/DomainSettings';

export const domainsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getDomains: build.query<DomainSettings[], void>({
      query: () => ({
        path: '/domains',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {},
        },
      }),
      providesTags: [ApiTags.domains],
    }),
    getEmailDomains: build.query<DomainSettings[], void>({
      query: () => ({
        path: '/domains-email',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {},
        },
      }),
      providesTags: [ApiTags.domains_emails],
    }),
    deleteDomain: build.mutation<any, Pick<DomainModel, 'id'>>({
      query: ({ id }) => ({
        path: `/domains/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          domainsApi.util.updateQueryData('getDomains', undefined, (draft) => {
            const index = draft.findIndex((domain) => domain.id === id);
            draft.splice(index, 1);
          }),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Domain has been deleted',
            dispatch,
          });
        } catch (error) {
          patchResult.undo();
          notify({
            status: 'error',
            errorMessage: 'This domain could not be deleted.',
            error,
            dispatch,
          });
        }
      },
    }),
    deleteEmailDomain: build.mutation<any, Pick<DomainModel, 'id'>>({
      query: ({ id }) => ({
        path: `/domains-email/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          domainsApi.util.updateQueryData(
            'getEmailDomains',
            undefined,
            (draft) => {
              const index = draft.findIndex((domain) => domain.id === id);
              draft.splice(index, 1);
            },
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Domain has been deleted',
            dispatch,
          });
        } catch (error) {
          patchResult.undo();
          notify({
            status: 'error',
            errorMessage: 'This domain could not be deleted.',
            error,
            dispatch,
          });
        }
      },
    }),
    addDomain: build.mutation<any, DomainSettings>({
      query: (body) => ({
        path: '/domains',
        method: ApiMethods.post,
        options: {
          body: { ...body, connectV2: true },
        },
      }),
      invalidatesTags: [ApiTags.domains],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          notify({
            status: 'error',
            errorMessage:
              'This domain could not be added. Contact support for further assistance.',
            error,
            dispatch,
          });
        }
      },
    }),
    addEmailDomain: build.mutation<any, DomainSettings>({
      query: (body) => ({
        path: '/domains-email',
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
      invalidatesTags: [ApiTags.domains_emails],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          notify({
            status: 'error',
            errorMessage:
              'This email domain could not be added. Contact support for further assistance.',
            error,
            dispatch,
          });
        }
      },
    }),
    setVerifiedDomains: build.mutation<any, DomainSettingEntity[]>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(verifiedDomains, { dispatch }) {
        dispatch(
          domainsApi.util.updateQueryData('getDomains', undefined, (draft) => {
            draft.forEach((domainItem) => {
              verifiedDomains.forEach((verifiedDomain) => {
                if (
                  domainItem.domainMetadata &&
                  domainItem.domainMetadata?.domain ===
                    verifiedDomain.fields.domain
                ) {
                  domainItem.domainMetadata.status =
                    DomainVerificationStatus.verified;
                }
              });
            });
          }),
        );
        dispatch(
          domainsApi.util.updateQueryData(
            'getEmailDomains',
            undefined,
            (draft) => {
              draft.forEach((domainItem) => {
                verifiedDomains.forEach((verifiedDomain) => {
                  if (
                    domainItem.emailDomainMetadata &&
                    domainItem.emailDomainMetadata?.emailDomain ===
                      verifiedDomain.structFields?.emailDomain
                  ) {
                    domainItem.emailDomainMetadata.emailDomainStatus =
                      DomainVerificationStatus.verified;
                    // domainItem.isPrimary = true;
                  }
                });
              });
            },
          ),
        );
      },
    }),
  }),
});

export const {
  useGetDomainsQuery,
  useGetEmailDomainsQuery,
  useDeleteDomainMutation,
  useDeleteEmailDomainMutation,
  useAddDomainMutation,
  useAddEmailDomainMutation,
  useSetVerifiedDomainsMutation,
} = domainsApi;
