import React, { useContext } from 'react';
import cx from 'classnames';
import {
  createStyles,
  Theme,
  makeStyles,
  StyledComponentProps,
} from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';
import { SelectProps, TextField } from '@material-ui/core';
import { ChevronDownIcon } from 'src/legacy/components/Icons';

import { GraySmall } from 'src/theme/colors';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { FlagsContext } from 'src/context';

export type TextFieldSizeVariant = 'skinny' | 'medium' | 'tall';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeholder: {
      '& .MuiInputBase-input': {
        color: GraySmall,
      },
    },
    skinny: {
      '& .MuiInputBase-input': {
        '&:not(textarea)': {
          height: '30px',
        },
      },
    },
    medium: {
      '& .MuiInputBase-input': {
        '&:not(textarea)': {
          height: '36px',
        },
      },
    },
    tall: {
      '& .MuiInputBase-input': {
        '&:not(textarea)': {
          height: '48px',
        },
      },
    },
    dropdownIcon: {
      height: '15px',
      width: '15px',
      color: GraySmall,
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(0.75),
    },
  }),
);

export type BaseTextFieldProps = TextFieldProps & {
  sizeVariant?: TextFieldSizeVariant;
  isPlaceholder?: boolean;
  additionalSelectProps?: Partial<SelectProps>;
  classes?: StyledComponentProps['classes'];
};

export const BaseTextField: React.FC<BaseTextFieldProps> = ({
  sizeVariant = 'skinny',
  additionalSelectProps,
  onBlur,
  isPlaceholder,
  onChange,
  className,
  helperText,
  ...textFieldProps
}) => {
  const { EnableInputErrorIcon } = useContext(FlagsContext);
  const classes = useStyles();
  const classNameOptions = React.useMemo(() => {
    const options = {
      [classes.placeholder]: isPlaceholder,
    };
    if (className) {
      options[className] = true;
    }
    return options;
  }, [className, isPlaceholder]);
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const stagedEvent = event;
    // For cases where value may not be simply string, we do not want to call onChange event
    if (
      event.target.value &&
      typeof event.target.value === 'string' &&
      !textFieldProps.select && // No trimming required for dropdowns
      !textFieldProps?.inputProps?.isautocomplete // No trimming for auto-complete controls
    ) {
      stagedEvent.target.value = event.target.value.trim();
      onChange?.(stagedEvent);
    }
    // onBlur to be called regardless
    onBlur?.(stagedEvent);
  };

  return (
    <TextField
      SelectProps={{
        classes: { icon: classes.dropdownIcon },
        IconComponent: ChevronDownIcon,
        ...additionalSelectProps,
      }}
      onBlur={handleBlur}
      onChange={onChange}
      classes={{
        root: classes[sizeVariant],
      }}
      className={cx(classNameOptions)}
      InputProps={{
        ...textFieldProps.InputProps,
        classes: {
          ...textFieldProps.InputProps?.classes,
          root: cx({
            ['[&.Mui-error_fieldset]:!border-error-primary']:
              EnableInputErrorIcon,
          }),
        },
      }}
      {...textFieldProps}
      helperText={
        // Make sure to show the error message if there is one
        // Trim the helper text to make sure blank spaces error is not shown with error icon
        EnableInputErrorIcon &&
        textFieldProps.error &&
        typeof helperText === 'string' &&
        helperText.trim().length > 0 ? (
          <ErrorMessage message={helperText} className="justify-end" />
        ) : (
          helperText
        )
      }
    />
  );
};
