import { TablePropertyFields } from 'src/constants';

export const LOAD_CRM_PROPERTIES_FIELDS = 'LOAD_CRM_PROPERTIES_FIELDS';
export const LOAD_CRM_PROPERTIES_ID = 'LOAD_CRM_PROPERTIES_ID';

export interface TablePropertiesState {
  crmProperties?: TablePropertyFields;
  crmPropertiesId?: string;
}

interface LoadCrmPropertiesFields {
  type: typeof LOAD_CRM_PROPERTIES_FIELDS;
  payload: TablePropertyFields;
}

interface LoadCrmPropertiesId {
  type: typeof LOAD_CRM_PROPERTIES_ID;
  payload: string;
}

export type TablePropertiesActionTypes =
  | LoadCrmPropertiesFields
  | LoadCrmPropertiesId;
