import { Theme } from '@material-ui/core';
import { HoverNonBorderBackground, BlackHeadings } from 'src/theme/colors';

export default {};

export const overrideIconButton = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiIconButton) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiIconButton = {
      root: {
        color: 'currentColor',
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'currentColor',
        },
        borderRadius: theme.shape.borderRadius,
      },
      colorPrimary: {
        color: theme.palette.primary.contrastText,
        '&:hover': {
          color: theme.palette.primary.contrastText,
        },
      },
      colorSecondary: {
        color: BlackHeadings,
        '&:hover': {
          backgroundColor: HoverNonBorderBackground,
        },
      },
      sizeSmall: {
        padding: '4px',
        fontSize: 'inherit',
      },
    };
  }
};
