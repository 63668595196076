import React from 'react';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';

type ChangeableModulesIds =
  | 'knowledgeBase'
  | 'messages'
  | 'files'
  | 'forms'
  | 'contracts';
export const usePageTitle = (
  defaultTitle: string,
  moduleId: ChangeableModulesIds,
) => {
  const { data: { moduleSettings } = {} } = useGetInstallsQuery();

  const pageTitle = React.useMemo(() => {
    if (!moduleSettings || moduleSettings.length === 0) {
      return defaultTitle;
    }
    const pageModule = moduleSettings.find((module) => module.id === moduleId);
    if (!pageModule) {
      return defaultTitle;
    }
    return pageModule.label;
  }, [moduleSettings]);

  return { pageTitle };
};
