import { FieldVisibility, TablePropertyFields } from 'src/constants/dataTypes';
export const DefaultProductColumnSettings: TablePropertyFields = {
  tablePropertyFields: [
    {
      id: 'name',
      name: 'Name',
      disabled: false,
    },
    {
      id: 'price',
      name: 'Price',
      disabled: false,
    },
    {
      id: 'status',
      name: 'Status',
      disabled: false,
    },
  ] as FieldVisibility[],
};
