import { API } from 'src/utils/AmplifyApiUtils';
import { Resource } from 'src/store/data/types';

export default class DataClient {
  static getResources() {
    return API.get('AppAPI', '/entities/resource?includeUsers=true', {});
  }

  static addResources(resource: Resource[]) {
    return API.post('AppAPI', '/entities/resource?includeUsers=true', {
      body: { data: resource },
    });
  }

  static updateResources(resource: Resource[]) {
    return API.put('AppAPI', '/entities/resource', {
      body: { data: resource },
    });
  }

  static deleteResources(id: string) {
    return API.del('AppAPI', '/entities/resource', {
      body: [id],
    });
  }
}
