import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GitBranchIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.25 5.625C7.28553 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 7.28553 1.875 6.25 1.875C5.21447 1.875 4.375 2.71447 4.375 3.75C4.375 4.78553 5.21447 5.625 6.25 5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 18.125C7.28553 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 7.28553 14.375 6.25 14.375C5.21447 14.375 4.375 15.2145 4.375 16.25C4.375 17.2855 5.21447 18.125 6.25 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 14.375V5.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 8.125C14.7855 8.125 15.625 7.28553 15.625 6.25C15.625 5.21447 14.7855 4.375 13.75 4.375C12.7145 4.375 11.875 5.21447 11.875 6.25C11.875 7.28553 12.7145 8.125 13.75 8.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 8.125C13.75 13.125 6.25 10 6.25 14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
