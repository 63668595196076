import React from 'react';
import { getCookie } from 'src/utils/StorageUtils';

/**
 * This hook is used to get the anonymousId that is storage in cookies
 * to connect user from previous session/sites
 * @returns anonymousId
 */
export const useAnonymousId = (): string => {
  const [savedAnonymousId, setSavedAnonymousId] = React.useState<string>('');

  React.useEffect(() => {
    // get anonymousId segment cookie
    const rawAnonymousIdFromCookie = getCookie('ajs_anonymous_id');
    // replace encoded quote sub-string from cookie value
    const anonymousIdFromCookie = rawAnonymousIdFromCookie?.replace(/%22/g, '');
    if (anonymousIdFromCookie) {
      setSavedAnonymousId(anonymousIdFromCookie);
    }
  }, []);

  return savedAnonymousId;
};
