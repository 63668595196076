import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WomanIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.12506 14.375V18.4375C8.12365 18.561 8.14693 18.6836 8.19354 18.7979C8.24016 18.9123 8.30916 19.0162 8.3965 19.1036C8.48384 19.1909 8.58775 19.2599 8.70213 19.3065C8.81651 19.3531 8.93905 19.3764 9.06256 19.375V19.375C9.18607 19.3764 9.30861 19.3531 9.42299 19.3065C9.53737 19.2599 9.64128 19.1909 9.72862 19.1036C9.81596 19.0162 9.88496 18.9123 9.93158 18.7979C9.97819 18.6836 10.0015 18.561 10.0001 18.4375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.0001 14.375V18.4375C9.99865 18.561 10.0219 18.6836 10.0685 18.7979C10.1152 18.9123 10.1842 19.0162 10.2715 19.1036C10.3588 19.1909 10.4627 19.2599 10.5771 19.3065C10.6915 19.3531 10.8141 19.3764 10.9376 19.375C11.0611 19.3764 11.1836 19.3531 11.298 19.3065C11.4124 19.2599 11.5163 19.1909 11.6036 19.1036C11.691 19.0162 11.76 18.9123 11.8066 18.7979C11.8532 18.6836 11.8765 18.561 11.8751 18.4375V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.14839 10.7031C7.11461 10.8219 7.05742 10.9328 6.98018 11.0292C6.90294 11.1256 6.80721 11.2056 6.69862 11.2645C6.59003 11.3234 6.47076 11.36 6.34783 11.3721C6.22489 11.3842 6.10078 11.3717 5.98277 11.3351C5.86395 11.3014 5.75309 11.2442 5.6567 11.1669C5.56031 11.0897 5.48034 10.9939 5.42149 10.8853C5.36264 10.7767 5.3261 10.6575 5.31401 10.5345C5.30193 10.4116 5.31455 10.2875 5.35113 10.1695L6.32769 6.87734C6.43537 6.51542 6.65711 6.19797 6.95986 5.9723C7.2626 5.74663 7.63016 5.62481 8.00777 5.625H11.9921C12.3698 5.62495 12.7373 5.74692 13.04 5.97273C13.3427 6.19855 13.5643 6.51612 13.6718 6.87812L14.6484 10.1703C14.685 10.2883 14.6976 10.4124 14.6855 10.5353C14.6734 10.6583 14.6369 10.7775 14.578 10.8861C14.5192 10.9947 14.4392 11.0905 14.3428 11.1677C14.2464 11.245 14.1356 11.3022 14.0168 11.3359V11.3359C13.8988 11.3721 13.7749 11.3843 13.6522 11.372C13.5295 11.3596 13.4105 11.3229 13.3022 11.2639C13.1938 11.205 13.0983 11.1251 13.0213 11.0288C12.9442 10.9325 12.8872 10.8217 12.8535 10.7031"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 3.75C10.8629 3.75 11.5625 3.05044 11.5625 2.1875C11.5625 1.32456 10.8629 0.625 10 0.625C9.13706 0.625 8.4375 1.32456 8.4375 2.1875C8.4375 3.05044 9.13706 3.75 10 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.125 7.5L6.25 13.75H13.75L11.875 7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
