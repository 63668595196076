import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SubwayIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.75 1.25H6.25C5.21447 1.25 4.375 2.08947 4.375 3.125V13.125C4.375 14.1605 5.21447 15 6.25 15H13.75C14.7855 15 15.625 14.1605 15.625 13.125V3.125C15.625 2.08947 14.7855 1.25 13.75 1.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M8.125 3.125H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 5H5.625C4.93464 5 4.375 5.55964 4.375 6.25V7.5C4.375 8.19036 4.93464 8.75 5.625 8.75H14.375C15.0654 8.75 15.625 8.19036 15.625 7.5V6.25C15.625 5.55964 15.0654 5 14.375 5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 13.125C7.22018 13.125 7.5 12.8452 7.5 12.5C7.5 12.1548 7.22018 11.875 6.875 11.875C6.52982 11.875 6.25 12.1548 6.25 12.5C6.25 12.8452 6.52982 13.125 6.875 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 13.125C13.4702 13.125 13.75 12.8452 13.75 12.5C13.75 12.1548 13.4702 11.875 13.125 11.875C12.7798 11.875 12.5 12.1548 12.5 12.5C12.5 12.8452 12.7798 13.125 13.125 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 18.125H14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 16.875L15 18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 16.875L5 18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
