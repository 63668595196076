import { Theme } from '@material-ui/core';

export default {};

export const overrideInputBase = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiInputBase) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiInputBase.input = {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    };
  }
};
