import React, { useMemo } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InfoOutlined } from '@material-ui/icons';
import { signaturePageContentWidth } from 'src/legacy/components/Signature/SignaturePageContent';
import { Contract, ContractField } from 'src/entities/Contract';
import { FieldType, InputType } from 'src/services/api';
import { RootState } from 'src/store';
import { BlackHeadings, NonHoverBorder, white } from 'src/theme/colors';
import { RegularCardShadow } from 'src/theme/shadows';
import { S3Utils } from 'src/utils';
import {
  ContractDragItemType,
  ContractDraggableItem,
} from '../Signature/ContractDraggableItem';
import {
  SignaturePageComponent,
  SignaturePageImageData,
} from 'src/store/signaturePage/types';
import { useGetContractInputValue } from 'src/hooks/useGetContractInputValue';
import { Callout } from '../Callout';
import MemoBaseTypography from '../Text/BaseTypography';
import { SignaturePageImage } from '../Signature/SignaturePageImage';
import { PDFSkeleton } from '../Skeleton/PDFSkeleton';
import {
  getComponentNameByComponentType,
  getComponentTypeUsingContractType,
} from 'src/utils/ContractUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: signaturePageContentWidth,
    margin: '0px auto',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  page: {
    pointerEvents: 'none',
    marginBottom: 15,
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: RegularCardShadow,
    '& img': {
      width: '100%',
    },
  },
  fieldsContainer: {
    position: 'absolute',
    inset: 0,
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
  },
  signatureImage: {
    height: '100%',
    width: '100%',
    objectFit: 'fill',
  },
  calloutContainer: {
    backgroundColor: white,
    border: `1px solid ${NonHoverBorder}`,
    marginBottom: theme.spacing(2),
  },
  callout: {
    display: 'flex',
    color: BlackHeadings,
    '& svg': {
      height: 14,
      width: 14,
      marginRight: theme.spacing(1),
    },
  },
}));

export const isContractField = (
  field: SignaturePageComponent | null,
): field is SignaturePageComponent => field !== null;

export const PendingContract = ({ contract }: { contract: Contract }) => {
  const classes = useStyles({});
  const [pageImages, setPageImages] = useState<Array<SignaturePageImageData>>(
    [],
  );

  const { clients, companies, customFields } = useSelector(
    (state: RootState) => ({
      clients: state.clients.clients,
      companies: state.clients.companies,
      customFields: state.clients.clientCustomFields?.additionalFields,
    }),
  );

  const getContractFieldValue = useGetContractInputValue(
    contract,
    clients.find((c) => c.id === contract.recipientId),
    companies,
    customFields,
  );

  const handleLoadPageImages = async () => {
    const { pageKeys, identityId } = contract;
    const images = await Promise.all(
      pageKeys.map(async (key, index) => {
        const s3ImageURL = await S3Utils.getFile(key, {
          identityId,
          level: 'protected',
        });
        return {
          key,
          url: s3ImageURL,
          pageNumber: index + 1,
          loaded: false,
        };
      }),
    );

    setPageImages(images);
  };

  useEffect(() => {
    handleLoadPageImages();
  }, []);

  const pageComponents: SignaturePageComponent[] = useMemo(() => {
    const formattedContractFields = (contract.fields || [])
      .map((field: ContractField): SignaturePageComponent | null => {
        const value = getContractFieldValue(field);

        // Remove AutoFill inputs for which the client doesn't have a value
        if (!value && field.inputType === InputType.AutoFill) return null;

        return {
          fieldType: field.type,
          inputType: field.inputType,
          autoFillField: field.autoFillField,
          xPosition: field.bounds.x,
          yPosition: field.bounds.y,
          label: field.label,
          receiverUserId: contract.recipientId,
          height: field.bounds.height,
          width: field.bounds.width,
          isAgree: false,
          key: field.id,
          resizedHeight: field.bounds.resizedHeight,
          resizedWidth: field.bounds.resizedWidth,
          value,
          page: field.page,
          componentType: getComponentTypeUsingContractType(
            field,
            field.inputType === InputType.Client,
          ),
          name: getComponentNameByComponentType(field),
          isOptional: field.isOptional,
          // For image based component the text inputed by user to generate
          // signature image is stored in the "value" key
          imageTextValue:
            field.type === FieldType.Signature ||
            field.type === FieldType.Initials
              ? field.value
              : '',
        };
      })
      .filter(isContractField);

    return formattedContractFields;
  }, [contract]);

  const handlePageImageLoad = (pageImage: SignaturePageImageData) => {
    if (pageImage.loaded) return;

    const pageData = {
      ...pageImage,
      loaded: true,
    };

    const stagedPageImages = pageImages.map((p) => {
      if (p.pageNumber === pageData.pageNumber) {
        return pageData;
      }
      return p;
    });

    setPageImages(stagedPageImages);
  };

  const isPdfLoaded = useMemo(
    () => pageImages.every((pageImage) => pageImage.loaded),
    [pageImages],
  );

  return (
    <div className={classes.root}>
      <Callout hideIcon className={classes.calloutContainer}>
        <MemoBaseTypography fontType="13Regular" className={classes.callout}>
          <div>
            <InfoOutlined />
          </div>
          <div>This contract has not been completed by the client</div>
        </MemoBaseTypography>
      </Callout>
      <div className={classes.container}>
        {!isPdfLoaded && <PDFSkeleton />}
        {pageImages?.length > 0 &&
          pageImages.map((pageImage) => (
            <SignaturePageImage
              pageImage={pageImage}
              handlePageImageLoad={() => handlePageImageLoad(pageImage)}
            />
          ))}

        <div className={classes.fieldsContainer}>
          {pageComponents.length > 0 &&
            pageComponents.map((component) => (
              <ContractDraggableItem
                type={ContractDragItemType.Pending}
                component={component}
                disableDragging
              />
            ))}
        </div>
      </div>
    </div>
  );
};
