import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AlbumsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.3723 6.875H3.62773C3.0049 6.875 2.5 7.3799 2.5 8.00273V15.7473C2.5 16.3701 3.0049 16.875 3.62773 16.875H16.3723C16.9951 16.875 17.5 16.3701 17.5 15.7473V8.00273C17.5 7.3799 16.9951 6.875 16.3723 6.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 3.125H14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.375 5H15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
