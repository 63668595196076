import {
  StreamChat,
  Channel,
  ChannelData,
  ExtendableGenerics,
} from 'stream-chat';
import { ChatContextValue } from 'stream-chat-react';
import { AppThunkAction } from 'src/store/reduxTypes';
import {
  MessagesActionTypes,
  MessageChannelEntity,
} from 'src/store/messages/types';
import MessagesClient from 'src/clients/MessagesClient';
import { removeDuplicates } from 'src/utils/array';
import { GetCurrentUser } from 'src/store/users/reducers';

interface ChannelMetaData extends ChannelData<ExtendableGenerics> {
  name?: string;
  members: string[];
  userPoolId: string;
  companyID?: string;
  singleClientId?: string;
  isGroupType?: boolean;
}

const startInitializeClientAction = () => ({
  type: MessagesActionTypes.INTIALIZE_CLIENT_START,
});

const getStreamClientSetAction = (currentUserId?: string) => ({
  type: MessagesActionTypes.INITIALIZE_CLIENT_DONE,
  currentUserId,
});

export const initializeClientAction =
  (client: StreamChat): AppThunkAction =>
  async (dispatch, getState) => {
    const {
      user: { id: currentUserId, isClient },
      messages: messagesState,
      users: usersState,
    } = getState();

    const currentUser = GetCurrentUser({
      isClient,
      currentUserId,
      usersState,
    });

    if (
      messagesState.clientInitialized ||
      messagesState.clientInitializing ||
      !currentUser
    ) {
      // do no initialize client if it is already initialized or initializing
      // or if current user is not present yet
      return;
    }
    dispatch(startInitializeClientAction());

    let getStreamId = '';
    let token = '';
    try {
      token = await MessagesClient.GetUserToken();
    } catch (error) {
      console.error('failed to get chat user token', error);
    }

    getStreamId = currentUser.getstreamId;
    if (token && getStreamId) {
      try {
        const result = await client.connectUser(
          {
            id: getStreamId,
            name: `${currentUser.fields.givenName} ${currentUser.fields.familyName}`,
            image: currentUser.fields.avatarImageUrl,
            fallbackColor: currentUser.fields.fallbackColor,
          },
          token,
        );
        if (result && result.me) {
          const { unread_count } = result.me;
          dispatch(setTotalUnreadMessages(unread_count));
        }
      } catch (ex) {
        console.error('failed to connect user with chat', ex);
      }

      if (getStreamId) {
        try {
          await MessagesClient.SetUserRole(currentUser.id);
        } catch (error) {
          console.error('failed to set user role', error);
        }
      }
    }

    dispatch(getStreamClientSetAction(getStreamId));
  };

export const createChannel =
  (
    client: StreamChat,
    newChannelData: ChannelData<ExtendableGenerics>,
    setActiveChannel: ChatContextValue['setActiveChannel'],
  ): AppThunkAction =>
  async (dispatch) => {
    function done(channel: Channel) {
      return {
        type: MessagesActionTypes.SET_CREATED_CHANNEL,
        channelId: channel.id,
      };
    }

    const channelId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const newChannel = client.channel('messaging', channelId, newChannelData);
    await newChannel.watch();
    setActiveChannel(newChannel);

    dispatch(done(newChannel));
  };

export const setTotalUnreadMessages = (totalUnreadMessagesCount: number) => ({
  type: MessagesActionTypes.SET_UNREAD_MESSAGES_COUNT,
  payload: totalUnreadMessagesCount,
});

export const addDeletedChannel = (channelID: string) => ({
  type: MessagesActionTypes.ADD_DELETED_CHANNEL,
  payload: channelID,
});

export const setNoLoadedChannel = (notLoaded: boolean) => ({
  type: MessagesActionTypes.SET_NO_CHANNEL_LOADED,
  payload: notLoaded,
});

export const setLoadingChannelError = (error: string) => ({
  type: MessagesActionTypes.SET_LOADING_CHANNEL_ERROR,
  payload: error,
});

export const createInternalChannel =
  (
    client: StreamChat,
    channelItem: MessageChannelEntity,
    portalConfig: any,
    setActiveChannel: ChatContextValue['setActiveChannel'],
    singleClientId?: string,
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const messagesState = getState().messages;
    if (!messagesState.currentUserId) {
      return;
    }
    const mergedMemberIDs = channelItem.fields.getstreamMembers.concat([
      messagesState.currentUserId,
    ]);

    // const formattedMemberIDs = channelItem.fields.members
    //   .map(memberID => getFullUserIdFromUserId(portalConfig, memberID))
    //   .concat([messagesState.currentUserId]);
    const channelMetaData: ChannelMetaData = {
      members: mergedMemberIDs,
      name: channelItem.fields.name,
      userPoolId: portalConfig.portalHeader,
      companyID: channelItem.fields.companyID,
    };
    if (singleClientId) {
      channelMetaData.singleClientId = singleClientId;
    }
    // add group metadata tag if no clientId or companyId is present
    if (!singleClientId && !channelItem.fields.companyID) {
      channelMetaData.isGroupType = true;
    }
    if (channelItem.fields.members.length > 0) {
      await MessagesClient.SetupChannelMembers(
        removeDuplicates(channelItem.fields.members),
      );
    }
    dispatch(createChannel(client, channelMetaData, setActiveChannel));
  };
