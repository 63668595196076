export const predefinedShortcutsIds: Record<string, boolean> = {
  'create-new': true,
  'go-back': true,
  'submit-form': true,
  'focus-chat': true,
  'navigate-previous-tab': true,
  'navigate-next-tab': true,
  search: true,
  'command-bar': true,
};

export const shortcutsDisplayNames: Record<string, string> = {
  Escape: 'Esc',
};

export enum TabNavigationShortcutsValues {
  SELECT_NEXT_KEY = ']',
  SELECT_PREVIOUS_KEY = '[',
}

export enum DeleteShortcutsValues {
  WINDOWS_KEY = 'Delete',
  MAC_OS_KEY = 'Backspace',
}
