import * as Shadows from 'src/theme/shadows';
import * as Borders from 'src/theme/borders';

export default {
  paper: {
    border: Borders.GrayThinBorder,
    boxShadow: Shadows.DropdownMenuShadow,
  },
  list: {
    padding: '4px 0',
  },
};
