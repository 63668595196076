import { Box } from '@material-ui/core';
import React from 'react';
import { PLAN_PAGE, PLAN_PAGE_CANCEL_START } from 'src/constants';
import { RouteContext } from 'src/context';
import { SettingsPageToolbarContext } from 'src/context/settingsPageToolbarContext';
import { useCustomerSubscription } from 'src/hooks/useCustomerSubscription';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { BaseDescription } from 'src/legacy/components/CustomizationForm/BaseDescription';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import CurrentPlan from 'src/legacy/components/Settings/PlanPage/CurrentPlan';

export const SettingsPageToolbar: React.FC = () => {
  const {
    pathname,
    title: pageTitle,
    description,
  } = React.useContext(RouteContext);
  const { toolbarActions } = React.useContext(SettingsPageToolbarContext);
  const { isCancelledPlan } = usePlanStatus();
  const { customerSubscription } = useCustomerSubscription();

  let pageDescription = description;

  // plan settings page description should be calculated based on the current plan
  if (pathname === PLAN_PAGE.path || pathname === PLAN_PAGE_CANCEL_START.path) {
    const label =
      pathname === PLAN_PAGE_CANCEL_START.path
        ? PLAN_PAGE_CANCEL_START.label
        : PLAN_PAGE.label;
    const currentPlanSubscription = isCancelledPlan
      ? undefined
      : customerSubscription;
    return (
      <Box>
        <BaseTypography fontType="24Medium">{label}</BaseTypography>
        <CurrentPlan subscription={currentPlanSubscription} />
      </Box>
    );
  }

  // other settings pages have static title and description
  // that can be defined in the settingsPagePathToSettingsMetaDataMap.
  return (
    <Box
      display="flex"
      width={1}
      justifyContent={toolbarActions ? 'space-between' : 'flex-start'}
      alignItems="center"
    >
      <BaseDescription
        titleFontType="24Medium"
        title={pageTitle}
        descriptions={[pageDescription]}
      />
      {toolbarActions}
    </Box>
  );
};
