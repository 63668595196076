import React, { useMemo } from 'react';
import { Chart } from 'src/legacy/components/HomePage/AnalyticsSection/Chart';
import { useAnalyticsAccess } from 'src/legacy/components/HomePage/AnalyticsSection/useAnalyticsAccess';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import {
  useFetchActiveClientsQuery,
  useFetchClientsQuery,
  useFetchSubscriptionsQuery,
  AggregateResponse,
  AggregateTargets,
  getErrorMessage,
} from 'src/services/api/analyticsApi';
import { ensureApiError } from 'src/utils/Errors';
import { ChartData } from 'src/types/analytics';
import { Body, Heading, Icon } from 'copilot-design-system';
import classNames from 'classnames';

const BUTTON_CLASSES = [
  'appearance-none',
  'border',
  'border-solid',
  'border-secondary-hover',
  'text-left',
  'flex-1',
  'outline-none',
  'px-3',
  'py-2',
  'rounded',
  'transition',
  'hover:bg-secondary-hover',
  'hover:cursor-pointer',
  'overflow-hidden',
  'w-full',
  'md:w-auto',
].join(' ');

function roundToTenth(num: number) {
  return Math.round(num * 10) / 10;
}

// Only exported for testing, broader usage would require a refactor,
// or at least moving to a utils file.
export function getTotalsData(
  tabbedData: Record<AggregateTargets, AggregateResponse | undefined>,
) {
  return (['CLIENT', 'CLIENT_ACTIVATION', 'SUBSCRIPTION'] as const).reduce<
    Record<
      AggregateTargets,
      {
        total: string;
        percentChanged: string;
        growth: 'positive' | 'negative' | 'neutral';
      }
    >
  >(
    (acc, tab) => {
      let total = '-';
      let percentChanged = '';
      let growth: 'positive' | 'negative' | 'neutral' = 'neutral';
      const tabData = tabbedData[tab];
      if (tabData) {
        const { total: beginTotal } = tabData.at(0) ?? {};
        const { total: endTotal } = tabData.at(tabData.length - 1) ?? {};
        if (typeof beginTotal === 'number' && typeof endTotal === 'number') {
          total = `${Intl.NumberFormat('en-us').format(endTotal) ?? '-'}`;
          if (beginTotal === endTotal) {
            percentChanged = '';
          } else if (beginTotal !== 0) {
            percentChanged = `${roundToTenth(
              ((endTotal - beginTotal) / beginTotal) * 100,
            )}%`;
            growth = endTotal > beginTotal ? 'positive' : 'negative';
          }
        }
      }
      acc[tab] = { total, percentChanged, growth };
      return acc;
    },
    {
      CLIENT: { total: '-', percentChanged: '', growth: 'neutral' },
      CLIENT_ACTIVATION: { total: '-', percentChanged: '', growth: 'neutral' },
      SUBSCRIPTION: { total: '-', percentChanged: '', growth: 'neutral' },
    },
  );
}

function getChartData(
  durationDays: number,
  data?: AggregateResponse,
): ChartData[] {
  const end = new Date();
  const start = new Date();
  start.setDate(end.getDate() - (durationDays - 1));
  const dataMap = new Map(
    data?.map((d) => [new Date(d.interval).toISOString().split('T')[0], d]) ??
      [],
  );
  let currentTotal = 0;
  return Array(durationDays)
    .fill(undefined)
    .map((_, i) => {
      const date = new Date(start);
      date.setDate(start.getDate() + i);
      const [key] = date.toISOString().split('T');
      const aggregate = dataMap.get(key);
      if (aggregate) {
        currentTotal = aggregate.total;
      }
      return {
        date,
        total: aggregate?.total ?? currentTotal,
      };
    });
}

export function ActiveState({ days }: { days: number }) {
  const [activeTab, setActiveTab] = React.useState<AggregateTargets>('CLIENT');
  const isVisible = useAnalyticsAccess();
  const { data: clientsData, error: clientsError } = useFetchClientsQuery(
    { days },
    { skip: !isVisible.CLIENT },
  );
  const { data: activeClientsData, error: activeClientsError } =
    useFetchActiveClientsQuery(
      { days },
      {
        skip: !isVisible.CLIENT_ACTIVATION,
      },
    );
  const { data: subscriptionsData, error: subscriptionsError } =
    useFetchSubscriptionsQuery(
      { days },
      {
        skip: !isVisible.SUBSCRIPTION,
      },
    );

  const tabbedData = {
    CLIENT: clientsData,
    CLIENT_ACTIVATION: activeClientsData,
    SUBSCRIPTION: subscriptionsData,
  };
  const data = useMemo(
    () => tabbedData[activeTab],
    [clientsData, activeClientsData, subscriptionsData, activeTab],
  );
  const error = {
    CLIENT: clientsError,
    CLIENT_ACTIVATION: activeClientsError,
    SUBSCRIPTION: subscriptionsError,
  }[activeTab];

  const chartData = useMemo(() => getChartData(days, data), [data]);
  const totals = useMemo(() => getTotalsData(tabbedData), [tabbedData]);

  return (
    <>
      <header className="pb-6 flex items-center gap-3 flex-col md:flex-row">
        {(['CLIENT', 'CLIENT_ACTIVATION', 'SUBSCRIPTION'] as const).map((tab) =>
          !isVisible[tab] ? null : (
            <button
              key={tab}
              className={`${BUTTON_CLASSES} ${
                activeTab === tab ? 'bg-gray-50' : 'bg-transparent'
              }`}
              onClick={() => setActiveTab(tab)}
            >
              <Body className="overflow-hidden text-nowrap text-ellipsis">
                {
                  {
                    CLIENT: 'Clients',
                    CLIENT_ACTIVATION: 'Active clients',
                    SUBSCRIPTION: 'Subscriptions',
                  }[tab]
                }
              </Body>
              <div className="flex flex-row items-center">
                <Heading>{totals[tab].total}</Heading>
                <div
                  className={classNames(
                    {
                      'text-success-primary': totals[tab].growth === 'positive',
                      'text-error-primary': totals[tab].growth === 'negative',
                    },
                    'flex flex-row items-center gap-[2px]',
                  )}
                >
                  {totals[tab].growth === 'neutral' ? null : (
                    <>
                      <Icon
                        icon="ArrowUpSolid"
                        className={classNames('ml-1 h-3 text-current', {
                          'rotate-180': totals[tab].growth === 'negative',
                        })}
                      />
                      <Body size="sm" className="text-current">
                        {totals[tab].percentChanged}
                      </Body>
                    </>
                  )}
                </div>
              </div>
            </button>
          ),
        )}
      </header>

      {error ? (
        <BaseTypography fontType="15Regular">
          {getErrorMessage(ensureApiError(error).message)}
        </BaseTypography>
      ) : (
        <div
          className={classNames({
            'text-success-primary': totals[activeTab].growth === 'positive',
            'text-error-primary': totals[activeTab].growth === 'negative',
          })}
        >
          <Chart data={chartData} />
        </div>
      )}
    </>
  );
}
