import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PdfIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="#212B36"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="#212B36"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15.6364H5.87891V14.5071H6.4755C7.30824 14.5071 7.82848 14.0135 7.82848 13.2589C7.82848 12.5114 7.31889 12 6.50213 12H5V15.6364ZM5.87891 13.8146V12.7085H6.31214C6.70632 12.7085 6.91761 12.9215 6.91761 13.2589C6.91761 13.5945 6.70632 13.8146 6.31214 13.8146H5.87891Z"
      fill="#212B36"
    />
    <path
      d="M9.60582 15.6364C10.7138 15.6364 11.4009 14.9528 11.4009 13.8164C11.4009 12.6818 10.7138 12 9.60405 12H8.26172V15.6364H9.60582ZM9.14062 14.8871V12.7493H9.55966C10.1847 12.7493 10.5202 13.0334 10.5202 13.8164C10.5202 14.603 10.1847 14.8871 9.57209 14.8871H9.14062Z"
      fill="#212B36"
    />
    <path
      d="M11.9141 15.6364H12.793V14.1751H14.2383V13.4595H12.793V12.7138H14.3963V12H11.9141V15.6364Z"
      fill="#212B36"
    />
  </SvgIcon>
);
