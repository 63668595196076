import { BaseEntity } from 'src/constants';

export interface Tag extends BaseEntity {
  structFields: TagStructFields;
}

export interface TagStructFields {
  label?: string;
  visibility?: TagVisibility;
}

export interface TagVisibility {
  filterType?: string;
  filterIDValues?: string[];
}

export interface TagQueryParamProps {
  selectedTag?: string;
}

export const LOAD_TAGS_REQUEST = 'LOAD_TAGS_REQUEST';
export const LOAD_TAGS_DONE = 'LOAD_TAGS_DONE';
export const LOAD_TAGS_FAIL = 'LOAD_TAGS_FAIL';
export const CREATE_TAGS_REQUEST = 'CREATE_TAGS_REQUEST';
export const CREATE_TAGS_DONE = 'CREATE_TAGS_DONE';
export const CREATE_TAGS_FAIL = 'CREATE_TAGS_FAIL';
export const UPDATE_TAGS_REQUEST = 'UPDATE_TAGS_REQUEST';
export const UPDATE_TAGS_DONE = 'UPDATE_TAGS_DONE';
export const UPDATE_TAGS_FAIL = 'UPDATE_TAGS_FAIL';
export const DELETE_TAGS_REQUEST = 'DELETE_TAGS_REQUEST';
export const DELETE_TAGS_DONE = 'DELETE_TAGS_DONE';
export const DELETE_TAGS_FAIL = 'DELETE_TAGS_FAIL';

export const defaultVisibilityFormData = {
  filterType: 'client',
  filterIDValues: [],
};

export interface TagsState {
  tags: Tag[];
  isLoading: boolean;
  isLoaded: boolean;
  isUpdating: boolean;
  error?: string;
}

interface OptimisticPayload {
  isOptimistic: boolean;
  tags: Tag[];
}

interface StartLoadTagsAction {
  type: typeof LOAD_TAGS_REQUEST;
}

interface LoadTagsDoneAction {
  type: typeof LOAD_TAGS_DONE;
  payload: Tag[];
}

interface LoadTagsFailAction {
  type: typeof LOAD_TAGS_FAIL;
  error: string;
}

interface CreateTagsRequestAction {
  type: typeof CREATE_TAGS_REQUEST;
  payload: OptimisticPayload;
}

interface CreateTagsDoneAction {
  type: typeof CREATE_TAGS_DONE;
  payload: Tag[];
}

interface CreateTagsFailAction {
  type: typeof CREATE_TAGS_FAIL;
  error: string;
}

interface UpdateTagsRequestAction {
  type: typeof UPDATE_TAGS_REQUEST;
  payload: OptimisticPayload;
}

interface UpdateTagsDoneAction {
  type: typeof UPDATE_TAGS_DONE;
  payload: Tag[];
}

interface UpdateTagsFailAction {
  type: typeof UPDATE_TAGS_FAIL;
  error: string;
}

interface DeleteTagsRequestAction {
  type: typeof DELETE_TAGS_REQUEST;
  payload: OptimisticPayload;
}

interface DeleteTagsDoneAction {
  type: typeof DELETE_TAGS_DONE;
  payload: Tag[];
}

interface DeleteTagsFailAction {
  type: typeof DELETE_TAGS_FAIL;
  error: string;
}

export type TagsActionTypes =
  | StartLoadTagsAction
  | LoadTagsDoneAction
  | LoadTagsFailAction
  | CreateTagsRequestAction
  | CreateTagsDoneAction
  | CreateTagsFailAction
  | UpdateTagsRequestAction
  | UpdateTagsDoneAction
  | UpdateTagsFailAction
  | DeleteTagsRequestAction
  | DeleteTagsDoneAction
  | DeleteTagsFailAction;
