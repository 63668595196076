import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ListCircleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.75 7.1875H13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 10H13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 12.7734H13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 10.0781C17.5 5.9375 14.1406 2.57812 10 2.57812C5.85938 2.57812 2.5 5.9375 2.5 10.0781C2.5 14.2188 5.85938 17.5781 10 17.5781C14.1406 17.5781 17.5 14.2188 17.5 10.0781Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M6.5625 7.5C6.73509 7.5 6.875 7.36009 6.875 7.1875C6.875 7.01491 6.73509 6.875 6.5625 6.875C6.38991 6.875 6.25 7.01491 6.25 7.1875C6.25 7.36009 6.38991 7.5 6.5625 7.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5625 10.3516C6.73509 10.3516 6.875 10.2117 6.875 10.0391C6.875 9.86647 6.73509 9.72656 6.5625 9.72656C6.38991 9.72656 6.25 9.86647 6.25 10.0391C6.25 10.2117 6.38991 10.3516 6.5625 10.3516Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5625 13.125C6.73509 13.125 6.875 12.9851 6.875 12.8125C6.875 12.6399 6.73509 12.5 6.5625 12.5C6.38991 12.5 6.25 12.6399 6.25 12.8125C6.25 12.9851 6.38991 13.125 6.5625 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
