import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CashIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.875 13.125L18.125 13.125C18.4702 13.125 18.75 12.8452 18.75 12.5V3.75C18.75 3.40482 18.4702 3.125 18.125 3.125L1.875 3.125C1.52982 3.125 1.25 3.40482 1.25 3.75V12.5C1.25 12.8452 1.52982 13.125 1.875 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 15H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 16.875H16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11.25C11.7259 11.25 13.125 9.85089 13.125 8.125C13.125 6.39911 11.7259 5 10 5C8.27411 5 6.875 6.39911 6.875 8.125C6.875 9.85089 8.27411 11.25 10 11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 6.25C17.9212 6.25 17.1263 5.92076 16.5403 5.33471C15.9542 4.74866 15.625 3.9538 15.625 3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 6.25C1.66038 6.25 2.06674 6.16917 2.44589 6.01212C2.82503 5.85508 3.16953 5.62489 3.45971 5.33471C3.74989 5.04453 3.98008 4.70003 4.13712 4.32089C4.29417 3.94174 4.375 3.53538 4.375 3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 10C17.9212 10 17.1263 10.3292 16.5403 10.9153C15.9542 11.5013 15.625 12.2962 15.625 13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 10C1.66038 10 2.06674 10.0808 2.44589 10.2379C2.82503 10.3949 3.16953 10.6251 3.45971 10.9153C3.74989 11.2055 3.98008 11.55 4.13712 11.9291C4.29417 12.3083 4.375 12.7146 4.375 13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
