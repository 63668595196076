import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const XlxIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.89311 12L6.62642 13.2393H6.65483L7.39169 12H8.25994L7.15021 13.8182L8.2848 15.6364H7.40057L6.65483 14.3952H6.62642L5.88068 15.6364H5L6.13814 13.8182L5.02131 12H5.89311Z"
      fill="currentColor"
    />
    <path
      d="M8.74645 15.6364V12H9.51527V15.0025H11.0742V15.6364H8.74645Z"
      fill="currentColor"
    />
    <path
      d="M12.3042 12L13.0376 13.2393H13.066L13.8028 12H14.6711L13.5613 13.8182L14.6959 15.6364H13.8117L13.066 14.3952H13.0376L12.2918 15.6364H11.4111L12.5493 13.8182L11.4324 12H12.3042Z"
      fill="currentColor"
    />
  </SvgIcon>
);
