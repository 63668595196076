import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BowlingBallIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M11.25 8.75C11.7678 8.75 12.1875 8.33027 12.1875 7.8125C12.1875 7.29473 11.7678 6.875 11.25 6.875C10.7322 6.875 10.3125 7.29473 10.3125 7.8125C10.3125 8.33027 10.7322 8.75 11.25 8.75Z"
      fill="currentColor"
    />
    <path
      d="M11.5625 5.9375C12.0803 5.9375 12.5 5.51777 12.5 5C12.5 4.48223 12.0803 4.0625 11.5625 4.0625C11.0447 4.0625 10.625 4.48223 10.625 5C10.625 5.51777 11.0447 5.9375 11.5625 5.9375Z"
      fill="currentColor"
    />
    <path
      d="M14.0625 7.5C14.5803 7.5 15 7.08027 15 6.5625C15 6.04473 14.5803 5.625 14.0625 5.625C13.5447 5.625 13.125 6.04473 13.125 6.5625C13.125 7.08027 13.5447 7.5 14.0625 7.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
