import { FieldVisibility, TablePropertyFields } from 'src/constants/dataTypes';

export const DefaultIntakeFormColumnSettings: TablePropertyFields = {
  tablePropertyFields: [
    {
      id: 'name',
      name: 'Name',
      disabled: false,
    },
    {
      id: 'creator',
      name: 'Created by',
      disabled: false,
    },
    {
      id: 'responseCount',
      name: 'Responses',
      disabled: false,
    },
    {
      id: 'waitForResponse',
      name: 'Waiting for response',
      disabled: false,
    },
    {
      id: 'latestResponse',
      name: 'Latest response',
      disabled: false,
    },
    {
      id: 'createdAt',
      name: 'Creation date',
      disabled: true, // creation date prop should be hidden by default
    },
  ] as FieldVisibility[],
};
