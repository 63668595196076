import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FolderOpenIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M2.5 7.5V4.6875C2.5 4.2731 2.66462 3.87567 2.95765 3.58265C3.25067 3.28962 3.6481 3.125 4.0625 3.125H7.02695C7.33546 3.12501 7.63707 3.21635 7.89375 3.3875L8.98125 4.1125C9.23793 4.28365 9.53954 4.37499 9.84805 4.375H15.9375C16.3519 4.375 16.7493 4.53962 17.0424 4.83265C17.3354 5.12567 17.5 5.5231 17.5 5.9375V7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7462 8.84961L18.1126 15.3125C18.1126 15.7264 17.9483 16.1234 17.6559 16.4164C17.3635 16.7093 16.9667 16.8743 16.5528 16.875H3.44733C3.03341 16.8743 2.63668 16.7093 2.34425 16.4164C2.05181 16.1234 1.88757 15.7264 1.88757 15.3125L1.25398 8.84961C1.24022 8.67761 1.26224 8.50462 1.31862 8.34154C1.37501 8.17846 1.46454 8.02882 1.5816 7.90204C1.69865 7.77526 1.84069 7.67409 1.99876 7.6049C2.15683 7.53571 2.32752 7.49999 2.50007 7.5H17.504C17.6762 7.50053 17.8465 7.53664 18.0041 7.60607C18.1617 7.6755 18.3032 7.77675 18.4199 7.90347C18.5365 8.03019 18.6257 8.17965 18.6818 8.34246C18.738 8.50527 18.7599 8.67793 18.7462 8.84961Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
