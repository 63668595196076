import { createStyles, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { StepperPageContext } from 'src/context';
import Button from 'src/legacy/components/Button';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { StepNavigationActions } from 'src/legacy/components/Layout/StepperLayout/StepNavigationActions';
import { StepperProgress } from 'src/legacy/components/Layout/StepperLayout/StepperProgress';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);
export const StepperPageHeader: React.FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  const classes = useStyles();
  const { pageTitle, showStepper, activeStepIndex } =
    useContext(StepperPageContext);

  return (
    <div className={classes.root}>
      <BaseTypography data-testid="stepper-page-title" fontType="13Medium">
        {pageTitle}
      </BaseTypography>
      {
        showStepper && (
          <StepperProgress />
        ) /* when showStepper is true, show the stepper progress bar */
      }
      {activeStepIndex > 0 ? (
        <StepNavigationActions /> // only show the navigation actions when it is not the first step
      ) : (
        <Button
          htmlId="cancel-button"
          variant="contained"
          color="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};
