import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CutIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M4.0625 8.125C5.27062 8.125 6.25 7.14562 6.25 5.9375C6.25 4.72938 5.27062 3.75 4.0625 3.75C2.85438 3.75 1.875 4.72938 1.875 5.9375C1.875 7.14562 2.85438 8.125 4.0625 8.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.0625 16.25C5.27062 16.25 6.25 15.2706 6.25 14.0625C6.25 12.8544 5.27062 11.875 4.0625 11.875C2.85438 11.875 1.875 12.8544 1.875 14.0625C1.875 15.2706 2.85438 16.25 4.0625 16.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.13281 6.83594L5.70312 7.42188L7.14844 8.00781C7.14844 8.00781 7.28359 7.75703 7.42188 7.61719L6.13281 6.83594Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.02246 13.0636L17.9689 6.3281C17.8713 6.06638 16.8752 5.85935 15.4689 6.17185C14.3314 6.42458 10.7361 7.30661 9.25176 8.3613C8.04121 9.22107 8.86738 10.625 7.5002 11.6406C6.4998 12.3828 5.76465 12.532 5.76465 12.532L6.02246 13.0636Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.4559 10.8687L11.5234 11.9792C12.0793 12.2425 14.0836 13.2788 16.0938 13.3983C16.6766 13.4331 17.2656 13.4136 17.6172 13.164L13.4559 10.8687Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 10.625C10.6904 10.625 11.25 10.0654 11.25 9.375C11.25 8.68464 10.6904 8.125 10 8.125C9.30964 8.125 8.75 8.68464 8.75 9.375C8.75 10.0654 9.30964 10.625 10 10.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
