import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CameraIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.693 5.80781L12.6531 4.16484C12.434 3.90938 12.1336 3.75 11.7969 3.75H8.20312C7.86641 3.75 7.56602 3.90938 7.34688 4.16484L6.30703 5.80781C6.08789 6.06367 5.80547 6.25 5.46875 6.25H3.125C2.79348 6.25 2.47554 6.3817 2.24112 6.61612C2.0067 6.85054 1.875 7.16848 1.875 7.5V15C1.875 15.3315 2.0067 15.6495 2.24112 15.8839C2.47554 16.1183 2.79348 16.25 3.125 16.25H16.875C17.2065 16.25 17.5245 16.1183 17.7589 15.8839C17.9933 15.6495 18.125 15.3315 18.125 15V7.5C18.125 7.16848 17.9933 6.85054 17.7589 6.61612C17.5245 6.3817 17.2065 6.25 16.875 6.25H14.5703C14.2324 6.25 13.9121 6.06367 13.693 5.80781Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.75C11.7259 13.75 13.125 12.3509 13.125 10.625C13.125 8.89911 11.7259 7.5 10 7.5C8.27411 7.5 6.875 8.89911 6.875 10.625C6.875 12.3509 8.27411 13.75 10 13.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M4.84375 6.17188V5.3125H3.90625V6.17188"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
