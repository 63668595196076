import React, { CSSProperties, useState } from 'react';
import {
  Button as MaterialBtn,
  ButtonProps,
  CircularProgress,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import classNames from 'classnames';

export type ButtonModel = {
  htmlId: string;
  isLoading?: boolean;
  progressVariant?: 'determinate' | 'indeterminate' | 'static';
  progressColor?: 'primary' | 'secondary' | 'inherit';
  progressValue?: number;
  progressSize?: number;
  progressStyles?: CSSProperties;
  component?: string;
  className?: string;
  containerClass?: string;
};

interface StyleProps {
  isLoading: boolean;
  progressSize: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    buttonContainer: {
      pointerEvents: (props: StyleProps) => (props.isLoading ? 'none' : 'auto'),
    },
    buttonContent: {
      opacity: (props: StyleProps) => (props.isLoading ? 0 : 'initial'),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: (props: StyleProps) => `-${props.progressSize / 2}px`,
      marginLeft: (props: StyleProps) => `-${props.progressSize / 2}px`,
    },
  }),
);

type ButtonComponent = 'button' | 'span';

const Button: React.FC<ButtonProps & ButtonModel> = (props) => {
  const [isAsyncClick, setIsAsyncClick] = useState<boolean>(false);

  const {
    children,
    isLoading = false,
    progressVariant = 'indeterminate',
    progressColor = 'secondary',
    progressValue = 0,
    progressStyles,
    progressSize = 18,
    onClick,
    disabled,
    htmlId,
    component = 'button',
    className,
    containerClass = '',
    ...rest
  } = props;

  const classes = useStyles({ isLoading, progressSize });
  // cases when we call async inside button click
  // e.g. onClick={async () => { await onClickHandler(); }}
  const onClickHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (isLoading) {
      return;
    }
    const click = onClick as any;
    if (!click || isAsyncClick) {
      return;
    }
    setIsAsyncClick(true);
    await click(event);
    setIsAsyncClick(false);
  };

  if (htmlId) {
    rest.id = htmlId;
  }

  return (
    <div
      className={classNames(classes.root, {
        [containerClass]: Boolean(containerClass),
      })}
    >
      <MaterialBtn
        {...rest}
        className={className}
        disabled={disabled}
        onClick={onClickHandler}
        component={component as ButtonComponent}
        classes={{ root: classes.buttonContainer }}
      >
        <div className={classes.buttonContent}>{children}</div>
      </MaterialBtn>
      {isLoading ? (
        <CircularProgress
          variant={progressVariant}
          color={progressColor}
          value={progressValue}
          style={{ ...progressStyles }}
          size={progressSize}
          className={classes.buttonProgress}
          data-testid={`${htmlId}-progress`}
        />
      ) : null}
    </div>
  );
};

export default Button;
