import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrophyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.875 18.125H13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18.125V13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9996 8.75C14.9996 6.77188 14.9965 3.49102 14.9949 2.5C14.9949 2.33424 14.9291 2.17527 14.8119 2.05806C14.6947 1.94085 14.5357 1.875 14.3699 1.875L5.62812 1.88516C5.4627 1.88516 5.30403 1.95074 5.18687 2.06752C5.06972 2.18431 5.00364 2.34278 5.00312 2.5082C5.00312 3.70273 4.99805 7.55391 4.99805 8.75C4.99805 11.2609 8.24023 13.125 9.99805 13.125C11.7559 13.125 14.9996 11.2609 14.9996 8.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 3.75H1.875V4.375C1.875 6.53203 3.18555 8.75 5 8.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 3.75H18.125V4.375C18.125 6.53203 16.8145 8.75 15 8.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
