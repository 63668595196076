import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrashIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.91797 1.875H11.5781C11.6836 1.875 11.7812 1.92578 11.8398 2.01562L12.582 3.125H6.91797L7.66016 2.01562C7.71875 1.92969 7.81641 1.875 7.92188 1.875H7.91797ZM14.8359 3.125L13.4023 0.972656C12.9961 0.367188 12.3125 0 11.582 0H7.91797C7.1875 0 6.50391 0.367188 6.09766 0.972656L4.66406 3.125H4.12891H2.25H1.9375C1.41797 3.125 1 3.54297 1 4.0625C1 4.58203 1.41797 5 1.9375 5H2.39062L3.32812 17.6836C3.42578 18.9883 4.51172 20 5.82031 20H13.6758C14.9844 20 16.0703 18.9883 16.168 17.6836L17.1094 5H17.5625C18.082 5 18.5 4.58203 18.5 4.0625C18.5 3.54297 18.082 3.125 17.5625 3.125H17.25H15.3711H14.8359ZM15.2305 5L14.3008 17.5469C14.2773 17.875 14.0039 18.125 13.6758 18.125H5.82031C5.49219 18.125 5.22266 17.8711 5.19531 17.5469L4.26953 5H15.2305Z"
      fill="currentColor"
    />
  </SvgIcon>
);
