import React from 'react';
import { useAppSelector } from 'src/hooks/useStore';
import { ShortcutSidebarSection } from 'src/legacy/components/ShortcutsDrawer/shortcutsDrawerTypes';
import { useRouteAccess } from 'src/routes/routeAccess';

export const useShortcutsSections = () => {
  const [isMacOs, setIsMacOs] = React.useState(false);

  const modKeyLabel = isMacOs ? '⌘ Command' : 'Ctrl';
  const routeAccess = useRouteAccess();
  const isClient = useAppSelector((state) => state.user.isClient);
  const availableClients = useAppSelector(
    (state) => state.clients.activeClients,
  );
  /**
   * This method calculate the second shortcut key
   * for a given module nav item.
   * @param moduleId
   * @returns
   */

  /**
   * When user does not have access to client details page
   * when it has a tab ui pattern, it does not make sense to
   * show the shortcut text for tab navigation.
   */
  const tabsShortcutNavigationText = React.useMemo(
    () => ({
      right: routeAccess.allowClientDetailsPage
        ? 'or right client details tab'
        : '',
      left: routeAccess.allowClientDetailsPage
        ? 'or left client details tab'
        : '',
    }),
    [routeAccess],
  );

  const shortcutsSections: Array<ShortcutSidebarSection> = React.useMemo(
    () =>
      [
        {
          id: 'section-general',
          sectionName: 'General',
          shortcuts: [
            ...(!isClient
              ? [
                  {
                    id: 'command-bar',
                    label: 'Command bar',
                    keys: [modKeyLabel, 'K'],
                  },
                  {
                    id: 'search',
                    label: 'Search',
                    keys: ['/'],
                  },
                ]
              : []),
            {
              id: 'submit-form',
              label: 'Submit',
              keys: [modKeyLabel, 'Enter'],
            },
            {
              id: 'go-back',
              label: 'Go back',
              keys: ['Escape'],
              tooltipText: '',
            },
            ...(!isClient
              ? [
                  {
                    id: 'create-new',
                    label: 'Create...',
                    keys: ['C'],
                  },
                ]
              : []),

            ...(!isClient && availableClients.length
              ? [
                  {
                    id: 'open-client',
                    label: 'Open client...',
                    keys: ['O'],
                  },
                ]
              : []),
            ...(routeAccess.allowMessagesPage
              ? [
                  {
                    id: 'focus-chat',
                    label: 'Focus chat',
                    keys: [modKeyLabel, '/'],
                  },
                ]
              : []),

            ...(routeAccess.allowClientDetailsPage ||
            routeAccess.allowMessagesPage
              ? [
                  {
                    id: 'navigate-previous-tab',
                    label: `Select above channel ${tabsShortcutNavigationText.left}`,
                    keys: [modKeyLabel, '['],
                  },
                  {
                    id: 'navigate-next-tab',
                    label: `Select below channel ${tabsShortcutNavigationText.right}`,
                    keys: [modKeyLabel, ']'],
                  },
                ]
              : []),
          ],
        },
      ].filter(Boolean),
    [
      modKeyLabel,
      routeAccess,
      tabsShortcutNavigationText,
      availableClients,
      isClient,
    ],
  );

  React.useEffect(() => {
    const isMac = navigator.platform.toLowerCase().includes('mac');
    setIsMacOs(isMac);
  }, []);

  return shortcutsSections;
};
