import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { FileUtils } from 'src/utils';
import {
  completeProgress,
  setProgressValue,
  resetProgressValue,
  alertSnackbar,
} from 'src/store/ui/actions';

export interface UploadProgressProps {
  totalSize: number;
  loaded: Array<number>;
}

export const useProgressUpload = () => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const setUploadProgress = (fileProgress: UploadProgressProps) => {
    const filesLoaded = FileUtils.calculateUploadProgress(
      fileProgress.totalSize,
      fileProgress.loaded,
    );

    dispatch(setProgressValue(filesLoaded));
    if (filesLoaded === 100) {
      dispatch(completeProgress());
      setIsUploading(false);
    }
  };

  const startUploadFiles = () => {
    setIsUploading(true);
    dispatch(resetProgressValue());
    dispatch(alertSnackbar({ progressMessage: 'Uploading files' }, true));
  };

  const exitUploadFiles = () => {
    setIsUploading(false);
    dispatch(completeProgress());
  };

  return { setUploadProgress, startUploadFiles, exitUploadFiles, isUploading };
};
