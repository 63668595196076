import { Dispatch } from 'redux';
import { Events } from 'src/constants/webSocketConsts/events';
import { ContractTemplate, contractsApi } from 'src/services/api/contractsApi';
import { ensureUnreachable } from 'src/utils/common_utils';

export function handleContractTemplateUpdate(
  event: Events,
  items: ContractTemplate[],
  dispatch: Dispatch<any>,
) {
  const [newContractTemplate] = items;

  switch (event) {
    case Events.Insert: {
      dispatch(
        contractsApi.util.updateQueryData(
          'getContractTemplates',
          undefined,
          (draft?: ContractTemplate[]) => {
            (draft ?? []).push(newContractTemplate);
          },
        ),
      );
      return;
    }
    case Events.Remove: {
      dispatch(
        contractsApi.util.updateQueryData(
          'getContractTemplates',
          undefined,
          (draft?: ContractTemplate[]) => {
            (draft ?? []).filter((item) => item.id !== newContractTemplate.id);
          },
        ),
      );
      return;
    }
    case Events.Modify: {
      dispatch(
        contractsApi.util.updateQueryData(
          'getContractTemplates',
          undefined,
          (draft?: ContractTemplate[]) => {
            const oldContractTemplate = (draft ?? []).find(
              (item) => item.id === newContractTemplate.id,
            );
            if (oldContractTemplate) {
              Object.assign(oldContractTemplate, newContractTemplate);
            }
          },
        ),
      );
      return;
    }
    case Events.Refresh:
    case Events.DomainVerified:
      return;
    default:
      ensureUnreachable(event);
  }
}
