import {
  Resource,
  LOAD_RESOURCES_REQUEST,
  LOAD_RESOURCES_DONE,
  LOAD_RESOURCES_FAIL,
  ADD_RESOURCES_DONE,
  ADD_RESOURCES_FAIL,
  ADD_RESOURCES_REQUEST,
  UPDATE_RESOURCES_DONE,
  UPDATE_RESOURCES_REQUEST,
  UPDATE_RESOURCES_FAIL,
  DELETE_RESOURCES_FAIL,
  DELETE_RESOURCES_DONE,
  DELETE_RESOURCES_REQUEST,
  ADD_TAGS,
  AddTagsAction,
  LoadResourcesDoneAction,
} from 'src/store/data/types';
import { AppThunkAction } from 'src/store/reduxTypes';
import { CallApiWithNotification } from 'src/clients/ApiService';
import ApiUtils from 'src/utils/ApiUtils';
import DataClient from 'src/clients/DataClient';
import { ensureError } from 'src/utils/Errors';

export const setResourcesAction = (
  resources: Resource[],
): LoadResourcesDoneAction => ({
  type: LOAD_RESOURCES_DONE,
  resources,
  users: [],
});

export const loadResource = (): AppThunkAction => async (dispatch) => {
  function request() {
    return { type: LOAD_RESOURCES_REQUEST };
  }

  function failure(error: string) {
    return { type: LOAD_RESOURCES_FAIL, error };
  }

  dispatch(request());

  try {
    const result = await DataClient.getResources();

    dispatch(setResourcesAction(result));
  } catch (ex) {
    const { message } = ensureError(ex);
    dispatch(failure(message));
  }
};

export const saveResources =
  (items: Resource): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: ADD_RESOURCES_REQUEST };
    }

    function success(payload: Resource[]) {
      return { type: ADD_RESOURCES_DONE, payload };
    }

    function failure(error: string) {
      return { type: ADD_RESOURCES_FAIL, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: DataClient.addResources,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: [items],
      successMessage: `The Resource has been created!`,
      errorMessage: `We were unable to create the Resource.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data.createdItems));
    } else {
      dispatch(failure(result.data));
    }
  };

export const updateResources =
  (items: Resource): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: UPDATE_RESOURCES_REQUEST };
    }

    function success(payload: Resource) {
      return { type: UPDATE_RESOURCES_DONE, payload };
    }

    function failure(error: string) {
      return { type: UPDATE_RESOURCES_FAIL, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: DataClient.updateResources,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: [items],
      successMessage: `The Resource has been updated!`,
      errorMessage: `We were unable to update the Resource.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(items));
    } else {
      dispatch(failure(result.data));
    }
  };

export const deleteResources =
  (id: string): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: DELETE_RESOURCES_REQUEST };
    }

    function success(payload: string) {
      return { type: DELETE_RESOURCES_DONE, payload };
    }

    function failure(error: string) {
      return { type: DELETE_RESOURCES_FAIL, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: DataClient.deleteResources,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: id,
      successMessage: `The Resource has been deleted!`,
      errorMessage: `We were unable to delete the Resource.`,
      dispatch,
    });
    if (result.status) {
      dispatch(success(id));
    } else {
      dispatch(failure(result.data));
    }
  };

export const addTags = (tags: string[]): AddTagsAction => ({
  type: ADD_TAGS,
  tags,
});
