import React from 'react';
import { AlertCircleIcon } from 'src/legacy/components/Icons';

import StandardPage from 'src/legacy/components/UI/StandardPage';

const params = {
  title: 'Page disabled!',
  titleForClient: 'Page disabled!',
  topIcon: <AlertCircleIcon />,
  descForClient:
    'The page you’re trying to reach has been disabled, please get in touch if this is a mistake.',
  desc: 'The page you’re trying to reach has been disabled by the workspace owner, please get in touch if this is a mistake.',
};

export const NotAllowedPage: React.FC = () => <StandardPage {...params} />;
