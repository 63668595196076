import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Provider as ReduxProvider } from 'react-redux';
import {
  RouteContext,
  PortalConfigContext,
  SessionData,
  FlagsContext,
  FlagContextType,
} from 'src/context';
import { ConfigureAuth } from 'src/legacy/components/Auth/ConfigureAuth';
import Notifier from 'src/legacy/components/AlertSnackbar/Notifier';
import { AppProviders } from 'src/legacy/components/App/AppProviders';

interface UnauthAppProps {
  context: {
    insertCss: (...styles: any[]) => () => void;
    pathname: string;
    query: Record<string, any>;
    isMobile: boolean;
    portalConfig: any;
    sessionData: SessionData;
    store: any;
    flags: FlagContextType;
    stripeContext: { publishableKey: string };
  };
  children: React.ReactNode;
}

const UnauthApp: React.FC<UnauthAppProps> = ({ context, children }) => {
  // NOTE: If you need to add or modify header, footer etc. of the app,
  // please do that inside the Layout component.
  const {
    insertCss,
    pathname,
    query,
    isMobile,
    portalConfig,
    sessionData,
    store,
    flags,
  } = context;

  const routeContext = React.useMemo(
    () => ({ pathname, query, isMobile, sessionData }),
    [pathname, query, isMobile, sessionData],
  );

  return (
    <FlagsContext.Provider value={flags}>
      <PortalConfigContext.Provider value={portalConfig}>
        <AppProviders insertCss={insertCss}>
          <ReduxProvider store={store}>
            <RouteContext.Provider value={routeContext}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                maxSnack={2}
              >
                <Notifier />
                <ConfigureAuth />
                {React.Children.only(children)}
              </SnackbarProvider>
            </RouteContext.Provider>
          </ReduxProvider>
        </AppProviders>
      </PortalConfigContext.Provider>
    </FlagsContext.Provider>
  );
};

export default UnauthApp;
