import React from 'react';
import {
  FiEdit as EditIcon,
  FiNavigation as NavigationIcon,
} from 'react-icons/fi';
import { TrashIcon, DetailsIcon } from 'src/legacy/components/Icons';
import { BaseActionsMenuButton, Action } from 'src/legacy/components/Dropdowns';
import { ClientActionsRendererProps } from 'src/legacy/components/AgGrid/Renderer/types';
import { Icon } from 'copilot-design-system';

export const ClientActionRenderer = ({
  data,
  handleClickRemove,
  handleClickEdit,
  handleClickInvite,
  handleClickCopyAccessLink,
  handleClickEmailAccessLink,
  handleClickViewDetails,
  entityName,
  onActionMenuClick,
}: ClientActionsRendererProps) => {
  const actions: Action[] = [
    {
      name: 'Edit info',
      icon: <EditIcon />,
      onClick: () => handleClickEdit(data),
    },
  ];
  if (data.status !== 'Active' && handleClickInvite) {
    actions.push({
      name: `${
        data.status === 'Inactive' ? 'Send invite' : 'Invite client'
      }...`,
      onClick: () => handleClickInvite(data),
      icon: <NavigationIcon />,
    });
  }

  if (handleClickEmailAccessLink) {
    actions.push({
      name: `Email magic link`,
      onClick: () => handleClickEmailAccessLink(data),
      icon: <Icon icon="Invite" className="h-4 w-4 text-gray-small" />,
    });
  }
  if (handleClickCopyAccessLink) {
    actions.push({
      name: `Copy magic link`,
      onClick: () => handleClickCopyAccessLink(data),
      icon: <Icon icon="Copy" className="h-4 w-4 text-gray-small" />,
    });
  }

  if (handleClickViewDetails) {
    actions.unshift({
      name: 'View details',
      icon: <DetailsIcon />,
      onClick: () => handleClickViewDetails(data),
    });
  }
  actions.push({
    name: `Delete ${entityName || 'client'}`,
    onClick: () => handleClickRemove(data),
    icon: <TrashIcon />,
    isDelete: true,
  });

  return (
    <BaseActionsMenuButton
      onActionMenuClick={onActionMenuClick}
      actions={actions}
    />
  );
};
