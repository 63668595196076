import { Theme, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { white } from 'src/theme/colors';
import { signaturePageContentWidth } from '../Signature/SignaturePageContent';

const useStyles = makeStyles((theme: Theme) => ({
  skeletonContainer: {
    position: 'absolute',
    inset: 0,
    background: white,
    height: 900,
    width: signaturePageContentWidth,
  },
  skeleton: {
    width: '100%',
    height: '100%',
    transform: 'none',
    marginBottom: theme.spacing(3.5),
  },
}));

export const PDFSkeleton = ({ className }: { className?: string }) => {
  const classes = useStyles();
  return (
    <div className={className || classes.skeletonContainer}>
      <Skeleton className={classes.skeleton} />
      <Skeleton className={classes.skeleton} />
    </div>
  );
};
