import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import ComboBox, {
  ChannelMemberSelectComboBoxOption,
  ComboBoxOption,
  ComboBoxProps,
} from 'src/legacy/components/Select/ComboBox';
import { Company, Client } from 'src/store/clients/types';
import * as UserUtils from 'src/utils/UserUtils';
import { useGetPortalConfigQuery } from 'src/services/api';
import { GetCurrentUser } from 'src/store/users/reducers';
import { Skeleton, Value, createFilterOptions } from '@material-ui/lab';
import { useFetchClientAccessData } from '../UsersTable/useFetchClientAccessData';
import { GROUP_OPTION_MAPPER } from 'src/constants/multiSelectConsts';

interface ChannelMemberSelectOwnProps {
  id: string;
  memberIds: string[] | string; // memberId can be all companyIds or a mix of companyIds and clientIds, depending on the memberIdsType prop
  memberIdsType?: 'company' | 'any';
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  excludeEmptyCompany?: boolean;
  label?: string;
  allowMultipleCompanies: boolean;
  // ignoreStore indicates that we should not rely on current state
  // of clients and companies to show options.
  // This is useful for Admin IUs that need to grant access
  // to clients that are not loaded by default.
  // So this boolean will tells us to make an api call to get all clients.
  // All clients will still be based on permission. E.g. a staff user
  // is restricted to their access level and clients can only see other
  // clients in their company.
  ignoreStore?: boolean;
  blurOnSelect?: boolean;
  hideCompanies?: boolean;
  openOnFocus?: boolean;
  fieldName?: string;
  popupIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  placeholder?: string;
}

export type ChannelMemberSelectProps<Multiple extends boolean = true> =
  ChannelMemberSelectOwnProps &
    Pick<
      Required<ComboBoxProps<ChannelMemberSelectComboBoxOption, Multiple>>,
      'onChange'
    > &
    Pick<
      ComboBoxProps<ChannelMemberSelectComboBoxOption, Multiple>,
      'multiple'
    > &
    Pick<
      ComboBoxProps<ChannelMemberSelectComboBoxOption, Multiple>,
      'additionalComboBoxProps'
    >;

const MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_IS_ONLY_ONE_GROUP = 6;
const MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_ARE_TWO_GROUPS = 3;

/**
 * This component lets you select members for a channel (file, messages, extension, etc.)
 * where you can select multiple clients from the same company or an entire company.
 */
export function ChannelMemberSelect<Multiple extends boolean = true>({
  id,
  onChange,
  memberIds,
  error,
  helperText,
  disabled = false,
  multiple = true as Multiple,
  autoFocus = true,
  excludeEmptyCompany = false,
  label = '',
  allowMultipleCompanies,
  memberIdsType = 'any',
  ignoreStore = false,
  blurOnSelect,
  hideCompanies = false,
  additionalComboBoxProps = {},
  fieldName,
  closeIcon = null,
  popupIcon,
  placeholder,
}: ChannelMemberSelectProps<Multiple>) {
  const { data: portalConfigurations } = useGetPortalConfigQuery();
  const filter = createFilterOptions<ChannelMemberSelectComboBoxOption>();
  const areCompaniesDisabled =
    portalConfigurations?.structFields?.disableCompanies;
  const storeCompanies = useSelector(
    (state: RootState) => state.clients.activeCompanies,
  );
  const storeClients = useSelector(
    (state: RootState) => state.clients.activeClients,
  );
  const {
    clients: fetchedClients,
    companies: fetchedCompanies,
    isLoading,
  } = useFetchClientAccessData(!ignoreStore); // skip api call by default when not ignoring store values

  const { activeClients, companies } = React.useMemo(() => {
    if (ignoreStore) {
      return { activeClients: fetchedClients, companies: fetchedCompanies };
    }

    return { activeClients: storeClients, companies: storeCompanies };
  }, [
    ignoreStore,
    fetchedClients,
    fetchedCompanies,
    storeCompanies,
    storeClients,
    isLoading,
  ]);

  const authenticatedUserID = useSelector(
    (state: RootState) =>
      GetCurrentUser({
        usersState: state.users,
        currentUserId: state.user.id,
        isClient: state.user.isClient,
      })?.id || '',
  );

  const [dropdownOptions, setDropdownOptions] = React.useState<
    ChannelMemberSelectComboBoxOption[] | null
  >(null);

  const [selectedOptions, setSelectedOptions] = React.useState<
    ComboBoxOption[] | ComboBoxOption | null
  >(multiple ? [] : null);

  // if excludeEmptyCompany prop = true then filter out the companies without any clients
  const validCompanies = React.useMemo(() => {
    if (hideCompanies) {
      return [];
    }
    if (!excludeEmptyCompany) {
      return companies;
    }

    const activeCompanies: Company[] = [];
    const companyIdToNumbOfAvailableClients = new Map<string, number>();
    activeClients.forEach((c) => {
      if (!UserUtils.isUserEnabled(c)) {
        return;
      }

      const { companyId } = c.fields;
      if (!companyId) {
        return;
      }

      const numbCompanies = companyIdToNumbOfAvailableClients.get(companyId);
      if (numbCompanies !== undefined) {
        companyIdToNumbOfAvailableClients.set(companyId, numbCompanies + 1);
      } else {
        companyIdToNumbOfAvailableClients.set(companyId, 1);
      }
    });

    companies.forEach((c) => {
      const enabledUsers = companyIdToNumbOfAvailableClients.get(c.id);
      if (enabledUsers !== undefined) {
        if (enabledUsers > 0) {
          activeCompanies.push(c);
        }
      }
    });
    return activeCompanies;
  }, [companies, activeClients, excludeEmptyCompany]);

  /**
   * This method formats options so they can be rendered in in the combobox dropdown
   */
  const allDropdownOptions = React.useMemo(() => {
    const optionList = [...validCompanies, ...activeClients];
    return optionList
      .map((item: Company | Client) => {
        if (UserUtils.hasUserNameFields(item)) {
          // is client user entity
          const {
            givenName,
            familyName,
            avatarImageUrl,
            fallbackColor,
            companyId,
          } = item.fields;

          const { getstreamId } = item;
          return {
            id: item.id,
            createdDate: item.createdDate,
            getstreamId,
            type: 'client' as const,
            label: `${givenName} ${familyName}`,
            avatar: avatarImageUrl,
            fallbackColor,
            companyId,
          };
        }
        const companyFields = (item as Company).fields;
        return {
          id: item.id,
          createdDate: item.createdDate,
          type: 'company' as const,
          label: companyFields.name || '',
          avatar: companyFields.avatarImageURL,
          fallbackColor: companyFields.fallbackColor,
          isPlaceholder: companyFields.isPlaceholder,
        };
      })
      .sort((a, b) => b.createdDate.localeCompare(a.createdDate));
  }, [validCompanies, activeClients, authenticatedUserID]);

  const updateMultiSelectDropdownOptions = (
    members: string[],
    dropDownSrc: ChannelMemberSelectComboBoxOption[],
  ) => {
    const selectedMenuOptions = dropDownSrc.filter(
      (opt) =>
        // Find all the options that are selected by looking directly
        // at the ids that are included in the memberIds list
        members.includes(opt.id) ||
        // or if the memberIds type is company, then we also want to select
        // any client options with a companyId value that is in the memberIds list.
        // This ensure that clients that are part of placeholder companies
        // show as selected options. This works because the dropDownSrc
        // is setup to have a list of options that includes:
        // 1. all companies that are not placeholders
        // 2. all clients that are part of companies that are placeholders
        (memberIdsType === 'company' &&
          opt.companyId &&
          memberIds.includes(opt.companyId)),
    );
    setSelectedOptions(selectedMenuOptions);

    if (memberIds.length === 0) {
      // if there are no IDs selected the full option list should be shown
      setDropdownOptions(dropDownSrc);
      return;
    }

    if (allowMultipleCompanies) {
      // when multiple companies are enabled, you can select multiple companies (or clients across companies).
      // in this case we want to filter out any options that are already selected.
      // This means if the memberIds type is company, then we also
      // want to filter out any clients that are part of companies that are already selected.
      setDropdownOptions(
        dropDownSrc.filter(
          (opt) =>
            !members.includes(opt.id) ||
            (memberIdsType === 'company' &&
              opt.companyId &&
              !memberIds.includes(opt.companyId)),
        ),
      );
      return;
    }

    if (Array.isArray(selectedMenuOptions) && selectedMenuOptions.length) {
      if (selectedMenuOptions.every((opt) => opt.type === 'company')) {
        // if a company is selected we want to prevent another combo box option from being selected
        setDropdownOptions([]);
        return;
      }

      // selected clients determine which clients we show in the dropdown (must be same company)
      const selectedClientCompanyIDs = selectedMenuOptions.map(
        (opt) => opt.companyId,
      );
      setDropdownOptions(
        dropDownSrc.filter(
          (option) =>
            !memberIds.includes(option.id) &&
            option.type !== 'company' &&
            selectedClientCompanyIDs.includes(option.companyId),
        ) || [],
      );
    }
  };

  const updateSingleSelectDropdownOptions = (
    memberId: string,
    dropDownSrc: ChannelMemberSelectComboBoxOption[],
  ) => {
    setSelectedOptions(
      allDropdownOptions.find((opt) => opt.id === memberId) || null,
    );

    setDropdownOptions(dropDownSrc);
  };

  // each time values change we update remaining choices accordingly
  React.useEffect(() => {
    let dropdownEntries: ChannelMemberSelectComboBoxOption[] =
      allDropdownOptions;
    // Behind feature flag, filter out companies if they are disabled
    if (areCompaniesDisabled) {
      dropdownEntries = dropdownEntries.filter((opt) => opt.type !== 'company');
    }

    if (memberIdsType === 'company') {
      // if the memberId type is company then we only want to let users select companies
      // or clients that are part of placeholder companies. To create this list of options
      // we first create a map of all companies that are not placeholders and then concat those opts
      // with all clients that are part of companies not in the map. (i.e. part of placeholder companies)
      // This results in a list of options that looks like:
      // [company1, company2, client1, client2]
      // where company1 and company2 are not placeholder companies
      // and client1, client2 are all part of placeholder companies
      const nonPlaceholderCompanyOptions = dropdownEntries
        .filter((opt) => opt.type === 'company' && !opt.isPlaceholder)
        .reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {} as Record<string, (typeof dropdownEntries)[number]>);

      dropdownEntries = Object.values(nonPlaceholderCompanyOptions).concat(
        dropdownEntries.filter(
          (opt) =>
            opt.type !== 'company' &&
            opt.companyId &&
            !nonPlaceholderCompanyOptions[opt.companyId],
        ),
      );
    }

    if (multiple && memberIds && Array.isArray(memberIds)) {
      updateMultiSelectDropdownOptions(memberIds, dropdownEntries);
    } else {
      const selectedMemberId = Array.isArray(memberIds)
        ? memberIds.at(0) || ''
        : memberIds;

      updateSingleSelectDropdownOptions(selectedMemberId, dropdownEntries);
    }
  }, [memberIds, allDropdownOptions, areCompaniesDisabled]);

  const getPlaceholder = () => {
    if (placeholder) {
      return placeholder;
    }
    if (hideCompanies) {
      return 'Type to find clients';
    }
    return 'Find a client or company';
  };

  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={60} />;
  }

  return dropdownOptions ? (
    <ComboBox<ChannelMemberSelectComboBoxOption, Multiple>
      blurOnSelect={blurOnSelect}
      textFieldVariant="medium"
      disabled={disabled}
      disabledIDs={[]}
      fieldName={fieldName}
      error={error}
      helperText={helperText}
      id={id}
      label={label}
      placeholder={getPlaceholder()}
      showPlaceholder
      onChange={(event, vals, eventName, target) => {
        let filteredVals = vals;

        if (eventName.toString() === 'remove-option') {
          // In a world where we have previously allowed multiple companies, but no longer do,
          // we are in a weird edge case where it is impossible to remove just one user from
          // access. Thus, when a user is removed, we need to remove all users in the same company.
          // This is a hack we should remove once we actually support users switching back to user mode
          // from company mode.
          const companyId = target?.option?.companyId;
          if (companyId && Array.isArray(vals)) {
            // Unsure why an array after a filter was not a valid value for filteredVals, but this
            // cast was unfortunately required.
            filteredVals = vals.filter(
              (val) => val.companyId !== companyId,
            ) as Value<
              ChannelMemberSelectComboBoxOption,
              Multiple,
              Multiple,
              undefined
            >;
          }
        }

        setSelectedOptions(filteredVals);
        onChange(event, filteredVals, eventName, target);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const companyItems = filtered.filter(
          (option) => option.type === 'company',
        );
        const clientItems = filtered.filter(
          (option) => option.type === 'client',
        );

        // Render the group items in bracket of six.
        // This means if there are 3 companies and 3 clients, we want to show all 3 companies and 3 clients.
        // If there are 3 companies and 4 clients, we want to show 3 companies and 3 clients.
        // If there are 2 companies and 4 clients, we want to show 2 companies and 4 clients.
        const clientItemsCount = Math.min(
          clientItems.length,
          companyItems.length >
            MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_ARE_TWO_GROUPS
            ? MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_ARE_TWO_GROUPS
            : MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_IS_ONLY_ONE_GROUP -
                companyItems.length,
        );
        const remainingSlots =
          MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_IS_ONLY_ONE_GROUP -
          clientItemsCount;
        const companySortedRule = params.inputValue
          ? remainingSlots
          : MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_ARE_TWO_GROUPS;
        const clientSortedRule = params.inputValue
          ? clientItemsCount
          : hideCompanies
          ? MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_IS_ONLY_ONE_GROUP
          : MAX_ITEMS_ALLOWED__IN_COMBOX_WHEN_THERE_ARE_TWO_GROUPS;

        const companyItemSlice = companyItems.slice(0, companySortedRule);
        const clientItemSlice = clientItems.slice(0, clientSortedRule);
        const sorted = [...clientItemSlice, ...companyItemSlice];

        // Adding 'No results found' option
        if (sorted.length === 0 && params.inputValue !== '') {
          sorted.push({
            id: 'no-option',
            type: 'no-option',
            label: params.inputValue,
          });
        }
        return sorted;
      }}
      options={
        dropdownOptions.sort((a, b) => a.type.localeCompare(b.type)) || []
      }
      groupBy={(option) => GROUP_OPTION_MAPPER[option.type] ?? ''}
      values={selectedOptions}
      additionalComboBoxProps={
        additionalComboBoxProps ?? {
          inputProps: {
            autoFocus,
          },
        }
      }
      popupIcon={popupIcon}
      closeIcon={closeIcon}
      withAvatars
      hideSelectedItems
      multiple={multiple}
      noOptionsText="No results found"
    />
  ) : null;
}
