import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import { Action } from 'src/constants';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';

interface RightClickMenuProps {
  actions: Action[];
  children: React.ReactNode;
  disabled?: boolean;
}

const RightClickMenu: React.FC<RightClickMenuProps> = ({
  actions,
  children,
  disabled,
}) => {
  const [menuPosition, setMenuPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const pressTimer = useRef<NodeJS.Timeout | null>(null);

  const showMenu = (event: React.MouseEvent | React.TouchEvent) => {
    // Don't show menu if disabled or no actions
    if (disabled || !actions || actions.length === 0) return;
    event.preventDefault();

    // Get the pointer position
    const x = 'clientX' in event ? event.clientX : event.touches[0].clientX;
    const y = 'clientY' in event ? event.clientY : event.touches[0].clientY;

    setMenuPosition({ top: y + 12, left: x }); // Position slightly below pointer
  };

  const closeMenu = () => {
    setMenuPosition(null);
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    if (disabled || !actions || actions.length === 0) return;

    pressTimer.current = setTimeout(() => showMenu(event), 500);
  };

  const handleTouchEnd = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
    }
  };

  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (pressTimer.current) {
        clearTimeout(pressTimer.current);
      }
    };
  }, []);

  // Create non-selectable styles
  // On Mobile, long press a element with text shows the copy/paste menu
  // This style prevents that from happening
  const nonSelectableStyle: CSSProperties = {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  };

  // Add CSS to prevent text selection
  useEffect(() => {
    // Apply user-select: none when the component mounts
    const originalUserSelect = document.body.style.userSelect;

    if (menuPosition) {
      document.body.style.userSelect = 'none';
    }

    // Reset when the menu closes
    return () => {
      if (menuPosition) {
        document.body.style.userSelect = originalUserSelect;
      }
    };
  }, [menuPosition]);

  return (
    <div
      onContextMenu={showMenu} // Right-click (desktop)
      onTouchStart={handleTouchStart} // Long press (mobile)
      onTouchEnd={handleTouchEnd}
      className="relative inline-block w-full"
      style={nonSelectableStyle}
    >
      {children}

      <Menu
        open={Boolean(menuPosition)}
        onClose={closeMenu}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition || undefined}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.key}
            onClick={(e) => {
              action.onClick?.(e);
              // Ensures the menu closes after an action is performed
              // Without this, the menu would remain open, even after performing the action
              closeMenu();
            }}
          >
            <ListItemIcon
              classes={{
                root: clsx('min-w-max mr-2', {
                  'text-error-primary': action.isDelete,
                  'text-black-heading': !action.isDelete,
                }),
              }}
            >
              {action.icon}
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: clsx({
                  ['text-error-primary']: action.isDelete,
                  'text-black-heading': !action.isDelete,
                }),
              }}
            >
              {action.name}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default RightClickMenu;
