import { createBrowserHistory, createMemoryHistory } from 'history';

const DEBUG = false;

// I had a tricky problem trying to figure out what was causing a redirection.
// This function should help if anyone has this issue in the future.
function getHistory(
  debug: boolean,
):
  | ReturnType<typeof createBrowserHistory>
  | ReturnType<typeof createMemoryHistory> {
  if (!debug) {
    return process.env.BROWSER ? createBrowserHistory() : createMemoryHistory();
  }

  if (process.env.BROWSER) {
    const browserHistory = createBrowserHistory();
    type PushParams = Parameters<typeof browserHistory.push>;
    type ReplaceParams = Parameters<typeof browserHistory.replace>;
    // Sorry to use any here, but it's just for debugging purposes.
    const result: any = {
      ...browserHistory,
      push: (...args: PushParams) => {
        // eslint-disable-next-line no-console
        console.log('historyDebugger.push', ...args);
        // eslint-disable-next-line no-console
        console.trace('historyDebugger.push');
        browserHistory.push(...args);
      },
      replace: (...args: ReplaceParams) => {
        // eslint-disable-next-line no-console
        console.log('historyDebugger.replace', ...args);
        // eslint-disable-next-line no-console
        console.trace('historyDebugger.replace');
        browserHistory.replace(...args);
      },
    };
    return result;
  }

  return createMemoryHistory();
}

export default getHistory(DEBUG);
