import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AnalyticsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.4375 10.9375L16.875 7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.0625 8.4375L11.5625 10.9375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 12.5L7.1875 8.4375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8125 7.5C18.3303 7.5 18.75 7.08027 18.75 6.5625C18.75 6.04473 18.3303 5.625 17.8125 5.625C17.2947 5.625 16.875 6.04473 16.875 6.5625C16.875 7.08027 17.2947 7.5 17.8125 7.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 12.8125C13.0178 12.8125 13.4375 12.3928 13.4375 11.875C13.4375 11.3572 13.0178 10.9375 12.5 10.9375C11.9822 10.9375 11.5625 11.3572 11.5625 11.875C11.5625 12.3928 11.9822 12.8125 12.5 12.8125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 8.4375C8.64277 8.4375 9.0625 8.01777 9.0625 7.5C9.0625 6.98223 8.64277 6.5625 8.125 6.5625C7.60723 6.5625 7.1875 6.98223 7.1875 7.5C7.1875 8.01777 7.60723 8.4375 8.125 8.4375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.1875 14.375C2.70527 14.375 3.125 13.9553 3.125 13.4375C3.125 12.9197 2.70527 12.5 2.1875 12.5C1.66973 12.5 1.25 12.9197 1.25 13.4375C1.25 13.9553 1.66973 14.375 2.1875 14.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
