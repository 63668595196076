import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DiceIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 13.3346V6.66425C17.4997 6.44593 17.4423 6.23149 17.3334 6.04226C17.2245 5.85304 17.0679 5.69564 16.8793 5.58573L10.9418 2.13026C10.6557 1.96372 10.3306 1.87598 9.99961 1.87598C9.66859 1.87598 9.34349 1.96372 9.05742 2.13026L3.1207 5.58573C2.93207 5.69564 2.77551 5.85304 2.66661 6.04226C2.55771 6.23149 2.50027 6.44593 2.5 6.66425V13.3346C2.50013 13.553 2.55751 13.7676 2.66641 13.957C2.77532 14.1464 2.93195 14.3039 3.1207 14.4139L9.0582 17.8693C9.34435 18.0356 9.66942 18.1233 10.0004 18.1233C10.3314 18.1233 10.6564 18.0356 10.9426 17.8693L16.8801 14.4139C17.0687 14.3038 17.2252 14.1462 17.3339 13.9569C17.4427 13.7675 17.4999 13.5529 17.5 13.3346Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.69531 6.01514L10 10.312L17.3047 6.01514"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18.1245V10.312"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.5625C10.5178 6.5625 10.9375 6.28268 10.9375 5.9375C10.9375 5.59232 10.5178 5.3125 10 5.3125C9.48223 5.3125 9.0625 5.59232 9.0625 5.9375C9.0625 6.28268 9.48223 6.5625 10 6.5625Z"
      fill="currentColor"
    />
    <path
      d="M8.125 12.5C8.47018 12.5 8.75 12.0803 8.75 11.5625C8.75 11.0447 8.47018 10.625 8.125 10.625C7.77982 10.625 7.5 11.0447 7.5 11.5625C7.5 12.0803 7.77982 12.5 8.125 12.5Z"
      fill="currentColor"
    />
    <path
      d="M4.375 13.75C4.72018 13.75 5 13.3303 5 12.8125C5 12.2947 4.72018 11.875 4.375 11.875C4.02982 11.875 3.75 12.2947 3.75 12.8125C3.75 13.3303 4.02982 13.75 4.375 13.75Z"
      fill="currentColor"
    />
    <path
      d="M11.875 12.5C12.2202 12.5 12.5 12.0803 12.5 11.5625C12.5 11.0447 12.2202 10.625 11.875 10.625C11.5298 10.625 11.25 11.0447 11.25 11.5625C11.25 12.0803 11.5298 12.5 11.875 12.5Z"
      fill="currentColor"
    />
    <path
      d="M15.625 10.3125C15.9702 10.3125 16.25 9.89277 16.25 9.375C16.25 8.85723 15.9702 8.4375 15.625 8.4375C15.2798 8.4375 15 8.85723 15 9.375C15 9.89277 15.2798 10.3125 15.625 10.3125Z"
      fill="currentColor"
    />
    <path
      d="M11.875 15.9375C12.2202 15.9375 12.5 15.5178 12.5 15C12.5 14.4822 12.2202 14.0625 11.875 14.0625C11.5298 14.0625 11.25 14.4822 11.25 15C11.25 15.5178 11.5298 15.9375 11.875 15.9375Z"
      fill="currentColor"
    />
    <path
      d="M15.625 13.75C15.9702 13.75 16.25 13.3303 16.25 12.8125C16.25 12.2947 15.9702 11.875 15.625 11.875C15.2798 11.875 15 12.2947 15 12.8125C15 13.3303 15.2798 13.75 15.625 13.75Z"
      fill="currentColor"
    />
  </SvgIcon>
);
