import * as Colors from 'src/theme/colors';

export default {
  '@global': {
    body: {
      margin: '8px 0 0',
      backgroundColor: Colors.white,
      lineHeight: 1.2,
    },
  },
};
