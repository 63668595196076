import React, { useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import {
  BlackHeadings,
  DividersAndCardBorders,
  GraySmall,
  LightGray,
  NonHoverBorder,
} from 'src/theme/colors';
import { MapTaskCodeToTaskDetails } from 'src/legacy/components/HomePage/OnboardingTasksSection/helper';
import Button from 'src/legacy/components/Button';
import { typography15MediumStyle } from 'src/legacy/components/Text';
import { OnboardingStatus } from 'src/entities/OnboardingStatus';
import { CheckedCircleIcon, EllipseIcon } from 'src/legacy/components/Icons';
import { TaskCodes } from 'src/legacy/components/HomePage/OnboardingTasksSection/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    tasksButtons: {
      boxSizing: 'border-box',
      minWidth: 260,
      borderRight: `1px solid ${DividersAndCardBorders}`,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      padding: theme.spacing(4, 3),
      [theme.breakpoints.down('sm')]: {
        borderRight: 'none',
        borderBottom: `1px solid ${DividersAndCardBorders}`,
      },
    },
    taskButton: {
      color: LightGray,
      ...typography15MediumStyle,
      '&:hover': {
        backgroundColor: 'white',
      },
      marginLeft: theme.spacing(1.5),
    },
    taskDone: {
      textDecoration: 'line-through',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    active: {
      color: BlackHeadings,
    },
    listItemContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    taskCheckDone: {
      color: NonHoverBorder,
      fill: GraySmall,
      fontSize: 16,
    },
    taskCheckNotDone: {
      color: LightGray,
      fontSize: 16,
    },
    taskCheckNotDoneActive: {
      color: BlackHeadings,
      fontSize: 16,
    },
  }),
);

interface TasksListProps {
  onboardingStatus: OnboardingStatus;
  activeTaskCode: string;
  onSelect: (taskCode: TaskCodes) => void;
}
export const TasksList: React.FC<TasksListProps> = ({
  onboardingStatus,
  activeTaskCode,
  onSelect,
}) => {
  const classes = useStyles();

  const handleSelectTaskCode = useCallback(
    (taskCode) => {
      onSelect(taskCode);
    },
    [onSelect],
  );

  return (
    <div className={classes.tasksButtons}>
      {onboardingStatus.additionalFields.tasks?.map((task) => (
        <div key={task.taskCode} className={classes.listItemContainer}>
          {task.done ? (
            <CheckedCircleIcon className={classes.taskCheckDone} />
          ) : (
            <EllipseIcon
              className={
                task.taskCode === activeTaskCode
                  ? classes.taskCheckNotDoneActive
                  : classes.taskCheckNotDone
              }
            />
          )}
          <Button
            className={clsx(classes.taskButton, {
              [classes.taskDone]: task.done,
              [classes.active]: task.taskCode === activeTaskCode,
            })}
            htmlId={task.taskCode}
            variant="text"
            disabled={task.done}
            onClick={() => handleSelectTaskCode(task.taskCode)}
            key={task.taskCode}
          >
            {MapTaskCodeToTaskDetails(task.taskCode).title}
          </Button>
        </div>
      ))}
    </div>
  );
};
