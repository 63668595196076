import React from 'react';
import Dropzone, { DropzoneOptions } from 'react-dropzone-latest';
import { Box } from '@material-ui/core';

interface DropzoneStateProps {
  dropzoneWrapperClass?: string;
}

export const FileDropzone: React.FC<DropzoneOptions & DropzoneStateProps> = (
  props,
) => {
  const { children, dropzoneWrapperClass, ...rest } = props;
  return (
    <Dropzone {...rest}>
      {({ getRootProps, getInputProps }) => (
        <Box {...getRootProps()} className={dropzoneWrapperClass}>
          <input {...getInputProps()} />
          {children}
        </Box>
      )}
    </Dropzone>
  );
};
