import { Notification } from 'src/store/notifications/types';

/**
 * This method counts unread notifications coming from
 * performed consecutive actions performed by a user.
 * Inside the loop block, at a first iteration, we initialize
 * the notifier ID (notification sender ID) to the first
 * notification sender ID. Then, in the next iterations, we should check
 * if the next notification is coming from the same user:
 * 1. If so, it will increment the count value by 1.
 * 2. otherwise, it should leave the loop block
 *    and then we return the final count value.
 * Note: this algorithm ensure to only show the most recent
 * notification count.
 * @param notifications
 */
export const useCountUnreadNotifications = (notifications: Notification[]) => {
  let count = 0;
  let firstUserId = null;

  for (let i = 0; i < notifications.length; i += 1) {
    if (i === 0) {
      firstUserId = notifications.at(0)?.fields?.senderUserId;
    }
    if (firstUserId !== notifications[i].fields?.senderUserId) {
      break;
    }
    count += 1;
  }
  return count;
};
