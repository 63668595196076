import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EggIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 1.875C7.5 1.875 3.75 6.70664 3.75 11.1934C3.75 15.6801 6.25 18.125 10 18.125C13.75 18.125 16.25 15.6801 16.25 11.1934C16.25 6.70664 12.5 1.875 10 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
