import { Method } from 'axios';
import { getApiEndpointUrl } from 'src/mocks/baseUrls';

export type ApiMethod = Method | 'del' | undefined;

export const handleNativeHttpRequestFetcher = (
  pathname: string,
  method: ApiMethod,
  headers: any = {},
  options: any = {},
) =>
  fetch(getApiEndpointUrl(pathname), {
    method,
    headers: headers || {
      'Content-Type': 'application/json',
    },
    ...options,
  }).then((res) => res.json());
