import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PartlySunnyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.53945 11.9863C3.67001 11.9661 3.79085 11.9051 3.88477 11.8122C3.97868 11.7193 4.04088 11.5991 4.0625 11.4688C4.53477 8.60039 6.62617 6.875 9.0625 6.875C11.3254 6.875 12.8367 8.34961 13.4453 9.91172C13.4844 10.0118 13.5489 10.1 13.6325 10.1677C13.7161 10.2353 13.8158 10.2799 13.9219 10.2973C15.875 10.6156 17.5 11.9199 17.5 14.1875C17.5 16.5078 15.6016 18.125 13.2812 18.125H4.14062C2.20703 18.125 0.625 17.1602 0.625 15.0312C0.625 13.1379 2.13555 12.2102 3.53945 11.9863Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M15.0312 10.6015C15.4133 10.3068 15.722 9.9277 15.933 9.4938C16.144 9.05991 16.2517 8.58304 16.2477 8.10056C16.2436 7.61807 16.1279 7.14309 15.9095 6.71282C15.6912 6.28255 15.3762 5.90869 14.9892 5.62052C14.6022 5.33235 14.1537 5.1377 13.6789 5.05184C13.2041 4.96597 12.7159 4.99121 12.2525 5.12559C11.7891 5.25996 11.3631 5.49982 11.0079 5.82636C10.6527 6.1529 10.3779 6.55726 10.2051 7.00775"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.125 8.125H19.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 1.875V3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.70508 3.70557L9.58906 4.58955"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5441 3.70557L16.6602 4.58955"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
