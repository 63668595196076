import React from 'react';
import { CircularProgress } from '@material-ui/core';

export interface LoaderProps {
  color?: string;
}

export const Loader: React.FC<LoaderProps> = ({ color }) => (
  // use color prop if provided, otherwise use primary color
  // when color is provided the color prop on the MUI component must be
  // set to 'inherit' to override the default primary color. Otherwise
  // the primaryColor css selector will override the color prop set via style
  <CircularProgress
    {...(color ? { color: 'inherit', style: { color } } : { color: 'primary' })}
  />
);
