import React, { useEffect, useState } from 'react';
import { RouteContext } from 'src/context';
import history from 'src/history';
import { ChannelType } from 'src/legacy/components/Channels/useChannelForm';
import { FixedSizeList } from 'react-window';

export type SelectorWrapper = (selectedItem: any) => void;

interface ChannelQueryProps {
  // A callback funtion that return the list select function extending the funcionality and a ref to be
  // attacked to the list component
  component: (
    onSelectionWrapper: SelectorWrapper,
    refList: React.RefObject<any>,
  ) => JSX.Element;
  // function to be callend when the item is selected on the list
  onSelect: (data: any) => void;
  // the type of the item on the channel
  channelType: ChannelType;
  data: any[];
  fallbackIndex: number | null;
  shouldSetQueryParam: boolean;
  // if true, don't select the fallbackIndex channel
  disableDefaultSelect?: boolean;
}

// Wrapper component around a list that expand the selection funcionality, when a item
// is selected it will append the identifier to the URL as a query param and on a refresh
// first load event, it will look on the URL query params to see if there is a channelId already
// and automatically select that channel
export const LinkUrlListWrapper: React.FC<ChannelQueryProps> = ({
  component: Component,
  onSelect,
  data,
  channelType,
  fallbackIndex = 0,
  shouldSetQueryParam = true,
  disableDefaultSelect = false,
}) => {
  const { query } = React.useContext(RouteContext);
  const [loaded, setLoaded] = useState(false);
  const refList = React.createRef<FixedSizeList>();

  const getIdentifierField = (item: any): string => {
    switch (channelType) {
      case 'files':
      case 'fileChannel':
        return item.fileChannel.id;
      case 'forms':
        return item.responseId;
      default:
        return '';
    }
  };
  // callend on each load only
  useEffect(() => {
    if (loaded || !data) {
      return;
    }

    if (query.channelId !== undefined) {
      const index = data.findIndex(
        (c) => getIdentifierField(c) === query.channelId,
      );
      const channel = data[index];
      if (channel) {
        refList.current?.scrollToItem(index, 'center');
        onSelect(channel);
      }
    } else if (fallbackIndex !== null) {
      if (disableDefaultSelect) return; // don't select any notification by default
      const channel = data[fallbackIndex];
      if (!channel) {
        return;
      }
      refList.current?.scrollToItem(fallbackIndex, 'center');
      onSelect(channel);
    }
    setLoaded(true);
  }, [loaded]);

  // onSelectwrapper extend the selection funcionality passed, with the query url set on selection
  const onSelectwrapper = (selectedItem: any) => {
    if (shouldSetQueryParam) {
      const id = getIdentifierField(selectedItem);
      history.push({ search: `?channelId=${id}` });
    }
    if (onSelect) {
      onSelect(selectedItem); // do main
    }
  };

  return <>{Component(onSelectwrapper, refList)}</>;
};
