import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DropzoneFilesIcon = (props: SvgIconProps) => (
  <SvgIcon fill="none" viewBox="0 0 71 71" {...props}>
    <path
      d="M52.7144 67.4669C54.6708 67.4669 56.5471 66.6897 57.9305 65.3063C59.3139 63.9229 60.0911 62.0467 60.0911 60.0902V30.1611C60.0909 28.8573 59.5729 27.6069 58.6511 26.6849L36.9423 4.97611C36.0203 4.05428 34.7699 3.53633 33.4661 3.53613H18.2902C16.3338 3.53613 14.4575 4.31331 13.0741 5.6967C11.6908 7.08008 10.9136 8.95635 10.9136 10.9128V60.0902C10.9136 62.0467 11.6908 63.9229 13.0741 65.3063C14.4575 66.6897 16.3338 67.4669 18.2902 67.4669H35.5023"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M35.5 4.76367V23.2052C35.5 24.5095 36.0181 25.7603 36.9404 26.6826C37.8626 27.6049 39.1135 28.123 40.4177 28.123H58.8593"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M51.1998 61.4998H36.7998M43.9998 54.2998V68.6998V54.2998Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
