import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TxtIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12.7138H6.1044V15.6364H6.97088V12.7138H8.07351V12H5V12.7138Z"
      fill="black"
    />
    <path
      d="M9.39231 12H8.40154L9.50062 13.8182L8.37314 15.6364H9.37456L10.0564 14.4751H10.0848L10.7666 15.6364H11.7751L10.6423 13.8182L11.7396 12H10.7559L10.0848 13.1488H10.0564L9.39231 12Z"
      fill="black"
    />
    <path
      d="M12.0752 12.7138H13.1796V15.6364H14.0461V12.7138H15.1487V12H12.0752V12.7138Z"
      fill="black"
    />
  </SvgIcon>
);
