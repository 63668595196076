import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CoreIcon = (props: SvgIconProps) => (
  <SvgIcon width="39" height="40" viewBox="0 0 39 40" fill="none" {...props}>
    <path
      d="M18.7657 30.1395C24.9851 30.1395 30.027 25.0977 30.027 18.8782C30.027 12.6588 24.9851 7.6169 18.7657 7.6169C12.5463 7.6169 7.50439 12.6588 7.50439 18.8782C7.50439 25.0977 12.5463 30.1395 18.7657 30.1395Z"
      fill="#7DDAA0"
    />
    <path
      d="M36.0809 15.7809C37.0916 15.7809 37.9109 14.9616 37.9109 13.9509C37.9109 12.9402 37.0916 12.1209 36.0809 12.1209C35.0703 12.1209 34.251 12.9402 34.251 13.9509C34.251 14.9616 35.0703 15.7809 36.0809 15.7809Z"
      fill="#003F27"
    />
    <path
      d="M36.5874 18.8773C36.5874 21.6896 35.9218 24.4621 34.645 26.9679C33.3682 29.4737 31.5165 31.6418 29.2413 33.2948C26.966 34.9479 24.3319 36.039 21.5542 36.4789C18.7764 36.9189 16.7157 36.9112 14.041 36.0421"
      stroke="#003F27"
      strokeWidth="0.780914"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M10.091 34.8662C7.56777 33.4972 5.4044 31.5504 3.77775 29.1851C2.1511 26.8198 1.10734 24.103 0.731823 21.257C0.356305 18.411 0.659686 15.5165 1.61716 12.8102C2.57463 10.1039 4.15902 7.66263 6.24077 5.68599"
      stroke="#003F27"
      strokeWidth="0.780914"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M9.25716 3.37005C11.7044 1.86942 14.472 0.969288 17.3338 0.743237C20.1956 0.517187 23.0702 0.971641 25.7227 2.06944C28.3752 3.16725 30.7302 4.87723 32.5951 7.05957C34.4601 9.24192 35.7821 11.8347 36.4531 14.6258"
      stroke="#003F27"
      strokeWidth="0.780914"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M16.4863 39.4195C18.2869 39.4195 19.7465 37.9598 19.7465 36.1592C19.7465 34.3587 18.2869 32.899 16.4863 32.899C14.6857 32.899 13.2261 34.3587 13.2261 36.1592C13.2261 37.9598 14.6857 39.4195 16.4863 39.4195Z"
      fill="#E3FFEE"
      stroke="#003F27"
      strokeWidth="0.780914"
      strokeLinecap="round"
    />
    <path
      d="M35.1162 15.666C36.9167 15.666 38.3764 14.2063 38.3764 12.4058C38.3764 10.6052 36.9167 9.14554 35.1162 9.14554C33.3156 9.14554 31.856 10.6052 31.856 12.4058C31.856 14.2063 33.3156 15.666 35.1162 15.666Z"
      fill="#E3FFEE"
      stroke="#003F27"
      strokeWidth="0.780914"
      strokeLinecap="round"
    />
    <path
      d="M4.60934 11.4741C6.40991 11.4741 7.86956 10.0145 7.86956 8.21392C7.86956 6.41336 6.40991 4.9537 4.60934 4.9537C2.80877 4.9537 1.34912 6.41336 1.34912 8.21392C1.34912 10.0145 2.80877 11.4741 4.60934 11.4741Z"
      fill="#E3FFEE"
      stroke="#003F27"
      strokeWidth="0.780914"
      strokeLinecap="round"
    />
  </SvgIcon>
);
