import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RibbonIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 11.25C12.7614 11.25 15 9.01142 15 6.25C15 3.48858 12.7614 1.25 10 1.25C7.23858 1.25 5 3.48858 5 6.25C5 9.01142 7.23858 11.25 10 11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.61133 8.89941L1.875 15.6252L5.26797 15.6088C5.37668 15.6082 5.48365 15.6361 5.57833 15.6895C5.673 15.7429 5.7521 15.8201 5.80781 15.9135L7.5 18.7502L10.9504 11.1596"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.318 8.75L18.125 15.625L14.732 15.6086C14.6233 15.608 14.5163 15.6359 14.4217 15.6893C14.327 15.7427 14.2479 15.8199 14.1922 15.9133L12.5 18.75L10 13.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8.75C11.3807 8.75 12.5 7.63071 12.5 6.25C12.5 4.86929 11.3807 3.75 10 3.75C8.61929 3.75 7.5 4.86929 7.5 6.25C7.5 7.63071 8.61929 8.75 10 8.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
