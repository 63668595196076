import React, { MouseEventHandler } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { isEmpty } from 'lodash';
import { ClickAwayListener } from '@material-ui/core';

import { RootState } from 'src/store/reduxTypes';
import {
  UpdateComponentAction,
  RemoveComponentAction,
  UpdateComponentDimensionsAction,
  SetSelectedComponent,
  RemoveSelectedComponent,
} from 'src/store/signaturePage/actions';
import { SignaturePageComponent } from 'src/store/signaturePage/types';
import {
  SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_WIDTH,
} from 'src/constants';
import {
  ContractDragItemType,
  ContractDraggableItem,
} from './ContractDraggableItem';

export interface ISize {
  height: number;
  width: number;
}
export interface SignatureDraggableComponentProps {
  component: SignaturePageComponent;
  requestComponentClicked: (component: SignaturePageComponent) => void;
  setElementReference: (element: HTMLButtonElement | null) => void;
  refreshElementsReference: boolean;
}

export const SignatureDraggableComponent: React.FC<
  SignatureDraggableComponentProps
> = ({
  component,
  requestComponentClicked,
  setElementReference,
  refreshElementsReference,
}) => {
  const dispatch = useDispatch();
  const draggableButtonRef = React.useRef<HTMLButtonElement>(null);

  const {
    isClient,
    startedEsignFlow,
    pageComponents,
    isSignatureButtonClickAwayDisabled,
  } = useSelector(
    (state: RootState) => ({
      isClient: state.user.isClient,
      currentStep: state.signaturePage.currentStep,
      startedEsignFlow: state.signaturePage.startedEsignFlow,
      pageComponents: state.signaturePage.pageComponents,
      isSignatureButtonClickAwayDisabled:
        state.signaturePage.isSignatureButtonClickAwayDisabled,
    }),
    shallowEqual,
  );

  const selectedComponentKey = useSelector(
    (state: RootState) => state.signaturePage.selectedComponentKey,
  );

  /**
   * Handle draggable component being moved. This will update
   * the store for signatures (not files store)
   * @param e the draggable event
   * @param data the data from draggable
   */
  const handleComponentMoved = (_: DraggableEvent, data: DraggableData) => {
    // Update signature component coords only when it's moved from the last position
    if (component.xPosition !== data.x || component.yPosition !== data.y) {
      const updatedCompnent = {
        ...component,
        xPosition: data.x,
        yPosition: data.y,
      };
      dispatch(UpdateComponentAction(updatedCompnent, data));
      // on drag stop make component selected
      if (component?.key) dispatch(SetSelectedComponent(component?.key));
    }
  };

  /**
   * Handle signature component being resized. This will update
   * the store for signatures (not files store)
   * @param dimensions the dimensions from resizable i.e height and width of the component
   */
  const handleComponentResized = (dimensions: ISize, componentId: string) => {
    const eSigComponent = pageComponents.find(
      (pageComponent) => pageComponent.key === componentId,
    );

    if (!isEmpty(eSigComponent)) {
      if (
        eSigComponent?.height !== dimensions.height ||
        eSigComponent?.width !== dimensions.width
      ) {
        const updatedCompnent = {
          ...component,
          ...dimensions,
        };
        dispatch(UpdateComponentDimensionsAction(updatedCompnent));
      }
    }
  };

  const handleDeSelectedComponent = () => {
    dispatch(RemoveSelectedComponent());
  };

  const handleComponentClicked: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!isClient) {
      //  make this component selected
      dispatch(SetSelectedComponent(component.key));
    }
    if (isClient && component.componentType.startsWith('request')) {
      // if this is a request component then trigger action based on what is being requested
      if (!startedEsignFlow) {
        // if user has not clicked to start flow then dont do anything or it is the 0 step
        return;
      }

      requestComponentClicked(component);
    }
  };

  const handleRemoveComponent = () => {
    dispatch(RemoveComponentAction(component.key));
  };

  const handleComponentClickAway = () => {
    if (isClient) return;

    if (isSignatureButtonClickAwayDisabled) return;

    if (selectedComponentKey === component.key) {
      // when internal user clicks away from component, make it not selected
      handleDeSelectedComponent();
    }
  };

  React.useEffect(() => {
    setElementReference(draggableButtonRef.current);
  }, []);

  React.useEffect(() => {
    if (refreshElementsReference) {
      setElementReference(draggableButtonRef.current);
    }
  }, [refreshElementsReference]);

  const stageDraggableComponent = React.useMemo(() => {
    const stagedComponent = { ...component };
    // If component width and height is not available fallback to default width and height
    if (stagedComponent) {
      if (!stagedComponent.width) {
        stagedComponent.width = SIGNATURE_COMPONENT_DEFAULT_WIDTH;
      }

      if (!stagedComponent?.height) {
        stagedComponent.height = SIGNATURE_COMPONENT_DEFAULT_HEIGHT;
      }
    }
    return stagedComponent;
  }, [component]);

  return (
    <ClickAwayListener onClickAway={handleComponentClickAway}>
      <ContractDraggableItem
        type={ContractDragItemType.Edit}
        ref={draggableButtonRef}
        component={stageDraggableComponent}
        handleComponentClicked={handleComponentClicked}
        handleComponentMoved={handleComponentMoved}
        handleComponentResized={handleComponentResized}
        handleRemoveComponent={handleRemoveComponent}
        isComponentSelected={selectedComponentKey === component.key}
        disableDragging={isClient}
      />
    </ClickAwayListener>
  );
};
