/**
 * Track a segment event while checking if library is initialized
 * @param eventName name of event to track
 * @param properties tracking properties for event
 * @returns
 */
export const TrackEvent = (
  eventName: string,
  properties?: Record<string, any>,
) => {
  if (!analytics || !analytics.track) {
    return Promise.resolve(false);
  }

  return new Promise((resolve) => {
    analytics.track(eventName, properties, () => {
      resolve(true);
    });
  });
};

export const TrackPartnerStackSignUp = (input: {
  name: string;
  email: string;
  customer_key: string;
  provider_key: string;
}) => {
  if (typeof growsumo === 'undefined' || !growsumo) {
    return;
  }

  growsumo.data = input;
  growsumo.createSignup();
};
