import React from 'react';
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    dialogBackdrop: {
      backgroundColor: 'transparent',
    },
  }),
);

const BackDrop = (props: { isModalOpen: boolean }) => {
  const classes = useStyles();
  const { isModalOpen } = props;
  return (
    <Backdrop className={classes.backdrop} open={isModalOpen}>
      <CircularProgress id="progress-bar" />
    </Backdrop>
  );
};

export default BackDrop;
