import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '.';
import { GetCurrentUser } from 'src/store/users/reducers';

export const GetCurrentUserFromState = (state: RootState) =>
  GetCurrentUser({
    usersState: state.users || {},
    currentUserId: state.user?.id || '',
    isClient: state.user?.isClient,
  });

export const selectCurrentUser = createSelector(
  (state: RootState) => state.users.users,
  (state: RootState) => state.users.internalUsers,
  (state: RootState) => state.user.id,
  (state: RootState) => state.user.isClient,
  (users, internalUsers, currentUserId, isClient) => {
    const allUsers = isClient ? users : internalUsers;
    return allUsers.find(
      // if client compare id, otherwise compare cognitoUsername
      (u) => (isClient ? u.id : u.cognitoUsername) === currentUserId,
    );
  },
);
