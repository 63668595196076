import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PhonePortraitIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.125 0.625H6.875C5.83947 0.625 5 1.46447 5 2.5V17.5C5 18.5355 5.83947 19.375 6.875 19.375H13.125C14.1605 19.375 15 18.5355 15 17.5V2.5C15 1.46447 14.1605 0.625 13.125 0.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 0.625H7.8125C7.89538 0.625 7.97487 0.657924 8.03347 0.716529C8.09208 0.775134 8.125 0.85462 8.125 0.9375C8.125 1.10326 8.19085 1.26223 8.30806 1.37944C8.42527 1.49665 8.58424 1.5625 8.75 1.5625H11.25C11.4158 1.5625 11.5747 1.49665 11.6919 1.37944C11.8092 1.26223 11.875 1.10326 11.875 0.9375C11.875 0.85462 11.9079 0.775134 11.9665 0.716529C12.0251 0.657924 12.1046 0.625 12.1875 0.625H13.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
