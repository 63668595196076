import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Heading3Icon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M3.98 8.3175H10.1225V12.2175C10.1225 13.7125 10.025 13.8425 8.855 13.875V14.2812H12.8525V13.875C11.6988 13.8425 11.6012 13.7125 11.6012 12.2175V4.56375C11.6012 3.0525 11.6825 2.955 12.8525 2.90625V2.5H8.855V2.90625C10.0413 2.955 10.1225 3.0525 10.1225 4.56375V7.7975H3.98V4.56375C3.98 3.0525 4.06125 2.955 5.2475 2.90625V2.5H1.25V2.90625C2.42 2.955 2.50125 3.0525 2.50125 4.56375V12.2175C2.50125 13.7288 2.40375 13.8425 1.25 13.875V14.2812H5.2475V13.875C4.0775 13.8425 3.98 13.7125 3.98 12.2175V8.3175Z"
      fill="currentColor"
    />
    <path
      d="M16.6237 12.8121C17.0974 12.7058 17.3816 12.5995 17.6763 12.4224C18.2132 12.0682 18.5395 11.4777 18.5395 10.8165C18.5395 9.62382 17.5605 8.75 16.2237 8.75C14.9711 8.75 13.9289 9.58839 13.9289 10.5921C13.9289 10.9109 14.0974 11.0408 14.4868 11.0408C14.8553 11.0408 14.95 10.9227 14.9711 10.474C15.0237 9.52935 15.4237 9.06883 16.2132 9.06883C16.7395 9.06883 17.0868 9.25776 17.3079 9.65924C17.4763 9.96626 17.5711 10.3796 17.5711 10.8401C17.5711 11.5132 17.3605 12.0445 16.9711 12.3397C16.6658 12.5641 16.2447 12.694 15.8237 12.694H15.3605V13.0719H15.8237C16.2974 13.0719 16.7395 13.1781 17.0447 13.3553C17.4974 13.6269 17.75 14.2409 17.75 15.0675C17.75 16.4018 17.1395 17.1812 16.0974 17.1812C15.6342 17.1812 15.2658 17.0277 15.0553 16.7443C14.8658 16.4845 14.8132 16.2601 14.7921 15.6815C14.7711 15.3509 14.6553 15.2328 14.35 15.2328C13.9395 15.2328 13.75 15.4099 13.75 15.7878C13.75 16.7679 14.7711 17.5 16.1395 17.5C17.7079 17.5 18.75 16.5317 18.75 15.0793C18.75 14.2645 18.4026 13.6032 17.8026 13.249C17.4658 13.0601 17.15 12.9656 16.6237 12.9184V12.8121Z"
      fill="currentColor"
    />
  </SvgIcon>
);
