import React, { useContext } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab';
import clsx from 'clsx';
import { FlagsContext } from 'src/context';

/**
 * Autocomplete Component
 *
 * This is a wrapper around MUI's `Autocomplete` component that allows for
 * additional customization while maintaining the existing MUI props.
 *
 * Key Features:
 * - Uses `FlagsContext` to conditionally apply error styles.
 * - Updates the error outline color when `EnableInputErrorIcon` flag is enabled.
 */

const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { EnableInputErrorIcon } = useContext(FlagsContext);

  return (
    <MuiAutocomplete
      {...props}
      classes={{
        ...props.classes,
        inputRoot: clsx(props.classes?.inputRoot, {
          ['[&.Mui-error_fieldset]:!border-error-primary']:
            EnableInputErrorIcon,
        }),
      }}
    />
  );
};

export default Autocomplete;
