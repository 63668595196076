import { PostOrPage } from '@tryghost/content-api';
import { Button, Heading, Icon } from 'copilot-design-system';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { HtmlRenderer } from 'src/components/HTMLRenderer';
import { COPILOT_CHANGELOG_LINK } from 'src/constants';
import { useEsc } from 'src/hooks/useEsc';
import { AlertSnackbar } from 'src/legacy/components/AlertSnackbar/AlertSnackbar';
import LegacyButton from 'src/legacy/components/Button';
import { HeartFilled } from 'src/legacy/components/Icons';
import { useUpdateLatestReleaseDismissalMutation } from 'src/services/api/LatestReleaseApi';
import { AlertVariant } from 'src/store/ui/types';
import { DateUtils } from 'src/utils';

interface WhatsNewModal {
  latestReleasePost: PostOrPage | null;
}

export const WhatsNewModal: React.FC<WhatsNewModal> = ({
  latestReleasePost,
}) => {
  const [updateLatestReleaseDismissal] =
    useUpdateLatestReleaseDismissalMutation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleUndoDismiss = () => {
    updateLatestReleaseDismissal({
      structFields: { latest_release_dismissed: '' },
    });
  };

  const successDismissSnackbar = React.useCallback(
    (snackbarKey: string) => (
      <div key={snackbarKey}>
        <AlertSnackbar
          variant={AlertVariant.SUCCESS}
          onClose={() => closeSnackbar(snackbarKey)}
          alertMessage="Release update closed"
          onAction={handleUndoDismiss}
          actionLabel="Undo"
        />
      </div>
    ),
    [closeSnackbar],
  );

  const handleDismissLatestRelease = async () => {
    if (!latestReleasePost) return;
    const result = await updateLatestReleaseDismissal({
      structFields: {
        latest_release_dismissed: latestReleasePost.id,
      },
    });

    if ('data' in result) {
      enqueueSnackbar('Resource has been dismissed', {
        content: (snackbarKey: string) => successDismissSnackbar(snackbarKey),
      });
    }
  };

  useEsc(() => {
    handleDismissLatestRelease();
  });

  const isReleasePostOlderThan14Days = useMemo(() => {
    if (!latestReleasePost?.published_at) return true;

    const publishedDate = new Date(latestReleasePost.published_at);

    const daysSinceRelease = DateUtils.getDiffFromDate(publishedDate, 'days');

    return daysSinceRelease > 14;
  }, [latestReleasePost]);

  if (!latestReleasePost?.html || isReleasePostOlderThan14Days) return null;

  return (
    // Background Overlay
    <div
      className="fixed inset-0 h-screen w-screen bg-black bg-opacity-40 z-modal items-center justify-center hidden lg:flex"
      onClick={handleDismissLatestRelease}
    >
      {/* Modal */}
      <div
        className="w-11/12 h-full max-h-[792px] max-w-[1094px] bg-white overflow-hidden rounded-4 shadow-popover-500"
        onClick={(e) => e.stopPropagation()} // Prevents the modal from closing when clicked inside since the overlay is also listening for clicks
      >
        <div className="flex items-center justify-between py-3 px-4 bg-[#F1EEFF]">
          <div className="flex items-center">
            <HeartFilled className="text-[#4B3AA3] mr-2 h-3 w-3" />
            <Heading size="lg" className="text-[#4B3AA3]">
              What's New
            </Heading>
          </div>
          <Button
            size="sm"
            label="Close"
            prefixIcon="Close"
            onClick={handleDismissLatestRelease}
            className="bg-white hover:bg-gray-100"
            variant="secondary"
          />
        </div>
        <div
          className="overflow-y-auto px-6"
          style={{ height: 'calc(100% - 52px)' }} // 52px is the height of the header
        >
          <div className="py-6 w-full max-w-[900px] mx-auto">
            <HtmlRenderer html={latestReleasePost.html} />
            <a
              href={COPILOT_CHANGELOG_LINK}
              target="_blank"
              className="inline-block mt-6"
            >
              {/* TODO: Replace with design system button. Current secondary button doesn't support endIcon */}
              <LegacyButton
                htmlId="view-all-releases"
                variant="contained"
                color="secondary"
                endIcon={<Icon icon="ArrowNE" />}
              >
                View all releases
              </LegacyButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
