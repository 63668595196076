import { TablePropertyFields } from 'src/constants/dataTypes';

export const DefaultCRMColumnSettings: TablePropertyFields = {
  companiesPropertyFields: [
    {
      id: 'company',
      name: 'Name',
      disabled: false,
    },
    {
      id: 'createdDate',
      name: 'Created Date',
      disabled: false,
    },
  ],
  tablePropertyFields: [
    {
      id: 'userName',
      name: 'Name',
      disabled: false,
    },
    {
      id: 'company',
      name: 'Company',
      disabled: false,
    },
    {
      id: 'status',
      name: 'Status',
      disabled: false,
    },
    {
      id: 'creationDate',
      name: 'Creation date',
      disabled: false,
    },
    {
      id: 'invitedBy',
      name: 'Invited by',
      disabled: true,
    },
  ],
};
