import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CloudCircleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.0421 9.39805C12.9768 9.38778 12.9164 9.35706 12.8697 9.31031C12.8229 9.26357 12.7922 9.2032 12.7819 9.13789C12.5265 7.53047 11.3601 6.5625 9.99991 6.5625C8.73546 6.5625 7.89327 7.39141 7.55928 8.26484C7.53989 8.31568 7.50744 8.36051 7.46519 8.3948C7.42294 8.42909 7.3724 8.45164 7.31866 8.46016C6.2378 8.63008 5.3335 9.34141 5.3128 10.5813C5.29249 11.8129 6.30928 12.8121 7.53936 12.8121H12.7343C13.8085 12.8121 14.6874 12.2762 14.6874 11.0934C14.6874 10.0301 13.828 9.51484 13.0421 9.39805Z"
      fill="currentColor"
    />
    <path
      d="M17.5 10C17.5 5.85938 14.1406 2.5 10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
