/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type KeyPressing = Record<string, boolean>;

export type ShortcutsState = {
  registeredShortcuts: Record<string, boolean>;
  pressedKeysState: KeyPressing;
  sidebarOpen: boolean;
};

const initialState: ShortcutsState = {
  registeredShortcuts: {},
  pressedKeysState: {
    escape: false,
    shift: false,
    enter: false,
    g: false,
    c: false,
    m: false,
    f: false,
    s: false,
    b: false,
    k: false,
    '?': false,
  },
  sidebarOpen: false,
};

const isStatePropertyChanged = (
  newStateProperty: Record<string, boolean>,
  existingStateProperty: Record<string, boolean>,
) =>
  Object.entries(newStateProperty).some(([key, value]) => {
    const oldValue = existingStateProperty[key];
    return oldValue !== value;
  });

export const shortcutsSlice = createSlice({
  name: 'shortcuts',
  initialState,
  reducers: {
    shortcutKeyPressing: (state, action: PayloadAction<KeyPressing>) => {
      const isChanged = isStatePropertyChanged(
        action.payload,
        state.pressedKeysState,
      );
      if (!isChanged) {
        return;
      }
      state.pressedKeysState = { ...state.pressedKeysState, ...action.payload };
    },
    registerShortcuts: (state, action: PayloadAction<KeyPressing>) => {
      const isChanged = isStatePropertyChanged(
        action.payload,
        state.registeredShortcuts,
      );
      if (!isChanged) {
        return;
      }
      state.registeredShortcuts = {
        ...state.registeredShortcuts,
        ...action.payload,
      };
    },
    shortcutsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
  },
});

export const { registerShortcuts, shortcutKeyPressing, shortcutsSidebarOpen } =
  shortcutsSlice.actions;

export const shortcutsReducer = shortcutsSlice.reducer;
