import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useDragDropManager, useDragLayer, XYCoord } from 'react-dnd';
import { ZIndex } from '@material-ui/core/styles/zIndex';
import { DragPreviewItem } from 'src/legacy/components/Signature/DragPreviewItem';
import ColorUtils from 'src/utils/ColorUtils';
import * as Colors from 'src/theme/colors';
import {
  SignatureDateIcon,
  SignatureIcon as SignIcon,
  SignatureTextIcon,
  SignatureInitialIcon,
} from 'src/legacy/components/Icons';
import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_WIDTH,
} from 'src/constants';
import { DRAG_ITEM_TYPE } from 'src/legacy/components/Signature/SignatureComponents/SignatureSidebarButton';

export const SignatureIconComponent = {
  Signature: SignIcon,
  Initials: SignatureInitialIcon,
  Date: SignatureDateIcon,
  Text: SignatureTextIcon,
};

type StyleProps = {
  offset: XYCoord | null;
  canDrop: boolean | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, StyleProps>({
    signatureButton: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex:
        (theme.zIndex as ZIndex & { loadingSpinner: number }).loadingSpinner -
        1, // ZIndex doesn't have the loadingSpinner defined in types.
      padding: theme.spacing(0.5, 1),
      justifyContent: 'center',
      boxShadow: 'none',
      height: SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
      minWidth: SIGNATURE_COMPONENT_DEFAULT_WIDTH,
      pointerEvents: 'none',
      opacity: 0.5,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: ColorUtils.GetColorDarknessShades(
        theme.palette.primary.main,
      ).light,
    },
    signatureButtonTransform: {
      transform: (props) =>
        `translate(${props.offset?.x}px, ${props.offset?.y}px)`,
    },
    invalidSignatureButton: {
      borderColor: Colors.red,
      color: Colors.red,
      backgroundColor: ColorUtils.GetColorDarknessShades(Colors.red).light,
    },
    contractButton: {
      height: CONTRACT_COMPONENT_DEFAULT_HEIGHT,
      paddingRight: theme.spacing(2),
      paddingLeft: '4px',
      justifyContent: 'flex-start',
      '& svg': {
        marginLeft: '4px',
      },
      '& .MuiButton-startIcon': {
        // MUI already uses "!important" on their start icon,
        // so we need "!important" here to override that
        marginRight: '0px !important',
        '& svg': {
          height: '8px',
          width: '8px',
        },
      },
    },
  }),
);

export const BoxDragPreview = () => {
  const manager = useDragDropManager();

  // Retrieve information about the currently dragged item
  const { offset, item } = useDragLayer((monitor) => ({
    offset: monitor.getSourceClientOffset(), // Top-left offset of the dragged item
    item: monitor.getItem(), // Data of the currently dragged item set in the SignatureSidebarButton useDrag hook
  }));

  // Verifying the dragging item is over a valid drop area
  const monitor = manager.getMonitor();
  const [id] = monitor.getTargetIds() || [];
  const isValidDroppableArea = monitor.canDropOnTarget(id);

  const classes = useStyles({
    offset,
    canDrop: isValidDroppableArea,
  });

  // item is null when nothing is being dragged
  const isSignComponentDragging = item && item.type === DRAG_ITEM_TYPE;

  // If no signature item is being dragged, return null to prevent rendering the drag preview
  if (!isSignComponentDragging) return null;

  return (
    <DragPreviewItem
      name={item.name}
      icon={item.icon}
      classes={classes}
      canDrop={isValidDroppableArea}
    />
  );
};
