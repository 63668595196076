import * as errorConsts from 'src/constants/errorConsts/errorCodesConsts';

/**
 * Set of error messages that can be ignored for tracking purposes
 */
export const IgnoreTrackingErrorMessagesSet: Record<string, boolean> = {
  [errorConsts.UserErrorMessage.emailInUse]: true,
  [errorConsts.UserErrorMessage.invalidCredentials]: true,
  [errorConsts.FormValidationErrorMessage.missingTitle]: true,
  [errorConsts.FormValidationErrorMessage.missingName]: true,
  [errorConsts.FormValidationErrorMessage.missingQuestion]: true,
  [errorConsts.KnowledgeBaseErrorMessages.missingContent]: true,
  [errorConsts.BillingErrorMessage.subBlockPaymentDelete]: true,
};
