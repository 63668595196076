import { API } from 'src/utils/AmplifyApiUtils';
import { Notification } from 'src/store/notifications/types';
import { NOTIFICATIONS_STATUSES } from 'src/constants';

export default class NotificationsClient {
  /**
   * After a channel is selected, this API method
   * is called to mark channel's unread notifications are read.
   * @param unreadNotifications
   * @param notificationEntity
   */
  static markFileNotificationAsRead(
    unreadNotifications: Array<Notification>,
    notificationEntity: string,
  ) {
    return API.put('AppAPI', `/entities/${notificationEntity}`, {
      queryStringParameters: {
        updateState: NOTIFICATIONS_STATUSES.READ,
      },
      body: {
        data: unreadNotifications,
      },
    });
  }
}
