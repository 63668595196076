export const UNAUTH_USER_ID = 'unauth';

export const SIDE_NAVBAR_WIDE_WIDTH = 317;

export const SIGNATURE_SIDEBAR_WIDE_WIDTH = 200;

export const RIGHT_SIDEBAR_WIDTH = 380;

export const AppAPIName = 'AppAPI';

export const UsersAPIName = 'UsersAPI';

export const MessagesAPIName = 'MessagesAPI';

export const UnAuthAPIName = 'UnAuthAPI';

export * from './paymentsConsts';
export * from './clientConsts';
export * from './pathConsts';
export * from './dataTypes';
export * from './componentTypes';
export * from './filesConsts';
export * from './fileConsts';
export * from './fieldsTypes';
export * from './permissionConsts';
export * from './entityConsts';
export * from './dateConsts';
export * from './userConsts';
export * from './validationConsts';
export * from './notificationsConsts';
export * from './errorConsts';
export * from './onboardingConsts';
export * from './stringConsts';
export * from './domainConsts';
export * from './referralConsts';
export * from './tableColumnConsts';
export * from './moduleManagementConsts';
export * from './signatureConsts';
export * from './fontsConsts';
export * from './uiConsts';
export * from './webhooks';
export * from './appsConst';
