import { useGetDisabledPermissionsQuery } from 'src/services/api/permissionsApi';
import {
  DisabledReason,
  GENERAL_OPERATIONS,
  PERM_MOD_CRM,
} from 'src/constants';

export const useCreateClientPermission = () => {
  const { data: disabledPermissions } = useGetDisabledPermissionsQuery();

  return disabledPermissions?.some(
    ({ permissionName, operationName, disabledReason }) => {
      return (
        permissionName === PERM_MOD_CRM &&
        operationName === GENERAL_OPERATIONS.CREATE &&
        disabledReason === DisabledReason.LimitReached
      );
    },
  );
};
