import { DeleteShortcutsValues } from 'src/legacy/components/ShortcutsDrawer/shortcutsSidebarConsts';
import { getModKeyState } from 'src/utils/KeyEventUtils';

export const useOSDetector = () => {
  const isMac = navigator.userAgent.includes('Mac');

  const isOSMetaKeyPressed = (ev: KeyboardEvent) => {
    if (isMac) {
      return getModKeyState(ev, 'Meta');
    }
    return getModKeyState(ev, 'Control');
  };

  const isOSDeleteKeyPressed = (
    event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (isMac) {
      return event.key === DeleteShortcutsValues.MAC_OS_KEY;
    }
    return event.key === DeleteShortcutsValues.WINDOWS_KEY;
  };

  return {
    isOSMetaKeyPressed,
    isMac,
    isOSDeleteKeyPressed,
  };
};
