import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const UmbrellaIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 10.625V16.875C10 17.2065 9.8683 17.5245 9.63388 17.7589C9.39946 17.9933 9.08152 18.125 8.75 18.125C8.41848 18.125 8.10054 17.9933 7.86612 17.7589C7.6317 17.5245 7.5 17.2065 7.5 16.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.125 10.625C18.125 6.1375 14.4875 2.5 10 2.5C5.5125 2.5 1.875 6.1375 1.875 10.625C2.12122 10.3788 2.41354 10.1834 2.73525 10.0502C3.05696 9.9169 3.40178 9.84831 3.75 9.84831C4.09822 9.84831 4.44304 9.9169 4.76475 10.0502C5.08646 10.1834 5.37878 10.3788 5.625 10.625C6.14546 10.1046 6.83613 9.78945 7.57025 9.73729C8.30437 9.68512 9.03267 9.89948 9.62148 10.341L10 10.625L10.3785 10.341C10.9673 9.89948 11.6956 9.68512 12.4297 9.73729C13.1639 9.78945 13.8545 10.1046 14.375 10.625V10.625C14.6212 10.3788 14.9135 10.1834 15.2352 10.0502C15.557 9.9169 15.9018 9.84831 16.25 9.84831C16.5982 9.84831 16.943 9.9169 17.2648 10.0502C17.5865 10.1834 17.8788 10.3788 18.125 10.625V10.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.5V1.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
