import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WalkIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.274 18.8406L10.0564 14.3586L8.30293 12.1168C8.04388 11.6785 7.90723 11.1787 7.90723 10.6695V5.625H8.51934C8.72569 5.62495 8.93003 5.66555 9.12069 5.74448C9.31135 5.82341 9.48459 5.93913 9.63052 6.08502C9.77645 6.23092 9.89221 6.40413 9.97119 6.59477C10.0502 6.78541 10.0908 6.98974 10.0908 7.19609V14.3586"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99609 11.4473V8.53632C4.99609 8.53632 6.45156 5.625 7.90703 5.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.457 10.7195L11.875 9.02344"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66113 18.6859L8.7498 15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0902 4.16948C10.8941 4.16948 11.5457 3.51785 11.5457 2.71401C11.5457 1.91018 10.8941 1.25854 10.0902 1.25854C9.2864 1.25854 8.63477 1.91018 8.63477 2.71401C8.63477 3.51785 9.2864 4.16948 10.0902 4.16948Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
