import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BrowsersIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.25 2.5H3.75C2.71447 2.5 1.875 3.33947 1.875 4.375V15.625C1.875 16.6605 2.71447 17.5 3.75 17.5H16.25C17.2855 17.5 18.125 16.6605 18.125 15.625V4.375C18.125 3.33947 17.2855 2.5 16.25 2.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M15.5398 2.5H4.46016C3.03477 2.5 1.875 3.67773 1.875 5.125V6.875H2.5C2.5 6.25 3.125 5.625 3.75 5.625H16.25C16.875 5.625 17.5 6.25 17.5 6.875H18.125V5.125C18.125 3.67773 16.9652 2.5 15.5398 2.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
