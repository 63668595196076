import { useGetNotificationsSettingsQuery } from 'src/services/api';

export const useInProductNotificationsState = () => {
  const { data: notificationSettings } = useGetNotificationsSettingsQuery();

  // we don't want to show 'Inbox' sidebar item when
  // a. all in product notifications are disabled
  // b. upload file notifications are disabled
  // c. signed contract i.e esign complete notifications are disabled
  const isInProductInboxNotificationsDisabled =
    notificationSettings?.disableInProduct ||
    (notificationSettings?.notifyAbout.uploadedFiles.disableInProduct &&
      notificationSettings.notifyAbout.signedContracts.disableInProduct &&
      notificationSettings.notifyAbout.newFormResponses.disableInProduct &&
      notificationSettings.notifyAbout.paidInvoices.disableInProduct);

  return {
    isInboxInProductNotificationsDisabled:
      isInProductInboxNotificationsDisabled,
    isMessagesInProductNotificationsDisabled:
      notificationSettings?.notifyAbout.newMessages.disableInProduct,
  };
};
