import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GoogleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#clip0_13477_5)">
      <path
        d="M24.7073 12.7338C24.7073 11.7095 24.6242 10.9621 24.4443 10.187H12.7485V14.8099H19.6137C19.4753 15.9588 18.7279 17.6889 17.067 18.8515L17.0437 19.0063L20.7417 21.8711L20.9979 21.8966C23.3508 19.7236 24.7073 16.5262 24.7073 12.7338Z"
        fill="#4285F4"
      />
      <path
        d="M12.7485 24.9141C16.1119 24.9141 18.9355 23.8068 20.9979 21.8967L17.067 18.8515C16.0151 19.5851 14.6032 20.0973 12.7485 20.0973C9.45434 20.0973 6.65842 17.9242 5.66175 14.9207L5.51566 14.9331L1.6704 17.909L1.62012 18.0488C3.66861 22.1181 7.87637 24.9141 12.7485 24.9141Z"
        fill="#34A853"
      />
      <path
        d="M5.66134 14.9207C5.39836 14.1456 5.24617 13.315 5.24617 12.4569C5.24617 11.5987 5.39836 10.7683 5.6475 9.99318L5.64054 9.82811L1.74712 6.80444L1.61974 6.86504C0.775462 8.55368 0.291016 10.45 0.291016 12.4569C0.291016 14.4639 0.775462 16.3601 1.61974 18.0487L5.66134 14.9207Z"
        fill="#FBBC05"
      />
      <path
        d="M12.7485 4.8167C15.0876 4.8167 16.6654 5.8271 17.5652 6.67147L21.0808 3.23884C18.9216 1.23187 16.1118 0 12.7485 0C7.87632 0 3.66859 2.7959 1.62012 6.86517L5.64788 9.99332C6.65838 6.98979 9.45428 4.8167 12.7485 4.8167Z"
        fill="#EB4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_13477_5">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
