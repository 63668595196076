import React from 'react';
import { RouteContext } from 'src/context';
import {
  useGetFormByIdQuery,
  useGetFormResponsesQuery,
  useGetResponsesByFormIdQuery,
} from 'src/services/api/formsApi';
import { FormResponseStatus } from 'src/legacy/components/FormsV2/formsTypes';
import { useAppSelector } from 'src/hooks/useStore';

/**
 * Custom hook that encapsulates forms and responses RTK Queries.
 * Filters out pending and responded forms.
 * One major difference is in queries for internal admin and client.
 * For internal user, we query `useGetResponsesByFormIdQuery` to get all responses for a form.
 * For client, we query `useGetFormResponsesQuery` to get all responses for that client.
 * Hence selectedResponse and isResponseLoading are different for internal admin and client based on the above queries.
 * @returns [respondedForms, pendingForms, selectedForm, selectedResponse, isResponseLoading]
 */
export const useFormResponse = (options?: {
  formId: string;
  responseRefId: string;
}) => {
  const { query } = React.useContext(RouteContext);
  const isClient = useAppSelector((state) => state.user.isClient);
  const { formId, responseRefId } = options || query;
  const { data: form } = useGetFormByIdQuery(formId as string);
  const {
    data: responsesForInternalAdmin = [],
    isLoading: areInternalResponsesLoading,
  } = useGetResponsesByFormIdQuery(formId as string, {
    skip: isClient,
  });

  const {
    data: responsesForClient = [],
    isLoading: isResponsesForClientLoading,
  } = useGetFormResponsesQuery(undefined, { skip: !isClient });

  const respondedForms = (responsesForInternalAdmin || []).filter(
    (response) => response.fields.status === FormResponseStatus.Completed,
  );

  const pendingForms = (responsesForInternalAdmin || []).filter(
    (response) => response.fields.status === FormResponseStatus.Pending,
  );

  const selectedResponse = (
    isClient ? responsesForClient : responsesForInternalAdmin || []
  ).find((x) => x.ref === responseRefId);

  return {
    respondedForms,
    pendingForms,
    isResponseLoading: isClient
      ? isResponsesForClientLoading
      : areInternalResponsesLoading,
    selectedResponse,
    selectedForm: form,
  };
};
