import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PaperClipIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M18.6442 9.43839L10.6182 17.4644C9.63499 18.4476 8.30143 19 6.91091 19C5.5204 19 4.18684 18.4476 3.20359 17.4644C2.22035 16.4811 1.66797 15.1476 1.66797 13.7571C1.66797 12.3665 2.22035 11.033 3.20359 10.0497L11.2296 2.02375C11.8851 1.36825 12.7741 1 13.7011 1C14.6281 1 15.5172 1.36825 16.1727 2.02375C16.8282 2.67924 17.1964 3.56829 17.1964 4.4953C17.1964 5.42231 16.8282 6.31135 16.1727 6.96685L8.13796 14.9928C7.81021 15.3206 7.36569 15.5047 6.90218 15.5047C6.43868 15.5047 5.99415 15.3206 5.66641 14.9928C5.33866 14.6651 5.15453 14.2206 5.15453 13.7571C5.15453 13.2935 5.33866 12.849 5.66641 12.5213L13.0811 5.11537"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
