import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { ForwardIcon } from 'src/legacy/components/Icons';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuTitleWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: 'auto',
    },
    menuTitle: {
      color: theme.palette.primary.main,
    },
    forwardIcon: {
      fontSize: 8,
      margin: theme.spacing(0, 1.5),
      color: theme.palette.primary.main,
    },
  }),
);
interface CommandbarMenuTitleProps {
  menuTitle: string;
}

export const CommandbarMenuTitle: React.FC<CommandbarMenuTitleProps> = ({
  menuTitle,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.menuTitleWrapper}>
      <BaseTypography className={classes.menuTitle} fontType="15Medium">
        {menuTitle}
      </BaseTypography>
      <ForwardIcon className={classes.forwardIcon} />
    </div>
  );
};
