import { copilotDotCom } from 'src/constants/hostnameConsts';

export const MESSAGE_CHANNEL_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-messages-app`;

export const FILE_CHANNEL_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-files-app`;

export const FORMS_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-forms-app`;

export const PRODUCTS_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-billing-app#products`;

export const INVOICES_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-billing-app#invoicing`;

export const SUBSCRIPTION_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-billing-app#subscriptions`;

export const KNOWLEDGE_BASE_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-helpdesk-app`;

export const INBOX_PAGE_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/internal-user-notifications#notification-center`;

export const NOTIFICATIONS_PAGE_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/internal-user-notifications`;

export const APPS_PAGE_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/intro-to-apps`;

export const AUTOMATIONS_PAGE_EMPTY_STATE_LEARN_MORE = `https://www.${copilotDotCom.prod}/guide/copilot-automations`;
