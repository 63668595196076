/* eslint-disable no-param-reassign */
import { OnboardingStatus } from 'src/entities/OnboardingStatus';
import { improvedOnboardingApi } from 'src/services/api/improvedOnboardingApi';
import { setTrialState } from 'src/store/user/actions';
import { AppThunkAction } from 'src/store';
import { Dispatch } from 'redux';

export const OnboardingTaskStatusHandler =
  (onboardingStatuses: OnboardingStatus): AppThunkAction =>
  async (dispatch: Dispatch<any>, getState) => {
    const stripeSubscriptions = getState().user.data?.stripeSubscriptions;
    dispatch(setTrialState(stripeSubscriptions, onboardingStatuses));

    dispatch(
      improvedOnboardingApi.util.updateQueryData(
        'getOnboardingTaskStatus',
        { subscriptions: stripeSubscriptions },
        (onboardingStatusDraft) => {
          onboardingStatusDraft.fields = onboardingStatuses.fields;
          onboardingStatusDraft.additionalFields =
            onboardingStatuses.additionalFields;
        },
      ),
    );
  };
