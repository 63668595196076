import {
  ADD_INTERNAL_USERS,
  ADD_USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  LOAD_USERS_FAILURE,
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  MODIFY_INTERNAL_USERS,
  REMOVE_INTERNAL_USERS,
  SET_INTERNAL_USERS,
  SET_USER_ROLE_FAILURE,
  SET_USER_ROLE_REQUEST,
  SET_USER_ROLE_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_USERS_SUCCESS,
  UsersActionTypes,
  UsersState,
} from 'src/store/users/types';

import { User } from 'src/constants/dataTypes';

const initialState: UsersState = {
  users: [],
  internalUsers: [],
  isLoading: false,
  error: '',
  loadedUsers: false,
  isCreating: false,
  isCreated: false,
  isUpdated: false,
  isUpdating: false,
  isResendingInvite: false,
};

type getCurrentUserInput = {
  currentUserId: string;
  isClient: boolean;
  usersState: UsersState;
};

export const GetCurrentUser = (input: getCurrentUserInput) => {
  const { currentUserId, isClient, usersState } = input;
  const { users = [], internalUsers = [] } = usersState;
  const usersToSearch = isClient ? users : internalUsers;
  return usersToSearch.find(
    // if client compare id, otherwise compare cognitoUsername
    (u) => (isClient ? u.id : u.cognitoUsername) === currentUserId,
  );
};

// eslint-disable-next-line default-param-last
const usersReducer = (state = initialState, action: UsersActionTypes) => {
  switch (action.type) {
    case LOAD_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case LOAD_USERS_SUCCESS: {
      const users = action.payload;
      return {
        ...state,
        isLoading: false,
        loadedUsers: true,
        users,
        error: '',
      };
    }
    case UPDATE_USERS_SUCCESS: {
      // merge users with the users in the payload based on the id
      const users = action.payload;

      // collect ids to be updated
      const modifiedIds = users.map((u) => u.id);

      return {
        ...state,
        // concat updated users and remove duplicates
        users: state.users
          .filter((u) => !modifiedIds.includes(u.id))
          .concat(users),
      };
    }
    case LOAD_USERS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isLoading: false,
        loadedUsers: false,
        error,
      };
    }
    case ADD_USER_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: '',
      };
    case ADD_USER_SUCCESS: {
      const { user: newUser, isClient } = action.payload;
      // get all users in state that are not in payload
      const isDuplicateNewUser = (
        isClient ? state.users : state.internalUsers
      ).find((currentUser) => currentUser.id === newUser.id);

      if (isDuplicateNewUser) {
        return state;
      }

      return {
        ...state,
        isCreating: false,
        isCreated: true,
        ...(isClient
          ? { users: state.users.concat([newUser]) }
          : { internalUsers: state.internalUsers.concat([newUser]) }),
        error: '',
      };
    }
    case ADD_USER_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error,
      };
    }
    case UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
        error: null,
      };

    case UPDATE_MEMBER_SUCCESS: {
      const { payload } = action;
      let updatedUser = state.users.find((user) => user.id === payload.id);

      if (updatedUser) {
        updatedUser = {
          ...updatedUser,
          fields: {
            ...updatedUser.fields,
            ...payload.fields,
          },
        };
      }

      let updatedData = state.users.filter(
        (client) => client.id !== payload.id,
      );
      if (updatedUser) {
        updatedData = updatedData.concat([updatedUser]);
      }

      return {
        ...state,
        users: updatedData,
        isUpdating: false,
        updatedClient: true,
        error: null,
      };
    }

    case UPDATE_MEMBER_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        error: action.error,
      };
    }

    case SET_USER_ROLE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
        error: '',
      };

    case SET_USER_ROLE_SUCCESS: {
      const updatedUserId = action.payload;
      const newUserRoles = action.newRoles;

      const usersStaged = state.users.map((user) => {
        if (user.id === updatedUserId) {
          return {
            ...user,
            fields: {
              ...user.fields,
              roles: newUserRoles,
            },
          };
        }

        return user;
      });

      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        users: usersStaged,
        error: '',
      };
    }

    case SET_USER_ROLE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        error,
      };
    }

    case DELETE_USER_REQUEST:
      return state;
    case DELETE_USER_FAILED:
      return state;
    case DELETE_USER_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        users: state.users.filter((u) => u.id !== id),
      };
    }
    case SET_INTERNAL_USERS: {
      const internalUsers = action.payload;
      return {
        ...state,
        internalUsers,
        loadedUsers: true,
      };
    }
    case ADD_INTERNAL_USERS: {
      const addedInternalUsers: User[] = action.payload;
      let updatedInternalUsers: User[] = state.internalUsers;
      const existingIDs: string[] = updatedInternalUsers.map((u) => u.id);
      addedInternalUsers.forEach((addedUser) => {
        if (!existingIDs.includes(addedUser.id)) {
          updatedInternalUsers = updatedInternalUsers.concat(addedUser);
        }
      });
      return {
        ...state,
        internalUsers: updatedInternalUsers,
      };
    }

    case MODIFY_INTERNAL_USERS: {
      const modifiedInternalUsers: User[] = action.payload;
      const modifiedIDMap: Record<string, User> = {};
      modifiedInternalUsers.forEach((u) => {
        modifiedIDMap[u.id] = u;
      });
      let finalizedInternalUsers: User[] = [];
      state.internalUsers.forEach((u) => {
        if (u.id in modifiedIDMap) {
          finalizedInternalUsers = finalizedInternalUsers.concat(
            modifiedIDMap[u.id],
          );
        } else {
          finalizedInternalUsers = finalizedInternalUsers.concat(u);
        }
      });
      return {
        ...state,
        internalUsers: finalizedInternalUsers,
      };
    }

    case REMOVE_INTERNAL_USERS: {
      const removedInternalUsers: User[] = action.payload;
      const removedIDs = removedInternalUsers.map((u) => u.id);
      const updatedInternalUsers = state.internalUsers.filter(
        (u) => !removedIDs.includes(u.id),
      );
      return {
        ...state,
        internalUsers: updatedInternalUsers,
      };
    }
    case INVITE_USER_REQUEST:
      return {
        ...state,
        isResendingInvite: true,
        error: null,
      };
    case INVITE_USER_SUCCESS: {
      const { invitedByUserId, invitedUserId } = action;

      const updatedUsers = state.internalUsers.map((user) => {
        if (user.id === invitedUserId) {
          return {
            ...user,
            fields: {
              ...user.fields,
              invitedBy: invitedByUserId,
            },
          };
        }
        return user;
      });

      return {
        ...state,
        internalUsers: updatedUsers,
        isResendingInvite: false,
        error: null,
      };
    }
    case INVITE_USER_FAILURE:
      return {
        ...state,
        isResendingInvite: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default usersReducer;
