import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PortalConfigContext } from 'src/context';
import { ActionButtonModal } from 'src/legacy/components/Modals';
import { closeAuthModal } from 'src/store/ui/actions';
import {
  GetAuthStateTitleMap,
  AUTH_STATES,
  GetMobileRestrictedDescription,
} from 'src/constants/authConsts';
import useNavigate from 'src/hooks/useNavigate';
import { RootState } from 'src/store';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';

const AuthModal = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.ui.authType);
  const closeModal = () => {
    dispatch(closeAuthModal());
  };

  React.useEffect(() => {
    if (authState === AUTH_STATES.SIGNED_IN) {
      window.location.href = '/';
    }
  }, [authState]);

  const portalConfig = React.useContext(PortalConfigContext);

  const { name } = portalConfig;

  const AuthStateTitleMap = GetAuthStateTitleMap(name);

  const modalProps = { open: false, onClose: closeModal };

  const { handleConfirm: confirmLeave } = useNavigate();

  if (authState) {
    modalProps.open = true;
  }

  const renderModal = () => {
    switch (authState) {
      case AUTH_STATES.DESKTOP_ONLY_MODAL:
        return (
          <ActionButtonModal
            title={AuthStateTitleMap[AUTH_STATES.DESKTOP_ONLY_MODAL]}
            mainComponent={
              <BaseTypography>
                {GetMobileRestrictedDescription(name)}
              </BaseTypography>
            }
            hideActions
            closeButton
            {...modalProps}
          />
        );
      case AUTH_STATES.CONFIRM_PAGE_CHANGE_MODAL:
        return (
          <ActionButtonModal
            title="Unsaved changes"
            mainComponent="If you leave this page, your changes will be lost."
            cancelActionText="Cancel"
            positiveActionText="Leave page"
            positiveAction
            onPositiveActionPerformed={confirmLeave}
            {...modalProps}
          />
        );
      default:
        return null;
    }
  };

  return <div>{renderModal()}</div>;
};

export default AuthModal;
