import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ColorFilterIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 11.875C12.5888 11.875 14.6875 9.77633 14.6875 7.1875C14.6875 4.59867 12.5888 2.5 10 2.5C7.41117 2.5 5.3125 4.59867 5.3125 7.1875C5.3125 9.77633 7.41117 11.875 10 11.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M13.4375 17.5C16.0263 17.5 18.125 15.4013 18.125 12.8125C18.125 10.2237 16.0263 8.125 13.4375 8.125C10.8487 8.125 8.75 10.2237 8.75 12.8125C8.75 15.4013 10.8487 17.5 13.4375 17.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M6.5625 17.5C9.15133 17.5 11.25 15.4013 11.25 12.8125C11.25 10.2237 9.15133 8.125 6.5625 8.125C3.97367 8.125 1.875 10.2237 1.875 12.8125C1.875 15.4013 3.97367 17.5 6.5625 17.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
