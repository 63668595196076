import { ApiMethods, appAPI } from '.';

export type CreateMagicLinkRequest = {
  userId: string;
  type: 'link' | 'email';
  nextPath: string;
  entityType: 'CLIENT_USER' | 'INVOICE' | 'CONTRACT' | 'FORM_TEMPLATE';
};

export type CreateMagicLinkResponse = {
  linkUrl: string;
  sentEmail: boolean;
};

export const magicLinksApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createMagicLink: build.mutation<
      CreateMagicLinkResponse,
      CreateMagicLinkRequest
    >({
      query: (body) => ({
        path: '/v1/magic-links',
        method: ApiMethods.post,
        options: { body },
      }),
    }),
  }),
});

export const { useCreateMagicLinkMutation } = magicLinksApi;
