import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Breadcrumbs,
  Chip,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { GraySmall, HoverNonBorderBackground } from 'src/theme/colors';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { ForwardIcon } from 'src/legacy/components/Icons';
import { RegularButtonHoverShadow } from 'src/theme/shadows';
import useNavigate from 'src/hooks/useNavigate';
import { useEscNavigation } from 'src/hooks/useEscNavigation';

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbsRoot: {
    overflow: 'hidden',
  },
  breadcrumbsListContainer: {
    flexWrap: 'nowrap',
  },
  breadcrumbListItem: {
    '&:last-child': {
      overflow: 'hidden',
    },
  },
  separator: {
    margin: theme.spacing(0, 1.5),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.5),
    },
    '& svg': {
      height: '7px',
      width: '7px',
    },
  },
  node: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    // clear out button styles
    appearance: 'none',
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: 0,

    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  default: {
    cursor: 'default',
  },
}));
const StyledChip = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    height: theme.spacing(3),
    color: GraySmall,
    borderRadius: theme.shape.borderRadius,
    fontWeight: theme.typography.fontWeightRegular,
    '& > .MuiChip-label': {
      padding: theme.spacing(0, 0.5),
    },
    '&:hover, &:focus': {
      backgroundColor: HoverNonBorderBackground,
    },
    '&:active': {
      boxShadow: RegularButtonHoverShadow,
    },
  },
}))(Chip) as typeof Chip;

export interface BreadCrumbItem {
  label: string;
  onClick?: () => void;
  path?: string;
  textTransform?:
    | 'capitalize'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase'
    | 'none'
    | 'uppercase';
}
export const BreadCrumbItem: React.FC<BreadCrumbItem> = ({
  label,
  onClick,
  path,
  textTransform,
}) => {
  const classes = useStyles({ textTransform });
  const { navigate } = useNavigate();

  const handleClick = React.useCallback(() => {
    if (path) {
      navigate(path);
    } else if (onClick) {
      onClick();
    }
  }, [navigate, path]);

  // if path is not provided, then wrap the label in a chip, otherwise, wrap the label in normal text
  return path || onClick ? (
    <StyledChip
      key={path}
      label={
        <BaseTypography
          fontType="13Medium"
          className={[classes.node, classes.pointer].join(' ')}
        >
          {label}
        </BaseTypography>
      }
      onClick={handleClick}
    />
  ) : (
    <BaseTypography
      className={[classes.node, classes.default, 'text-black-heading'].join(
        ' ',
      )}
      component="span"
      fontType="13Medium"
      onClick={onClick}
    >
      {label}
    </BaseTypography>
  );
};
export interface BreadCrumbBaseProps {
  items: Prettify<BreadCrumbItem>[];
  showOnlyTitleOnMobile?: boolean;
}
export const BreadCrumbBase: React.FC<BreadCrumbBaseProps> = ({
  items,
  showOnlyTitleOnMobile = false,
}) => {
  const classes = useStyles();

  const previousPage = items.at(items.length - 2);

  useEscNavigation(previousPage?.path || '', {
    disabled: items.length === 1,
  });

  const theme = useTheme();
  const isExtraSmallViewport = useMediaQuery(theme.breakpoints.down('xs'));

  // if showOnlyTitleOnMobile is true, then show only the last item in the breadcrumb
  // this is done to make sure that the breadcrumb does not take up too much space on mobile
  const formattedBreadcrumbItems =
    isExtraSmallViewport && showOnlyTitleOnMobile ? items.slice(-1) : items;

  return (
    <Breadcrumbs
      classes={{
        separator: classes.separator,
        root: classes.node,
        ol: classes.breadcrumbsListContainer,
        li: classes.breadcrumbListItem,
      }}
      className={classes.breadcrumbsRoot}
      separator={<ForwardIcon />}
      aria-label="breadcrumb"
      color="initial"
    >
      {formattedBreadcrumbItems.map(
        ({ label, onClick, path, textTransform }) => (
          <BreadCrumbItem
            key={label}
            label={label}
            onClick={onClick}
            path={path}
            textTransform={textTransform}
          />
        ),
      )}
    </Breadcrumbs>
  );
};
