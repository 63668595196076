import { CustomFieldType } from 'src/store/clients/types';

export const AllClientSelectValue = 'default';

export const COPYABLE_FIELDS_TYPES: Array<CustomFieldType> = [
  'url',
  'email',
  'text',
  'number',
  'phoneNumber',
];

export const NEW_CLIENT_CREATE_OPTIONS = {
  // legacy crm new button options
  CREATE_ONE_CLIENT: 'Create one client',
  IMPORT_MANY_CLIENTS: 'Import many clients',
  // new crm new button options
  CREATE_CLIENT: 'Create client',
  CREATE_COMPANY: 'Create company',
  IMPORT: 'Import',
  COPY_SIGNUP_LINK: 'Copy client sign up link',
};
