import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TvIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.4945 3.75H2.50547C1.81209 3.75 1.25 4.31209 1.25 5.00547V13.1195C1.25 13.8129 1.81209 14.375 2.50547 14.375H17.4945C18.1879 14.375 18.75 13.8129 18.75 13.1195V5.00547C18.75 4.31209 18.1879 3.75 17.4945 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5 16.25H15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
