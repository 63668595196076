import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WarningIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.34255 17.4316H16.6574C16.8737 17.4316 17.0862 17.3755 17.2743 17.2687C17.4623 17.1619 17.6195 17.0082 17.7304 16.8226C17.8412 16.6369 17.9021 16.4256 17.9069 16.2094C17.9117 15.9932 17.8603 15.7795 17.7578 15.5891L11.1008 3.22578C10.6285 2.34922 9.37146 2.34922 8.89919 3.22578L2.24216 15.5891C2.13964 15.7795 2.08828 15.9932 2.09308 16.2094C2.09789 16.4256 2.1587 16.6369 2.26958 16.8226C2.38046 17.0082 2.53762 17.1619 2.72568 17.2687C2.91375 17.3755 3.12629 17.4316 3.34255 17.4316V17.4316Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.77554 7.63258L9.99976 12.3982L10.2236 7.63453C10.225 7.60408 10.2201 7.57366 10.2094 7.54514C10.1986 7.51663 10.1821 7.4906 10.1609 7.46867C10.1398 7.44673 10.1144 7.42934 10.0862 7.41756C10.0581 7.40578 10.0279 7.39986 9.99742 7.40016V7.40016C9.96747 7.40045 9.93788 7.40674 9.9104 7.41866C9.88292 7.43057 9.85811 7.44788 9.83743 7.46954C9.81674 7.49121 9.80061 7.5168 9.78998 7.5448C9.77936 7.5728 9.77444 7.60265 9.77554 7.63258V7.63258Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15.5176C9.84548 15.5176 9.69444 15.4718 9.56596 15.3859C9.43749 15.3001 9.33735 15.1781 9.27822 15.0353C9.21909 14.8925 9.20362 14.7355 9.23376 14.5839C9.26391 14.4324 9.33831 14.2932 9.44757 14.1839C9.55683 14.0746 9.69604 14.0002 9.84759 13.9701C9.99913 13.9399 10.1562 13.9554 10.299 14.0145C10.4417 14.0737 10.5637 14.1738 10.6496 14.3023C10.7354 14.4308 10.7812 14.5818 10.7812 14.7363C10.7812 14.9435 10.6989 15.1422 10.5524 15.2888C10.4059 15.4353 10.2072 15.5176 10 15.5176Z"
      fill="currentColor"
    />
  </SvgIcon>
);
