import { BaseEntity } from 'src/constants/dataTypes';

export const INITIAL_BASE_ENTITY: BaseEntity = {
  id: '',
  ownerId: '',
  identityId: '',
  owner: null,
  createdDate: '',
  getstreamId: '',
  metadata: {},
  updatedDate: '',
  entityStatus: '',
};

export const ENTITIES = {
  ARTICLE: 'ARTICLE',
  CLIENT_COMPANY: 'CLIENT_COMPANY',
  CLIENT_CUSTOM_FIELDS: 'CLIENT_CUSTOM_FIELDS',
  CLIENT_USER: 'CLIENT_USER',
  CONTRACT_TEMPLATE: 'CONTRACT_TEMPLATE',
  CONTRACT: 'CONTRACT',
  DOMAIN_SETTINGS: 'DOMAIN_SETTINGS',
  EXTENSION_ITEM: 'DASHBOARD',
  FILE_CHANNEL: 'FILE_CHANNEL',
  FILE_METADATA: 'FILE_METADATA',
  FORM_RESPONSE: 'FORM_RESPONSE', // forms v2 response entity
  FORM_TEMPLATE: 'FORM_TEMPLATE',
  INTERNAL_NOTES: 'NOTE',
  INTAKE_FORM_RESPONSE: 'INTAKE_FORM_RESPONSE',
  INTAKE_FORM_TEMPLATE: 'INTAKE_FORM_TEMPLATE',
  INTERNAL_USER: 'USER',
  KNOWLEDGE_BASE_SETTINGS: 'KNOWLEDGE_BASE_SETTINGS',
  MESSAGE_SETTINGS: 'MESSAGE_SETTINGS',
  NOTIFICATION_GROUPING: 'NOTIFICATION_GROUPING',
  NOTIFY_FILE_ESIGN_REQUEST: 'NOTIFY_FILE_ESIGN_REQUEST',
  NOTIFY_FILE_ESIGN_REQUEST_COMPLETE: 'NOTIFY_FILE_ESIGN_REQUEST_COMPLETE',
  NOTIFY_FILE_UPLOADED: 'NOTIFY_FILE_UPLOADED',
  NOTIFY_FORM_COMPLETE: 'NOTIFY_FORM_COMPLETE',
  NOTIFY_INVOICE_PAID: 'NOTIFY_INVOICE_PAID',
  NOTIFY_FORM_REQUEST: 'NOTIFY_FORM_REQUEST',
  NOTIFY_NEW_INVOICE: 'NOTIFY_NEW_INVOICE',
  NOTIFY_CUSTOM_APP_ACTION: 'NOTIFY_CUSTOM_APP_ACTION',
  ONBOARDING_TASK_STATUS: 'ONBOARDING_TASKS_STATUS',
  PAYMENT_INFO: 'PAYMENT_INFO',
  PORTAL_CONFIG: 'PORTAL_CONFIG',
  PORTAL_SETTINGS: 'PORTAL_SETTINGS',
  REFERRAL: 'REFERRAL',
  STRIPE_INVOICE: 'STRIPE_INVOICE',
  STRIPE_SUBSCRIPTION: 'STRIPE_SUBSCRIPTION',
  TAG: 'TAG',
  PRODUCT: 'PRODUCT',
  PRICE: 'PRICE',
  INVOICE: 'INVOICE',
  AUTOMATION_RUN: 'AUTOMATION_RUN',
  AUTOMATION: 'AUTOMATION',
  RECENTLY_VISITED: 'RECENTLY_VISITED',
  ANALYTICS_EVENTS_AGGREGATE: 'ANALYTICS_EVENTS_AGGREGATE',
  CUSTOMER_SUBSCRIPTION: 'STRIPE_CUSTOMER_SUBSCRIPTION',
};

export enum EntityStatus {
  Deleted = 'DELETED',
  Archived = 'ARCHIVED',
}
