/* eslint-disable no-param-reassign */
// this handler updates inboxApi redux store which uses ImmerJS and should allow param re-assign
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Events } from 'src/constants/webSocketConsts/events';
import { InboxNotificationDetailsResponse } from 'src/entities/Notifications';
import { inboxApi } from 'src/services/api/inboxApi';
const DELETE_MARKER = '#DELETED';

const updateInboxNotificationsCache = (
  notificationsState: InboxNotificationDetailsResponse[],
  notificationsItems: InboxNotificationDetailsResponse[],
  wsEvent: string,
) => {
  // if ws event is modify and notification coming in has delete marker, treat as delete event
  // remove notification that matches the id of the incoming notification if it exists. If it does not exist
  // then do nothing.
  if (
    wsEvent === Events.Modify &&
    notificationsItems.at(0)?.defaultListIndexPkey.includes(DELETE_MARKER)
  ) {
    const deletingNotification = notificationsItems.at(0);
    if (!deletingNotification) return;
    const deletingNotificationIndex = notificationsState.findIndex(
      (notification) => notification.id === deletingNotification.id,
    );
    if (deletingNotificationIndex !== -1) {
      notificationsState.splice(deletingNotificationIndex, 1);
    }
    return;
  }

  notificationsItems.forEach((notificationItem) => {
    // when a deleted notification ws item comes it we don't want to update the redux store
    // with this item.
    if (notificationItem.defaultListIndexPkey.includes(DELETE_MARKER)) {
      return;
    }

    // if notification already exist then update the notification
    const existingNotificationIndex = notificationsState.findIndex(
      (notification) => notification.id === notificationItem.id,
    );
    if (existingNotificationIndex >= 0) {
      notificationsState[existingNotificationIndex] = notificationItem;
      return;
    }

    // insert new notification
    notificationsState.push(notificationItem);
  });
};
/**
 * Handles incoming inbox notifications from websocket.
 * It checks if notification already exist in redux store and updates it if it does.
 * If notification does not exist in redux store then it inserts it.
 * @param notificationsItems - array of incoming notifications
 */
export const handleInboxNotifications =
  (notificationsItems: InboxNotificationDetailsResponse[], wsEvent: string) =>
  async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(
      inboxApi.util.updateQueryData(
        'getInboxNotifications',
        undefined,
        (draftState) =>
          updateInboxNotificationsCache(
            draftState,
            notificationsItems,
            wsEvent,
          ),
      ),
    );
  };
