import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const IsoIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.87891 12.0497H6V15.6861H6.87891V12.0497Z" fill="black" />
    <path
      d="M9.39622 13.1399H10.2378C10.2307 12.4599 9.68919 12 8.84402 12C8.01305 12 7.41468 12.4528 7.42001 13.1293C7.41824 13.6815 7.80531 13.9922 8.43386 14.136L8.81383 14.2248C9.21333 14.3171 9.39267 14.4254 9.39622 14.6296C9.39267 14.8516 9.18493 15.006 8.83159 15.006C8.44274 15.006 8.18706 14.8249 8.16752 14.4751H7.32591C7.33656 15.3256 7.92782 15.7322 8.84224 15.7322C9.74778 15.7322 10.284 15.3221 10.2876 14.6314C10.284 14.0508 9.8916 13.6957 9.17427 13.5376L8.86177 13.4666C8.53152 13.3956 8.322 13.2855 8.3291 13.0689C8.33088 12.87 8.50133 12.7262 8.84224 12.7262C9.18493 12.7262 9.37314 12.8807 9.39622 13.1399Z"
      fill="black"
    />
    <path
      d="M14.1636 13.8679C14.1636 12.6658 13.4037 12 12.4271 12C11.447 12 10.6924 12.6658 10.6924 13.8679C10.6924 15.0646 11.447 15.7358 12.4271 15.7358C13.4037 15.7358 14.1636 15.07 14.1636 13.8679ZM13.2652 13.8679C13.2652 14.5799 12.9527 14.9652 12.4271 14.9652C11.9033 14.9652 11.589 14.5799 11.589 13.8679C11.589 13.1559 11.9033 12.7706 12.4271 12.7706C12.9527 12.7706 13.2652 13.1559 13.2652 13.8679Z"
      fill="black"
    />
  </SvgIcon>
);
