import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ThermometerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.0202 11.8074C11.9758 11.7793 11.9391 11.7403 11.9137 11.6942C11.8883 11.6481 11.875 11.5964 11.8749 11.5437V3.125C11.8749 2.62772 11.6774 2.15081 11.3257 1.79917C10.9741 1.44754 10.4972 1.25 9.99991 1.25C9.50263 1.25 9.02572 1.44754 8.67409 1.79917C8.32246 2.15081 8.12491 2.62772 8.12491 3.125V11.5437C8.12483 11.5963 8.11151 11.6479 8.08618 11.694C8.06085 11.74 8.02433 11.7789 7.97999 11.807C7.42461 12.1688 6.97324 12.6693 6.67051 13.2589C6.36777 13.8485 6.22414 14.507 6.25382 15.1691C6.29852 16.1477 6.72434 17.0699 7.44022 17.7386C8.15609 18.4072 9.10518 18.7692 10.0845 18.7471C11.0638 18.725 11.9956 18.3206 12.6806 17.6203C13.3656 16.9201 13.7494 15.9796 13.7499 15C13.7502 14.3659 13.5921 13.7418 13.2901 13.1843C12.988 12.6268 12.5515 12.1535 12.0202 11.8074V11.8074Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 4.375V15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 16.875C11.0355 16.875 11.875 16.0355 11.875 15C11.875 13.9645 11.0355 13.125 10 13.125C8.96447 13.125 8.125 13.9645 8.125 15C8.125 16.0355 8.96447 16.875 10 16.875Z"
      fill="currentColor"
    />
  </SvgIcon>
);
