import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NoCompanyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M15.8333 17.5V4.16667C15.8333 3.72464 15.6577 3.30072 15.3452 2.98816C15.0326 2.67559 14.6087 2.5 14.1667 2.5H5.83333C5.39131 2.5 4.96738 2.67559 4.65482 2.98816C4.34226 3.30072 4.16667 3.72464 4.16667 4.16667V17.5M15.8333 17.5H4.16667M15.8333 17.5H17.5M15.8333 17.5H11.6667M4.16667 17.5H2.5M4.16667 17.5H8.33333M11.6667 17.5V13.3333C11.6667 13.1123 11.5789 12.9004 11.4226 12.7441C11.2663 12.5878 11.0543 12.5 10.8333 12.5H9.16667C8.94565 12.5 8.73369 12.5878 8.57741 12.7441C8.42113 12.9004 8.33333 13.1123 8.33333 13.3333V17.5M11.6667 17.5H8.33333M7.5 5.83333H8.33333M7.5 9.16667H8.33333M11.6667 5.83333H12.5M11.6667 9.16667H12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
