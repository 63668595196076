import { Box, styled } from '@material-ui/core';

export const CenterFlex = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadingContainer = styled(CenterFlex)({
  width: '100%',
  height: '100vh',
});

export const Flex = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
