import { DisabledReason } from 'src/constants';
import { ApiMethods, ApiTags, appAPI } from './index';

export interface DisabledPermission {
  permissionName: string;
  operationName: string;
  disabledReason: DisabledReason;
}

export const permissionsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getDisabledPermissions: build.query<Array<DisabledPermission>, void>({
      query: () => ({
        path: `/v1/permissions/disabled`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.permissions],
    }),
  }),
});

export const { useGetDisabledPermissionsQuery } = permissionsApi;
