import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ClipboardIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.125 2.5H14.375C14.8723 2.5 15.3492 2.69754 15.7008 3.04917C16.0525 3.40081 16.25 3.87772 16.25 4.375V16.875C16.25 17.3723 16.0525 17.8492 15.7008 18.2008C15.3492 18.5525 14.8723 18.75 14.375 18.75H5.625C5.12772 18.75 4.65081 18.5525 4.29917 18.2008C3.94754 17.8492 3.75 17.3723 3.75 16.875V4.375C3.75 3.87772 3.94754 3.40081 4.29917 3.04917C4.65081 2.69754 5.12772 2.5 5.625 2.5H6.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M12.1043 1.25H7.8957C7.33198 1.25 6.875 1.70698 6.875 2.2707V2.7293C6.875 3.29302 7.33198 3.75 7.8957 3.75H12.1043C12.668 3.75 13.125 3.29302 13.125 2.7293V2.2707C13.125 1.70698 12.668 1.25 12.1043 1.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
