import { SvgIcon } from '@material-ui/core';
import React from 'react';

const QuickBooksIcon = () => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_36694_242639)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#212B36"
      />
      <path
        d="M2.41076 9.99685C2.41156 12.2576 4.23476 14.096 6.49556 14.116H7.07396V12.6048H6.49556C5.09396 12.9872 3.64836 12.1608 3.26676 10.7592C3.26356 10.748 3.26116 10.7368 3.25796 10.7256C2.86516 9.30565 3.68756 7.83445 5.10276 7.42565H5.39156C5.75396 7.34645 6.12916 7.34645 6.49156 7.42565H7.89636V15.4488C7.88916 16.2976 8.57156 16.9921 9.42036 17V5.83685H6.50436C4.22756 5.84885 2.39236 7.70405 2.40436 9.98085V9.98165L2.41076 9.99685ZM13.5108 5.83845H12.9324V7.42965H13.5108C14.9092 7.04805 16.3516 7.87205 16.7332 9.26965C16.7364 9.28005 16.7388 9.29125 16.742 9.30165C17.1324 10.7168 16.3124 12.1824 14.9028 12.5904H14.614C14.2516 12.6696 13.8764 12.6696 13.514 12.5904H12.1092V4.56805C12.1164 3.71925 11.434 3.02485 10.5852 3.01685V14.196H13.5012C15.798 14.16 17.6308 12.2696 17.5956 9.97285C17.5604 7.72645 15.7484 5.91365 13.5012 5.87845L13.5108 5.83845Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_36694_242639">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default QuickBooksIcon;
