import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PortalModulesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M7.76025 1.6665H2.44775C2.01628 1.6665 1.6665 2.01628 1.6665 2.44775V6.09201C1.6665 6.52349 2.01628 6.87326 2.44775 6.87326H7.76025C8.19173 6.87326 8.5415 6.52349 8.5415 6.09201V2.44775C8.5415 2.01628 8.19173 1.6665 7.76025 1.6665Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.7603 13.3335H12.4478C12.0163 13.3335 11.6665 13.6833 11.6665 14.1147V17.759C11.6665 18.1905 12.0163 18.5403 12.4478 18.5403H17.7603C18.1917 18.5403 18.5415 18.1905 18.5415 17.759V14.1147C18.5415 13.6833 18.1917 13.3335 17.7603 13.3335Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.76025 10H2.44775C2.01628 10 1.6665 10.3498 1.6665 10.7812V17.625C1.6665 18.0565 2.01628 18.4063 2.44775 18.4063H7.76025C8.19173 18.4063 8.5415 18.0565 8.5415 17.625V10.7812C8.5415 10.3498 8.19173 10 7.76025 10Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.7603 1.6665H12.4478C12.0163 1.6665 11.6665 2.01628 11.6665 2.44775V9.2915C11.6665 9.72298 12.0163 10.0728 12.4478 10.0728H17.7603C18.1917 10.0728 18.5415 9.72298 18.5415 9.2915V2.44775C18.5415 2.01628 18.1917 1.6665 17.7603 1.6665Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
