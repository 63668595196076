import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BonfireIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.6789 14.3881C10.6473 14.0275 10.3328 13.7459 9.9543 13.7459C9.79117 13.7448 9.63239 13.7985 9.50347 13.8984C9.37454 13.9984 9.28294 14.1388 9.24336 14.297L8.53242 17.7494C8.50893 17.8503 8.49674 17.9536 8.49609 18.0572C8.49609 18.8357 9.14766 19.3767 9.9543 19.3767C10.7609 19.3767 11.4129 18.8357 11.4129 18.0572C11.4125 17.9325 11.3955 17.8083 11.3625 17.6881L10.6789 14.3881Z"
      fill="currentColor"
    />
    <path
      d="M16.0569 15.9322C15.9846 15.8688 15.9068 15.8119 15.8245 15.7623L13.1085 13.8642C12.844 13.6982 12.5616 13.7029 12.3565 13.9033C12.267 13.9885 12.2102 14.1023 12.1959 14.2251C12.1816 14.3478 12.2108 14.4717 12.2784 14.5751L14.3292 17.2705C14.376 17.3358 14.4283 17.3971 14.4854 17.4537C14.8409 17.8509 15.6292 17.8728 16.0624 17.4537C16.4897 17.0275 16.4847 16.2681 16.0569 15.9322Z"
      fill="currentColor"
    />
    <path
      d="M18.0977 13.1204H15.3469C15.1691 13.1204 15 13.2512 15 13.4391C15 13.627 15.1152 13.7458 15.2703 13.7969L17.8652 14.5997C18.2422 14.6876 18.75 14.3516 18.75 13.9321C18.75 13.3985 18.4531 13.1204 18.0977 13.1204Z"
      fill="currentColor"
    />
    <path
      d="M5.00859 13.4374C5.00859 13.2421 4.83086 13.1187 4.63945 13.1187H1.88867C1.54219 13.1187 1.25 13.4929 1.25 13.9124C1.25 14.3319 1.74297 14.6874 2.12109 14.5995L4.75508 13.7968C4.92656 13.7456 5.00859 13.6327 5.00859 13.4374Z"
      fill="currentColor"
    />
    <path
      d="M6.89641 13.8671L4.17962 15.7569C4.09524 15.8044 4.0166 15.8614 3.94524 15.9268C3.84175 16.0231 3.7592 16.1396 3.70276 16.2692C3.64632 16.3988 3.61719 16.5386 3.61719 16.6799C3.61719 16.8213 3.64632 16.9611 3.70276 17.0907C3.7592 17.2203 3.84175 17.3368 3.94524 17.4331C4.15835 17.6343 4.44039 17.7465 4.73352 17.7465C5.02666 17.7465 5.30869 17.6343 5.5218 17.4331C5.57987 17.3775 5.63222 17.3163 5.67805 17.2503L7.7304 14.5702C7.86712 14.3522 7.84446 14.0823 7.65227 13.9038C7.55096 13.8093 7.41944 13.7538 7.28106 13.747C7.14269 13.7403 7.00641 13.7828 6.89641 13.8671Z"
      fill="currentColor"
    />
    <path
      d="M14.3748 4.88634C14.3748 2.71055 12.4522 1.40352 10.6951 1.25118C10.6528 1.24749 10.6102 1.25247 10.5699 1.26581C10.5296 1.27914 10.4924 1.30056 10.4606 1.32874C10.4288 1.35693 10.4031 1.39129 10.3851 1.42974C10.3671 1.46818 10.357 1.5099 10.3557 1.55235C10.2338 4.5211 6.08301 5.87618 5.67325 8.53204C5.39981 10.3055 6.32364 11.359 7.01348 11.8828C7.08653 11.9383 7.17094 11.977 7.26067 11.9961C7.35041 12.0152 7.44326 12.0142 7.53257 11.9932C7.62188 11.9723 7.70546 11.9318 7.77732 11.8748C7.84918 11.8177 7.90755 11.7455 7.94824 11.6633C8.82715 9.87188 10.8518 9.18321 12.2342 8.30626C13.9092 7.24376 14.3748 6.10157 14.3748 4.88634Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.375 3.73822C8.87422 2.83979 8.1832 2.52729 7.06406 2.50268C7.02501 2.50049 6.98663 2.51358 6.95704 2.53916C6.92745 2.56474 6.90895 2.60082 6.90547 2.63979C6.86836 3.9765 5.625 4.39174 5.625 5.49721C5.625 6.25385 5.83555 6.63002 6.29531 7.03158"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.7676 6.98291C14.1035 7.54189 14.375 8.15791 14.375 8.85791C14.375 10.9095 12.716 12.4946 10.6695 12.4946C9.72241 12.4953 8.81131 12.1317 8.125 11.479"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
