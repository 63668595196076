import React from 'react';
import { createStyles, Drawer, makeStyles, Theme } from '@material-ui/core';
import { NonHoverBorder } from 'src/theme/colors';
import { BANNER_HEIGHT } from 'src/constants';

const DRAWER_CONTAINER_WIDTH = 360;
interface Props {
  hasBanner: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: DRAWER_CONTAINER_WIDTH,
      height: '-webkit-fill-available',
      flexShrink: 0,
      borderRight: `1px solid ${NonHoverBorder}`,
      border: 'none',
      overflow: 'hidden',
      zIndex: theme.zIndex.drawer + 1,
      // mobile styles
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        borderRight: 'none',
        position: 'fixed',
        right: 0,
        top: (props: Pick<Props, 'hasBanner'>) =>
          props.hasBanner ? BANNER_HEIGHT : 0,
      },
    },
  }),
);

export const SideDrawer: React.FC<Props> = ({ children, hasBanner }) => {
  const classes = useStyles({ hasBanner });

  return (
    <Drawer variant="permanent" classes={{ paper: classes.root }}>
      {children}
    </Drawer>
  );
};
