import { Stripe } from 'stripe';
import {
  BILLING_INTERVALS,
  CARDS_ICONS,
  PAYMENT_CARDS_ICONS,
  RecipientDataModel,
} from 'src/constants';
import { AccountVerificationStatus } from 'src/legacy/components/StripeConnectForm/VerificationStatus';
import { Invoice, InvoiceAdditionalFields } from 'src/store/payments/types';
import { LineItemsSchema } from 'src/legacy/components/billing/new/types';
import { StripePaymentMethod } from 'src/types/payment';

/**
 * Determine status of stripe account based on connect status
 * More details about status in stripe docs here: https://stripe.com/docs/api/accounts/object#account_object-requirements
 * @param payouts_enabled Whether Stripe can send payouts to this account.
 * @param accountRequirements
 * @returns
 */
export const GetStripeAccountVerificationStatus = (
  payouts_enabled: boolean,
  charges_enabled: boolean,
  accountRequirements: Stripe.Account.Requirements,
) => {
  const { disabled_reason, pending_verification, currently_due } =
    accountRequirements || {};
  let status: AccountVerificationStatus = 'pending';

  if (payouts_enabled) {
    // if payouts enabled we set status to verified
    status = 'verified';
  }

  if (disabled_reason) {
    // if then there is some reason for disabling we know account is in failed state
    status = 'failed';
  }

  if (pending_verification && pending_verification.length > 0) {
    // when there are pending verifiations show pending, this can happen when
    // payouts_enabled or disabled_reason is set
    status = 'pending';
  }

  if (!charges_enabled) {
    // If onboarding is not completed then for the given account, charges_enabled field will be false
    // Ref: https://docs.stripe.com/connect/express-accounts#handle-users-not-completed-onboarding
    status = 'onboarding';
  } else if (currently_due && currently_due.length > 0) {
    status = 'unverified';
  }

  return status;
};

export const getPaymentMethodLogo = (
  cardBrand: string,
  variant: 'clear' | 'contained' = 'clear',
) => {
  const logoData = CARDS_ICONS[cardBrand];
  // TODO: use variant param once contained icons will be ready
  return logoData ? logoData.path[variant] : '';
};
export const getCardLogo = (
  cardBrand: string,
  variant: 'clear' | 'contained' = 'clear',
) => {
  const logoData = PAYMENT_CARDS_ICONS[cardBrand];

  return logoData ? logoData.path[variant] : '';
};

export interface PaymentOptions {
  allowPaymentViaACH: boolean;
  allowPaymentViaCC: boolean;
  absorbTransactionFees?: boolean;
}

/**
 * Get plan from subscription invoice
 * @param stripe invoice
 * @returns plan for subscription invoice, undefined otherwise
 */
export const getPlanFromInvoice = (info: {
  additionalFields: InvoiceAdditionalFields;
  fields: Invoice;
}) => {
  const { additionalFields, fields } = info;
  const additionalFieldsPlan =
    additionalFields.lines &&
    additionalFields.lines.data.length > 0 &&
    additionalFields.lines.data[0].plan
      ? {
          interval: additionalFields.lines.data[0].plan.interval,
          intervalCount: additionalFields.lines.data[0].plan.interval_count,
        }
      : undefined;
  const fieldsPlan =
    fields.interval && fields.intervalCount
      ? { interval: fields.interval, intervalCount: fields.intervalCount }
      : undefined;

  return additionalFieldsPlan || fieldsPlan;
};

export interface InvoiceDetailOptions {
  id: string;
  amountDue: number;
  ignoreFee: boolean;
  fees: number;
  tax: number;
  currency: string;
  invoiceNumber: string;
  dueDate: string;
  dateOfIssue: string;
  description?: string;
  collectionMethod: string;
  taxPercentage?: number;
}

export interface StripePlanInfo {
  interval: string;
  intervalCount: number;
}

/**
 * Create stripe plan info from billing period
 * @param billing period i.e. day, week, month and year
 * @returns plan for subscription invoice, undefined otherwise
 */
export const getStripePlanInfoFromBillingPeriod = (billingPeriod: string) => {
  switch (billingPeriod) {
    case BILLING_INTERVALS.QUARTERLY: {
      return { interval: BILLING_INTERVALS.MONTH, intervalCount: 3 };
    }
    case BILLING_INTERVALS.BIANNUALLY: {
      return { interval: BILLING_INTERVALS.MONTH, intervalCount: 6 };
    }
    case BILLING_INTERVALS.SEMIANNUALLY: {
      return { interval: BILLING_INTERVALS.MONTH, intervalCount: 24 };
    }
    default: {
      return { interval: billingPeriod, intervalCount: 1 };
    }
  }
};

export const getPaymentMethodTypeFromOptions = (options: PaymentOptions) => {
  if (options.allowPaymentViaACH && !options.allowPaymentViaCC) {
    return 'bank_account';
  }
  if (options.allowPaymentViaCC && !options.allowPaymentViaACH) {
    return 'card';
  }

  return 'unknown';
};

interface BillingRecipient {
  data: RecipientDataModel;
}

export const billingRecipientComparator = (
  _a: any,
  _b: any,
  nodeA: BillingRecipient,
  nodeB: BillingRecipient,
) => {
  const { companyFields: aCompanyFields, client: aClient } = nodeA.data;
  const { companyFields: bCompanyFields, client: bClient } = nodeB.data;
  const a: string =
    aClient && aClient.fields
      ? `${aClient.fields.givenName}${aClient.fields.familyName}`
      : aCompanyFields?.fields.name || '';
  const b: string =
    bClient && bClient.fields
      ? `${bClient.fields.givenName}${bClient.fields.familyName}`
      : bCompanyFields?.fields.name || '';
  return a.localeCompare(b);
};

/**
 * This function is used to get label for payment method number.
 * Based on the type of source we show different labels.
 * @param source stripe source
 * @returns a label to use for showing payment method number
 */
export const getPaymentNumberLabel = (source: StripePaymentMethod) => {
  const { card, bankAccount } = getPaymentMethodDetailsFromSource(source);

  if (card) {
    return `ending in ${card.last4}`;
  }

  if (bankAccount) {
    return `•••• ${bankAccount.last4}`;
  }

  return '';
};

export const areLineItemsValid = (lineItems: LineItemsSchema) => {
  return lineItems.some((x) => !x.isValid);
};

export const getPaymentMethodDetailsFromSource = (
  source?: StripePaymentMethod,
) => {
  if (!source) {
    return {};
  }
  const sourceCard = source.object === 'card' ? source : undefined;
  const paymentMethodCard =
    source.object === 'payment_method' && source.type === 'card'
      ? source.card
      : undefined;
  const sourceBank = source.object === 'bank_account' ? source : undefined;
  const paymentMethodBank =
    source.object === 'payment_method' && source.type === 'us_bank_account'
      ? source.us_bank_account
      : undefined;

  const card = sourceCard || paymentMethodCard;
  const bankAccount = sourceBank || paymentMethodBank;

  return {
    card,
    bankAccount,
  };
};
