import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HandLeftIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.125 12.5V5.625C3.125 5.29348 3.2567 4.97554 3.49112 4.74112C3.72554 4.5067 4.04348 4.375 4.375 4.375V4.375C4.70652 4.375 5.02446 4.5067 5.25888 4.74112C5.4933 4.97554 5.625 5.29348 5.625 5.625V10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 10V3.125C5.625 2.79348 5.7567 2.47554 5.99112 2.24112C6.22554 2.0067 6.54348 1.875 6.875 1.875C7.20652 1.875 7.52446 2.0067 7.75888 2.24112C7.9933 2.47554 8.125 2.79348 8.125 3.125V9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 9.41406V3.75C10.625 3.41848 10.7567 3.10054 10.9911 2.86612C11.2255 2.6317 11.5435 2.5 11.875 2.5V2.5C12.2065 2.5 12.5245 2.6317 12.7589 2.86612C12.9933 3.10054 13.125 3.41848 13.125 3.75V12.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 9.375V1.875C8.125 1.54348 8.2567 1.22554 8.49112 0.991117C8.72554 0.756696 9.04348 0.625 9.375 0.625C9.70652 0.625 10.0245 0.756696 10.2589 0.991117C10.4933 1.22554 10.625 1.54348 10.625 1.875V9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 12.5001C3.125 17.086 5.625 19.3751 9.0625 19.3751C12.5 19.3751 13.8949 17.8282 14.6875 15.9376L16.7465 10.3126C17.0066 9.6075 16.8887 8.95359 16.2828 8.60945C15.6766 8.26492 14.8801 8.44227 14.552 9.0657L13.125 12.5001"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
