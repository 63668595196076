import * as React from 'react';
import {
  BreadCrumbBase,
  BreadCrumbBaseProps,
} from 'src/legacy/components/UI/BreadCrumbBase';

import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { NonHoverBackground } from 'src/theme/colors';

const useNotificationBreadcrumbsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: 32,
      borderRadius: 64,
      width: 'fit-content',
      backgroundColor: NonHoverBackground,
      padding: theme.spacing(0, 1.5),
      alignItems: 'center',
      flexShrink: 0,
    },
  }),
);

export const NotificationContentBreadcrumbs: React.FC<BreadCrumbBaseProps> = ({
  items,
}) => {
  const classes = useNotificationBreadcrumbsStyles();
  return (
    <div className={classes.root}>
      <BreadCrumbBase items={items} />
    </div>
  );
};
