import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ReorderIcon = (props?: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.75 10C3.75 8.61929 4.86929 7.5 6.25 7.5C7.63071 7.5 8.75 8.61929 8.75 10C8.75 11.3807 7.63071 12.5 6.25 12.5C4.86929 12.5 3.75 11.3807 3.75 10ZM3.75 2.5C3.75 1.11929 4.86929 0 6.25 0C7.63071 0 8.75 1.11929 8.75 2.5C8.75 3.88071 7.63071 5 6.25 5C4.86929 5 3.75 3.88071 3.75 2.5ZM3.75 17.5C3.75 16.1193 4.86929 15 6.25 15C7.63071 15 8.75 16.1193 8.75 17.5C8.75 18.8807 7.63071 20 6.25 20C4.86929 20 3.75 18.8807 3.75 17.5ZM11.25 10C11.25 8.61929 12.3693 7.5 13.75 7.5C15.1307 7.5 16.25 8.61929 16.25 10C16.25 11.3807 15.1307 12.5 13.75 12.5C12.3693 12.5 11.25 11.3807 11.25 10ZM11.25 2.5C11.25 1.11929 12.3693 0 13.75 0C15.1307 0 16.25 1.11929 16.25 2.5C16.25 3.88071 15.1307 5 13.75 5C12.3693 5 11.25 3.88071 11.25 2.5ZM11.25 17.5C11.25 16.1193 12.3693 15 13.75 15C15.1307 15 16.25 16.1193 16.25 17.5C16.25 18.8807 15.1307 20 13.75 20C12.3693 20 11.25 18.8807 11.25 17.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
