import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CardIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.9375 3.75H4.0625C2.85438 3.75 1.875 4.72938 1.875 5.9375V14.0625C1.875 15.2706 2.85438 16.25 4.0625 16.25H15.9375C17.1456 16.25 18.125 15.2706 18.125 14.0625V5.9375C18.125 4.72938 17.1456 3.75 15.9375 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 7.5H18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="2.34375"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 11.7188H5V12.5H6.875V11.7188Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2.34375"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
