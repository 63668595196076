import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PricetagIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.002 1.875H12.2012C12.0519 1.87489 11.9087 1.93388 11.8027 2.03906L2.20508 11.6367C1.99493 11.8479 1.87695 12.1337 1.87695 12.4316C1.87695 12.7296 1.99493 13.0154 2.20508 13.2266L6.77539 17.7969C6.98658 18.007 7.27238 18.125 7.57031 18.125C7.86824 18.125 8.15405 18.007 8.36524 17.7969L17.959 8.20313C18.0642 8.09721 18.1232 7.95396 18.123 7.80469V3C18.1238 2.85239 18.0953 2.7061 18.0393 2.56953C17.9832 2.43297 17.9007 2.30884 17.7965 2.20428C17.6923 2.09972 17.5685 2.0168 17.4321 1.96029C17.2958 1.90378 17.1496 1.87479 17.002 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 6.25C14.7528 6.25 14.5111 6.17669 14.3055 6.03934C14.1 5.90199 13.9398 5.70676 13.8452 5.47836C13.7505 5.24995 13.7258 4.99861 13.774 4.75614C13.8223 4.51366 13.9413 4.29093 14.1161 4.11612C14.2909 3.9413 14.5137 3.82225 14.7561 3.77402C14.9986 3.72579 15.2499 3.75054 15.4784 3.84515C15.7068 3.93976 15.902 4.09998 16.0393 4.30554C16.1767 4.5111 16.25 4.75277 16.25 5C16.25 5.33152 16.1183 5.64946 15.8839 5.88388C15.6495 6.1183 15.3315 6.25 15 6.25Z"
      fill="currentColor"
    />
  </SvgIcon>
);
