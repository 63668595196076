export interface NameListModel {
  [_: string]: {
    path: string;
    invertBooleanValue?: boolean;
  };
}

export const portalConfigNameListModel: NameListModel = {
  clientAccentColor: {
    path: 'brand.clientAccentColor',
  },
  clientFontFamily: {
    path: 'brand.clientFontFamily',
  },
  clientSidebarBackgroundColor: {
    path: 'brand.clientSidebarBackgroundColor',
  },
  clientSidebarTextColor: {
    path: 'brand.clientSidebarTextColor',
  },
  portalColor: {
    path: 'theme.palette.primary.main',
  },
  portalName: { path: 'name' },
  websiteTitle: { path: 'metadata.title' },
  metaDescription: { path: 'metadata.description' },
  allowClientBranding: { path: 'brand.allowClientBranding' },
  linkedInUrl: { path: 'metadata.linkedInUrl' },
  facebookUrl: { path: 'metadata.facebookUrl' },
  instagramUrl: { path: 'metadata.instagramUrl' },
  twitterUrl: { path: 'metadata.twitterUrl' },
  logoUrl: { path: 'assets.logo.logoImageUrl' },
  iconUrl: { path: 'assets.logo.iconImageUrl' },
  faviconUrl: { path: 'assets.logo.faviconUrl' },
  signInUrl: { path: 'assets.authImage.signInUrl' },
  socialImageUrl: { path: 'assets.socialShareImage.imageUrl' },
  allowSignup: { path: 'features.signupDisabled', invertBooleanValue: true },
  allowClientInvites: {
    path: 'features.clientInvitesDisabled',
    invertBooleanValue: true,
  },
  gaMeasurementId: { path: 'metadata.gaMeasurementId' },
  messages: {
    path: 'features.messagingPageDisabled',
    invertBooleanValue: true,
  },
  files: {
    path: 'features.filesPageDisabled',
    invertBooleanValue: true,
  },
  payments: {
    path: 'features.paymentsModuleDisabled',
    invertBooleanValue: true,
  },
  knowledgeBase: {
    path: 'features.resourceSectionDisabled',
    invertBooleanValue: true,
  },
  signOutUrl: { path: 'SignoutRedirectURL' },
  landings: {
    path: 'MarketingSite.Enabled',
  },
  showBadge: {
    path: 'MarketingSite.disableBadge',
    invertBooleanValue: true,
  },
  callSchedulingLink: {
    path: 'MarketingSite.callSchedulingLink',
  },
  forms: {
    path: 'features.formsPageDisabled',
    invertBooleanValue: true,
  },
};

export const landingPageNameListModel: NameListModel = {
  heroTitle: { path: 'home.heroTitle' },
  heroImageUrl: { path: 'home.heroImage' },
  heroSubtitle: { path: 'home.heroSubtitle' },
  aboutImageUrl: { path: 'home.aboutSectionImage' },
  aboutDescription: { path: 'home.aboutSectionDescription' },
  teamImageUrl: { path: 'home.teamSectionImage' },
  teamDescription: { path: 'home.teamSectionDescription' },
  home: { path: 'home.isActive' },
  team: { path: 'team.isActive' },
  about_us: { path: 'aboutUs.isActive' },
  aboutPageHeroImage: { path: 'aboutUs.aboutPageHeroImage' },
  aboutPageTagline: { path: 'aboutUs.aboutPageTagline' },
  aboutPageParagraph: { path: 'aboutUs.aboutPageParagraph' },
  services: { path: 'aboutUs.services' },
  teamHeroImage: { path: 'team.teamHeroImage' },
  employees: { path: 'team.employees' },
  contact_us: { path: 'contactUs.isActive' },
  contactUsHeroImage: { path: 'contactUs.contactUsHeroImage' },
  contactUsEmail: { path: 'contactUs.contactUsEmail' },
  phoneNumber: { path: 'contactUs.phoneNumber' },
  daysOfBusiness: { path: 'contactUs.daysOfBusiness' },
  workHours: { path: 'contactUs.workHours' },
  streetAddress: { path: 'contactUs.streetAddress' },
  cityName: { path: 'contactUs.cityName' },
  state: { path: 'contactUs.state' },
  postalCode: { path: 'contactUs.postalCode' },
};
