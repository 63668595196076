import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GameControllerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M18.2619 9.71997C17.5431 6.47076 16.4771 4.3981 14.7685 3.89302C14.4376 3.79649 14.0945 3.74833 13.7498 3.75005C12.6994 3.75005 11.8705 4.37505 9.99977 4.37505C8.12906 4.37505 7.29859 3.75005 6.24977 3.75005C5.89068 3.74819 5.53309 3.79631 5.18727 3.89302C3.47633 4.3981 2.4193 6.47271 1.69234 9.71997C0.950156 13.0368 1.08453 15.6575 2.53531 16.1606C3.55094 16.5122 4.45914 15.7852 5.3193 14.7153C6.29586 13.4965 7.49859 13.1215 9.99977 13.1215C12.5009 13.1215 13.6568 13.4965 14.6349 14.7153C15.4943 15.786 16.4361 16.504 17.4248 16.1668C19.0271 15.6196 19.0045 13.0758 18.2619 9.71997Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M11.4062 9.53125C11.8377 9.53125 12.1875 9.18147 12.1875 8.75C12.1875 8.31853 11.8377 7.96875 11.4062 7.96875C10.9748 7.96875 10.625 8.31853 10.625 8.75C10.625 9.18147 10.9748 9.53125 11.4062 9.53125Z"
      fill="currentColor"
    />
    <path
      d="M13.125 11.25C12.9704 11.25 12.8193 11.2041 12.6908 11.1182C12.5622 11.0323 12.4621 10.9101 12.403 10.7673C12.344 10.6244 12.3286 10.4672 12.3589 10.3156C12.3892 10.164 12.4638 10.0248 12.5733 9.91563C12.6827 9.80645 12.8221 9.7322 12.9738 9.70227C13.1255 9.67235 13.2826 9.6881 13.4253 9.74753C13.568 9.80696 13.6899 9.9074 13.7755 10.0361C13.8611 10.1649 13.9066 10.3161 13.9062 10.4707C13.9057 10.6776 13.8232 10.8758 13.6767 11.0219C13.5303 11.168 13.3319 11.25 13.125 11.25V11.25Z"
      fill="currentColor"
    />
    <path
      d="M13.125 7.8125C13.5565 7.8125 13.9062 7.46272 13.9062 7.03125C13.9062 6.59978 13.5565 6.25 13.125 6.25C12.6935 6.25 12.3438 6.59978 12.3438 7.03125C12.3438 7.46272 12.6935 7.8125 13.125 7.8125Z"
      fill="currentColor"
    />
    <path
      d="M14.8438 9.53125C15.2752 9.53125 15.625 9.18147 15.625 8.75C15.625 8.31853 15.2752 7.96875 14.8438 7.96875C14.4123 7.96875 14.0625 8.31853 14.0625 8.75C14.0625 9.18147 14.4123 9.53125 14.8438 9.53125Z"
      fill="currentColor"
    />
    <path
      d="M6.25 6.875V10.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 8.75H4.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
