import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { FormResponsePreview } from 'src/legacy/components/FormsV2/FormResponseDetail';
import {
  QuestionAnswerWithIdentityId,
  QuestionType,
} from 'src/legacy/components/FormsV2/formsTypes';
import { RegularCardBox } from 'src/legacy/components/Cards/RegularCardBox';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { GraySmall } from 'src/theme/colors';
import { RichTextDescription } from 'src/legacy/components/FormsV2/RichTextDescription';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  title: {
    color: (props: { isFormBuilderPreview: boolean }) =>
      props.isFormBuilderPreview ? GraySmall : 'inherit',
  },
  titleCardWrapper: {
    padding: 24,
    marginBottom: 20,
  },
  shadowlessCard: {
    boxShadow: 'none',
    backgroundColor: 'white',
  },
}));

/**
 * This context is used to tell the form builder preview nested components
 * that they are in preview mode.
 */
export const FormBuilderPreviewContext = React.createContext<{
  isFormBuilderPreview: boolean;
}>({
  isFormBuilderPreview: false,
});
interface FormTitlePreviewProps {
  title: string;
  description: string;
  disableShadow?: boolean;
}

/**
 * This component is used to preview the form title and description.
 * @returns {JSX.Element}
 */
export const FormTitlePreviewCard: React.FC<FormTitlePreviewProps> = ({
  title,
  description,
  disableShadow = false,
}) => {
  const { isFormBuilderPreview } = React.useContext(FormBuilderPreviewContext);
  const classes = useStyles({ isFormBuilderPreview });
  return (
    <RegularCardBox
      className={clsx(classes.titleCardWrapper, {
        [classes.shadowlessCard]: disableShadow,
      })}
    >
      <BaseTypography fontType="24Medium">
        {title || <span className={classes.title}>Form title</span>}
      </BaseTypography>
      <RichTextDescription description={description} />
    </RegularCardBox>
  );
};

/**
 * This component is used to preview the form responses.
 * It is used in the form builder page.
 * @returns {JSX.Element}
 */
export const FormBuilderPreview: React.FC<{ previewIsOn: boolean }> = ({
  previewIsOn,
}) => {
  const { values } = useFormikContext<{
    title: string;
    description: string;
    questions: QuestionType[];
  }>();
  // get the form responses objects from form builder formik state
  // get the form config and turn them into form responses preview objects
  // the form responses preview objects are the same as form responses objects
  // but they don't have response values
  const responses = useMemo(() => {
    if (!values) return [];
    return values.questions.map((question) => ({
      title: question.title,
      description: question.description,
      type: question.type,
      multipleChoiceOptions: question.multipleChoiceOptions,
      isRequired: question.isRequired,
      hasOtherOption: question.hasOtherOption,
    }));
  }, [values.questions]);

  const previewContextValue = useMemo(
    () => ({
      isFormBuilderPreview: previewIsOn,
    }),
    [previewIsOn],
  );

  return (
    <FormBuilderPreviewContext.Provider value={previewContextValue}>
      <FormTitlePreviewCard
        title={values.title}
        description={values.description}
      />
      <FormResponsePreview
        responses={responses as Array<QuestionAnswerWithIdentityId>}
      />
    </FormBuilderPreviewContext.Provider>
  );
};
