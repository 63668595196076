import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ThumbsUpIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.5 17.8969C12.5 17.8969 11.875 18.125 10 18.125C8.125 18.125 7.10938 17.5 6.25 16.875H3.75C3.08696 16.875 2.45107 16.6116 1.98223 16.1428C1.51339 15.6739 1.25 15.038 1.25 14.375V12.5C1.25 11.837 1.51339 11.2011 1.98223 10.7322C2.45107 10.2634 3.08696 10 3.75 10H4.92188C5.13651 9.99881 5.34731 9.94296 5.53437 9.83771C5.72143 9.73246 5.87859 9.58128 5.99102 9.39844C5.99102 9.39844 6.32812 8.66445 7.34375 6.90547C8.35938 5.14648 10.3125 2.5 10.625 1.875C11.7578 1.875 12.3047 2.73438 11.9531 3.73867C11.5516 4.88672 11.027 5.86289 10.8805 7.14063C10.8594 7.32734 11.0031 7.60938 11.191 7.60938L16.25 8.00781"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 10.5859L13.125 10.5078C12.3438 10.4359 11.875 10.0234 11.875 9.33594C11.875 8.64844 12.4219 8.20937 13.125 8.16406L16.25 8.00781C16.9375 8.00781 17.5 8.64844 17.5 9.33594V9.34258C17.4982 9.67295 17.3658 9.98919 17.1315 10.2222C16.8973 10.4552 16.5804 10.5859 16.25 10.5859Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M17.5 13.125L13.125 13.0469C12.4219 13.0141 11.875 12.5621 11.875 11.875C11.875 11.1871 12.4219 10.7477 13.125 10.7031L17.5 10.625C17.8312 10.626 18.1485 10.7581 18.3827 10.9923C18.6169 11.2265 18.749 11.5438 18.75 11.875V11.875C18.749 12.2062 18.6169 12.5235 18.3827 12.7577C18.1485 12.9919 17.8312 13.124 17.5 13.125V13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M15.625 18.125L13.125 18.0078C12.3047 17.9359 11.875 17.5625 11.875 16.875C11.875 16.1875 12.4375 15.7031 13.125 15.7031L15.625 15.625C15.9562 15.6259 16.2736 15.7579 16.5079 15.9921C16.7421 16.2264 16.8741 16.5438 16.875 16.875V16.875C16.8741 17.2062 16.7421 17.5236 16.5079 17.7579C16.2736 17.9921 15.9562 18.1241 15.625 18.125V18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M16.875 15.625L13.125 15.5469C12.3828 15.5141 11.875 15.0625 11.875 14.375C11.875 13.6875 12.3828 13.2484 13.125 13.2031L16.875 13.125C17.2062 13.1259 17.5236 13.2579 17.7579 13.4921C17.9921 13.7264 18.1241 14.0438 18.125 14.375V14.375C18.1241 14.7062 17.9921 15.0236 17.7579 15.2579C17.5236 15.4921 17.2062 15.6241 16.875 15.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
