import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MedalIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 18.125C12.4162 18.125 14.375 16.1662 14.375 13.75C14.375 11.3338 12.4162 9.375 10 9.375C7.58375 9.375 5.625 11.3338 5.625 13.75C5.625 16.1662 7.58375 18.125 10 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15.625C11.0355 15.625 11.875 14.7855 11.875 13.75C11.875 12.7145 11.0355 11.875 10 11.875C8.96447 11.875 8.125 12.7145 8.125 13.75C8.125 14.7855 8.96447 15.625 10 15.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.74174 12.6172L1.63393 6.22344C1.51674 6.04105 1.44899 5.83132 1.43735 5.61484C1.42571 5.39836 1.47057 5.18258 1.56752 4.98867L2.77846 2.5668C2.88222 2.3589 3.04187 2.18404 3.23948 2.06184C3.4371 1.93964 3.66487 1.87494 3.89721 1.875H16.1019C16.334 1.87502 16.5616 1.93967 16.759 2.06172C16.9565 2.18377 17.1161 2.35839 17.2199 2.56602L18.4308 4.98789C18.5278 5.18179 18.5726 5.39758 18.561 5.61406C18.5493 5.83054 18.4816 6.04027 18.3644 6.22266L14.2574 12.6172"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4922 5.625H1.44531"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7477 2.05469L11.9141 9.76563"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.49023 5.625L8.08594 9.76562"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
