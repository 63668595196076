import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { PlansUtils } from 'src/utils';

export const useCustomerSubscription = () => {
  const stripeCustomer = useSelector(
    (state: RootState) => state.user.data?.stripeCustomer,
  );
  const stripeSubscriptions = useSelector(
    (state: RootState) => state.user.data?.stripeSubscriptions,
  );
  const customerPaymentMethods = useSelector(
    (state: RootState) => state.user.data?.stripePaymentMethods,
  );

  // filter out trial and incomplete subscriptions
  const customerSubscriptions = React.useMemo(
    () => PlansUtils.activeSubscriptions(stripeSubscriptions),

    [stripeSubscriptions],
  );

  const customerSubscription = React.useMemo(
    () =>
      customerSubscriptions && customerSubscriptions.length > 0
        ? customerSubscriptions.at(0)
        : undefined,
    [customerSubscriptions],
  );

  return {
    stripeCustomer,
    allSubscriptions: stripeSubscriptions, // holds all subscriptions trial and paid (having all statuses)
    customerSubscriptions: customerSubscriptions || [],
    customerSubscription,
    customerPaymentMethods,
  };
};
