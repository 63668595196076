import { DraggableData } from 'react-draggable';
import { SignatureComponentType } from 'src/constants';
import { FieldType, InputType } from 'src/services/api';
import { SignatureStatusTypes } from 'src/store/files/types';

export enum SignaturePageActionTypes {
  GET_PAGES = 'GET_PAGES',
  GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS',
  GET_PAGES_ERROR = 'GET_PAGES_ERROR',
  CHANGE_ESIGN_FLOW_STATE = 'CHANGE_ESIGN_FLOW_STATE',
  ADD_COMPONENT = 'ADD_COMPONENT',
  UPDATE_COMPONENT = 'UPDATE_COMPONENT',
  UPDATE_COMPONENT_DIMENSION = 'UPDATE_COMPONENT_DIMENSION',
  UPDATE_COMPONENT_LABEL = 'UPDATE_COMPONENT_LABEL',
  COMPLETE_RECEIVER_COMPONENT = 'COMPLETE_RECEIVER_COMPONENT',
  SETUP_PAGE_IMAGE = 'SETUP_PAGE_IMAGE',
  SETUP_PAGE_IMAGE_HEIGHT = 'SETUP_PAGE_IMAGE_HEIGHT',
  REMOVE_COMPONENT = 'REMOVE_COMPONENT',
  REMOVE_COMPONENT_VALUE = 'REMOVE_COMPONENT_VALUE',
  SET_ACTIVE_COMPONENT = 'SET_ACTIVE_COMPONENT',
  REMOVE_ACTIVE_COMPONENT = 'REMOVE_ACTIVE_COMPONENT',
  START_SAVING_COMPONENTS = 'START_SAVING_COMPONENTS',
  STOP_SAVING_COMPONENTS = 'STOP_SAVING_COMPONENTS',
  RESET_ESIGN_TO_INITIAL_VALUES = 'RESET_ESIGN_TO_INITIAL_VALUES',
  ADD_PLACEHOLDER_COMPONENT = 'ADD_PLACEHOLDER_COMPONENT',
  REMOVE_PLACEHOLDER_COMPONENT = 'REMOVE_PLACEHOLDER_COMPONENT',
  TOGGLE_RESET_COMPONENTS_REFERENCE = 'TOGGLE_RESET_COMPONENTS_REFERENCE',
  INSERT_EVERYWHERE = 'INSERT_EVERYWHERE',
  SKIP_COMPONENT = 'SKIP_COMPONENT',
  START_FILLING_SIGNATURES = 'START_FILLING_SIGNATURES',
  UPDATE_CLIENT_SIGNATURE_FLOW_STATUS = 'UPDATE_CLIENT_SIGNATURE_FLOW_STATUS',
  UPDATE_PAGE_COMPONENTS_PLACEMENT = 'UPDATE_PAGE_COMPONENTS_PLACEMENT',
  SET_SELECTED_COMPONENT = 'SET_SELECTED_COMPONENT',
  REMOVE_SELECTED_COMPONENT = 'REMOVE_SELECTED_COMPONENT',
  DISABLE_COMPONENT_CLICKAWAY = 'DISABLE_COMPONENT_CLICKAWAY',
  ENABLE_COMPONENT_CLICKAWAY = 'ENABLE_COMPONENT_CIICKAWAY',
  SET_PAGE_COMPONENTS = 'SET_PAGE_COMPONENTS',
}

export interface SignaturePageComponent {
  componentType: SignatureComponentType;
  fieldType?: FieldType;
  inputType?: InputType;
  receiverUserId?: string;
  value?: string;
  key: string;
  xPosition: number;
  yPosition: number;
  page?: number;
  height: number;
  width: number;
  pageHeight?: number;
  pageWidth?: number;
  isOptional?: boolean;
  label?: string;
  isSkipped?: boolean;
  resizedWidth?: number;
  resizedHeight?: number;
  name?: string; // Holds value for signature/initials in text format
  isAgree?: boolean;
  autoFillField?: string;
  imageTextValue?: string; // Holds text value of the contract image based components
  encodedImgValue?: string;
}

export interface SignatureEvent {
  eventAt: string;
  eventType: string;
  performedByEmail: string;
  sourceIP: string;
}

export interface SignaturePageImageData {
  key: string;
  url: string;
  pageNumber: number;
  element?: { y: number; offsetHeight: number };
  loaded: boolean;
}

export enum SignaturePageImageStatus {
  complete = 'complete',
  pending = 'pending',
  fileNotFound = 'file_not_found',
  corruptFile = 'corrupt_file',
  setupFailed = 'setup_failed',
}

export interface SignaturePageState {
  channelId: string;
  fileId: string;
  fileIdentityId: string;
  fileKey: string;
  fileName: string;
  startedEsignFlow: boolean;
  currentStep: number;
  totalSteps: number;
  pageImages: Array<SignaturePageImageData>;
  pageComponents: Array<SignaturePageComponent>;
  initialPageComponents: Array<SignaturePageComponent>; // Need for resetting component placement. Has intact xPosition and yPosition for referencing during window resize.
  pagePlaceholderComponents: Array<SignaturePageComponent>;
  pageImageKeysField: Array<string>;
  pageImageStatus: SignaturePageImageStatus | '';
  loading: boolean;
  loaded: boolean;
  completeRenderPageImages: boolean;
  error?: string;
  activeComponentKey: string;
  saving: boolean;
  esignStatus: SignatureStatusTypes | '';
  isEsignPopoverOpen: boolean;
  resetComponentsReference: boolean;
  signatureFlowStatus: SignatureFlowStatus;
  selectedComponentKey: string;
  isSignatureButtonClickAwayDisabled: boolean;
}

export enum ESignatureFlowStatus {
  START_SIGNATURE = 'START_SIGNATURE',
  FILL_SIGNATURE = 'FILL_SIGNATURE',
  END_SIGNAURE = 'END_SIGNAURE',
}

export type SignatureFlowStatus = keyof typeof ESignatureFlowStatus;

interface GetPages {
  type: typeof SignaturePageActionTypes.GET_PAGES;
}

interface GetPagesSuccess {
  type: typeof SignaturePageActionTypes.GET_PAGES_SUCCESS;
  updatedState: Partial<SignaturePageState>;
  isClient: boolean;
  currentUserId: string;
}

interface GetPagesError {
  type: typeof SignaturePageActionTypes.GET_PAGES_ERROR;
  error: string;
}

export interface ChangeEsignFlowStateActionPayload {
  startedEsignFlow?: boolean;
  currentStep?: number;
  totalSteps?: number;
}

interface ChangeEsignFlowStateActionType {
  type: typeof SignaturePageActionTypes.CHANGE_ESIGN_FLOW_STATE;
  payload: ChangeEsignFlowStateActionPayload;
}

interface AddComponentActionType {
  type: typeof SignaturePageActionTypes.ADD_COMPONENT;
  component: SignaturePageComponent;
}

interface UpdateComponentActionType {
  type: typeof SignaturePageActionTypes.UPDATE_COMPONENT;
  component: SignaturePageComponent;
  data: DraggableData;
}
interface UpdateComponentDimensionsActionType {
  type: typeof SignaturePageActionTypes.UPDATE_COMPONENT_DIMENSION;
  component: SignaturePageComponent;
}

interface CompleteReceiverComponentActionType {
  type: typeof SignaturePageActionTypes.COMPLETE_RECEIVER_COMPONENT;
  component: SignaturePageComponent;
  originalComponentKey: string;
}

interface SetupSignaturePageImageActionType {
  type: typeof SignaturePageActionTypes.SETUP_PAGE_IMAGE;
  pageImageData: SignaturePageImageData;
}

interface SetupSignaturePageImageHeightActionType {
  type: typeof SignaturePageActionTypes.SETUP_PAGE_IMAGE_HEIGHT;
  key: string;
  y: number;
}

interface RemoveComponentActionType {
  type: typeof SignaturePageActionTypes.REMOVE_COMPONENT;
  componentKey: string;
  forContracts?: boolean;
}

interface RemoveComponentValueActionType {
  type: typeof SignaturePageActionTypes.REMOVE_COMPONENT_VALUE;
  componentKey: string;
}

interface SetActiveComponentActionType {
  type: typeof SignaturePageActionTypes.SET_ACTIVE_COMPONENT;
  componentKey: string;
}

interface RemoveActiveComponentActionType {
  type: typeof SignaturePageActionTypes.REMOVE_ACTIVE_COMPONENT;
}

export type ComponentCoordinatesType = {
  xPosition: number;
  yPosition: number;
};

export type ClientTextComponentFields = {
  label?: string;
  isOptional?: boolean;
};

interface StartSavingComponents {
  type: typeof SignaturePageActionTypes.START_SAVING_COMPONENTS;
}

interface StopSavingComponents {
  type: typeof SignaturePageActionTypes.STOP_SAVING_COMPONENTS;
}

interface ResetESignToInitialValuesActionType {
  type: typeof SignaturePageActionTypes.RESET_ESIGN_TO_INITIAL_VALUES;
}

interface AddPlaceholderComponentActionType {
  type: typeof SignaturePageActionTypes.ADD_PLACEHOLDER_COMPONENT;
  component: SignaturePageComponent;
}

interface RemovePlaceholderComponentActionType {
  type: typeof SignaturePageActionTypes.REMOVE_PLACEHOLDER_COMPONENT;
}

interface ToggleResetComponentsReferenceActionType {
  type: typeof SignaturePageActionTypes.TOGGLE_RESET_COMPONENTS_REFERENCE;
}

interface InsertEverywhereActionType {
  type: typeof SignaturePageActionTypes.INSERT_EVERYWHERE;
  component: SignaturePageComponent;
}

interface SkipComponentActionType {
  type: typeof SignaturePageActionTypes.SKIP_COMPONENT;
  componentKey: string;
}
interface UpdatePageComponentsPlacementActionType {
  type: typeof SignaturePageActionTypes.UPDATE_PAGE_COMPONENTS_PLACEMENT;
  pageComponents: Array<SignaturePageComponent>;
}

interface StartFillingSignaturesActionType {
  type: typeof SignaturePageActionTypes.START_FILLING_SIGNATURES;
}

interface UpdateClientSigantureFlowStatusActionType {
  type: typeof SignaturePageActionTypes.UPDATE_CLIENT_SIGNATURE_FLOW_STATUS;
  status: ESignatureFlowStatus;
}

interface SetSelectedComponentActionType {
  type: typeof SignaturePageActionTypes.SET_SELECTED_COMPONENT;
  componentKey: string;
}
interface RemoveSelectedComponentActionType {
  type: typeof SignaturePageActionTypes.REMOVE_SELECTED_COMPONENT;
}

interface UpdateComponentLabelActionType {
  type: typeof SignaturePageActionTypes.UPDATE_COMPONENT_LABEL;
  componentKey: string;
  label: string;
  isOptional: boolean;
}
interface DisableComponentClickAwayAction {
  type: typeof SignaturePageActionTypes.DISABLE_COMPONENT_CLICKAWAY;
}

interface EnableComponentClickAwayAction {
  type: typeof SignaturePageActionTypes.ENABLE_COMPONENT_CLICKAWAY;
}

interface SetPageComponentsActionType {
  type: typeof SignaturePageActionTypes.SET_PAGE_COMPONENTS;
  pageComponents: SignaturePageComponent[];
}

export type SignaturePageActions =
  | GetPages
  | GetPagesSuccess
  | GetPagesError
  | ChangeEsignFlowStateActionType
  | AddComponentActionType
  | UpdateComponentActionType
  | UpdateComponentDimensionsActionType
  | CompleteReceiverComponentActionType
  | SetupSignaturePageImageActionType
  | SetupSignaturePageImageHeightActionType
  | RemoveComponentActionType
  | RemoveComponentValueActionType
  | SetActiveComponentActionType
  | RemoveActiveComponentActionType
  | StartSavingComponents
  | StopSavingComponents
  | ResetESignToInitialValuesActionType
  | AddPlaceholderComponentActionType
  | RemovePlaceholderComponentActionType
  | ToggleResetComponentsReferenceActionType
  | InsertEverywhereActionType
  | SkipComponentActionType
  | UpdatePageComponentsPlacementActionType
  | StartFillingSignaturesActionType
  | UpdateClientSigantureFlowStatusActionType
  | SetSelectedComponentActionType
  | RemoveSelectedComponentActionType
  | UpdateComponentLabelActionType
  | DisableComponentClickAwayAction
  | EnableComponentClickAwayAction
  | SetPageComponentsActionType;
