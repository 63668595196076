import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GolfIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 15.625V1.25L16.875 4.375L10 7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 13.125C6.60166 13.125 3.15244 14.8125 2.51416 17.9977C2.4372 18.3816 2.67861 18.75 3.12509 18.75H16.8751C17.322 18.75 17.5634 18.3816 17.4864 17.9977C16.8478 14.8125 13.3985 13.125 10.0001 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
