import React from 'react';
import { ChipCellRendererProps } from 'src/legacy/components/AgGrid/Renderer/types';
import { BaseChip } from 'src/legacy/components/UI';
import { convertTitleCase } from 'src/utils/StringUtils';

export const ChipCellRenderer: React.FC<ChipCellRendererProps> = React.memo(
  ({ mapStatusToSeverity, value }) => (
    <BaseChip
      severity={mapStatusToSeverity(value)}
      label={convertTitleCase(value)}
    />
  ),
);
