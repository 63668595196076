import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BookOpenIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 4.253C11.168 3.477 12.754 3 14.5 3C16.247 3 17.832 3.477 19 4.253V17.253C17.832 16.477 16.247 16 14.5 16C12.754 16 11.168 16.477 10 17.253M10 4.253V17.253V4.253ZM10 4.253C8.832 3.477 7.246 3 5.5 3C3.754 3 2.168 3.477 1 4.253V17.253C2.168 16.477 3.754 16 5.5 16C7.246 16 8.832 16.477 10 17.253V4.253Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
