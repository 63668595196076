import { Theme } from '@material-ui/core';
import { NonHoverBorder } from 'src/theme/colors';

export default {
  colorPrimary: {
    '&$checked': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    color: NonHoverBorder,
  },
};

export const overrideRadio = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiRadio) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiRadio = {
      colorPrimary: {
        ...theme.overrides.MuiRadio.colorPrimary,
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
        },
      },
    };
  }
};
