import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ForwardIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5.97766 20L15.9777 10L5.97766 0L4.5 1.5L13 10L4.5 18.5L5.97766 20Z"
      fill="currentColor"
    />
  </SvgIcon>
);
