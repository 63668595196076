import {
  SET_RUNTIME_VARIABLE,
  SetRuntimeAction,
} from 'src/store/runtime/types';

/* eslint-disable-next-line default-param-last */
export default function runtime(state = {}, action: SetRuntimeAction) {
  switch (action.type) {
    case SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
}
