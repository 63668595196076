import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const VisibleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.98676 4.375C6.94223 4.375 3.81918 6.13711 1.36058 9.66133C1.29041 9.76303 1.25195 9.88325 1.25007 10.0068C1.24819 10.1303 1.28298 10.2517 1.35004 10.3555C3.2391 13.3125 6.32035 15.625 9.98676 15.625C13.6133 15.625 16.7578 13.3055 18.6504 10.3418C18.7159 10.24 18.7507 10.1216 18.7507 10.0006C18.7507 9.87958 18.7159 9.76113 18.6504 9.65937C16.7536 6.72969 13.586 4.375 9.98676 4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
