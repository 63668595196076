import React, { CSSProperties, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import {
  Box,
  ButtonProps,
  GridSize,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { IconType } from 'react-icons';
import _ from 'lodash';
import BackDrop from 'src/legacy/components/BackDrop';
import { setCanSubmitFormPage, setFormPageChanged } from 'src/store/ui/actions';
import useSavePageUnload from 'src/hooks/useSavePageUnload';
import { FormPageAction as DefaultFormPageHeader } from 'src/legacy/components/Page/FormPageAction';
import { RootState } from 'src/store';
import { ActionButtonModal } from 'src/legacy/components/Modals';
import { HeaderStyleProps } from 'src/legacy/components/Page/formPageTypes';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { useFormSubmitShortcut } from 'src/hooks/useFormSubmitShortcut';

interface OptionalFormPageProps
  extends Partial<Record<Breakpoint, boolean | GridSize> & HeaderStyleProps> {
  entityName: string;
  title: React.ReactElement | string | React.ReactNode;
  titleIcon: IconType | typeof SvgIcon;
  buttonLabel: string;
  actionLabel: ReactNode;
  cancelLabel: string;
  createButton: boolean;
  createButtonProps: ButtonProps;
  handleCancel?: () => void;
  shouldModalOpen: () => boolean;
  closeButton: boolean;
  isOuterBackdrop?: boolean;
  shouldShowPageAction?: boolean;
  disableSubmit?: boolean;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  pageActionPosition?: 'top' | 'bottom';
  isSettingsForm?: boolean;
  showConfirmationModal?: boolean;
  deleteLabel: string;
  showDeleteAction?: boolean;
  hideCancelAction?: boolean;
  handleDelete?: () => void;
  hideBreadCrumb?: boolean;
  BreadcrumbsComponent?: JSX.Element;
  hideSaveBarActions?: boolean;
  showAuthNavMenu?: boolean;
  parentPageTitle?: string;
  resetFormOnCancel?: boolean;
  isContentLoading?: boolean;
  formStyle?: CSSProperties;
  refreshOnSave?: boolean;
  hideFormToolbar?: boolean;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  metadata?: Record<string, any>;
  HeaderComponent?: React.FC<any>;
  previewIsOn?: boolean;
  onTogglePreview?: () => void;
  cancelDisabled?: boolean;
}

interface RequiredFormPageProps<Values extends FormikValues> {
  handleSave: (values: Values) => Promise<void>;
  initialFormValue: Values;
}

export type FormRenderer<Values extends FormikValues> = React.FC<
  FormikProps<Values> & { previewIsOn?: boolean }
>;

type UseFormHook<Values extends FormikValues> = () => {
  validationScheme: FormikConfig<Values>['validationSchema'];
  FormRenderer: FormRenderer<Values>;
  submitDisabledTooltip?: React.ReactNode;
};

// This any type is unfortunate, but this used to be a non-typed components.
// Once we get all forms to pass a generic explicitly we can remove it.
export type FormPageProps<Values extends FormikValues = any> =
  RequiredFormPageProps<Values> &
    Partial<OptionalFormPageProps> & {
      useFormHook: UseFormHook<Values>;
    };

export function FormPage<Values extends FormikValues>({
  entityName,
  title,
  titleIcon: TitleIcon,
  actionLabel,
  cancelLabel = 'Cancel',
  initialFormValue,
  handleCancel,
  handleSave,
  useFormHook,
  isOuterBackdrop = false,
  shouldShowPageAction = false,
  headerVariant = 'default',
  disableSubmit = false,
  validateOnChange = true,
  validateOnBlur = true,
  isSettingsForm = false,
  showConfirmationModal = false,
  deleteLabel = 'Delete',
  showDeleteAction = false,
  hideCancelAction = false,
  handleDelete,
  BreadcrumbsComponent,
  hideSaveBarActions = false,
  showAuthNavMenu = false,
  resetFormOnCancel = true,
  formStyle = {},
  refreshOnSave = false,
  hideFormToolbar = false,
  showBackButton = false,
  onBackButtonClick,
  HeaderComponent,
  previewIsOn = false,
  onTogglePreview,
  cancelDisabled = false,
}: FormPageProps<Values>) {
  const removeUnloadListener = useSavePageUnload();

  const dispatch = useDispatch();
  const { validationScheme, FormRenderer, submitDisabledTooltip } =
    useFormHook();
  const [isSaving, setIsSaving] = React.useState(false);
  const formRef = React.useRef<any>();
  const canSubmitFormPage = useSelector(
    (state: RootState) => state.ui.canSubmitFormPage,
  );
  const [
    isSettingFormConfirmationModalOpen,
    setIsSettingFormConfirmationModalOpen,
  ] = React.useState(false);
  const hasTitle = title || entityName;

  const titleValue =
    typeof title === 'string'
      ? title ||
        (initialFormValue.id ? `Update ${entityName}` : `Create ${entityName}`)
      : '';

  /**
   * This method will handle edit/create form entity.
   * @param values
   */
  const saveChanges = async (values: Values) => {
    setIsSaving(true);

    await handleSave({
      ...values,
      // TODO: I'm not sure what `fields` is for, but it's such a common word
      //       in the codebase that I can't guarantee we're not using it anywhere.
      fields: values.fields ?? {},
    });

    // if we want to refresh on save, then clear the unload listener
    // to avoid a browser prompt
    if (refreshOnSave) {
      removeUnloadListener();
      window.location.reload();
    }

    dispatch(setFormPageChanged(false));
    dispatch(setCanSubmitFormPage(false));
    setIsSaving(false);
  };

  /**
   * When form submit button is clicked, this
   * method will check if it is a settings form
   * it will ask user for changes confirmation.
   * Otherwise it will save changes directly.
   * @param values: formik values
   */
  const onFormSubmit = async (values: Values) => {
    if (!showConfirmationModal) {
      saveChanges(values);
      return;
    }

    setIsSettingFormConfirmationModalOpen(true);
  };

  /**
   * This function will be triggered when user confirm
   * changes from confirmation modal.
   */
  const handleConfirmSave = async () => {
    if (formRef.current) {
      const formValues = formRef.current.values;
      saveChanges(formValues);
      setIsSettingFormConfirmationModalOpen(false);
    }
  };

  /**
   * This method closes saving changes confirmation
   * modal.
   */
  const handleDismiss = () => {
    setIsSettingFormConfirmationModalOpen(false);
  };

  return (
    <>
      <Formik<Values>
        innerRef={formRef}
        enableReinitialize
        initialValues={initialFormValue}
        validationSchema={validationScheme}
        validateOnChange={validateOnChange}
        validateOnBlur={validateOnBlur}
        onSubmit={onFormSubmit}
      >
        {(props) => {
          useFormSubmitShortcut(props.handleSubmit);
          const handleClickCancel = () => {
            if (resetFormOnCancel) {
              props.resetForm(props.initialValues);
            }
            if (handleCancel) {
              handleCancel();
            }
          };
          React.useEffect(() => {
            dispatch(setFormPageChanged(props.dirty));
          }, [props.dirty]);

          React.useEffect(() => {
            if (canSubmitFormPage) {
              onFormSubmit(props.values);
            }
          }, [props.values, canSubmitFormPage]);

          /**
           * This method calculates the form page title
           * When breadcrumb component renderer is provided,
           * then render it, otherwise render the title.
           * @returns {JSX.Element}
           */
          const getTitleProps = () => {
            // when form page breadcrumbs is available render is as title
            if (BreadcrumbsComponent) {
              return {
                title: BreadcrumbsComponent,
              };
            }
            // if title is available, render it
            if (title) {
              return {
                title: (
                  <BaseTypography
                    fontType="13Medium"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {title}
                  </BaseTypography>
                ),
              };
            }

            return null;
          };

          const showFormAction =
            ((_.isEmpty(props.errors) && props.dirty) ||
              shouldShowPageAction) &&
            !hideSaveBarActions;

          const FormPageHeaderComponent =
            HeaderComponent || DefaultFormPageHeader;

          return (
            <form onSubmit={props.handleSubmit} style={formStyle}>
              {!hideFormToolbar && (
                <FormPageHeaderComponent
                  onTogglePreview={onTogglePreview}
                  previewIsOn={previewIsOn}
                  isSettingsForm={isSettingsForm}
                  onCancel={handleClickCancel}
                  isDisabled={disableSubmit || Boolean(submitDisabledTooltip)}
                  tooltipComponent={submitDisabledTooltip}
                  isSubmitting={isSaving}
                  cancelLabel={cancelLabel}
                  actionLabel={actionLabel}
                  deleteLabel={deleteLabel}
                  onDelete={handleDelete}
                  showActions={showFormAction}
                  hideCancelAction={hideCancelAction}
                  showAuthNavMenu={showAuthNavMenu}
                  showDeleteActon={showDeleteAction && !hideSaveBarActions}
                  {...getTitleProps()}
                  isNew={!initialFormValue.id}
                  headerVariant={headerVariant}
                  showBackButton={showBackButton}
                  onBackButtonClick={onBackButtonClick}
                  dirty={props.dirty}
                  cancelDisabled={cancelDisabled}
                />
              )}
              {!shouldShowPageAction && hasTitle && (
                <Box fontSize={19} fontWeight={500}>
                  {TitleIcon && <TitleIcon />}
                  <Typography>
                    <Box fontSize={19} fontWeight={500} component="span">
                      {titleValue}
                    </Box>
                  </Typography>
                </Box>
              )}
              {FormRenderer({
                ...props,
                previewIsOn,
              })}
            </form>
          );
        }}
      </Formik>
      {isOuterBackdrop && <BackDrop isModalOpen />}
      {showConfirmationModal && (
        <ActionButtonModal
          open={isSettingFormConfirmationModalOpen}
          onClose={handleDismiss}
          positiveAction
          onPositiveActionPerformed={handleConfirmSave}
          title="Save changes"
          positiveActionText="Confirm"
          mainComponent="Are you sure you want to save your changes?"
        />
      )}
    </>
  );
}
