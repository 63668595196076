import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const FileUploadIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15 18.125C15.3438 18.125 15.625 17.8438 15.625 17.5V6.25H12.5C11.8086 6.25 11.25 5.69141 11.25 5V1.875H5C4.65625 1.875 4.375 2.15625 4.375 2.5V17.5C4.375 17.8438 4.65625 18.125 5 18.125H15ZM2.5 2.5C2.5 1.12109 3.62109 0 5 0H11.4648C12.1289 0 12.7656 0.261719 13.2344 0.730469L16.7695 4.26562C17.2383 4.73438 17.5 5.37109 17.5 6.03516V17.5C17.5 18.8789 16.3789 20 15 20H5C3.62109 20 2.5 18.8789 2.5 17.5V2.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
