import { Theme } from '@material-ui/core';

export const zIndex = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  rightSidebar: 1200,
  drawer: 1170,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
  loadingSpinner: 3000,
  contractSignatureComponent: 2,
};

declare module '@material-ui/core/styles' {
  export interface CustomTheme extends Theme {
    zIndex: Theme['zIndex'] & {
      contractSignatureComponent: number;
    };
  }
}
