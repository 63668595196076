import { Dispatch } from 'redux';
import {
  FormResponse,
  FormResponseStatus,
  FormVisibility,
} from 'src/legacy/components/FormsV2/formsTypes';
import { Events } from 'src/constants/webSocketConsts/events';
import { formsApi } from 'src/services/api/formsApi';

const updateCache = (
  draft: FormResponse[],
  event: Events,
  items: FormResponse[],
) => {
  const [upcomingFormResponseItem] = items;
  // find the index of the form response object to update/delete/insert.
  const upcomingFormResponseItemIndex = draft.findIndex(
    (formResponse) => formResponse.ref === upcomingFormResponseItem.ref,
  );

  switch (event) {
    case Events.Insert:
      // if the form response already exists, do not add it to the forms responses state
      if (upcomingFormResponseItemIndex !== -1) {
        return;
      }

      // when a form response item comes with response-requested (pending) status,
      // check if it is related to an open-always form.
      // if so, remove the open-always form response item so to prevent
      // duplicate form responses in the forms responses table.
      if (
        upcomingFormResponseItem.fields.status === FormResponseStatus.Pending
      ) {
        const existingAlwaysOnFormResponse = draft.find(
          (formResp) =>
            formResp.formId === upcomingFormResponseItem.formId &&
            formResp.fields.visibility === FormVisibility.AllClients,
        );

        if (existingAlwaysOnFormResponse) {
          draft.splice(draft.indexOf(existingAlwaysOnFormResponse), 1);
        }
      }

      // add the form response to the forms responses state

      draft.unshift(upcomingFormResponseItem);
      break;
    case Events.Remove:
      draft.splice(upcomingFormResponseItemIndex, 1);
      break;
    case Events.Modify:
      // update the forms responses cache with the upcoming form response.
      const existingFormResponse = draft.find(
        (formResponse) => formResponse.ref === upcomingFormResponseItem.ref,
      );
      // if the form response already exists, update it.
      if (existingFormResponse) {
        draft.splice(
          0,
          draft.length,
          ...draft.map((formResponse) => {
            if (formResponse.ref === upcomingFormResponseItem.ref) {
              return upcomingFormResponseItem;
            }
            return formResponse;
          }),
        );
        return;
      } else {
        // if the form response does not exist, add it to the forms responses state
        draft.unshift(upcomingFormResponseItem);
      }

      break;
    default:
      break;
  }
};
/**
 * This function is responsible of handling the websocket
 * events cache updates for the forms responses.
 * The updateQueryData method is used to apply the changes
 * to the forms responses cache state.
 * @param event - web socket event name
 * @param items - web socket items
 * @returns void
 */
export const FormsV2ResponseHandler = (
  event: Events,
  items: FormResponse[],
  dispatch: Dispatch<any>,
) => {
  // when a form response web socket event comes in,
  // we need to update the following rtk query caches:
  // 1. getResponsesByFormId - update the form responses cache for the specific form.
  // 2. getResponsesByUserId - update the form responses cache for the specific user.
  // 3. getFormResponses - update the form responses cache for all form responses.

  const item = items.at(0);

  if (item) {
    dispatch(
      formsApi.util.updateQueryData(
        'getResponsesByClientId',
        item.clientId,
        (draft) => updateCache(draft, event, items),
      ),
    );
    dispatch(
      formsApi.util.updateQueryData(
        'getResponsesByFormId',
        item.formId,
        (draft) => updateCache(draft, event, items),
      ),
    );
  }

  return formsApi.util.updateQueryData('getFormResponses', undefined, (draft) =>
    updateCache(draft, event, items),
  );
};
