import React, { PropsWithChildren } from 'react';
import { Tabs as MuiTabs, makeStyles, Grid, Theme } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import { BaseTab } from 'src/legacy/components/UI/BaseTab/BaseTab';
import { Tab } from 'src/types/crm_details';

export const TABS_CONTAINER_HEIGHT = 64;

export type BaseTabsHeaderProps<T extends string> = {
  tabs: Array<Tab<T>>;
  activeTabKey: T | undefined;
  onTabChange: (newValue: T) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    overflow: 'inherit',
    minHeight: 'unset',
    borderBottom: `1px solid ${Colors.NonHoverBorder}`,
  },
  tabsFlexContainer: {
    display: 'flex',
    height: `${TABS_CONTAINER_HEIGHT}px`,
    alignItems: 'center',
    padding: theme.spacing(0, 4.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  // the base tabs header can host an inline element
  // e.g. in clients details tabs, we show the channel selector inlined
  // with the tabs.
  inlineElementContainer: {
    // the inline element will be aligned to the right of the tabs
    marginLeft: 'auto',
    // only show the inline element on desktop
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  // on mobile screen, the inline element will be shown below the tabs
  // and will take the full width of the screen
  mobileInlineElementContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tabBtn: {
    height: 'inherit',
    // Resetting disabled tab button pointer-events:none
    // In cases where we want to display a tooltip on disabled buttons,
    // we set pointer events to 'auto' since tooltips use mouse events to render.
    // Mouse events will only trigger when pointer events are not set to 'none'.
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  tabsIndicator: {
    height: '1px', // the height of the indicator is 1px regarding figma
  },
}));

/**
 * Base Tabs component renders tabs header
 * @returns {React.ReactElement}
 */
export const BaseTabsHeader = <T extends string>({
  tabs,
  activeTabKey,
  onTabChange,
  children,
}: PropsWithChildren<BaseTabsHeaderProps<T>>) => {
  const classes = useStyles();
  const fallbackTab = tabs.length ? tabs.at(0)?.key : '';
  const activeTab = activeTabKey || fallbackTab;
  return (
    <Grid container>
      <Grid item xs={12}>
        <MuiTabs
          value={activeTab}
          onChange={(_, newVal) => onTabChange(newVal)}
          aria-label="simple tabs example"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            root: classes.tabsRoot,
            flexContainer: classes.tabsFlexContainer,
            indicator: classes.tabsIndicator,
          }}
        >
          {tabs.map((tab) => (
            <BaseTab
              className={classes.tabBtn}
              key={tab.key}
              label={tab.label}
              value={tab.key}
              id={tab.key}
              aria-controls={tab.key}
              data-testid={`tab-${tab.key}`}
              disabled={tab.disabled}
            />
          ))}

          <div className={classes.inlineElementContainer}>{children}</div>
        </MuiTabs>

        <div className={classes.mobileInlineElementContainer}>{children}</div>
      </Grid>
    </Grid>
  );
};
