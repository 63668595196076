import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrailSignIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 15.625V18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8.125V10.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.875V3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 8.125H4.00898C3.92686 8.12501 3.84553 8.10884 3.76966 8.07741C3.69378 8.04597 3.62485 7.99989 3.5668 7.9418L1.25 5.625L3.5668 3.3082C3.62485 3.25011 3.69378 3.20403 3.76966 3.1726C3.84553 3.14116 3.92686 3.12499 4.00898 3.125H16.25C16.4158 3.125 16.5747 3.19085 16.6919 3.30806C16.8092 3.42527 16.875 3.58424 16.875 3.75V7.5C16.875 7.66576 16.8092 7.82473 16.6919 7.94194C16.5747 8.05915 16.4158 8.125 16.25 8.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 15.625H15.991C16.0731 15.625 16.1545 15.6088 16.2303 15.5774C16.3062 15.546 16.3751 15.4999 16.4332 15.4418L18.75 13.125L16.4332 10.8082C16.3751 10.7501 16.3062 10.704 16.2303 10.6726C16.1545 10.6412 16.0731 10.625 15.991 10.625H3.75C3.58424 10.625 3.42527 10.6908 3.30806 10.8081C3.19085 10.9253 3.125 11.0842 3.125 11.25V15C3.125 15.1658 3.19085 15.3247 3.30806 15.4419C3.42527 15.5592 3.58424 15.625 3.75 15.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
