import React from 'react';
import { CLIENT_DETAILS_PAGE, SETTINGS_TEAM_PAGE } from 'src/constants';
import { RouteContext } from 'src/context';
import { useKeyboardShortcuts } from 'src/hooks/useKeyboardShortcuts';
import useNavigate from 'src/hooks/useNavigate';
import { useUserCanInvite } from 'src/hooks/useUserCanInvite';

export const useInviteMemberCommandAction = () => {
  const { pathname } = React.useContext(RouteContext);
  const { navigate } = useNavigate();
  const { isUserCanInvite } = useUserCanInvite();

  // setup invite team member command action if user has permission
  useKeyboardShortcuts(
    isUserCanInvite
      ? [
          {
            id: 'invite-team-member-action',
            name: 'Invite team member',
            perform: () =>
              navigate(`${SETTINGS_TEAM_PAGE.path}?openInviteModal=true`),
            section: pathname.includes(CLIENT_DETAILS_PAGE.path)
              ? 'General'
              : undefined,
          },
        ]
      : [],
  );
};
