import { typography13RegularStyle } from 'src/legacy/components/Text';
import { red } from 'src/theme/colors';

export default {
  root: {
    textAlign: 'right',
    ...typography13RegularStyle,
    marginTop: 0,
    '&.MuiFormHelperText-root.Mui-error': {
      color: red,
    },
  },
  contained: {
    marginRight: 0,
  },
};
