import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FlashlightIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.8375 6.36174L13.6374 2.16252C13.1238 1.64924 12.5171 1.88244 12.0039 2.39689L11.5371 2.86564C10.5164 3.88635 10.4582 5.14611 10.3878 6.11994C10.3531 6.59807 10.0058 7.19611 9.66479 7.53713L2.28823 14.909C1.64956 15.5469 1.79253 16.4488 2.43393 17.0903L2.90932 17.5656C3.54839 18.2043 4.44956 18.3531 5.09136 17.7117L12.4675 10.3367C12.8089 9.99533 13.405 9.65119 13.8851 9.6133C14.8566 9.54064 16.175 9.42385 17.1374 8.46135L17.6039 7.99494C18.1175 7.48166 18.3507 6.87502 17.8375 6.36174Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M8.77637 12.0032C9.20784 12.0032 9.55762 11.6534 9.55762 11.2219C9.55762 10.7905 9.20784 10.4407 8.77637 10.4407C8.34489 10.4407 7.99512 10.7905 7.99512 11.2219C7.99512 11.6534 8.34489 12.0032 8.77637 12.0032Z"
      fill="currentColor"
    />
    <path
      d="M11.2891 3.16406L16.8359 8.71094"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
