import * as React from 'react';
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { ButtonProps } from '@material-ui/core';

import BackDrop from 'src/legacy/components/BackDrop';
import {
  ModalWrapper,
  ModalWrapperProps,
} from 'src/legacy/components/Modals/ModalWrapper';
import { CreateButton } from 'src/legacy/components/UI';
import { CreateButtonProps } from 'src/legacy/components/UI/Buttons/CreateButton';
import { useFormSubmitShortcut } from 'src/hooks/useFormSubmitShortcut';
import { pascalToSnakeCase } from 'src/utils/StringUtils';

interface FormModalProps {
  entityName: string;
  title: string;
  buttonLabel: string;
  noCancelButton?: boolean;
  cancelButtonLabel: string;
  buttonEndIcon?: JSX.Element;
  description: string;
  actionLabel: string;
  open: boolean;
  createButton: boolean;
  createButtonProps: ButtonProps | Partial<CreateButtonProps>;
  showProgress: boolean;
  onClose: () => void;
  shouldModalOpen: () => boolean;
  skipResetOnSubmit?: boolean;
  showSaveAction?: boolean;
  isOuterBackdrop?: boolean;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  modalProps?: ModalWrapperProps;
  formRef?: any;
  width?: number;
}

type UseFormHook<Values extends FormikValues> = () => {
  validationScheme: FormikConfig<Values>['validationSchema'];
  FormRenderer: React.FC<FormikProps<Values>>;
  getSubmitValues?: (values?: Values) => Promise<any>;
  submitDisabledTooltip?: React.ReactNode;
};

interface RequiredFormPageProps<Values extends FormikValues> {
  handleSave: (values: Values) => Promise<void>;
  initialFormValue: Values;
}

export type FormModalPageProps<Values extends FormikValues = any> =
  RequiredFormPageProps<Values> &
    Partial<FormModalProps> & {
      useFormHook: UseFormHook<Values>;
    };

export function FormModal<Values extends FormikValues>({
  entityName,
  title,
  buttonLabel,
  noCancelButton,
  cancelButtonLabel,
  buttonEndIcon,
  actionLabel,
  initialFormValue,
  shouldModalOpen,
  skipResetOnSubmit = false,
  handleSave,
  showSaveAction = true,
  useFormHook,
  onClose,
  createButton = false,
  createButtonProps = {},
  open,
  isOuterBackdrop = false,
  validateOnChange = true,
  validateOnBlur = true,
  modalProps = {
    open: true,
  },
  width,
  formRef,
}: FormModalPageProps<Values>) {
  const { validationScheme, getSubmitValues, FormRenderer } = useFormHook();
  const [modalState, setModalState] = React.useState({
    formValue: initialFormValue,
    open: false,
  });
  const cachedModalState = React.useMemo(
    () => ({
      ...modalState,
      formValue: initialFormValue,
    }),
    [initialFormValue, modalState],
  );

  const openModal = () => {
    if (!shouldModalOpen || shouldModalOpen()) {
      setModalState({
        formValue: initialFormValue,
        open: true,
      });
    }
  };

  const resetModal = () => {
    if (onClose) {
      onClose();
    }
    setModalState({
      formValue: initialFormValue,
      open: false,
    });
  };

  const closeModal = () => {
    resetModal();
  };

  const handleFormSubmit: FormikConfig<Values>['onSubmit'] = async (
    values,
    formikHelpers,
  ) => {
    const { setSubmitting, resetForm } = formikHelpers;
    const extraValues = getSubmitValues ? await getSubmitValues(values) : {};
    const { isValid = true } = extraValues;

    if (isValid) {
      delete extraValues.isValid;
      const params = {
        ...values,
        fields: { ...values.fields, ...extraValues },
      };
      try {
        if (handleSave) {
          await handleSave(params);
        }
        if (!skipResetOnSubmit) {
          if (resetForm) {
            resetForm();
          }
          resetModal();
        }
      } catch (err) {
        console.info('Submitting form error', err);
      }
    }
    setSubmitting(false);
  };

  const modalOpen = React.useMemo(
    () => cachedModalState.open || open,
    [cachedModalState, open],
  );

  return (
    <>
      {createButton && (
        <CreateButton
          htmlId={
            actionLabel
              ? `btn-create${pascalToSnakeCase(actionLabel)}`
              : `btn-create-${entityName}`
          }
          onClick={openModal}
          {...createButtonProps}
        >
          {actionLabel || 'New'}
        </CreateButton>
      )}

      {modalOpen && (
        <Formik<Values>
          innerRef={formRef}
          enableReinitialize
          initialValues={cachedModalState.formValue}
          validationSchema={validationScheme}
          onSubmit={handleFormSubmit}
          validateOnChange={validateOnChange}
          validateOnBlur={validateOnBlur}
        >
          {(props) => {
            useFormSubmitShortcut(props.handleSubmit);

            return (
              <ModalWrapper
                title={
                  title ||
                  (cachedModalState.formValue.id
                    ? `Update ${entityName}`
                    : `New ${entityName}`)
                }
                onClose={() => {
                  if (props.resetForm) {
                    props.resetForm();
                  }
                  closeModal();
                }}
                onSuccess={props.submitForm}
                isLoading={props.isSubmitting}
                positiveAction={showSaveAction}
                successButtonLabel={buttonLabel}
                successButtonEndIcon={buttonEndIcon}
                cancelButtonLabel={cancelButtonLabel}
                {...(width ? { width } : {})}
                {...modalProps}
                open={modalProps?.open ?? modalOpen}
                noCancelButton={noCancelButton}
              >
                {FormRenderer(props)}
              </ModalWrapper>
            );
          }}
        </Formik>
      )}

      {isOuterBackdrop && <BackDrop isModalOpen />}
    </>
  );
}
