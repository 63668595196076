import React from 'react';

export type SidebarTheme = {
  sidebarTextColor: string;
  sidebarBackgroundColor: string;
  sidebarItemHoverBackground: string;
};
export const SidebarThemeContext = React.createContext<SidebarTheme>({
  sidebarTextColor: '',
  sidebarBackgroundColor: '',
  sidebarItemHoverBackground: '',
});
