import React, { useMemo, useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Icon, IconButton } from 'copilot-design-system';

const OptionsMenu = ({
  handleClear,
  isEmptyAddress,
}: {
  handleClear: () => void;
  isEmptyAddress: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const optionsList = useMemo(() => {
    const options = [];
    if (!isEmptyAddress) {
      options.push(
        <MenuItem
          key="deleteAddress"
          onClick={() => {
            handleClear();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon classes={{ root: 'min-w-8' }}>
            <Icon icon="Trash" className="w-4 h-4 text-red" />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'text-red' }}>
            Delete address
          </ListItemText>
        </MenuItem>,
      );
    }
    return options;
  }, [isEmptyAddress]);

  if (optionsList.length === 0) return null;

  return (
    <>
      <IconButton
        className="w-6 h-6 p-0 flex items-center justify-center hover:bg-gray-100 [&>*]:w-4 [&>*]:h-4"
        icon="Ellipsis"
        label="options"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="minimal"
      />
      <Menu
        open={anchorEl !== null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper:
            'border border-solid border-secondary-hover shadow-dropdown-menu',
        }}
      >
        {optionsList.map((optionItem) => optionItem)}
      </Menu>
    </>
  );
};

export default OptionsMenu;
