import { User, UserRolesTypesUnMapped } from 'src/constants/dataTypes';

export interface AttributeField {
  Name: string;
  Value: string;
}

export interface UsersState {
  users: User[];
  internalUsers: User[];
  isLoading: boolean;
  error: string;
  loadedUsers: boolean;
  isCreating: boolean;
  isCreated: boolean;
  isUpdating: boolean;
  isUpdated: boolean;
  isResendingInvite: boolean;
}

export const LOAD_USERS_REQUEST = 'LOADING_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const ADD_USER_REQUEST = 'ADDING_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_CLIENT_FAILURE';
export const UPDATE_MEMBER_REQUEST = 'UPDATEING_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';
export const SET_USER_ROLE_REQUEST = 'SET_USER_ROLE_REQUEST';
export const SET_USER_ROLE_SUCCESS = 'SET_USER_ROLE_SUCCESS';
export const SET_USER_ROLE_FAILURE = 'SET_USER_ROLE_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const SET_INTERNAL_USERS = 'SET_INTERNAL_USERS';
export const ADD_INTERNAL_USERS = 'ADD_INTERNAL_USERS';
export const MODIFY_INTERNAL_USERS = 'MODIFY_INTERNAL_USERS';
export const REMOVE_INTERNAL_USERS = 'REMOVE_INTERNAL_USERS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const INVITE_USER_REQUEST = 'INVITING_USER';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';

interface StartLoadUsersAction {
  type: typeof LOAD_USERS_REQUEST;
}

interface LoadUsersDoneAction {
  type: typeof LOAD_USERS_SUCCESS;
  payload: User[];
}

interface UpdateUsersDoneAction {
  type: typeof UPDATE_USERS_SUCCESS;
  payload: User[];
}

interface LoadUsersFailAction {
  type: typeof LOAD_USERS_FAILURE;
  error: string;
}

interface StartAddUserAction {
  type: typeof ADD_USER_REQUEST;
}

interface AddUserDoneAction {
  type: typeof ADD_USER_SUCCESS;
  payload: any;
}

interface AddUserFailAction {
  type: typeof ADD_USER_FAILURE;
  error: string;
}

interface StartUpdateMemberAction {
  type: typeof UPDATE_MEMBER_REQUEST;
}

interface UpdateMemberDoneAction {
  type: typeof UPDATE_MEMBER_SUCCESS;
  payload: any;
}

interface UpdateMemberFailAction {
  type: typeof UPDATE_MEMBER_FAILURE;
  error: string;
}

interface StartSetUserRoleAction {
  type: typeof SET_USER_ROLE_REQUEST;
}

interface SetUserRoleDoneAction {
  type: typeof SET_USER_ROLE_SUCCESS;
  payload: string;
  newRoles: Array<UserRolesTypesUnMapped>;
}

interface SetUserRoleFailAction {
  type: typeof SET_USER_ROLE_FAILURE;
  error: string;
}
interface StartDeleteUserAction {
  type: typeof DELETE_USER_REQUEST;
}

interface DeleteUserDoneAction {
  type: typeof DELETE_USER_SUCCESS;
  payload: string;
}

interface DeleteUserFailAction {
  type: typeof DELETE_USER_FAILED;
  error: string;
}

interface SetUsersSuccessAction {
  type: typeof SET_INTERNAL_USERS;
  payload: User[];
}

interface AddUsersSuccessAction {
  type: typeof ADD_INTERNAL_USERS;
  payload: User[];
}

interface ModifyUsersSuccessAction {
  type: typeof MODIFY_INTERNAL_USERS;
  payload: User[];
}

interface RemoveUsersSuccessAction {
  type: typeof REMOVE_INTERNAL_USERS;
  payload: User[];
}

interface StartInviteUserAction {
  type: typeof INVITE_USER_REQUEST;
}

interface InviteUserDoneAction {
  type: typeof INVITE_USER_SUCCESS;
  invitedUserId: string;
  invitedByUserId: string;
}

interface InviteUserFailAction {
  type: typeof INVITE_USER_FAILURE;
  error: string;
}

export type UsersActionTypes =
  | StartLoadUsersAction
  | LoadUsersDoneAction
  | LoadUsersFailAction
  | StartAddUserAction
  | AddUserDoneAction
  | AddUserFailAction
  | StartUpdateMemberAction
  | UpdateMemberDoneAction
  | UpdateMemberFailAction
  | StartSetUserRoleAction
  | SetUserRoleDoneAction
  | SetUserRoleFailAction
  | StartDeleteUserAction
  | DeleteUserDoneAction
  | DeleteUserFailAction
  | SetUsersSuccessAction
  | AddUsersSuccessAction
  | RemoveUsersSuccessAction
  | ModifyUsersSuccessAction
  | StartInviteUserAction
  | InviteUserDoneAction
  | InviteUserFailAction
  | UpdateUsersDoneAction;
