import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

type PlusIconProps = SvgIconProps;

export const PlusIcon = (props: PlusIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M19 10H1M10 1V19V1Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
