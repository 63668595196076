export const getClientIdFromOwnerId = (ownerId: string) => {
  if (ownerId && ownerId.length) {
    const ownerIdStaged = ownerId.split('/');
    if (ownerIdStaged.length >= 2) {
      return ownerIdStaged.at(2);
    }
  }

  return '';
};

export const getCompanyIdFromOwnerId = (ownerId: string) => {
  if (ownerId && ownerId.length) {
    const ownerIdStaged = ownerId.split('/');
    if (ownerIdStaged.length >= 1) {
      return ownerIdStaged.at(1);
    }
  }

  return '';
};

export const getFileNameFromFileKey = (
  fileKey: string,
  options?: { withExtension: boolean },
) => {
  if (fileKey && fileKey.length) {
    const fileKeyStaged = fileKey.split('/');
    if (fileKeyStaged.length >= 2) {
      const fileName =
        options && options.withExtension
          ? fileKeyStaged[fileKeyStaged.length - 1]
          : fileKeyStaged[fileKeyStaged.length - 1].replace(/\.[^/.]+$/, '');
      return fileName;
    }
  }

  return '';
};

export const getExtensionFromFileKey = (fileKey: string) => {
  if (fileKey && fileKey.length) {
    const reg = /(?:\.([^.]+))?$/;

    const fileExec = reg.exec(fileKey);
    const fileExtension =
      fileExec !== null && fileExec.at(0) ? fileExec.at(0) : '';
    return fileExtension ?? '';
  }

  return '';
};

/**
 * Checks if the extensions of fileName and fileKey match, and removes the extension from fileName if they do.
 * @param fileName The original file name
 * @param fileKey The file key path
 * @returns The fileName without extension if extensions match, otherwise the original fileName
 */
export const removeExtensionIfMatch = (
  fileName: string,
  fileKey: string,
): string => {
  // Extract extensions from both strings
  const fileNameExt = fileName.split('.').pop()?.toLowerCase();
  const fileKeyExt = fileKey.split('.').pop()?.toLowerCase();

  // Check if both have extensions and if they match
  if (fileNameExt && fileKeyExt && fileNameExt === fileKeyExt) {
    // Remove the extension from fileName
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      return fileName.substring(0, lastDotIndex);
    }
  }

  // Return original fileName if extensions don't match or if there's no extension
  return fileName;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const getHumanReadableFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;
  let tempBytes = bytes;
  if (Math.abs(tempBytes) < thresh) {
    return `${tempBytes} B`;
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    tempBytes /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(tempBytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${tempBytes.toFixed(dp)} ${units[u]}`;
};
