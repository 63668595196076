import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ExternalLinkIcon = (props?: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.3333 10.889V16.2223C15.3333 16.6938 15.146 17.146 14.8126 17.4794C14.4792 17.8128 14.0271 18.0001 13.5556 18.0001H3.77778C3.30628 18.0001 2.8541 17.8128 2.5207 17.4794C2.1873 17.146 2 16.6938 2 16.2223V6.44453C2 5.97303 2.1873 5.52085 2.5207 5.18745C2.8541 4.85405 3.30628 4.66675 3.77778 4.66675H9.11111"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6667 2H18.0001V7.33333"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22217 11.7778L17.9999 2"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
