import { Auth, Hub } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import React from 'react';
import { AppViewMode, REGISTER_PAGE } from 'src/constants';
import {
  SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
  SIGNED_IN_WITH_GOOGLE_USER_KEY,
} from 'src/constants/authConsts';
import { AuthErrors } from 'src/constants/errorConsts/errorCodesConsts';
import { PortalConfigContext } from 'src/context';
import history from 'src/history';
import { useAppDispatch } from 'src/hooks/useStore';
import {
  setAuthError,
  setProcessingCallback,
  setSignedIn,
} from 'src/store/auth/slice';
import { alertSnackbar } from 'src/store/ui/actions';
import { AlertVariant } from 'src/store/ui/types';
import { isEmptyString } from 'src/utils/StringUtils';
import { AlertSnackbar } from '../AlertSnackbar/AlertSnackbar';

// Amplify Hub events we are responding to
// full list available here
// https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
enum CognitoHubEvents {
  // Cognito Hosted UI OAuth url parsing initiated
  ParsingCallbackUrl = 'parsingCallbackUrl',

  // user signed in
  SignIn = 'signIn',
  // Cognito Hosted UI sign in successful
  HostedUiSignIn = 'cognitoHostedUI',

  // user sign in failed
  SignInFailure = 'signIn_failure',
  // Cognito Hosted UI sign in failed
  HostedUiSignInFailure = 'cognitoHostedUI_failure',

  // custom oauth state
  // we use to distinguish b/w the origin of 'sign in with google'
  // it can be triggered from login or the signup page
  CustomOAuthState = 'customOAuthState',
}

// ConfigureAuth configures amplify using portal config
// This also handles the hub events and sets the correct state in the store
export const ConfigureAuth = () => {
  const portalConfig = React.useContext(PortalConfigContext);
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : undefined,
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const errorSnackbar = React.useCallback((key: string) => {
    const errorMessage = `${
      AuthErrors.userRecordNotFound
    } ${window.sessionStorage.getItem(
      SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
    )}.`;

    const { features } = portalConfig;

    const { signupDisabled } = features;

    return (
      <div key={key}>
        <AlertSnackbar
          variant={AlertVariant.ERROR}
          onClose={() => closeSnackbar(key)}
          alertMessage={errorMessage}
          onAction={() => history.push(REGISTER_PAGE)}
          actionLabel={
            signupDisabled ? undefined : AuthErrors.userRecordNotFoundAction
          }
        />
      </div>
    );
  }, []);

  React.useEffect(() => {
    const isAdminLogin = window.location.pathname === '/admin';

    const authConfig = {
      ...portalConfig.AWS.Auth,
      authenticationFlowType: isAdminLogin ? 'CUSTOM_AUTH' : 'USER_SRP_AUTH',
    };

    // when the app-error param is set and we are on the login page
    // then this refers to a error from signing in with google
    // the 'error' query param is used by amplify, so we use a different one
    //
    // this should contain an error code from the flow
    const error = params.get('app-error');
    if (window.location.pathname === '/login' && params.has('app-error')) {
      switch (error) {
        case 'user_not_found': {
          // if the error was a 'user_not_found' then the failed email should be present in the
          // session store
          const errorMessage =
            // format the error message with the email
            `${AuthErrors.userRecordNotFound} ${window.sessionStorage.getItem(
              SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
            )}.`;
          dispatch(setAuthError(errorMessage));

          // for the clients we show a snackbar for the error
          if (portalConfig.viewMode === AppViewMode.CLIENT) {
            enqueueSnackbar(errorMessage, {
              persist: false,
              content: errorSnackbar,
            });
          }

          break;
        }

        case 'invite_login_mismatch': {
          dispatch(setAuthError(AuthErrors.inviteAndLoginMismatch));

          // for the clients we show a snackbar for the error
          if (portalConfig.viewMode === AppViewMode.CLIENT) {
            dispatch(
              alertSnackbar({
                errorMessage: AuthErrors.inviteAndLoginMismatch,
              }),
            );
          }
          break;
        }

        case 'login_expired':
          dispatch(
            alertSnackbar({
              errorMessage: AuthErrors.loginExpired,
            }),
          );

          break;

        default:
          console.error('an error occurred', error);
      }

      // reset the error key only if the we are not rendering a error
      if (!error || isEmptyString(error)) {
        // reset session store
        window.sessionStorage.removeItem(
          SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
        );
      }

      // remove app-error query param, so that the user can refresh and not see the error message again
      // the other params should exist as it is
      const pageParams = new URLSearchParams(window.location.search);
      pageParams.delete('app-error');

      // this is used so a page refresh is not done by the browser
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${pageParams}`,
      );
    }

    if (authConfig.oauth) {
      // the redirect sign in depends on the page you are in
      // the user is redirected back to the login / signup page based on the
      // page we trigger login from
      if (portalConfig.viewMode === AppViewMode.INTERNAL) {
        authConfig.oauth.redirectSignIn =
          window.location.origin +
          (window.location.pathname === '/signup' ? '/signup' : '/login');
      }
    }

    // setup the amplify hub listener
    // based on the event this will set the state of authentication
    // in the auth key of the store
    // components can use that key to respond and show appropriate ui
    const hubListener = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        // this is called when amplify starts processing the url
        // It will be called each time when `Auth.configure` is called
        case CognitoHubEvents.ParsingCallbackUrl: {
          // we show a loading indicator only when we have received a
          // authorization code from google or the action param is confirm-login
          if (
            (params.get('code') || params.get('action') === 'confirm-login') &&
            // after hosted ui redirection, we land on signup form
            // at this point we dont want to keep show the loading indicator
            // because we need to render the form to proceed to next
            // registration form.
            params.get('action') !== 'signup'
          ) {
            dispatch(setProcessingCallback(true));
          }

          break;
        }

        // custom oauth state is used to store data throught the oauth flow.
        // In our implementation, this is being used to store the next url we want to
        // redirect to after the signing in process is complete.
        // Note that, this event is triggered after the sign in event.
        //
        case CognitoHubEvents.CustomOAuthState:
          // For example, when the user signs up through google then the
          // customState is set to be signup?state=create-account
          // This is available in payload.data, so we grab the current host and
          // set the path to be as set in the custom state
          dispatch(setProcessingCallback(true));
          // for clients this redirect is handled by the unauth-router
          if (portalConfig.viewMode === AppViewMode.INTERNAL) {
            window.location.href = `${window.location.origin}/${payload.data}`;
          }
          break;

        case CognitoHubEvents.HostedUiSignIn:
          // track the signed in user with google
          // since the current signed in user may change by switching workspaces
          // this represents the user that was logged in via the hosted ui
          window.localStorage.setItem(
            SIGNED_IN_WITH_GOOGLE_USER_KEY,
            payload.data.username,
          );
          break;

        case CognitoHubEvents.SignIn:
          dispatch(setSignedIn(true));
          break;

        case CognitoHubEvents.SignInFailure:
        case CognitoHubEvents.HostedUiSignInFailure: {
          // amplify throws an error which is uri encoded
          // js does not decode the + sign to space, so we do it manually
          console.error(payload.data.message);

          // the error message from amplify is uri encoded
          // with spaces replaced with + signs
          const errMessage = decodeURIComponent(
            payload.data.message,
          ).replaceAll('+', ' ');

          let formattedErr = errMessage;

          // if the error is from pre-signup trigger
          // extract the message from the thrown error
          if (errMessage.includes('PreSignUp')) {
            formattedErr = errMessage.split(' ').slice(4).join(' ');
          } else if (errMessage.includes('attributes required: [familyName]')) {
            formattedErr = AuthErrors.noLastName;
          }

          dispatch(setAuthError(formattedErr));
          break;
        }

        default:
      }
    });

    Auth.configure(authConfig);

    return () => {
      // unsubscribe from hub events as cleanup
      // Hub provides a way to stop listening for messages with calling the result of the .listen function.
      hubListener();
    };
  }, []);

  return null;
};
