import { CallApiWithNotification } from 'src/clients/ApiService';
import EntityClient from 'src/clients/EntityClient';
import ExtensionClient from 'src/clients/ExtensionClient';
import { ExtensionActionTypes, ExtensionItem } from 'src/store/dashboard/types';
import {
  AppThunkAction,
  createItemErrorAction,
  creatingItemAction,
  getItemsErrorAction,
  setSelectedItemAction,
  startGetItemsAction,
} from 'src/store/reduxTypes';
import ApiUtils from 'src/utils/ApiUtils';
import { ensureError } from 'src/utils/Errors';
import { findExistingDashboardItem } from 'src/utils/ExtensionUtils';

export const setExtensionItemsAction = (items: ExtensionItem[]) => ({
  type: ExtensionActionTypes.SET_EXTENSION_ITEMS,
  items,
});

export const loadExtensionItemsAction =
  (): AppThunkAction => async (dispatch, getState) => {
    dispatch(startGetItemsAction());

    try {
      const res: ExtensionItem[] = await EntityClient.GetItems('dashboard');
      const {
        user: { isClient },
      } = getState();
      const connectedItems = res.filter(
        (item) => item.fields.status === 'connected',
      );
      dispatch(setExtensionItemsAction(isClient ? connectedItems : res));
    } catch (err) {
      const { message } = ensureError(err);
      if (message) dispatch(getItemsErrorAction(message));
    }
  };

export const UpdateExtensionItemsAction = (items: ExtensionItem[]) => ({
  type: ExtensionActionTypes.UPDATE_EXTENSION_ITEMS,
  items,
});

export const DeleteExtensionItemsAction = (
  itemIds: string[],
  resetSelectedChannel: boolean,
) => ({
  type: ExtensionActionTypes.DELETE_EXTENSION_ITEMS,
  itemIds,
  resetSelectedChannel,
});

/**
 * Create a dashboard item that holds the dashboard embed within it
 * and a list of client members. If there is a channel with these members already
 * then do not create new dashboard item and select existing one
 * @param item the dashboard to create
 */
export const createDashboardAction =
  (
    item: Partial<ExtensionItem>,
  ): AppThunkAction | Promise<string | undefined> =>
  async (dispatch, getState) => {
    const { dashboard } = getState();
    const existingChannel = findExistingDashboardItem(dashboard.items, item);

    if (existingChannel) {
      dispatch(setSelectedItemAction(existingChannel.id));
      return;
    }

    dispatch(creatingItemAction());

    try {
      const res = await EntityClient.CreateItems({
        entityType: 'dashboard',
        entityItems: [item],
      });

      dispatch(UpdateExtensionItemsAction(res.createdItems));
      // when a dashboard is created, it should be selected.
      dispatch(setSelectedItemAction(res.createdItems[0].id));
      return res.createdItems[0].id;
    } catch (err) {
      const { message } = ensureError(err);
      if (message) dispatch(createItemErrorAction(message));
    }
  };

/**
 * When dashboard embed is saved. It can be saved either to an exiting dashboard item
 * or a new dashboard item
 * @param item dashboard item from form modal for adding embed
 */
export const saveDashboardEmbedAction =
  (
    item: Partial<ExtensionItem>,
  ): AppThunkAction | Promise<string | undefined> =>
  async (dispatch) => {
    const saveDashboardEmbedApiParams = {
      executeFunction: EntityClient.CreateItems,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: { entityType: 'dashboard', entityItems: [item] },
      successMessage: `Embed updated.`,
      errorMessage: `Unable to save embed, please contact support for help.`,
      dispatch,
    };

    if (item.id) {
      saveDashboardEmbedApiParams.executeFunction = EntityClient.UpdateItems;
      saveDashboardEmbedApiParams.checkFunction =
        ApiUtils.IsBatchUpdateResultSuccessful;
    }

    const result = await CallApiWithNotification({
      ...saveDashboardEmbedApiParams,
    });
    if (result.status) {
      if (item.id) {
        dispatch(UpdateExtensionItemsAction(result.data.updatedItems));
      } else {
        dispatch(UpdateExtensionItemsAction(result.data.createdItems));
      }
      if (result.data.succeededIds[0]) {
        // set selected channel to successful item
        dispatch(setSelectedItemAction(result.data.succeededIds[0]));
        return result.data.succeededIds[0];
      }
    } else {
      console.error('Failed to save embed');
    }
  };

/**
 * When dashboard embed is saved. It can be saved either to an exiting dashboard item
 * or a new dashboard item
 * @param item dashboard item from form modal for adding embed
 */
export const deleteDashboardEmbedAction =
  (item: Partial<ExtensionItem>): AppThunkAction =>
  async (dispatch) => {
    const deleteDashboardEmbedApiParams = {
      executeFunction: EntityClient.DeleteItems,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: { entityType: 'dashboard', entityItems: [item] },
      successMessage: `Embed deleted.`,
      errorMessage: `Unable to delete dashboard, please contact support for help.`,
      dispatch,
    };

    const result = await CallApiWithNotification({
      ...deleteDashboardEmbedApiParams,
    });

    if (result.status) {
      console.info('Failed to delete embed');
    } else {
      console.error('Failed to delete embed');
    }
  };

/**
 * Deletes extension channel
 * @param channelId channel id which needs to be deleted
 */
export const deleteLocalExtensionChannel =
  (channelId: string): AppThunkAction =>
  async (dispatch) => {
    console.info('Deleting file channel with id', channelId);

    const result = await CallApiWithNotification({
      executeFunction: ExtensionClient.deleteExtension,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: channelId,
      successMessage: `Channel deleted`,
      errorMessage: `There was a problem deleting this channel.`,
      dispatch,
    });

    if (result.status && result.data.succeededIds.length > 0) {
      dispatch(DeleteExtensionItemsAction([result.data.succeededIds[0]], true));
    } else {
      console.error('Unsuccessful file channel create result', result);
    }
  };

/**
 * Action to update extension channel members
 * @param extension
 * @param updatedMemberIds
 * @returns
 */
export const updateExtensionChannellMembers =
  (extension: ExtensionItem, updatedMemberIds: string[]): AppThunkAction =>
  async (dispatch) => {
    const memberRecord: Record<string, boolean> = {};
    updatedMemberIds.forEach((id) => {
      memberRecord[id] = true;
    });

    const updatedChannel: ExtensionItem = {
      ...extension,
      fields: {
        ...extension.fields,
        memberIds: memberRecord,
      },
    };

    const result = await CallApiWithNotification({
      executeFunction: EntityClient.UpdateItems,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: { entityType: 'dashboard', entityItems: [updatedChannel] },
      successMessage: `Group members updated`,
      errorMessage: `Group members could not be updated.`,
      dispatch,
    });

    if (result.status) {
      dispatch(UpdateExtensionItemsAction(result.data.updatedItems));
    }
  };
