import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CellularIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M18.4375 3.75H16.5625C16.3899 3.75 16.25 3.88991 16.25 4.0625V15.9375C16.25 16.1101 16.3899 16.25 16.5625 16.25H18.4375C18.6101 16.25 18.75 16.1101 18.75 15.9375V4.0625C18.75 3.88991 18.6101 3.75 18.4375 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M13.4375 6.875H11.5625C11.3899 6.875 11.25 7.01491 11.25 7.1875V15.9375C11.25 16.1101 11.3899 16.25 11.5625 16.25H13.4375C13.6101 16.25 13.75 16.1101 13.75 15.9375V7.1875C13.75 7.01491 13.6101 6.875 13.4375 6.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M8.4375 9.375H6.5625C6.38991 9.375 6.25 9.51491 6.25 9.6875V15.9375C6.25 16.1101 6.38991 16.25 6.5625 16.25H8.4375C8.61009 16.25 8.75 16.1101 8.75 15.9375V9.6875C8.75 9.51491 8.61009 9.375 8.4375 9.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M3.4375 11.875H1.5625C1.38991 11.875 1.25 12.0149 1.25 12.1875V15.9375C1.25 16.1101 1.38991 16.25 1.5625 16.25H3.4375C3.61009 16.25 3.75 16.1101 3.75 15.9375V12.1875C3.75 12.0149 3.61009 11.875 3.4375 11.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
