import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FootstepsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.81225 9.64236C8.15639 11.9322 7.52592 13.1842 5.74545 13.4474C3.76693 13.74 2.9517 12.4146 2.60756 10.1248C2.14467 7.04354 3.23256 5.12713 4.78373 5.00369C6.19545 4.89158 7.46771 7.35291 7.81225 9.64236Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M8.73618 15.9973C8.85845 17.2973 8.15571 18.5106 7.09556 18.7184C6.02525 18.9278 4.8022 18.079 4.55298 16.7969C4.30376 15.5149 5.24634 15.4469 6.40142 15.2262C7.5565 15.0055 8.58853 14.4293 8.73618 15.9973Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M12.1879 5.89182C11.8437 8.18166 12.4742 9.43361 14.2543 9.69689C16.2332 9.98947 17.048 8.66408 17.3922 6.37424C17.8554 3.2926 16.7672 1.36564 15.2164 1.25314C13.7945 1.15002 12.5324 3.60197 12.1879 5.89182Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M11.2638 12.2473C11.1415 13.5469 11.8443 14.7606 12.9044 14.9684C13.9747 15.1778 15.1978 14.329 15.447 13.0469C15.6962 11.7649 14.7536 11.6969 13.5986 11.4758C12.4435 11.2547 11.4114 10.679 11.2638 12.2473Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
