import { Theme } from '@material-ui/core';

export default {};

export const overrideCheckbox = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiCheckbox) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiCheckbox.root = {
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
      '&.Mui-checked&:hover': {
        backgroundColor: 'transparent',
      },
    };
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiCheckbox.colorPrimary = {
      color: theme.palette.primary.main,
    };
  }
};
