import { Document } from '@contentful/rich-text-types';
import { appAPI, ApiTags, ApiMethods } from '.';
interface ContentfulAppsResponse {
  items: Array<any>;
  includes: {
    Asset: Array<any>;
    Entry: Array<any>;
  };
}

export enum ContentfulAppType {
  Internal = 'Internal',
  Client = 'Client',
}

export enum ContentfulAppCreator {
  Copilot = 'Copilot',
  Partner = 'Partner',
}

export enum AppType {
  EMBED = 'Embed',
  APP = 'App',
}

export interface ContentfulRating {
  name: string;
  customerName: string;
  location: string;
  yearsWithApp: string;
  rate: number;
  date: string;
  comment: string;
}

export interface ContentfulApp {
  name: string; // name of the app
  description: string; // description of the app
  iconUrl: string; // url of the icon
  appsType: ContentfulAppType; // will this app be displayed internal or client side
  appCreator: ContentfulAppCreator; // this is used to display "Made by Copilot" badge
  learnMoreLink: string; // url to the setup instructions page
  copilotAppId: string; // core module id
  isFeatured: boolean;
  isPlatformApp: boolean;
  slug: string;
  releaseSegment?: 'Launchpad' | 'Dogfood';
  presetName?: string;
  guideSlug: string;
  pricing: string;
  appType: AppType;
  reviewsCollection: Array<ContentfulRating>;
  guideContent: {
    content: Document['content'];
  };
  aboutAppInfo: {
    builtBy: string;
    launchDate: string;
    pricing: string;
    visibility: string;
    appType: string;
    website: string;
    requirements?: string;
  };
  appThumbnails: string[];
}

export const contentfulApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getContentfulApps: build.query<ContentfulApp[], void>({
      query: () => ({
        path: '/data/contentful',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            select: 'fields',
            contentType: 'partnerApps',
          },
        },
      }),
      providesTags: [ApiTags.contentfulApps],
      // formatting the api response
      transformResponse(baseQueryReturnValue: ContentfulAppsResponse) {
        if (!baseQueryReturnValue) return [];

        const allApps = baseQueryReturnValue.items; // all apps added in Contentful CMS
        const allAssets = baseQueryReturnValue.includes.Asset; // all asset files
        const allEntries = baseQueryReturnValue.includes.Entry; // entries contains all the referenced info related to the app
        return allApps.map((app) => {
          const {
            name,
            appCreator,
            appsType,
            appType,
            description,
            isFeatured,
            setupInstructionsLink,
            copilotAppId,
            icon,
            isPlatformApp,
            releaseSegment,
            presetName,
            guideArticle,
            slug,
            builtBy,
            launchDate,
            pricing,
            reviews,
            imageList,
            website,
            requirements,
          } = app.fields;

          const appThumbnails = (imageList || []).map(
            (image: { sys: { id: string } }) => {
              const thumbnailInfo = allAssets.find(
                (asset) => asset.sys.id === image?.sys.id,
              );
              return thumbnailInfo?.fields?.file?.url || '';
            },
          );

          // get icon information from all assets array
          const iconInfo = allAssets.find(
            (asset) => asset.sys.id === icon?.sys.id,
          );
          // url to access the app icon
          const iconUrl = iconInfo?.fields?.file?.url || '';

          const { slug: guideSlug, content: guideContent } =
            allEntries.find((entry) => entry.sys.id === guideArticle?.sys?.id)
              ?.fields || {};

          const reviewsCollection = (reviews || []).map(
            (review: { sys: { id: string } }) => {
              return allEntries.find(
                (entry) => entry.sys.id === review?.sys?.id,
              )?.fields;
            },
          );

          return {
            name,
            description,
            iconUrl,
            appsType,
            appCreator,
            learnMoreLink: setupInstructionsLink,
            copilotAppId,
            isFeatured,
            isPlatformApp,
            releaseSegment,
            presetName,
            guideSlug,
            guideContent,
            slug,
            reviewsCollection,
            pricing,
            appType,
            appThumbnails,
            aboutAppInfo: {
              builtBy,
              launchDate,
              pricing,
              appType,
              visibility: appsType,
              website,
              requirements,
            },
          };
        });
      },
    }),
  }),
});

export const { useGetContentfulAppsQuery } = contentfulApi;
