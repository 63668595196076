import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { FormControl, Theme, makeStyles } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { BlackHeadings, GraySmall } from 'src/theme/colors';
import {
  MultiCategorySelect,
  MultiCategorySelectProps,
} from 'src/legacy/components/Select/MultiCategorySelect';
import { FormBodyAPI } from 'src/constants';
import { FieldType } from 'src/services/api';
import { ResizableTextField } from 'src/legacy/components/TextField';
import { DateTextField } from '../legacy/components/UI/Date/useAddDateForm';
import { ensureUnreachable } from 'src/utils/common_utils';
import { VariableContractField } from 'src/entities/Contract';

interface useShareContractParams {
  variableInputs: VariableContractField[];
  selectedTemplateRef: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  copyText: {
    wordBreak: 'break-all',
    color: BlackHeadings,
  },
  iconButton: {
    padding: 0,
  },
  copyIcon: {
    fontSize: 12,
  },
  inputs: {
    marginTop: theme.spacing(2),
  },
  muted: {
    color: GraySmall,
  },
  allClientSelector: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

export const useShareContract = ({
  variableInputs,
}: useShareContractParams) => {
  // Creating validation schema for all the variable inputs
  // We want all the variable inputs to be required
  const generateValidationSchema = useMemo(() => {
    const yupSchema: Record<
      string,
      Yup.StringSchema<string> | Yup.DateSchema<Date>
    > = {};
    if (variableInputs?.length > 0) {
      variableInputs.forEach((input) => {
        if (input.id)
          yupSchema[input.label] =
            input.type === FieldType.Date
              ? Yup.date().required('Field is required')
              : Yup.string()
                  .required('Field is required')
                  .max(1000, "Field can't be more than 1000 characters");
      });
    }
    return yupSchema;
  }, [variableInputs]);

  const validationScheme = Yup.object().shape({
    recipientIds: Yup.array()
      .of(Yup.string())
      .required('Please select at least one client'),
    fields: Yup.object().shape(generateValidationSchema),
  });

  const FormRenderer = useCallback(
    ({
      values,
      errors,
      handleBlur,
      handleChange,
      setFieldValue,
      touched,
    }: FormBodyAPI) => {
      const classes = useStyles();
      const hasVariableInputs = variableInputs?.length > 0;

      const handleRecipientChange: MultiCategorySelectProps['onChange'] = (
        _,
        vals,
      ) => {
        if (!Array.isArray(vals)) {
          return;
        }

        const clientsIds = vals.map((val) => val.id);
        setFieldValue('recipientIds', clientsIds);
      };

      const renderVariableInputs = () =>
        variableInputs.map((input) => {
          switch (input.type) {
            case FieldType.Date:
              return (
                <FormControl fullWidth className={classes.inputs}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      label={input.label}
                      TextFieldComponent={DateTextField}
                      disableToolbar
                      name={`fields.${input.label}`}
                      margin="none"
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                      value={values.fields[input.label]}
                      onChange={(val) => {
                        setFieldValue(
                          `fields.${input.label}`,
                          moment(val).format('MM/DD/YYYY'),
                        );
                      }}
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              );
            case FieldType.Text:
              return (
                <FormControl fullWidth className={classes.inputs}>
                  <ResizableTextField
                    variant="outlined"
                    type="text"
                    label={input.label}
                    name={`fields.${input.label}`}
                    value={values.fields[input.label]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      touched?.fields &&
                      touched.fields[input.label] &&
                      errors?.fields &&
                      errors.fields[input.label]
                    }
                    helperText={
                      touched?.fields &&
                      touched.fields[input.label] &&
                      errors?.fields &&
                      errors.fields[input.label]
                    }
                  />
                </FormControl>
              );
            case FieldType.Signature:
            case FieldType.Initials:
              return null;
            default:
              return ensureUnreachable(input.type);
          }
        });

      return (
        <div>
          <FormControl fullWidth className={classes.inputs}>
            <MultiCategorySelect
              multiple
              includeInternalUsers={false}
              includeClientUsers
              optionToExclude={[]}
              filterResultsLimit={0}
              values={values.recipientIds || []}
              id="clients-select"
              label="Select client(s)"
              onChange={handleRecipientChange}
              placeholder="Type to find clients"
              showPlaceholder
              showClientsInOtherCompanies
              helperText={errors.recipientIds}
              error={Boolean(errors.recipientIds)}
              autoFocus={!hasVariableInputs}
            />
          </FormControl>

          {hasVariableInputs && <div>{renderVariableInputs()}</div>}
        </div>
      );
    },
    [variableInputs],
  );
  return { validationScheme, FormRenderer };
};
