import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LaptopIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.8695 3.75H3.13047C2.43709 3.75 1.875 4.31209 1.875 5.00547V14.3695C1.875 15.0629 2.43709 15.625 3.13047 15.625H16.8695C17.5629 15.625 18.125 15.0629 18.125 14.3695V5.00547C18.125 4.31209 17.5629 3.75 16.8695 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M0.625 16.25H19.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
