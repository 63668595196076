import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const JpgIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6655 12.0497V14.5639C6.66372 14.8782 6.52523 15.0433 6.2731 15.0433C6.03162 15.0433 5.88425 14.8888 5.87537 14.6065H5.00002C4.99647 15.3896 5.54334 15.7358 6.22338 15.7358C6.99398 15.7358 7.5302 15.2724 7.53198 14.5639V12.0497H6.6655Z"
      fill="#212B36"
    />
    <path
      d="M8.08374 15.6861H8.96264V14.5568H9.55923C10.392 14.5568 10.9122 14.0632 10.9122 13.3086C10.9122 12.5611 10.4026 12.0497 9.58587 12.0497H8.08374V15.6861ZM8.96264 13.8643V12.7582H9.39588C9.79006 12.7582 10.0013 12.9712 10.0013 13.3086C10.0013 13.6442 9.79006 13.8643 9.39588 13.8643H8.96264Z"
      fill="#212B36"
    />
    <path
      d="M13.7318 13.2376H14.6214C14.5326 12.5131 13.8863 12 13.0447 12C12.0734 12 11.3064 12.6836 11.3064 13.8732C11.3064 15.0238 12.0131 15.7358 13.0553 15.7358C13.9911 15.7358 14.6604 15.1552 14.6604 14.1786V13.6974H13.0926V14.3384H13.8135C13.8046 14.7219 13.5383 14.9652 13.0624 14.9652C12.5173 14.9652 12.2031 14.5621 12.2031 13.8626C12.2031 13.1683 12.5351 12.7706 13.0624 12.7706C13.414 12.7706 13.6537 12.9428 13.7318 13.2376Z"
      fill="#212B36"
    />
  </SvgIcon>
);
