import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NutritionIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.75 5.0001C12.4898 4.88721 11.25 5.6251 10 5.6251C8.75 5.6251 7.50977 4.88291 6.25 5.0001C3.75 5.23447 2.5 7.5001 2.5 11.2501C2.5 14.3751 5 18.7501 6.84375 18.7501C8.6875 18.7501 8.87266 17.8126 10 17.8126C11.1273 17.8126 11.3121 18.7501 13.1562 18.7501C15.0004 18.7501 17.5 14.3751 17.5 11.2501C17.5 7.5001 16.3672 5.23447 13.75 5.0001Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M12.6528 3.24768C11.8325 4.06799 10.8692 4.30237 10.3551 4.37229C10.3081 4.37906 10.2602 4.37504 10.215 4.36052C10.1698 4.346 10.1285 4.32137 10.0942 4.28849C10.0599 4.25562 10.0336 4.21537 10.0172 4.1708C10.0009 4.12623 9.99484 4.07851 9.99966 4.03127C10.0924 3.15258 10.4874 2.33338 11.1172 1.7137C11.9766 0.85432 12.9141 0.662914 13.411 0.625805C13.4574 0.621572 13.5041 0.627762 13.5478 0.643922C13.5915 0.660082 13.631 0.685803 13.6635 0.719203C13.6959 0.752604 13.7205 0.792839 13.7354 0.836966C13.7503 0.881093 13.7552 0.927996 13.7497 0.974242C13.6516 1.83427 13.2649 2.63567 12.6528 3.24768Z"
      fill="currentColor"
    />
    <path
      d="M8.4375 13.75C8.95527 13.75 9.375 12.9105 9.375 11.875C9.375 10.8395 8.95527 10 8.4375 10C7.91973 10 7.5 10.8395 7.5 11.875C7.5 12.9105 7.91973 13.75 8.4375 13.75Z"
      fill="currentColor"
    />
    <path
      d="M11.5625 13.75C12.0803 13.75 12.5 12.9105 12.5 11.875C12.5 10.8395 12.0803 10 11.5625 10C11.0447 10 10.625 10.8395 10.625 11.875C10.625 12.9105 11.0447 13.75 11.5625 13.75Z"
      fill="currentColor"
    />
  </SvgIcon>
);
