import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PulseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.875 12.5H4.375L6.875 2.5L9.375 17.5L11.875 8.75L13.125 12.5H15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 13.75C17.5654 13.75 18.125 13.1904 18.125 12.5C18.125 11.8096 17.5654 11.25 16.875 11.25C16.1846 11.25 15.625 11.8096 15.625 12.5C15.625 13.1904 16.1846 13.75 16.875 13.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
