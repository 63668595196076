import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GitCompareIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M11.875 6.25L9.375 3.75L11.875 1.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.08594 13.75L10.5859 16.25L8.08594 18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 5.625C5.41053 5.625 6.25 4.78553 6.25 3.75C6.25 2.71447 5.41053 1.875 4.375 1.875C3.33947 1.875 2.5 2.71447 2.5 3.75C2.5 4.78553 3.33947 5.625 4.375 5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 18.125C16.6605 18.125 17.5 17.2855 17.5 16.25C17.5 15.2145 16.6605 14.375 15.625 14.375C14.5895 14.375 13.75 15.2145 13.75 16.25C13.75 17.2855 14.5895 18.125 15.625 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.75H13.2812C13.9029 3.75 14.499 3.99693 14.9385 4.43647C15.3781 4.87601 15.625 5.47215 15.625 6.09375V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.96094 16.25H6.67969C6.05809 16.25 5.46194 16.0031 5.02241 15.5635C4.58287 15.124 4.33594 14.5279 4.33594 13.9062V5.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
