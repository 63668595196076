import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HammerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.8366 9.64825C10.7936 9.56999 10.74 9.49807 10.6772 9.43457L9.96082 8.72793C9.88724 8.65302 9.80034 8.59248 9.70457 8.54942C9.55387 8.4822 9.38635 8.46216 9.22405 8.49193C9.06174 8.5217 8.91224 8.59989 8.7952 8.71622C8.54676 8.96075 8.09207 9.40684 7.27176 10.2174C5.70301 11.7693 2.79012 14.3705 1.47293 15.4799C1.40657 15.5354 1.35246 15.6041 1.31403 15.6817C1.2756 15.7592 1.25369 15.8439 1.24969 15.9303C1.24569 16.0167 1.25968 16.1031 1.29078 16.1838C1.32188 16.2646 1.36941 16.338 1.43035 16.3994L2.95379 17.9397C3.01514 18.0012 3.08856 18.0494 3.16942 18.0812C3.25029 18.113 3.33686 18.1277 3.4237 18.1245C3.51053 18.1212 3.59576 18.1 3.67401 18.0622C3.75226 18.0244 3.82185 17.9709 3.8784 17.9049C5.02059 16.5623 7.64012 13.6471 9.1909 12.1147C9.98739 11.3311 10.4339 10.8811 10.6866 10.6326C10.8119 10.5052 10.8939 10.3414 10.9208 10.1647C10.9478 9.98797 10.9183 9.80726 10.8366 9.64825V9.64825Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6891 7.85176L17.3488 6.52363C17.3284 6.5032 17.304 6.48715 17.2771 6.47647C17.2502 6.46579 17.2215 6.4607 17.1926 6.46152C17.1637 6.46104 17.1351 6.46628 17.1082 6.47694C17.0814 6.4876 17.057 6.50347 17.0363 6.52363C16.9881 6.57211 16.9294 6.60885 16.8647 6.631C16.8 6.65315 16.7311 6.66012 16.6633 6.65137C16.4883 6.63223 16.302 6.57793 16.1816 6.46152C15.9082 6.19356 16.2242 5.66621 15.9836 5.32871C15.7088 4.95938 15.4075 4.61055 15.082 4.28496C14.8063 4.01152 13.7223 2.97754 11.8965 2.23301C11.3138 1.99431 10.6902 1.87171 10.0605 1.87207C9.03125 1.87207 8.23984 2.33145 7.95117 2.59512C7.72148 2.80293 7.48242 3.13301 7.48242 3.13301C7.62047 3.08268 7.76149 3.04094 7.90469 3.00801C8.2033 2.94402 8.50972 2.9244 8.81406 2.94981C9.42188 3.0002 10.1543 3.28535 10.5473 3.59395C11.1805 4.10176 11.4527 4.78106 11.4953 5.65762C11.5266 6.30957 10.9004 7.13262 10.3062 7.80371C10.254 7.86272 10.2264 7.93959 10.2293 8.01836C10.2323 8.09714 10.2654 8.17177 10.3219 8.22676L11.1516 9.05606C11.2094 9.11314 11.2871 9.14547 11.3683 9.1462C11.4496 9.14693 11.5279 9.116 11.5867 9.05996C12.1309 8.53223 12.8012 7.94785 13.1812 7.71387C13.5613 7.47988 13.868 7.41387 14.0152 7.39785C14.3046 7.36983 14.5955 7.43293 14.8473 7.57832C14.8916 7.6034 14.9321 7.63471 14.9676 7.67129C15.2199 7.92754 15.2047 8.34629 14.948 8.59863L14.8699 8.67246C14.8496 8.69245 14.8335 8.71629 14.8225 8.74258C14.8115 8.76887 14.8058 8.79708 14.8058 8.82559C14.8058 8.85409 14.8115 8.88231 14.8225 8.9086C14.8335 8.93488 14.8496 8.95872 14.8699 8.97871L16.2102 10.3068C16.2307 10.3271 16.2552 10.343 16.282 10.3536C16.3088 10.3642 16.3376 10.3693 16.3664 10.3686C16.4246 10.3694 16.4807 10.3472 16.5227 10.3068L18.6891 8.16426C18.729 8.12204 18.7513 8.06613 18.7513 8.00801C18.7513 7.94989 18.729 7.89398 18.6891 7.85176V7.85176Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
