import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FolderIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.1875 16.875H2.8125C2.3981 16.875 2.00067 16.7104 1.70765 16.4174C1.41462 16.1243 1.25 15.7269 1.25 15.3125V4.6875C1.25 4.2731 1.41462 3.87567 1.70765 3.58265C2.00067 3.28962 2.3981 3.125 2.8125 3.125H5.77695C6.08546 3.12501 6.38707 3.21635 6.64375 3.3875L7.73125 4.1125C7.98793 4.28365 8.28954 4.37499 8.59805 4.375H17.1875C17.6019 4.375 17.9993 4.53962 18.2924 4.83265C18.5854 5.12567 18.75 5.5231 18.75 5.9375V15.3125C18.75 15.7269 18.5854 16.1243 18.2924 16.4174C17.9993 16.7104 17.6019 16.875 17.1875 16.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 7.5H18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
