import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TerminalIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 1.875H3.125C2.08947 1.875 1.25 2.71447 1.25 3.75V16.25C1.25 17.2855 2.08947 18.125 3.125 18.125H16.875C17.9105 18.125 18.75 17.2855 18.75 16.25V3.75C18.75 2.71447 17.9105 1.875 16.875 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 4.375L6.875 6.875L3.75 9.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 9.375H10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
