import React from 'react';
import { AppSessionData } from 'src/constants/dataTypes/sessionDataTypes';

export type SessionData = {
  portalIdToSession: Record<string, AppSessionData>;
  currentPortalSession: string;
};

export type RouteContextType = {
  pathname: string;
  query: Record<string, string>;
  isMobile: boolean;
  sessionData: SessionData;
  title?: string;
  description?: string;
};

export const RouteContext = React.createContext<Prettify<RouteContextType>>({
  pathname: '/',
  query: {},
  isMobile: false,
  sessionData: {
    portalIdToSession: {},
    currentPortalSession: '',
  },
  title: undefined,
  description: undefined,
});
