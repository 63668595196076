import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FormsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.5 2.7998H15.25C15.7141 2.7998 16.1592 2.98945 16.4874 3.32702C16.8156 3.66459 17 4.12243 17 4.59982V17.1999C17 17.6773 16.8156 18.1352 16.4874 18.4728C16.1592 18.8103 15.7141 19 15.25 19H4.75C4.28587 19 3.84075 18.8103 3.51256 18.4728C3.18437 18.1352 3 17.6773 3 17.1999V4.59982C3 4.12243 3.18437 3.66459 3.51256 3.32702C3.84075 2.98945 4.28587 2.7998 4.75 2.7998H6.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.625 1H7.375C6.89175 1 6.5 1.40295 6.5 1.90001V3.70003C6.5 4.19709 6.89175 4.60003 7.375 4.60003H12.625C13.1083 4.60003 13.5 4.19709 13.5 3.70003V1.90001C13.5 1.40295 13.1083 1 12.625 1Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
