import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GitPullRequestIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 18.125C6.03553 18.125 6.875 17.2855 6.875 16.25C6.875 15.2145 6.03553 14.375 5 14.375C3.96447 14.375 3.125 15.2145 3.125 16.25C3.125 17.2855 3.96447 18.125 5 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5.625V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 6.25L8.75 3.75L11.25 1.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5.625C6.03553 5.625 6.875 4.78553 6.875 3.75C6.875 2.71447 6.03553 1.875 5 1.875C3.96447 1.875 3.125 2.71447 3.125 3.75C3.125 4.78553 3.96447 5.625 5 5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 18.125C16.0355 18.125 16.875 17.2855 16.875 16.25C16.875 15.2145 16.0355 14.375 15 14.375C13.9645 14.375 13.125 15.2145 13.125 16.25C13.125 17.2855 13.9645 18.125 15 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 3.75H12.6562C13.2779 3.75 13.874 3.99693 14.3135 4.43647C14.7531 4.87601 15 5.47215 15 6.09375V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
