import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const JustifyCenterIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 20" fill="none" {...props}>
    <path
      d="M21.1538 3H8.84615C7.82655 3 7 3.85473 7 4.90909V15.0909C7 16.1453 7.82655 17 8.84615 17H21.1538C22.1734 17 23 16.1453 23 15.0909V4.90909C23 3.85473 22.1734 3 21.1538 3Z"
      stroke="currentColor"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M18.5 8C19.3284 8 20 7.32843 20 6.5C20 5.67157 19.3284 5 18.5 5C17.6716 5 17 5.67157 17 6.5C17 7.32843 17.6716 8 18.5 8Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      fill="none"
    />
    <path
      d="M16.8462 13.0745L13.3592 9.38231C13.1373 9.14698 12.8391 9.01043 12.5255 9.00057C12.2119 8.99071 11.9066 9.10829 11.6719 9.32927L7 13.7359M13.7692 17L18.5131 11.9675C18.73 11.7369 19.0204 11.6007 19.3272 11.5856C19.634 11.5705 19.935 11.6776 20.1712 11.8859L23 14.3887"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2 15H5M2 5H5H2ZM2 10H5H2Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M25 15H28M25 5H28H25ZM25 10H28H25Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
