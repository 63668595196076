import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RocketIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M18.0399 2.10182C18.0333 2.06977 18.0176 2.04027 17.9948 2.01681C17.9719 1.99335 17.9429 1.97691 17.911 1.9694C15.7868 1.44987 10.8798 3.30104 8.21844 5.96042C7.74413 6.43083 7.31164 6.94161 6.92586 7.48698C6.10555 7.41276 5.28524 7.47526 4.5868 7.77995C2.61453 8.64714 2.04071 10.912 1.88055 11.8842C1.87187 11.9375 1.87558 11.9921 1.89138 12.0437C1.90718 12.0953 1.93466 12.1426 1.97168 12.1819C2.00869 12.2212 2.05426 12.2514 2.10484 12.2702C2.15542 12.2891 2.20967 12.296 2.26336 12.2905L5.43016 11.9428C5.43235 12.1817 5.44669 12.4203 5.47313 12.6577C5.48965 12.8218 5.56287 12.975 5.68016 13.0909L6.90633 14.3171C7.02236 14.4344 7.1757 14.5077 7.33992 14.5241C7.5759 14.5504 7.81305 14.5648 8.05047 14.5671L7.70321 17.7311C7.69778 17.7848 7.70477 17.8389 7.72361 17.8894C7.74246 17.9399 7.77266 17.9854 7.81189 18.0224C7.85112 18.0593 7.89832 18.0868 7.94985 18.1026C8.00138 18.1184 8.05585 18.1221 8.10906 18.1135C9.08172 17.9573 11.3513 17.3835 12.2134 15.4116C12.518 14.7124 12.5805 13.8964 12.5102 13.08C13.0572 12.6945 13.5695 12.262 14.0411 11.7874C16.7122 9.13112 18.5516 4.3319 18.0399 2.10182ZM11.6669 8.34636C11.4336 8.1131 11.2747 7.8159 11.2103 7.49234C11.1459 7.16877 11.1789 6.83338 11.3051 6.52857C11.4314 6.22377 11.6452 5.96325 11.9195 5.77995C12.1938 5.59665 12.5163 5.49882 12.8462 5.49882C13.1761 5.49882 13.4986 5.59665 13.7729 5.77995C14.0472 5.96325 14.261 6.22377 14.3872 6.52857C14.5134 6.83338 14.5465 7.16877 14.4821 7.49234C14.4177 7.8159 14.2588 8.1131 14.0255 8.34636C13.8707 8.50143 13.6869 8.62447 13.4845 8.70841C13.2822 8.79236 13.0653 8.83557 12.8462 8.83557C12.6271 8.83557 12.4102 8.79236 12.2078 8.70841C12.0054 8.62447 11.8216 8.50143 11.6669 8.34636V8.34636Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.28281 13.75C3.8934 13.7978 3.53113 13.9743 3.25352 14.2516C2.56523 14.9422 2.5 17.5 2.5 17.5C2.5 17.5 5.05937 17.4348 5.74805 16.7457C6.0264 16.4685 6.20323 16.1056 6.25 15.7156"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
