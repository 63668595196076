import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MoonIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.25 5.3125C6.25 4.11641 6.42617 2.90586 6.875 1.875C3.88945 3.17461 1.875 6.22344 1.875 9.6875C1.875 14.3473 5.65273 18.125 10.3125 18.125C13.7766 18.125 16.8254 16.1105 18.125 13.125C17.0941 13.5738 15.8836 13.75 14.6875 13.75C10.0277 13.75 6.25 9.97227 6.25 5.3125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
