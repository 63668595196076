import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BookIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 6.25C10.625 3.78282 12.9856 2.52305 18.125 2.5C18.2072 2.49969 18.2886 2.51565 18.3645 2.54695C18.4405 2.57825 18.5095 2.62427 18.5676 2.68237C18.6257 2.74047 18.6718 2.80949 18.7031 2.88546C18.7344 2.96143 18.7503 3.04284 18.75 3.125V14.375C18.75 14.5408 18.6842 14.6997 18.5669 14.8169C18.4497 14.9342 18.2908 15 18.125 15C13.125 15 11.1934 16.0082 10 17.5C8.81368 16.0156 6.875 15 1.875 15C1.48907 15 1.25 14.6856 1.25 14.2996V3.125C1.24969 3.04284 1.26565 2.96143 1.29695 2.88546C1.32825 2.80949 1.37427 2.74047 1.43237 2.68237C1.49047 2.62427 1.55949 2.57825 1.63546 2.54695C1.71143 2.51565 1.79284 2.49969 1.875 2.5C7.01446 2.52305 9.375 3.78282 10 6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.25V17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
