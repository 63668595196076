import history from 'src/history';

interface UrlOptions {
  removeTrailingSlash?: boolean;
}

export default class UrlUtils {
  /**
   * Returns base route from given short link url
   * For example if given url is in format '{host}/sl/form/{ref_id}' then return 'forms'
   * */
  static getBaseRouteFromShortLinkURL(baseUrl: string) {
    // contains mapping between short-link code and the base path to route to
    const shortLinks: Map<string, string> = new Map([['form', 'forms']]);

    const shortLinkKeys = Array.from(shortLinks.keys());

    const matchedShortlinkCodes = shortLinkKeys.filter((item) =>
      baseUrl.includes(item),
    );

    const shortLinkLinkCode =
      matchedShortlinkCodes && matchedShortlinkCodes.length > 0
        ? matchedShortlinkCodes.at(0) || ''
        : '';
    return shortLinks.get(shortLinkLinkCode);
  }

  static getExtensionFromFileUrl(url: string) {
    return url.split(/#|\?/)[0].split('.').pop()?.trim();
  }

  static FormatUrlQueryToString(query: Record<string, string>) {
    return Object.keys(query)
      .map((key) => `${key}=${(query as Record<string, string>)[key]}`)
      .join('&');
  }

  static startsWithProtocol(url: string) {
    return url.startsWith('http://') || url.startsWith('https://');
  }

  static GetFullUrl(url: string, options?: UrlOptions) {
    const { removeTrailingSlash = false } = options || {};

    let convertedUrl = url;
    if (removeTrailingSlash) {
      convertedUrl = url.endsWith('/') ? url.slice(0, -1) : url;
    }

    return !this.startsWithProtocol(convertedUrl) ? `https://${url}` : url;
  }

  static isLocalhost(): boolean {
    return Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
    );
  }
}

/**
 *
 * @param message receives a string message and parses it to HTML
 * @returns HTML string
 */
export const parseMessageToHTML = (message: string): string => {
  if (!message) {
    return '';
  }

  // Converts image markdown to HTML img tags
  const imageMarkdownRegex = /!\[([^\]]*)\]\((https?:\/\/[^\s]+)\)/gi;
  const parsedWithImages = message.replace(
    imageMarkdownRegex,
    (_, alt, url) => {
      return `<img src="${url}" alt="${alt}" />`;
    },
  );

  // Converts markdown links to HTML anchor tags
  const markdownLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/gi;
  const parsedWithMarkdownLinks = parsedWithImages.replace(
    markdownLinkRegex,
    (_, text, url) => {
      return `<a href="${url}" target="_blank">${text}</a>`;
    },
  );

  // Split the text into segments based on HTML tags
  const segments = parsedWithMarkdownLinks.split(/(<[^>]+>)/);

  // Process each segment, only converting URLs in non-HTML segments
  const processedSegments = segments.map((segment) => {
    // Skip if segment is an HTML tag
    if (segment.startsWith('<') && segment.endsWith('>')) {
      return segment;
    }

    // Convert plain links to HTML anchor tags
    const linkRegex =
      /(https?:\/\/[^\s]+|www\.[^\s]+\.[^\s]+|[^\s]+\.[^\s]+)/gi;
    return segment.replace(linkRegex, (match) => {
      const link =
        match.startsWith('http') || match.startsWith('www')
          ? match
          : `http://${match}`;
      return `<a href="${link}" target="_blank">${match}</a>`;
    });
  });

  // Join segments and convert newlines
  const parsedWithLinks = processedSegments.join('');
  const parsedWithNewLines = parsedWithLinks.replace(/\n/g, '<br>');

  return parsedWithNewLines;
};

export const replaceQueryParam = (queryName: string, queryValue: string) => {
  const queryParams = new URLSearchParams(history.location.search);
  queryParams.set(queryName, queryValue);
  history.replace({
    search: queryParams.toString(),
  });
};
