import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const UnreadMessage = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2222 7.77772C17.9589 7.77772 18.6655 7.48507 19.1864 6.96413C19.7073 6.4432 20 5.73666 20 4.99995C20 4.26323 19.7073 3.55669 19.1864 3.03576C18.6655 2.51483 17.9589 2.22217 17.2222 2.22217C16.4855 2.22217 15.779 2.51483 15.258 3.03576C14.7371 3.55669 14.4444 4.26323 14.4444 4.99995C14.4444 5.73666 14.7371 6.4432 15.258 6.96413C15.779 7.48507 16.4855 7.77772 17.2222 7.77772ZM17.7778 15.5555V8.85064C17.5972 8.87495 17.4097 8.88883 17.2222 8.88883C16.441 8.88883 15.7118 8.65967 15.1042 8.26036L10.1215 12.3506C9.40278 12.9409 8.37153 12.9409 7.65278 12.3506L1.66667 7.43397V6.66661C1.66667 6.36106 1.91667 6.11106 2.22222 6.11106H13.4931C13.3889 5.76036 13.3333 5.38536 13.3333 4.99995C13.3333 4.81245 13.3472 4.62495 13.3715 4.44439H2.22222C0.996528 4.44439 0 5.44092 0 6.66661V15.5555C0 16.7812 0.996528 17.7777 2.22222 17.7777H15.5556C16.7812 17.7777 17.7778 16.7812 17.7778 15.5555ZM1.66667 9.59022L6.59722 13.6388C7.93056 14.7326 9.85069 14.7326 11.1806 13.6388L16.1111 9.59022V15.5555C16.1111 15.8611 15.8611 16.1111 15.5556 16.1111H2.22222C1.91667 16.1111 1.66667 15.8611 1.66667 15.5555V9.59022Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
