import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RadioIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 11.251C10.6904 11.251 11.25 10.6913 11.25 10.001C11.25 9.31062 10.6904 8.75098 10 8.75098C9.30964 8.75098 8.75 9.31062 8.75 10.001C8.75 10.6913 9.30964 11.251 10 11.251Z"
      fill="currentColor"
    />
    <path
      d="M7.19753 7.50977C6.58777 8.19611 6.25098 9.0823 6.25098 10.0004C6.25098 10.9185 6.58777 11.8047 7.19753 12.491"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8037 12.491C13.4135 11.8047 13.7503 10.9185 13.7503 10.0004C13.7503 9.0823 13.4135 8.19611 12.8037 7.50977"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.20673 5.51904C4.07052 6.73461 3.43848 8.33639 3.43848 10.0003C3.43848 11.6642 4.07052 13.266 5.20673 14.4815"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7939 14.4815C15.9302 13.266 16.5622 11.6642 16.5622 10.0003C16.5622 8.33639 15.9302 6.73461 14.7939 5.51904"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9922 16.25C18.5268 14.5299 19.3749 12.3052 19.3749 10C19.3749 7.69479 18.5268 5.47015 16.9922 3.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.00776 3.75C1.47313 5.47015 0.625 7.69479 0.625 10C0.625 12.3052 1.47313 14.5299 3.00776 16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
