import { Box, styled } from '@material-ui/core';
import { HoverNonBorderTableEditableItemBackground } from 'src/theme/colors';

export const EditableCellValueWrapper = styled(Box)({
  padding: 6,
  borderRadius: 4,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: HoverNonBorderTableEditableItemBackground,
  },
});
