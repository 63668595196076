import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CloudyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.91328 9.42148C4.04403 9.40188 4.16513 9.34115 4.25904 9.24808C4.35294 9.15502 4.41477 9.03447 4.43555 8.90391C4.94531 5.68711 7.27891 3.75 10 3.75C12.527 3.75 14.2105 5.40469 14.8797 7.14844C14.9191 7.25192 14.9851 7.34318 15.071 7.413C15.1569 7.48282 15.2598 7.52873 15.3691 7.54609C17.5531 7.89297 19.375 9.34414 19.375 11.875C19.375 14.4531 17.2656 16.25 14.6875 16.25H4.53125C2.38281 16.25 0.625 15.1781 0.625 12.8125C0.625 10.6863 2.33945 9.65586 3.91328 9.42148Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
