import { Dispatch } from 'react';
import { ApiTags } from 'src/services/api';
import { invoicesApi } from 'src/services/api/invoicesApi';

export function clientCompanyInvoiceHandler(
  isClient: boolean,
  dispatch: Dispatch<any>,
) {
  // Invalidate the invoices cache to force a refetch of the invoices list for clients side ,
  if (isClient) {
    dispatch(invoicesApi.util.invalidateTags([ApiTags.invoices]));
  } else {
    // Invalidate the client invoices cache to force a refetch of the client details invoices list
    dispatch(invoicesApi.util.invalidateTags([ApiTags.clientInvoices]));
  }
}
