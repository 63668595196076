import React from 'react';
import { makeStyles, createStyles, TextFieldProps } from '@material-ui/core';
import { BaseTextField } from 'src/legacy/components/TextField/BaseTextField';

const useStyles = makeStyles(() =>
  createStyles({
    textAreaContainer: {
      '& textarea': {
        resize: 'vertical',
      },
    },
  }),
);

export const ResizableTextField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <BaseTextField
      className={classes.textAreaContainer}
      fullWidth
      multiline
      minRows={2}
      maxRows="Infinity"
      {...props}
    />
  );
};
