export const WEBHOOK_EVENTS = [
  'client.created',
  'client.deleted',
  'client.updated',
  'client.customFields.updated',
  'client.activated',
  'company.created',
  'company.updated',
  'company.deleted',
  'formResponse.completed',
  'file.created',
  'file.updated',
  'file.deleted',
  'folder.created',
  'folder.updated',
  'folder.deleted',
  'link.created',
  'link.updated',
  'link.deleted',
  'message.sent',
  'contract.signed',
  'contract.requested',
  'notification.created',
  'messageChannel.created',
  'messageChannel.deleted',
  'invoice.created',
  'invoice.deleted',
  'invoice.paid',
  'invoice.voided',
  'internalUser.deleted',
  'internalUser.created',
  'payment.succeeded',
  'payment.failed',
  'product.created',
  'product.updated',
  'price.created',
  'note.created',
  'note.updated',
  'note.deleted',
  'subscription.created',
  'subscription.canceled',
] as const;

export function getWebhookEventNames() {
  // The map is for typescript's benefit, the as const doesn't work everywhere.
  return WEBHOOK_EVENTS.map((event) => event);
}
