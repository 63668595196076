import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SignatureIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.4617 1.01074C17.3658 1.01292 17.2744 1.05163 17.206 1.11896L15.7007 2.62428C15.6657 2.65923 15.638 2.70074 15.619 2.74643C15.6001 2.79212 15.5903 2.84109 15.5903 2.89055C15.5903 2.94001 15.6001 2.98899 15.619 3.03468C15.638 3.08037 15.6657 3.12187 15.7007 3.15683L17.5753 5.03138C17.6458 5.10119 17.7409 5.14036 17.8401 5.14036C17.9393 5.14036 18.0345 5.10119 18.105 5.03138L19.6103 3.52606C19.6801 3.45558 19.7193 3.3604 19.7193 3.2612C19.7193 3.16201 19.6801 3.06682 19.6103 2.99635L17.7358 1.1218C17.6994 1.0859 17.656 1.05779 17.6084 1.03918C17.5608 1.02058 17.5099 1.01187 17.4588 1.01358L17.4617 1.01074ZM17.473 1.9182L18.8207 3.26589L17.8494 4.23725L16.5017 2.88956L17.473 1.9182Z"
      fill="currentColor"
    />
    <path
      d="M17.8879 4.39062C17.7927 4.39424 17.7023 4.43388 17.6352 4.50154L10.7618 11.3749L9.41409 10.0272L10.8342 8.6071C10.904 8.53663 10.9432 8.44144 10.9432 8.34225C10.9432 8.24305 10.904 8.14787 10.8342 8.07739C10.7993 8.0424 10.7577 8.01464 10.7121 7.99569C10.6664 7.97675 10.6174 7.967 10.5679 7.967C10.5185 7.967 10.4695 7.97675 10.4238 7.99569C10.3781 8.01464 10.3366 8.0424 10.3017 8.07739L10.1128 8.26343L8.60746 9.76875C8.57247 9.80371 8.5447 9.84521 8.52576 9.8909C8.50682 9.93659 8.49707 9.98557 8.49707 10.035C8.49707 10.0845 8.50682 10.1335 8.52576 10.1792C8.5447 10.2248 8.57247 10.2663 8.60746 10.3013L10.482 12.1759C10.5525 12.2457 10.6477 12.2848 10.7469 12.2848C10.8461 12.2848 10.9413 12.2457 11.0117 12.1759L18.1549 5.03266C18.2247 4.96219 18.2639 4.867 18.2639 4.76781C18.2639 4.66861 18.2247 4.57343 18.1549 4.50296C18.1188 4.46657 18.0755 4.43796 18.0279 4.41889C17.9803 4.39982 17.9293 4.39068 17.878 4.39205L17.8879 4.39062Z"
      fill="currentColor"
    />
    <path
      d="M11.4974 7.01758C11.4477 7.01739 11.3985 7.02704 11.3526 7.04595C11.3067 7.06487 11.265 7.09269 11.2299 7.1278C11.1947 7.16292 11.1669 7.20464 11.148 7.25055C11.1291 7.29647 11.1194 7.34567 11.1196 7.39533C11.1198 7.44475 11.1297 7.49365 11.1488 7.53924C11.1679 7.58483 11.1958 7.62621 11.2309 7.66102C11.2659 7.69584 11.3075 7.7234 11.3533 7.74214C11.399 7.76088 11.448 7.77043 11.4974 7.77024C11.5967 7.76987 11.6918 7.73025 11.7621 7.66002C11.8323 7.58979 11.8719 7.49465 11.8723 7.39533C11.8725 7.34591 11.8629 7.29694 11.8442 7.25121C11.8255 7.20548 11.7979 7.16389 11.7631 7.12881C11.7283 7.09373 11.6869 7.06585 11.6413 7.04677C11.5957 7.02769 11.5468 7.01777 11.4974 7.01758Z"
      fill="currentColor"
    />
    <path
      d="M16.0419 2.51584C15.9461 2.51811 15.843 2.55788 15.7749 2.62519L12.2104 6.18969C12.0627 6.33596 12.0627 6.57596 12.2104 6.72223C12.3567 6.86993 12.5967 6.86993 12.7429 6.72223L16.3074 3.15774C16.4551 3.01146 16.4551 2.77146 16.3074 2.62519C16.2339 2.55248 16.1455 2.51229 16.0419 2.51584Z"
      fill="currentColor"
    />
    <path
      d="M8.8986 9.64484C8.73671 9.6383 8.60322 9.73686 8.54499 9.88768L7.41884 12.8983C7.30424 13.2008 7.59919 13.4962 7.90168 13.384L10.9123 12.255C11.1055 12.1819 11.2035 11.9667 11.131 11.7722C11.059 11.5776 10.8427 11.4782 10.6482 11.5506L8.4186 12.3885L9.25647 10.1589C9.32861 9.96578 9.23077 9.74993 9.03777 9.67608C8.99943 9.66231 8.94603 9.65336 8.90542 9.65236L8.8986 9.64484Z"
      fill="currentColor"
    />
    <path
      d="M17.8879 4.39067C17.7926 4.39429 17.7022 4.43393 17.6351 4.50158C17.5653 4.57205 17.5261 4.66724 17.5261 4.76643C17.5261 4.86563 17.5653 4.96081 17.6351 5.03128L18.1208 5.51696L15.3799 8.25779C15.3449 8.29274 15.3172 8.33425 15.2982 8.37994C15.2793 8.42563 15.2695 8.47461 15.2695 8.52407C15.2695 8.57352 15.2793 8.6225 15.2982 8.66819C15.3172 8.71388 15.3449 8.75539 15.3799 8.79034C15.4504 8.86015 15.5456 8.89931 15.6448 8.89931C15.744 8.89931 15.8392 8.86015 15.9096 8.79034L18.9203 5.77969C18.9901 5.70921 19.0293 5.61403 19.0293 5.51483C19.0293 5.41564 18.9901 5.32045 18.9203 5.24998L18.169 4.49874C18.1329 4.46235 18.0897 4.43375 18.042 4.41467C17.9944 4.3956 17.9434 4.38647 17.8921 4.38783L17.8879 4.39067Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.67144 2.00446C3.07862 2.00446 2.55817 2.18575 2.16706 2.51379C1.77596 2.84039 1.51727 3.29217 1.35559 3.81445C1.02916 4.86045 1.05995 6.21722 1.29539 7.71356C1.54945 9.32499 2.0699 11.0659 2.70584 12.7349C1.90361 14.332 0.996667 15.9578 0.0573921 17.4253C0.00211512 17.5121 -0.0140464 17.6159 0.0124551 17.7138C0.0389566 17.8117 0.105956 17.8958 0.198745 17.9476C0.291362 17.9984 0.40165 18.013 0.505703 17.9882C0.609755 17.9635 0.699187 17.9015 0.754611 17.8155C1.5938 16.5091 2.3714 15.0675 3.1259 13.6287C3.57705 14.6977 4.02668 15.8012 4.53943 16.6645C4.57807 16.7287 4.63581 16.7811 4.70565 16.8153C4.77549 16.8496 4.85444 16.8641 4.93296 16.8573C5.01147 16.8505 5.08619 16.8225 5.14806 16.7768C5.20994 16.7312 5.25632 16.6697 5.28161 16.5999L7 12.5L7.5 13.25L8.30356 13.0187L7.28794 11.328C7.25196 11.2607 7.19547 11.2048 7.12558 11.1674C7.0557 11.13 6.97554 11.1128 6.8952 11.1179C6.81486 11.123 6.73794 11.1502 6.67411 11.1961C6.61029 11.242 6.56241 11.3045 6.53652 11.3757L4.83353 15.4037C4.39931 14.5894 3.98202 13.7203 3.60323 12.7564C4.45936 11.0154 5.17382 9.28888 5.62036 7.73499C6.06382 6.19549 6.25784 4.84304 6.01147 3.79273C5.88859 3.26757 5.64654 2.8086 5.24465 2.48631C4.84277 2.16258 4.29922 2 3.65866 2L3.67144 2.00446ZM4.7185 3.06916C4.48138 2.87823 4.15956 2.7627 3.67144 2.7627L3.67206 2.76702C3.24708 2.76702 2.95298 2.87924 2.70969 3.08211C2.4664 3.28498 2.27208 3.60295 2.13842 4.03171C1.87142 4.88059 1.87604 6.16399 2.10239 7.59989C2.30411 8.88041 2.72293 10.2789 3.19411 11.6429C3.85776 10.2185 4.48292 8.78833 4.84169 7.54234C5.26852 6.06039 5.41295 4.78419 5.22048 3.95977C5.12347 3.54828 4.95563 3.26052 4.7185 3.06916Z"
      fill="currentColor"
    />
  </SvgIcon>
);
