import React from 'react';
import { useDispatch } from 'react-redux';
import { saveBackToPortalPage } from 'src/store/ui/actions';
import useNavigate from 'src/hooks/useNavigate';

export const useSettingsPageRedirection = (
  permissionValidator: boolean,
  settingsLandingPagePath: string,
  settingsLandingPagePathQuery?: string,
) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const goToSettings = React.useCallback(
    (specificPath?: string) => {
      if (permissionValidator) {
        // save actual pathname as back to portal page path
        // so that when user clicks on back to portal button
        // he will be redirected to the same page he comes from.
        const currentFullPath = `${window.location.pathname}${window.location.search}`;
        // settings page should not be saved as back to portal page
        // to avoid going back to settings page and not to portal page
        if (!currentFullPath.includes('/settings')) {
          dispatch(saveBackToPortalPage(currentFullPath));
        }

        navigate(
          specificPath ||
            `${settingsLandingPagePath}${settingsLandingPagePathQuery || ''}`,
        );
      }
    },
    [settingsLandingPagePath, navigate],
  );

  return { goToSettings };
};
