import {
  MessagesState,
  MessagesActions,
  MessagesActionTypes,
} from 'src/store/messages/types';

const initialState: MessagesState = {
  clientInitialized: false,
  clientInitializing: false,
  noChannelLoaded: false,
  loadingError: '',
  deletedChannels: [],
};

// eslint-disable-next-line default-param-last
const MessagesReducer = (state = initialState, action: MessagesActions) => {
  switch (action.type) {
    case MessagesActionTypes.INTIALIZE_CLIENT_START:
      return {
        ...state,
        clientInitialized: false,
        clientInitializing: true,
      };
    case MessagesActionTypes.INITIALIZE_CLIENT_DONE:
      return {
        ...state,
        currentUserId: action.currentUserId,
        clientInitialized: true,
        clientInitializing: false,
      };
    case MessagesActionTypes.SET_CREATED_CHANNEL:
      return {
        ...state,
        createdChannelId: action.createdChannelId,
      };
    case MessagesActionTypes.SET_NO_CHANNEL_LOADED:
      return {
        ...state,
        noChannelLoaded: action.payload,
      };
    case MessagesActionTypes.SET_LOADING_CHANNEL_ERROR:
      return {
        ...state,
        loadingError: action.payload,
      };
    case MessagesActionTypes.RESET_CLIENT:
      return {
        ...state,
        clientInitialized: false,
        clientInitializing: false,
      };

    case MessagesActionTypes.SET_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        totalUnreadMessages: action.payload,
      };
    case MessagesActionTypes.ADD_DELETED_CHANNEL:
      return {
        ...state,
        deletedChannels: [action.payload, ...(state.deletedChannels || [])],
      };
    default:
      return state;
  }
};

export default MessagesReducer;
