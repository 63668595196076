import { UserFields } from 'src/constants';
import { Tag } from 'src/store/tags/types';

interface FilterAllowedTagIdSetForUserInput {
  tags: Tag[];
  userId: string;
  userFields: UserFields;
}

export const FilterAllowedTagIdSetForUser = (
  input: FilterAllowedTagIdSetForUserInput,
) => {
  const { customFields } = input.userFields;
  // create a set of custom fields values for the client
  const currentUserTagCustomFieldValues: Record<string, boolean> = {};
  if (customFields) {
    Object.values(customFields).forEach((customFieldOptions) => {
      // for each custom field value only add the values that are string[]
      // to represent the tags custom field
      if (Array.isArray(customFieldOptions)) {
        customFieldOptions.forEach((customFieldOption) => {
          if (typeof customFieldOption === 'string') {
            currentUserTagCustomFieldValues[customFieldOption] = true;
          }
        });
      }
    });
  }

  return input.tags
    .filter((tag) => {
      const tagVisibility = tag.structFields.visibility;
      // when there is no visibility filtering or no filter id values it is always allowed
      if (!tagVisibility || !tagVisibility.filterIDValues) {
        return true;
      }

      switch (tagVisibility.filterType) {
        case 'client': {
          // when the current user is in the list of allowed clients, it is allowed
          return tagVisibility.filterIDValues?.includes(input.userId);
        }
        case 'company': {
          // when the current user is in the list of allowed companies, it is allowed
          const { companyId } = input.userFields;
          if (!companyId) {
            return false;
          }
          return tagVisibility.filterIDValues?.includes(companyId);
        }
        case 'customField': {
          // when the current user has a value for the tag custom field, it is allowed
          if (!customFields) {
            return false;
          }
          // find if any tag visibility values are in any tag custom fields values on this client
          const matchedCustomFieldValue = tagVisibility.filterIDValues?.find(
            (tagCustomFieldValue) =>
              currentUserTagCustomFieldValues[tagCustomFieldValue],
          );
          return Boolean(matchedCustomFieldValue);
        }
        default:
          return false;
      }
    })
    .reduce((acc, tag) => {
      acc[tag.id] = true;
      return acc;
    }, {} as Record<string, boolean>);
};
