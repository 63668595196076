import { useEffect } from 'react';
import { KeyboardKeys } from 'src/constants/shortcutConsts';

export const useEsc = (callback: () => void) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === KeyboardKeys.Esc) {
        callback();
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [callback]);
};
