export function clearCanvasContext(
  canvasElement: HTMLCanvasElement,
  canvasContext: CanvasRenderingContext2D,
) {
  canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
}

export function getCanvasContent(
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D | null,
  fontSize: number,
  fontFamily: string,
  text: string,
) {
  if (!context) {
    return null;
  }
  clearCanvasContext(canvas, context);
  context.font = `${fontSize}px ${fontFamily}`;
  context.fillText(text, 0, 24);
  const { width, fontBoundingBoxAscent, actualBoundingBoxDescent } =
    context.measureText(text);

  return {
    width,
    height: fontBoundingBoxAscent + actualBoundingBoxDescent,
    dataUrl: canvas.toDataURL('image/png'),
  };
}

export function convertTextToDataEncodedImage(
  text: string,
  fontSize: number,
  fontFamily: string,
) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return null;
  }

  const canvasContent = getCanvasContent(
    canvas,
    context,
    fontSize,
    fontFamily,
    text,
  );

  if (!canvasContent) {
    return null;
  }

  const { width, height } = canvasContent;

  const scaledCanvas = document.createElement('canvas');
  const scaledContext = scaledCanvas.getContext('2d');
  if (!scaledContext) {
    return null;
  }

  scaledCanvas.width = width;
  scaledCanvas.height = height;

  const scaledCanvasContent = getCanvasContent(
    scaledCanvas,
    scaledContext,
    fontSize,
    fontFamily,
    text,
  );

  if (!scaledCanvasContent) {
    return null;
  }

  return scaledCanvasContent.dataUrl;
}
