import { Dispatch } from 'react';
import { AppThunkAction } from 'src/store';
import { alertSnackbar } from 'src/store/ui/actions';
import { GetErrorCodeMessage } from 'src/constants/errorConsts/errorCodeMessages';
import { IgnoreTrackingErrorMessagesSet } from 'src/constants/errorConsts/errorCodeSets';
import * as ErrCodes from 'src/constants/errorConsts/errorCodesConsts';

interface ExecuteFunctionResponse {
  errors?: Array<string>;
}

interface DataResponse {
  code?: string;
  data?: { code?: string; message?: string };
  message?: string;
}

interface ApiServiceError {
  response: DataResponse;
}

interface ExecuteWithSnackbarOptions<T> {
  params: any;
  executeFunction: (params: any) => Promise<T & ExecuteFunctionResponse>;
  checkFunction?: (params: any) => boolean;
  successMessage: string;
  errorMessage: string; // this is a fallback error message if one is not determined from reponse
  dispatch: Dispatch<any>;
  disableApiFailureNotification?: boolean;
}

const showSuccessAlertSnackbar =
  (message: string): AppThunkAction =>
  async (dispatch) => {
    dispatch(
      alertSnackbar({
        successMessage: message,
      }),
    );
  };

const showFailAlertSnackbar =
  (message: string): AppThunkAction =>
  async (dispatch) => {
    dispatch(
      alertSnackbar({
        errorMessage: message,
      }),
    );
  };

/**
 * Determine if this error can be ignored for tracking purposes
 * @param errorData info about error used to determine if error is ignoreable
 */
const isErrorIgnoreableForTracking = ({
  message,
}: {
  message?: string;
}): boolean => {
  if (message) {
    return Boolean(IgnoreTrackingErrorMessagesSet[message]);
  }

  return false;
};

class ResultError {
  response: any;

  constructor(value: any) {
    this.response = value;
  }
}

export interface ApiWithNotificationResponseType<T> {
  status: boolean;
  data: T;
  errorMessage: string;
}

// Displays notification in snackbar. Still in testing process. Needs to be documented how to be used.
export const notify = ({
  status,
  successMessage = '',
  error,
  errorMessage,
  dispatch,
}: {
  status: 'success' | 'error';
  successMessage?: string;
  errorMessage?: string;
  error?: any;
  dispatch: Dispatch<any>;
  response?: any;
}) => {
  if (status === 'success') {
    if (successMessage) {
      dispatch(showSuccessAlertSnackbar(successMessage));
    }
  } else {
    let message;

    // try to get the message based on the error response code
    if (error?.response?.code) {
      message = GetErrorCodeMessage(error.response.code);
    } else if (error?.response?.data?.code) {
      message = GetErrorCodeMessage(error.response.data.code);
    } else if (error?.error?.data?.code) {
      // this the format of the error object that is passed from rtk-query mutation
      // methods. The backend returns a normal portalerr object which rtk wraps in an
      // error
      message = GetErrorCodeMessage(error.error.data.code);
    }

    // if no message is defined for this error response code set to the fallback
    // errorMessage param
    if (!message) {
      message = errorMessage;
    }

    if (message) {
      dispatch(showFailAlertSnackbar(message));
    }

    // check if error can be ignored for tracking purposes. If so
    // dont need to log console error
    if (isErrorIgnoreableForTracking({ message })) {
      return;
    }

    if (error.response) {
      console.error('api error response', error.response);
    } else {
      console.error('unexpected error', error);
    }
  }
};

export const CallApiWithNotification = async <T>(
  executionOptions: ExecuteWithSnackbarOptions<T>,
): Promise<ApiWithNotificationResponseType<T>> => {
  const {
    executeFunction,
    checkFunction,
    params,
    successMessage,
    errorMessage,
    dispatch,
    disableApiFailureNotification,
  } = executionOptions;

  try {
    const result = await executeFunction(params);
    if (result) {
      if ((checkFunction && checkFunction(result)) || !checkFunction) {
        if (successMessage) {
          dispatch(showSuccessAlertSnackbar(successMessage));
        }

        return {
          status: true,
          data: result,
          errorMessage: '',
        };
      }
    }
    if (result.errors && result.errors.length > 0) {
      // if the result has an errors property that includes an array of items throw the first error
      // to be handle by snackbar
      throw new ResultError(result.errors[0]);
    } else {
      throw Error();
    }
  } catch (error) {
    let message = '';
    const errorToCheck = error as ApiServiceError;
    // try to get the message based on the error response code
    if (errorToCheck?.response?.code) {
      message = GetErrorCodeMessage(errorToCheck.response.code);
      if (
        errorToCheck.response.code ===
          ErrCodes.CommonErrCode.invalidParameter &&
        errorToCheck.response.message
      ) {
        message = `${errorMessage} ${errorToCheck.response.message}.`;
      }
    } else if (errorToCheck?.response?.data?.code) {
      message = GetErrorCodeMessage(errorToCheck.response.data.code);
    } else if (error instanceof ResultError) {
      if (typeof error?.response === 'string') {
        message = error.response;
      }
    } else if (errorToCheck?.response?.data?.message) {
      // check if error message was passed as response
      message = errorToCheck?.response.data.message;
    }
    // if no message is defined for this error response code set to the fallback
    // errorMessage param
    if (!message) {
      message = errorMessage;
    }

    if (message && !disableApiFailureNotification) {
      dispatch(showFailAlertSnackbar(message));
    }

    // check if error can be ignored for tracking purposes. If so
    // dont need to log console error
    if (isErrorIgnoreableForTracking({ message })) {
      if (errorToCheck.response) {
        console.error('api error response', errorToCheck.response);
      } else {
        console.error('unexpected error', error);
      }
    }

    throw new Error(message);
  }
};
