import UsersClient, { UserAccessAttributes } from 'src/clients/UsersClient';
import { AppThunkAction } from 'src/store/reduxTypes';
import {
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  SET_USER_ROLE_REQUEST,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_FAILURE,
  DELETE_USER_FAILED,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  SET_INTERNAL_USERS,
  ADD_INTERNAL_USERS,
  MODIFY_INTERNAL_USERS,
  REMOVE_INTERNAL_USERS,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  UPDATE_USERS_SUCCESS,
} from 'src/store/users/types';
import { Client, ClientFormData } from 'src/store/clients/types';
import { User, UserRolesTypesUnMapped } from 'src/constants/dataTypes';
import ApiUtils from 'src/utils/ApiUtils';
import { CallApiWithNotification } from 'src/clients/ApiService';
import ClientsClient from 'src/clients/ClientsClient';
import { usersApi } from 'src/services/api';
import { isEqual } from 'lodash';
import { AppViewMode } from 'src/constants';

/**
 * Set the internal users from initial data load
 */
export const setInternalUsers = (users: User[]) => ({
  type: SET_INTERNAL_USERS,
  payload: users,
});

export const addInternalUsers = (users: User[]) => ({
  type: ADD_INTERNAL_USERS,
  payload: users,
});

/**
 * Modify internal users in state. If this includes the current user
 * then if the access level was modified (either access level changes or access list changed)
 * then refetch companies and clients. This is done by initializing a refetch on
 * the rtk queries. This will work when the IU changes the access level themselves or
 * receives a websocket message that their access level has changed.
 * @param users list of internal users to modify in state
 * @returns
 */
export const modifyInternalUsers =
  (users: User[]): AppThunkAction =>
  async (dispatch, getState) => {
    const currentLoggedInUserID = getState().user.id;
    const updatedCurrentIU = users.find(
      (u) => u.cognitoUsername === currentLoggedInUserID,
    );

    // The user store which has a reference to the current users id
    // doesn't have the access level properties so to
    // check if access level is changed, look for the IU
    // in the users.InternalUser state and compare the access properties
    // to check if we need to re-fetch clients/companies.
    if (updatedCurrentIU) {
      const { internalUsers } = getState().users;
      const prevInternalUser = internalUsers.find(
        (u) => u.id === updatedCurrentIU.id,
      );

      if (
        prevInternalUser?.isClientAccessLimited !==
          updatedCurrentIU.isClientAccessLimited ||
        !isEqual(
          prevInternalUser?.companyAccessList,
          updatedCurrentIU.companyAccessList,
        )
      ) {
        const initiateOptions = { subscribe: false, forceRefetch: true };
        dispatch(
          usersApi.endpoints.getCompanies.initiate(
            currentLoggedInUserID,
            initiateOptions,
          ),
        );
        dispatch(
          usersApi.endpoints.getUsers.initiate(
            {
              userId: currentLoggedInUserID,
              // this refetch is only called when the current logged in user is an IU and their access has changed so know the view mode is Internal
              appViewMode: AppViewMode.INTERNAL,
            },
            initiateOptions,
          ),
        );
      }
    }

    dispatch({ type: MODIFY_INTERNAL_USERS, payload: users });
  };

export const removeInternalUsers = (users: User[]) => ({
  type: REMOVE_INTERNAL_USERS,
  payload: users,
});

export const SetUsersAction = (users: User[]) => ({
  type: LOAD_USERS_SUCCESS,
  payload: users,
});

export const inviteInternalUser =
  (userId: string, userFirstName: string): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: INVITE_USER_REQUEST };
    }

    function success(invitedUserId: string, invitedByUserId: string) {
      return { type: INVITE_USER_SUCCESS, invitedUserId, invitedByUserId };
    }

    function failure(error: string) {
      return { type: INVITE_USER_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: ClientsClient.inviteInternalUser,
      params: userId,
      successMessage: `${userFirstName} was sent an email to activate their account.`,
      errorMessage: `We could not invite ${userFirstName}.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(userId, result.data.fields.invitedBy));
    } else {
      dispatch(failure(result.data));
    }
  };

export const updateUsers = (users: User[]) => ({
  type: UPDATE_USERS_SUCCESS,
  payload: users,
});

export const listUsers =
  (isClient = false): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: LOAD_USERS_REQUEST };
    }

    function failure(error: string) {
      return { type: LOAD_USERS_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: isClient
        ? UsersClient.listClients
        : UsersClient.listUsers,
      params: {},
      successMessage: ``,
      errorMessage: `Can't load users`,
      dispatch,
    });

    if (result.status) {
      dispatch(SetUsersAction(result.data));
    } else {
      dispatch(failure(result.data));
    }
  };

export const addUser =
  (clientData: ClientFormData, isClient: boolean): boolean | AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: ADD_USER_REQUEST };
    }

    function success(user: User) {
      return {
        type: ADD_USER_SUCCESS,
        payload: {
          user,
          isClient,
        },
      };
    }

    function failure(error: string) {
      return { type: ADD_USER_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: UsersClient.addUser,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: { clientData, isClient },
      successMessage: `${clientData.cognitoFirstName} was sent their invitation to join the team.`,
      errorMessage: `We were unable to invite ${clientData.cognitoFirstName}.`,
      dispatch,
    });

    if (result.status) {
      const [newClient] = result.data.createdItems;
      dispatch(success(newClient));
    } else {
      dispatch(failure(result.data));
    }

    return result.status;
  };

export const setUserRole =
  (clientData: Client, roles: Array<UserRolesTypesUnMapped>): AppThunkAction =>
  async (dispatch, getState) => {
    const { user } = getState();

    function request() {
      return { type: SET_USER_ROLE_REQUEST };
    }

    function success(
      updatedUserId: string,
      newRoles: Array<UserRolesTypesUnMapped>,
    ) {
      return { type: SET_USER_ROLE_SUCCESS, payload: updatedUserId, newRoles };
    }

    function failure(error: string) {
      return { type: SET_USER_ROLE_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: UsersClient.setUserRole,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: {
        user: clientData,
        isClient: user.isClient,
        roles,
      },
      successMessage: `The role has been changed.`,
      errorMessage: `We were unable to set the role.`,
      dispatch,
    });

    if (result.status) {
      const [updatedUserId] = result.data.succeededIds;
      dispatch(success(updatedUserId, roles));
    } else {
      dispatch(failure(result.data));
    }
  };

export const deleteUser =
  (user: User, userType: 'internal' | 'client' = 'internal'): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: DELETE_USER_REQUEST };
    }

    function success(payload: string) {
      return { type: DELETE_USER_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: DELETE_USER_FAILED, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction:
        userType === 'internal'
          ? UsersClient.deleteUser
          : ClientsClient.deleteUser,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: user,
      successMessage: `${
        user ? `${user.fields.givenName} ${user.fields.familyName}` : ''
      } has been deleted.`,
      errorMessage: `${
        user ? `${user.fields.givenName} ${user.fields.familyName}` : ''
      } could not be deleted.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(user.id));
    } else {
      dispatch(failure(result.data));
    }
  };

export type UpdateUserInput = {
  id: string;
  fields: {
    [key: string]: string | number | boolean;
  };
  userAccessAttributes?: UserAccessAttributes;
};

export const updateUser =
  (input: UpdateUserInput, isClient: boolean): AppThunkAction =>
  async (dispatch) => {
    const { id: userId, fields, userAccessAttributes } = input;

    const result = await CallApiWithNotification({
      executeFunction: UsersClient.updateProfileAttributes,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: {
        userId,
        isClient,
        fields,
        userAccessAttributes,
      },
      successMessage: 'User updated.',
      errorMessage: `The changes for the user could not be updated.`,
      dispatch,
    });

    if (result.status) {
      dispatch(modifyInternalUsers(result.data.createdItems));
    }
  };
