import { AlphaNumericRegex } from 'src/constants';
import {
  InvoiceCollectionMethod,
  LineItemType,
  MAX_LINE_ITEM_AMOUNT,
  MAX_LINE_ITEM_QUANTITY,
  MAX_TOTAL_AMOUNT,
  MAX_TOTAL_AMOUNT_ERROR,
  MIN_LINE_ITEM_AMOUNT,
  MIN_LINE_ITEM_AMOUNT_ERROR,
  PaymentMethodType,
} from 'src/services/api/invoicesApi';
import { v4 } from 'uuid';
import { z } from 'zod';

export const LINE_ITEM_AMOUNT_ZERO = '0';

export const DEFAULT_LINE_ITEM = {
  type: LineItemType.OneTime,
  id: v4(),
  description: '',
  quantity: '1',
  amount: '',
} as const;

const paymentMethodSchema = z.enum([
  PaymentMethodType.BankAccount,
  PaymentMethodType.CreditCard,
]);

export const paymentPreferencesSchema = z.object({
  paymentMethodTypes: z
    .array(paymentMethodSchema)
    .min(1, { message: 'At least one payment method is required' }),
  absorbTransactionFee: z.array(paymentMethodSchema),
});

export const oneOffLineItemSchema = z.object({
  type: z.literal(LineItemType.OneTime),
  id: z.string(),
  description: z
    .string({
      required_error: 'Provide an item',
    })
    .max(100, { message: 'Item must be less than 100 characters' })
    .refine((value) => AlphaNumericRegex.test(value), {
      message: 'The item cannot be only numbers',
    }),
  amount: z.string({ required_error: 'Price is required' }).refine(
    (value) => {
      const number = parseFloat(value);
      return number <= MAX_LINE_ITEM_AMOUNT;
    },
    { message: 'Amount can be at most 100,000' },
  ),
  quantity: z.string({ required_error: 'Quantity is required' }).refine(
    (value) => {
      const parsedQuantity = parseFloat(value);
      return parsedQuantity >= 1 && parsedQuantity <= MAX_LINE_ITEM_QUANTITY;
    },
    { message: 'Specify quantity between 1 and 1,000,000' },
  ),
  isEditing: z.boolean().optional(),
  isValid: z.boolean().optional(),
  shouldAddProduct: z.boolean().optional(),
});

export const productLineItemSchema = z.object({
  productId: z.string(),
  type: z.literal(LineItemType.Product),
  priceId: z.string(),
  id: z.string(),
  quantity: z.string({ required_error: 'Quantity is required' }).refine(
    (value) => {
      const parsedQuantity = parseFloat(value);
      return parsedQuantity >= 1 && parsedQuantity <= MAX_LINE_ITEM_QUANTITY;
    },
    { message: 'Specify quantity between 1 and 1,000,000' },
  ),
  amount: z
    .string({ required_error: 'Price is required' })
    .refine(
      (value) => {
        const number = parseFloat(value);
        return number >= MIN_LINE_ITEM_AMOUNT;
      },
      { message: 'Amount must be between 0.5 and 100,000' },
    )
    .refine(
      (value) => {
        const number = parseFloat(value);
        return number <= MAX_LINE_ITEM_AMOUNT;
      },
      { message: 'Amount can be at most 100,000' },
    ),
  description: z.string({
    required_error: 'Provide a product',
  }),
  isEditing: z.boolean().optional(),
  isValid: z.boolean().optional(),
  shouldAddPrice: z.boolean().optional(),
});

const lineItemSchema = z
  .discriminatedUnion('type', [oneOffLineItemSchema, productLineItemSchema])
  .superRefine((data, ctx) => {
    if (
      data.type === LineItemType.OneTime &&
      data.shouldAddProduct &&
      parseFloat(data.amount) < MIN_LINE_ITEM_AMOUNT
    ) {
      ctx.addIssue({
        path: ['amount'],
        message: 'Amount must be between 0.5 and 100,000',
        code: z.ZodIssueCode.custom,
      });
    }
  });

export const lineItemsSchema = z.array(lineItemSchema);

export type OneOffLineItem = z.infer<typeof oneOffLineItemSchema>;
export type ProductLineItem = z.infer<typeof productLineItemSchema>;
export type LineItem = z.infer<typeof lineItemSchema>;
export type LineItemsSchema = z.infer<typeof lineItemsSchema>;

const manualInvoiceSchema = z.object({
  collectionMethod: z.literal(InvoiceCollectionMethod.SendInvoice),
  dueDate: z.string(),
  dueDays: z
    .string({
      required_error: 'Please enter the number of days till the payment is due',
    })
    .refine(
      (value) => {
        const parsedValue = parseFloat(value);
        return parsedValue > 0;
      },
      {
        message:
          'The number of days till the payment is due must be greater than 0',
      },
    )
    .refine(
      (value) => {
        const parsedValue = parseFloat(value);
        const DAYS_IN_TWO_YEARS = 365 * 2;
        return parsedValue <= DAYS_IN_TWO_YEARS;
      },
      {
        message: 'Due days cannot be more than 2 years in the future',
      },
    ),
  recipientId: z.string({ required_error: 'Please select a recipient' }),
  taxPercentage: z
    .string()
    .optional()
    .refine(
      (value) => {
        // Tax is totally optional so we can return true if it's not provided
        if (!value) return true;
        const parsedQuantity = parseFloat(value);
        return parsedQuantity >= 0 && parsedQuantity <= 100;
      },
      { message: 'Tax rate must be under 100%' },
    ),
  totalAmount: z
    .number()
    .min(MIN_LINE_ITEM_AMOUNT, MIN_LINE_ITEM_AMOUNT_ERROR)
    .max(MAX_TOTAL_AMOUNT, MAX_TOTAL_AMOUNT_ERROR),
  attachmentKeys: z.array(z.string()).nullable(),
  memo: z
    .string()
    .max(500, { message: 'Memo must be under 500 characters' })
    .optional(),
  lineItems: lineItemsSchema,
  paymentPreferences: paymentPreferencesSchema,
  primarySource: z.string().nullable(),
  currency: z.string().nullable(),
});

const automaticInvoiceSchema = z.object({
  collectionMethod: z.literal(InvoiceCollectionMethod.ChargeAutomatically),
  recipientId: z.string({ required_error: 'Please select a recipient' }),
  taxPercentage: z
    .string()
    .optional()
    .refine(
      (value) => {
        // Tax is totally optional so we can return true if it's not provided
        if (!value) return true;
        const parsedQuantity = parseFloat(value);
        return parsedQuantity >= 0 && parsedQuantity <= 100;
      },
      { message: 'Tax rate must be under 100%' },
    ),
  totalAmount: z
    .number()
    .min(MIN_LINE_ITEM_AMOUNT, MIN_LINE_ITEM_AMOUNT_ERROR)
    .max(MAX_TOTAL_AMOUNT, MAX_TOTAL_AMOUNT_ERROR),
  attachmentKeys: z.array(z.string()).nullable(),
  memo: z
    .string()
    .max(500, { message: 'Memo must be under 500 characters' })
    .optional(),
  lineItems: lineItemsSchema,
  paymentPreferences: paymentPreferencesSchema,
  primarySource: z.string().nullable(),
  currency: z.string().nullable(),
});

export type ManualInvoiceSchema = z.infer<typeof manualInvoiceSchema>;
export type AutomaticInvoiceSchema = z.infer<typeof automaticInvoiceSchema>;

export const invoiceSchema = z.discriminatedUnion('collectionMethod', [
  manualInvoiceSchema,
  automaticInvoiceSchema,
]);

export const invoiceTemplateSchema = z.object({
  name: z.string({
    required_error: 'Template name is required',
  }),
  description: z.string().optional(),
  lineItems: lineItemsSchema,
  taxPercentage: z
    .string()
    .optional()
    .refine(
      (value) => {
        // Tax is totally optional so we can return true if it's not provided
        if (!value) return true;
        const parsedQuantity = parseFloat(value);
        return parsedQuantity >= 0 && parsedQuantity <= 100;
      },
      { message: 'Tax rate must be under 100%' },
    ),
  totalAmount: z
    .number()
    .min(MIN_LINE_ITEM_AMOUNT, MIN_LINE_ITEM_AMOUNT_ERROR)
    .max(MAX_TOTAL_AMOUNT, MAX_TOTAL_AMOUNT_ERROR),
  memo: z
    .string()
    .max(500, { message: 'Memo must be under 500 characters' })
    .optional(),
  attachmentKeys: z.array(z.string()).nullable(),
});

export type InvoiceSchema = z.infer<typeof invoiceSchema>;
export type InvoiceTemplateSchema = z.infer<typeof invoiceTemplateSchema>;
