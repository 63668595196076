export const NEW_FILE_OPTIONS_FOR_MAIN = {
  UPLOAD_FILE: 'Upload file',
  UPLOAD_FOLDER: 'Upload folder',
  ADD_LINK: 'Add a link',
  NEW_FOLDER: 'Create folder',
} as const;

export enum SignatureComponentType {
  REQUEST_SIGN = 'requestSign',
  REQUEST_DATE = 'requestDate',
  REQUEST_INITIAL = 'requestInitial',
  REQUEST_TEXT = 'requestText',
  OWNER_SIGN = 'ownerSign',
  OWNER_DATE = 'ownerDate',
  OWNER_INITIAL = 'ownerInitial',
  OWNER_TEXT = 'ownerText',
}

export const SignatureComponentTypeLabelMap = {
  signature: 'Signature',
  date: 'Date',
  initials: 'Initials',
  text: 'Text',
} as const;
