export enum DomainVerificationStatus {
  verified = 'SUCCESS',
  verifying = 'VERIFYING',
  failed = 'FAILED',
}

export const REGISTRAR_VALUES = {
  GODADDY: 'godaddy',
  BLUEHOST: 'bluehost',
  HOSTGATOR: 'hostgator',
  NAMECHEAP: 'namecheap',
  GOOGLE: 'google',
  CLOUDFLARE: 'cloudflare',
  OTHER: 'other',
};

export const RegistrarInstructions = {
  [REGISTRAR_VALUES.CLOUDFLARE]: `Portal issues an SSL certificate for you, so you don't need an additional proxy with Cloudflare. Disable the Cloudflare proxy when adding DNS records.`,
};

export const PreservePeriodRegistrars = [
  REGISTRAR_VALUES.NAMECHEAP,
  REGISTRAR_VALUES.GOOGLE,
];

export const REGISTRAR_LIST = [
  {
    id: '0',
    label: 'GoDaddy.com',
    value: REGISTRAR_VALUES.GODADDY,
  },
  {
    id: '3',
    label: 'Namecheap.com',
    value: REGISTRAR_VALUES.NAMECHEAP,
  },
  {
    id: '6',
    label: 'Cloudflare',
    value: REGISTRAR_VALUES.CLOUDFLARE,
  },
  {
    id: '5',
    label: 'Google',
    value: REGISTRAR_VALUES.GOOGLE,
  },
  {
    id: '1',
    label: 'Bluehost.com',
    value: REGISTRAR_VALUES.BLUEHOST,
  },
  {
    id: '2',
    label: 'HostGator.com',
    value: REGISTRAR_VALUES.HOSTGATOR,
  },
  {
    id: '4',
    label: 'Other',
    value: REGISTRAR_VALUES.OTHER,
  },
];

export const DOMAIN_INSTRUCTION_TYPE = {
  CNAME: 'CNAME',
};
