export const NO_ACTIVE_PLAN_PROMPT_TITLE =
  'You don’t have an active Copilot plan';
export const NO_ACTIVE_PLAN_PROMPT_BODY_ADMIN =
  'You don’t currently have an active Copilot subscription. To continue using Copilot, please reselect a plan below.';
export const NO_ACTIVE_PLAN_PROMPT_BODY_STAFF =
  'You don’t currently have an active Copilot subscription. To continue using Copilot, please contact an admin user on your team and ask them to select a plan.';
export const TRIAL_PLAN_EXPIRED_PROMPT_TITLE = 'Your Copilot trial has ended';
export const TRIAL_PLAN_EXPIRED_PROMPT_BODY_ADMIN =
  'Your 14-day trial has ended. To continue using Copilot, select a plan below.';
export const TRIAL_PLAN_EXPIRED_PROMPT_BODY_STAFF =
  'Your 14-day trial has ended. To continue using Copilot, please contact an admin user on your team and ask them to select a plan.';
