import { useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import { RootState } from 'src/store';
import { initializeClientAction } from 'src/store/messages/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import { createSelector } from '@reduxjs/toolkit';

const selector = createSelector(
  [
    (state: RootState) => state.users.users,
    (state: RootState) => state.users.internalUsers,
    (state: RootState) => state.messages.clientInitialized,
    (state: RootState) => state.user.loaded,
  ],
  (clientUsers, internalUsers, clientInitialized, userLoaded) => ({
    clientUsers,
    internalUsers,
    clientInitialized,
    userLoaded,
  }),
);

export const useChatClient = () => {
  const dispatch = useAppDispatch();
  const { client: chatClient } = useChatContext();

  const { userLoaded, clientUsers, internalUsers, clientInitialized } =
    useAppSelector(selector);

  /**
   * This effect is responsible for initializing the client.
   * It depends the clientUsers and internalUsers states
   * from where the current user data is retrieved.
   */
  useEffect(() => {
    if (!userLoaded) return;
    dispatch(initializeClientAction(chatClient));
  }, [chatClient, userLoaded, clientUsers, internalUsers]);

  return clientInitialized ? chatClient : null;
};
