import { InboxNotificationDetailsResponse } from 'src/entities/Notifications';
import { API } from 'src/utils/AmplifyApiUtils';

export default class InboxClient {
  static async loadAllPaginatedNotifications(
    notifications: InboxNotificationDetailsResponse[],
    nextToken: string | undefined = undefined,
  ): Promise<InboxNotificationDetailsResponse[]> {
    // call notifications over and over again until we get all notifications
    const getNotificationsResponse = await API.get('AppAPI', `/notifications`, {
      queryStringParameters: {
        nextToken,
        limit: 1000,
      },
    });
    const allNotifications = [
      ...notifications,
      ...getNotificationsResponse.data,
    ];
    if (!getNotificationsResponse.nextToken) {
      return allNotifications;
    }
    return this.loadAllPaginatedNotifications(
      allNotifications,
      getNotificationsResponse.nextToken,
    );
  }
}
