import React from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  IconButton,
  Theme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ChevronLeft } from '@material-ui/icons';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { BackIcon } from 'src/legacy/components/Icons';
import { PLAN_PAGE } from 'src/constants';
import useNavigate from 'src/hooks/useNavigate';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { DescriptionTooltip } from 'src/legacy/components/Tooltip';
import { BlackHeadings } from 'src/theme/colors';

import { typography12MediumStyle } from 'src/legacy/components/Text';
import Button from 'src/legacy/components/Button';
import { RootState } from 'src/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectPlanButton: {
      color: BlackHeadings,
      textDecoration: 'underline',
      ...typography12MediumStyle,
    },
    backToggleMobile: {
      padding: 0,
      marginRight: theme.spacing(1),
    },
  }),
);

export const SettingsSidebarBackToggle = () => {
  const classes = useStyles();
  const { navigate } = useNavigate();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const backToPortalDestinationPath = useSelector(
    (state: RootState) => state.ui.backToPortalDestinationPath,
  );
  const { isTrialPeriodExpired, isCancelledPlan, isPaidPlan } = usePlanStatus();

  const handleBackToggleClicked = () => {
    navigate(backToPortalDestinationPath);
  };

  // disable back toggle on settings screen
  const disableBackToggle =
    (isTrialPeriodExpired || isCancelledPlan) && !isPaidPlan;
  const backToggleRenderer = () => {
    if (isSmScreen) {
      return (
        <IconButton
          disabled={disableBackToggle}
          onClick={handleBackToggleClicked}
          className={classes.backToggleMobile}
        >
          <ChevronLeft />
        </IconButton>
      );
    }

    return (
      <Button
        disabled={disableBackToggle}
        htmlId="settings-sidebar-back-toggle"
        startIcon={<BackIcon />}
        variant="text"
        color="primary"
        onClick={handleBackToggleClicked}
      >
        <BaseTypography fontType="13Medium">Go back</BaseTypography>
      </Button>
    );
  };

  const goToPlanSettings = () => {
    navigate(PLAN_PAGE.path);
  };

  return disableBackToggle ? (
    <DescriptionTooltip
      placement="bottom-start"
      interactive
      title={
        <BaseTypography fontType="12Medium" style={{ color: BlackHeadings }}>
          To continue using Copilot please{' '}
          <Button
            htmlId="go-to-select-plan-btn"
            variant="text"
            onClick={goToPlanSettings}
            className={classes.selectPlanButton}
          >
            select a plan
          </Button>
        </BaseTypography>
      }
    >
      <Box>{backToggleRenderer()}</Box>
    </DescriptionTooltip>
  ) : (
    backToggleRenderer()
  );
};
