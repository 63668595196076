import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ContractIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.3773 16.0684H3.87015C2.44859 16.0684 1.84192 15.5051 1.84192 14.1851V4.14058C1.84192 2.82057 2.44859 2.25724 3.87015 2.25724H8.60266V4.14058C8.60266 6.16455 9.80336 7.2795 11.983 7.2795H14.0113V9.16284C14.0113 9.50938 14.3141 9.79063 14.6873 9.79063C15.0605 9.79063 15.3634 9.50938 15.3634 9.16284V6.65171C15.3634 6.48514 15.2922 6.32527 15.1651 6.20808L9.75652 1.18582C9.62941 1.0678 9.45812 1.00167 9.27873 1.00167H3.87015C1.69049 1.00167 0.489777 2.11661 0.489777 4.14058V14.1851C0.489777 16.2091 1.69049 17.324 3.87015 17.324H8.3773C8.7505 17.324 9.05338 17.0427 9.05338 16.6963C9.05338 16.3497 8.7505 16.0684 8.3773 16.0684ZM9.95481 4.14058V3.14534L13.0548 6.02393H11.983C10.5615 6.02393 9.95481 5.4606 9.95481 4.14058ZM11.0816 8.53507C11.4548 8.53507 11.7577 8.81631 11.7577 9.16284C11.7577 9.50938 11.4548 9.79063 11.0816 9.79063H4.77158C4.39838 9.79063 4.0955 9.50938 4.0955 9.16284C4.0955 8.81631 4.39838 8.53507 4.77158 8.53507H11.0816ZM9.05338 12.511C9.05338 12.8576 8.7505 13.1388 8.3773 13.1388H4.77158C4.39838 13.1388 4.0955 12.8576 4.0955 12.511C4.0955 12.1645 4.39838 11.8832 4.77158 11.8832H8.3773C8.7505 11.8832 9.05338 12.1645 9.05338 12.511ZM14.6873 11.0462C12.3265 11.0462 10.4055 12.8299 10.4055 15.0221C10.4055 17.2144 12.3265 18.9981 14.6873 18.9981C17.0481 18.9981 18.9691 17.2144 18.9691 15.0221C18.9691 12.8299 17.0481 11.0462 14.6873 11.0462ZM14.6873 17.7426C13.072 17.7426 11.7577 16.5221 11.7577 15.0221C11.7577 13.5222 13.072 12.3018 14.6873 12.3018C16.3028 12.3018 17.6169 13.5222 17.6169 15.0221C17.6169 16.5221 16.3028 17.7426 14.6873 17.7426ZM16.2918 13.8804C16.556 14.1257 16.556 14.5233 16.2918 14.7686L14.7892 16.1639C14.6621 16.2811 14.4908 16.3471 14.3114 16.3471C14.132 16.3471 13.9598 16.281 13.8336 16.163L13.0827 15.4657C12.8186 15.2206 12.8186 14.823 13.0827 14.5777C13.3469 14.3333 13.7751 14.3324 14.0392 14.5777L14.3123 14.8313L15.3372 13.8804C15.6005 13.636 16.0278 13.636 16.2918 13.8804Z"
      fill="currentColor"
    />
  </SvgIcon>
);
