import { useContext, useEffect, useState } from 'react';
import { RouteContext, type RouteContextType } from 'src/context';
import history from 'src/history';

// Normalize search is necessary when comparing a URL string with
// a route context object. In the string the search params are
// arbitrarily ordered, in route context the search params are an
// object. This function takes either input and returns a search
// string ordered a-z by key.
function normalizeSearch(search: string | Record<string, string>): string {
  if (typeof search === 'string') {
    return normalizeSearchString(search);
  } else {
    return normalizeSearchObject(search);
  }
}

function normalizeSearchString(search: string): string {
  const searchParams = new URLSearchParams(search);
  const sortedParams = Array.from(searchParams.entries()).sort();
  return new URLSearchParams(
    sortedParams.map(([key, value]) => `${key}=${value}`).join('&'),
  ).toString();
}

function normalizeSearchObject(search: Record<string, string>): string {
  const sortedKeys = Object.keys(search).sort();
  return new URLSearchParams(
    sortedKeys.map((key) => `${key}=${search[key]}`).join('&'),
  ).toString();
}

export function useRunOncePerNavigation(
  callback: (routeContext: RouteContextType) => void,
) {
  const routeContext = useContext(RouteContext);
  const [url, setUrl] = useState<string>('');
  const [prevUrl, setPrevUrl] = useState<string>('');

  useEffect(() => {
    // This history listener is set up carefully to ensure the callback
    // fires only once per navigation event. If we simply listen for
    // changes on RouteContext, several re-renders can happen between
    // navigations.
    const unregister = history.listen((location) => {
      setUrl(`${location.pathname}?${normalizeSearch(location.search)}`);
    });
    return () => {
      unregister();
    };
  }, [history, setUrl]);

  useEffect(() => {
    if (!url && routeContext) {
      setUrl(
        `${routeContext.pathname}?${normalizeSearch(
          routeContext.query,
        ).toString()}`,
      );
    }
    if (
      // if we've got a context
      routeContext &&
      // and the url has changed since we last called the callback
      url !== prevUrl &&
      // and the router context has caught up with the navigation
      url ===
        `${routeContext.pathname}?${normalizeSearch(
          routeContext.query,
        ).toString()}`
    ) {
      // then we can call the callback and update the previous URL
      setPrevUrl(url);
      callback(routeContext);
    }
  }, [url, setPrevUrl, prevUrl, routeContext]);
}
