export const INITIAL_PAGE_PRIMARY_FONT = 'h2';
export const INITIAL_PAGE_SECONDARY_FONT = 'h4';
export const DEFAULT_FONTFAMILY = 'Inter';

export const CLIENT_FONTFAMILY_OPTIONS = [
  {
    label: 'Abhaya Libre',
    value: 'Abhaya+Libre',
  },
  {
    label: 'Alegreya',
    value: 'Alegreya',
  },
  {
    label: 'Alegreya Sans',
    value: 'Alegreya+Sans',
  },
  {
    label: 'Andada Pro',
    value: 'Andada+Pro',
  },
  {
    label: 'Archivo',
    value: 'Archivo',
  },
  {
    label: 'Asap Condensed',
    value: 'Asap+Condensed',
  },
  {
    label: 'Barlow',
    value: 'Barlow',
  },
  {
    label: 'Bitter',
    value: 'Bitter',
  },
  {
    label: 'Chivo',
    value: 'Chivo',
  },
  {
    label: 'Cormorant',
    value: 'Cormorant',
  },
  {
    label: 'DM Sans',
    value: 'DM+Sans',
  },
  {
    label: 'Dosis',
    value: 'Dosis',
  },
  {
    label: 'Encode Sans',
    value: 'Encode+Sans',
  },
  {
    label: 'Enriqueta',
    value: 'Enriqueta',
  },
  {
    label: 'Epilogue',
    value: 'Epilogue',
  },
  {
    label: 'Fira Sans',
    value: 'Fira+Sans',
  },
  {
    label: 'Frank Ruhl Libre',
    value: 'Frank+Ruhl+Libre',
  },
  {
    label: 'Gelasio',
    value: 'Gelasio',
  },
  {
    label: 'Hahmlet',
    value: 'Hahmlet',
  },
  {
    label: 'Inconsolata',
    value: 'Inconsolata',
  },
  {
    label: 'Inter',
    value: 'Inter',
  },
  {
    label: 'JetBrains Mono',
    value: 'JetBrains+Mono',
  },
  {
    label: 'Lato',
    value: 'Lato',
  },
  {
    label: 'Libre Franklin',
    value: 'Libre+Franklin',
  },
  {
    label: 'Lora',
    value: 'Lora',
  },
  {
    label: 'Manrope',
    value: 'Manrope',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Mukta',
    value: 'Mukta',
  },
  {
    label: 'Mulish',
    value: 'Mulish',
  },
  {
    label: 'Noto Sans',
    value: 'Noto+Sans',
  },
  {
    label: 'Nunito',
    value: 'Nunito',
  },
  {
    label: 'Oswald',
    value: 'Oswald',
  },
  {
    label: 'Open Sans',
    value: 'Open Sans',
  },
  {
    label: 'Playfair Display',
    value: 'Playfair+Display',
  },
  {
    label: 'Poppins',
    value: 'Poppins',
  },
  {
    label: 'Proza Libre',
    value: 'Proza+Libre',
  },
  {
    label: 'Quicksand',
    value: 'Quicksand',
  },
  {
    label: 'Raleway',
    value: 'Raleway',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Roboto Slab',
    value: 'Roboto+Slab',
  },
  {
    label: 'Rokkitt',
    value: 'Rokkitt',
  },
  {
    label: 'Rubik',
    value: 'Rubik',
  },
  {
    label: 'Sora',
    value: 'Sora',
  },
  {
    label: 'Source Sans Pro',
    value: 'Source+Sans+Pro',
  },
  {
    label: 'Space Grotesk',
    value: 'Space+Grotesk',
  },
  {
    label: 'Spectral',
    value: 'Spectral',
  },
  {
    label: 'Syne',
    value: 'Syne',
  },
  {
    label: 'Unbounded',
    value: 'Unbounded',
  },
  {
    label: 'Vollkorn',
    value: 'Vollkorn',
  },
  {
    label: 'Work Sans',
    value: 'Work+Sans',
  },
];
