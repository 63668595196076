/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthState = {
  error: string;
  signedIn: boolean;
  processingCallback: boolean;
};

const initialState: AuthState = {
  error: '',
  signedIn: false,
  processingCallback: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setSignedIn: (state, action: PayloadAction<boolean>) => {
      state.signedIn = action.payload;
    },
    setProcessingCallback: (state, action: PayloadAction<boolean>) => {
      state.processingCallback = action.payload;
    },
  },
});

export const { setAuthError, setSignedIn, setProcessingCallback } =
  authSlice.actions;

export const authReducer = authSlice.reducer;
