import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MaleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.4375 17.5C11.7167 17.5 14.375 14.8417 14.375 11.5625C14.375 8.28331 11.7167 5.625 8.4375 5.625C5.15831 5.625 2.5 8.28331 2.5 11.5625C2.5 14.8417 5.15831 17.5 8.4375 17.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 6.25V2.5H13.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6562 7.34375L17.5 2.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
