import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

type ChatBubbleProps = SvgIconProps;

export const ChatBubble = (props: ChatBubbleProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M2.833 18.2423C2.833 18.2456 2.833 18.2517 2.82925 18.2602C2.83066 18.2545 2.8316 18.2484 2.833 18.2423ZM20.6202 19.0125C20.4659 19.0092 20.3135 18.9774 20.1707 18.9188L17.5091 17.9752L17.4894 17.9672C17.4314 17.943 17.369 17.9309 17.3061 17.9316C17.2482 17.9316 17.1907 17.9421 17.1364 17.9625C17.0718 17.9869 16.4891 18.2058 15.8778 18.3736C15.5464 18.4645 14.3928 18.7613 13.4671 18.7613C11.1008 18.7613 8.88832 17.8519 7.23785 16.2005C6.43156 15.3991 5.79222 14.4458 5.35684 13.3957C4.92147 12.3456 4.6987 11.2196 4.70144 10.0828C4.70142 9.4905 4.76523 8.8999 4.89175 8.32125C5.30379 6.41719 6.39504 4.68516 7.96441 3.44625C9.57426 2.18346 11.5617 1.49805 13.6077 1.5C16.055 1.5 18.3355 2.4375 20.0296 4.13344C21.6308 5.73985 22.5083 7.85204 22.5013 10.0805C22.4999 11.7364 22.0055 13.3543 21.081 14.7281L21.0721 14.7413L21.0374 14.7881C21.0294 14.7989 21.0214 14.8092 21.0139 14.82L21.0069 14.8327C20.9883 14.8644 20.9714 14.8971 20.9563 14.9306L21.6941 17.5528C21.7267 17.661 21.7444 17.7731 21.7466 17.8861C21.7468 18.0341 21.7178 18.1806 21.6612 18.3174C21.6047 18.4541 21.5217 18.5784 21.4171 18.683C21.3125 18.7876 21.1882 18.8706 21.0515 18.9271C20.9147 18.9837 20.7682 19.0127 20.6202 19.0125Z" />
    <path d="M14.0564 19.94C14.008 19.8458 13.9398 19.7631 13.8566 19.6976C13.7734 19.6321 13.677 19.5853 13.5741 19.5603C13.3031 19.4886 12.9872 19.5008 12.7458 19.483C10.4664 19.3245 8.32145 18.3463 6.70734 16.7291C5.27237 15.3021 4.33979 13.4477 4.04999 11.4449C3.98437 10.9883 3.98437 10.8749 3.98437 10.8749C3.98434 10.7225 3.93793 10.5738 3.85131 10.4485C3.76468 10.3232 3.64196 10.2273 3.49947 10.1734C3.35697 10.1196 3.20146 10.1104 3.05362 10.1471C2.90578 10.1838 2.77262 10.2647 2.67187 10.3789C2.67187 10.3789 2.30249 10.7811 2.12812 11.1847C1.63906 12.3115 1.4305 13.5401 1.5203 14.7652C1.61011 15.9903 1.9956 17.1753 2.64374 18.2188C2.76562 18.4217 2.76562 18.5155 2.73843 18.7264C2.61046 19.3878 2.3864 20.5545 2.26968 21.1597C2.23103 21.356 2.24541 21.559 2.31132 21.7478C2.37724 21.9366 2.49231 22.1045 2.64468 22.2341L2.66624 22.2524C2.86781 22.4124 3.11759 22.4997 3.37499 22.4999C3.51977 22.5 3.66315 22.4715 3.79687 22.416L6.30515 21.448C6.39151 21.4152 6.48691 21.4152 6.57327 21.448C7.56093 21.8155 8.5889 22.0105 9.56296 22.0105C10.7766 22.0116 11.9756 21.745 13.0744 21.2295C13.3303 21.1095 13.7306 20.9792 13.965 20.7083C14.0537 20.6032 14.1096 20.4744 14.1259 20.3379C14.1421 20.2013 14.118 20.063 14.0564 19.94Z" />
  </SvgIcon>
);
