import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CalendarNumberIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.25 3.125H3.75C2.71447 3.125 1.875 3.96447 1.875 5V16.25C1.875 17.2855 2.71447 18.125 3.75 18.125H16.25C17.2855 18.125 18.125 17.2855 18.125 16.25V5C18.125 3.96447 17.2855 3.125 16.25 3.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5 1.875V3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 1.875V3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.125 6.25H1.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 10.1562L13.5711 8.90625H13.75V15.4688"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49473 11.9777C7.85059 11.9777 8.50215 11.8105 8.9291 11.3734C9.06894 11.2339 9.17945 11.0678 9.25412 10.885C9.32879 10.7021 9.3661 10.5061 9.36387 10.3086C9.36387 9.28828 8.48145 8.75 7.49316 8.75C6.65723 8.75 6.18379 9.20352 6.01465 9.48945"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82031 14.6154C6.01094 14.9385 6.59023 15.6248 7.53438 15.6248C8.65117 15.6248 9.57031 15.0021 9.57031 13.9131C9.57031 13.4201 9.42734 12.9756 9.11797 12.6604C8.63516 12.1666 7.89727 11.9775 7.49492 11.9775"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
