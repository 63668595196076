import { API } from 'src/utils/AmplifyApiUtils';

export default class UiClient {
  static uploadPortalConfig(data: { config: any; source?: string }) {
    let apiPath = '/portal/config';
    if (data.source) {
      apiPath = `${apiPath}?source=${data.source}`;
    }

    return API.post('AppAPI', apiPath, {
      body: data.config,
    });
  }

  static contactSupport(message: string, attachment: string) {
    return API.post('AppAPI', `/portal/support`, {
      body: {
        message,
        attachment,
      },
    });
  }
}
