import { Events } from '../constants/webSocketConsts/events';
import { subscriptionsApi } from '../services/api/subscriptionsApi';
import { ensureUnreachable } from '../utils/common_utils';
import { Dispatch } from 'redux';
import { Subscription } from '../types/subscriptions';

const updateCache = (
  event: Events,
  items: Subscription[],
  draft?: Subscription[],
) => {
  console.info('updateCache', { draft, event, items });

  if (!draft) {
    return;
  }

  items.forEach((subObj) => {
    const upcomingSubscriptionIndex = draft.findIndex(
      (item: Subscription) => item.id === subObj.id,
    );

    switch (event) {
      case Events.Insert: {
        if (upcomingSubscriptionIndex !== -1) {
          return;
        }

        draft.unshift(subObj);
        break;
      }
      case Events.Modify:
        if (upcomingSubscriptionIndex === -1) {
          draft.unshift(subObj);
          return;
        }
        draft.splice(
          0,
          draft.length,
          ...draft.map((item: Subscription) =>
            item.id === subObj.id ? subObj : item,
          ),
        );
        break;
      case Events.Remove:
        if (upcomingSubscriptionIndex === -1) {
          return;
        }
        draft.splice(upcomingSubscriptionIndex, 1);
        break;
      case Events.Refresh:
      case Events.DomainVerified:
        return;
      default:
        ensureUnreachable(event);
    }
  });
};

export function handleSubscriptionUpdate(
  event: Events,
  items: Subscription[],
  dispatch: Dispatch<any>,
) {
  dispatch(
    subscriptionsApi.util.updateQueryData(
      'getSubscriptions',
      undefined,
      (draft?: Subscription[]) => {
        updateCache(event, items, draft);
      },
    ),
  );

  // Updating cache for each subscription for subscription edit view
  items.forEach((item) => {
    dispatch(
      subscriptionsApi.util.updateQueryData(
        'getSubscriptionById',
        item.id,
        (draft?: Subscription) => {
          if (!draft) {
            return;
          }
          Object.assign(draft, item);
        },
      ),
    );
  });
}
