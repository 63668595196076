import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SVGIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.07031 13.1399H7.91193C7.90483 12.4599 7.36328 12 6.51811 12C5.68714 12 5.08878 12.4528 5.09411 13.1293C5.09233 13.6815 5.4794 13.9922 6.10795 14.136L6.48793 14.2248C6.88743 14.3171 7.06676 14.4254 7.07031 14.6296C7.06676 14.8516 6.85902 15.006 6.50568 15.006C6.11683 15.006 5.86115 14.8249 5.84162 14.4751H5C5.01065 15.3256 5.60192 15.7322 6.51634 15.7322C7.42188 15.7322 7.9581 15.3221 7.96165 14.6314C7.9581 14.0508 7.5657 13.6957 6.84837 13.5376L6.53587 13.4666C6.20561 13.3956 5.99609 13.2855 6.0032 13.0689C6.00497 12.87 6.17543 12.7262 6.51634 12.7262C6.85902 12.7262 7.04723 12.8807 7.07031 13.1399Z"
      fill="#212B36"
    />
    <path
      d="M9.23118 12.0497H8.24751L9.47443 15.6861H10.6445L11.8714 12.0497H10.8878L10.0746 14.7131H10.0444L9.23118 12.0497Z"
      fill="#212B36"
    />
    <path
      d="M14.4345 13.2376H15.324C15.2353 12.5131 14.589 12 13.7473 12C12.7761 12 12.0091 12.6836 12.0091 13.8732C12.0091 15.0238 12.7157 15.7358 13.758 15.7358C14.6937 15.7358 15.3631 15.1552 15.3631 14.1786V13.6974H13.7953V14.3384H14.5162C14.5073 14.7219 14.2409 14.9652 13.7651 14.9652C13.22 14.9652 12.9057 14.5621 12.9057 13.8626C12.9057 13.1683 13.2377 12.7706 13.7651 12.7706C14.1167 12.7706 14.3564 12.9428 14.4345 13.2376Z"
      fill="#212B36"
    />
  </SvgIcon>
);
