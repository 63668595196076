import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AirplaneIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.9267 8.75C15.7938 8.75 15.3489 8.75391 15.2235 8.76172L12.6884 8.82812C12.6752 8.82893 12.6621 8.82608 12.6504 8.81987C12.6388 8.81366 12.629 8.80435 12.6224 8.79297L7.56065 2.63906C7.52995 2.59842 7.49078 2.56494 7.44586 2.54092C7.40094 2.51691 7.35134 2.50295 7.30049 2.5H6.2501L9.10166 8.78906C9.10846 8.80346 9.1114 8.81938 9.11019 8.83525C9.10898 8.85113 9.10367 8.86642 9.09476 8.87962C9.08586 8.89281 9.07367 8.90347 9.0594 8.91054C9.04514 8.9176 9.02928 8.92084 9.01338 8.91992L4.25987 8.99023C4.2104 8.99173 4.16128 8.98155 4.11648 8.96051C4.07169 8.93947 4.03249 8.90817 4.00205 8.86914L2.55674 7.11133C2.43955 6.95898 2.22002 6.87695 2.02901 6.87695H1.29229C1.2419 6.87695 1.24932 6.92422 1.26299 6.97187L2.03799 9.76172C2.09657 9.91113 2.09657 10.0772 2.03799 10.2266L1.26221 13.0078C1.23916 13.084 1.2419 13.125 1.33135 13.125H2.03135C2.34932 13.125 2.39268 13.0836 2.55518 12.8789L4.02783 11.0938C4.05854 11.055 4.09779 11.0239 4.14252 11.0029C4.18725 10.9819 4.23624 10.9716 4.28565 10.9727L8.99971 11.0781C9.01687 11.0785 9.03368 11.0831 9.04868 11.0915C9.06367 11.0998 9.0764 11.1117 9.08575 11.1261C9.09511 11.1405 9.1008 11.157 9.10235 11.1741C9.1039 11.1912 9.10125 11.2084 9.09463 11.2242L6.2501 17.5H7.29072C7.34147 17.497 7.39098 17.4831 7.43583 17.4592C7.48067 17.4352 7.51979 17.4018 7.55049 17.3613L12.6228 11.2109C12.638 11.1875 12.7009 11.1758 12.7278 11.1758L15.2239 11.2422C15.3528 11.25 15.7938 11.2539 15.9271 11.2539C17.6563 11.2539 18.7501 10.7785 18.7501 10C18.7501 9.22148 17.661 8.75 15.9267 8.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
