import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FilesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M2.28564 7.49996V4.60713C2.28564 4.18089 2.45497 3.77211 2.75636 3.47072C3.05776 3.16932 3.46654 3 3.89277 3H6.9419C7.25923 3.00001 7.56944 3.09395 7.83346 3.27L8.95202 4.01571C9.21603 4.19175 9.52625 4.2857 9.84358 4.2857H16.107C16.5332 4.2857 16.942 4.45503 17.2434 4.75642C17.5448 5.05782 17.7141 5.4666 17.7141 5.89283V7.49996"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9959 8.88791L18.3442 15.5354C18.3442 15.9612 18.1753 16.3695 17.8745 16.6708C17.5737 16.9721 17.1657 17.1418 16.7399 17.1425H3.2601C2.83435 17.1418 2.42629 16.9721 2.1255 16.6708C1.82472 16.3695 1.65578 15.9612 1.65578 15.5354L1.00409 8.88791C0.989946 8.711 1.01258 8.53307 1.07058 8.36533C1.12858 8.19759 1.22067 8.04368 1.34107 7.91328C1.46147 7.78288 1.60756 7.67882 1.77015 7.60765C1.93273 7.53648 2.10829 7.49975 2.28578 7.49976H17.7182C17.8954 7.5003 18.0705 7.53744 18.2326 7.60886C18.3947 7.68027 18.5403 7.78441 18.6603 7.91475C18.7803 8.04509 18.872 8.19881 18.9297 8.36628C18.9875 8.53374 19.01 8.71133 18.9959 8.88791V8.88791Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
