import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BuildIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.3851 7.42193C15.1502 7.65534 14.8325 7.78634 14.5013 7.78634C14.1701 7.78634 13.8524 7.65534 13.6175 7.42193L12.5796 6.38403C12.346 6.14915 12.2148 5.83134 12.2148 5.50005C12.2148 5.16876 12.346 4.85095 12.5796 4.61607L14.9288 2.26567C14.9335 2.2612 14.9369 2.25565 14.9389 2.24951C14.9409 2.24337 14.9413 2.23684 14.9402 2.2305C14.939 2.22415 14.9363 2.21819 14.9323 2.21315C14.9283 2.20811 14.923 2.20415 14.9171 2.20161C13.4007 1.55161 11.4347 1.88286 10.1863 3.12231C8.99172 4.30786 8.92376 6.09107 9.36126 7.64732C9.42342 7.86852 9.42326 8.1026 9.36081 8.32373C9.29836 8.54486 9.17604 8.74443 9.00735 8.90044L2.49954 14.8438C2.30753 15.014 2.15237 15.2218 2.04361 15.4542C1.93484 15.6866 1.87476 15.9388 1.86706 16.1953C1.85936 16.4518 1.9042 16.7071 1.99883 16.9456C2.09346 17.1842 2.23588 17.4008 2.41733 17.5823C2.59878 17.7637 2.81542 17.9061 3.05394 18.0008C3.29246 18.0954 3.54782 18.1402 3.80431 18.1325C4.0608 18.1248 4.31301 18.0648 4.54542 17.956C4.77784 17.8472 4.98555 17.6921 5.15579 17.5001L11.1659 10.9766C11.32 10.8108 11.5162 10.6899 11.7337 10.6271C11.9511 10.5643 12.1816 10.5618 12.4003 10.62C13.9448 11.0336 15.7027 10.9559 16.8816 9.79224C18.1507 8.54224 18.4245 6.33286 17.8093 5.06841C17.8065 5.06288 17.8025 5.05809 17.7975 5.05443C17.7925 5.05078 17.7867 5.04835 17.7806 5.04736C17.7745 5.04637 17.7683 5.04684 17.7624 5.04873C17.7565 5.05063 17.7512 5.05389 17.7468 5.05825L15.3851 7.42193Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.75 16.875C4.09518 16.875 4.375 16.5952 4.375 16.25C4.375 15.9048 4.09518 15.625 3.75 15.625C3.40482 15.625 3.125 15.9048 3.125 16.25C3.125 16.5952 3.40482 16.875 3.75 16.875Z"
      fill="currentColor"
    />
  </SvgIcon>
);
