import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SuccessCheckmarkIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 23 24" {...props}>
    <path
      d="M11.4893 0.234375C5.17682 0.234375 0.0411682 5.37002 0.0411682 11.6825C0.0411682 17.9951 5.17682 23.1307 11.4893 23.1307C17.8019 23.1307 22.9375 17.9951 22.9375 11.6825C22.9375 5.37002 17.8019 0.234375 11.4893 0.234375ZM9.20085 16.7346L4.95014 12.4931L6.56663 10.872L9.19856 13.4994L15.2592 7.43871L16.878 9.05748L9.20085 16.7346Z"
      fill="#1E9A50"
    />
  </SvgIcon>
);
