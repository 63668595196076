import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const InfiniteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 10C10 10 8.125 6.25 5.07812 6.25C2.96406 6.25 1.25 7.92969 1.25 10C1.25 12.0703 2.96406 13.75 5.07812 13.75C6.54336 13.75 7.85156 12.8746 8.75 11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 10C10 10 11.875 13.75 14.9219 13.75C17.0359 13.75 18.75 12.0703 18.75 10C18.75 7.92969 17.0359 6.25 14.9219 6.25C13.4566 6.25 12.1484 7.12539 11.25 8.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
