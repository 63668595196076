import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SendNotificationIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.4394 2.56135C16.8544 1.97635 16.026 1.74635 15.221 1.94718L4.29367 4.67886C2.837 5.04303 1.86523 6.29634 1.87607 7.79801C1.8869 9.29968 2.87534 10.5388 4.337 10.883L8.20867 11.7939L9.11951 15.6647C9.46368 17.1263 10.7028 18.1147 12.2044 18.1255C12.2119 18.1255 12.2203 18.1255 12.2278 18.1255C13.7187 18.1255 14.9602 17.1563 15.3227 15.708L18.0545 4.78051C18.2544 3.97634 18.0253 3.14718 17.4394 2.56135ZM16.8411 4.47719L14.1093 15.4047C13.8352 16.4988 12.9511 16.8822 12.212 16.8755C11.5078 16.8705 10.5945 16.4755 10.3361 15.3789L9.41615 11.4697L12.9419 7.94302C13.1861 7.69885 13.1861 7.30302 12.9419 7.05885C12.6978 6.81468 12.3019 6.81468 12.0577 7.05885L8.53114 10.5855L4.62183 9.66551C3.52433 9.40718 3.12944 8.49302 3.12444 7.78886C3.11944 7.08469 3.50185 6.16468 4.59518 5.89135L15.5227 3.15967C15.6119 3.13717 15.7019 3.12636 15.7902 3.12636C16.0736 3.12636 16.3469 3.23802 16.5544 3.44552C16.8269 3.71719 16.9344 4.10302 16.8411 4.47719Z"
      fill="currentColor"
    />
  </SvgIcon>
);
