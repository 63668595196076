export const RegularCardShadow = '0px 0px 24px rgba(0, 0, 0, 0.07)';
export const DropdownMenuShadow = '0px 9px 24px rgba(0, 0, 0, 0.12)';
export const SmallCardShadow = '0px 2px 5px rgba(0, 0, 0, 0.03)';
export const SecondaryButtonShadow = '0px 1px 1px rgba(0, 0, 0, 0.07)';
export const ModalShadow = '0px 16px 70px rgba(0, 0, 0, 0.5)';
export const PrimaryButtonShadow = '0px 1px 2px rgba(0, 0, 0, 0.07)';
export const RegularButtonHoverShadow = '0px 1px 2px 1px rgba(0, 0, 0, 0.07)';
export const RegularInputShadow = '0px 2px 12px rgba(0, 0, 0, 0.12)';
export const RegularDialogShadow = '0px 4px 20px 5px rgba(0, 0, 0, 0.25)';
export const AvatarTextShadow = '0px 2px 2px rgba(0, 0, 0, 0.25)';
export const LayoutPreviewShadow = '0px 0px 22px rgba(0, 0, 0, 0.07)';
export const AuthCardShadow = '0px 0px 20px rgba(0, 0, 0, 0.12)';
export const CommandBarShadow = '0px 16px 70px rgba(0, 0, 0, 0.5)';
export const SidebarShadow = ' -5px 4px 15px rgba(0, 0, 0, 0.07);';
export const AdminCardShadow = '0px 0px 144px 0px #E3FFEE66;';
export const ClientAuthCardShadow = '0px 0px 60px rgba(0, 0, 0, 0.12)';
export const RightSidebarShadow = '-5px 4px 15px 0px rgba(0, 0, 0, 0.07)';
