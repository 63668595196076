export enum DomainSettingsAPiStatus {
  invalidStatus = 'domain_settings_api_invalid_status',
}

export enum DomainSettingsAPiErrors {
  invalidStatusError = 'Please wait a few minutes before submitting your domain.',
}
export enum SignaturePageImageErrors {
  corruptFileError = 'This file is corrupt, please use a different file.',
  fileNotFoundError = 'File is missing, please re-upload file.',
  fileSetupError = 'File setup failed. Contact support for further assistance',
}

export enum ContractErrorCode {
  clientInputRequired = 'contract_client_input_required',
  pdfPagesLimitError = 'contract_pdf_pages_num_err',
}

export enum ContractErrorMessage {
  clientInputRequired = 'Contracts must have at least one client input.',
  pdfPagesLimitError = 'PDF must have less than 50 pages.',
}

export enum PortalUrlValidation {
  valid = 'valid',
  invalid = 'portal_stack_invalid_name',
}

export enum FormValidationErrorCode {
  missingName = 'dynamic_form_missing_name',
  missingQuestion = 'dynamic_form_input_missing',
}

export enum FormValidationErrorMessage {
  missingName = 'Please add a Form title.',
  missingQuestion = 'Please add at least one question to your form.',
  missingTitle = 'Please add a title to your form before saving.',
}

export enum BillingErrorCode {
  noBankConnected = 'billing_no_bank_connected',
  noValidBankConnected = 'billing_no_valid_bank_connected',
  noPaymentMethod = 'billing_no_payment_method',
  cardDeclined = 'billing_card_declined',
  bankAccountExists = 'bank_account_exists',
  subBlockPaymentDelete = 'subscription_block_delete',
  amountTooSmall = 'amount_too_small',
  genericDeclineError = 'generic_decline',
}

export enum BillingErrorMessage {
  noBankConnected = 'Please go to Settings/Billing to connect your banking information',
  noValidBankConnected = 'Invalid banking information. Please recheck your banking information',
  noPaymentMethod = 'Payment method is required',
  cardDeclined = 'Credit card was declined',
  bankAccountExists = 'This payment method is already on file',
  subBlockPaymentDelete = 'You cannot remove a payment method that is tied to an active subscription.',
  amountTooSmall = 'Total amount must be at least $0.50',
  genericDecline = 'Payment method was declined',
}

export enum ModuleSettingsErrorCode {
  invalidModuleOrdering = 'portal_external_link_first_module',
}

export enum ModuleSettingsMessage {
  invalidModuleOrdering = 'First module cannot be external link',
}

export enum PaymentErrorCode {
  cardDeclined = 'card_declined',
}

export enum PaymentErrorMessage {
  cardDeclined = 'This card was declined, please verify your info or contact your provider',
}

export enum UserErrorCode {
  usernameExists = 'UsernameExistsException',
  accountExists = 'account_already_exists',
  passwordResetError = 'user_api_password_reset',
}

export enum UserErrorMessage {
  emailInUse = 'This email is already being used',
  passwordResetErr = 'Unable to reset password. Please try again or contact support.',
  invalidCredentials = 'Incorrect email or password.',
}

export enum CommonErrCode {
  invalidParameter = 'parameter_invalid',
}

export enum CommonErrMessages {
  invalidParameter = 'Invalid parameter on request',
}

export enum DashboardErrCode {
  invalidEmbedLink = 'dashboard_embed_link_invalid',
}

export enum DashboardErrorMessages {
  invalidEmbedLink = 'This link cannot be embedded, please use an embed code.',
}

export enum KnowledgeBaseErrorMessages {
  missingContent = 'Please add content to the article before saving.',
}

export enum EmbedErrors {
  errorRenderExtension = 'This extension could not be shown. Please verify the extension content is setup correctly.',
}

export enum ReferralErrors {
  invalidEmail = 'One of more emails is invalid',
  requiuredEmail = 'Please enter at least one valid email address',
}

export enum AuthErrorCodes {
  accountAlreadyExists = 'account_already_exists',
  googleAccountExists = 'google_account_already_exists',
}

export enum AuthErrors {
  inviteAndLoginMismatch = 'Cannot authenticate user. Google account did not match the email that was invited.',
  userRecordNotFound = 'No user found for the signed in email:',
  userRecordNotFoundAction = 'Click here to create a new account.',
  userSessionNotFound = 'No user session available.',
  emailAlreadyInUse = 'The email address is already used by another account.',
  loginExpired = 'Login expired. Please try again.',
  noLastName = 'Cannot login, please add a family name to the account.',
}
