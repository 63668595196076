import { IconType } from 'src/legacy/components/Icons';
import { BaseEntity } from 'src/constants/dataTypes/entityTypes';
import {
  ExtensionType,
  ModuleType,
} from 'src/constants/dataTypes/moduleDataTypes';

export interface CrmSettings {
  defaultLeadUserID?: string;
  defaultAssigneeIDs?: string[];
}

export interface CrmTableColumn {
  id: string;
  name: string;
  hidden: boolean;
}

export interface CrmTableSettings {
  columns?: CrmTableColumn[];
}

export interface BillingSettings {
  absorbTransactionFees?: boolean;
  allowPaymentViaACH?: boolean;
  allowPaymentViaCC?: boolean;
  absorbACHFees?: boolean;
  absorbCreditCardFee?: boolean;
  defaultMemo?: string;
}

export enum VisibilityRuleType {
  Client = 'client',
  Company = 'company',
  CustomField = 'customField',
  NoVisibility = 'noVisibility',
}

export enum VisibilityComparator {
  IncludesAnyOf = 'includesAnyOf',
  DoesntContainAnyOf = 'doesntContainAnyOf',
  IncludesAllOf = 'includesAllOf',
}

export type VisibilityRule =
  | {
      ruleType: VisibilityRuleType.NoVisibility;
    }
  | {
      ruleType: VisibilityRuleType.Client | VisibilityRuleType.Company;
      comparator: VisibilityComparator;
      ruleKey: string;
      values: string[];
    }
  | {
      ruleType: VisibilityRuleType.CustomField;
      ruleKey: string;
      comparator: VisibilityComparator;
      values: string[];
      customFieldId: string;
    };

export interface ModuleSettingsItem {
  id: string;
  label: string;
  icon: string;
  type: ModuleType;
  disabled: boolean;
  visibilityConfig?: VisibilityRule[];
}

export interface ItemsCount {
  CLIENT_USER: number;
  USER: number;
  STRIPE_INVOICE: number;
  STRIPE_SUBSCRIPTION: number;
  FILE_CHANNEL: number;
  INTAKE_FORM_TEMPLATE: number;
}

export interface QuickbooksSettings {
  accessToken?: string;
  refreshToken?: string;
  realmId: string;
}

export type ExtensionsSettings = Record<string, ExtensionConfig>;

export interface EmbedlyData {
  html?: string;
}

export interface ExtensionEmbed {
  embedCode?: string;
  embedLink?: string;
  embedlyData?: EmbedlyData;
  autoSizeEmbed?: boolean;
}

export interface ExtensionConfig extends ExtensionEmbed {
  type: ExtensionType;
  sort: number;
  icon: IconType;
  name: string;
  id?: string; // id is used in webapp to easily get id from config object
  embedCount?: number;
  isAuthenticated?: boolean;
  appId?: string;
  queryParams?: Record<string, string>[];
}

export interface FileSettings {
  clientFileDeleteDisabled?: boolean;
  clientFileAddDisabled?: boolean;
  clientFolderUpdateDisabled?: boolean;
}

export interface PortalSettings extends BaseEntity {
  fields: {
    crmSettings?: CrmSettings;
    crmTableSettings?: CrmTableSettings;
    billingSettings?: BillingSettings;
    moduleSettings?: ModuleSettingsItem[];
    itemsCount?: ItemsCount;
    quickbooksSettings?: QuickbooksSettings;
    extensionsSettings?: ExtensionsSettings;
    fileSettings?: FileSettings;
    minimumSeats?: number;
  };
}

export type MessagesSettings = any; // TODO: add type for messages settings

export type ContractSettings = {
  isEmailAttachmentExcluded: boolean;
};
