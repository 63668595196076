import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PuzzleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.8219 9.57158H15.6251C15.6021 9.57158 15.5801 9.56246 15.5639 9.54623C15.5477 9.53001 15.5385 9.50799 15.5385 9.48504V6.14291C15.5385 5.69696 15.3614 5.26928 15.0461 4.95394C14.7307 4.63861 14.303 4.46146 13.8571 4.46146H10.515C10.492 4.46146 10.47 4.45234 10.4538 4.43611C10.4375 4.41988 10.4284 4.39787 10.4284 4.37492V3.17808C10.4284 2.00375 9.49812 1.02067 8.32379 1.00033C8.03927 0.995332 7.7566 1.04705 7.49228 1.15245C7.22796 1.25786 6.98728 1.41486 6.78428 1.61427C6.58128 1.81369 6.42002 2.05154 6.30992 2.31394C6.19982 2.57634 6.14308 2.85804 6.14301 3.1426V4.37492C6.14301 4.39787 6.13389 4.41988 6.11766 4.43611C6.10143 4.45234 6.07942 4.46146 6.05647 4.46146H2.71434C2.26009 4.46283 1.82483 4.64388 1.50363 4.96509C1.18243 5.28629 1.00137 5.72154 1 6.17579V9.2211C1 9.24405 1.00912 9.26606 1.02535 9.28229C1.04158 9.29852 1.06359 9.30764 1.08654 9.30764H2.25092C3.52175 9.30764 4.57363 10.4101 4.59137 11.681C4.60954 12.9734 3.5741 14.1538 2.28554 14.1538H1.08654C1.06359 14.1538 1.04158 14.1629 1.02535 14.1792C1.00912 14.1954 1 14.2174 1 14.2404V17.2857C1.00137 17.7399 1.18243 18.1752 1.50363 18.4964C1.82483 18.8176 2.26009 18.9986 2.71434 19H5.75964C5.78259 19 5.80461 18.9909 5.82083 18.9747C5.83706 18.9584 5.84618 18.9364 5.84618 18.9135V18.0078C5.84618 16.6976 6.9171 15.5696 8.226 15.5389C9.52841 15.5086 10.6924 16.4177 10.6924 17.7145V18.9135C10.6924 18.9364 10.7015 18.9584 10.7177 18.9747C10.7339 18.9909 10.7559 19 10.7789 19H13.8571C14.303 19 14.7307 18.8228 15.0461 18.5075C15.3614 18.1922 15.5385 17.7645 15.5385 17.3185V13.9435C15.5385 13.9206 15.5477 13.8986 15.5639 13.8823C15.5801 13.8661 15.6021 13.857 15.6251 13.857H16.8574C18.0529 13.857 19.0001 12.8761 19.0001 11.6762C19.0001 10.4763 17.9962 9.57158 16.8219 9.57158V9.57158Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
