import React from 'react';
import OTPInput from 'react-otp-input';
import { Input, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ErrorText } from 'src/legacy/components/UI/Text/ErrorText';
import { MFA_CODE_LENGTH } from 'src/constants/authConsts';

const useStyles = makeStyles(() =>
  createStyles({
    otpInput: {
      margin: 'auto',
    },
    otpContainer: {
      margin: '10px',
      fontSize: '24px',
    },
    errorContainer: {
      float: 'right',
    },
  }),
);

interface VerifyOtpProps {
  value: string | undefined;
  onChange: (otp: string) => void;
  error: React.ReactNode;
  className?: string;
}

/**
 * OtpInput component that takes a 6 digit otp as input.
 * onChange prop is used to return the entered otp.
 * This is also responsible for rendering errors when errors are set
 * in the prop
 */
const OtpInput: React.FC<VerifyOtpProps> = ({
  value,
  onChange,
  error,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <OTPInput
        value={value}
        shouldAutoFocus
        onChange={onChange}
        numInputs={MFA_CODE_LENGTH}
        renderSeparator={(index) => <span>{index === 2 ? '-' : ''}</span>}
        renderInput={(props) => (
          <Input
            className={classes.otpContainer}
            fullWidth
            inputProps={{
              ...props,
              className: clsx(props.className, classes.otpInput),
            }}
          />
        )}
        inputType="tel"
      />
      <div className={classes.errorContainer}>
        <ErrorText>{error}</ErrorText>
      </div>
    </div>
  );
};

export default OtpInput;
