/**
 * Magic links give a client access to a portal without a password.
 * This dialog allows internal users to generate a magic link for a client.
 */

import * as React from 'react';
import { Client } from 'src/constants';
import { Body, Button, Heading, IconButton } from 'copilot-design-system';
import { Dialog } from '@material-ui/core';
import {
  CreateMagicLinkRequest,
  useCreateMagicLinkMutation,
} from 'src/services/api/magicLinksApi';
import { useAppDispatch } from 'src/hooks/useStore';
import { alertSnackbar } from 'src/store/ui/actions';
import { Callout } from 'src/components/Callout';
import { RowDivider } from 'src/components/RowDivider';
const ENTITY_TYPE_LABEL = {
  CLIENT_USER: 'magic',
  INVOICE: 'payment',
  CONTRACT: 'eSign',
  FORM_TEMPLATE: 'form',
} as const;

const ENTITY_TYPE_SECURITY_DESC = {
  CLIENT_USER: '',
  INVOICE: 'pay this invoice',
  CONTRACT: 'sign this contract',
  FORM_TEMPLATE: 'fill out this form',
};
export type ShareTypes = { email: boolean; link: boolean };

export function CreateClientMagicLinkDialog({
  clients,
  entityType = 'CLIENT_USER',
  nextPath = '/',
  onCancel,
  onSuccess,
  open,
  shareTypes,
  showOnboardingCallout = false,
}: {
  clients: Client[];
  entityType?: CreateMagicLinkRequest['entityType'];
  nextPath?: CreateMagicLinkRequest['nextPath'];
  onCancel: () => void;
  onSuccess: () => void;
  open: boolean;
  shareTypes: ShareTypes;
  showOnboardingCallout?: boolean;
}) {
  const [createMagicLink] = useCreateMagicLinkMutation();
  const dispatch = useAppDispatch();
  const entityCopy = ENTITY_TYPE_SECURITY_DESC[entityType];
  const singleClient = clients.length === 1 ? clients.at(0) : null;
  const label = ENTITY_TYPE_LABEL[entityType];
  const shareCopy =
    shareTypes.email && shareTypes.link
      ? 'Share'
      : shareTypes.email
      ? 'Email'
      : 'Copy';
  const clientsString = clients
    .map((client, idx) => {
      let prefix = '';
      if (clients.length > 2 && idx !== 0) {
        prefix = prefix + ', ';
      }
      if (clients.length > 1 && idx === clients.length - 1) {
        prefix = prefix + `${clients.length === 2 ? ' ' : ''}and `;
      }
      return `${prefix}${client.fields.givenName} ${client.fields.familyName} (${client.fields.email})`;
    })
    .join('');

  const emailContentsDescription =
    clients.length > 1 ? 'single-use magic links' : 'a single-use magic link';

  const handleCreateLink = async (type: 'link' | 'email') => {
    if (singleClient) {
      try {
        const response = await createMagicLink({
          entityType,
          nextPath,
          type,
          userId: singleClient.id,
        }).unwrap();
        if (response.linkUrl) {
          await navigator.clipboard.writeText(response.linkUrl);
          dispatch(
            alertSnackbar({ successMessage: 'Link copied to clipboard' }),
          );
        } else {
          dispatch(
            alertSnackbar({
              successMessage: `Link sent to ${singleClient.fields.email}`,
            }),
          );
        }
        onSuccess();
      } catch (error) {
        dispatch(
          alertSnackbar({
            errorMessage: 'Failed to create magic link, please try again',
          }),
        );
        console.error('Failed to create magic link:', error);
      }
    } else {
      // when multiple clients are created
      // for each client send a magic link email
      try {
        await Promise.all(
          clients.map((client) =>
            createMagicLink({
              entityType,
              nextPath,
              type,
              userId: client.id,
            }),
          ),
        );

        dispatch(
          alertSnackbar({
            successMessage: `Emails sent to all ${clients.length} clients`,
          }),
        );
        onSuccess();
      } catch (error) {
        dispatch(
          alertSnackbar({
            errorMessage: 'Failed to create magic link, please try again',
          }),
        );
        console.error('Failed to create magic link:', error);
      }
    }
  };

  return (
    <Dialog classes={{ paper: 'max-w-[470px]' }} onClose={onCancel} open={open}>
      <div className="max-w-[470px]">
        <header className="px-5 py-4 border-0 border-b border-gray-100 border-solid flex justify-between items-center">
          <Heading size="lg">
            {shareCopy} {label} link{clients.length > 1 ? 's' : ''}
          </Heading>
          <IconButton icon="Close" onClick={onCancel} variant="minimal" />
        </header>
        <main className="px-5 py-4">
          {showOnboardingCallout && (
            <Callout
              status="info"
              label="The quickest way to try out the client experience is to copy the magic link, paste it in a new browser tab, and activate the client account."
              className="mb-2.5"
            />
          )}

          {shareTypes.email && (
            <>
              <Button
                data-testid="email-access-link"
                className={['w-full', 'mb-2'].join(' ')}
                label={`Email magic link${clients.length > 1 ? 's' : ''}`}
                onClick={async () => {
                  await handleCreateLink('email');
                }}
                variant="secondary"
              />

              <>
                {!entityCopy && !shareTypes.link && (
                  <Body size="base">
                    <span className="font-medium">{`${clientsString}`}</span>
                    {` will receive ${emailContentsDescription} that provides full access to their account${
                      clients.length > 1 ? 's' : ''
                    }.`}
                  </Body>
                )}
                {entityCopy && !shareTypes.link && (
                  <Body size="base">
                    <span className="font-medium">{`${clientsString}`}</span>
                    {` will receive ${emailContentsDescription} that let${
                      clients.length > 1 ? '' : 's'
                    } them ${entityCopy} and provides full access to their account`}
                  </Body>
                )}
              </>
            </>
          )}

          {shareTypes.email && shareTypes.link && (
            <RowDivider className="my-5">
              <Body size="xs">OR</Body>
            </RowDivider>
          )}

          {shareTypes.link && singleClient && (
            <>
              <Button
                className={['w-full', 'mb-2'].join(' ')}
                label={`Copy ${label} link`}
                onClick={async () => {
                  await handleCreateLink('link');
                }}
                variant="secondary"
              />
              {/* Only show the copy magic link option when only one client is created since 
              /* we don't support multi magic link copy to clipboard */}
              {
                <>
                  {!entityCopy && !shareTypes.email && (
                    <Body size="base">
                      {`This single-use magic link gives the recipient full access to `}
                      <span className="font-medium">
                        {`${singleClient.fields.givenName} ${singleClient.fields.familyName}'s (${singleClient.fields.email}) `}
                      </span>
                      {`account. Treat it like a password and share it only with the intended recipient.`}
                    </Body>
                  )}
                  {entityCopy && !shareTypes.email && (
                    <Body size="base">
                      {`This single-use magic link lets the recipient ${entityCopy} and provides full access to `}
                      <span className="font-medium">
                        {`${singleClient.fields.givenName} ${singleClient.fields.familyName}'s (${singleClient.fields.email}) `}
                      </span>
                      {`account. Treat it like a password and share it only with the intended recipient.`}
                    </Body>
                  )}
                </>
              }
            </>
          )}
          {singleClient && shareTypes.email && shareTypes.link && (
            <Body size="base">
              {`A single use magic link provides full access to `}
              <span className="font-medium">
                {`${singleClient.fields.givenName} ${singleClient.fields.familyName}'s (${singleClient.fields.email}) `}
              </span>
              {`account. Treat it like a password and share it only with the intended recipient.`}
            </Body>
          )}
        </main>
        <footer className="px-5 py-4 flex justify-end border-0 border-t border-gray-100 border-solid">
          <Button label="Cancel" onClick={onCancel} variant="secondary" />
        </footer>
      </div>
    </Dialog>
  );
}
