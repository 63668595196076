import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AccessibilityIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 3.75C10.8629 3.75 11.5625 3.05044 11.5625 2.1875C11.5625 1.32456 10.8629 0.625 10 0.625C9.13706 0.625 8.4375 1.32456 8.4375 2.1875C8.4375 3.05044 9.13706 3.75 10 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M7.97763 10.7202C8.09091 10.0148 8.14169 9.33273 7.9581 8.86125C7.80185 8.45578 7.46201 8.22882 7.05185 8.07414L3.4374 6.9046C2.96865 6.74835 2.53076 6.48663 2.4999 5.96945C2.46083 5.30538 3.04677 4.8757 3.63271 5.03195C3.63271 5.03195 7.07021 6.24835 9.9999 6.24835C12.9296 6.24835 16.328 5.03742 16.328 5.03742C17.0311 4.8421 17.4999 5.38898 17.4999 5.96671C17.4999 6.52179 17.0702 6.7171 16.5624 6.90187L13.1249 8.14835C12.8124 8.26554 12.3046 8.49992 12.1093 8.85851C11.8749 9.27843 11.914 10.012 12.0272 10.7175L12.2577 11.8749L13.719 18.2773C13.8284 18.7909 13.4729 19.271 12.9612 19.3609C12.4495 19.4507 12.0311 19.1015 11.8796 18.6046L10.4124 14.0761C10.3418 13.8589 10.2793 13.6392 10.2249 13.4171L9.9999 12.4999L9.79287 13.3456C9.72724 13.6152 9.65172 13.8818 9.5663 14.1456L8.1249 18.6007C7.96865 19.1027 7.55849 19.4503 7.04677 19.3609C6.53505 19.2714 6.17177 18.7519 6.28662 18.2773L7.74716 11.8777L7.97763 10.7202Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
