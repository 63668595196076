import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  SignatureComponentType,
} from 'src/constants';

import {
  SignaturePageActions,
  SignaturePageActionTypes,
  SignaturePageState,
  SignaturePageImageData,
  ESignatureFlowStatus,
  SignaturePageComponent,
} from 'src/store/signaturePage/types';

export const InternalUserSignatureSteps = {
  MySignature: 1,
  ClientSignature: 2,
  Submit: 3,
};

const initialState: SignaturePageState = {
  channelId: '',
  fileId: '',
  fileIdentityId: '',
  fileKey: '',
  fileName: '',
  startedEsignFlow: false,
  currentStep: 0,
  totalSteps: 0,
  pageImages: [],
  pageComponents: [],
  initialPageComponents: [],
  pagePlaceholderComponents: [],
  loading: false,
  loaded: false,
  completeRenderPageImages: false,
  pageImageKeysField: [],
  error: '',
  pageImageStatus: '',
  activeComponentKey: '',
  saving: false,
  esignStatus: '',
  isEsignPopoverOpen: false,
  resetComponentsReference: false,
  signatureFlowStatus: ESignatureFlowStatus.START_SIGNATURE,
  selectedComponentKey: '',
  isSignatureButtonClickAwayDisabled: false,
};

const getComponentPage = (
  data: { node: { offsetHeight: number }; y: number },
  pageImages: SignaturePageImageData[],
) => {
  let firstPageOffsetTop = 0;
  if (pageImages.length > 0) {
    firstPageOffsetTop = pageImages.at(0)?.element?.y || 0;
  }

  let _pageNumber;
  pageImages.forEach(({ element, pageNumber }) => {
    if (!element) {
      return;
    }

    const { y: pageY, offsetHeight: pageHeight } = element;
    const componentOffsetTop = data.y + firstPageOffsetTop;

    if (
      componentOffsetTop > pageY && // top of component is below page
      componentOffsetTop + data.node.offsetHeight <= pageY + pageHeight // bottom of component is above page
    ) {
      // console.info('Component is part of page', pageImage.pageNumber);
      _pageNumber = pageNumber;
    }
  });
  if (!_pageNumber) {
    console.info('Page number not determined for', data, pageImages);
  }
  return _pageNumber;
};

const SignaturePageReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: SignaturePageActions,
) => {
  switch (action.type) {
    case SignaturePageActionTypes.GET_PAGES:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case SignaturePageActionTypes.GET_PAGES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case SignaturePageActionTypes.GET_PAGES_SUCCESS: {
      /**
       * Based on if the current user is a client/internal
       * setup the signature sidebar and components.
       * Internal users have steps based on InternalUserSignatureSteps
       * For clients. The total steps is based on the number of request
       * components that are assigned to this client user
       */
      const progressState = {
        currentStep: InternalUserSignatureSteps.MySignature,
        totalSteps: Object.keys(InternalUserSignatureSteps).length,
      };
      if (action.isClient) {
        const requestComponents = action.updatedState.pageComponents?.filter(
          (c) => c.receiverUserId === action.currentUserId,
        );
        const completedRequestComponents = requestComponents?.filter(
          (c) => c.value,
        );
        progressState.currentStep = completedRequestComponents?.length || 0;
        progressState.totalSteps = requestComponents?.length || 0;
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        initialPageComponents: action.updatedState.pageComponents,
        ...action.updatedState,
        ...progressState,
      };
    }
    case SignaturePageActionTypes.CHANGE_ESIGN_FLOW_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SignaturePageActionTypes.ADD_COMPONENT: {
      const { component } = action;
      const updatedComponent = { ...component };
      updatedComponent.page = getComponentPage(
        {
          y: component.yPosition,
          node: {
            offsetHeight: CONTRACT_COMPONENT_DEFAULT_HEIGHT, // the DOM height of eSig component
          },
        },
        state.pageImages,
      );

      return {
        ...state,
        pageComponents: [...state.pageComponents, updatedComponent],
      };
    }
    case SignaturePageActionTypes.UPDATE_COMPONENT: {
      const { data, component } = action;
      const updatedComponent = { ...component };
      updatedComponent.page = getComponentPage(data, state.pageImages);

      const componentsStaged = state.pageComponents.map((c) => {
        if (c.key === updatedComponent.key) {
          return {
            ...c,
            xPosition: updatedComponent.xPosition,
            yPosition: updatedComponent.yPosition,
            page: updatedComponent.page,
          };
        }

        return c;
      });

      return {
        ...state,
        pageComponents: [...componentsStaged],
      };
    }
    case SignaturePageActionTypes.UPDATE_COMPONENT_DIMENSION: {
      const { component } = action;
      const updatedComponent = { ...component };

      const componentsStaged = state.pageComponents.map((c) => {
        if (c.key === updatedComponent.key) {
          return {
            ...c,
            height: updatedComponent.height,
            width: updatedComponent.width,
            page: updatedComponent.page,
          };
        }

        return c;
      });

      return {
        ...state,
        pageComponents: [...componentsStaged],
      };
    }
    case SignaturePageActionTypes.UPDATE_COMPONENT_LABEL: {
      const { label, componentKey, isOptional } = action;
      const componentsStaged = state.pageComponents.map((c) => {
        if (c.key === componentKey) {
          return {
            ...c,
            label,
            isOptional,
          };
        }

        return c;
      });

      return {
        ...state,
        pageComponents: [...componentsStaged],
      };
    }
    case SignaturePageActionTypes.COMPLETE_RECEIVER_COMPONENT: {
      const pageComponentsChanged = state.pageComponents.map((component) => {
        let componentStaged = component;

        if (component.key === action.originalComponentKey) {
          componentStaged = {
            ...action.component,
            key: action.originalComponentKey,
          };
        }
        return componentStaged;
      });

      return {
        ...state,
        pageComponents: pageComponentsChanged,
        // increment current step when receiver component is completed with value
        currentStep: state.currentStep + (action.component.value ? 1 : 0),
      };
    }
    case SignaturePageActionTypes.INSERT_EVERYWHERE: {
      const pageComponentsChanged = state.pageComponents.map((component) => {
        let componentStaged = component;
        if (component.componentType !== action.component.componentType)
          return component;

        componentStaged = {
          ...component,
          value: action.component.value,
          name: action.component.name,
          resizedWidth: action.component.resizedWidth,
          resizedHeight: action.component.resizedHeight,
          imageTextValue: action.component.imageTextValue,
        };
        if (
          component.componentType === SignatureComponentType.REQUEST_INITIAL ||
          component.componentType === SignatureComponentType.REQUEST_SIGN
        ) {
          componentStaged.isAgree = action.component.isAgree;
        }
        return componentStaged;
      });

      return {
        ...state,
        pageComponents: pageComponentsChanged,
      };
    }
    case SignaturePageActionTypes.SKIP_COMPONENT: {
      const pageComponentsChanged = state.pageComponents.map((component) => {
        let componentStaged = component;

        if (component.key === action.componentKey) {
          componentStaged = {
            ...component,
            key: action.componentKey,
            isSkipped: true,
            value: '', // reset value if user is skipping already filled component, without this user cannot clear/skip already filled text component
          };
        }
        return componentStaged;
      });

      return {
        ...state,
        pageComponents: pageComponentsChanged,
      };
    }
    case SignaturePageActionTypes.SETUP_PAGE_IMAGE: {
      const unchangedImages = state.pageImages.filter(
        (p) => p.key !== action.pageImageData.key,
      );
      const updatedImages = [...unchangedImages, action.pageImageData];
      updatedImages.sort((a, b) => (a.pageNumber > b.pageNumber ? 1 : -1));
      return {
        ...state,
        pageImages: updatedImages,
        completeRenderPageImages: updatedImages.every(
          (pageImage) => pageImage.loaded,
        ),
      };
    }
    case SignaturePageActionTypes.SETUP_PAGE_IMAGE_HEIGHT: {
      const updatePageImages = state.pageImages.map((p) => {
        if (p.key === action.key) {
          return { ...p, element: { ...p.element, y: action.y } };
        }

        return p;
      });

      return {
        ...state,
        pageImages: updatePageImages,
      };
    }
    case SignaturePageActionTypes.REMOVE_COMPONENT: {
      // We only need to update labels incremently for client inputs
      const updateLabel = (
        component: SignaturePageComponent,
        index: number,
      ) => {
        const labelPrefix =
          component.componentType === SignatureComponentType.REQUEST_INITIAL
            ? 'initial'
            : component.componentType === SignatureComponentType.REQUEST_SIGN
            ? 'signature'
            : // Date is an exception because it is both a client input and a variable input
            // So we need to place an extra check for date inputs
            component.componentType === SignatureComponentType.REQUEST_DATE &&
              component.inputType === 'client'
            ? 'date'
            : '';

        return {
          ...component,
          label: labelPrefix ? `${labelPrefix}_${index + 1}` : component.label,
        };
      };

      const updateLabels = (components: SignaturePageComponent[]) => {
        const buckets: Record<string, SignaturePageComponent[]> = {};

        components.forEach((component) => {
          const key = `${component.componentType}_${component.inputType}`;
          if (!buckets[key]) {
            buckets[key] = [];
          }

          buckets[key].push(component);
        });

        const updatedComponents: SignaturePageComponent[] = [];

        Object.values(buckets).forEach((bucket) => {
          bucket.forEach((component, index) => {
            updatedComponents.push(updateLabel(component, index));
          });
        });

        return updatedComponents;
      };

      const unchangedComponents = state.pageComponents.filter(
        (c) => c.key !== action.componentKey,
      );

      let updatedComponents = unchangedComponents;

      if (action.forContracts) {
        // For contracts, we assign labels to components based on index.
        // After removing a component, we need to update the labels of the remaining components based on their index and component type.
        // Label needs to be index + 1 of specific component type
        updatedComponents = updateLabels(unchangedComponents);
      }

      return {
        ...state,
        pageComponents: updatedComponents,
      };
    }
    case SignaturePageActionTypes.REMOVE_COMPONENT_VALUE: {
      const pageComponentsChanged = state.pageComponents.map((component) => {
        let componentStaged = component;
        if (component.key === action.componentKey) {
          componentStaged = { ...component, value: '' };
        }
        return componentStaged;
      });

      return {
        ...state,
        currentStep: state.currentStep - 1,
        pageComponents: pageComponentsChanged,
        resetComponentsReference: true,
      };
    }
    case SignaturePageActionTypes.SET_ACTIVE_COMPONENT: {
      return {
        ...state,
        activeComponentKey: action.componentKey,
      };
    }
    case SignaturePageActionTypes.REMOVE_ACTIVE_COMPONENT: {
      return {
        ...state,
        activeComponentKey: '',
      };
    }
    case SignaturePageActionTypes.SET_SELECTED_COMPONENT: {
      return {
        ...state,
        selectedComponentKey: action.componentKey,
      };
    }
    case SignaturePageActionTypes.REMOVE_SELECTED_COMPONENT: {
      return {
        ...state,
        selectedComponentKey: '',
      };
    }
    case SignaturePageActionTypes.START_SAVING_COMPONENTS:
      return {
        ...state,
        saving: true,
      };

    case SignaturePageActionTypes.STOP_SAVING_COMPONENTS: {
      return {
        ...state,
        saving: false,
      };
    }
    case SignaturePageActionTypes.RESET_ESIGN_TO_INITIAL_VALUES: {
      return {
        ...initialState,
      };
    }
    case SignaturePageActionTypes.ADD_PLACEHOLDER_COMPONENT: {
      const { component } = action;
      const componentStaged = { ...component };
      return {
        ...state,
        pagePlaceholderComponents: [
          ...state.pagePlaceholderComponents,
          componentStaged,
        ],
        isEsignPopoverOpen: true,
      };
    }
    case SignaturePageActionTypes.REMOVE_PLACEHOLDER_COMPONENT: {
      return {
        ...state,
        pagePlaceholderComponents: [],
        isEsignPopoverOpen: false,
      };
    }
    case SignaturePageActionTypes.TOGGLE_RESET_COMPONENTS_REFERENCE: {
      return {
        ...state,
        resetComponentsReference: false,
      };
    }
    case SignaturePageActionTypes.UPDATE_PAGE_COMPONENTS_PLACEMENT: {
      const { pageComponents } = action;
      return {
        ...state,
        pageComponents,
      };
    }
    case SignaturePageActionTypes.START_FILLING_SIGNATURES: {
      return {
        ...state,
        signatureFlowStatus: ESignatureFlowStatus.FILL_SIGNATURE,
      };
    }
    case SignaturePageActionTypes.UPDATE_CLIENT_SIGNATURE_FLOW_STATUS: {
      const { status } = action;
      return {
        ...state,
        signatureFlowStatus: status,
      };
    }
    case SignaturePageActionTypes.ENABLE_COMPONENT_CLICKAWAY: {
      return {
        ...state,
        isSignatureButtonClickAwayDisabled: false,
      };
    }
    case SignaturePageActionTypes.DISABLE_COMPONENT_CLICKAWAY: {
      return {
        ...state,
        isSignatureButtonClickAwayDisabled: true,
      };
    }
    case SignaturePageActionTypes.SET_PAGE_COMPONENTS: {
      return {
        ...state,
        pageComponents: action.pageComponents,
      };
    }
    default:
      return state;
  }
};

export default SignaturePageReducer;
