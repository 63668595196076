import { HoverBackground, BlackHeadings, BlackSmall } from 'src/theme/colors';

export default {
  root: {
    minHeight: 'unset',
    padding: '6px 16px',
    color: BlackSmall,
    '&:hover': {
      color: BlackHeadings,
      background: HoverBackground,
    },
    '&.MuiListItem-button': {
      height: 'auto',
    },
  },
};
