import React from 'react';
import { Action, useRegisterActions } from 'kbar';
import { registerShortcuts } from 'src/store/shortcuts/slice';
import { useAppSelector, useAppDispatch } from 'src/hooks/useStore';
/**
 * This hook is a refactored/cleaned up version
 * of the original useKeyboardShortcuts hook.
 * @param shortcutActions
 */
export const useKeyboardShortcuts = (shortcutActions: Array<Action>) => {
  const registeredShortcuts = useAppSelector(
    (state) => state.shortcuts.registeredShortcuts,
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    // update registered shortcuts
    shortcutActions.forEach((action) => {
      const actionShortcut = action.shortcut?.join('+');
      if (!actionShortcut) return;
      if (!registeredShortcuts[actionShortcut]) {
        dispatch(registerShortcuts({ [actionShortcut]: true }));
      }
    });
  }, [shortcutActions]);

  // register keyboard shortcuts to kbar state
  useRegisterActions(shortcutActions, [shortcutActions]);
};
