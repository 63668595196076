import { useEffect, useRef, useState } from 'react';

export type Rect = Pick<DOMRectReadOnly, 'width' | 'height' | 'x' | 'y'>;

export function useResizeObserver<ElementType extends HTMLElement>() {
  const ref = useRef<ElementType | null>(null);
  const [rect, setRect] = useState<Rect>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  } as const);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const {
        width = 0,
        height = 0,
        x = 0,
        y = 0,
      } = entries.at(0)?.contentRect ?? {};
      setRect({ width, height, x, y });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return { ref, rect };
}
