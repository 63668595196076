import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ShopIcon = (
  <SvgIcon
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    stroke="#000"
    enableBackground="new 0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M488.468,448.903h-8.532V226.4C499.202,214.003,512,192.386,512,167.822c0-1.305-0.341-2.588-0.988-3.721L451.499,59.953
			c-4.18-7.313-12.009-11.857-20.432-11.857H80.933c-8.423,0-16.252,4.543-20.432,11.857L0.988,164.101
			C0.341,165.234,0,166.518,0,167.822c0,24.564,12.798,46.181,32.064,58.578v222.503h-8.532c-4.142,0-7.5,3.358-7.5,7.5
			c0,4.142,3.358,7.5,7.5,7.5h464.936c4.143,0,7.5-3.358,7.5-7.5C495.968,452.261,492.61,448.903,488.468,448.903z M15.517,175.322
			h24.044c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H20.424l53.101-92.927c1.516-2.652,4.354-4.299,7.408-4.299h350.134
			c3.054,0,5.893,1.647,7.408,4.299l53.1,92.927h-19.141c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h24.048
			c-3.667,26.584-26.532,47.125-54.108,47.125c-27.575,0-50.429-20.543-54.097-47.125h52.096c4.143,0,7.5-3.358,7.5-7.5
			c0-4.142-3.357-7.5-7.5-7.5H71.631c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h52.091
			c-3.668,26.582-26.523,47.125-54.097,47.125C42.049,222.447,19.184,201.906,15.517,175.322z M372.222,175.322
			c-3.668,26.582-26.523,47.125-54.097,47.125c-27.575,0-50.429-20.543-54.097-47.125H372.222z M247.972,175.322
			c-3.668,26.582-26.523,47.125-54.097,47.125c-27.574,0-50.429-20.543-54.097-47.125H247.972z M424.854,448.904h-81.193v-25.081
			h81.193V448.904z M424.854,408.823h-81.193V271.516h81.193V408.823z M464.936,448.904h-25.081V264.016c0-4.142-3.357-7.5-7.5-7.5
			h-96.193c-4.143,0-7.5,3.358-7.5,7.5v184.887H47.064V233.674c7.081,2.433,14.665,3.773,22.561,3.773
			c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207
			c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207
			c7.896,0,15.48-1.34,22.561-3.772V448.904z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M296.081,256.516H79.645c-4.142,0-7.5,3.358-7.5,7.5v152.307c0,4.142,3.358,7.5,7.5,7.5h216.436
			c4.143,0,7.5-3.358,7.5-7.5V264.016C303.581,259.873,300.224,256.516,296.081,256.516z M288.581,408.823H87.145V271.516h201.436
			V408.823z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </SvgIcon>
);
