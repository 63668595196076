import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Box, createStyles, IconButton, makeStyles } from '@material-ui/core';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import {
  QuickTextEditor,
  QuickTextEditorProps,
} from 'src/legacy/components/TextEditor/QuickTextEditor';
import { EditableCellValueWrapper } from 'src/legacy/components/UI/Tables/EditableCellWrapper';
import {
  BlackHeadings,
  HoverNonBorderTableEditableItemBackground,
} from 'src/theme/colors';
import { COPYABLE_FIELDS_TYPES } from 'src/constants';
import {
  AddressCustomField,
  ClientTableRowModel,
  CustomFieldOption,
  MultiSelectCustomField,
} from 'src/store/clients/types';
import {
  getFieldValidationByType,
  getFieldInputComponent,
  formatFieldValue,
} from 'src/utils/CustomFieldsUtils';
import {
  CustomFieldValue,
  CustomFieldValueData,
  isAddressCustomFieldValues,
} from 'src/legacy/components/ClientDetailsPage/clientDetailsTypes';
import { ExternalLinkIcon } from 'src/legacy/components/Icons';
import { UrlUtils } from 'src/utils';
import { MultiSelect } from 'src/legacy/components/MultiSelect';
import { MapFieldTypeToDefaultProps } from 'src/legacy/components/CustomFieldsMenu';
import AddressInput from 'src/legacy/components/AddressInput';

const useStyles = makeStyles(() =>
  createStyles({
    fieldIconBtn: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: HoverNonBorderTableEditableItemBackground,
      },
      '& svg': {
        color: BlackHeadings,
        fontSize: '14px',
        fill: 'none',
      },
    },
  }),
);
type CustomFieldsType = {
  [key: string]: CustomFieldValue | null | undefined;
};
export interface CustomFieldsRendererProps extends ICellRendererParams {
  customFieldData: MultiSelectCustomField | CustomFieldOption;
  data: ClientTableRowModel & CustomFieldsType;
  setIsEdit?: (isEdit: boolean) => void;
  onCustomFieldValueUpdated: (customFieldData: CustomFieldValueData) => void;
  onCustomFieldPropertyUpdated: (
    customFieldProperty: CustomFieldOption,
  ) => void;
}
export const CustomFieldsRenderer: React.FC<CustomFieldsRendererProps> =
  React.memo(
    ({
      customFieldData: field,
      onCustomFieldValueUpdated,
      onCustomFieldPropertyUpdated,
      data,
      setIsEdit = () => null,
    }) => {
      // when multi select has empty tags, it notify the custom fields
      // renderer component in order to render field icon.
      const [forceShowFieldIcon, setForceShowFieldIcon] = React.useState(false);
      const { FieldIcon, inputComponent } = React.useMemo(
        () => ({
          FieldIcon: MapFieldTypeToDefaultProps[field.type].icon,
          inputComponent: getFieldInputComponent(field.type),
        }),
        [field.type],
      );
      const [editable, setEditable] = React.useState(false);
      const cellValue = React.useMemo(() => data[field.id], [data]);

      const classes = useStyles();
      /**
       * This function handle saving new custom field value
       * @param newValue custom field value to submit
       */
      const handleUpdateCustomFieldValue = (newValue: string) => {
        if (!field.id) {
          return;
        }
        const value = newValue?.trim();
        // if both values are falsey or
        // if value has not changed then nothing to update
        const skipUpdateCall = (!value && !cellValue) || value === cellValue;
        if (!skipUpdateCall) {
          onCustomFieldValueUpdated({
            fieldId: field.id,
            value,
            updatingClient: data.clientFullData,
          });
        }
        // hide quick text editor
        setEditable(false);
        setIsEdit(false);
      };

      const handleComplexCustomFieldValueChange = (
        customFieldValueData: CustomFieldValueData,
      ) => {
        const { fieldId, value } = customFieldValueData;

        onCustomFieldValueUpdated({
          fieldId,
          value,
          updatingClient: data.clientFullData,
        });
      };

      const inputActions = React.useMemo(() => {
        let actions: QuickTextEditorProps['inputActions'] = undefined;
        if (field.type === 'url') {
          actions = [
            {
              key: 'external-link-action',
              icon: ExternalLinkIcon,
              onClick: (
                _: React.MouseEvent<HTMLButtonElement>,
                linkUrl: string,
              ) => window.open(UrlUtils.GetFullUrl(linkUrl), '_blank'),
            },
          ];
        }

        return actions;
      }, [field.type]);

      /**
       * This function is used to switch custom field cell
       * to edit mode.
       */
      const setEditableTrue = () => {
        setEditable(true);
        setIsEdit(true);
      };

      /**
       * This renderer function is used to display
       * text field inputs.
       */
      const textCustomFieldRenderer = () => {
        if (editable) {
          return (
            <QuickTextEditor
              onUpdate={handleUpdateCustomFieldValue}
              canEdit={editable}
              onCancel={() => setEditable(false)}
              onFocus={(ev) => {
                if (!ev) setEditable(false);
              }}
              // cellValue might not be string, if not we pass empty string value for processing
              value={typeof cellValue === 'string' ? cellValue : ''}
              showCopyIcon
              InputComponent={inputComponent}
              skipErrorOnUnfocused
              inputWrapperStyleProps={{
                width: 200,
                position: 'absolute',
                zIndex: 1,
              }}
              validator={(value: string) =>
                getFieldValidationByType(field.type, value)
              }
              inputActions={inputActions}
              canSubmitEmptyString
            />
          );
        }

        if (cellValue) {
          return (
            <EditableCellValueWrapper onClick={setEditableTrue}>
              <BaseTypography>
                {formatFieldValue(
                  field.type,
                  typeof cellValue === 'string' ? cellValue : '',
                )}
              </BaseTypography>
            </EditableCellValueWrapper>
          );
        }

        return null;
      };

      /**
       * This renderer is used to display multi select field
       * input.
       */
      const multiSelectCustomFieldRenderer = () => (
        <MultiSelect
          showPreviewPlaceholder={false}
          onTagsEmpty={(isEmpty) => setForceShowFieldIcon(isEmpty)}
          tagsLimit={1}
          // this prop is used to trigger open
          // tags menu from outside the multiselect
          // component itself. e.g. when user
          // clicks on field icon menu button
          // it should open the multi select menu.
          shouldOpenMenu={editable}
          onClose={() => {
            setEditable(false);
            setIsEdit(false);
          }}
          placeholder="Add a tag"
          initialFieldValue={Array.isArray(cellValue) ? cellValue : []}
          field={{
            ...(field as MultiSelectCustomField),
          }}
          onSaveProperty={onCustomFieldPropertyUpdated}
          onSaveValue={handleComplexCustomFieldValueChange}
        />
      );

      const isCellValueEmpty =
        cellValue === null ||
        cellValue === undefined ||
        ((typeof cellValue === 'string' || Array.isArray(cellValue)) &&
          cellValue.length === 0) ||
        (typeof cellValue === 'object' &&
          Object.keys(cellValue).length === 0) ||
        (field.type === 'address' &&
          isAddressCustomFieldValues(cellValue) &&
          (cellValue.fullAddress === '' || cellValue.fullAddress === null));

      return (
        <Box display="flex" alignItems="center">
          {(isCellValueEmpty || forceShowFieldIcon) && !editable && (
            <IconButton
              onClickCapture={setEditableTrue}
              className={classes.fieldIconBtn}
            >
              <FieldIcon />
            </IconButton>
          )}
          {COPYABLE_FIELDS_TYPES.includes(field.type) &&
            textCustomFieldRenderer()}
          {field.type === 'multiSelect' && multiSelectCustomFieldRenderer()}
          {field.type === 'address' && (
            <AddressInput
              field={field as AddressCustomField}
              value={(cellValue ?? { fullAddress: '' }) as AddressCustomField}
              placeholder={MapFieldTypeToDefaultProps[field.type].placeholder}
              onSaveValue={handleComplexCustomFieldValueChange}
              onClose={() => {
                setEditable(false);
                setIsEdit(false);
              }}
              renderAsCell
              shouldOpenMenu={editable}
            />
          )}
        </Box>
      );
    },
  );
