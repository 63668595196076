import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BusinessIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.875 16.25V18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 1.25H10.625C10.9565 1.25 11.2745 1.3817 11.5089 1.61612C11.7433 1.85054 11.875 2.16848 11.875 2.5V18.5938C11.875 18.6352 11.8585 18.6749 11.8292 18.7042C11.7999 18.7335 11.7602 18.75 11.7188 18.75H1.875V2.5C1.875 2.16848 2.0067 1.85054 2.24112 1.61612C2.47554 1.3817 2.79348 1.25 3.125 1.25V1.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 7.5H16.875C17.2065 7.5 17.5245 7.6317 17.7589 7.86612C17.9933 8.10054 18.125 8.41848 18.125 8.75V18.75H11.875V8.125C11.875 7.95924 11.9408 7.80027 12.0581 7.68306C12.1753 7.56585 12.3342 7.5 12.5 7.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.83105 16.8697C3.70161 16.8867 3.57012 16.8626 3.45503 16.801C3.33993 16.7394 3.24703 16.6433 3.18934 16.5262C3.13165 16.4091 3.11208 16.2769 3.13337 16.1481C3.15466 16.0193 3.21575 15.9004 3.30806 15.8081C3.40037 15.7158 3.51926 15.6547 3.64806 15.6334C3.77686 15.6121 3.90909 15.6316 4.02619 15.6893C4.1433 15.747 4.2394 15.8399 4.30101 15.955C4.36262 16.0701 4.38665 16.2016 4.36972 16.3311C4.35186 16.4677 4.28936 16.5945 4.19194 16.6919C4.09453 16.7894 3.96765 16.8519 3.83105 16.8697V16.8697Z"
      fill="currentColor"
    />
    <path
      d="M3.83105 13.7447C3.70161 13.7617 3.57012 13.7376 3.45503 13.676C3.33993 13.6144 3.24703 13.5183 3.18934 13.4012C3.13165 13.2841 3.11208 13.1519 3.13337 13.0231C3.15466 12.8943 3.21575 12.7754 3.30806 12.6831C3.40037 12.5908 3.51926 12.5297 3.64806 12.5084C3.77686 12.4871 3.90909 12.5066 4.02619 12.5643C4.1433 12.622 4.2394 12.7149 4.30101 12.83C4.36262 12.9451 4.38665 13.0766 4.36972 13.2061C4.35186 13.3427 4.28936 13.4695 4.19194 13.5669C4.09453 13.6644 3.96765 13.7269 3.83105 13.7447V13.7447Z"
      fill="currentColor"
    />
    <path
      d="M3.83105 10.6197C3.70161 10.6367 3.57012 10.6126 3.45503 10.551C3.33993 10.4894 3.24703 10.3933 3.18934 10.2762C3.13165 10.1591 3.11208 10.0269 3.13337 9.89806C3.15466 9.76926 3.21575 9.65037 3.30806 9.55806C3.40037 9.46575 3.51926 9.40466 3.64806 9.38337C3.77686 9.36208 3.90909 9.38165 4.02619 9.43934C4.1433 9.49703 4.2394 9.58993 4.30101 9.70503C4.36262 9.82012 4.38665 9.95161 4.36972 10.0811C4.35186 10.2177 4.28936 10.3445 4.19194 10.4419C4.09453 10.5394 3.96765 10.6019 3.83105 10.6197V10.6197Z"
      fill="currentColor"
    />
    <path
      d="M3.83105 7.49472C3.70161 7.51165 3.57012 7.48762 3.45503 7.42601C3.33993 7.3644 3.24703 7.2683 3.18934 7.15119C3.13165 7.03409 3.11208 6.90186 3.13337 6.77306C3.15466 6.64426 3.21575 6.52537 3.30806 6.43306C3.40037 6.34075 3.51926 6.27966 3.64806 6.25837C3.77686 6.23708 3.90909 6.25665 4.02619 6.31434C4.1433 6.37203 4.2394 6.46493 4.30101 6.58002C4.36262 6.69512 4.38665 6.82661 4.36972 6.95605C4.35186 7.09265 4.28936 7.21953 4.19194 7.31694C4.09453 7.41436 3.96765 7.47686 3.83105 7.49472V7.49472Z"
      fill="currentColor"
    />
    <path
      d="M3.83105 4.36972C3.70161 4.38665 3.57012 4.36262 3.45503 4.30101C3.33993 4.2394 3.24703 4.1433 3.18934 4.02619C3.13165 3.90909 3.11208 3.77686 3.13337 3.64806C3.15466 3.51926 3.21575 3.40037 3.30806 3.30806C3.40037 3.21575 3.51926 3.15466 3.64806 3.13337C3.77686 3.11208 3.90909 3.13165 4.02619 3.18934C4.1433 3.24703 4.2394 3.33993 4.30101 3.45503C4.36262 3.57012 4.38665 3.70161 4.36972 3.83105C4.35186 3.96765 4.28936 4.09453 4.19194 4.19194C4.09453 4.28936 3.96765 4.35186 3.83105 4.36972Z"
      fill="currentColor"
    />
    <path
      d="M6.95605 13.7447C6.82661 13.7617 6.69512 13.7376 6.58002 13.676C6.46493 13.6144 6.37203 13.5183 6.31434 13.4012C6.25665 13.2841 6.23708 13.1519 6.25837 13.0231C6.27966 12.8943 6.34075 12.7754 6.43306 12.6831C6.52537 12.5908 6.64426 12.5297 6.77306 12.5084C6.90186 12.4871 7.03409 12.5066 7.15119 12.5643C7.2683 12.622 7.3644 12.7149 7.42601 12.83C7.48762 12.9451 7.51165 13.0766 7.49472 13.2061C7.47686 13.3427 7.41436 13.4695 7.31694 13.5669C7.21953 13.6644 7.09265 13.7269 6.95605 13.7447V13.7447Z"
      fill="currentColor"
    />
    <path
      d="M6.95605 10.6197C6.82661 10.6367 6.69512 10.6126 6.58002 10.551C6.46493 10.4894 6.37203 10.3933 6.31434 10.2762C6.25665 10.1591 6.23708 10.0269 6.25837 9.89806C6.27966 9.76926 6.34075 9.65037 6.43306 9.55806C6.52537 9.46575 6.64426 9.40466 6.77306 9.38337C6.90186 9.36208 7.03409 9.38165 7.15119 9.43934C7.2683 9.49703 7.3644 9.58993 7.42601 9.70503C7.48762 9.82012 7.51165 9.95161 7.49472 10.0811C7.47686 10.2177 7.41436 10.3445 7.31694 10.4419C7.21953 10.5394 7.09265 10.6019 6.95605 10.6197V10.6197Z"
      fill="currentColor"
    />
    <path
      d="M6.95605 7.49472C6.82661 7.51165 6.69512 7.48762 6.58002 7.42601C6.46493 7.3644 6.37203 7.2683 6.31434 7.15119C6.25665 7.03409 6.23708 6.90186 6.25837 6.77306C6.27966 6.64426 6.34075 6.52537 6.43306 6.43306C6.52537 6.34075 6.64426 6.27966 6.77306 6.25837C6.90186 6.23708 7.03409 6.25665 7.15119 6.31434C7.2683 6.37203 7.3644 6.46493 7.42601 6.58002C7.48762 6.69512 7.51165 6.82661 7.49472 6.95605C7.47686 7.09265 7.41436 7.21953 7.31694 7.31694C7.21953 7.41436 7.09265 7.47686 6.95605 7.49472V7.49472Z"
      fill="currentColor"
    />
    <path
      d="M6.95605 4.36972C6.82661 4.38665 6.69512 4.36262 6.58002 4.30101C6.46493 4.2394 6.37203 4.1433 6.31434 4.02619C6.25665 3.90909 6.23708 3.77686 6.25837 3.64806C6.27966 3.51926 6.34075 3.40037 6.43306 3.30806C6.52537 3.21575 6.64426 3.15466 6.77306 3.13337C6.90186 3.11208 7.03409 3.13165 7.15119 3.18934C7.2683 3.24703 7.3644 3.33993 7.42601 3.45503C7.48762 3.57012 7.51165 3.70161 7.49472 3.83105C7.47686 3.96765 7.41436 4.09453 7.31694 4.19194C7.21953 4.28936 7.09265 4.35186 6.95605 4.36972V4.36972Z"
      fill="currentColor"
    />
    <path
      d="M10.0811 16.8697C9.95161 16.8867 9.82012 16.8626 9.70502 16.801C9.58993 16.7394 9.49703 16.6433 9.43934 16.5262C9.38165 16.4091 9.36208 16.2769 9.38337 16.1481C9.40466 16.0193 9.46575 15.9004 9.55806 15.8081C9.65037 15.7158 9.76926 15.6547 9.89806 15.6334C10.0269 15.6121 10.1591 15.6316 10.2762 15.6893C10.3933 15.747 10.4894 15.8399 10.551 15.955C10.6126 16.0701 10.6367 16.2016 10.6197 16.3311C10.6019 16.4677 10.5394 16.5945 10.4419 16.6919C10.3445 16.7894 10.2177 16.8519 10.0811 16.8697V16.8697Z"
      fill="currentColor"
    />
    <path
      d="M10.0811 13.7447C9.95161 13.7617 9.82012 13.7376 9.70502 13.676C9.58993 13.6144 9.49703 13.5183 9.43934 13.4012C9.38165 13.2841 9.36208 13.1519 9.38337 13.0231C9.40466 12.8943 9.46575 12.7754 9.55806 12.6831C9.65037 12.5908 9.76926 12.5297 9.89806 12.5084C10.0269 12.4871 10.1591 12.5066 10.2762 12.5643C10.3933 12.622 10.4894 12.7149 10.551 12.83C10.6126 12.9451 10.6367 13.0766 10.6197 13.2061C10.6019 13.3427 10.5394 13.4695 10.4419 13.5669C10.3445 13.6644 10.2177 13.7269 10.0811 13.7447V13.7447Z"
      fill="currentColor"
    />
    <path
      d="M10.0811 10.6197C9.95161 10.6367 9.82012 10.6126 9.70502 10.551C9.58993 10.4894 9.49703 10.3933 9.43934 10.2762C9.38165 10.1591 9.36208 10.0269 9.38337 9.89806C9.40466 9.76926 9.46575 9.65037 9.55806 9.55806C9.65037 9.46575 9.76926 9.40466 9.89806 9.38337C10.0269 9.36208 10.1591 9.38165 10.2762 9.43934C10.3933 9.49703 10.4894 9.58993 10.551 9.70503C10.6126 9.82012 10.6367 9.95161 10.6197 10.0811C10.6019 10.2177 10.5394 10.3445 10.4419 10.4419C10.3445 10.5394 10.2177 10.6019 10.0811 10.6197V10.6197Z"
      fill="currentColor"
    />
    <path
      d="M10.4423 7.31754C10.6856 7.07422 10.6846 6.67874 10.4401 6.43421C10.1956 6.18967 9.80009 6.18868 9.55677 6.432C9.31346 6.67531 9.31445 7.07079 9.55898 7.31533C9.80352 7.55986 10.199 7.56085 10.4423 7.31754Z"
      fill="currentColor"
    />
    <path
      d="M10.0811 4.36972C9.95161 4.38665 9.82012 4.36262 9.70502 4.30101C9.58993 4.2394 9.49703 4.1433 9.43934 4.02619C9.38165 3.90909 9.36208 3.77686 9.38337 3.64806C9.40466 3.51926 9.46575 3.40037 9.55806 3.30806C9.65037 3.21575 9.76926 3.15466 9.89806 3.13337C10.0269 3.11208 10.1591 3.13165 10.2762 3.18934C10.3933 3.24703 10.4894 3.33993 10.551 3.45503C10.6126 3.57012 10.6367 3.70161 10.6197 3.83105C10.6019 3.96765 10.5394 4.09453 10.4419 4.19194C10.3445 4.28936 10.2177 4.35186 10.0811 4.36972V4.36972Z"
      fill="currentColor"
    />
    <path
      d="M15.625 15.625C15.5014 15.625 15.3805 15.6617 15.2778 15.7303C15.175 15.799 15.0949 15.8966 15.0476 16.0108C15.0003 16.125 14.9879 16.2507 15.012 16.3719C15.0361 16.4932 15.0957 16.6045 15.1831 16.6919C15.2705 16.7793 15.3818 16.8389 15.5031 16.863C15.6243 16.8871 15.75 16.8747 15.8642 16.8274C15.9784 16.7801 16.076 16.7 16.1447 16.5972C16.2133 16.4945 16.25 16.3736 16.25 16.25C16.25 16.0842 16.1842 15.9253 16.0669 15.8081C15.9497 15.6908 15.7908 15.625 15.625 15.625Z"
      fill="currentColor"
    />
    <path
      d="M15.625 12.5C15.5014 12.5 15.3805 12.5367 15.2778 12.6053C15.175 12.674 15.0949 12.7716 15.0476 12.8858C15.0003 13 14.9879 13.1257 15.012 13.2469C15.0361 13.3682 15.0957 13.4795 15.1831 13.5669C15.2705 13.6544 15.3818 13.7139 15.5031 13.738C15.6243 13.7621 15.75 13.7497 15.8642 13.7024C15.9784 13.6551 16.076 13.575 16.1447 13.4722C16.2133 13.3695 16.25 13.2486 16.25 13.125C16.25 12.9592 16.1842 12.8003 16.0669 12.6831C15.9497 12.5658 15.7908 12.5 15.625 12.5Z"
      fill="currentColor"
    />
    <path
      d="M15.625 9.375C15.5014 9.375 15.3805 9.41166 15.2778 9.48033C15.175 9.54901 15.0949 9.64662 15.0476 9.76082C15.0003 9.87503 14.9879 10.0007 15.012 10.1219C15.0361 10.2432 15.0957 10.3545 15.1831 10.4419C15.2705 10.5294 15.3818 10.5889 15.5031 10.613C15.6243 10.6371 15.75 10.6247 15.8642 10.5774C15.9784 10.5301 16.076 10.45 16.1447 10.3472C16.2133 10.2445 16.25 10.1236 16.25 10C16.25 9.83424 16.1842 9.67527 16.0669 9.55806C15.9497 9.44085 15.7908 9.375 15.625 9.375Z"
      fill="currentColor"
    />
    <path
      d="M13.125 15.625C13.0014 15.625 12.8805 15.6617 12.7778 15.7303C12.675 15.799 12.5949 15.8966 12.5476 16.0108C12.5003 16.125 12.4879 16.2507 12.512 16.3719C12.5361 16.4932 12.5957 16.6045 12.6831 16.6919C12.7705 16.7793 12.8818 16.8389 13.0031 16.863C13.1243 16.8871 13.25 16.8747 13.3642 16.8274C13.4784 16.7801 13.576 16.7 13.6447 16.5972C13.7133 16.4945 13.75 16.3736 13.75 16.25C13.75 16.0842 13.6842 15.9253 13.5669 15.8081C13.4497 15.6908 13.2908 15.625 13.125 15.625Z"
      fill="currentColor"
    />
    <path
      d="M13.125 12.5C13.0014 12.5 12.8805 12.5367 12.7778 12.6053C12.675 12.674 12.5949 12.7716 12.5476 12.8858C12.5003 13 12.4879 13.1257 12.512 13.2469C12.5361 13.3682 12.5957 13.4795 12.6831 13.5669C12.7705 13.6544 12.8818 13.7139 13.0031 13.738C13.1243 13.7621 13.25 13.7497 13.3642 13.7024C13.4784 13.6551 13.576 13.575 13.6447 13.4722C13.7133 13.3695 13.75 13.2486 13.75 13.125C13.75 12.9592 13.6842 12.8003 13.5669 12.6831C13.4497 12.5658 13.2908 12.5 13.125 12.5Z"
      fill="currentColor"
    />
    <path
      d="M13.125 9.375C13.0014 9.375 12.8805 9.41166 12.7778 9.48033C12.675 9.54901 12.5949 9.64662 12.5476 9.76082C12.5003 9.87503 12.4879 10.0007 12.512 10.1219C12.5361 10.2432 12.5957 10.3545 12.6831 10.4419C12.7705 10.5294 12.8818 10.5889 13.0031 10.613C13.1243 10.6371 13.25 10.6247 13.3642 10.5774C13.4784 10.5301 13.576 10.45 13.6447 10.3472C13.7133 10.2445 13.75 10.1236 13.75 10C13.75 9.83424 13.6842 9.67527 13.5669 9.55806C13.4497 9.44085 13.2908 9.375 13.125 9.375Z"
      fill="currentColor"
    />
  </SvgIcon>
);
