import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ColorPaletteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.8008 13.5898C16.543 13.3516 16.1641 13.293 15.8398 13.2383C15.3906 13.1641 15.2187 13.082 14.957 12.8477C14.3984 12.3516 14.3984 11.6328 14.957 11.1367L16.1406 10.0859C17.9531 8.48438 17.9531 5.85938 16.1406 4.25781C14.8047 3.08203 13.0117 2.5 11.1484 2.5C8.97266 2.5 6.69922 3.29297 4.94531 4.84766C1.68359 7.73047 1.68359 12.4531 4.94531 15.3359C6.56641 16.7695 8.75391 17.4844 10.918 17.5H10.9844C13.1484 17.5 15.2813 16.8008 16.7969 15.4531C17.3594 14.957 17.2652 14.0234 16.8008 13.5898Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M5.625 9.375C6.31536 9.375 6.875 8.81536 6.875 8.125C6.875 7.43464 6.31536 6.875 5.625 6.875C4.93464 6.875 4.375 7.43464 4.375 8.125C4.375 8.81536 4.93464 9.375 5.625 9.375Z"
      fill="currentColor"
    />
    <path
      d="M5.9375 13.3984C6.62786 13.3984 7.1875 12.8388 7.1875 12.1484C7.1875 11.4581 6.62786 10.8984 5.9375 10.8984C5.24714 10.8984 4.6875 11.4581 4.6875 12.1484C4.6875 12.8388 5.24714 13.3984 5.9375 13.3984Z"
      fill="currentColor"
    />
    <path
      d="M8.75 6.875C9.44036 6.875 10 6.31536 10 5.625C10 4.93464 9.44036 4.375 8.75 4.375C8.05964 4.375 7.5 4.93464 7.5 5.625C7.5 6.31536 8.05964 6.875 8.75 6.875Z"
      fill="currentColor"
    />
    <path
      d="M10 16.2109C11.0355 16.2109 11.875 15.3715 11.875 14.3359C11.875 13.3004 11.0355 12.4609 10 12.4609C8.96447 12.4609 8.125 13.3004 8.125 14.3359C8.125 15.3715 8.96447 16.2109 10 16.2109Z"
      fill="currentColor"
    />
    <path
      d="M12.8125 6.875C13.5029 6.875 14.0625 6.31536 14.0625 5.625C14.0625 4.93464 13.5029 4.375 12.8125 4.375C12.1221 4.375 11.5625 4.93464 11.5625 5.625C11.5625 6.31536 12.1221 6.875 12.8125 6.875Z"
      fill="currentColor"
    />
  </SvgIcon>
);
