import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CsvIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.27237 13.3228H7.49467C7.48047 13.2222 7.45147 13.1328 7.40767 13.0547C7.36387 12.9754 7.30765 12.9079 7.23899 12.8523C7.17034 12.7966 7.09103 12.754 7.00107 12.7244C6.91229 12.6948 6.81581 12.68 6.71165 12.68C6.52344 12.68 6.35949 12.7268 6.21982 12.8203C6.08014 12.9126 5.97183 13.0476 5.89489 13.2251C5.81795 13.4015 5.77947 13.6158 5.77947 13.8679C5.77947 14.1271 5.81795 14.3449 5.89489 14.5213C5.97301 14.6977 6.08191 14.8308 6.22159 14.9208C6.36127 15.0108 6.52285 15.0558 6.70632 15.0558C6.8093 15.0558 6.90459 15.0421 6.99219 15.0149C7.08097 14.9877 7.15968 14.948 7.22834 14.896C7.29699 14.8427 7.35381 14.7782 7.39879 14.7024C7.44496 14.6267 7.47692 14.5402 7.49467 14.4432L8.27237 14.4467C8.25225 14.6136 8.20194 14.7746 8.12145 14.9297C8.04214 15.0836 7.93501 15.2215 7.80007 15.3434C7.66631 15.4641 7.50651 15.56 7.32067 15.631C7.13601 15.7009 6.92708 15.7358 6.69389 15.7358C6.36955 15.7358 6.07955 15.6624 5.82386 15.5156C5.56937 15.3688 5.36813 15.1564 5.22017 14.8782C5.07339 14.6 5 14.2633 5 13.8679C5 13.4714 5.07457 13.134 5.22372 12.8558C5.37287 12.5777 5.57528 12.3658 5.83097 12.2202C6.08665 12.0734 6.37429 12 6.69389 12C6.90459 12 7.09991 12.0296 7.27983 12.0888C7.46094 12.148 7.62133 12.2344 7.76101 12.348C7.90069 12.4605 8.01432 12.5984 8.10192 12.7617C8.1907 12.9251 8.24751 13.1121 8.27237 13.3228Z"
      fill="currentColor"
    />
    <path
      d="M10.7963 13.0955C10.7821 12.9523 10.7212 12.841 10.6135 12.7617C10.5057 12.6824 10.3596 12.6428 10.1749 12.6428C10.0494 12.6428 9.94348 12.6605 9.85707 12.696C9.77066 12.7304 9.70437 12.7783 9.6582 12.8398C9.61322 12.9014 9.59073 12.9712 9.59073 13.0494C9.58836 13.1145 9.60198 13.1713 9.63157 13.2198C9.66235 13.2683 9.70437 13.3104 9.75764 13.3459C9.8109 13.3802 9.87246 13.4104 9.94229 13.4364C10.0121 13.4613 10.0867 13.4826 10.166 13.5004L10.4927 13.5785C10.6513 13.614 10.7969 13.6613 10.9295 13.7205C11.0621 13.7797 11.1769 13.8525 11.274 13.9389C11.371 14.0253 11.4462 14.1271 11.4995 14.2443C11.5539 14.3615 11.5817 14.4959 11.5829 14.6474C11.5817 14.8699 11.5249 15.0629 11.4125 15.2262C11.3012 15.3884 11.1402 15.5144 10.9295 15.6044C10.72 15.6932 10.4673 15.7376 10.1713 15.7376C9.87778 15.7376 9.6221 15.6926 9.4043 15.6026C9.18768 15.5127 9.01841 15.3795 8.89648 15.2031C8.77575 15.0256 8.71242 14.806 8.7065 14.5444H9.45046C9.45875 14.6663 9.49367 14.7681 9.55522 14.8498C9.61796 14.9303 9.70141 14.9912 9.80558 15.0327C9.91093 15.0729 10.0299 15.093 10.1625 15.093C10.2927 15.093 10.4057 15.0741 10.5016 15.0362C10.5987 14.9983 10.6738 14.9457 10.7271 14.8782C10.7804 14.8107 10.807 14.7332 10.807 14.6456C10.807 14.5639 10.7827 14.4953 10.7342 14.4396C10.6868 14.384 10.617 14.3366 10.5247 14.2976C10.4335 14.2585 10.3217 14.223 10.1891 14.1911L9.79315 14.0916C9.48656 14.017 9.2445 13.9004 9.06694 13.7418C8.88938 13.5832 8.8012 13.3696 8.80238 13.1009C8.8012 12.8807 8.85979 12.6883 8.97816 12.5238C9.09772 12.3593 9.26166 12.2308 9.46999 12.1385C9.67833 12.0462 9.91507 12 10.1802 12C10.4501 12 10.6857 12.0462 10.8869 12.1385C11.0893 12.2308 11.2467 12.3593 11.3592 12.5238C11.4717 12.6883 11.5297 12.8789 11.5332 13.0955H10.7963Z"
      fill="currentColor"
    />
    <path
      d="M12.757 12.0497L13.6359 14.8125H13.6697L14.5503 12.0497H15.4026L14.1491 15.6861H13.1583L11.903 12.0497H12.757Z"
      fill="currentColor"
    />
  </SvgIcon>
);
