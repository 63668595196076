import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HeadsetIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M3.24219 15C2.73438 13.7109 1.875 11.3527 1.875 10C1.875 5.5125 5.8332 1.875 10 1.875C14.1668 1.875 18.125 5.5125 18.125 10C18.125 11.3527 17.2266 13.7891 16.7578 15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.23433 10.5519L3.69957 10.8644C2.51871 11.5558 2.46129 13.6929 3.56754 15.6379C4.67379 17.5828 6.53121 18.5992 7.71207 17.9082L8.24683 17.5957C8.38877 17.5111 8.49191 17.3743 8.53412 17.2146C8.57633 17.0549 8.55425 16.885 8.47261 16.7414L5.07847 10.7812C5.03846 10.7104 4.98478 10.6482 4.92055 10.5982C4.85632 10.5483 4.78281 10.5116 4.7043 10.4903C4.62578 10.4689 4.54381 10.4634 4.46313 10.474C4.38246 10.4846 4.30469 10.5111 4.23433 10.5519V10.5519Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M15.7661 10.5519L16.3008 10.8644C17.4817 11.5554 17.5407 13.6925 16.4329 15.6375C15.325 17.5824 13.4692 18.5988 12.2883 17.9078L11.7536 17.5953C11.6116 17.5107 11.5085 17.3739 11.4663 17.2142C11.4241 17.0545 11.4461 16.8846 11.5278 16.741L14.9219 10.7812C14.9619 10.7104 15.0156 10.6482 15.0798 10.5982C15.1441 10.5483 15.2176 10.5116 15.2961 10.4903C15.3746 10.4689 15.4566 10.4634 15.5373 10.474C15.6179 10.4846 15.6957 10.5111 15.7661 10.5519V10.5519Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
