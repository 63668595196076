import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const InvisibleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="none"
      d="M1.36055 9.66162C1.29037 9.76332 1.25191 9.88354 1.25003 10.0071C1.24816 10.1306 1.28294 10.252 1.35 10.3558C1.87875 11.1834 2.50089 11.9606 3.20312 12.6507M18.6504 9.65967C18.7159 9.76142 18.7507 9.87987 18.7507 10.0009C18.7507 10.1219 18.7159 10.2403 18.6504 10.3421C16.7578 13.3058 13.6133 15.6253 9.98672 15.6253C7.34653 15.6253 5.00978 14.4262 3.20312 12.6507M3.20312 12.6507L0.703125 13.4964M7.01172 15.1015L5.72266 17.1878M13.0273 15.1015L14.4531 17.1878M16.7755 12.6507L19.1602 13.4964"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
