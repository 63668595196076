import {
  KnowledgeBaseActionTypes,
  KnowledgeBaseState,
  Article,
  LOAD_ARTICLES_REQUEST,
  LOAD_ARTICLES_DONE,
  LOAD_ARTICLES_FAIL,
  CREATE_ARTICLE_DONE,
  UPDATE_ARTICLE_DONE,
  DELETE_ARTICLES_DONE,
  GET_KNOWLEDGE_BASE_SETTINGS_REQUEST,
  GET_KNOWLEDGE_BASE_SETTINGS_DONE,
  GET_KNOWLEDGE_BASE_SETTINGS_FAIL,
  UPDATE_KNOWLEDGE_BASE_SETTINGS_DONE,
  UPDATE_KNOWLEDGE_BASE_SETTINGS_FAIL,
  ON_NEW_TAG_ADD_SUCCCESS,
  UPSERT_ARTICLES,
} from 'src/store/knowledgeBase/types';

export const initialArticle: Article = {
  id: '',
  structFields: {
    title: '',
    content: '',
    tags: [],
    previewText: '',
    contentHtml: '',
  },
  ownerId: '',
  identityId: '',
  owner: null,
  createdDate: '',
  updatedDate: '',
  entityStatus: '',
  getstreamId: '',
  metadata: {},
};

const initialState: KnowledgeBaseState = {
  articles: [],
  articlesLoaded: false,
  articlesLoading: false,
  settingsLoaded: false,
  settingsLoading: false,
};

const knowledgeBase = (
  /* eslint-disable-next-line default-param-last */
  state = initialState,
  action: KnowledgeBaseActionTypes,
) => {
  switch (action.type) {
    case LOAD_ARTICLES_REQUEST: {
      return {
        ...state,
        articlesLoading: true,
        error: '',
      };
    }
    case LOAD_ARTICLES_DONE: {
      const { payload } = action;
      return {
        ...state,
        articlesLoading: false,
        articlesLoaded: true,
        articles: payload,
        error: '',
      };
    }
    case LOAD_ARTICLES_FAIL: {
      const { error } = action;
      return {
        ...state,
        articlesLoading: false,
        articlesLoaded: false,
        error,
      };
    }
    case CREATE_ARTICLE_DONE: {
      const { payload } = action;
      return {
        ...state,
        articles: [payload].concat(state.articles),
        settings: {
          ...state.settings,
          articles: state.settings?.articles
            ? state.settings.articles.concat(payload.id)
            : [payload.id],
        },
      };
    }
    case UPDATE_ARTICLE_DONE: {
      const { payload } = action;
      const updatedArticles = state.articles.map((article) => {
        if (article.id === payload.id) {
          return payload;
        }

        return article;
      });
      return {
        ...state,
        articles: updatedArticles,
      };
    }
    case DELETE_ARTICLES_DONE: {
      const { payload } = action;
      const deletedIDs = payload.map((a) => a.id);
      const finalArticlesList: Article[] = [];
      state.articles.forEach((a) => {
        if (!deletedIDs.includes(a.id)) {
          finalArticlesList.push(a);
        }
      });
      return {
        ...state,
        articles: finalArticlesList,
      };
    }
    case GET_KNOWLEDGE_BASE_SETTINGS_REQUEST: {
      return {
        ...state,
        settingsLoading: true,
        error: '',
      };
    }
    case GET_KNOWLEDGE_BASE_SETTINGS_DONE: {
      const { payload } = action;
      return {
        ...state,
        settingsLoading: false,
        settingsLoaded: true,
        settings: payload.structFields,
        error: '',
      };
    }
    case GET_KNOWLEDGE_BASE_SETTINGS_FAIL: {
      const { error } = action;
      return {
        ...state,
        settingsLoading: false,
        settingsLoaded: false,
        error,
      };
    }
    case UPDATE_KNOWLEDGE_BASE_SETTINGS_DONE: {
      const { payload } = action;
      let updatedSettings = state.settings;
      if (payload.fieldType === 'tags') {
        updatedSettings = {
          ...state.settings,
          tags: payload.values,
        };
      } else if (payload.fieldType === 'articles') {
        updatedSettings = {
          ...state.settings,
          articles: payload.values,
        };
      }
      return {
        ...state,
        settings: updatedSettings,
      };
    }
    case UPDATE_KNOWLEDGE_BASE_SETTINGS_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }
    case ON_NEW_TAG_ADD_SUCCCESS: {
      const { tagId } = action;
      return {
        ...state,
        settings: {
          ...state.settings,
          tags: state.settings?.tags
            ? state.settings.tags.concat(tagId)
            : [tagId],
        },
      };
    }
    case UPSERT_ARTICLES: {
      const { payload } = action;
      // keep a list of articles we are updating, not inserting
      const updatedArticleIDs: string[] = [];
      // iterate through current articles to check for articles which will have data replaced
      const finalArticles: Article[] = state.articles.map((a: Article) => {
        const payloadUpdatedArticleIdx: number = payload.findIndex(
          (el: Article) => el.id === a.id,
        );
        if (payloadUpdatedArticleIdx > -1) {
          // if index is > -1, the article is present in the payload and we use the updated (payload) version
          updatedArticleIDs.push(payload[payloadUpdatedArticleIdx].id);
          return payload[payloadUpdatedArticleIdx];
        }
        return a;
      });
      // return state with the updated articles and append new articles to the end of the list
      return {
        ...state,
        articles: finalArticles.concat(
          payload.filter((a: Article) => !updatedArticleIDs.includes(a.id)),
        ),
      };
    }
    default:
      return state;
  }
};

export default knowledgeBase;
