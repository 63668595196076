import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RecordingIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 13.75C7.07107 13.75 8.75 12.0711 8.75 10C8.75 7.92893 7.07107 6.25 5 6.25C2.92893 6.25 1.25 7.92893 1.25 10C1.25 12.0711 2.92893 13.75 5 13.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 13.75C17.0711 13.75 18.75 12.0711 18.75 10C18.75 7.92893 17.0711 6.25 15 6.25C12.9289 6.25 11.25 7.92893 11.25 10C11.25 12.0711 12.9289 13.75 15 13.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 13.75H15"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
