import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const TimelineInvoiceIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M6.66406 13.3348H10.8325"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2313 13.439C13.2888 13.439 13.3355 13.3924 13.3355 13.3348C13.3355 13.2773 13.2888 13.2306 13.2313 13.2306C13.1737 13.2306 13.1271 13.2773 13.1271 13.3348C13.1271 13.3924 13.1737 13.439 13.2313 13.439"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66406 10.0003H10.8325"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33008 3.74738V16.253C3.33008 16.5421 3.47969 16.8106 3.72569 16.9625C3.97169 17.1144 4.27883 17.1279 4.53725 16.9983L5.18002 16.6765C5.41455 16.559 5.69079 16.559 5.92533 16.6765L7.40344 17.4152C7.63797 17.5327 7.91422 17.5327 8.14875 17.4152L9.62687 16.6765C9.8614 16.559 10.1376 16.559 10.3722 16.6765L11.8503 17.4152C12.0851 17.5327 12.3616 17.5327 12.5964 17.4152L14.0729 16.6765C14.3077 16.559 14.5842 16.559 14.819 16.6765L15.4626 16.9983C15.721 17.1276 16.0279 17.1139 16.2737 16.9621C16.5194 16.8102 16.669 16.5419 16.669 16.253V3.74738C16.669 3.45827 16.5194 3.18976 16.2734 3.03788C16.0274 2.886 15.7202 2.87245 15.4618 3.00207L14.819 3.32387C14.5845 3.44142 14.3083 3.44142 14.0737 3.32387L12.5956 2.58523C12.3611 2.46768 12.0848 2.46768 11.8503 2.58523L10.3722 3.32387C10.1377 3.44059 9.86214 3.44059 9.6277 3.32387L8.14959 2.58523C7.91476 2.46768 7.63827 2.46768 7.40344 2.58523L5.92699 3.32387C5.69217 3.44142 5.41567 3.44142 5.18085 3.32387L4.53641 3.00207C4.27807 2.87274 3.97117 2.88645 3.72539 3.0383C3.47961 3.19016 3.33008 3.45848 3.33008 3.74738Z"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M13.2313 10.1045C13.2888 10.1045 13.3355 10.0579 13.3355 10.0003C13.3355 9.94278 13.2888 9.89613 13.2313 9.89613C13.1737 9.89613 13.1271 9.94278 13.1271 10.0003C13.1271 10.0579 13.1737 10.1045 13.2313 10.1045"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66406 6.66538H10.8325"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2313 6.76959C13.2888 6.76959 13.3355 6.72293 13.3355 6.66538C13.3355 6.60782 13.2888 6.56117 13.2313 6.56117C13.1737 6.56117 13.1271 6.60782 13.1271 6.66538C13.1271 6.72293 13.1737 6.76959 13.2313 6.76959"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
