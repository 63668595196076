import React from 'react';
import { clearCanvasContext, getCanvasContent } from './canvas_helpers';

interface BaseCanvasProps {
  id: string;
  text: string;
  fontFamily: string;
  fontSize: number;
  onDataChange: (dataUrl: string, textWidth: number) => void;
}

export const CANVAS_SIGNATURE_COMPONENT_HEIGHT = 42;

const BaseCanvas: React.FC<BaseCanvasProps> = React.memo(
  ({ id, text, fontFamily, fontSize, onDataChange }) => {
    const [textWidth, setTextWidth] = React.useState(0);
    const [textHeight, setTextHeight] = React.useState(0);
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

    React.useEffect(() => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
          if (text?.length > 0) {
            const canvasContent = getCanvasContent(
              canvas,
              context,
              fontSize,
              fontFamily,
              text,
            );
            if (canvasContent) {
              const { width, height, dataUrl } = canvasContent;
              setTextWidth(width);
              setTextHeight(height);
              onDataChange(dataUrl, width);
            }
            return;
          }
          // if the text is empty we should clear the canvas
          clearCanvasContext(canvas, context);
        }
      }
    }, [text, textWidth]);

    return (
      <canvas id={id} ref={canvasRef} height={textHeight} width={textWidth} />
    );
  },
);

export default BaseCanvas;
