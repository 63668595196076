import React, { useContext } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { VariableInputs } from './VariableInputs';
import { AutofillInputs } from './AutofillInputs';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';
import { SelectedRecipientInfoInputs } from './SelectedRecipientInfoInputs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 0.5),
  },
}));

export const AdvancedInputs = () => {
  const { isOneOffContract } = useContext(ContractBuilderContext);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {isOneOffContract ? <SelectedRecipientInfoInputs /> : <AutofillInputs />}
      {!isOneOffContract && <VariableInputs />}
    </div>
  );
};
