import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PngIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15.6861H5.87891V14.5568H6.4755C7.30824 14.5568 7.82848 14.0632 7.82848 13.3086C7.82848 12.5611 7.31889 12.0497 6.50213 12.0497H5V15.6861ZM5.87891 13.8643V12.7582H6.31214C6.70632 12.7582 6.91761 12.9712 6.91761 13.3086C6.91761 13.6442 6.70632 13.8643 6.31214 13.8643H5.87891Z"
      fill="#212B36"
    />
    <path
      d="M11.3565 12.0497H10.4794V14.1449H10.4492L9.01811 12.0497H8.26172V15.6861H9.14062V13.5874H9.16371L10.6108 15.6861H11.3565V12.0497Z"
      fill="#212B36"
    />
    <path
      d="M14.2955 13.2376H15.1851C15.0963 12.5131 14.45 12 13.6084 12C12.6372 12 11.8701 12.6836 11.8701 13.8732C11.8701 15.0238 12.5768 15.7358 13.6191 15.7358C14.5548 15.7358 15.2242 15.1552 15.2242 14.1786V13.6974H13.6563V14.3384H14.3772C14.3683 14.7219 14.102 14.9652 13.6262 14.9652C13.0811 14.9652 12.7668 14.5621 12.7668 13.8626C12.7668 13.1683 13.0988 12.7706 13.6262 12.7706C13.9777 12.7706 14.2174 12.9428 14.2955 13.2376Z"
      fill="#212B36"
    />
  </SvgIcon>
);
