import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CrossIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.4267 15.3826C16.7151 15.671 16.7151 16.1386 16.4267 16.427C16.283 16.5707 16.0941 16.6435 15.9051 16.6435C15.7161 16.6435 15.5271 16.5717 15.3834 16.427L9.99926 11.0427L4.61513 16.427C4.47142 16.5707 4.28243 16.6435 4.09345 16.6435C3.90446 16.6435 3.71548 16.5717 3.57177 16.427C3.28337 16.1386 3.28337 15.671 3.57177 15.3826L8.9559 9.99841L3.57177 4.61422C3.28337 4.32581 3.28337 3.85822 3.57177 3.56982C3.86017 3.28141 4.32772 3.28141 4.61612 3.56982L10.0002 8.95407L15.3844 3.56982C15.6728 3.28141 16.1403 3.28141 16.4287 3.56982C16.7171 3.85822 16.7171 4.32581 16.4287 4.61422L11.0446 9.99841L16.4267 15.3826Z"
      fill="currentColor"
    />
  </SvgIcon>
);
