import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HeartHalfIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.7864 2.5C11.9079 2.5 10.6614 3.65391 10.0004 4.49219C9.34106 3.65273 8.09301 2.5 6.2145 2.5C3.84653 2.5 1.90004 4.47422 1.87543 6.90039C1.85434 9.01797 2.60317 10.9734 4.16489 12.877C4.89809 13.7707 6.22739 15.1996 9.29731 17.2836C9.50434 17.4255 9.74945 17.5014 10.0004 17.5014C10.2514 17.5014 10.4965 17.4255 10.7036 17.2836C13.7735 15.1996 15.1028 13.7707 15.836 12.877C17.3985 10.973 18.1465 9.01758 18.1254 6.90039C18.1008 4.47422 16.1543 2.5 13.7864 2.5ZM10.0004 16.25V8.10859C10.0004 7.3418 10.2047 6.59453 10.5555 5.9125C10.5564 5.91034 10.5574 5.90825 10.5586 5.90625C10.7862 5.49546 11.0727 5.12025 11.409 4.79258C12.1172 4.10156 12.9168 3.75 13.7864 3.75C15.4719 3.75 16.8575 5.16875 16.875 6.91406C16.9145 11.0004 13.4235 13.9262 10.0004 16.25Z"
      fill="currentColor"
    />
  </SvgIcon>
);
