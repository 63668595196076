import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TabletPortraitIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15 0.625H5C3.96447 0.625 3.125 1.46447 3.125 2.5V17.5C3.125 18.5355 3.96447 19.375 5 19.375H15C16.0355 19.375 16.875 18.5355 16.875 17.5V2.5C16.875 1.46447 16.0355 0.625 15 0.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
