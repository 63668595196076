import { History } from 'history';

export type StoreHelpersConfig = {
  history: History;
};
export default function createHelpers({ history }: StoreHelpersConfig) {
  return {
    history,
  };
}
