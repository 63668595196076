import React, { ReactNode } from 'react';
import {
  Divider,
  IconButton,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Action, BaseActionsMenu } from '../Dropdowns';
import BaseTypography from '../Text/BaseTypography';
import MenuLogo from '../Navbar/MenuLogo';
import { ChevronLeft } from '@material-ui/icons';
import { NonHoverBackground, NonHoverBorder } from 'src/theme/colors';
import cx from 'classnames';

interface Props {
  title: ReactNode;
  actions?: Action[];
  actionMenuAnchorSetter?: (anchorEl: HTMLElement | null) => void;
  onBackClick?: () => void;
  AdditionalSlotsComponents?: React.ReactNode[];
  isChannelsListShown?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '60px',
      width: '-webkit-fill-available',
      borderBottom: `1px solid ${NonHoverBorder}`,
      boxSizing: 'border-box',
      // on mobile screens
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2.5),
      },
      // desktop screens
      [theme.breakpoints.up('md')]: {
        padding: (props: Pick<Props, 'isChannelsListShown'>) =>
          props.isChannelsListShown
            ? theme.spacing(0, 2.5)
            : theme.spacing(0, 4.5),
      },
    },
    titleSlot: {
      gap: theme.spacing(1.5),
    },
    titleText: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '100%',
      marginRight: theme.spacing(2.5),
    },
    goBackBtn: {
      padding: 0,
      height: 30,
      width: 28,
      border: `1px solid ${NonHoverBackground}`,
      // hide the back button on desktop screen
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    actionsGap: {
      gap: theme.spacing(1.5),
    },
    actionsShrink: {
      flexShrink: 0,
    },
  }),
);

// ref is used to get the width of the toolbar container.
// This is used to calculate the width of the search input when expanded.
export const ChannelContentToolbar = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      actions,
      title,
      actionMenuAnchorSetter,
      onBackClick: handleBackClick,
      AdditionalSlotsComponents = [],
      isChannelsListShown = true,
    },
    ref,
  ) => {
    const classes = useStyles({ isChannelsListShown });

    return (
      <div className={classes.root} ref={ref}>
        <div
          className={cx(classes.actions, {
            [classes.actionsGap]: title,
          })}
        >
          {handleBackClick ? (
            <IconButton className={classes.goBackBtn} onClick={handleBackClick}>
              <ChevronLeft style={{ fontSize: 12 }} />
            </IconButton>
          ) : (
            <MenuLogo />
          )}
          <BaseTypography className={classes.titleText} fontType="13Medium">
            {title}
          </BaseTypography>
        </div>
        <div
          className={cx(classes.actions, classes.actionsShrink, {
            [classes.actionsGap]: actions && actions.length > 0,
          })}
        >
          {actions && actions.length > 0 && (
            <BaseActionsMenu
              contained
              actions={actions}
              onActionButtonAnchorElSet={actionMenuAnchorSetter}
            />
          )}
          {AdditionalSlotsComponents.length > 0 &&
            actions &&
            actions.length > 0 && <Divider orientation="vertical" flexItem />}
          {AdditionalSlotsComponents.map((ComponentNode, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`additional-toolbar-slot${index}`}>
              {ComponentNode}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  },
);
