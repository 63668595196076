import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoPlayerWrapper: {
      '& iframe': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderTopLeftRadius: theme.shape.borderRadius,
      },
    },
  }),
);
interface ResourceCardVideoPlayerProps {
  videoUrl: string;
  isPlayingVideo: boolean;
}
export const ResourceCardVideoPlayer: React.FC<
  ResourceCardVideoPlayerProps
> = ({ videoUrl, isPlayingVideo }) => {
  const classes = useStyles();

  if (!isPlayingVideo) return null;

  return (
    <div
      className={classes.videoPlayerWrapper}
      data-testid="video-player-wrapper"
    >
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="500px"
        config={{
          youtube: {
            playerVars: { showinfo: 0, controls: 1, autoplay: 1 },
          },
        }}
      />
    </div>
  );
};
