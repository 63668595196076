import { FieldVisibility, TablePropertyFields } from 'src/constants/dataTypes';

export const DefaultSubscriptionColumnSettings: TablePropertyFields = {
  tablePropertyFields: [
    {
      id: 'client',
      name: 'client',
      disabled: false,
    },
    {
      id: 'price',
      name: 'Price',
      disabled: false,
    },
    {
      id: 'billingPeriod',
      name: 'Billing period',
      disabled: false,
    },
    {
      id: 'status',
      name: 'Status',
      disabled: false,
    },
    {
      id: 'date',
      name: 'Created',
      disabled: false,
    },
    {
      id: 'nextPayment',
      name: 'Next payment',
      disabled: false,
    },
  ] as FieldVisibility[],
};
