import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ListIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.25 5.625H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 10H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 14.375H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 6.25C3.47018 6.25 3.75 5.97018 3.75 5.625C3.75 5.27982 3.47018 5 3.125 5C2.77982 5 2.5 5.27982 2.5 5.625C2.5 5.97018 2.77982 6.25 3.125 6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 10.625C3.47018 10.625 3.75 10.3452 3.75 10C3.75 9.65482 3.47018 9.375 3.125 9.375C2.77982 9.375 2.5 9.65482 2.5 10C2.5 10.3452 2.77982 10.625 3.125 10.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 15C3.47018 15 3.75 14.7202 3.75 14.375C3.75 14.0298 3.47018 13.75 3.125 13.75C2.77982 13.75 2.5 14.0298 2.5 14.375C2.5 14.7202 2.77982 15 3.125 15Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
