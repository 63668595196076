import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GiftIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 4.0625V6.25H12.1875C12.6201 6.25 13.0431 6.12171 13.4028 5.88134C13.7625 5.64098 14.0429 5.29933 14.2085 4.89962C14.3741 4.49991 14.4174 4.06007 14.333 3.63574C14.2486 3.21141 14.0402 2.82163 13.7343 2.51571C13.4284 2.20978 13.0386 2.00144 12.6143 1.91703C12.1899 1.83263 11.7501 1.87595 11.3504 2.04151C10.9507 2.20708 10.609 2.48746 10.3687 2.84719C10.1283 3.20692 10 3.62985 10 4.0625V4.0625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10 4.0625C10 4.66641 10 6.25 10 6.25H7.8125C7.37985 6.25 6.95692 6.12171 6.59719 5.88134C6.23746 5.64098 5.95708 5.29933 5.79151 4.89962C5.62595 4.49991 5.58263 4.06007 5.66703 3.63574C5.75144 3.21141 5.95978 2.82163 6.26571 2.51571C6.57163 2.20978 6.96141 2.00144 7.38574 1.91703C7.81007 1.83263 8.24991 1.87595 8.64962 2.04151C9.04934 2.20708 9.39098 2.48746 9.63134 2.84719C9.87171 3.20692 10 3.62985 10 4.0625V4.0625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.25 6.25H3.75C3.05964 6.25 2.5 6.80964 2.5 7.5V9.375C2.5 10.0654 3.05964 10.625 3.75 10.625H16.25C16.9404 10.625 17.5 10.0654 17.5 9.375V7.5C17.5 6.80964 16.9404 6.25 16.25 6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 10.625V16.25C16.25 16.7473 16.0525 17.2242 15.7008 17.5758C15.3492 17.9275 14.8723 18.125 14.375 18.125H5.625C5.12772 18.125 4.65081 17.9275 4.29917 17.5758C3.94754 17.2242 3.75 16.7473 3.75 16.25V10.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.25V18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
