import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LeafIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.5734 6.69621C9.10109 4.45324 5.50734 6.0634 2.18703 2.54777C1.41359 1.72746 1.86281 11.747 6.01906 15.5439C9.05773 18.3173 13.7495 18.0079 15.3316 15.3126C16.9136 12.6173 16.0456 8.93879 12.5734 6.69621Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75781 9.88281C10.1172 13.0469 13.5938 14.9219 18.1641 15.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
