import * as Weights from 'src/theme/weights';
import { BlackHeadings } from 'src/theme/colors';
import { CLIENT_FONTFAMILY_OPTIONS, DEFAULT_FONTFAMILY } from 'src/constants';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const generateTypography = (portalConfig: any): TypographyOptions => {
  // for clients
  // get the font family from the portalConfig

  const isClient = portalConfig.viewMode === 'client';

  // font family for client user
  // if we don't have font family in portal config
  // use default font family
  const { clientFontFamily } = portalConfig.brand;

  const clientFontFamilyLabel =
    // get the font family label
    CLIENT_FONTFAMILY_OPTIONS.find((f) => f.value === clientFontFamily)
      ?.label || DEFAULT_FONTFAMILY;

  // internal user font family
  const internalUserFontFamily = DEFAULT_FONTFAMILY;

  const portalFontFamily = isClient
    ? clientFontFamilyLabel
    : internalUserFontFamily;

  const fontFamily = [
    `"${portalFontFamily}"`,
    '"Helvetica"',
    '"Arial"',
    'san-serif',
  ].join(',');

  return {
    fontFamily,
    h1: {
      color: BlackHeadings,
      fontWeight: Weights.medium,
      fontSize: '58px',
      letterSpacing: '-0.24px',
      lineHeight: '70px',
    },
    h2: {
      color: BlackHeadings,
      fontWeight: Weights.medium,
      fontSize: '24px',
      letterSpacing: '-0.24px',
      lineHeight: '30px',
    },
    h3: {
      color: BlackHeadings,
      fontStyle: 'normal',
      fontWeight: Weights.medium,
      fontSize: '18px',
      lineHeight: '24px',
    },
    h4: {
      color: BlackHeadings,
      fontStyle: 'normal',
      fontWeight: Weights.medium,
      fontSize: '15px',
      lineHeight: '24px',
    },
    h5: {
      color: BlackHeadings,
      fontWeight: Weights.regular,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    },
    h6: {
      color: BlackHeadings,
      fontStyle: 'normal',
      fontWeight: Weights.regular,
      fontSize: '12px',
      lineHeight: '24px',
    },
    subtitle1: {
      color: BlackHeadings,
      fontWeight: Weights.regular,
      fontSize: '9px',
      letterSpacing: '-0.05px',
      lineHeight: '25px',
    },
    subtitle2: {
      color: BlackHeadings,
      fontWeight: Weights.regular,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body1: {
      color: BlackHeadings,
      fontWeight: Weights.regular,
      fontSize: '13px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    },
    body2: {
      fontStyle: 'normal',
      fontSize: '13px',
      lineHeight: '24px',
      fontWeight: Weights.medium,
    },
    button: {
      color: BlackHeadings,
      fontSize: '16px',
      textTransform: 'none',
    },
    caption: {
      color: portalConfig.theme.palette.text.secondary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px',
    },
    overline: {
      color: portalConfig.theme.palette.text.secondary,
      fontSize: '11px',
      fontWeight: Weights.semiBold,
      letterSpacing: '0.33px',
      lineHeight: '13px',
      textTransform: 'uppercase',
    },
  };
};
