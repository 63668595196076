import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PrismIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.97339 1.79219L1.46558 12.8043C1.36705 12.9485 1.30004 13.1118 1.26891 13.2836C1.23778 13.4554 1.24325 13.6319 1.28494 13.8015C1.32663 13.971 1.40362 14.1299 1.51087 14.2677C1.61812 14.4055 1.75323 14.5191 1.90738 14.6012L9.41363 18.6039C9.59346 18.6999 9.79416 18.7501 9.998 18.7501C10.2018 18.7501 10.4026 18.6999 10.5824 18.6039L18.0902 14.5996C18.2443 14.5176 18.3794 14.4039 18.4867 14.2661C18.594 14.1283 18.6709 13.9695 18.7126 13.7999C18.7543 13.6303 18.7598 13.4539 18.7287 13.282C18.6975 13.1102 18.6305 12.9469 18.532 12.8027L11.0257 1.79219C10.9118 1.6251 10.7587 1.48837 10.5799 1.39388C10.401 1.29939 10.2018 1.25 9.99957 1.25C9.7973 1.25 9.5981 1.29939 9.41927 1.39388C9.24043 1.48837 9.08737 1.6251 8.97339 1.79219Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.25V18.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
