import moment from 'moment';
import React from 'react';
import {
  ACTIVE_BILLING_PLATFORM_PLANS,
  LEGACY_BILLING_PLANS,
} from 'src/constants';
import { useCustomerSubscription } from 'src/hooks/useCustomerSubscription';

export const usePlanInfo = () => {
  const { stripeCustomer, customerSubscription } = useCustomerSubscription();

  const activeBillingPlans = Object.values(ACTIVE_BILLING_PLATFORM_PLANS);

  const {
    planName,
    currentPeriodEndDate,
    cancelAtPeriodEnd,
    isLegacyEnterprisePlan,
    isLegacyPlan,
  } = React.useMemo(() => {
    const mainSubscriptionItem = customerSubscription?.items?.data[0];

    let plan = '';
    if (mainSubscriptionItem?.price.id.includes('pro')) {
      plan = 'Professional plan';
    } else if (mainSubscriptionItem?.price.id.includes('basic')) {
      plan = 'Basic plan';
    } else if (mainSubscriptionItem?.price.id.includes('standard')) {
      plan = 'Standard plan';
    } else if (mainSubscriptionItem?.price.id.includes('enterprise')) {
      plan = 'Enterprise plan';
    } else if (mainSubscriptionItem?.price.id.includes('starter')) {
      plan = 'Starter Plan';
    } else if (mainSubscriptionItem?.price.id.includes('professional')) {
      plan = 'Professional Plan';
    } else if (mainSubscriptionItem?.price.id.includes('advanced')) {
      plan = 'Advanced Plan';
    }

    const periodEndDate = customerSubscription
      ? moment(new Date(customerSubscription.current_period_end * 1000)).format(
          'MMMM Do, YYYY',
        )
      : undefined;

    return {
      planName: plan,
      currentPeriodEndDate: periodEndDate,
      cancelAtPeriodEnd: Boolean(customerSubscription?.cancel_at_period_end),
      isLegacyPlan:
        Boolean(customerSubscription) && // In case of trial plan, customer subscription is undefined. Also, trial plan is not legecy plan
        !activeBillingPlans.includes(mainSubscriptionItem?.price.id || ''),
      isLegacyEnterprisePlan:
        mainSubscriptionItem?.price.id === LEGACY_BILLING_PLANS.ENTERPRISE,
    };
  }, [stripeCustomer, customerSubscription]);

  return {
    planName,
    currentPeriodEndDate,
    cancelAtPeriodEnd,
    isLegacyEnterprisePlan,
    isLegacyPlan,
  };
};
