import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ChatbubblesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.8359 12.5234C16.7969 12.3828 16.8828 12.1875 16.9648 12.0469C16.9899 12.005 17.0173 11.9646 17.0469 11.9258C17.7485 10.8831 18.1239 9.65519 18.125 8.39844C18.1367 4.79688 15.0977 1.875 11.3398 1.875C8.0625 1.875 5.32812 4.10547 4.6875 7.06641C4.59166 7.50526 4.54321 7.95314 4.54297 8.40234C4.54297 12.0078 7.46484 15.0078 11.2227 15.0078C11.8203 15.0078 12.625 14.8281 13.0664 14.707C13.5078 14.5859 13.9453 14.4258 14.0586 14.3828C14.1747 14.339 14.2978 14.3165 14.4219 14.3164C14.5573 14.3159 14.6914 14.3425 14.8164 14.3945L17.0313 15.1797C17.0798 15.2002 17.1311 15.2134 17.1836 15.2188C17.2665 15.2188 17.346 15.1858 17.4046 15.1272C17.4632 15.0686 17.4961 14.9891 17.4961 14.9063C17.4934 14.8705 17.4868 14.8351 17.4766 14.8008L16.8359 12.5234Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.59612 9.0625C2.08379 9.98291 1.83595 11.0271 1.88 12.0795C1.92405 13.132 2.25827 14.1518 2.84573 15.0262C2.93596 15.1625 2.98674 15.268 2.97112 15.3387C2.95549 15.4094 2.5051 17.7555 2.5051 17.7555C2.49427 17.8104 2.49838 17.8672 2.517 17.9199C2.53562 17.9727 2.56807 18.0195 2.61096 18.0555C2.66819 18.1011 2.73935 18.1256 2.81252 18.125C2.85162 18.1251 2.89033 18.1171 2.9262 18.1016L5.1219 17.2422C5.27302 17.1826 5.4416 17.1854 5.59065 17.25C6.33049 17.5383 7.14846 17.7188 7.96682 17.7188C9.06497 17.7199 10.1439 17.4303 11.0938 16.8793"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
