import React from 'react';
import { makeStyles } from '@material-ui/core';
import { NonHoverBorder } from 'src/theme/colors';

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    width: 40,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& > *': {
      position: 'absolute',
      bottom: 0,
      border: `1px solid ${NonHoverBorder}`,
      // first avatar size is 30*30
      '&:first-child': {
        width: 30,
        height: 30,
        zIndex: 2,
      },
      // second avatar size is 25*25
      '&:nth-child(2)': {
        width: 25,
        height: 25,
        left: 15,
        bottom: 15,
        zIndex: 1,
      },
      // third avatar size is 20*20
      '&:nth-child(3)': {
        width: 20,
        height: 20,
        bottom: 20,
        zIndex: 0,
      },
    },
  },
}));

/**
 * This component wraps a list of avatars and render them in a group.
 */
export const AvatarGroup: React.FC = ({ children }): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
