import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PizzaIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.8111 4.80781C13.999 4.06953 12.0974 3.75 10.0001 3.75C7.90287 3.75 5.8556 4.10156 4.18373 4.79219C3.8681 4.92109 3.58763 5.14375 3.79037 5.55391C3.95482 5.88711 7.92982 14.6484 9.43998 17.7734C9.49084 17.879 9.57047 17.9681 9.6697 18.0304C9.76893 18.0927 9.88373 18.1258 10.0009 18.1258C10.1181 18.1258 10.2329 18.0927 10.3321 18.0304C10.4314 17.9681 10.511 17.879 10.5619 17.7734L16.1888 5.57734C16.3134 5.30781 16.224 4.97578 15.8111 4.80781Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M17.0461 3.22969C15.0121 2.425 12.5067 1.875 10 1.875C7.49338 1.875 5.02541 2.37422 2.94846 3.20625C2.76525 3.28125 2.42189 3.4543 2.51603 3.74531L2.89416 4.6875C3.05041 5.00781 3.23283 5.00781 3.48244 5.00781C3.55236 5.00781 3.65002 4.96875 3.76681 4.92266C5.75443 4.15062 7.86774 3.75304 10 3.75C12.2172 3.75 14.4887 4.21875 16.2344 4.92188C16.3746 4.97656 16.4528 5.00703 16.5188 5.00703C16.7758 5.00703 16.9242 5.09258 17.1047 4.69063L17.4863 3.75C17.5649 3.51562 17.2914 3.32734 17.0461 3.22969Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M7.5 8.75C8.19036 8.75 8.75 8.19036 8.75 7.5C8.75 6.80964 8.19036 6.25 7.5 6.25C6.80964 6.25 6.25 6.80964 6.25 7.5C6.25 8.19036 6.80964 8.75 7.5 8.75Z"
      fill="currentColor"
    />
    <path
      d="M12.5 9.375C13.1904 9.375 13.75 8.81536 13.75 8.125C13.75 7.43464 13.1904 6.875 12.5 6.875C11.8096 6.875 11.25 7.43464 11.25 8.125C11.25 8.81536 11.8096 9.375 12.5 9.375Z"
      fill="currentColor"
    />
    <path
      d="M10 13.75C10.6904 13.75 11.25 13.1904 11.25 12.5C11.25 11.8096 10.6904 11.25 10 11.25C9.30964 11.25 8.75 11.8096 8.75 12.5C8.75 13.1904 9.30964 13.75 10 13.75Z"
      fill="currentColor"
    />
  </SvgIcon>
);
