import { Events } from 'src/constants/webSocketConsts/events';
import {
  insertFiles,
  filesDeletedAction,
  filesUpdatedAction,
} from 'src/store/files/actions';
import { ensureUnreachable } from 'src/utils/common_utils';

const FileHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
      return insertFiles(items);

    case Events.Remove:
      return filesDeletedAction(items.map((item) => item.id));

    case Events.Modify:
      return filesUpdatedAction(
        items.map((item) => item.id),
        items,
      );

    case Events.DomainVerified:
    case Events.Refresh:
      return null;

    default:
      return ensureUnreachable(event);
  }
};

export default FileHandler;
