export const UserRoles = {
  InternalAdmin: 'role_internalAdmin',
  ClientAdmin: 'role_clientAdmin',
  InternalStaff: 'role_internalStaff',
  ClientStaff: 'role_clientStaff',
};

export const UserAttributeKeys = {
  CompanyId: 'custom:companyName',
};

export const InternalUserCompanyId = 'default';
