export interface RecursiveLoadableResponse<T> {
  data: T[];
  nextToken?: string;
}

export type Params = Record<
  string,
  string | boolean | number | undefined | null
>;

export async function recursiveLoad<T>(
  fetch: ({
    queryStringParameters,
  }: {
    queryStringParameters: Params;
  }) => Promise<RecursiveLoadableResponse<T>>,
  baseQueryOptions: Params,
): Promise<{ data: T[] }> {
  let items: T[] = [];
  let nextToken: string | undefined;

  while (true) {
    const queryStringParameters: Params = {
      ...baseQueryOptions,
    };

    if (nextToken) {
      queryStringParameters['nextToken'] = nextToken;
    }

    const response: RecursiveLoadableResponse<T> = await fetch({
      queryStringParameters,
    });

    const responseItems = response?.data ? response.data : [];
    items = [...items, ...responseItems];

    nextToken = response?.nextToken;
    if (!nextToken) {
      break;
    }
  }

  return { data: items };
}
