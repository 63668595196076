import { API } from 'src/utils/AmplifyApiUtils';
import { BaseEntity } from 'src/constants';
import { Tag } from 'src/store/tags/types';

export default class TagsClient {
  /**
   * Fetch a list of all tags in the portal
   */
  static getTags() {
    return API.get('AppAPI', '/entities/tags', {});
  }

  /**
   * Create a new Tag object given StructFields as the input
   */
  static createTag(newTagStruct: Partial<BaseEntity>) {
    return API.post('AppAPI', '/entities/tags', { body: newTagStruct });
  }

  /**
   * Update a tag through API to match entity passed into this method
   */
  static updateTag(updatedTag: Tag) {
    return API.put('AppAPI', '/entities/tags', { body: updatedTag });
  }

  /**
   * Delete a tag based on ID
   */
  static deleteTag(id: string) {
    return API.del('AppAPI', '/entities/tags', { body: { id } });
  }
}
