import { Grid, Theme, Tooltip, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import {
  InformationCircleIcon,
  SignatureDateIcon,
  SignatureTextIcon,
} from 'src/legacy/components/Icons';
import {
  SignaturePopoverForm,
  SignatureSidebarButton,
} from 'src/legacy/components/Signature/SignatureComponents';
import { useClientInputLabelForm } from 'src/legacy/components/Signature/SignatureSidebar/useClientInputLabelForm';
import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  CONTRACT_COMPONENT_DEFAULT_WIDTH,
  SignatureComponentType,
} from 'src/constants';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';
import { InputType } from 'src/services/api';
import {
  AddComponentAction,
  AddPlaceholderComponentAction,
  RemovePlaceholderComponentAction,
  SetSelectedComponent,
} from 'src/store/signaturePage/actions';
import {
  ComponentCoordinatesType,
  SignaturePageComponent,
} from 'src/store/signaturePage/types';
import { useAppSelector } from 'src/hooks/useStore';
import { alertSnackbar } from 'src/store/ui/actions';
import {
  getFieldTypeUsingComponentType,
  hasSignatureCompnentsWithDuplicateLabels,
} from 'src/utils/ContractUtils';
import MemoBaseTypography from 'src/legacy/components/Text/BaseTypography';
import { GraySmall } from 'src/theme/colors';

const VARIABLE_INPUT_TOOLTIP_TEXT = `Each time you share a contract with a client, you'll be prompted to provide these values.`;

const useStyles = makeStyles((theme: Theme) => ({
  inputTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
  },
  tooltipIcon: {
    height: 12,
    width: 12,
    color: GraySmall,
  },
}));

/**
 * This component renders the contract sidebar variable inputs
 */
export const VariableInputs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [droppedComponent, setDroppedComponent] =
    useState<SignaturePageComponent | null>(null);
  const { placeholderElement } = useContext(ContractBuilderContext);
  const [labelModalInfo, setLabelModalInfo] = useState<{
    isOpen: boolean;
    componentType: SignatureComponentType | null;
  }>({
    isOpen: false,
    componentType: null,
  });
  const pageComponents = useAppSelector(
    (state) => state.signaturePage.pageComponents,
  );

  const handleComponentDropped = (
    coords: ComponentCoordinatesType,
    componentType: SignatureComponentType,
  ) => {
    const key = v4();
    const stagedComponent = {
      componentType,
      key,
      fields: { key, value: '' },
      ...coords,
      height: CONTRACT_COMPONENT_DEFAULT_HEIGHT,
      width: CONTRACT_COMPONENT_DEFAULT_WIDTH,
    };

    setDroppedComponent(stagedComponent);

    dispatch(AddPlaceholderComponentAction(stagedComponent));
    setLabelModalInfo({
      isOpen: true,
      componentType,
    });
  };

  const handleCloseModal = () => {
    setLabelModalInfo({
      isOpen: false,
      componentType: null,
    });
    dispatch(RemovePlaceholderComponentAction());
  };

  const handleSaveComponent = (formValues: { label: string }) => {
    if (!droppedComponent) return;

    const hasDuplicateLabels = hasSignatureCompnentsWithDuplicateLabels(
      pageComponents,
      formValues.label,
    );

    if (hasDuplicateLabels) {
      dispatch(
        alertSnackbar({
          errorMessage: 'This label is already in use. Please try again.',
        }),
      );
      return;
    }

    const stagedComponent = {
      ...droppedComponent,
      fields: {
        key: droppedComponent.key,
        value: '',
      },
      label: formValues.label,
      name: formValues.label,
      inputType: InputType.Variable,
      fieldType: getFieldTypeUsingComponentType(droppedComponent.componentType),
    };
    dispatch(AddComponentAction(stagedComponent));
    dispatch(SetSelectedComponent(stagedComponent.key));

    handleCloseModal();
  };

  return (
    <>
      <div className={classes.inputTitle}>
        <MemoBaseTypography fontType="12Medium">
          Variable inputs
        </MemoBaseTypography>
        <Tooltip title={VARIABLE_INPUT_TOOLTIP_TEXT}>
          <div>
            <InformationCircleIcon className={classes.tooltipIcon} />
          </div>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SignatureSidebarButton
            label="Date"
            onDrop={(coordinates) =>
              handleComponentDropped(
                coordinates,
                SignatureComponentType.REQUEST_DATE,
              )
            }
            buttonIcon={SignatureDateIcon}
            isClientSignatureButton
          />
        </Grid>
        <Grid item xs={12}>
          <SignatureSidebarButton
            label="Text"
            onDrop={(coords) =>
              handleComponentDropped(
                coords,
                SignatureComponentType.REQUEST_TEXT,
              )
            }
            buttonIcon={SignatureTextIcon}
            isClientSignatureButton
          />
        </Grid>
      </Grid>
      <SignaturePopoverForm
        key="variable-inputs"
        initialFormValue={{
          label: '',
        }}
        title={`Variable ${
          labelModalInfo.componentType === SignatureComponentType.REQUEST_DATE
            ? 'date'
            : 'text'
        } field`}
        actionLabel="Add"
        handleSave={handleSaveComponent}
        onClose={handleCloseModal}
        useFormHook={() => useClientInputLabelForm({ isRequired: true })}
        open={labelModalInfo.isOpen}
        anchorEl={placeholderElement}
      />
    </>
  );
};
