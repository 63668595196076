import { BaseEntity } from 'src/constants/dataTypes/entityTypes';

export enum PortalChannelType {
  Client = 'client',
  Company = 'company',
}

export interface PortalConfigEntityFields {
  regionId: string;
  accountId: string;
  disableCompanies: boolean;
  defaultChannelType: PortalChannelType;
}

// This is the portal_config entity type.
// ownerId points to the json config file in s3
export interface PortalConfigEntity extends BaseEntity {
  structFields: Partial<PortalConfigEntityFields>;
}
