import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { MENU_LOGO_WIDTH } from 'src/legacy/components/Navbar/MenuLogo';

type StyleProps = {
  hasTitle: boolean;
};

export const usePageElementsStyles = makeStyles((theme) =>
  createStyles<string, StyleProps>({
    toolbarContainer: (props) => ({
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        width: 0,
        minWidth: `calc(100% - ${MENU_LOGO_WIDTH}px)`,
        padding: theme.spacing(1.5, 0),
      },
      width: '100%',
      alignItems: 'center',
      justifyContent: props.hasTitle ? 'space-between' : 'flex-end',
    }),
    pageTitleBoxPadding: {
      padding: '12px 0px',
      [theme.breakpoints.up('sm')]: {
        marginRight: 'auto',
        flexGrow: 100,
      },
    },
    densePadding: {
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
      marginRight: 'auto',
      flexGrow: 100,
    },
    rightBox: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
        flexGrow: 1,
        whiteSpace: 'nowrap',
      },
    },
    searchBox: {
      [theme.breakpoints.down('xs')]: {
        flexGrow: 1,
        marginRight: theme.spacing(1),
      },
    },
    rightSpace: {
      marginRight: theme.spacing(4),
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    toolBarTitle: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      transition: 'all 0.5s',
      marginRight: theme.spacing(1),
    },
  }),
);

interface PageToolbarProps {
  actionButtons: ReactElement[] | ReactElement;
  title?: string | React.ReactNode;
  toolbarContainerClassName?: string;
}

export const PageToolbar: React.FC<PageToolbarProps> = ({
  actionButtons,
  title,
  toolbarContainerClassName,
}) => {
  const classes = usePageElementsStyles({
    hasTitle: Boolean(title),
  });

  const titleRenderer = () => (
    <BaseTypography className={classes.toolBarTitle} fontType="13Medium">
      {title}
    </BaseTypography>
  );

  return (
    <div
      className={[
        classes.toolbarContainer,
        toolbarContainerClassName ?? '',
      ].join(' ')}
    >
      {title ? titleRenderer() : null}
      <div className={classes.actionsContainer}>{actionButtons}</div>
    </div>
  );
};
