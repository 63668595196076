import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WalletIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.25 5.625H3.75C2.71447 5.625 1.875 6.46447 1.875 7.5V15C1.875 16.0355 2.71447 16.875 3.75 16.875H16.25C17.2855 16.875 18.125 16.0355 18.125 15V7.5C18.125 6.46447 17.2855 5.625 16.25 5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M16.0688 5.6251V4.45323C16.0687 4.16581 16.0051 3.88195 15.8827 3.62191C15.7603 3.36187 15.582 3.13206 15.3605 2.94886C15.139 2.76567 14.8798 2.63362 14.6015 2.56212C14.3231 2.49063 14.0323 2.48146 13.75 2.53526L3.4625 4.29112C3.01575 4.37626 2.61272 4.61463 2.32291 4.96512C2.0331 5.31561 1.87469 5.75625 1.875 6.21104V8.1251"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 12.5C14.1278 12.5 13.8861 12.4267 13.6805 12.2893C13.475 12.152 13.3148 11.9568 13.2202 11.7284C13.1255 11.4999 13.1008 11.2486 13.149 11.0061C13.1973 10.7637 13.3163 10.5409 13.4911 10.3661C13.6659 10.1913 13.8887 10.0723 14.1311 10.024C14.3736 9.97579 14.6249 10.0005 14.8534 10.0952C15.0818 10.1898 15.277 10.35 15.4143 10.5555C15.5517 10.7611 15.625 11.0028 15.625 11.25C15.625 11.5815 15.4933 11.8995 15.2589 12.1339C15.0245 12.3683 14.7065 12.5 14.375 12.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
