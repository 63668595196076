import { Box, IconButton, makeStyles, createStyles } from '@material-ui/core';
import React, { ComponentProps } from 'react';
import { v4 } from 'uuid';
import { shallowEqual, useSelector } from 'react-redux';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import { MultiSelectCustomField } from 'src/store/clients/types';
import { BaseMenu } from 'src/legacy/components/Dropdowns';
import {
  MoreActionsIcon,
  PlusIcon,
  ReorderIcon,
  TrashIcon,
} from 'src/legacy/components/Icons';
import { SelectDropDown } from 'src/legacy/components/Select/SelectDropdown';
import {
  MultiSelectOption,
  SelectOption,
} from 'src/legacy/components/Select/types';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import {
  BaseChip,
  SeverityLevel,
  TruncatedText,
} from 'src/legacy/components/UI';
import {
  MultiSelectOptionsColors,
  GraySmall,
  red,
  DividersAndCardBorders,
  LightGray,
  NonHoverBorder,
} from 'src/theme/colors';
import ColorUtils from 'src/utils/ColorUtils';
import { CustomFieldValueData } from 'src/legacy/components/ClientDetailsPage/clientDetailsTypes';
import { MultiSelectInput } from 'src/legacy/components/MultiSelect/MultiSelectInput';
import { RootState } from 'src/store';
import { BaseTextField } from 'src/legacy/components/TextField';
import { partition } from 'src/utils/array';
import { SortableMultiSelectList } from 'src/legacy/components/MultiSelect/SortableMultiSelectList';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '36px',
      display: 'flex',
      alignItems: 'center',
    },
    menuInput: {
      width: '200px',
      borderBottom: `1px solid ${DividersAndCardBorders}`,
      '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    dragIcon: {
      color: NonHoverBorder,
      fontSize: 10,
    },
  }),
);
interface MultiSelectProps {
  placeholder: string;
  initialFieldValue: string[];
  field: MultiSelectCustomField;
  onSaveProperty: (
    property: MultiSelectCustomField,
    deletedOptionKey?: string,
  ) => void;
  onSaveValue: (property: CustomFieldValueData) => void;
  tagsLimit?: number;
  shouldOpenMenu?: boolean;
  showPreviewPlaceholder?: boolean;
  onClose?: () => void;
  onTagsEmpty?: (isEmpty: boolean) => void;
}
export const MultiSelect: React.FC<MultiSelectProps> = ({
  onSaveProperty,
  onSaveValue,
  initialFieldValue,
  field,
  placeholder,
  showPreviewPlaceholder = true,
  tagsLimit = 3,
  shouldOpenMenu,
  onClose,
  onTagsEmpty,
}) => {
  const classes = useStyles();
  const multiSelectInputRef = React.useRef<HTMLDivElement | null>(null);
  const [fieldValue, setFieldValue] =
    React.useState<string[]>(initialFieldValue);

  React.useEffect(() => {
    setFieldValue(initialFieldValue);
  }, [initialFieldValue]);
  const portalCustomFields = useSelector(
    (state: RootState) =>
      state.clients.clientCustomFields?.additionalFields || {},
    shallowEqual,
  );
  // On CRM table, when  multi select options get updated (label/tag)
  // table row cannot trigger that change since field options are not part of
  // the client row data. For that reason we've defined multiSelectOptions
  // as memoized property which listen to portalCustomFields changes to get the
  // latest updated multi select field options state.
  const multiSelectOptions = React.useMemo(() => {
    const multiSelectField = portalCustomFields[
      field.id
    ] as MultiSelectCustomField;

    return multiSelectField && multiSelectField.options
      ? multiSelectField.options
      : [];
  }, [portalCustomFields]);
  const selectedTags = React.useMemo(() => {
    if (!fieldValue) return [];
    const tags: MultiSelectOption[] = [];
    fieldValue.forEach((tagId: string) => {
      // we should only show tag when it is existing in multi select field options.
      const selectedTag = multiSelectOptions.find((opt) => opt.id === tagId);
      if (selectedTag) {
        tags.push(selectedTag);
      }
    });

    // notify the parent component if filtered tags
    // are empty so that it update showing field icon.
    if (onTagsEmpty) onTagsEmpty(tags.length === 0);

    return tags;
  }, [multiSelectOptions, fieldValue]);
  const [multiSelectAnchorEl, setMultiSelectAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const [editingOption, setEditingOption] =
    React.useState<MultiSelectOption | null>(null);
  const [editOptionMenuAnchorEl, setEditOptionMenuAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [nextOptionColorIndex, setNextOptionColorIndex] = React.useState(0);
  /**
   * Here we're listening to shouldOpenMenu changes.
   * When it is truthy it should open the multi select menu
   * by setting the multi select input component ref as
   * menu anchor.
   */
  React.useEffect(() => {
    if (shouldOpenMenu && multiSelectInputRef.current) {
      setMultiSelectAnchorEl(multiSelectInputRef.current || null);
    }
  }, [shouldOpenMenu]);

  /**
   * This function will update the next tag color index.
   * Tags colors are  roundly assigned so we keep creating
   * new tag with new colors.
   */
  const updateNextOptionColor = () => {
    if (
      nextOptionColorIndex <
      Object.values(MultiSelectOptionsColors).length - 1
    ) {
      setNextOptionColorIndex(nextOptionColorIndex + 1);
    } else {
      setNextOptionColorIndex(0);
    }
  };
  /**
   * This function handle select dropdown change
   * event. When create-option item is selected
   * we need to update the property (custom field)
   * options with the added option. Otherwise it
   * @param _ev
   * @param selectedOptions
   * @returns
   */
  const handleChange: ComponentProps<
    typeof SelectDropDown
  >['onChange'] = async (_ev, selectedOptions) => {
    const selectedOption = (selectedOptions as MultiSelectOption[])[
      (selectedOptions as MultiSelectOption[]).length - 1
    ];
    if (selectedOption && selectedOption?.id === 'add-new-option-item') {
      const newOptionId = v4();
      const newOptionColor = Object.values(MultiSelectOptionsColors)[
        nextOptionColorIndex
      ];
      const newOption = {
        id: `option-${newOptionId}`,
        label: selectedOption.label,
        color: newOptionColor,
        order: 0,
      };
      const updatedMultiSelectProperty = {
        ...field,
        options: [...(multiSelectOptions || []), ...[newOption]],
      };

      await onSaveProperty(
        updatedMultiSelectProperty as MultiSelectCustomField,
      );

      // when new tag is created we should set it in field value
      onSaveValue({
        fieldId: field.id,
        value: (fieldValue || []).concat([newOption.id]),
      });
      setFieldValue((fieldValue || []).concat([newOption.id]));
      updateNextOptionColor();
      setEditOptionMenuAnchorEl(null);
    } else {
      const selectedOptionsIds = (selectedOptions as MultiSelectOption[]).map(
        (option) => option.id,
      );
      onSaveValue({
        fieldId: field.id,
        value: selectedOptionsIds,
      });
      setFieldValue(selectedOptionsIds);
    }
  };

  const handleOpenEditOptionMenu = (
    ev: React.MouseEvent<HTMLButtonElement>,
    option: MultiSelectOption,
  ) => {
    // when option menu button is clicked
    // we need to stop event propagation
    // so that change event is not triggered.
    ev.stopPropagation();
    setEditOptionMenuAnchorEl(ev.currentTarget);
    setEditingOption(option);
  };
  /**
   * When delete icon button is clicked from the edit menu,
   * this function is triggered to perform the delete action.
   * It removes the deleting option from options array
   * and calls saveProperty method to update the custom
   * field entity.
   */
  const handleRemoveOption = () => {
    const [[deletedOption], filteredMultiSelectPropertyOptions] = partition(
      multiSelectOptions,
      (option) => option.id === editingOption?.id,
    );
    onSaveProperty(
      {
        ...field,
        options: filteredMultiSelectPropertyOptions,
      },
      deletedOption.id,
    );
    // when a tag is removed from menu list, it should be
    // filtered from input values.
    const updatedFieldValue = selectedTags
      .filter((option) => option.id !== editingOption?.id)
      .map((option) => option.id);

    onSaveValue({
      fieldId: field.id,
      value: updatedFieldValue,
    });
    // close edit option menu after remove is performed
    setEditOptionMenuAnchorEl(null);
  };

  /**
   * This method is triggered when option color is
   * clicked. It takes the color value and update the
   * multi select property with updated option object.
   * @param colorVal: color option item value
   */
  const handleEditOptionColor = (colorVal: string) => {
    const updatedMultiSelectOptions = multiSelectOptions.map((option) => {
      if (option.id === editingOption?.id) {
        return {
          ...editingOption,
          color: colorVal,
        };
      }
      return option;
    });

    onSaveProperty({
      ...field,
      options: updatedMultiSelectOptions,
    } as MultiSelectCustomField);

    // close edit option menu after tag color is updated
    setEditOptionMenuAnchorEl(null);
  };

  /**
   * This function handle updating multi select option (tag)
   * label change. New label value is submitted when enter key
   * is pressed.
   * @param
   */
  const handleMultiSelectOptionLabelChange = (
    ev: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const optionLabelValue = ev.currentTarget.value;
    // when enter key is pressed updating option label
    // and call save property method to save it in custom
    // fields settings.
    if (ev.keyCode === 13) {
      const updatedMultiSelectOptions = multiSelectOptions.map((option) => {
        if (option.id === editingOption?.id) {
          return {
            ...editingOption,
            label: optionLabelValue,
          };
        }
        return option;
      });

      onSaveProperty({
        ...field,
        options: updatedMultiSelectOptions,
      });
      // close edit option menu after tag label is updated
      setEditOptionMenuAnchorEl(null);
    }
  };

  /**
   * This method handle swapping multiselect
   * options and save them inside the property
   * entity.
   * @param sortData
   * @returns
   */
  const handleSortOptions = (sortData: SortEnd) => {
    if (sortData.newIndex === sortData.oldIndex) return;
    const sortedMultiSelectOptions = arrayMove(
      multiSelectOptions,
      sortData.oldIndex,
      sortData.newIndex,
    );

    onSaveProperty({
      ...field,
      options: sortedMultiSelectOptions,
    });
  };
  // To prevent click on the control resulting in row click, event bubbling needs to be stopped.
  const handleMultiSelectInput = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    ev.stopPropagation();
    setMultiSelectAnchorEl(ev.currentTarget);
  };

  return (
    <>
      <div
        ref={multiSelectInputRef}
        className={classes.root}
        onClickCapture={handleMultiSelectInput}
      >
        {selectedTags.length > 0 && (
          <MultiSelectInput selectedTags={selectedTags} tagsLimit={tagsLimit} />
        )}

        {showPreviewPlaceholder && selectedTags.length === 0 && (
          <BaseTypography fontType="13Medium" style={{ color: LightGray }}>
            {placeholder}
          </BaseTypography>
        )}
      </div>
      {Boolean(multiSelectAnchorEl) && (
        <SelectDropDown<MultiSelectOption, true, true>
          creatable
          listHeaderTitle="Select a tag or create one"
          isMultiple
          onClose={() => {
            setMultiSelectAnchorEl(null);
            if (onClose) onClose();
          }}
          open={Boolean(multiSelectAnchorEl)}
          getOptionSelected={(option, value) => option.id === value.id}
          forcePopupIcon={false}
          placeholder={placeholder}
          renderTags={(opts, getTagProps) =>
            opts.map((option, index: number) => {
              let optionColor = '';
              if ('color' in option) {
                optionColor = option.color;
              }
              return (
                <BaseChip
                  deleteIconStyle={{
                    color: optionColor,
                    backgroundColor: 'transparent',
                  }}
                  style={{
                    backgroundColor:
                      ColorUtils.GetColorDarknessShades(optionColor).light,
                  }}
                  label={
                    <TruncatedText
                      styleProps={{
                        color: (option as MultiSelectOption).color,
                      }}
                      text={option.label}
                      maxChars={10}
                    />
                  }
                  {...getTagProps({ index })}
                />
              );
            })
          }
          // To be able to render tags inside the
          // menu input we should use text field
          // and not the default base menu input
          inputRenderer={(params) => (
            <BaseTextField
              autoFocus
              placeholder={placeholder}
              className={classes.menuInput}
              sizeVariant="medium"
              variant="outlined"
              {...params}
            />
          )}
          disableClearable
          anchorEl={multiSelectAnchorEl}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          onChange={handleChange}
          value={selectedTags}
          options={multiSelectOptions}
          // multi select options labels need to be wrapped
          // inside chips (tags), so we need to pass a custom
          // option renderer function as param.
          // eslint-disable-next-line react/no-unstable-nested-components
          customOptionRenderer={(option: SelectOption) =>
            option.id === 'add-new-option-item' ? (
              <Box display="flex" alignItems="center" key="add-new-option-item">
                <PlusIcon style={{ fontSize: 9 }} />
                <BaseTypography style={{ marginLeft: 8 }}>
                  Create
                </BaseTypography>
                <BaseChip
                  style={{
                    marginLeft: 4,
                    backgroundColor: ColorUtils.GetColorDarknessShades(
                      Object.values(MultiSelectOptionsColors)[
                        nextOptionColorIndex
                      ],
                    ).light,
                    color: Object.values(MultiSelectOptionsColors)[
                      nextOptionColorIndex
                    ],
                  }}
                  severity={SeverityLevel.low}
                  label={
                    <TruncatedText
                      styleProps={{
                        color: Object.values(MultiSelectOptionsColors)[
                          nextOptionColorIndex
                        ],
                      }}
                      text={option.label}
                      maxChars={10}
                    />
                  }
                />
              </Box>
            ) : (
              <Box display="flex" width={1} alignItems="center" key={option.id}>
                <ReorderIcon className={classes.dragIcon} />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width={1}
                  pl={2}
                >
                  <BaseChip
                    label={
                      <TruncatedText
                        styleProps={{
                          color: (option as MultiSelectOption).color,
                        }}
                        text={option.label}
                        maxChars={10}
                      />
                    }
                    style={{
                      backgroundColor: ColorUtils.GetColorDarknessShades(
                        (option as MultiSelectOption).color,
                      ).light,
                      color: (option as MultiSelectOption).color,
                    }}
                  />
                  <IconButton
                    aria-label="edit option actions"
                    aria-controls="multi-select-option-edit-menu"
                    aria-haspopup="true"
                    onClickCapture={(ev: React.MouseEvent<HTMLButtonElement>) =>
                      handleOpenEditOptionMenu(ev, option as MultiSelectOption)
                    }
                    color="secondary"
                    size="small"
                  >
                    <MoreActionsIcon
                      htmlColor={GraySmall}
                      style={{ fontSize: 20 }}
                    />
                  </IconButton>
                </Box>
              </Box>
            )
          }
          ListboxProps={{
            onSortTagsOptions: handleSortOptions,
          }}
          ListboxComponent={SortableMultiSelectList}
        />
      )}
      {/** This menu is the sub-menu showing the tags colors list */}
      {Boolean(editOptionMenuAnchorEl) && (
        <BaseMenu
          showTitleDivider={false}
          menuTitle={
            <BaseTypography fontType="10Medium">Colors</BaseTypography>
          }
          menuInputProps={{
            onKeyDown: handleMultiSelectOptionLabelChange,
            defaultValue: editingOption?.label,
            endAdornment: (
              <IconButton onClick={handleRemoveOption}>
                <TrashIcon style={{ color: red, fontSize: 12 }} />
              </IconButton>
            ),
          }}
          menuProps={{
            anchorEl: editOptionMenuAnchorEl,
            open: Boolean(editOptionMenuAnchorEl),
            onClose: () => setEditOptionMenuAnchorEl(null),
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 20,
              horizontal: 'center',
            },
          }}
          actions={Object.entries(MultiSelectOptionsColors).map(
            ([colorName, colorRgb]) => ({
              key: colorName,
              icon: (
                <Box
                  style={{
                    backgroundColor:
                      ColorUtils.GetColorDarknessShades(colorRgb).light,
                  }}
                  height={18}
                  width={18}
                  borderRadius={4}
                />
              ),
              name: colorName,
              onClick: () => handleEditOptionColor(colorRgb),
            }),
          )}
        />
      )}
    </>
  );
};
