import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    truncated: {
      display: 'inline-block',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: (props: { maxChars?: string }) =>
        props.maxChars !== undefined ? `${props.maxChars}ch` : '90%',
    },
  }),
);

interface TruncatedTextProps {
  styleProps?: any;
  text: string;
  prefix?: string;
  disableTruncation?: boolean;
  maxChars?: number;
  showTitle?: boolean;
  htmlId?: string;
}

export const TruncatedText: React.FC<TruncatedTextProps> = ({
  styleProps,
  text,
  prefix,
  disableTruncation = false,
  maxChars,
  showTitle,
  htmlId,
}) => {
  const classes = useStyles({ maxChars: maxChars?.toString() });

  const maxCharsToCheck = maxChars || 0;

  return (
    <Typography
      title={showTitle && text.length > maxCharsToCheck ? text : ''}
      component="span"
      style={styleProps}
    >
      {prefix && <span>{prefix}: </span>}
      <span
        data-testid={htmlId}
        className={!disableTruncation ? classes.truncated : ''}
      >
        {text}
      </span>
    </Typography>
  );
};
