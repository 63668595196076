import React, { useEffect } from 'react';
import Button from 'src/legacy/components/Button';
import { HomePageSection } from 'src/legacy/components/HomePage/HomePageSection';
import { IconNameToComponent } from 'src/legacy/components/Icons';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { Avatar } from 'src/legacy/components/UI/Avatars';
import { CLIENT_DETAILS_PAGE } from 'src/constants';
import history from 'src/history';
import { DEFAULT_APPS } from 'src/hooks/useApps';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import {
  GetRecentlyVisitedResponse,
  useGetRecentlyVisitedAppsQuery,
} from 'src/services/api/analyticsApi';
import {
  selectActiveCompaniesMap,
  selectClientsMap,
} from 'src/store/clients/types';
import { setHasRecentlyVisited } from 'src/store/ui/actions';
import { keys } from 'src/utils/ObjectUtils';
import { capitalize } from 'src/utils/StringUtils';
import { getInitials } from 'src/utils/UserUtils';

const isIconName = (
  icon: string | null | undefined,
): icon is keyof typeof IconNameToComponent =>
  !!icon && icon in IconNameToComponent;

const RecentLinkItem = ({
  link,
  label,
  avatarData,
  icon: AppIcon,
}: {
  link: string;
  label: string;
  icon?: React.ReactNode;
  avatarData?: {
    url: string;
    fallbackColor: string;
    initials: string;
    rounded?: boolean;
  } | null;
}) => (
  <Button
    className="whitespace-nowrap overflow-hidden"
    variant="contained"
    color="secondary"
    htmlId={label}
    startIcon={
      avatarData ? (
        <Avatar
          style={{ width: '20px', height: '20px' }} // TODO: remove this inline style
          src={avatarData.url}
          alt={label}
          fallbackColor={avatarData.fallbackColor}
          fallbackLetters={getInitials(label)}
          variant={avatarData.rounded ? 'rounded' : 'circle'}
        />
      ) : (
        AppIcon
      )
    }
    onClick={() => {
      history.push(link);
    }}
  >
    {label}
  </Button>
);

type RouteCategory = 'Crm' | 'Apps';
type RouteData = {
  clientId?: string;
  companyId?: string;
  moduleExtensionConfigId?: string;
};
const RecentlyVisitedInner = ({
  data,
}: {
  data: GetRecentlyVisitedResponse;
}) => {
  const activeClients = useAppSelector(selectClientsMap);
  const activeCompanies = useAppSelector(selectActiveCompaniesMap);
  const moduleSettings = useAppSelector(
    (state) => state.settings.moduleSettings ?? [],
  );

  const moduleSettingsMap = React.useMemo(() => {
    const map = new Map(moduleSettings.map((module) => [module.id, module]));
    return map;
  }, [moduleSettings]);

  const filteredData = React.useMemo(() => {
    return {
      Crm: data.Crm.filter(
        (crmItem) =>
          Boolean(activeClients.get(crmItem.clientId ?? '')) ||
          Boolean(activeCompanies.get(crmItem.companyId ?? '')),
      ),
      Apps: data.Apps.filter(
        (app) =>
          moduleSettingsMap.has(app.moduleExtensionConfigId) &&
          !moduleSettingsMap.get(app.moduleExtensionConfigId)?.disabled,
      ),
    };
  }, [data, activeClients, moduleSettingsMap]);

  const getAvatarInfo = (params: { clientId?: string; companyId?: string }) => {
    const { clientId, companyId } = params;
    if (clientId) {
      const client = activeClients.get(clientId);
      const clientFullName = `${client?.fields.givenName} ${client?.fields.familyName}`;
      const clientAvatarData = {
        url: client?.fields.avatarImageUrl ?? '',
        fallbackColor: client?.fields.fallbackColor,
        initials: getInitials(
          `${client?.fields.givenName} ${client?.fields.familyName}`,
        ),
      };

      return {
        label: clientFullName,
        avatarData: clientAvatarData,
      };
    }
    if (companyId) {
      const company = activeCompanies.get(companyId);
      return {
        label: company?.fields.name ?? '',
        avatarData: {
          url: company?.fields.avatarImageURL ?? '',
          fallbackColor: company?.fields.fallbackColor ?? '',
          initials: getInitials(company?.fields.name ?? ''),
        },
      };
    }

    return {
      label: '',
      avatarData: { url: '', initials: '', fallbackColor: '' },
    };
  };

  /**
   * This function is responsible of returning the full link
   * for the recently visited item. It iterates throw each category
   * The category can be CRM or Apps, and for each category it will
   * return the route info which includes the appRoute, appName, appIcon,
   * and the avatar data if it is a client details route.
   * @param category designate the route category, it can either be apps or crm
   * @param routeData holds the route metadata such the clientId and the moduleExtensionConfigID
   */
  const getRouteInfo = (category: RouteCategory, routeData: RouteData) => {
    let appRoute, appIcon, appName;
    let avatarData = null;
    const { clientId, companyId, moduleExtensionConfigId } = routeData;
    switch (category) {
      case 'Crm':
        appRoute = `${CLIENT_DETAILS_PAGE.path}?clientUserId=${clientId}`;
        if (companyId) {
          appRoute = `/companies/${companyId}`;
        }
        const { label, avatarData: avatarInfo } = getAvatarInfo({
          clientId,
          companyId,
        });
        appName = label;
        avatarData = {
          url: avatarInfo.url,
          fallbackColor: avatarInfo.fallbackColor ?? '',
          initials: avatarInfo.initials,
          rounded: Boolean(companyId),
        };
        break;
      case 'Apps':
        if (moduleExtensionConfigId) {
          const coreApp = DEFAULT_APPS.find(
            (a) => a.id === moduleExtensionConfigId,
          );
          appRoute = coreApp
            ? coreApp.path
            : `/apps?id=${moduleExtensionConfigId}`;

          // redirect payments recently visited routes to the default
          // billing route. TODO: we do plan to implement separate redirection
          // for each billing sub-route.
          if (moduleExtensionConfigId === 'payments') {
            appRoute = '/products';
          }
          appName = moduleSettingsMap.get(moduleExtensionConfigId)?.label;
          appIcon = moduleSettingsMap.get(moduleExtensionConfigId)?.icon;
        }
        break;
      default:
        appRoute = '';
        appName = '';
        appIcon = null;
        break;
    }
    return { appRoute, appName, avatarData, appIcon };
  };

  const getCategoryDisplayName = (category: string) => {
    if (category === 'Crm') {
      return 'CRM';
    }
    return capitalize(category);
  };

  return (
    <div className="grid grid-cols-[auto_1fr] gap-x-3 gap-y-4 mt-1 items-baseline">
      {keys(filteredData).map((routeCategory) => {
        const routes = filteredData[routeCategory];
        if (routes.length === 0) {
          return null;
        }
        return (
          <React.Fragment key={routeCategory}>
            <BaseTypography fontType="13Medium" className="pr-1.5">
              {getCategoryDisplayName(routeCategory)}
            </BaseTypography>
            <div className="flex gap-3 flex-wrap">
              {routes.map((item) => {
                const { appRoute, appName, appIcon, avatarData } = getRouteInfo(
                  routeCategory,
                  item,
                );

                const IconComponent =
                  IconNameToComponent[
                    isIconName(appIcon) ? appIcon : 'dashboard'
                  ];

                return (
                  <RecentLinkItem
                    key={`link-${routeCategory}-${JSON.stringify(item)}`}
                    link={appRoute ?? ''}
                    label={appName ?? ''}
                    icon={
                      // for apps we should show the app icon
                      routeCategory === 'Apps' ? (
                        <IconComponent className="h-10 w-10" />
                      ) : null
                    }
                    avatarData={avatarData}
                  />
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const RecentlyVisited: React.FC = () => {
  const { apps: recentlyVisitedApps, crm: recentlyVisitedCrm } = useAppSelector(
    (state) => state.ui.hasRecentlyVisited,
  );
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetRecentlyVisitedAppsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const hasData = Boolean(data?.Apps.length) || Boolean(data?.Crm.length);
  useEffect(() => {
    if (recentlyVisitedApps && recentlyVisitedCrm) {
      return;
    }

    const payload = { apps: false, crm: false };
    if (data?.Apps.length) {
      payload.apps = true;
    }
    if (data?.Crm.length) {
      payload.crm = true;
    }
    dispatch(setHasRecentlyVisited(payload));
  }, [data, recentlyVisitedApps, recentlyVisitedCrm]);

  if (!hasData && !isLoading) {
    return null;
  }

  const skeletonRows = [recentlyVisitedApps, recentlyVisitedCrm].filter(
    (val) => !!val,
  ).length;
  if (skeletonRows === 0) {
    return null;
  }

  return (
    <HomePageSection title="Recently visited">
      {data && <RecentlyVisitedInner data={data} />}
      {isLoading && (
        <div role="status" className="max-w-sm animate-pulse">
          <div className="flex flex-row mb-4">
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-16 mr-2"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mr-2"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mr-2"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mr-2"></div>
          </div>

          {skeletonRows > 1 && (
            <>
              <div className="flex flex-row">
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-16 mr-2"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mr-2"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mr-2"></div>
                <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mr-2"></div>
              </div>

              <span className="sr-only">Loading...</span>
            </>
          )}
        </div>
      )}
    </HomePageSection>
  );
};
