import React, { useContext, useMemo } from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BlackHeadings } from 'src/theme/colors';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { User } from '@sentry/react';
import { GetCurrentUser } from 'src/store/users/reducers';
import { useGetWelcomeBlurbQuery } from 'src/services/api/welcomeBlurbApi';
import { FlagsContext } from 'src/context';

interface SectionHeaderProps {
  title: string;
  subTitle: string;
  description: string;
  descriptionDetail: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionDescriptionWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1.5, 0),
    },
    sectionTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1.5, 0),
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5),
    },
  }),
);

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  title,
  subTitle,
  description,
  descriptionDetail,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.sectionTitleWrapper}>
        <BaseTypography style={{ color: BlackHeadings }} fontType="24Medium">
          {title}
        </BaseTypography>
        <BaseTypography
          data-testid="section-subtitle"
          style={{ color: BlackHeadings }}
          fontType="18Regular"
        >
          {subTitle}
        </BaseTypography>
      </div>
      <div className={classes.sectionDescriptionWrapper}>
        <BaseTypography style={{ color: BlackHeadings }} fontType="18Medium">
          {description}
        </BaseTypography>
        <BaseTypography
          data-testid="section-subtitle"
          style={{ color: BlackHeadings }}
          fontType="18Medium"
        >
          {descriptionDetail}
        </BaseTypography>
      </div>

      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
};
export const FactOfTheDaySectionHeader = () => {
  const { EnableNewIUHomeWelcomeBlurb } = useContext(FlagsContext);
  const currentUser = useSelector<RootState, User | undefined>((state) =>
    GetCurrentUser({
      usersState: state.users,
      isClient: state.user.isClient,
      currentUserId: state.user.id,
    }),
  );
  const { givenName } = currentUser?.fields || {};

  const getDate = useMemo(() => {
    const formatter = new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const date = new Date();
    return formatter.format(date);
  }, []);

  const greeting = useMemo(() => {
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12) {
      return 'Good morning';
    }
    if (hours < 18) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }, []);

  const { data } = useGetWelcomeBlurbQuery();
  const title = `${greeting}${givenName ? `, ${givenName}` : ''} 👋`;

  return (
    <div>
      <div className="flex flex-col">
        <BaseTypography
          className="pb-[4px] text-gray-small"
          fontType="14Regular"
        >
          {getDate}
        </BaseTypography>
        <BaseTypography className="text-black-heading" fontType="Heading2XL">
          {title}
        </BaseTypography>
        {EnableNewIUHomeWelcomeBlurb && (
          <BaseTypography
            data-testid="section-subtitle"
            className="pt-[6px] text-gray-small"
            fontType="16Regular"
          >
            {data?.text}
          </BaseTypography>
        )}
      </div>
    </div>
  );
};
