import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LayersIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.9844 5.37695L11.15 2.7168C10.5176 2.42773 9.48242 2.42773 8.85 2.7168L3.01953 5.37695C2.33203 5.68945 2.33203 6.20078 3.01953 6.51328L8.80078 9.15C9.46055 9.45078 10.5465 9.45078 11.2062 9.15L16.9875 6.51328C17.6719 6.20078 17.6719 5.68906 16.9844 5.37695Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 12.0517L3.01953 13.5013C2.33203 13.8138 2.33203 14.3255 3.01953 14.638L8.80078 17.2747C9.46055 17.5751 10.5465 17.5751 11.2063 17.2747L16.9875 14.638C17.675 14.3255 17.675 13.8138 16.9875 13.5013L13.8648 11.9985"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 7.98755L3.01563 9.43911C2.32813 9.75161 2.32813 10.2633 3.01563 10.5758L8.79687 13.2122C9.45664 13.5129 10.5426 13.5129 11.2023 13.2122L16.9836 10.5758C17.675 10.2633 17.675 9.75161 16.9875 9.43911L13.75 7.98755"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
