import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SquareIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.25 17.5H3.75C3.41876 17.4991 3.10136 17.3671 2.86714 17.1329C2.63292 16.8986 2.50093 16.5812 2.5 16.25V3.75C2.50093 3.41876 2.63292 3.10136 2.86714 2.86714C3.10136 2.63292 3.41876 2.50093 3.75 2.5H16.25C16.5812 2.50093 16.8986 2.63292 17.1329 2.86714C17.3671 3.10136 17.4991 3.41876 17.5 3.75V16.25C17.4991 16.5812 17.3671 16.8986 17.1329 17.1329C16.8986 17.3671 16.5812 17.4991 16.25 17.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
