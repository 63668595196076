import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const HeartFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g clip-path="url(#clip0_212_2)">
        <path
          d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM5.28125 11.2148C4.69922 10.6328 4.375 9.84766 4.375 9.02734V8.88672C4.375 7.25391 5.69922 5.93359 7.32813 5.93359C8.22656 5.93359 9.07422 6.33984 9.63672 7.04297L10 7.49609L10.3633 7.04297C10.9258 6.34375 11.7734 5.93359 12.6719 5.93359C14.3047 5.93359 15.625 7.25781 15.625 8.88672V9.02734C15.625 9.84766 15.3008 10.6328 14.7187 11.2148L13.75 12.1836L10 15.9336L6.25 12.1836L5.28125 11.2148Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_212_2">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
