import {
  DataState,
  Resource,
  DataActionTypes,
  LOAD_RESOURCES_REQUEST,
  LOAD_RESOURCES_DONE,
  LOAD_RESOURCES_FAIL,
  ADD_RESOURCES_REQUEST,
  ADD_RESOURCES_FAIL,
  ADD_RESOURCES_DONE,
  UPDATE_RESOURCES_REQUEST,
  UPDATE_RESOURCES_DONE,
  UPDATE_RESOURCES_FAIL,
  DELETE_RESOURCES_REQUEST,
  DELETE_RESOURCES_FAIL,
  DELETE_RESOURCES_DONE,
  ADD_TAGS,
} from 'src/store/data/types';

export const initialResource: Resource = {
  id: '',
  ownerId: '',
  owner: {},
  fields: {
    tags: [],
    content: '',
    question: '',
  },
};

const initialState: DataState = {
  resources: [],
  tags: [],
  error: '',
  isLoading: false,
  isLoaded: false,
  isCreating: false,
};

/* eslint-disable-next-line default-param-last */
const dataReducer = (state = initialState, action: DataActionTypes) => {
  switch (action.type) {
    case LOAD_RESOURCES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case LOAD_RESOURCES_DONE: {
      const { resources, users: resourceUsers } = action;

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        resources: resources.filter(
          (t: Resource) => t.fields.tags !== undefined,
        ),
        resourceUsers,
        error: '',
      };
    }
    case LOAD_RESOURCES_FAIL: {
      const { error } = action;
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error,
      };
    }
    case ADD_RESOURCES_REQUEST:
      return {
        ...state,
        isCreating: true,
        error: '',
      };
    case ADD_RESOURCES_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    case ADD_RESOURCES_DONE:
      return {
        ...state,
        isCreating: false,
        error: '',
        resources: [...state.resources, ...action.payload],
      };
    case UPDATE_RESOURCES_REQUEST:
      return {
        ...state,
        isCreating: false,
        error: '',
      };
    case UPDATE_RESOURCES_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    case UPDATE_RESOURCES_DONE:
      return {
        ...state,
        isCreating: false,
        error: '',
        resources: state.resources.map((t: Resource) =>
          t.id === action.payload.id ? action.payload : t,
        ),
      };
    case DELETE_RESOURCES_REQUEST:
      return {
        ...state,
        error: '',
      };
    case DELETE_RESOURCES_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case DELETE_RESOURCES_DONE:
      return {
        ...state,
        error: '',
        resources: state.resources.filter(
          (t: Resource) => t.id !== action.payload,
        ),
      };
    case ADD_TAGS:
      return {
        ...state,
        tags: [...action.tags],
      };
    default:
      return state;
  }
};

export default dataReducer;
