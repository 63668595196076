// eslint-disable-next-line no-useless-escape
export const URLValidationRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$%&'\(\)\*\+,;=.]+$/;

export const StrictHttpsURLValidationRegex =
  /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const DomainValidationRegex =
  /^(?!:\/\/)[a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?(\.[a-z]{2,})?$/;

export const SubdomainValidationRegex = /^[a-zA-Z0-9]+$/;

export const PortalUrlValidationRegex =
  /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

export const EmailValidationRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const IFrameSnippetValidationRegex =
  /^<iframe.*?src="([^"]+)".*?<\/iframe>$/;
// eslint-disable-next-line no-useless-escape
export const EmbedCodeValidationRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$%&'\(\)\*\+,;=.]+$|^<iframe.*?src="([^"]+)".*?<\/iframe>$/;

export const YoutubeLinkRegex =
  /(http:|https:)?(\/\/)?(www\.)?(youtube.com|youtu.be)\/(watch|embed)?(\?v=|\/)?(\S+)?/;

export const AlphaNumericRegex = /^(?!\d+$)(?!.*\s).*$|^.*[^\d\s].*$/;

export const NonNegativeNumberRegex = /^(?!0+(\.0+)?$)\d*\.?\d+$/;
