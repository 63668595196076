import React, { ChangeEvent, ComponentProps, useRef } from 'react';
import { AutocompleteInputChangeReason } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { BaseTextField } from 'src/legacy/components/TextField';
import {
  GoogleMapPrediction,
  useGetGoogleMapsAutocompleteQuery,
} from 'src/services/api/googleMapsApi';
import { useDebounceInput } from 'src/hooks/useDebounceInput';
import Autocomplete from 'src/components/Autocomplete';

interface GoogleMapsAddressInputProps
  extends Pick<
    ComponentProps<typeof BaseTextField>,
    'label' | 'error' | 'helperText'
  > {
  addressValue: string;
  onAddressValueChange: (e: ChangeEvent<HTMLInputElement>) => void;
  country: string;
  autoFocus?: boolean;
  onAutocompleteChange: (value: GoogleMapPrediction) => void;
  onAutocompleteInputChange: (
    newValue: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
}

const GoogleMapsAddressInput = ({
  addressValue,
  onAddressValueChange,
  country,
  label,
  error,
  helperText,
  autoFocus,
  onAutocompleteChange,
  onAutocompleteInputChange,
}: GoogleMapsAddressInputProps) => {
  const hasAddressChanged = useRef(false);
  const [debouncedAddressValue, onDebouncedAddressChange] = useDebounceInput(
    addressValue,
    (e) => {
      if (e.target.value !== addressValue) {
        hasAddressChanged.current = true;
        onAddressValueChange(e);
      }
    },
  );

  const { data, isFetching } = useGetGoogleMapsAutocompleteQuery(
    { search: addressValue, country },
    { skip: addressValue === '' || !hasAddressChanged.current },
  );

  return (
    <Autocomplete
      classes={{
        option: 'min-h-0',
      }}
      freeSolo
      options={data?.predictions ?? []}
      getOptionLabel={(option) =>
        option.addressLine1
          ? `${option.addressLine1} ${option.addressLine2}`
          : option.addressLine2
      }
      renderOption={(option) => option.fullAddress}
      filterOptions={(x) => x}
      onChange={(_, value) => {
        if (!value || typeof value === 'string') {
          return;
        }
        onAutocompleteChange(value);
      }}
      inputValue={addressValue}
      onInputChange={(_, newValue, reason) => {
        onAutocompleteInputChange(newValue, reason);
      }}
      renderInput={(params) => (
        <BaseTextField
          {...params}
          value={debouncedAddressValue}
          onChange={onDebouncedAddressChange}
          label={label}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            className: '',
            autoFocus,
            endAdornment: isFetching ? <CircularProgress size={20} /> : null,
            inputProps: { ...params.inputProps, 'data-1p-ignore': true },
          }}
        />
      )}
    />
  );
};

export default GoogleMapsAddressInput;
