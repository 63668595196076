import React from 'react';
import * as Yup from 'yup';
import { Box, FormControl, TextFieldProps } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { v4 } from 'uuid';
import MomentUtils from '@date-io/moment';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import { BaseTextField } from 'src/legacy/components/TextField';
import { DATE_FORMAT } from 'src/constants';

// map of error message returned by Yup validation
// to the user friendly error message to show
const dateErrorMessageMap: Record<string, string> = {
  'Invalid Date Format': 'Invalid date format',
};

export const DateTextField: React.FC<TextFieldProps> = (props) => {
  const { error, helperText, value, ...rest } = props;
  const errorMessage = () => {
    if (!error) return ''; // if there is no error return ''
    // if value is empty return required error
    if (!value) return 'Date is required';

    // if helperText is not in the error format map return the original helperText
    if (typeof helperText === 'string') {
      return dateErrorMessageMap[helperText] || helperText;
    }
    return helperText;
  };

  return (
    <BaseTextField
      sizeVariant="medium"
      value={value}
      error={error}
      helperText={errorMessage()}
      {...rest}
    />
  );
};

export const useAddDateForm = () => {
  const validationScheme = Yup.object().shape({
    value: Yup.date().required('Required'),
  });

  const getSubmitValues = async () => ({
    key: v4(),
  });

  const FormRenderer: React.FC<FormBodyAPI> = ({ setFieldValue, values }) => (
    <Box>
      <FormControl fullWidth>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            TextFieldComponent={DateTextField}
            disableToolbar
            name="value"
            margin="none"
            variant="inline"
            inputVariant="outlined"
            format={DATE_FORMAT}
            value={values.value}
            onChange={(val) => {
              setFieldValue('value', moment(val).format('MM/DD/YYYY'));
            }}
            autoOk
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </Box>
  );

  return { validationScheme, getSubmitValues, FormRenderer };
};
