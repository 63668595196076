import { Events } from 'src/constants/webSocketConsts/events';
import {
  upsertArticlesToState,
  removeArticlesFromState,
  refreshArticles,
} from 'src/store/knowledgeBase/actions';
import { ensureUnreachable } from 'src/utils/common_utils';

const ArticleHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
    case Events.Modify:
      return upsertArticlesToState(items);
    case Events.Remove: {
      return removeArticlesFromState(items);
    }
    case Events.Refresh: {
      return refreshArticles(items);
    }
    case Events.DomainVerified: {
      return null;
    }
    default:
      return ensureUnreachable(event);
  }
};

export default ArticleHandler;
