import { copilotDotCom, copilotApp } from 'src/constants/hostnameConsts';

export const docsURL = `https://docs.${copilotApp.prod}`;

export const ClientImportHelpCenterLink = `https://www.${copilotApp.prod}/guide/client-import`;

export const GET_STARTED_VIDEO_URL = `https://www.${copilotApp.prod}/university`;
export const API_DOCS_URL = `${docsURL}/reference/`;
export const JOIN_DISCOURSE_COMMUNITY_URL = `https://community.${copilotApp.prod}/session/sso?return_path=/`;
export const PORTAL_UPDATES_URL = `https://www.${copilotApp.prod}/updates/`;
export const COPILOT_GUIDE_URL = `https://www.${copilotApp.prod}/guide`;

export const SUPPORT_EMAIL = `support@${copilotDotCom.prod}`;
export const SALES_EMAIL = `sales@${copilotDotCom.prod}`;
export const FIND_AN_EXPERT_URL = `https://www.${copilotDotCom.prod}/experts`;
export const REQUEST_AN_APP_URL =
  'https://copilotplatforms.typeform.com/to/yfp8IhME';

export const TOS_LINK = `https://www.${copilotApp.prod}/legal/terms-of-service`;
export const PRIVACY_POLICY_LINK = `https://www.${copilotApp.prod}/legal/privacy-policy`;
export const PORTAL_URL = `https://www.${copilotApp.prod}`;
