import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ThumbsDownIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.5 2.10313C7.5 2.10313 8.125 1.875 10 1.875C11.875 1.875 12.8906 2.5 13.75 3.125H16.25C16.913 3.125 17.5489 3.38839 18.0178 3.85723C18.4866 4.32607 18.75 4.96196 18.75 5.625V7.5C18.75 8.16304 18.4866 8.79893 18.0178 9.26777C17.5489 9.73661 16.913 10 16.25 10H15.0781C14.8635 10.0012 14.6527 10.057 14.4656 10.1623C14.2786 10.2675 14.1214 10.4187 14.009 10.6016C14.009 10.6016 13.6719 11.3355 12.6562 13.0945C11.6406 14.8535 9.6875 17.5 9.375 18.125C8.24219 18.125 7.69531 17.2656 8.04688 16.2613C8.44844 15.1133 8.97305 14.1371 9.11953 12.8594C9.14063 12.6727 8.99688 12.3906 8.80703 12.3906L3.75 11.9922"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 9.41406L6.875 9.49219C7.65625 9.56406 8.125 9.97656 8.125 10.6641C8.125 11.3516 7.57812 11.7906 6.875 11.8359L3.75 11.9922C3.0625 11.9922 2.5 11.3516 2.5 10.6641V10.6574C2.50176 10.3271 2.63423 10.0108 2.86846 9.77783C3.10269 9.54484 3.41963 9.41406 3.75 9.41406V9.41406Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M2.5 6.875L6.875 6.95312C7.57812 6.98594 8.125 7.43789 8.125 8.125C8.125 8.81289 7.57812 9.25234 6.875 9.29688L2.5 9.375C2.1688 9.37397 1.85145 9.24194 1.61725 9.00775C1.38306 8.77355 1.25103 8.4562 1.25 8.125V8.125C1.25103 7.7938 1.38306 7.47645 1.61725 7.24225C1.85145 7.00806 2.1688 6.87603 2.5 6.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M4.375 1.875L6.875 1.99219C7.69531 2.06406 8.125 2.4375 8.125 3.125C8.125 3.8125 7.5625 4.29688 6.875 4.29688L4.375 4.375C4.04376 4.37407 3.72636 4.24208 3.49214 4.00786C3.25792 3.77364 3.12593 3.45624 3.125 3.125V3.125C3.12593 2.79376 3.25792 2.47636 3.49214 2.24214C3.72636 2.00792 4.04376 1.87593 4.375 1.875V1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M3.125 4.375L6.875 4.45312C7.61719 4.48594 8.125 4.9375 8.125 5.625C8.125 6.3125 7.61719 6.75156 6.875 6.79688L3.125 6.875C2.79376 6.87407 2.47636 6.74208 2.24214 6.50786C2.00792 6.27364 1.87593 5.95624 1.875 5.625V5.625C1.87593 5.29376 2.00792 4.97636 2.24214 4.74214C2.47636 4.50792 2.79376 4.37593 3.125 4.375V4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
