import { copilotApp, copilotDotCom } from 'src/constants/hostnameConsts';

const apiIDForDev = '03n8zndl3l';
const apiIDForProd = 'gs1plashf3';
const websocketDevApiUrl = `${apiIDForDev}.execute-api.us-east-1.amazonaws.com/dev`;

const ApiUrlToWebSocketApiID: Record<string, string> = {
  [`https://app-api.${copilotApp.staging}`]: websocketDevApiUrl,
  [`https://app-api.${copilotDotCom.staging}`]: websocketDevApiUrl,
  [`https://app-api.${copilotApp.prod}`]: `${apiIDForProd}.execute-api.us-west-2.amazonaws.com/production`,
  [`https://app-api.${copilotDotCom.prod}`]: `${apiIDForProd}.execute-api.us-west-2.amazonaws.com/production`,
};

type ApiUrlToWebSocketApiID = typeof ApiUrlToWebSocketApiID;

export type ApiUrlToWebSocketApiIDKeys = keyof ApiUrlToWebSocketApiID;

interface GetWebSocketUrlInput {
  apiUrl: string;
  userId: string;
  wssEndpoint?: string;
}

/**
 * Given the core api url determine which websocket api id to use
 * when the input core api url does not match any of the keys in the
 * ApiUrlToWebSocketApiID object use the dev websocket api id
 * @param apiUrl the api url for core api
 * @param userId the current userId to connect to the websocket
 * @returns websocket url to connect to
 */
export const GetWebSocketUrl = ({
  apiUrl,
  userId,
  wssEndpoint,
}: GetWebSocketUrlInput) => {
  const websocketApiURL =
    wssEndpoint || ApiUrlToWebSocketApiID[apiUrl] || websocketDevApiUrl;
  const encodedUserId = encodeURIComponent(userId);
  return `wss://${websocketApiURL}?userID=${encodedUserId}`;
};
