import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TelescopeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.55972 12.7953L1.37574 12.4777C1.31414 12.3711 1.27415 12.2534 1.25805 12.1313C1.24195 12.0093 1.25005 11.8852 1.2819 11.7663C1.31375 11.6473 1.36872 11.5358 1.44367 11.4381C1.51861 11.3404 1.61207 11.2584 1.71871 11.1969L5.11207 9.24101C5.18321 9.19977 5.26179 9.17298 5.34331 9.16216C5.42483 9.15135 5.50768 9.15673 5.58711 9.178C5.66655 9.19926 5.741 9.23599 5.80622 9.28609C5.87143 9.33618 5.92612 9.39865 5.96714 9.46991L6.46363 10.3293C6.54642 10.4728 6.56881 10.6434 6.52589 10.8034C6.48296 10.9635 6.37823 11.1 6.23472 11.1828L2.84214 13.1387C2.62648 13.2629 2.37035 13.2965 2.12994 13.2322C1.88952 13.1678 1.68446 13.0107 1.55972 12.7953V12.7953Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66678 10.6922L5.74647 9.10155C5.62226 8.88617 5.58869 8.63026 5.65316 8.39013C5.71762 8.14999 5.87484 7.9453 6.09022 7.82108L10.9519 5.01835C11.0231 4.97711 11.1017 4.95032 11.1832 4.93951C11.2647 4.9287 11.3475 4.93408 11.427 4.95534C11.5064 4.97661 11.5809 5.01334 11.6461 5.06343C11.7113 5.11352 11.766 5.17599 11.807 5.24726L13.0402 7.37968C13.123 7.5231 13.1454 7.69348 13.1026 7.85343C13.0599 8.01338 12.9554 8.14982 12.8121 8.2328L7.9492 11.0351C7.84252 11.097 7.72468 11.1372 7.60244 11.1534C7.4802 11.1696 7.35596 11.1616 7.23684 11.1297C7.11771 11.0979 7.00604 11.0428 6.90822 10.9677C6.8104 10.8927 6.72835 10.799 6.66678 10.6922Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3533 7.89863L11.5365 4.75684C11.4123 4.54145 11.3787 4.28554 11.4432 4.04541C11.5077 3.80528 11.6649 3.60059 11.8803 3.47637L15.5244 1.37559C15.7401 1.2513 15.9962 1.21761 16.2367 1.28191C16.4772 1.3462 16.6823 1.50322 16.8072 1.71855L18.624 4.86035C18.6856 4.96702 18.7255 5.08477 18.7416 5.20687C18.7576 5.32898 18.7494 5.45305 18.7175 5.572C18.6856 5.69095 18.6305 5.80244 18.5555 5.90011C18.4805 5.99778 18.387 6.0797 18.2803 6.14121L14.6361 8.24199C14.4204 8.36635 14.1642 8.40004 13.9237 8.33566C13.6832 8.27129 13.478 8.11411 13.3533 7.89863V7.89863Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.98438 18.7501L8.73984 10.6255"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6172 10.001L14.3965 17.5002"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
