import React from 'react';
import { InboxSidebarItemData } from 'src/legacy/components/Inbox';

interface InboxPageContextType {
  selectedNotification: InboxSidebarItemData | null;
  setSelectedNotification: (notification: any) => void;
}

export const InboxPageContext = React.createContext<InboxPageContextType>({
  selectedNotification: null,
  setSelectedNotification: (notification: InboxSidebarItemData | null) => {
    console.info('setSelectedNotification not provided', notification);
  },
});
