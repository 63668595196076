import React from 'react';
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { RegularCardBox } from 'src/legacy/components/Cards/RegularCardBox';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import { UrlUtils } from 'src/utils';
import history from 'src/history';
import { YoutubeLinkRegex } from 'src/constants';
import { ResourceCardVideoPlayer } from 'src/legacy/components/HomePage/ResourcesSection/ResourceCardVideoPlayer';
import { ResourceCardThumbnail } from 'src/legacy/components/HomePage/ResourcesSection/ResourceCardThumbnail';
import { ResourceActionButton } from 'src/legacy/components/HomePage/ResourcesSection/ResourceActionButton';

interface StylesProps {
  isPlayingVideo: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 192,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'fit-content',
      },
    },
    contentWrapper: {
      padding: theme.spacing(2.5, 3),
    },
    assetsWrapper: {
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: (props: StylesProps) => (props.isPlayingVideo ? '100%' : 292), // this is to maintain aspect ratio of 1.68 since height is 174px
      },
    },
    descriptionText: {
      overflow: 'hidden',
      color: GraySmall,
      marginTop: theme.spacing(0.25),
    },
    actionsWrapper: {
      marginTop: theme.spacing(3.75),
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2.5),
      alignItems: 'center',
    },
  }),
);

interface ResourceCardProps {
  title: string;
  description: string;
  primaryActionLabel: string;
  secondaryActionLabel?: string;
  thumbnail: string;
  asset: string;
  primaryActionLink: string;
  secondaryActionLink?: string;
  onDismissClick?: () => void;
  hideOnMobile: boolean;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({
  title,
  description,
  primaryActionLabel,
  secondaryActionLabel,
  thumbnail: resourceImage,
  primaryActionLink,
  secondaryActionLink,
  onDismissClick,
  asset: resourceVideo,
  hideOnMobile,
}) => {
  const [isPlayingVideo, playVideo] = React.useState(false);
  const classes = useStyles({ isPlayingVideo });

  const [videoUrl, setVideoUrl] = React.useState(resourceVideo);

  const isVideoCard = Boolean(resourceImage && resourceVideo);
  const isImageCard = Boolean(resourceImage && !resourceVideo);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAction = (actionLink: string) => {
    // determining action path type: external or internal
    // when action path external url (starts with http/s)
    if (UrlUtils.startsWithProtocol(actionLink)) {
      // if action link is youtube link then open it in player
      if (actionLink.match(YoutubeLinkRegex)) {
        setVideoUrl(actionLink);
        playVideo(true);
        return;
      }

      window.open(actionLink, '__blank');
      return;
    }

    // when action link is not an external link, navigate to it
    history.push(actionLink);
  };

  const handleClose = () => {
    // check if video is playing close it otherwise dismiss the card
    if (isPlayingVideo) {
      playVideo(false);
      return;
    }
    if (onDismissClick) onDismissClick();
  };

  const showThumbnailContainer = isVideoCard || isImageCard || isPlayingVideo;

  if (isMobileScreen && hideOnMobile) {
    return null;
  }
  return (
    <RegularCardBox
      onDismissClick={onDismissClick ? handleClose : undefined}
      data-testid="resource-card"
    >
      <Grid className={clsx({ [classes.root]: !isPlayingVideo })} container>
        {/* show asset wrapper grid only when card has image or video */}
        {showThumbnailContainer && (
          <Grid
            item
            xs={12}
            md={isPlayingVideo ? 12 : 4}
            className={classes.assetsWrapper}
          >
            <ResourceCardVideoPlayer
              isPlayingVideo={isPlayingVideo}
              videoUrl={videoUrl}
            />
            {((!isPlayingVideo && isVideoCard) || isImageCard) && (
              <ResourceCardThumbnail
                onPlayVideo={() => playVideo(true)}
                thumbnailSrc={resourceImage}
                isVideoThumbnail={isVideoCard}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={showThumbnailContainer ? 8 : 12}>
          <div className={classes.contentWrapper}>
            <BaseTypography
              style={{ color: BlackHeadings }}
              fontType="15Medium"
            >
              {title}
            </BaseTypography>
            <BaseTypography
              fontType="15Regular"
              className={classes.descriptionText}
            >
              {description}
            </BaseTypography>

            <div className={classes.actionsWrapper}>
              <ResourceActionButton
                actionLabel={primaryActionLabel}
                actionLink={primaryActionLink}
                buttonProps={{
                  htmlId: 'primary-action-button',
                  variant: 'contained',
                  color: 'secondary',
                  onClick: () => handleAction(primaryActionLink),
                }}
              />
              {secondaryActionLink && secondaryActionLabel && (
                <ResourceActionButton
                  actionLabel={secondaryActionLabel}
                  actionLink={secondaryActionLink}
                  buttonProps={{
                    htmlId: 'secondary-action-button',
                    variant: 'text',
                    color: 'primary',
                    onClick: () => handleAction(secondaryActionLink),
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </RegularCardBox>
  );
};
