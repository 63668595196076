import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TweetIcon = (
  <SvgIcon viewBox="0 0 60 60">
    <g>
      <g>
        <path
          id="Shape"
          d="m45.038 26.435-6.583-.756c-2.5664427-2.5174121-6.3472281-3.3439004-9.73-2.127-3.9081451 1.4654435-6.4306176 5.2780047-6.251 9.448h-20.266c-.72104481.0061075-1.39251309.3680416-1.794.967-.44046553.6392907-.5363027 1.4550298-.256 2.179 1.376 3.531 5.408 11.439 14.173 12.448-2.323529 2.7419295-5.17120833 4.9922625-8.376 6.619-.86769192.4477967-1.3400329 1.410755-1.163 2.371.14173894.9543055.89154142 1.7032956 1.846 1.844 2.44444506.3732478 4.9132347.5644403 7.386.572 5.6 0 12.192-1.062 17.231-5.04 4.436-3.5 7.019-8.751 7.684-15.695 1.0883637-1.2235766 1.849024-2.702925 2.211-4.3l5.107-4.616c.6653322-.596126.913918-1.5299916.633-2.378-.2578613-.8341279-.9845729-1.4368434-1.852-1.536zm-.122 2.43-5.348 4.835c-.160944.1456891-.270107.3397957-.311.553-.2769823 1.4620257-.9716584 2.812476-2 3.888-.1584402.1700861-.2552686.3883899-.275.62-.545 6.49-2.889 11.412-6.965 14.63-6.989 5.516-17.46 4.909-23.079 4.059-.09888962-.0155354-.17229725-.099912-.174-.2-.02823038-.1059592.02024186-.2174039.117-.269 7.236-3.78 10.084-8.58 10.2-8.782.178453-.3090879.1786463-.6898553.0005074-.9991243-.1781389-.3092689-.5076037-.5001532-.8645074-.5008757-8.847 0-12.876-7.889-14.2-11.281-.03822182-.1087986-.02185267-.229335.044-.324.02850562-.0546598.08187731-.0919826.143-.1h21.365c.2965701.0000448.5778611-.1315504.7678916-.3592393.1900305-.227689.2691945-.5279804.2161084-.8197607-.0508797-.2768097-.0773149-.5575581-.079-.839-.1694547-3.3106359 1.8107051-6.3543346 4.906-7.541 2.7674912-.9999362 5.8645053-.2622152 7.884 1.878.1616718.1707902.3783648.2791366.612.306l6.933.8c.0866415.0106554.1571599.0747631.176.16.0386338.1004113.011169.2142509-.069.286z"
        />
        <path
          id="Shape"
          d="m56 0h-52c-2.209139 0-4 1.790861-4 4v13c0 2.209139 1.790861 4 4 4h42.791l2.838 6.381c.240603.5420345.7779644.8914287 1.371.8914287s1.130397-.3493942 1.371-.8914287l2.838-6.381h.791c2.209139 0 4-1.790861 4-4v-13c0-2.209139-1.790861-4-4-4zm2 17c0 1.1045695-.8954305 2-2 2h-1.44c-.3950541-.0002259-.753213.2321464-.914.593l-2.646 5.948-2.646-5.948c-.160787-.3608536-.5189459-.5932259-.914-.593h-43.44c-1.1045695 0-2-.8954305-2-2v-13c0-1.1045695.8954305-2 2-2h52c1.1045695 0 2 .8954305 2 2z"
        />
        <path
          id="Shape"
          d="m26 36c-.5522847 0-1 .4477153-1 1 0 .064-.071 6.478-4.515 9.144-.3132279.1806127-.5045637.516242-.5004101.8777878.0041537.3615459.2031497.6926908.5204443.8660598.3172946.1733689.7034632.1619565 1.0099658-.0298476 5.423-3.258 5.485-10.549 5.485-10.858 0-.5522847-.4477153-1-1-1z"
        />
        <path
          id="Shape"
          d="m7 7h4c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1h-4c-.55228475 0-1 .44771525-1 1s.44771525 1 1 1z"
        />
        <path
          id="Shape"
          d="m15 7h13c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1h-13c-.5522847 0-1 .44771525-1 1s.4477153 1 1 1z"
        />
        <path
          id="Shape"
          d="m44 5h-12c-.5522847 0-1 .44771525-1 1s.4477153 1 1 1h12c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1z"
        />
        <path
          id="Shape"
          d="m18 10h-11c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1h11c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
        />
        <path
          id="Shape"
          d="m37 10h-14c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h14c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
        />
        <path
          id="Shape"
          d="m51 9c-2.209139 0-4 1.790861-4 4s1.790861 4 4 4 4-1.790861 4-4c0-1.060866-.4214274-2.0782816-1.1715729-2.8284271-.7501455-.75014554-1.7675611-1.1715729-2.8284271-1.1715729zm0 6c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2z"
        />
      </g>
    </g>
  </SvgIcon>
);
