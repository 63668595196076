import { styled } from '@material-ui/core';

// resuable page header container
export const PageHeaderContainer = styled('div')({
  height: 60,
  width: '-webkit-fill-available',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
