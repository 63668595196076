import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TicketIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.2988 5.70308C13.9738 5.378 13.7835 4.94224 13.766 4.48293C13.7485 4.02362 13.905 3.57462 14.2042 3.22573C14.229 3.19671 14.2419 3.15942 14.2403 3.12131C14.2387 3.08321 14.2227 3.04711 14.1957 3.02026L12.4718 1.29448C12.4433 1.266 12.4047 1.25 12.3644 1.25C12.3241 1.25 12.2855 1.266 12.257 1.29448L9.50933 4.04214C9.40795 4.14349 9.33158 4.26707 9.28628 4.40307V4.40307C9.24115 4.53939 9.16487 4.66332 9.0635 4.76503C8.96214 4.86674 8.83846 4.94343 8.70229 4.98901V4.98901C8.5662 5.03436 8.4425 5.11072 8.34097 5.21206L1.29448 12.257C1.266 12.2855 1.25 12.3241 1.25 12.3644C1.25 12.4047 1.266 12.4433 1.29448 12.4718L3.01831 14.1957C3.04516 14.2227 3.08125 14.2387 3.11936 14.2403C3.15746 14.2419 3.19476 14.229 3.22378 14.2042C3.57259 13.9047 4.02169 13.7479 4.48116 13.7653C4.94063 13.7828 5.37656 13.9731 5.70169 14.2982C6.02682 14.6233 6.21714 15.0593 6.23456 15.5187C6.25199 15.9782 6.09522 16.4273 5.79565 16.7761C5.7709 16.8051 5.75804 16.8424 5.75963 16.8805C5.76122 16.9186 5.77716 16.9547 5.80425 16.9816L7.52808 18.7054C7.55657 18.7339 7.59521 18.7499 7.6355 18.7499C7.67579 18.7499 7.71442 18.7339 7.74292 18.7054L14.7898 11.6589C14.8911 11.5574 14.9675 11.4337 15.0128 11.2976V11.2976C15.058 11.1613 15.1342 11.0374 15.2356 10.9357C15.337 10.8339 15.4607 10.7573 15.5968 10.7117V10.7117C15.7328 10.6664 15.8564 10.59 15.9578 10.4886L18.7054 7.74097C18.7339 7.71247 18.7499 7.67383 18.7499 7.63354C18.7499 7.59326 18.7339 7.55462 18.7054 7.52612L16.9816 5.80229C16.9547 5.77521 16.9186 5.75927 16.8805 5.75768C16.8424 5.75608 16.8051 5.76895 16.7761 5.7937C16.4277 6.09345 15.979 6.25065 15.5197 6.23384C15.0604 6.21704 14.6244 6.02748 14.2988 5.70308V5.70308Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M9.78555 5.48574L9.14062 4.84082"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.5043 7.20557L11.0742 6.77588"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.2246 8.9252L12.7949 8.49512"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.1586 10.8598L14.5137 10.2148"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
