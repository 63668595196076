import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LongAnswerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <rect width="20" height="20" rx="3" />
    <rect x="3" y="4.5" width="6.36365" height="1.53846" fill="white" />
    <rect x="3" y="10.6523" width="6.36365" height="1.53846" fill="white" />
    <rect x="10.6362" y="4.5" width="3.81819" height="1.53846" fill="white" />
    <rect
      x="10.6362"
      y="10.6523"
      width="3.81819"
      height="1.53846"
      fill="white"
    />
    <rect x="15.7271" y="4.5" width="1.27273" height="1.53846" fill="white" />
    <rect
      x="15.7271"
      y="10.6523"
      width="1.27273"
      height="1.53846"
      fill="white"
    />
    <rect x="3" y="7.57617" width="3.81819" height="1.53846" fill="white" />
    <rect x="3" y="13.7305" width="3.81819" height="1.53846" fill="white" />
    <rect
      x="8.09082"
      y="7.57617"
      width="8.90911"
      height="1.53846"
      fill="white"
    />
    <rect
      x="8.09082"
      y="13.7305"
      width="8.90911"
      height="1.53846"
      fill="white"
    />
  </SvgIcon>
);
