import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PinIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 6.25C11.3807 6.25 12.5 5.13071 12.5 3.75C12.5 2.36929 11.3807 1.25 10 1.25C8.61929 1.25 7.5 2.36929 7.5 3.75C7.5 5.13071 8.61929 6.25 10 6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 6.40625C10.625 6.31301 10.588 6.22359 10.522 6.15766C10.4561 6.09173 10.3667 6.05469 10.2734 6.05469H9.72656C9.63332 6.05469 9.5439 6.09173 9.47797 6.15766C9.41204 6.22359 9.375 6.31301 9.375 6.40625V17.8734C9.37507 18.0395 9.40813 18.2039 9.47227 18.357L9.86562 19.2945C9.87881 19.3187 9.89824 19.3388 9.92188 19.3528C9.94553 19.3668 9.97251 19.3742 10 19.3742C10.0275 19.3742 10.0545 19.3668 10.0781 19.3528C10.1018 19.3388 10.1212 19.3187 10.1344 19.2945L10.5277 18.357C10.5919 18.2039 10.6249 18.0395 10.625 17.8734V6.40625Z"
      fill="currentColor"
    />
    <path
      d="M10.9375 3.75C11.4553 3.75 11.875 3.33027 11.875 2.8125C11.875 2.29473 11.4553 1.875 10.9375 1.875C10.4197 1.875 10 2.29473 10 2.8125C10 3.33027 10.4197 3.75 10.9375 3.75Z"
      fill="currentColor"
    />
  </SvgIcon>
);
