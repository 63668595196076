export const FOLDER_START_ID = 'FOLDER_';

export const FILE_TYPE = {
  FILE: 'file',
  FOLDER: 'folder',
} as const;

export const FILES_ACTION = {
  VIEW: 'view',
  DOWNLOAD: 'download',
  PREVIEW: 'preview',
};

export const FOLDER_FILE_KEY = '__placeholder__';

export const ESIGNATURE_PAGE_MARGIN = 15;

// currently only the presence of / is being checked, so the
// error message is specific
// in future, this can be made dynamic depending on the checks we want to perform
export const FOLDER_VALIDATION_ERR_MSG = 'Folder name cannot contain a /';
export const IMAGE_FORMATS = ['png', 'jpg', 'jpeg', 'gif'];
