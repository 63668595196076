import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MoreActionsIcon = (props?: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path d="M3 12C4.10457 12 5 11.1046 5 10C5 8.89543 4.10457 8 3 8C1.89543 8 1 8.89543 1 10C1 11.1046 1.89543 12 3 12Z" />
    <path d="M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z" />
    <path d="M17 12C18.1046 12 19 11.1046 19 10C19 8.89543 18.1046 8 17 8C15.8954 8 15 8.89543 15 10C15 11.1046 15.8954 12 17 12Z" />
  </SvgIcon>
);
