import Axios from 'axios';
import { isEqual } from 'lodash';
import { RowDragEvent } from '@ag-grid-community/core';
import S3Utils, { FileWithPath, UploadResponse } from 'src/utils/S3Utils';
import {
  getFileNameFromFileKey,
  getExtensionFromFileKey,
  removeExtensionIfMatch,
} from 'src/legacy/components/Files/helpers';
import {
  FileResponse,
  FilesTableData,
  PreprocessedFilesTableData,
} from 'src/store/files/types';
import {
  FILE_TYPE,
  FOLDER_FILE_KEY,
  FOLDER_START_ID,
} from 'src/constants/fileConsts';
import { ROOT_NODE_ID } from 'src/constants/agGridConsts';
import { HTMLInputEvent } from 'src/constants/dataTypes';
import { FileWithPreview } from 'src/types/files';

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

function isHtmlInputEvent(
  event: HTMLInputEvent | File[],
): event is HTMLInputEvent {
  return 'target' in event;
}

function isFileResponse(
  file: PreprocessedFilesTableData | FileResponse | FilesTableData | undefined,
): file is FileResponse {
  return file !== undefined && 'fields' in file;
}

export type Folder = {
  path: string;
  children: Folder[];
};

export interface GetAllFilesFromPathInput {
  files: Array<FileResponse>;
  sourcePath: string;
  fileType: string;
  fileKey: string;
  ignoreLinks?: boolean;
}

export interface FilesDragDropEvent extends React.DragEvent<HTMLElement> {
  dataTransfer: DataTransfer;
}
export interface FileWithPaths extends File {
  path?: string;
  folderPath?: string;
}

export const getSizeSuffix = (size: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;
  let sizeInUnit = size;
  while (sizeInUnit >= 1024 && unitIndex < units.length - 1) {
    sizeInUnit /= 1024;
    unitIndex += 1;
  }
  return `${sizeInUnit.toFixed(0)} ${units[unitIndex]}`;
};

export const maxUploadSize = 6000000; // 6mb in bytes

// getDirectoryFiles takes in a directory handle and tries to get any nested files/folders within, it ensures its name is passed as the parent folder path
// to keep track of what folders metadata we need to create. If a directory has no files/folder within it we count it as an empty folder and ensure
// we also create its metadata
export const getDirectoryFiles = async (
  directory: FileSystemEntry,
  files: FileWithPaths[],
  emptyFolders: string[],
  folderPath: string,
) => {
  let directoryReader: FileSystemDirectoryReader = (
    directory as FileSystemDirectoryEntry
  ).createReader();
  const entriesPromise = new Promise((resolve) => {
    const promises: Promise<unknown>[] = [];
    directoryReader.readEntries(async (entries) => {
      if (entries.length === 0) {
        emptyFolders.push(folderPath);
        // ensure we also add the possible empty parent folder hiearchy
        if (folderPath.split('/').length > 1) {
          const parentFolderParts = folderPath.split('/');
          parentFolderParts.forEach((_, i) => {
            if (i != parentFolderParts.length - 1) {
              emptyFolders.push(parentFolderParts.slice(0, i + 1).join('/'));
            }
          });
        }
      }
      entries.forEach(async (entry) => {
        if (entry.isFile && !entry.name.startsWith('.')) {
          const fileEntry = entry as FileSystemFileEntry;
          const filePromise = new Promise((res) => {
            fileEntry.file((f: FileWithPaths) => {
              f.folderPath = `${folderPath}/`;
              files.push(f);
              res(files);
            });
          });
          promises.push(filePromise);
        } else if (entry.isDirectory) {
          const dirPromise = new Promise(async (res) => {
            const { files: nestedFiles, emptyFolders: folders } =
              await getDirectoryFiles(
                entry,
                [],
                [],
                `${folderPath}/${entry.name}`,
              );
            files.push(...nestedFiles);
            emptyFolders.push(...folders);
            res(files);
          });
          promises.push(dirPromise);
        }
      });
      await Promise.all(promises);
      resolve(files);
    });
  });
  await entriesPromise;
  return { files, emptyFolders };
};

export const pathMatch = (filePath: string, referencePath: string) => {
  const filePathParts: string[] = filePath.split('/');
  let pathMatchFlag = true;
  referencePath.split('/').forEach((refPathPart: string, idx: number) => {
    if (filePathParts[idx] !== refPathPart) {
      pathMatchFlag = false;
    }
  });
  return pathMatchFlag;
};

/**
 * given a list a files and a selected file path string (from query parameters), return the files and folders that match
 * @param fileEntities the file entities that need to be filtered
 * @param selectedFilePathString currently selected path in "a/b/c" format
 * */
export const filterFilesBySelectedPath = (
  fileEntities: FileResponse[],
  selectedFilePathString: string,
) => {
  const selectedFilePathParts = selectedFilePathString
    .split('/')
    .filter((str) => str !== '');
  return fileEntities.filter((f) => {
    const filePath = f.fields?.path || '';
    if (!filePath && selectedFilePathString) {
      return false;
    }
    const fileRecordPath: string[] = filePath
      .split('/')
      .filter((str) => str !== '');
    switch (f.fields.fileKey) {
      case FOLDER_FILE_KEY:
        // folder placeholder files will have slightly different path logic
        // example: path "A/B/C" on a folder matches a selected path "A/B"
        if (fileRecordPath.length > 0) {
          if (
            fileRecordPath.length - 1 !== selectedFilePathParts.length ||
            !pathMatch(
              fileRecordPath.slice(0, fileRecordPath.length - 1).join('/'),
              selectedFilePathString,
            )
          ) {
            return false;
          }
        }
        break;
      default:
        if (
          fileRecordPath.length !== selectedFilePathParts.length ||
          !pathMatch(fileRecordPath.join('/'), selectedFilePathParts.join('/'))
        ) {
          // files path logic is more direct, length and equivalence are checked
          return false;
        }
        break;
    }
    return true;
  });
};

export default class FileUtils {
  static CompressImage = async (
    originFile: File,
    width: number,
    height: number,
  ): Promise<File> =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(originFile);
      reader.onload = (event) => {
        if (event && event.target) {
          const img = new Image();
          img.src = event.target.result as string;
          img.onload = () => {
            const elem = document.createElement('canvas');
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext('2d');
            // img.width and img.height will contain the original dimensions
            if (ctx) {
              ctx.drawImage(img, 0, 0, width, height);
              ctx.canvas.toBlob(
                (blob) => {
                  if (blob) {
                    resolve(
                      new File([blob], originFile.name, {
                        type: 'image/jpeg',
                        lastModified: Date.now(),
                      }),
                    );
                  }
                },
                'image/png',
                1,
              );
            }
          };
          reader.onerror = (error) => console.info(error);
        }
      };
    });

  static getInitialChannel = (
    channelId: string,
    companyId: string,
    userId: string | null = null,
  ) => {
    const initialChannel = {
      ownerId: channelId,
      fields: userId
        ? {
            companyId,
            userId,
          }
        : { companyId },
      id: channelId,
    };
    return initialChannel;
  };

  static getCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area,
    rotation = 0,
  ): Promise<string> => {
    const createImage = (url: string) =>
      new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
      });

    const getRadianAngle = (degreeValue: number) =>
      (degreeValue * Math.PI) / 180;

    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx?.translate(safeArea / 2, safeArea / 2);
    ctx?.rotate(getRadianAngle(rotation));
    ctx?.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx?.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    if (data) {
      ctx?.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
      );
    }

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file as Blob));
      }, 'image/webp');
    });
  };

  static uploadImageFields = async (
    filePreview: FileWithPreview | null,
    headerPath: string,
    fieldName: string,
    sectionName?: string,
    onUploadProgress?: (loaded: number, total: number) => void,
  ) => {
    let imageUrl = '';
    if (filePreview && filePreview.preview) {
      const blob = await fetch(filePreview.preview).then((r) => r.blob());
      try {
        const res: UploadResponse = await S3Utils.uploadPortalFile(
          blob as File,
          `${headerPath}/images/assets/${
            sectionName ? `${sectionName}/` : ''
          }${fieldName}/${filePreview.name}`,
          (fileLoaded: number, fileTotalSize: number) => {
            if (onUploadProgress) {
              onUploadProgress(fileLoaded, fileTotalSize);
            }
          },
        );
        if (res && res.Location) {
          imageUrl = res.Location;
        }
      } catch {
        imageUrl = '';
      }
    }

    return imageUrl;
  };

  static getFileNameFromPath = (filePath: string) => filePath.split('/').pop();

  /**
   * download a blob object as a file
   * @param fileBlob file's blob object that needs to be downloaded
   * @param fileKey this is used to get file extension
   * @param fileName name of the downloaded file
   * */
  static downloadFile = async (
    fileBlob: any,
    fileKey: string,
    fileName: string,
  ) => {
    const url = window.URL.createObjectURL(new Blob([fileBlob]));
    const link = document.createElement('a');
    let fileExtention = getExtensionFromFileKey(fileKey);
    if (fileExtention === '') {
      // Get file extention from blob type if not found by fileKey
      const fileType = fileBlob.type.split('/');
      fileExtention = `.${fileType[fileType.length - 1]}`;
    }
    const fileNameStaged = `${fileName}${fileExtention}`;
    link.href = url;
    link.setAttribute(
      'download',
      (fileName && fileNameStaged) ||
        getFileNameFromFileKey(fileKey, { withExtension: true }),
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // get file's blob object using it's url
  // and then download the file using that blob object.
  static downloadFileFromUrl = async (
    fileUrl: string,
    fileKey: string,
    fileName: string,
  ) => {
    if (fileKey.endsWith('.zip')) {
      // open zip files in new window to leverage browser download progress
      window.open(fileUrl, '_blank');
      return;
    }
    const res = await Axios.get(fileUrl, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    const fileBlob = res.data;
    // download the blob as  file
    const updatedFileName = removeExtensionIfMatch(fileName, fileKey);
    this.downloadFile(fileBlob, fileKey, updatedFileName);
  };

  static makeFolderPath(folder: Folder): Array<string[]> {
    if (!folder.children || folder.children.length === 0) {
      return [[folder.path]];
    }
    const allChildrenPaths = folder.children
      .map((children) => this.makeFolderPath(children))
      .reduce((result, item) => result.concat(item), []);

    const allPaths = allChildrenPaths.map((childrenPath) =>
      [folder.path].concat(childrenPath),
    );

    return [[folder.path]].concat(allPaths);
  }

  static makeAllFolders(
    files: PreprocessedFilesTableData[],
    allFiles: FileResponse[] | undefined,
    selectedFilePath?: string,
  ): FilesTableData[] {
    const paths = files.map((fileItem) => fileItem.path);

    const folderFiles = files.filter(
      (fileItem) => fileItem.fileKey === FOLDER_FILE_KEY,
    );

    const result: Folder[] = [];
    const level = { result };

    paths.forEach((filePath: string[]) => {
      filePath.reduce((finalResult: any, path: string) => {
        const stagedFinalResult = finalResult;
        if (!stagedFinalResult[path]) {
          stagedFinalResult[path] = { result: [] };
          stagedFinalResult.result.push({
            path,
            children: stagedFinalResult[path].result,
          });
        }
        return stagedFinalResult[path];
      }, level);
    });

    let foldersResult = result
      .map((resultItem) => this.makeFolderPath(resultItem))
      .reduce((finalResult, item) => finalResult.concat(item), []);
    if (selectedFilePath) {
      foldersResult = foldersResult.filter(
        (folderPath) => !pathMatch(selectedFilePath, folderPath.join('/')),
      );
    }

    const folders = foldersResult.map((folderResultItem, index) => {
      const folderFile = folderFiles.find((folderFileItem) =>
        isEqual(folderFileItem.path, folderResultItem),
      );

      if (folderFile) {
        // esigPendingFile means >=1 file in folder has e-signature status.
        const esigPendingFile = allFiles
          ? allFiles.find(
              // if allFiles !== undefined, we are in the breadcrumbs flow and need to check ALL files to determine esig status of a folder
              (f) =>
                f.fields?.path &&
                f.fields.esignStatus === 'pending' &&
                pathMatch(f.fields.path, folderFile.path.join('/')),
            )
          : files.find(
              (f) =>
                f.signatureStatus === 'pending' &&
                pathMatch(f.path.join('/'), folderFile.path.join('/')),
            );

        return {
          ...folderFile,
          path: folderResultItem,
          filePath: folderResultItem,
          fileName: folderResultItem[folderResultItem.length - 1],
          type: FILE_TYPE.FOLDER,
          signatureStatus: isFileResponse(esigPendingFile)
            ? esigPendingFile?.fields.esignStatus
            : esigPendingFile?.signatureStatus,
        };
      }
      return {
        creator: {
          id: '',
          avatarUrl: '',
          email: '',
          fallbackColor: '',
          firstName: '',
          lastName: '',
        },
        identityId: '',
        createdDate: '',
        fileUrl: '',
        fileKey: '',
        pageImageKeys: undefined,
        signatureStatus: undefined,
        lastEsigModifierIdentityID: '',
        filePath: folderResultItem,
        path: folderResultItem,
        fileName: folderResultItem[folderResultItem.length - 1],
        fileId: FOLDER_START_ID + index,
        type: FILE_TYPE.FOLDER,
      };
    });

    return folders;
  }

  static getAllFilesFromPath(input: GetAllFilesFromPathInput) {
    if (input.fileType === FILE_TYPE.FILE) {
      return input.files.filter(
        (fileItem) => fileItem.fields.fileKey === input.fileKey,
      );
    }
    if (input.fileType === FILE_TYPE.FOLDER) {
      return input.files
        .filter(
          (item) =>
            item.fields.path &&
            pathMatch(item.fields.path || '', input.sourcePath),
        )
        .filter((f) =>
          input.ignoreLinks
            ? !(f.fields.fileUrl && f.fields.fileUrl.length > 0)
            : true,
        );
    }
    return [];
  }

  /**
   * Parses identity id from the attachment urls
   * attachment url is in format {identityid}/attachments/{date}/{filename}
   * @param identityId - the identity id of the current user
   * @param attachmentUrl - the attachment url for uploaded attachment
   */
  static getAttachmentDetails(identityId: string, attachmentUrl: string) {
    const attachmentUrlParts = attachmentUrl.split('/attachments/');
    if (attachmentUrlParts.length < 2) {
      return {
        identityId,
        attachmentKey: attachmentUrl,
      };
    }
    const attachmentIdentityId = attachmentUrlParts.at(0) || identityId;
    const attachmentKey = attachmentUrlParts.at(1) || attachmentUrl;

    return {
      identityId: attachmentIdentityId,
      attachmentKey: `attachments/${attachmentKey}`,
    };
  }

  static checkTargetAvailableFolder(event: RowDragEvent) {
    if (event.overNode) {
      if (event.overNode.data.type === FILE_TYPE.FILE) {
        if (
          !event.overNode.parent ||
          event.overNode.parent.id !== ROOT_NODE_ID
        ) {
          return false;
        }
        if (event.node.parent && event.node.parent.id === ROOT_NODE_ID) {
          return false;
        }
      }
      if (event.overNode === event.node.parent) return false;
      if (event.overNode === event.node) return false;
      if (event.node === event.overNode.parent) return false;
    }
    return true;
  }

  /**
   * Calculate the progress of uploaded files
   * @param {number} totalSize - the total size of files or just one file
   * @param {number} progressLoaded - the progress from Storage.put callback
   * @return {number} current progress
   */
  static calculateUploadProgress(
    totalSize: number,
    progressLoaded: Array<number>,
  ) {
    let loadedTotal = 0;

    if (progressLoaded && progressLoaded.length) {
      progressLoaded.forEach((fileLoadProgress: number) => {
        loadedTotal += fileLoadProgress;
      });
    }

    const fileProgress = Math.round((loadedTotal / totalSize) * 100);

    return fileProgress;
  }

  static getNewBasePath(basePath: string, blockedPaths: string[]) {
    if (blockedPaths.length < 1) {
      // as a precaution, return basePath unmodified if there are not blocked paths
      return basePath;
    }
    let increment = 1;
    let modifiedBasePath = basePath;
    while (blockedPaths.includes(modifiedBasePath)) {
      modifiedBasePath = `${basePath} (${increment})`;
      increment += 1;
    }
    return modifiedBasePath;
  }

  /**
   * given a folder upload input, return the path which needs to be modified if there is a conflict against blocked paths
   * @param blockedPaths - string values of invalid paths that could trigger return of a value
   * @param acceptedFiles - files added through a dropzone or upload action, if in same folder they'll share base folder
   */
  static getOverlappingFolderName(
    blockedPaths: string[],
    acceptedFiles: HTMLInputEvent | FileWithPath[],
  ) {
    const uploadedBasePath = (() => {
      if (isHtmlInputEvent(acceptedFiles)) {
        // folder uploaded through action
        const targetFiles = acceptedFiles.target.files;
        if (!targetFiles) {
          return '';
        }
        const files: File[] = [...targetFiles].filter(
          (f) => !f.name.startsWith('.'),
        );
        return files
          .at(0)
          ?.webkitRelativePath.split('/')
          .filter((part) => part !== '')[0];
      }

      return acceptedFiles
        .at(0)
        ?.path.split('/')
        .filter((part) => part !== '')[0];
    })();

    if (uploadedBasePath && blockedPaths.includes(uploadedBasePath)) {
      return uploadedBasePath;
    }
    return '';
  }

  static getEntityName(fileEntity: FilesTableData) {
    let entityName = 'file';
    if (fileEntity.type === FILE_TYPE.FOLDER) {
      entityName = 'folder';
    } else if (fileEntity.fileUrl) {
      entityName = 'link';
    }
    return entityName;
  }
}
