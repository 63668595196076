import React from 'react';
import { MenuItem, makeStyles, createStyles, Theme } from '@material-ui/core';
import { Action, ActionImpl } from 'kbar';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { GraySmall } from 'src/theme/colors';
import ShortcutKey from 'src/legacy/components/ShortcutsDrawer/ShortcutKey';
import { RenderParams } from 'src/legacy/components/CommandBar/commandBarTypes';
import { BaseResultItem } from 'src/legacy/components/CommandBar/BaseResultItem';

const SHORTCUT_ITEM_HEIGHT = 48;
interface StyleProps {
  keysCount: number;
  disabled?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, StyleProps>({
    itemContentWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    sectionTitle: {
      '&.MuiMenuItem-root.MuiListItem-button': {
        height: SHORTCUT_ITEM_HEIGHT,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    shortcutKeysWrapper: {
      display: 'flex',
      // add gap between keys only when they're more than one.
      gap: (props) => theme.spacing(props.keysCount > 1 ? 1 : 0),
      alignItems: 'center',
    },
    itemDisabled: {
      color: theme.palette.text.disabled,
    },
  }),
);

export const CommandBarResultsItem: React.FC<
  RenderParams<
    | (ActionImpl & {
        disabled?: boolean;
        callback?: React.MouseEventHandler<HTMLLIElement>;
      })
    | string
  >
> = ({ item, active }) => {
  const classes = useStyles({
    keysCount: (item as Action).shortcut?.length || 0,
    // casting here because I don't want to change runtime behavior, but this should be checked later in the render
    disabled: (item as { disabled?: boolean }).disabled,
  });
  const isSectionTitle = typeof item === 'string' || item instanceof String;
  // This calculates the separator text between the shortcuts keys label
  const shortcutKeysSeparator = React.useMemo(() => {
    // when item is section title, return null
    if (isSectionTitle) {
      return null;
    }

    // when action item includes sidebar, that means it's a navigation shortcut
    if (item?.id.includes('sidebar')) {
      return 'then';
    }
    return null;
  }, [item]);

  return isSectionTitle ? (
    <MenuItem classes={{ root: classes.sectionTitle }}>
      <BaseTypography fontType="13Medium">{item}</BaseTypography>
    </MenuItem>
  ) : (
    <BaseResultItem onClick={item.callback} active={active}>
      <div className={classes.itemContentWrapper}>
        <BaseTypography
          className={item.disabled ? classes.itemDisabled : ''}
          fontType="13Medium"
        >
          {item.name}
        </BaseTypography>
        <div className={classes.shortcutKeysWrapper}>
          {item.shortcut?.map((char, index) => (
            <>
              <ShortcutKey key={char}>
                <BaseTypography
                  style={{ textTransform: 'capitalize' }}
                  fontType="13Medium"
                >
                  {char}
                </BaseTypography>
              </ShortcutKey>
              {index === 0 && (
                <BaseTypography
                  fontType="12Regular"
                  style={{ color: GraySmall }}
                >
                  {shortcutKeysSeparator}
                </BaseTypography>
              )}
            </>
          ))}
        </div>
      </div>
    </BaseResultItem>
  );
};
