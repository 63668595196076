import { useContext, useEffect, useMemo, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  useLazyGetCompaniesQuery,
  useLazyGetOtherUserDataQuery,
  useLazyGetUserNotificationsQuery,
  useLazyGetUsersQuery,
  useLazyGetUserSettingsQuery,
  useLazyGetUserFileChannelsQuery,
} from 'src/services/api';
import UsersClient from 'src/clients/UsersClient';
import { PortalConfigContext } from 'src/context';
import { AppViewMode } from 'src/constants/uiConsts';
import { useLazyGetOnboardingTaskStatusQuery } from 'src/services/api/improvedOnboardingApi';
import { useCustomerSubscription } from 'src/hooks/useCustomerSubscription';
/**
 * This hook is used to load all the user data.
 * On mount, it gets the authenticated user from Cognito
 * and pass it to all the queries that need it.
 * @param userLoaded - when user data is not loaded, fire the queries
 */
export const useGetUserDataQueries = (
  userLoaded: boolean,
  flexibleAuth?: boolean,
) => {
  const [authUser, setAuthUser] = useState<CognitoUser>();
  const portalConfig = useContext(PortalConfigContext);

  const authUserId = useMemo(() => authUser?.getUsername() || '', [authUser]);
  const appViewMode = portalConfig.viewMode;
  const { allSubscriptions } = useCustomerSubscription();

  const [loadUsers] = useLazyGetUsersQuery();
  const [loadCompanies] = useLazyGetCompaniesQuery();
  const [loadUserFileChannels] = useLazyGetUserFileChannelsQuery();
  const [loadUserNotifications] = useLazyGetUserNotificationsQuery();
  const [loadUserOtherData] = useLazyGetOtherUserDataQuery();
  const [loadUserSettings] = useLazyGetUserSettingsQuery();
  const [loadOnboardingTaskStatus] = useLazyGetOnboardingTaskStatusQuery();

  /**
   * This function initializes the authenticated user state
   */
  const initializeAuthenticatedUser = async () => {
    try {
      const authenticatedUser = await UsersClient.getAuthUser({
        portalId: portalConfig.portalHeader,
        portalName: portalConfig.subdomain,
      });
      setAuthUser(authenticatedUser);
    } catch (err) {
      if (!flexibleAuth) {
        console.error('User not authenticated, redirecting to login', err);
        // when auth user cannot be found there is a corrupted state
        // in the sessions and we need to sign out all sessions
        window.location.href = '/auth/signout-all';
      }
    }
  };

  useEffect(() => {
    initializeAuthenticatedUser();
  }, []);

  useEffect(() => {
    // when user is not loaded and authUser is set, load the user data
    if (authUser && !userLoaded) {
      loadUsers({
        userId: authUserId,
        appViewMode: appViewMode as AppViewMode,
      });
      loadCompanies(authUserId);
      loadUserFileChannels(authUserId);
      loadUserNotifications(authUserId);
      loadUserOtherData({
        cognitoUserId: authUserId,
      });
      loadUserSettings(authUserId);
    }
  }, [authUser, userLoaded]);

  useEffect(() => {
    loadOnboardingTaskStatus({ subscriptions: allSubscriptions });
  }, [allSubscriptions]);
};
