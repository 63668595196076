import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SignatureInitialIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.4583 1C17.3624 1.00218 17.2709 1.04089 17.2026 1.10821L15.6973 2.61354C15.6623 2.64849 15.6345 2.69 15.6156 2.73569C15.5967 2.78138 15.5869 2.83035 15.5869 2.87981C15.5869 2.92927 15.5967 2.97825 15.6156 3.02394C15.6345 3.06962 15.6623 3.11113 15.6973 3.14608L17.5719 5.02064C17.6423 5.09045 17.7375 5.12962 17.8367 5.12962C17.9359 5.12962 18.0311 5.09045 18.1016 5.02064L19.6069 3.51531C19.6767 3.44484 19.7159 3.34966 19.7159 3.25046C19.7159 3.15127 19.6767 3.05608 19.6069 2.98561L17.7323 1.11105C17.6959 1.07515 17.6526 1.04705 17.605 1.02844C17.5574 1.00984 17.5065 1.00113 17.4554 1.00284L17.4583 1ZM17.4696 1.90746L18.8173 3.25515L17.8459 4.22651L16.4983 2.87882L17.4696 1.90746Z"
      fill="currentColor"
    />
    <path
      d="M17.8845 4.37988C17.7892 4.3835 17.6989 4.42314 17.6317 4.49079L10.7584 11.3642L9.41067 10.0165L10.8308 8.59636C10.9006 8.52588 10.9398 8.4307 10.9398 8.3315C10.9398 8.23231 10.9006 8.13712 10.8308 8.06665C10.7958 8.03166 10.7543 8.00389 10.7086 7.98495C10.663 7.96601 10.614 7.95626 10.5645 7.95626C10.5151 7.95626 10.4661 7.96601 10.4204 7.98495C10.3747 8.00389 10.3332 8.03166 10.2982 8.06665L10.1094 8.25269L8.60404 9.75801C8.56905 9.79296 8.54129 9.83447 8.52234 9.88016C8.5034 9.92585 8.49365 9.97482 8.49365 10.0243C8.49365 10.0737 8.5034 10.1227 8.52234 10.1684C8.54129 10.2141 8.56905 10.2556 8.60404 10.2906L10.4786 12.1651C10.5491 12.2349 10.6443 12.2741 10.7435 12.2741C10.8426 12.2741 10.9378 12.2349 11.0083 12.1651L18.1515 5.02192C18.2213 4.95145 18.2605 4.85626 18.2605 4.75707C18.2605 4.65787 18.2213 4.56269 18.1515 4.49221C18.1153 4.45583 18.0721 4.42722 18.0245 4.40815C17.9769 4.38908 17.9259 4.37994 17.8746 4.3813L17.8845 4.37988Z"
      fill="currentColor"
    />
    <path
      d="M11.494 7.00684C11.4443 7.00665 11.3951 7.01629 11.3492 7.03521C11.3033 7.05413 11.2616 7.08195 11.2264 7.11706C11.1913 7.15218 11.1635 7.19389 11.1446 7.23981C11.1257 7.28572 11.116 7.33493 11.1162 7.38459C11.1164 7.43401 11.1263 7.48291 11.1454 7.5285C11.1645 7.57409 11.1924 7.61547 11.2274 7.65028C11.2625 7.6851 11.3041 7.71266 11.3498 7.7314C11.3956 7.75014 11.4445 7.75969 11.494 7.7595C11.5933 7.75913 11.6884 7.71951 11.7587 7.64928C11.8289 7.57905 11.8685 7.48391 11.8689 7.38459C11.8691 7.33517 11.8595 7.2862 11.8408 7.24047C11.822 7.19474 11.7945 7.15314 11.7597 7.11807C11.7248 7.08299 11.6835 7.05511 11.6379 7.03603C11.5923 7.01694 11.5434 7.00702 11.494 7.00684V7.00684Z"
      fill="currentColor"
    />
    <path
      d="M16.0384 2.5051C15.9427 2.50737 15.8396 2.54714 15.7715 2.61445L12.207 6.17895C12.0593 6.32522 12.0593 6.56522 12.207 6.71149C12.3532 6.85918 12.5932 6.85918 12.7395 6.71149L16.304 3.14699C16.4517 3.00072 16.4517 2.76072 16.304 2.61445C16.2304 2.54174 16.1421 2.50155 16.0384 2.5051V2.5051Z"
      fill="currentColor"
    />
    <path
      d="M8.89518 9.6341C8.73329 9.62756 8.5998 9.72612 8.54157 9.87694L7.41542 12.8876C7.30082 13.1901 7.59578 13.4855 7.89826 13.3733L10.9089 12.2443C11.102 12.1711 11.2 11.956 11.1276 11.7614C11.0556 11.5669 10.8393 11.4675 10.6448 11.5399L8.41518 12.3778L9.25305 10.1482C9.3252 9.95504 9.22735 9.73918 9.03436 9.66534C8.99601 9.65156 8.94262 9.64262 8.902 9.64162L8.89518 9.6341Z"
      fill="currentColor"
    />
    <path
      d="M17.8844 4.37993C17.7892 4.38354 17.6988 4.42318 17.6317 4.49084C17.5618 4.56131 17.5227 4.65649 17.5227 4.75569C17.5227 4.85489 17.5618 4.95007 17.6317 5.02054L18.1173 5.50622L15.3765 8.24705C15.3415 8.282 15.3137 8.32351 15.2948 8.3692C15.2759 8.41489 15.2661 8.46386 15.2661 8.51332C15.2661 8.56278 15.2759 8.61176 15.2948 8.65745C15.3137 8.70314 15.3415 8.74464 15.3765 8.7796C15.447 8.84941 15.5422 8.88857 15.6414 8.88857C15.7406 8.88857 15.8357 8.84941 15.9062 8.7796L18.9169 5.76894C18.9867 5.69847 19.0258 5.60329 19.0258 5.50409C19.0258 5.4049 18.9867 5.30971 18.9169 5.23924L18.1656 4.488C18.1295 4.45161 18.0862 4.423 18.0386 4.40393C17.991 4.38486 17.94 4.37572 17.8887 4.37709L17.8844 4.37993Z"
      fill="currentColor"
    />
    <path
      d="M5.56286 8.90865H6.39844C6.33381 7.47292 5.09197 6.41113 3.3331 6.41113C1.58807 6.41113 0.244673 7.45907 0.244673 8.98251C0.244673 10.1966 1.10334 10.9584 2.58523 11.3969L3.71626 11.7293C4.88885 12.0709 5.69212 12.5095 5.69212 13.4743C5.69212 14.61 4.66264 15.3717 3.25 15.3717C1.97585 15.3717 0.978693 14.7577 0.886364 13.6359H0C0.101562 15.1317 1.32031 16.1611 3.25 16.1611C5.29972 16.1611 6.5277 15.0024 6.5277 13.4882C6.5277 11.9186 5.16122 11.2907 3.96094 10.9537L2.96378 10.6675C2.0451 10.409 1.08026 9.94274 1.08026 8.94558C1.08026 7.92995 1.9897 7.1867 3.31001 7.1867C4.56108 7.1867 5.45668 7.85609 5.56286 8.90865Z"
      fill="currentColor"
    />
    <path
      d="M7.33523 16.0688C7.70455 16.0688 8 15.7687 8 15.404C8 15.0347 7.70455 14.7393 7.33523 14.7393C6.97053 14.7393 6.67045 15.0347 6.67045 15.404C6.67045 15.7687 6.97053 16.0688 7.33523 16.0688Z"
      fill="currentColor"
    />
  </SvgIcon>
);
