import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export const DownloadAttachmentIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.3612 18.125C16.8648 18.125 17.2699 17.7369 17.2699 17.2545C17.2699 16.772 16.8648 16.3839 16.3612 16.3839H3.63919C3.13561 16.3839 2.73047 16.772 2.73047 17.2545C2.73047 17.7369 3.13561 18.125 3.63919 18.125H16.3612ZM9.3376 13.2065C9.50798 13.3806 9.75031 13.4821 10.0002 13.4821C10.2501 13.4821 10.4886 13.3842 10.6628 13.2065L15.5093 8.27344C15.8539 7.9216 15.8349 7.37388 15.4714 7.04381C15.108 6.71373 14.5324 6.73186 14.1879 7.08008L10.9089 10.4171V7.38839V2.74554C10.9089 2.26311 10.5038 1.875 10.0002 1.875C9.49663 1.875 9.09149 2.26311 9.09149 2.74554V7.38839V10.4171L5.81632 7.08371C5.47177 6.73186 4.89624 6.71736 4.53276 7.04743C4.16927 7.37751 4.15034 7.92885 4.49489 8.27706L9.34139 13.2101L9.3376 13.2065Z"
      fill="currentColor"
    />
  </SvgIcon>
);
