import React from 'react';
import { getModKeyState } from 'src/utils/KeyEventUtils';

type FormSubmitHandler = (ev?: React.FormEvent<HTMLFormElement>) => void;
export const useFormSubmitShortcut = (handleSubmit: FormSubmitHandler) => {
  /**
   * This function handles form submit when user press cmd/ctrl + enter.
   * @param ev: Keydown event
   */
  const keyDownHandler = (ev: KeyboardEvent) => {
    const { tagName } = ev.target as any;
    if (ev.keyCode === 13 && tagName !== 'TEXTAREA') {
      ev.preventDefault();
      if (getModKeyState(ev, 'Control') || getModKeyState(ev, 'Meta')) {
        handleSubmit();
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', keyDownHandler, false);
    return () => {
      window.removeEventListener('keydown', keyDownHandler, false);
    };
  }, []);
};
