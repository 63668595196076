import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M18 2L2 18M18 18L2 2L18 18Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
