import React from 'react';
import { InputBase, InputBaseProps, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import { black, DividersAndCardBorders } from 'src/theme/colors';
import { typography12RegularStyle } from 'src/legacy/components/Text';

interface InputStylesProps {
  width?: number | string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      width: (props: InputStylesProps) => props.width || 223,
      borderBottom: `1px solid ${DividersAndCardBorders}`,
      '& input': {
        ...typography12RegularStyle,
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
        borderRadius: 4,
        height: 'auto',
        '&::placeholder': {
          color: black,
        },
      },
    },
  }),
);
export const BaseMenuInput: React.FC<InputBaseProps & InputStylesProps> =
  React.forwardRef((props, ref) => {
    const classes = useStyles({ width: props.width });
    return <InputBase className={classes.root} ref={ref} {...props} />;
  });
