import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GitNetworkIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 5.625C6.03553 5.625 6.875 4.78553 6.875 3.75C6.875 2.71447 6.03553 1.875 5 1.875C3.96447 1.875 3.125 2.71447 3.125 3.75C3.125 4.78553 3.96447 5.625 5 5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18.125C11.0355 18.125 11.875 17.2855 11.875 16.25C11.875 15.2145 11.0355 14.375 10 14.375C8.96447 14.375 8.125 15.2145 8.125 16.25C8.125 17.2855 8.96447 18.125 10 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 5.625C16.0355 5.625 16.875 4.78553 16.875 3.75C16.875 2.71447 16.0355 1.875 15 1.875C13.9645 1.875 13.125 2.71447 13.125 3.75C13.125 4.78553 13.9645 5.625 15 5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5.625C5 8.5418 7.69219 10 10 10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 5.625C15 8.5418 12.3078 10 10 10"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
