import { Events } from 'src/constants/webSocketConsts/events';
import { setCustomFieldsAction } from 'src/store/clients/actions';
import { CustomFieldEntity } from 'src/store/clients/types';
import { ensureUnreachable } from 'src/utils/common_utils';

export interface CustomFieldWSEntity extends CustomFieldEntity {
  fields: {
    addedCustomFields?: string[];
    deletedCustomFields?: string[];
  };
}

const CustomFieldsHandler = (
  event: Events,
  items: Array<CustomFieldWSEntity>,
) => {
  if (items && items.length === 0) {
    return null;
  }
  const item = items.at(0);
  if (!item) {
    return null;
  }

  switch (event) {
    case Events.Insert:
      return setCustomFieldsAction(item, '');
    case Events.Modify: {
      // since we're using update api to delete custom fields
      // we need to distinguish the websocket message whether it
      // is an update deletion action.
      const isDeleteWsMessage = item.fields.deletedCustomFields !== null;
      const stagedUpdatedFields = {
        ...item,
        // currently when websocket modify/delete events are
        // received they contains the diff values (updatedFields, removedFields)
        // we don't need to save them in custom fields entity.
        fields: null,
      };
      return setCustomFieldsAction(stagedUpdatedFields, '', isDeleteWsMessage);
    }
    case Events.Remove:
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default CustomFieldsHandler;
