import React from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';

/**
 * A component that shows a tooltip only on non-desktop screens.
 * @param {ReactNode} children - The content to be wrapped with the tooltip.
 */
export const NonDesktopActionTooltip: React.FC = ({ children }) => {
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Tooltip
      title="This feature is only available on larger screens."
      placement="bottom"
      disableHoverListener={isDesktopScreen}
      disableTouchListener={isDesktopScreen}
      disableFocusListener={isDesktopScreen}
      enterTouchDelay={0} // Ensures that the tooltip appears instantly on mobile touch events
    >
      <div>{children}</div>
    </Tooltip>
  );
};
