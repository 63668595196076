import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ReadMessage = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 5H14C14.275 5 14.5 5.225 14.5 5.5V6.19063L13.7937 6.76875C14.3313 6.59375 14.9062 6.5 15.5 6.5C15.6687 6.5 15.8344 6.50625 16 6.52187V5.5C16 4.39688 15.1031 3.5 14 3.5H2C0.896875 3.5 0 4.39688 0 5.5V13.5C0 14.6031 0.896875 15.5 2 15.5H11.2563C10.8875 15.0531 10.5875 14.5469 10.375 14H2C1.725 14 1.5 13.775 1.5 13.5V8.13125L5.9375 11.775C7.11562 12.7437 8.80625 12.7594 10.0031 11.8219C10.0312 10.9094 10.2812 10.0562 10.7031 9.30937L9.1125 10.6156C8.46562 11.1469 7.5375 11.1469 6.89062 10.6156L1.5 6.19063V5.5C1.5 5.225 1.725 5 2 5ZM20 12C20 10.8065 19.5259 9.66193 18.682 8.81802C17.8381 7.97411 16.6935 7.5 15.5 7.5C14.3065 7.5 13.1619 7.97411 12.318 8.81802C11.4741 9.66193 11 10.8065 11 12C11 13.1935 11.4741 14.3381 12.318 15.182C13.1619 16.0259 14.3065 16.5 15.5 16.5C16.6935 16.5 17.8381 16.0259 18.682 15.182C19.5259 14.3381 20 13.1935 20 12ZM17.6031 10.6469C17.7969 10.8406 17.7969 11.1594 17.6031 11.3531L15.3531 13.6031C15.1594 13.7969 14.8406 13.7969 14.6469 13.6031L13.3969 12.3531C13.2031 12.1594 13.2031 11.8406 13.3969 11.6469C13.5906 11.4531 13.9094 11.4531 14.1031 11.6469L15 12.5437L16.8969 10.6469C17.0906 10.4531 17.4094 10.4531 17.6031 10.6469Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
