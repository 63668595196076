import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FunnelIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.38293 3.40313L7.97082 11.0766C8.06933 11.1897 8.12407 11.3344 8.12512 11.4844V16.1453C8.12448 16.2106 8.1445 16.2745 8.18231 16.3277C8.22013 16.3809 8.2738 16.4209 8.33567 16.4418L11.4665 17.4836C11.5133 17.4993 11.5632 17.5035 11.6119 17.4961C11.6607 17.4886 11.707 17.4696 11.747 17.4406C11.7869 17.4117 11.8194 17.3736 11.8417 17.3296C11.864 17.2855 11.8754 17.2368 11.8751 17.1875V11.4844C11.8767 11.3341 11.9322 11.1894 12.0314 11.0766L18.6173 3.40313C18.6854 3.3238 18.7294 3.22661 18.7439 3.12308C18.7585 3.01954 18.7431 2.914 18.6995 2.81896C18.6559 2.72392 18.586 2.64336 18.4981 2.58683C18.4101 2.5303 18.3078 2.50016 18.2032 2.5H1.79895C1.69423 2.49979 1.59165 2.52965 1.5034 2.58604C1.41516 2.64242 1.34496 2.72296 1.30114 2.81807C1.25733 2.91319 1.24174 3.01888 1.25624 3.12259C1.27074 3.2263 1.31471 3.32367 1.38293 3.40313Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
