import { BaseEntity } from 'src/constants/dataTypes';

export const ESIG_REQUEST_NOTIFICATION_TEXT = 'Open eSignature request';
export const ESIG_COMPLETE_NOTIFICATION_TEXT = 'Document Signed';
export const FORMS_COMPLETE_NOTIFICATION_TEXT = 'Responded';
export const FORMS_PENDING_NOTIFICATION_TEXT = 'Waiting for response';

export enum FormsNotificationsStatuses {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum NotificationsActionTypes {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS',
  MARK_NOTIFICATION_READ_FAILURE = 'MARK_NOTIFICATION_READ_FAILURE',
  INSERT_NOTIFICATIONS = 'INSERT_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS',
  DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS',
}

export enum NotificationGroups {
  FileNotifications = 'fileNotifications',
  EsigRequestNotifications = 'esigRequestNotifications',
  EsigRequestCompleteNotifications = 'esigRequestCompleteNotifications',
  FormCompleteNotifications = 'formCompleteNotifications',
  FormRequestNotifications = 'formRequestNotifications',
  NewInvoiceNotifications = 'newInvoiceNotifications',
  FileUploadNotification = 'NOTIFY_FILE_UPLOADED',
  InvoicePaidNotifcations = 'invoicePaidNotifications',
}

export interface SetNotificationsActionType {
  type: typeof NotificationsActionTypes.SET_NOTIFICATIONS;
  payload: Notifications;
}

export interface MarkNotificationReadSuccessActionType {
  type: typeof NotificationsActionTypes.MARK_NOTIFICATION_READ_SUCCESS;
  payload: Array<string> | null;
  notificationType: NotificationGroups;
}

export interface MarkNotificationReadFailureActionType {
  type: typeof NotificationsActionTypes.MARK_NOTIFICATION_READ_FAILURE;
}

export interface InsertFilesNotificationsActionType {
  type: typeof NotificationsActionTypes.INSERT_NOTIFICATIONS;
  notificationType: NotificationGroups;
  payload: Array<Notification>;
}

export interface UpdateFilesNotificationsActionType {
  type: typeof NotificationsActionTypes.UPDATE_NOTIFICATIONS;
  notificationType: NotificationGroups;
  payload: Array<Notification>;
}

export interface DeleteFilesNotificationsActionType {
  type: typeof NotificationsActionTypes.DELETE_NOTIFICATIONS;
  notificationType: NotificationGroups;
  payload: Array<Notification>;
}

export interface BaseNotificationFields {
  channelId?: string;
  notificationGroup?: NotificationGroups;
  senderUserId: string;
}

export interface FileNotification extends BaseEntity {
  fields: BaseNotificationFields;
}

export interface EsigRequestNotification extends BaseEntity {
  fields?: BaseNotificationFields;
}

export interface EsigRequestCompleteNotification extends BaseEntity {
  fields: BaseNotificationFields;
}

export interface FormCompleteNotificationFields extends BaseNotificationFields {
  formId: string;
}
export interface FormCompleteNotification extends BaseEntity {
  fields: FormCompleteNotificationFields;
}
export interface FormRequestNotification extends BaseEntity {
  fields: BaseNotificationFields;
}
export interface NewInvoiceNotification extends BaseEntity {
  fields: BaseNotificationFields;
}
export type Notification =
  | FileNotification
  | EsigRequestNotification
  | EsigRequestCompleteNotification
  | FormCompleteNotification
  | FormRequestNotification
  | NewInvoiceNotification;

export interface NotificationsState {
  isLoaded: boolean;
  isLoading: boolean;
  fileNotifications: FileNotification[];
  esigRequestNotifications: EsigRequestNotification[];
  esigRequestCompleteNotifications: EsigRequestCompleteNotification[];
  formCompletedNotifications: FormCompleteNotification[];
  formRequestNotifications: FormRequestNotification[];
  newInvoiceNotifications: NewInvoiceNotification[];
}

export const initialNotificationsState: NotificationsState = {
  isLoaded: false,
  isLoading: false,
  fileNotifications: [],
  esigRequestNotifications: [],
  esigRequestCompleteNotifications: [],
  formCompletedNotifications: [],
  formRequestNotifications: [],
  newInvoiceNotifications: [],
};

export interface Notifications {
  fileNotifications: FileNotification[];
  esigRequestNotifications: EsigRequestNotification[];
  esigRequestCompleteNotifications: EsigRequestCompleteNotification[];
  formCompletedNotifications: FormCompleteNotification[];
  formRequestNotifications: FormRequestNotification[];
  newInvoiceNotifications: NewInvoiceNotification[];
}

export interface NotificationSummaryData {
  time?: Date;
  subTitle: string;
}

export type NotificationsActions =
  | SetNotificationsActionType
  | MarkNotificationReadSuccessActionType
  | MarkNotificationReadFailureActionType
  | InsertFilesNotificationsActionType
  | UpdateFilesNotificationsActionType
  | DeleteFilesNotificationsActionType;
