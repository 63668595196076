import React from 'react';
import { styled, Box } from '@material-ui/core';
import { ImageRendererProps } from 'src/legacy/components/AgGrid/Renderer/types';
import { Image } from 'src/constants/dataTypes';

const StyledImageRendererContainer = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ImageRenderer = ({ value = [] }: ImageRendererProps) => (
  <StyledImageRendererContainer>
    {value.map((img: Image, idx: number) => (
      <img
        key={`${img.id}`}
        src={img.thumbnails ? img.thumbnails.small.url : img.url}
        alt={`${img.id} ${idx}`}
        width="45%"
      />
    ))}
  </StyledImageRendererContainer>
);

export default ImageRenderer;
