import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DocIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.9265 8.49561V16.9263C16.9265 17.4774 16.7076 18.006 16.3179 18.3957C15.9282 18.7853 15.3997 19.0043 14.8486 19.0043H5.15165C4.60055 19.0043 4.07203 18.7853 3.68234 18.3957C3.29265 18.006 3.07373 17.4774 3.07373 16.9263V3.07353C3.07373 2.52243 3.29265 1.9939 3.68234 1.60421C4.07203 1.21453 4.60055 0.995605 5.15165 0.995605H9.42654C9.79382 0.995661 10.146 1.14156 10.4058 1.40123L16.5209 7.51639C16.7806 7.77612 16.9265 8.12833 16.9265 8.49561Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.3418V6.5366C10 6.904 10.1459 7.25635 10.4057 7.51614C10.6655 7.77593 11.0179 7.92188 11.3853 7.92188H16.5801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.09411 15.6861C7.20206 15.6861 7.8892 15.0025 7.8892 13.8661C7.8892 12.7315 7.20206 12.0497 6.09233 12.0497H4.75V15.6861H6.09411ZM5.62891 14.9368V12.799H6.04794C6.67294 12.799 7.00852 13.0831 7.00852 13.8661C7.00852 14.6527 6.67294 14.9368 6.06037 14.9368H5.62891Z"
      fill="black"
    />
    <path
      d="M11.8345 13.8679C11.8345 12.6658 11.0746 12 10.098 12C9.1179 12 8.36328 12.6658 8.36328 13.8679C8.36328 15.0646 9.1179 15.7358 10.098 15.7358C11.0746 15.7358 11.8345 15.07 11.8345 13.8679ZM10.9361 13.8679C10.9361 14.5799 10.6236 14.9652 10.098 14.9652C9.57422 14.9652 9.25994 14.5799 9.25994 13.8679C9.25994 13.1559 9.57422 12.7706 10.098 12.7706C10.6236 12.7706 10.9361 13.1559 10.9361 13.8679Z"
      fill="black"
    />
    <path
      d="M15.6484 13.3672C15.5597 12.4972 14.9116 12 14.0327 12C13.0632 12 12.3086 12.6658 12.3086 13.8679C12.3086 15.0646 13.0455 15.7358 14.0327 15.7358C15.0305 15.7358 15.5827 15.0575 15.6484 14.413L14.7607 14.4077C14.6985 14.7575 14.4357 14.9652 14.0504 14.9652C13.532 14.9652 13.2053 14.5906 13.2053 13.8679C13.2053 13.1737 13.5249 12.7706 14.0558 12.7706C14.4553 12.7706 14.7163 13.0032 14.7607 13.3672H15.6484Z"
      fill="black"
    />
  </SvgIcon>
);
