import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const OptionsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 5H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 5H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.375 15H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 15H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 10H17.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 10H5.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 6.25C13.8154 6.25 14.375 5.69036 14.375 5C14.375 4.30964 13.8154 3.75 13.125 3.75C12.4346 3.75 11.875 4.30964 11.875 5C11.875 5.69036 12.4346 6.25 13.125 6.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 11.25C7.56536 11.25 8.125 10.6904 8.125 10C8.125 9.30964 7.56536 8.75 6.875 8.75C6.18464 8.75 5.625 9.30964 5.625 10C5.625 10.6904 6.18464 11.25 6.875 11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 16.25C13.8154 16.25 14.375 15.6904 14.375 15C14.375 14.3096 13.8154 13.75 13.125 13.75C12.4346 13.75 11.875 14.3096 11.875 15C11.875 15.6904 12.4346 16.25 13.125 16.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
