import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Client, FILES_PAGE } from 'src/constants';
import { RootState } from 'src/store';
import { GraySmall } from 'src/theme/colors';
import { regular } from 'src/theme/weights';
import { FilesBreadcrumbs } from 'src/legacy/components/Files/FilesBreadcrumbs';
import { NotificationTableRowData } from 'src/legacy/components/Inbox/InboxContent';

interface NotificationTableBreadcrumbRendererProps {
  data: NotificationTableRowData;
}

const useStyles = makeStyles(() =>
  createStyles({
    breadcrumbs: {
      background: 'transparent',
      flexWrap: 'nowrap',
      padding: 0,
    },
    lastChip: {
      '&> span:first-of-type': {
        fontWeight: regular,
        color: GraySmall,
      },
    },
  }),
);

export const NotificationTableBreadcrumbRenderer = (
  props: NotificationTableBreadcrumbRendererProps,
) => {
  const classes = useStyles();
  const {
    data: { location, channelId },
  } = props;
  const baseFilesUrl = `${FILES_PAGE.path}?channelId=${channelId || ''}`;

  const fileChannels = useSelector((state: RootState) => state.files.channels);
  const activeClients = useSelector(
    (state: RootState) => state.clients.activeClients,
  );

  /**
   * get the label of first breadcrumb
   */
  const getFirstNodeLabel = React.useMemo(() => {
    const selectedChannel = fileChannels.find((c) => c.id === channelId);
    if (selectedChannel) {
      // if individual client's channel the label will be client name
      if (selectedChannel.fields?.singleClientId) {
        let nodeLabel = '';
        const { memberIDs = {} } = selectedChannel.additionalFields || {};
        Object.keys(memberIDs).forEach((mID: string) => {
          const client = activeClients.find(
            (clientUser: Client) => clientUser.id === mID,
          );
          if (client) {
            nodeLabel = `${client.fields?.givenName}'s files`;
          }
        });
        if (nodeLabel !== '') {
          return nodeLabel;
        }
      }
      // for channels having more than 1 client we will use "Group's files" as label
      return `Group's files`;
    }

    return '';
  }, [fileChannels, activeClients, channelId]);

  const filePathParts = location
    .split('/')
    .filter((part: string) => part !== '');

  if (!channelId) return '';

  return (
    <div data-testid="notification-file-breadcrumb">
      <FilesBreadcrumbs
        firstNodeLabel={getFirstNodeLabel}
        selectedChannelId={channelId}
        filePathParts={filePathParts}
        baseURL={baseFilesUrl}
        selectedFilePath={location}
        isSmallWidth={false}
        hasToolbar={false}
        className={classes.breadcrumbs}
        showRootChip
        lastChipClassname={classes.lastChip}
      />
    </div>
  );
};
