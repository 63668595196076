import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BrushIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.6707 2.32943C17.3741 2.03284 16.9718 1.86621 16.5523 1.86621C16.1329 1.86621 15.7306 2.03284 15.434 2.32943L7.1875 11.5134C8.08906 11.697 8.98906 12.5794 9.11172 13.4376L17.6707 4.56615C17.9673 4.26954 18.1339 3.86726 18.1339 3.44779C18.1339 3.02833 17.9673 2.62605 17.6707 2.32943V2.32943Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.39062 13.125C4.22344 13.125 3.28125 14.082 3.28125 15.268C3.28125 16.2035 2.46562 16.6965 1.875 16.6965C2.52187 17.568 3.62578 18.125 4.6875 18.125C6.24141 18.125 7.5 16.8465 7.5 15.268C7.5 14.082 6.55781 13.125 5.39062 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
