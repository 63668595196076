export const BANNER_HEIGHT = 40;

export const DESKTOP_APP_BAR_HEIGHT = 61;

export const MOBILE_APP_BAR_HEIGHT = 52;

export const DRAWER_WIDTH = 200;

// 1396 = 1080 (content max width) + 280 + 36 (left and right padding)
export const SETTINGS_PAGE_MAX_WIDTH = 1396;
export enum AppViewMode {
  CLIENT = 'client',
  INTERNAL = 'internal',
}
