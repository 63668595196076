import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NotificationClipboardIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.2026 3.48571H13.9756C13.8941 2.43171 13.2116 1.80469 12.1013 1.80469H7.89871C6.7884 1.80469 6.10592 2.43171 6.02439 3.48571H5.79743C3.76507 3.48571 2.64551 4.60528 2.64551 6.63764V15.0428C2.64551 17.0751 3.76507 18.1947 5.79743 18.1947H14.2026C16.2349 18.1947 17.3545 17.0751 17.3545 15.0428V6.63764C17.3545 4.60528 16.2349 3.48571 14.2026 3.48571ZM7.26833 3.69584C7.26833 3.20078 7.40365 3.06546 7.89871 3.06546H12.1013C12.5963 3.06546 12.7317 3.20078 12.7317 3.69584V4.53635C12.7317 5.03142 12.5963 5.16674 12.1013 5.16674H7.89871C7.40365 5.16674 7.26833 5.03142 7.26833 4.53635V3.69584ZM16.0937 15.0428C16.0937 16.3683 15.528 16.9339 14.2026 16.9339H5.79743C4.47194 16.9339 3.90628 16.3683 3.90628 15.0428V6.63764C3.90628 5.31215 4.47194 4.74648 5.79743 4.74648H6.02439C6.10592 5.80049 6.7884 6.42751 7.89871 6.42751H12.1013C13.2116 6.42751 13.8941 5.80049 13.9756 4.74648H14.2026C15.528 4.74648 16.0937 5.31215 16.0937 6.63764V15.0428Z"
      fill="currentColor"
    />
  </SvgIcon>
);
