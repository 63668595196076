import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BeakerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.3906 1.87695L15.5469 1.875H5C2.87891 1.875 2.5 3.26953 2.5 3.75C3.68359 3.91406 4.375 4.0625 4.375 5.3125V15.625C4.375 16.288 4.63839 16.9239 5.10723 17.3928C5.57607 17.8616 6.21196 18.125 6.875 18.125H14.375C15.038 18.125 15.6739 17.8616 16.1428 17.3928C16.6116 16.9239 16.875 16.288 16.875 15.625V3.75C16.875 3.00781 17.3242 2.25195 17.3672 2.1875C17.4141 2.11328 17.5 2.01563 17.5 1.97266C17.5 1.92969 17.4883 1.87695 17.3906 1.87695Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 6.875H16.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
