import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BasketIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M2.67192 7.5C2.46186 7.49979 2.26023 7.5826 2.11097 7.7304C1.9617 7.87821 1.8769 8.07901 1.87505 8.28906C1.8742 8.36187 1.88474 8.43436 1.9063 8.50391L3.92583 15.625C4.01943 15.9576 4.21947 16.2504 4.49532 16.4585C4.77117 16.6667 5.10762 16.7786 5.45317 16.7773H14.5469C14.8931 16.7777 15.2301 16.6654 15.507 16.4576C15.7839 16.2497 15.9857 15.9575 16.0821 15.625L18.1016 8.50391L18.125 8.28906C18.1232 8.07901 18.0384 7.87821 17.8891 7.7304C17.7399 7.5826 17.5382 7.49979 17.3282 7.5H2.67192ZM10.2235 13.7527C9.89881 13.7511 9.5819 13.6534 9.31273 13.4718C9.04356 13.2902 8.83421 13.033 8.71108 12.7326C8.58796 12.4322 8.55658 12.102 8.62091 11.7838C8.68525 11.4655 8.8424 11.1735 9.07255 10.9445C9.30271 10.7155 9.59554 10.5598 9.91409 10.497C10.2326 10.4343 10.5626 10.4673 10.8625 10.5919C11.1623 10.7165 11.4184 10.9272 11.5987 11.1972C11.7789 11.4673 11.875 11.7847 11.875 12.1094C11.873 12.546 11.6981 12.964 11.3885 13.272C11.079 13.58 10.6601 13.7528 10.2235 13.7527V13.7527Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 7.5L10 2.5L13.75 7.5"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
