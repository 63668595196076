export const LEAD_TOOLTIP_DESCRIPTION =
  'The team member selected is the individual that is primarily responsible for the client. If the messaging module is enabled, welcome messages are always sent by the lead.';

export const VISIBILITY_TOOLTIP_DESCRIPTION =
  "The team member/s selected can see the client and the client's associated information. Admins are always selected for all clients.";

export const DELETE_CUSTOM_FIELD_CONFIRMATION =
  'When you delete a property, the property and the values that you have saved for all clients will be deleted.';

export const USER_STATUSES = 'User Statuses';
export const INACTIVE = 'Inactive';
export const ACTIVE = 'Active';
export const CLIENT_HAS_NOT_ACTIVATED_ACCOUNT =
  'A client who has not activated their account and has not interacted with your business in any way.';
export const CLIENT_HAS_SUCCESSFULLY_ACTIVATED_ACCOUNT =
  'A client who has successfully activated their account by either (1) opening a magic link (delivered via email or shared directly), (2) signing in with Google, or (3) setting a password.';
export const IU_HAS_NOT_ACTIVATED_ACCOUNT =
  'A user who has not activated their account and has not interacted with a workspace in any way.';
export const IU_HAS_SUCCESSFULLY_ACTIVATED_ACCOUNT =
  'A user who has successfully activated their account and joined a workspace.';
