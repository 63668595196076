import { BaseItemsInitialState } from 'src/store/reduxTypes';
import {
  DashboardState,
  ExtensionActionTypes,
  ExtensionActions,
  ExtensionItem,
} from 'src/store/dashboard/types';

const initialState: DashboardState = {
  ...BaseItemsInitialState,
};

// eslint-disable-next-line default-param-last
const DashboardReducer = (state = initialState, action: ExtensionActions) => {
  switch (action.type) {
    case ExtensionActionTypes.GET_ITEMS:
      return {
        ...state,
        itemsLoading: true,
        itemsLoaded: false,
      };
    case ExtensionActionTypes.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItemId: action.itemId,
        selectedItemType: action.options?.itemType,
        selectedItemTitle: action.options?.title,
      };
    case ExtensionActionTypes.SET_EXTENSION_ITEMS:
      return {
        ...state,
        itemsLoading: false,
        itemsLoaded: true,
        items: action.items,
      };
    case ExtensionActionTypes.GET_ITEMS_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsLoaded: false,
        loadingError: action.error,
      };
    case ExtensionActionTypes.CREATE_ITEMS_ERROR:
      return {
        ...state,
        createError: action.error,
      };
    case ExtensionActionTypes.UPDATE_EXTENSION_ITEMS: {
      // create a map of itemId to updated item
      const updateItemIdToObj: Record<string, ExtensionItem> =
        action.items.reduce(
          (map, item) => ({
            ...map,
            [item.id]: item,
          }),
          {},
        );

      // for all existing items in state see if they are part of updated item map
      const updatedItems = state.items.map((item) => {
        if (updateItemIdToObj[item.id]) {
          // if item is part of updated item map then this should replace the item
          // in the existing state
          const updatedItem = updateItemIdToObj[item.id];
          delete updateItemIdToObj[item.id]; // remove item to track that it has been updated
          return updatedItem;
        }
        return item;
      });

      // only items remaining in updated map are new ones which can be added directly
      const newItems = Object.entries(updateItemIdToObj).map(
        ([, dashboardItem]) => dashboardItem,
      );
      return {
        ...state,
        items: [...updatedItems, ...newItems],
      };
    }
    case ExtensionActionTypes.DELETE_EXTENSION_ITEMS: {
      const { itemIds, resetSelectedChannel } = action;
      const filteredItems = state.items.filter(
        (chan) => !itemIds.includes(chan.id),
      );

      const selectedItemId = resetSelectedChannel ? '' : state.selectedItemId;

      return {
        ...state,
        items: filteredItems,
        selectedItemId,
      };
    }

    default:
      return state;
  }
};

export default DashboardReducer;
