import React from 'react';
import { uniq } from 'lodash';
import { Auth } from 'aws-amplify';
import { AuthenticatedPortalDataType } from 'src/legacy/components/Onboarding/Login/onboardingLoginTypes';
import UsersClient from 'src/clients/UsersClient';
import { PortalConfigContext, RouteContext, SessionData } from 'src/context';
import { setAuthError, setProcessingCallback } from 'src/store/auth/slice';
import { useAppDispatch } from 'src/hooks/useStore';
import { AuthErrors } from 'src/constants/errorConsts/errorCodesConsts';
import { CognitoUserWithAttributes } from 'src/utils/CognitoUtils';
import { SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY } from 'src/constants/authConsts';
import { logoutFromHostedUi } from 'src/utils/AuthUtils';
import { ensureApiError } from 'src/utils/Errors';

type getWorkspacesOptions = {
  querySession?: boolean;
};

export const useWorkspaces = () => {
  const [loadingWorkspaces, setLoadingWorkspaces] = React.useState(false);
  const { sessionData } = React.useContext(RouteContext);
  const portalConfig = React.useContext(PortalConfigContext);

  const dispatch = useAppDispatch();

  const getWorkspaces = async ({ querySession }: getWorkspacesOptions = {}) => {
    setLoadingWorkspaces(true);
    let portalIdToSession: SessionData['portalIdToSession'] = {};
    // get valid portal sessions
    if (querySession) {
      // if useApi is true, call the API to get the sessions
      const portalSessionsResponse = await UsersClient.getSessions();
      ({ portalIdToSession } = portalSessionsResponse.data);
    } else {
      // otherwise, use the session data from the context
      ({ portalIdToSession } = sessionData);
    }

    // extract the unique list of emails from the portal sessions
    const userEmails = uniq(
      Object.values(portalIdToSession || {})
        .map((session) => session.email || '') // use || to satisfy typescript
        .filter(Boolean),
    );

    try {
      // call GET /workspaces to get the workspaces
      const workspaces: Record<
        string,
        Record<string, AuthenticatedPortalDataType>
      > = await UsersClient.getWorkspaces(userEmails);
      setLoadingWorkspaces(false);

      return { workspaces, portalIdToSession };
    } catch (ex) {
      const error = ensureApiError(ex);
      // a user_not_found error might indicate an invalid user being logged in
      if (error.code === 'user_not_found') {
        dispatch(setProcessingCallback(false));

        let user: CognitoUserWithAttributes;
        try {
          // if there is a user signed but does not have access to the apis,
          // then the user is not present in the database. Here we use the signed in user info
          // to show a customized error message
          user = await Auth.currentAuthenticatedUser();

          // save the failed user's email, this is used only to show error message
          window.sessionStorage.setItem(
            SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY,
            user.attributes.email,
          );

          // this user is invalid and will not be able to sign in, so we sign him out
          // this allows him to select another account while logging in with google
          // we set the error code in the url, so that the ui can display the proper error message
          logoutFromHostedUi(
            portalConfig,
            `${window.location.origin}/login?app-error=${error.code}`,
          );
        } catch (ex1) {
          // show a general error message
          dispatch(setAuthError(AuthErrors.userSessionNotFound));
        }
      }

      setLoadingWorkspaces(false);
    }
    return {
      workspaces: {},
      portalIdToSession: {},
    };
  };

  return { getWorkspaces, loadingWorkspaces, setLoadingWorkspaces };
};
