import React, { CSSProperties } from 'react';
import { makeStyles, Chip, ChipProps, Theme } from '@material-ui/core';
import {
  CopilotGreen,
  CopilotLightGreen,
  GraySmall,
  HighSeverityTagBackgroundColor,
  LowSeverityTagBackgroundColor,
  LowSeverityTagTextColor,
  MediumSeverityTagBackgroundColor,
  MediumSeverityTagTextColor,
  NeutralSeverityTagBackgroundColor,
  red,
} from 'src/theme/colors';
import { CloseIcon } from 'src/legacy/components/Icons';
import ColorUtils from 'src/utils/ColorUtils';

interface BaseChipProps extends ChipProps {
  severity?: SeverityLevel;
  deleteIconStyle?: CSSProperties;
}

export enum SeverityLevel {
  high = 'high',
  medium = 'medium',
  low = 'low',
  neutral = 'neutral',
  upgrade = 'upgrade',
}

export const severityStyles = {
  high: {
    background: HighSeverityTagBackgroundColor,
    color: red,
  },
  medium: {
    background: MediumSeverityTagBackgroundColor,
    color: MediumSeverityTagTextColor,
  },
  low: {
    background: LowSeverityTagBackgroundColor,
    color: LowSeverityTagTextColor,
  },
  neutral: {
    background: NeutralSeverityTagBackgroundColor,
    color: GraySmall,
  },
  upgrade: {
    background: CopilotLightGreen,
    color: CopilotGreen,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  defaultChip: {
    backgroundColor: ColorUtils.GetColorDarknessShades(
      theme.palette.primary.main,
    ).light,
    color: theme.palette.primary.main,
  },
  closeIcon: {
    backgroundColor: ColorUtils.GetColorDarknessShades(
      theme.palette.primary.main,
    ).light,
    borderRadius: theme.shape.borderRadius,
    padding: '3px',
    height: 6,
    width: 6,
    color: theme.palette.primary.main,
    marginLeft: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  ...severityStyles,
}));

export const BaseChip: React.FC<BaseChipProps> = ({
  severity,
  deleteIconStyle,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Chip
      classes={{ root: classes.defaultChip, deleteIcon: classes.closeIcon }}
      deleteIcon={<CloseIcon style={deleteIconStyle || {}} />}
      className={`${severity ? classes[severity] : classes.defaultChip}`}
      {...rest}
    />
  );
};
