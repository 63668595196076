import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GlobeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 1.875C5.51289 1.875 1.875 5.51289 1.875 10C1.875 14.4871 5.51289 18.125 10 18.125C14.4871 18.125 18.125 14.4871 18.125 10C18.125 5.51289 14.4871 1.875 10 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M9.9998 1.875C7.73145 1.875 5.59863 5.51289 5.59863 10C5.59863 14.4871 7.73145 18.125 9.9998 18.125C12.2682 18.125 14.401 14.4871 14.401 10C14.401 5.51289 12.2682 1.875 9.9998 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M4.58301 4.5835C6.07676 5.64404 7.95723 6.27646 9.9998 6.27646C12.0424 6.27646 13.9229 5.64404 15.4166 4.5835"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4166 15.4171C13.9229 14.3565 12.0424 13.7241 9.9998 13.7241C7.95723 13.7241 6.07676 14.3565 4.58301 15.4171"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 1.875V18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M18.125 10H1.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
