import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const NewspaperIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.375 16.2445V2.8125C14.3743 2.56408 14.2753 2.32604 14.0996 2.15038C13.924 1.97473 13.6859 1.87572 13.4375 1.875H2.8125C2.56408 1.87572 2.32604 1.97473 2.15038 2.15038C1.97473 2.32604 1.87572 2.56408 1.875 2.8125V16.5625C1.87624 16.9765 2.04125 17.3732 2.33401 17.666C2.62677 17.9587 3.02348 18.1238 3.4375 18.125H16.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 18.125C15.7527 18.125 15.2758 17.9275 14.9242 17.5758C14.5725 17.2242 14.375 16.7473 14.375 16.25V5H17.1875C17.4361 5 17.6746 5.09877 17.8504 5.27459C18.0262 5.4504 18.125 5.68886 18.125 5.9375V16.25C18.125 16.7473 17.9275 17.2242 17.5758 17.5758C17.2242 17.9275 16.7473 18.125 16.25 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 5H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 7.5H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 10H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 12.5H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 15H11.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 8.125H4.375C4.20924 8.125 4.05027 8.05915 3.93306 7.94194C3.81585 7.82473 3.75 7.66576 3.75 7.5V5C3.75 4.83424 3.81585 4.67527 3.93306 4.55806C4.05027 4.44085 4.20924 4.375 4.375 4.375H6.875C7.04076 4.375 7.19973 4.44085 7.31694 4.55806C7.43415 4.67527 7.5 4.83424 7.5 5V7.5C7.5 7.66576 7.43415 7.82473 7.31694 7.94194C7.19973 8.05915 7.04076 8.125 6.875 8.125Z"
      fill="currentColor"
    />
  </SvgIcon>
);
