import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AutomationsIcon = (props?: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.3419 1.25C13.9949 1.25 14.4667 1.87549 14.2855 2.50098L12.4564 8.90625H15.3693C16.0292 8.90625 16.5625 9.43945 16.5625 10.0991C16.5625 10.4512 16.4087 10.7827 16.142 11.0083L7.29739 18.5176C7.11961 18.668 6.89397 18.75 6.66148 18.75C6.00848 18.75 5.53668 18.1245 5.71788 17.499L7.54697 11.0938H4.59307C3.95375 11.0938 3.4375 10.5776 3.4375 9.94189C3.4375 9.60352 3.58451 9.28564 3.84093 9.06689L12.706 1.48584C12.8838 1.33203 13.1095 1.25 13.3419 1.25ZM12.1077 4.15527L5.91275 9.45312H8.63417C8.89058 9.45312 9.13332 9.57275 9.29059 9.77783C9.44785 9.98291 9.49572 10.2495 9.42392 10.4956L7.89227 15.8618L14.1556 10.5469H11.3693C11.1128 10.5469 10.8701 10.4272 10.7128 10.2222C10.5556 10.0171 10.5077 9.75049 10.5795 9.50439L12.1077 4.15527Z"
      fill="currentColor"
    />
  </SvgIcon>
);
