import { API } from 'src/utils/AmplifyApiUtils';

export default class MessagesClient {
  static GetUserToken(): Promise<string> {
    return API.get('MessagesAPI', `/token`, { responseType: 'text' });
  }

  static SetupChannelMembers(members: string[]) {
    return API.post('MessagesAPI', `/members`, {
      body: {
        members,
      },
    });
  }

  static HideChannel(channelId: string) {
    return API.post('MessagesAPI', `/channels/${channelId}/archive`, {});
  }

  static SetUserRole(memberId: string) {
    return API.post('MessagesAPI', `/member/${memberId}/role`, {});
  }

  static SendMassMessage(body: { memberIds: string[]; messageBody: string }) {
    return API.post('MessagesAPI', `/message`, {
      body,
    });
  }
}
