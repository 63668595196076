import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GitMergeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5.03906 5.625C6.0746 5.625 6.91406 4.78553 6.91406 3.75C6.91406 2.71447 6.0746 1.875 5.03906 1.875C4.00353 1.875 3.16406 2.71447 3.16406 3.75C3.16406 4.78553 4.00353 5.625 5.03906 5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.03906 18.125C6.0746 18.125 6.91406 17.2855 6.91406 16.25C6.91406 15.2145 6.0746 14.375 5.03906 14.375C4.00353 14.375 3.16406 15.2145 3.16406 16.25C3.16406 17.2855 4.00353 18.125 5.03906 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.03906 5.625V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0391 13.125C16.0746 13.125 16.9141 12.2855 16.9141 11.25C16.9141 10.2145 16.0746 9.375 15.0391 9.375C14.0035 9.375 13.1641 10.2145 13.1641 11.25C13.1641 12.2855 14.0035 13.125 15.0391 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.03906 5.625C5.03906 9.375 9.41406 11.25 13.1641 11.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
