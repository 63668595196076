import React from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { InvoiceActionRendererProps } from 'src/legacy/components/AgGrid/Renderer/types';

const InvoiceActionRenderer = ({ data }: InvoiceActionRendererProps) => (
  <IconButton
    size="small"
    onClick={() => window.open(data.hosted_invoice_url, '_blank')}
  >
    <ArrowForward />
  </IconButton>
);

export default InvoiceActionRenderer;
