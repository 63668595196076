import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const JustifyRightIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 20" fill="none" {...props}>
    <path
      d="M26.1538 3H13.8462C12.8266 3 12 3.85473 12 4.90909V15.0909C12 16.1453 12.8266 17 13.8462 17H26.1538C27.1734 17 28 16.1453 28 15.0909V4.90909C28 3.85473 27.1734 3 26.1538 3Z"
      stroke="currentColor"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M23.5 8C24.3284 8 25 7.32843 25 6.5C25 5.67157 24.3284 5 23.5 5C22.6716 5 22 5.67157 22 6.5C22 7.32843 22.6716 8 23.5 8Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      fill="none"
    />
    <path
      d="M21.8462 13.0745L18.3592 9.38231C18.1373 9.14698 17.8391 9.01043 17.5255 9.00057C17.2119 8.99071 16.9066 9.10829 16.6719 9.32927L12 13.7359M18.7692 17L23.5131 11.9675C23.73 11.7369 24.0204 11.6007 24.3272 11.5856C24.634 11.5705 24.935 11.6776 25.1712 11.8859L28 14.3887"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2 15H10M2 5H10H2ZM2 10H10H2Z"
      stroke="#90959D"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
