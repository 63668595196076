import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const StatsIcon = (
  <SvgIcon viewBox="0 0 470 470" enableBackground="new 0 0 470 470">
    <g>
      <path d="m257.567,360.535h-45c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h45c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
      <path d="m469.962,397.284c-0.03-0.304-0.082-0.597-0.146-0.885-0.015-0.067-0.034-0.132-0.051-0.198-0.058-0.229-0.125-0.454-0.204-0.674-0.025-0.07-0.049-0.139-0.076-0.208-0.1-0.258-0.212-0.509-0.339-0.752-0.009-0.016-0.014-0.034-0.023-0.051-0.038-0.072-41.624-78.35-41.624-78.35v-204.275c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v198.644h-355v-211.071c0-12.407 10.093-22.5 22.5-22.5h297.574c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-297.573c-20.678,0-37.5,16.822-37.5,37.5v216.702l-41.585,78.278c-0.047,0.089-0.053,0.106-0.061,0.123-0.127,0.243-0.239,0.495-0.339,0.753-0.027,0.068-0.051,0.137-0.075,0.206-0.079,0.221-0.146,0.446-0.204,0.676-0.017,0.066-0.036,0.13-0.05,0.197-0.064,0.288-0.116,0.581-0.146,0.88-0.027,0.252-0.04,0.502-0.04,0.756v15c0,20.678 16.822,37.5 37.5,37.5h395c20.678,0 37.5-16.822 37.5-37.5v-15c0-0.254-0.013-0.504-0.038-0.751zm-415.454-71.749h360.983l34.531,65h-430.045l34.531-65zm377.992,110h-395c-12.407,0-22.5-10.093-22.5-22.5v-7.5h440v7.5c0,12.407-10.093,22.5-22.5,22.5z" />
      <path d="m422.5,34.465h21.894l-179.003,179.002-55.411-55.411c-2.929-2.929-7.678-2.929-10.606,0l-124.677,124.676c-2.929,2.929-2.929,7.678 0,10.606 1.464,1.464 3.384,2.197 5.303,2.197s3.839-0.732 5.303-2.197l119.373-119.373 55.411,55.411c2.929,2.929 7.678,2.929 10.606,0l184.307-184.305v21.894c0,4.142 3.358,7.5 7.5,7.5s7.5-3.358 7.5-7.5v-40c0-4.142-3.358-7.5-7.5-7.5h-40c-4.142,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
    </g>
  </SvgIcon>
);
