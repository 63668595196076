export const EXTENSION_SETTINGS_UPGRADE_TITLE = 'Add an App';
export const EXTENSION_SETTINGS_UPGRADE_DESCRIPTION =
  'With Apps, extend your portal with hundreds of popular apps including Airtable, ClickUp, Monday.com, Google Data Studio, Databox, and Calendly. Apps show on the sidebar, just like Portal modules, and let you offer clients a one-stop-shop with everything.';
export const ExtensionStandardPageTitle = 'Embed an App';
export const ExtensionStandardPageDescription =
  'You can embed an App like Google Data Studio here to share with clients.';
export const ExtensionStandardPageConnectButton = 'Connect App';
export const DISABLE_APP_DESCRIPTION =
  'When you disable an app it will no longer be accessible by your team or clients. If you enable it again in the future all data from before will be restored.';
export const REMOVE_APP_DESCRIPTION =
  'When you remove an app, all clients will immediately lose access and any configuration details will be lost.';
