import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const AlarmIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.2529 10.625C16.2529 9.38887 15.8864 8.1805 15.1996 7.15269C14.5129 6.12488 13.5367 5.3238 12.3947 4.85076C11.2527 4.37771 9.996 4.25394 8.78362 4.49509C7.57124 4.73625 6.45759 5.33151 5.58352 6.20559C4.70944 7.07966 4.11418 8.19331 3.87302 9.40569C3.63187 10.6181 3.75564 11.8747 4.22869 13.0168C4.70173 14.1588 5.50281 15.1349 6.53062 15.8217C7.55843 16.5084 8.7668 16.875 10.0029 16.875C11.6605 16.875 13.2502 16.2165 14.4224 15.0444C15.5945 13.8723 16.2529 12.2826 16.2529 10.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M5.55176 3.56289C5.22379 3.28224 4.80686 3.12707 4.3752 3.125L4.26622 3.12813C3.26817 3.18828 2.5002 4.0625 2.50294 5.11719C2.50294 5.6332 2.68497 5.87383 2.92794 6.18086C2.94303 6.20059 2.96209 6.21693 2.98389 6.22884C3.00569 6.24075 3.02974 6.24796 3.0545 6.25H3.08887C3.10823 6.24965 3.12725 6.24486 3.14445 6.23598C3.16166 6.22711 3.17659 6.2144 3.18809 6.19883L5.56192 3.86719C5.58219 3.84669 5.59803 3.82224 5.60844 3.79536C5.61886 3.76848 5.62363 3.73974 5.62247 3.71094C5.62148 3.68273 5.6147 3.65502 5.60253 3.62955C5.59037 3.60408 5.57309 3.58139 5.55176 3.56289V3.56289Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M14.4488 3.56289C14.7768 3.28224 15.1937 3.12707 15.6254 3.125L15.7344 3.12813C16.7324 3.18828 17.5004 4.0625 17.4976 5.11719C17.4976 5.6332 17.3156 5.87383 17.0726 6.18086C17.0575 6.20052 17.0384 6.21679 17.0166 6.22863C16.9948 6.24047 16.9708 6.24762 16.9461 6.24961H16.9117C16.8923 6.24926 16.8733 6.24447 16.8561 6.23559C16.8389 6.22672 16.824 6.21401 16.8125 6.19844L14.4386 3.86719C14.4184 3.84669 14.4025 3.82224 14.3921 3.79536C14.3817 3.76848 14.3769 3.73974 14.3781 3.71094C14.3791 3.68273 14.3859 3.65502 14.398 3.62955C14.4102 3.60408 14.4275 3.58139 14.4488 3.56289V3.56289Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M10.0029 6.25V10.625H6.87793"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2529 16.875L14.6904 15.3125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75293 16.875L5.31543 15.3125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
