import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import {
  ADD_ACCOUNT_PAGE,
  ADMIN_PAGE,
  AppViewMode,
  EmailValidationRegex,
  AUTH_ACCESS_LINK_PAGE,
  LOGIN_PAGE_BASE_PATH,
  ONBOARDING_PAGE,
  SIGNUP_PAGE,
  WORKSPACES_PAGE,
  DOMAIN_CHANGE_PAGE,
} from 'src/constants';
import {
  REDIRECT_PATH_AFTER_GOOGLE_LOGIN,
  SIGNED_IN_WITH_GOOGLE_USER_KEY,
} from 'src/constants/authConsts';
import { PortalConfig } from 'src/context';
import * as Yup from 'yup';

const unAuthPageRoutes = [
  LOGIN_PAGE_BASE_PATH,
  AUTH_ACCESS_LINK_PAGE,
  ADD_ACCOUNT_PAGE,
  WORKSPACES_PAGE,
  SIGNUP_PAGE,
  ONBOARDING_PAGE,
  ADMIN_PAGE,
  DOMAIN_CHANGE_PAGE,
];

const flexiblyAuthedPageRoutes = ['/payment-links/pay'];

export const isPassword = Yup.string()
  .max(256, 'Password must be less than 256 characters')
  .min(8, 'Password must be at least 8 characters')
  .trim('Password cannot have leading or trailing spaces')
  .matches(/[a-z]/, 'Password must have at least one lowercase char')
  .matches(/[A-Z]/, 'Password must have at least one uppercase char')
  .matches(/[0-9]+/, 'Password must have at least one number');
export const PasswordInputValidation = isPassword.required(
  'Password is required',
);

type getPasswordValidationOptions = {
  isPortalLogin: boolean;
  isAdminLogin: boolean;
};

/**
 * Get password validation schema based on login type
 * @param options config for password validation
 * @returns
 */
export const GetPasswordValidation = ({
  isPortalLogin,
  isAdminLogin,
}: getPasswordValidationOptions) => {
  if (isAdminLogin) {
    // admin login does not require password
    return Yup.string();
  }

  if (isPortalLogin) {
    // portal login requires password, BE handles validation
    return Yup.string().required('Password is required');
  }

  // otherwise this is new password validation where FE checks for regex
  // for nicer validation experience that doesn't require user to submit
  return PasswordInputValidation;
};

export const isValidEmail = (email: string) => EmailValidationRegex.test(email);

type getPreferredUsernameInput = {
  email: string;
  viewMode: string;
  portalId: string;
};

export const getPreferredUsername = ({
  email: inputEmail,
  viewMode,
  portalId,
}: getPreferredUsernameInput) => {
  const email = inputEmail.toLowerCase(); // lower-case email to match saved user property
  return viewMode === 'client' ? `${portalId}|${email}` : email;
};

export type GetSignInMetadataInput = {
  poolId?: string;
  clientId?: string;
};

/**
 * Create client metadata object for cognito. This is used in the signIn methods
 * to pass the poolId and clientId to cognito for migration.
 * @param queryParam query param object with poolId and clientId
 * @returns
 */
export const GetSignInMetadata = (queryParam: GetSignInMetadataInput) => {
  let metadata;
  const { poolId, clientId } = queryParam;
  if (poolId && clientId) {
    metadata = {
      userPoolId: poolId,
      clientId,
    };
  }
  return metadata;
};

/**
 * Check if the pathname starts with any of the UnAuth page routes. This is used by server
 * side rendering and client side rendering to determine which App component to use.
 * @param pathname path name of the request
 * @param query query object of the request
 * @returns true/false if the request is for an unauth page
 */
export const UseUnAuthApp = (pathname: string) =>
  unAuthPageRoutes.some((x) => pathname.startsWith(x));

export const UseFlexibleAuth = (pathname: string) =>
  flexiblyAuthedPageRoutes.some((x) => pathname.startsWith(x));

export const SignInWithGoogle = async (customState: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const { portalConfig } = window.App;
  const redirectSignOutURL = portalConfig.AWS.Auth.oauth?.redirectSignOut;

  // to complete google login the user is redirected to the google endpoint
  // the next params are lost in this process, so here we save them in sessionStorage before
  // redirecting
  const query = new URLSearchParams(window.location.search);
  const next = query.get('next');
  if (next) {
    window.sessionStorage.setItem(REDIRECT_PATH_AFTER_GOOGLE_LOGIN, next);
  }

  // for clients we make an additional call to initiate-signin
  // this sets the current origin as a cookie, so that the users are redirected
  // back to this url even when the login fails
  if (portalConfig.viewMode === AppViewMode.CLIENT && redirectSignOutURL) {
    const initLoginApi = `${
      new URL(redirectSignOutURL).origin
    }/auth/google/initiate-login?origin=${window.location.origin}`;
    await axios.get(initLoginApi, { withCredentials: true });
  }

  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState,
  });
};

/**
 * logs out the current logged in user from cognito's hosted ui
 * @param portalConfig uses this to determine the client id and hosted ui domain to sign out from
 * @param logoutUri this is where the user will be redirected after the logout is complete. This
 * should be one of the allowed log out uris in cognito
 */
export const logoutFromHostedUi = (
  portalConfig: PortalConfig,
  logoutUri: string,
) => {
  // inputs for logout endpoint
  const logOutParams = new URLSearchParams({
    client_id: portalConfig.AWS.Auth.userPoolWebClientId,
    // once log out is complete, we redirect to the url in the current flow
    logout_uri: logoutUri,
  });

  window.localStorage.removeItem(SIGNED_IN_WITH_GOOGLE_USER_KEY);
  // call the logout endpoint of the hosted ui which logs any google authed user out
  // this cannot be used as an xmlhttprequest as with all hosted ui endpoint
  // amplify does the same for all calls, since these redirects are fast its not noticeable in the ui
  window.location.href = `https://${
    portalConfig.AWS.Auth.oauth?.domain
  }/logout?${logOutParams.toString()}`;
};
