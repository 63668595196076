import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BacklinksIcons = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M6.38702 6.30769H5.15385C4.05218 6.30769 2.99563 6.71291 2.21663 7.4342C1.43764 8.1555 1 9.13378 1 10.1538C1 11.1739 1.43764 12.1522 2.21663 12.8735C2.99563 13.5948 4.05218 14 5.15385 14H14.8462C15.9478 14 17.0044 13.5948 17.7834 12.8735C18.5624 12.1522 19 11.1739 19 10.1538C19 9.13378 18.5624 8.1555 17.7834 7.4342C17.0044 6.71291 15.9478 6.30769 14.8462 6.30769H12.9639M11.6586 4L8.89231 6.56135L11.6586 9.1227"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
