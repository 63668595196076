import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TennisballIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0246 10.6031C17.8258 10.6176 17.7027 10.625 17.5 10.625C13.0125 10.625 9.375 6.9875 9.375 2.5C9.375 2.29023 9.38281 2.16055 9.39844 1.95312"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.93945 9.39687C1.93945 9.39687 2.29844 9.375 2.5 9.375C6.9875 9.375 10.625 13.0125 10.625 17.5C10.625 17.7102 10.6012 18.0469 10.6012 18.0469"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
