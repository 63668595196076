import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FishIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M9.375 5.9375C7.39414 6.41445 5.69727 7.98086 4.67578 8.80586C4.38109 8.53282 4.07052 8.27743 3.7457 8.04102C2.32539 7.00352 0.625 6.875 0.625 6.875C0.625 6.875 1.06914 8.88789 2.24063 9.99336C1.06914 11.0992 0.625 13.1121 0.625 13.1121C0.625 13.1121 2.32539 12.9836 3.7457 11.9461C4.06777 11.7117 4.37573 11.4585 4.66797 11.1879C5.6875 12.0137 7.39219 13.584 9.375 14.0625L8.75 16.25C10.2902 15.9895 11.8305 14.8629 12.45 14.3652C17.5 14.1406 19.375 10.8984 19.375 10C19.375 9.14062 17.5 5.85938 12.4652 5.63555C11.8562 5.14766 10.3031 4.0125 8.75 3.75L9.375 5.9375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 9.99976C16.5952 9.99976 16.875 9.71993 16.875 9.37476C16.875 9.02958 16.5952 8.74976 16.25 8.74976C15.9048 8.74976 15.625 9.02958 15.625 9.37476C15.625 9.71993 15.9048 9.99976 16.25 9.99976Z"
      fill="currentColor"
    />
    <path
      d="M14.7799 13.9062C14.0912 12.7196 13.7285 11.372 13.7285 10C13.7285 8.628 14.0912 7.28038 14.7799 6.09375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="20"
      strokeLinecap="round"
    />
  </SvgIcon>
);
