import {
  BaseItemsState,
  BaseItemsActionTypes,
  BaseItemActions,
} from 'src/store/reduxTypes';
import { BaseEntity, ExtensionEmbed } from 'src/constants/dataTypes';

export type DashboardState = BaseItemsState<ExtensionItem>;

export interface ExtensionItem extends BaseEntity {
  fields: ExtensionItemFields;
}

export type ExtensionItemStatus = 'connected' | 'disconnected';

export interface ExtensionItemFields extends ExtensionEmbed {
  memberIds: Record<string, boolean>;
  companyID?: string;
  status: ExtensionItemStatus;
  embedLink: string;
  extensionConfigId?: string;
  singleClientId?: string;
  viewType?: string; // only used on frontend
  content?: string; // only used on frontend
}

export enum ExtensionItemActions {
  SET_EXTENSION_ITEMS = 'SET_EXTENSION_ITEMS',
  UPDATE_EXTENSION_ITEMS = 'UPDATE_EXTENSION_ITEMS',
  DELETE_EXTENSION_ITEMS = 'DELETE_EXTENSION_ITEMS',
}

export const ExtensionActionTypes = {
  ...BaseItemsActionTypes,
  ...ExtensionItemActions,
};

export interface SetExtensionItemsActionType {
  type: typeof ExtensionActionTypes.SET_EXTENSION_ITEMS;
  items: ExtensionItem[];
}

export interface UpdateExtensionItemsActionType {
  type: typeof ExtensionActionTypes.UPDATE_EXTENSION_ITEMS;
  items: ExtensionItem[];
}

export interface DeleteExtensionItemsActionType {
  type: typeof ExtensionActionTypes.DELETE_EXTENSION_ITEMS;
  itemIds: string[];
  resetSelectedChannel: boolean;
}

export type ExtensionActions =
  | BaseItemActions
  | SetExtensionItemsActionType
  | UpdateExtensionItemsActionType
  | DeleteExtensionItemsActionType;
