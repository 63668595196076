import React from 'react';
import { FlagsContext } from 'src/context';
import { useLatestRelease } from 'src/hooks/useLatestRelease';
import { useAppSelector } from 'src/hooks/useStore';
import { WhatsNewModal } from 'src/legacy/components/WhatsNew/WhatsNewModal';
import { useGetDismissedLatestReleaseQuery } from 'src/services/api/LatestReleaseApi';
import { selectCurrentUser } from 'src/store/storeUtils';
import { DateUtils } from 'src/utils';

export const WhatsNew = () => {
  const { EnableWhatsNewModalView } = React.useContext(FlagsContext);
  const isClient = useAppSelector((state) => state.user.isClient);
  const currentUserCreatedDate = useAppSelector(selectCurrentUser)?.createdDate;

  const userCanSeeWhatsNew =
    !isClient &&
    DateUtils.getDaysDiffOfDate(new Date(currentUserCreatedDate as string)) > 7;

  const latestReleasePost = useLatestRelease(!userCanSeeWhatsNew);

  const {
    data: latestReleaseDismissal,
    isLoading: isLoadingDismissedLatestRelease,
  } = useGetDismissedLatestReleaseQuery(null, { skip: !userCanSeeWhatsNew });

  // this determines whether to show the latest release card or not
  // it actually compares the latest release post id with the latest release dismissal
  // state. If the latest release id is in dismissal object do not show it.
  const showLatestReleaseSection =
    latestReleaseDismissal?.structFields.latest_release_dismissed !==
      latestReleasePost?.id && !isLoadingDismissedLatestRelease;

  return showLatestReleaseSection && EnableWhatsNewModalView ? (
    <WhatsNewModal latestReleasePost={latestReleasePost} />
  ) : null;
};
