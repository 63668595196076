import * as SettingTypes from 'src/store/settings/types';

const initialState: SettingTypes.SettingsState = {
  invoices: [],
  prices: [],
  loadError: '',
  loading: false,
  loaded: false,
  isUpdated: false,
  isUpdating: false,
  crmSettings: {},
  crmTableSettings: {},
  fileSettings: {},
  moduleSettings: [],
  minimumSeats: 0,
  webhookConfigSets: [],
};

export default function settingsReducer(
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: SettingTypes.SettingsActions,
) {
  switch (action.type) {
    case SettingTypes.LOAD_SETTINGS_START:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case SettingTypes.LOAD_SETTINGS_ERROR:
      return {
        ...state,
        loadError: action.error,
        loading: false,
        loaded: false,
      };
    case SettingTypes.LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
        loaded: true,
      };

    case SettingTypes.CLEAR_SETTINGS_CHANGES: {
      return {
        ...state,
      };
    }

    case SettingTypes.UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        isUpdated: false,
        isUpdating: true,
      };
    case SettingTypes.UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        isUpdated: true,
        isUpdating: false,
      };
    }
    case SettingTypes.UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdated: false,
        isUpdating: false,
      };
    case SettingTypes.UPDATE_CRM_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        crmSettings: {
          ...action.payload,
        },
      };
    case SettingTypes.UPDATE_CRM_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        loadError: action.error,
      };
    case SettingTypes.UPDATE_CRM_TABLE_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        crmTableSettings: {
          ...action.payload,
        },
      };
    case SettingTypes.UPDATE_CRM_TABLE_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        loadError: action.error,
      };
    case SettingTypes.UPDATE_BILLING_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        billingSettings: {
          ...action.payload,
        },
      };
    case SettingTypes.UPDATE_BILLING_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        loadError: action.error,
      };
    case SettingTypes.UPDATE_MODULE_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        moduleSettings: action.payload,
      };
    case SettingTypes.UPDATE_MODULE_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        loadError: action.error,
        moduleSettings: action.payload,
      };
    case SettingTypes.UPDATE_FILE_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        fileSettings: action.payload,
      };
    case SettingTypes.UPDATE_FILE_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        loadError: action.error,
      };
    case SettingTypes.LOAD_PORTAL_SETTINGS: {
      // data here is in the form PortalSettings[]
      // as websocket batches the requests so we are getting an array in instead of single object
      // because we will only have one portal settings per portal, so we are ignoring the remaining
      // batch and utilizing last entry only from array
      const { data } = action;
      let updatedSettings = {};
      if (data.length > 0) {
        const settingsData = data[data.length - 1];
        updatedSettings = {
          ...settingsData.fields,
        };
      } else {
        return state;
      }

      return {
        ...state,
        ...updatedSettings,
      };
    }
    case SettingTypes.LOAD_MESSAGE_SETTINGS: {
      // data here is in the form MessageSettings[], should only have one item
      const { data } = action;
      const messageSettings = data.length > 0 ? data.at(0) : {};
      return {
        ...state,
        messageSettings,
      };
    }
    case SettingTypes.REMOVE_PORTAL_SETTINGS: {
      // data here is in the form PortalSettings[]
      // as websocket batches the requests so we are getting an array in instead of single object
      // because we will only have one portal settings per portal, so we are ignoring the remaining
      // batch and utilizing last entry only from array
      const { data } = action;
      const crmSettings =
        data.length > 0 ? data[data.length - 1].fields.crmSettings : {};
      const newCrmSettings =
        crmSettings?.defaultLeadUserID === state.crmSettings?.defaultLeadUserID
          ? {}
          : state.crmSettings;
      return {
        ...state,
        newCrmSettings,
      };
    }
    case SettingTypes.REMOVE_MESSAGE_SETTINGS: {
      // if we receive this message, just clear messageSettings
      const messageSettings = {};
      return {
        ...state,
        messageSettings,
      };
    }
    case SettingTypes.ADD_WEBHOOK_CONFIG_SET_SUCCESS: {
      return {
        ...state,
        webhookConfigSets: [...state.webhookConfigSets, action.payload],
      };
    }
    case SettingTypes.GET_WEBHOOK_CONFIG_SETS_SUCCESS: {
      return {
        ...state,
        webhookConfigSets: action.payload,
      };
    }
    case SettingTypes.DELETE_WEBHOOK_CONFIG_SET_SUCCESS: {
      return {
        ...state,
        webhookConfigSets: state.webhookConfigSets.filter(
          (configSet) => configSet.id !== action.payload,
        ),
      };
    }
    case SettingTypes.UPDATE_WEBHOOK_CONFIG_SET_SUCCESS: {
      const updatedWebhookConfigSets = state.webhookConfigSets.map(
        (configSet) => {
          if (action.payload.id === configSet.id) {
            return action.payload;
          }
          return configSet;
        },
      );
      return {
        ...state,
        webhookConfigSets: updatedWebhookConfigSets,
      };
    }
    case SettingTypes.CLEAR_SETTINGS:
      return initialState;
    case SettingTypes.UPDATE_CONTRACT_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        contractSettings: action.payload,
      };
    case SettingTypes.UPDATE_CONTRACT_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        loadError: action.error,
      };
    default:
      return state;
  }
}
