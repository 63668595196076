import React from 'react';
import * as Yup from 'yup';
import { FormControlLabel, Checkbox, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { v4 } from 'uuid';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import { useSignaturePreview } from 'src/hooks/useSignaturePreview';
import { HoverNonBorderBackground } from 'src/theme/colors';
import { BaseTextField } from 'src/legacy/components/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signaturePreview: {
      width: '100%',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: HoverNonBorderBackground,
      borderRadius: theme.shape.borderRadius,
      margin: `0 0 ${theme.spacing(2)}px 0`,
    },
  }),
);
export const useAddMySignatureForm = (
  signatureType: 'signature' | 'initial',
  initialValue?: string | null,
) => {
  const classes = useStyles();
  const [signatureInput, setSignatureInput] = React.useState<string | null>(
    null,
  );
  const isSignatureTypeForm = signatureType === 'signature';

  const validationScheme = Yup.object().shape({
    value: Yup.string()
      .max(255)
      .trim()
      .required(`${isSignatureTypeForm ? 'Name is' : 'Initials are'} required`),
    ...(isSignatureTypeForm
      ? {
          isAgree: Yup.bool().oneOf([true], 'Required'),
        }
      : {}),
  });
  const { signaturePreviewComponent, signatureDataUrl, signatureWidth } =
    useSignaturePreview(signatureInput || '');

  const getSubmitValues = async () => ({
    imageTextValue: signatureInput, // Save the text value converted to Base64 image for potential editing
    value: signatureDataUrl,
    key: v4(),
    signatureWidth,
  });

  // if we have initial value for signature or intials
  // set intial value to signatureInput to update canvas
  React.useEffect(() => {
    if (initialValue) {
      setSignatureInput(initialValue);
    }
  }, []);

  const FormRenderer: React.FC<FormBodyAPI> = ({
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
  }) => (
    <div>
      <BaseTextField
        sizeVariant="medium"
        error={Boolean(touched.value && errors.value)}
        fullWidth
        helperText={(touched.value && errors.value) || null}
        name={'value'}
        label={isSignatureTypeForm ? 'Your name' : 'Your initials'}
        onBlur={handleBlur}
        onChange={(ev) => {
          // when signature input change it should
          // be updated to update/create canvas element
          // from it.
          setSignatureInput(ev.target.value.trim()); // remove leading and trailing spaces
          handleChange(ev);
        }}
        value={values.value}
        variant="outlined"
        autoFocus
        style={{
          minHeight: 80,
        }}
        inputProps={{
          'data-1pignore': 'true', // Disable 1Password
        }}
        autoComplete="new-password"
      />

      <div className={classes.signaturePreview} id="canvas-wrapper">
        {values.value ? signaturePreviewComponent : null}
      </div>

      {/*  show legally bound checkbox for signature */}
      {isSignatureTypeForm && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isAgree}
                name="isAgree"
                onChange={handleChange}
                color="primary"
              />
            }
            label="I agree to be legally bound by this document."
          />
        </div>
      )}
    </div>
  );

  return { validationScheme, getSubmitValues, FormRenderer };
};
