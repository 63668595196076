import { IconButton, IconButtonProps, makeStyles } from '@material-ui/core';
import React from 'react';
import { BackIcon } from 'src/legacy/components/Icons';
import { Flex } from 'src/legacy/components/UI/Containers';
import { BlackHeadings, NonHoverBorder } from 'src/theme/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: theme.spacing(2),
    color: BlackHeadings,
  },
  buttonIcon: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${NonHoverBorder}`,
    padding: theme.spacing(1),
    '& svg': {
      fontSize: 12,
    },
  },
}));

export const BackButton: React.FC<IconButtonProps> = ({
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Flex className={classes.root}>
      <IconButton className={classes.buttonIcon} {...props}>
        <BackIcon />
      </IconButton>
      {children}
    </Flex>
  );
};
