import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DetailsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.2959 4.44434H18.3329"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.2959 10H18.3329"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.2959 15.5557H18.3329"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6665 4.44434H1.67525"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6665 10H1.67525"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6665 15.5557H1.67525"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
