import { appAPI } from '.';
import { notify } from 'src/clients/ApiService';
import { RecommendedResourceData, ResourceDismissalData } from 'src/constants';
import { ApiMethods, ApiTags } from 'src/services/api/appApi';

const recommendedResourcesApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getAllRecommendedResources: build.query<RecommendedResourceData, void>({
      query: () => ({
        path: `/recommended-resources/`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.recommended_resources],
    }),
    updateResourcesDismissal: build.mutation<ResourceDismissalData, unknown>({
      query: (body) => ({
        path: `/recommended-resources/dismiss`,
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
      async onQueryStarted({ structFields }, { dispatch, queryFulfilled }) {
        // optimistically update the resources state by filtering the dismissed cards
        // this only applies when dismissing card not undoing dismissed card.
        const patchResult = dispatch(
          recommendedResourcesApi.util.updateQueryData(
            'getAllRecommendedResources',
            undefined,
            (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft.structFields = draft.structFields.filter(
                (resource) => !structFields.dismissed[resource.CardUUID],
              );
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          notify({
            status: 'error',
            errorMessage: 'This resource could not be deleted.',
            error,
            dispatch,
          });
        }
      },
      invalidatesTags: [ApiTags.recommended_resources], // refetch get recommended resources
    }),
    invalidateRecommendedResources: build.mutation<any, void>({
      queryFn: () => ({ data: [] }),
      invalidatesTags: [ApiTags.recommended_resources],
    }),
  }),
});

export const {
  useGetAllRecommendedResourcesQuery,
  useUpdateResourcesDismissalMutation,
  useInvalidateRecommendedResourcesMutation,
} = recommendedResourcesApi;
