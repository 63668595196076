export function atPolyfill(val: unknown[] | string, index: number) {
  // To convert negative indices to positive indices
  index = index >= 0 ? index : val.length + index;

  // Return undefined for out-of-bounds indices
  if (index < 0 || index >= val.length) {
    return undefined;
  }

  // Return the element at the specified index
  return val[index];
}
