import { Events } from 'src/constants/webSocketConsts/events';
import { SetPaymentInfo } from 'src/store/payments/actions';
import { ensureUnreachable } from 'src/utils/common_utils';

const paymentHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Modify: {
      if (items.length === 0) {
        return null;
      }
      return SetPaymentInfo(items.at(0));
    }
    case Events.DomainVerified:
    case Events.Refresh:
    case Events.Insert:
    case Events.Remove:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default paymentHandler;
