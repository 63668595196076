import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GridIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.96875 1.875H2.65625C2.22478 1.875 1.875 2.22478 1.875 2.65625V7.96875C1.875 8.40022 2.22478 8.75 2.65625 8.75H7.96875C8.40022 8.75 8.75 8.40022 8.75 7.96875V2.65625C8.75 2.22478 8.40022 1.875 7.96875 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3438 1.875H12.0312C11.5998 1.875 11.25 2.22478 11.25 2.65625V7.96875C11.25 8.40022 11.5998 8.75 12.0312 8.75H17.3438C17.7752 8.75 18.125 8.40022 18.125 7.96875V2.65625C18.125 2.22478 17.7752 1.875 17.3438 1.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.96875 11.25H2.65625C2.22478 11.25 1.875 11.5998 1.875 12.0312V17.3438C1.875 17.7752 2.22478 18.125 2.65625 18.125H7.96875C8.40022 18.125 8.75 17.7752 8.75 17.3438V12.0312C8.75 11.5998 8.40022 11.25 7.96875 11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3438 11.25H12.0312C11.5998 11.25 11.25 11.5998 11.25 12.0312V17.3438C11.25 17.7752 11.5998 18.125 12.0312 18.125H17.3438C17.7752 18.125 18.125 17.7752 18.125 17.3438V12.0312C18.125 11.5998 17.7752 11.25 17.3438 11.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
