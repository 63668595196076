import { API } from 'src/utils/AmplifyApiUtils';
import {
  Article,
  ArticleStructFields,
  UpdateKnowledgeBaseSettingsInput,
} from 'src/store/knowledgeBase/types';

export default class KnowledgeBaseClient {
  /**
   * Fetch a list of articles in the portal
   */
  static getArticles() {
    return API.get('AppAPI', '/entities/articles', {});
  }

  /**
   * Create a new article object given StructFields as the input
   */
  static createArticle(article: ArticleStructFields) {
    return API.post('AppAPI', '/entities/articles', { body: article });
  }

  /**
   * Update an article through API to match entity passed into this method
   */
  static updateArticle(article: Article) {
    return API.put('AppAPI', '/entities/articles', { body: article });
  }

  /**
   * Delete an article based on ID
   */
  static deleteArticle(id: string) {
    return API.del('AppAPI', '/entities/articles', { body: { id } });
  }

  /**
   * Get knowledge base settings for portal
   */
  static getKnowledgeBaseSettings() {
    return API.get('AppAPI', '/entities/knowledgebase-settings', {});
  }

  /**
   * Update knowledge base settings
   */
  static updateKnowledgebaseSettings(input: UpdateKnowledgeBaseSettingsInput) {
    return API.put('AppAPI', '/entities/knowledgebase-settings', {
      body: { ...input },
    });
  }
}
