import { DraggableData } from 'react-draggable';
import { AppThunkAction } from 'src/store/reduxTypes';
import {
  SignaturePageActionTypes,
  SignaturePageComponent,
  SignaturePageActions,
  ChangeEsignFlowStateActionPayload,
  SignaturePageImageData,
  ESignatureFlowStatus,
} from 'src/store/signaturePage/types';
import S3Utils from 'src/utils/S3Utils';
import { getFileNameFromFileKey } from 'src/legacy/components/Files/helpers';
import { ContractFile } from 'src/context/contractBuilderContext';

export function requestLoadPages() {
  return { type: SignaturePageActionTypes.GET_PAGES };
}

export function loadPagesError(error: string) {
  return {
    type: SignaturePageActionTypes.GET_PAGES_ERROR,
    error,
  };
}

export interface AddSignatureFormSubmitType extends SignaturePageComponent {
  fields: {
    key: string;
    value: string;
  };
}

export const ChangeEsignFlowStateAction = (
  payload: ChangeEsignFlowStateActionPayload,
) => ({
  type: SignaturePageActionTypes.CHANGE_ESIGN_FLOW_STATE,
  payload,
});

export const AddComponentAction = (
  input: AddSignatureFormSubmitType,
): SignaturePageActions => {
  const { fields, xPosition = 0, yPosition = 0, ...componentProps } = input;
  return {
    type: SignaturePageActionTypes.ADD_COMPONENT,
    component: {
      ...componentProps,
      xPosition,
      yPosition,
      ...fields,
    },
  };
};

export const AddPlaceholderComponentAction = (
  input: AddSignatureFormSubmitType,
): SignaturePageActions => {
  const { fields, xPosition = 0, yPosition = 0, ...componentProps } = input;
  return {
    type: SignaturePageActionTypes.ADD_PLACEHOLDER_COMPONENT,
    component: { ...componentProps, xPosition, yPosition, ...fields },
  };
};

export const RemovePlaceholderComponentAction = (): SignaturePageActions => ({
  type: SignaturePageActionTypes.REMOVE_PLACEHOLDER_COMPONENT,
});

export const CompleteReceiverComponentAction = (
  input: AddSignatureFormSubmitType,
): SignaturePageActions => {
  const { fields, key: originalKey, ...componentProps } = input;
  return {
    type: SignaturePageActionTypes.COMPLETE_RECEIVER_COMPONENT,
    component: {
      ...componentProps,
      ...fields,
    },
    originalComponentKey: originalKey,
  };
};

export const InsertEverywhereAction = (
  componentInput: AddSignatureFormSubmitType,
): SignaturePageActions => {
  const { fields, ...componentProps } = componentInput;
  return {
    type: SignaturePageActionTypes.INSERT_EVERYWHERE,
    component: {
      ...componentProps,
      ...fields,
    },
  };
};

export const SkipComponentAction = (
  componentInput: AddSignatureFormSubmitType,
): SignaturePageActions => {
  const { key: originalKey } = componentInput;
  return {
    type: SignaturePageActionTypes.SKIP_COMPONENT,
    componentKey: originalKey,
  };
};

export const UpdateComponentAction = (
  component: SignaturePageComponent,
  data: DraggableData,
): SignaturePageActions => ({
  type: SignaturePageActionTypes.UPDATE_COMPONENT,
  component,
  data,
});

export const UpdateComponentDimensionsAction = (
  component: SignaturePageComponent,
): SignaturePageActions => ({
  type: SignaturePageActionTypes.UPDATE_COMPONENT_DIMENSION,
  component,
});

export const UpdateComponentLabelAction = (
  componentKey: string,
  label: string,
  isOptional: boolean,
): SignaturePageActions => ({
  type: SignaturePageActionTypes.UPDATE_COMPONENT_LABEL,
  componentKey,
  label,
  isOptional,
});

export const SetupSignaturePageImageAction = (
  pageImageData: SignaturePageImageData,
): SignaturePageActions => ({
  type: SignaturePageActionTypes.SETUP_PAGE_IMAGE,
  pageImageData,
});

export const SetupSignaturePageHeightImageAction = (
  key: string,
  y: number,
): SignaturePageActions => ({
  type: SignaturePageActionTypes.SETUP_PAGE_IMAGE_HEIGHT,
  key,
  y,
});

export const RemoveComponentAction = (
  componentKey: string,
): SignaturePageActions => ({
  type: SignaturePageActionTypes.REMOVE_COMPONENT,
  componentKey,
});

export const RemoveComponentValue = (componentKey: string) => ({
  type: SignaturePageActionTypes.REMOVE_COMPONENT_VALUE,
  componentKey,
});

export const SetActiveComponent = (componentKey: string) => ({
  type: SignaturePageActionTypes.SET_ACTIVE_COMPONENT,
  componentKey,
});

export const RemoveActiveComponent = () => ({
  type: SignaturePageActionTypes.REMOVE_ACTIVE_COMPONENT,
});

export const StartSavingComponents = () => ({
  type: SignaturePageActionTypes.START_SAVING_COMPONENTS,
});

export const StopSavingComponents = () => ({
  type: SignaturePageActionTypes.STOP_SAVING_COMPONENTS,
});

export const ResetESignToInitialValues = () => ({
  type: SignaturePageActionTypes.RESET_ESIGN_TO_INITIAL_VALUES,
});

export const ToggleResetComponentsReference = () => ({
  type: SignaturePageActionTypes.TOGGLE_RESET_COMPONENTS_REFERENCE,
});

export const UpdateComponentsPlacement = (
  pageComponents: SignaturePageComponent[],
) => ({
  type: SignaturePageActionTypes.UPDATE_PAGE_COMPONENTS_PLACEMENT,
  pageComponents,
});

export const StartFillingSignatures = () => ({
  type: SignaturePageActionTypes.START_FILLING_SIGNATURES,
});

export const UpdateClientSignatureFlowStatus = (
  status: ESignatureFlowStatus,
) => ({
  type: SignaturePageActionTypes.UPDATE_CLIENT_SIGNATURE_FLOW_STATUS,
  status,
});

export const SetSelectedComponent = (componentKey: string) => ({
  type: SignaturePageActionTypes.SET_SELECTED_COMPONENT,
  componentKey,
});

export const RemoveSelectedComponent = () => ({
  type: SignaturePageActionTypes.REMOVE_SELECTED_COMPONENT,
});

export const DisableComponentClickaway = () => ({
  type: SignaturePageActionTypes.DISABLE_COMPONENT_CLICKAWAY,
});

export const EnableComponentClickAway = () => ({
  type: SignaturePageActionTypes.ENABLE_COMPONENT_CLICKAWAY,
});

export const loadContractPages =
  (
    uploadedFile: ContractFile,
    pageComponents: SignaturePageComponent[],
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const { fileKey, pageFileKeys, identityId } = uploadedFile;
    const { user } = getState();
    const { isClient } = user;

    try {
      const pageImages = await Promise.all(
        pageFileKeys.map(async (key, index) => {
          const s3ImageURL = await S3Utils.getFile(key, {
            identityId,
            level: 'protected',
          });
          return {
            key,
            url: s3ImageURL,
            pageNumber: index + 1,
            loaded: false,
          };
        }),
      );
      dispatch({
        type: SignaturePageActionTypes.GET_PAGES_SUCCESS,
        updatedState: {
          pageImages,
          fileKey,
          fileName: getFileNameFromFileKey(fileKey),
          pageImageKeysField: pageFileKeys,
          pageComponents,
        },
        isClient,
        currentUserId: user.id,
      });
    } catch (err) {
      console.error('Error loading the contract pages', err);
      dispatch(loadPagesError('Failed to load the contract file pages'));
    }
  };

export const setPageComponents = (
  pageComponents: SignaturePageComponent[],
): SignaturePageActions => ({
  type: SignaturePageActionTypes.SET_PAGE_COMPONENTS,
  pageComponents,
});
