import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { red } from 'src/theme/colors';

const useStyles = makeStyles(() =>
  createStyles({
    errorContainer: {
      color: red,
    },
  }),
);

export const ErrorText: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <BaseTypography fontType="13Medium">{children}</BaseTypography>
    </div>
  );
};
