import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { StepperPageContext } from 'src/context';
import { BlackSmall } from 'src/theme/colors';
import Button from 'src/legacy/components/Button';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { DescriptionTooltip } from 'src/legacy/components/Tooltip';
import { StepperSubmitAction } from 'src/legacy/components/Layout/StepperLayout/stepperTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: theme.spacing(2.5),
      justifyContent: 'end',
    },
  }),
);

export const StepNavigationActions: React.FC = () => {
  const classes = useStyles();
  const {
    onNext,
    onBack,
    activeStep,
    nextButtonTooltipOptions,
    loading,
    submitActions,
  } = useContext(StepperPageContext);

  /**
   * Since we are dynamically rendering the submit actions,
   * we need to keep track of the loading state for each action.
   * This is done by using a record of action label and boolean.
   */
  const [submitActionsLoading, setSubmitActionsLoading] = React.useState<
    Record<string, boolean>
  >(
    submitActions.reduce(
      (acc, action) => ({ ...acc, [action.label]: false }),
      {},
    ),
  );
  const handleSubmitAction = async (action: StepperSubmitAction) => {
    if (action.handler) {
      setSubmitActionsLoading({ [action.label]: true });
      await action.handler();
      setSubmitActionsLoading({ [action.label]: false });
    }
  };

  return (
    <div className={classes.root}>
      <Button
        htmlId="go-back-button"
        variant="contained"
        size="small"
        color="secondary"
        onClick={onBack}
        data-testid="go-back-button"
      >
        Go back
      </Button>

      {submitActions.length ? ( // when submit actions are defined, do not render the next button.
        submitActions.map((action) => (
          <DescriptionTooltip
            placement="bottom"
            title={
              <div style={{ color: BlackSmall }}>
                <BaseTypography fontType="12Medium">
                  {action.tooltipText}
                </BaseTypography>
              </div>
            }
            disableHoverListener={!action.tooltipText}
          >
            <div>
              <Button
                key={action.label}
                htmlId={action.label}
                variant="contained"
                color={action.primaryAction ? 'primary' : 'secondary'}
                isLoading={submitActionsLoading[action.label]}
                size="small"
                onClick={() => handleSubmitAction(action)}
                progressColor={action.primaryAction ? 'secondary' : 'primary'}
                disabled={!activeStep?.complete}
              >
                {action.label}
              </Button>
            </div>
          </DescriptionTooltip>
        ))
      ) : (
        <DescriptionTooltip
          placement="bottom"
          title={
            <div style={{ color: BlackSmall }}>
              {nextButtonTooltipOptions?.title && (
                <BaseTypography fontType="13Medium">
                  {nextButtonTooltipOptions?.title}
                </BaseTypography>
              )}
              <BaseTypography fontType="12Medium">
                {nextButtonTooltipOptions?.description}
              </BaseTypography>
            </div>
          }
          disableHoverListener={!nextButtonTooltipOptions}
        >
          <div>
            <Button
              htmlId="go-next-button"
              variant="contained"
              color="secondary"
              size="small"
              onClick={onNext}
              disabled={!activeStep?.complete}
              data-testid="go-next-button"
              progressColor="primary"
              isLoading={loading}
            >
              Next
            </Button>
          </div>
        </DescriptionTooltip>
      )}
    </div>
  );
};
