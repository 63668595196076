import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ServerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 8.125C14.1421 8.125 17.5 6.72589 17.5 5C17.5 3.27411 14.1421 1.875 10 1.875C5.85786 1.875 2.5 3.27411 2.5 5C2.5 6.72589 5.85786 8.125 10 8.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.5 8.35938C17.5 10.0852 14.1406 11.4844 10 11.4844C5.85938 11.4844 2.5 10.0852 2.5 8.35938"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.5 11.7188C17.5 13.4445 14.1406 14.8438 10 14.8438C5.85938 14.8438 2.5 13.4445 2.5 11.7188"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.5 4.97021V15.0296C2.5 16.739 5.85938 18.1249 10 18.1249C14.1406 18.1249 17.5 16.739 17.5 15.0296V4.97021"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
