import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CompassIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 10C17.5 5.85938 14.1406 2.5 10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M13.6978 5.89591L9.11964 7.72716C8.80522 7.8529 8.51962 8.04121 8.28017 8.28066C8.04072 8.52011 7.85242 8.80571 7.72668 9.12013L5.89543 13.6983C5.87269 13.755 5.86713 13.8173 5.87942 13.8772C5.89171 13.9371 5.92132 13.9921 5.96458 14.0354C6.00783 14.0786 6.06283 14.1082 6.12275 14.1205C6.18267 14.1328 6.24489 14.1272 6.30168 14.1045L10.8798 12.2733C11.1942 12.1475 11.4798 11.9592 11.7193 11.7198C11.9587 11.4803 12.147 11.1947 12.2728 10.8803L14.104 6.30216C14.1268 6.24537 14.1323 6.18316 14.12 6.12324C14.1077 6.06332 14.0781 6.00832 14.0349 5.96506C13.9916 5.92181 13.9366 5.8922 13.8767 5.87991C13.8168 5.86762 13.7546 5.87318 13.6978 5.89591V5.89591ZM9.99972 10.9377C9.8143 10.9377 9.63305 10.8827 9.47888 10.7797C9.3247 10.6767 9.20454 10.5303 9.13359 10.359C9.06263 10.1877 9.04406 9.99917 9.08024 9.81731C9.11641 9.63546 9.2057 9.46841 9.33681 9.3373C9.46792 9.20619 9.63497 9.1169 9.81683 9.08072C9.99868 9.04455 10.1872 9.06312 10.3585 9.13407C10.5298 9.20503 10.6762 9.32519 10.7792 9.47936C10.8822 9.63354 10.9372 9.81479 10.9372 10.0002C10.9372 10.2489 10.8385 10.4873 10.6626 10.6631C10.4868 10.8389 10.2484 10.9377 9.99972 10.9377Z"
      fill="currentColor"
    />
  </SvgIcon>
);
