import React, { useCallback, useContext } from 'react';
import { InboxPageContext } from 'src/context/inboxPageContext';
import {
  DeleteAllNotificationsType,
  useDeleteAllNotificationsMutation,
  useUpdateAllNotificationsMutation,
  useUpdateNotificationMutation,
} from 'src/services/api/inboxApi';
import { Action, BaseMenu } from '../Dropdowns';
import {
  TrashIcon,
  MoreActionsIcon,
  ReadMessage as ReadMessageIcon,
  UnreadMessage as UnreadMessageIcon,
} from '../Icons';
import classNames from 'classnames';

/**
 * This component is used to show the inbox sidebar actions:
 * Mark all as read, Mark all as unread, Delete all notifications
 */
export const NotificationsActionDropdown: React.FC<{
  totalUnreadNotificationsCount: number;
  totalReadNotificationsCount: number;
  disabled: boolean;
}> = ({
  totalUnreadNotificationsCount,
  totalReadNotificationsCount,
  disabled,
}) => {
  const { selectedNotification, setSelectedNotification } =
    useContext(InboxPageContext);

  const [viewButtonAnchor, setViewButtonAnchor] =
    React.useState<HTMLElement | null>(null);
  const [updateNotification] = useUpdateNotificationMutation();
  const [updateAllNotifications] = useUpdateAllNotificationsMutation();
  const [deleteAllNotifications] = useDeleteAllNotificationsMutation();

  const handleDeleteAllNotifications = (type: DeleteAllNotificationsType) => {
    deleteAllNotifications({ type });
    setSelectedNotification(null);
  };
  // handles updating the selected notification read status
  const handleUpdateSelectedNotificationStatus = (isRead: boolean) => {
    if (selectedNotification) {
      updateNotification({
        id: selectedNotification?.id || '',
        isRead,
      });
      setSelectedNotification({ ...selectedNotification, isRead });
    }
  };
  const handleUpdateAllNotifications = (isRead: boolean) => {
    updateAllNotifications({ isRead });
    if (selectedNotification) {
      setSelectedNotification({ ...selectedNotification, isRead });
    }
  };

  /**
   * This function setup the view button actions based on the scenarios
   * described in the figma design.
   * @link https://www.figma.com/file/QZOvjHYQLyIWWMuPXYqTc2/Main?type=design&node-id=40180-38418&mode=design&t=MDjRRazd6ONBRAJQ-0
   */
  const getViewDropdownActions = useCallback(() => {
    let actions: Array<Action> = [];
    // if no notification is selected and there are no notifications
    // actions menu should only show 'Delete all notifications'
    if (!selectedNotification) {
      if (totalUnreadNotificationsCount === 0) {
        actions = [
          {
            name: 'Delete all notifications',
            onClick: () =>
              handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
            isDelete: true,
            icon: <TrashIcon style={{ fontSize: 12 }} />,
          },
        ];

        return actions;
      }

      actions = [
        {
          name: 'Mark all notifications as read',
          onClick: () => handleUpdateAllNotifications(true),
          icon: <ReadMessageIcon style={{ fontSize: 13 }} />,
          hasDivider: true,
        },
        // if there are read notifications then surface 'Delete all read notifications'
        ...(totalReadNotificationsCount > 0
          ? [
              {
                name: 'Delete all read notifications',
                onClick: () =>
                  handleDeleteAllNotifications(DeleteAllNotificationsType.READ),
                isDelete: true,
                icon: <TrashIcon style={{ fontSize: 13 }} />,
              },
            ]
          : []),
        {
          name: 'Delete all notifications',
          onClick: () =>
            handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 13 }} />,
        },
      ];

      return actions;
    }

    // when selected notification is read and there is no unread notifications
    if (selectedNotification.isRead && totalUnreadNotificationsCount === 0) {
      actions = [
        {
          name: 'Mark as unread',
          onClick: () => handleUpdateSelectedNotificationStatus(false),
          icon: <UnreadMessageIcon style={{ fontSize: 13 }} />,
        },
        {
          name: 'Mark all notifications as unread',
          onClick: () => handleUpdateAllNotifications(false),
          icon: <UnreadMessageIcon style={{ fontSize: 13 }} />,
          hasDivider: true,
        },
        {
          name: 'Delete all notifications',
          onClick: () =>
            handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 13 }} />,
        },
      ];
      return actions;
    }

    // if selected notification is unread and all notifications are unread
    if (
      !selectedNotification.isRead &&
      totalUnreadNotificationsCount > 0 &&
      totalReadNotificationsCount === 0
    ) {
      actions = [
        {
          name: 'Mark as read',
          onClick: () => handleUpdateSelectedNotificationStatus(true),
          icon: <ReadMessageIcon style={{ fontSize: 13 }} />,
        },
        {
          name: 'Mark all notifications as read',
          onClick: () => handleUpdateAllNotifications(true),
          icon: <ReadMessageIcon style={{ fontSize: 13 }} />,
          hasDivider: true,
        },
        {
          name: 'Delete all notifications',
          onClick: () =>
            handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 13 }} />,
        },
      ];
    }

    // when there are both unread and read notifications
    if (totalUnreadNotificationsCount > 0 && totalReadNotificationsCount > 0) {
      actions = selectedNotification.isRead
        ? [
            {
              name: 'Mark as unread',
              onClick: () => handleUpdateSelectedNotificationStatus(false),
              icon: <UnreadMessageIcon style={{ fontSize: 13 }} />,
            },
            {
              name: 'Mark all notifications as unread',
              onClick: () => handleUpdateAllNotifications(false),
              icon: <UnreadMessageIcon style={{ fontSize: 13 }} />,
            },
            {
              name: 'Mark all notifications as read',
              onClick: () => handleUpdateAllNotifications(true),
              icon: <ReadMessageIcon style={{ fontSize: 13 }} />,
              hasDivider: true,
            },
            {
              name: 'Delete all read notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.READ),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 13 }} />,
            },
            {
              name: 'Delete all notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 13 }} />,
            },
          ]
        : [
            {
              name: 'Mark as read',
              onClick: () => handleUpdateSelectedNotificationStatus(true),
              icon: <ReadMessageIcon style={{ fontSize: 13 }} />,
            },
            {
              name: 'Mark all notifications as read',
              onClick: () => handleUpdateAllNotifications(true),
              icon: <ReadMessageIcon style={{ fontSize: 13 }} />,
              hasDivider: true,
            },
            {
              name: 'Delete all read notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.READ),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 13 }} />,
            },
            {
              name: 'Delete all notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 13 }} />,
            },
          ];
    }
    return actions;
  }, [
    totalUnreadNotificationsCount,
    totalReadNotificationsCount,
    selectedNotification,
  ]);

  return (
    <>
      {/* TODO: Replace with IconButton from design-system when POR-13084 is done */}
      <button
        className={classNames(
          'h-7 w-7 flex items-center justify-center border-none cursor-pointer hover:bg-gray-100 rounded-4',
          {
            'bg-gray-100': Boolean(viewButtonAnchor),
            'bg-transparent': !Boolean(viewButtonAnchor),
          },
        )}
        onClick={(e) => {
          setViewButtonAnchor(e.currentTarget);
        }}
        id="view-notifications-actions-button"
        disabled={disabled}
      >
        <MoreActionsIcon className="text-lg" />
      </button>
      <BaseMenu
        key="view-notifications-actions-menu"
        menuProps={{
          open: Boolean(viewButtonAnchor),
          anchorEl: viewButtonAnchor,
          onClose: () => {
            setViewButtonAnchor(null);
          },
          getContentAnchorEl: null,
          keepMounted: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        actions={getViewDropdownActions()}
      />
    </>
  );
};
