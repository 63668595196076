import React from 'react';
import {
  makeStyles,
  createStyles,
  Button,
  ButtonProps,
  ButtonGroup,
  Theme,
  CircularProgress,
  useMediaQuery,
  PopoverOrigin,
  ButtonGroupProps,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import classNames from 'classnames';
import { PlusIcon, ChevronDownIcon } from 'src/legacy/components/Icons';
import { BaseMenu } from 'src/legacy/components/Dropdowns/BaseMenu';
import { HoverBackground } from 'src/theme/colors';
import ColorUtils from 'src/utils/ColorUtils';
import { Action } from 'src/legacy/components/Dropdowns';
import { MenuVariant } from 'src/constants';

interface StyleProps {
  isSecondaryButton: boolean;
}

const useCreateButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    createButton: {
      padding: `0 ${theme.spacing(1)}px`,
    },
    createButtonActive: {
      backgroundColor: ({ isSecondaryButton }: StyleProps) =>
        // background color of button based on button variant (primary or secondary)
        // when dropdown menu is open
        isSecondaryButton
          ? HoverBackground
          : ColorUtils.GetColorDarknessShades(theme.palette.primary.main)
              .midDark,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -15,
      marginLeft: -15,
    },
  }),
);

export interface DropDownItemMoreAction {
  name: string;
  icon: React.ReactElement;
  // when clicked on sub action
  // we will get parent menu item information in param
  onClick: (action: Action) => void;
}

export interface DropDownOption {
  value: string;
  label: string;
  // these will be the sub actions we want on the the dropdown menu item
  subActions?: DropDownItemMoreAction[];
}

export interface MainOption {
  label: string;
  icon: JSX.Element;
  disabled?: boolean;
  tooltip?: string;
  hasDivider?: boolean;
}

export interface CreateButtonProps {
  htmlId: string;
  caretOptions: DropDownOption[];
  dropDownTitle: string;
  actionOptionTitle: string;
  mainOptions: Array<MainOption>;
  headerOptions?: Array<MainOption>;
  onClickOption: (option: any) => void;
  onClickOptionAction: () => void;
  isUploading?: boolean;
  iconOnly?: boolean;
  forceShowLabel?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  buttonGroupProps?: ButtonGroupProps;
  dropdownVariant?: MenuVariant;
  hasStartIcon?: boolean;
  disabledTooltip?: JSX.Element | string;
}

export const defaultCreateButtonProps: CreateButtonProps = {
  htmlId: 'default-create-button-id',
  caretOptions: [],
  dropDownTitle: 'Create',
  actionOptionTitle: 'Create New',
  mainOptions: [],
  onClickOption: () => {},
  onClickOptionAction: () => {},
  isUploading: false,
  iconOnly: false,
  forceShowLabel: false,
  dropdownVariant: 'regular',
  hasStartIcon: true,
};

export type CreateButtonCombinedProps = ButtonProps &
  Partial<CreateButtonProps>;

export const CreateButton: React.FC<CreateButtonCombinedProps> = React.memo(
  ({
    children,
    htmlId,
    caretOptions,
    mainOptions,
    headerOptions,
    dropDownTitle,
    actionOptionTitle,
    isUploading = false,
    onClickOption,
    onClickOptionAction,
    iconOnly = false,
    forceShowLabel = false,
    anchorOrigin,
    transformOrigin,
    onClick,
    buttonGroupProps,
    dropdownVariant = 'wide',
    hasStartIcon = true,
    disabledTooltip = '',
    ...rest
  }) => {
    const isSecondaryButton = buttonGroupProps?.color === 'secondary';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useCreateButtonStyles({ isSecondaryButton });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const formatOptions = (options: MainOption[]): Action[] =>
      options.map(({ label, icon, disabled, tooltip, hasDivider }) => ({
        key: label,
        onClick: () => onClickOption?.(label),
        name: label,
        icon,
        isDisabled: disabled,
        tooltipLabel: tooltip,
        hasDivider,
      }));

    const getFormattedHeaderActions = React.useCallback((): Action[] => {
      if (headerOptions) {
        return formatOptions(headerOptions);
      }
      return [];
    }, [headerOptions]);

    const getFormattedActions = React.useCallback((): Action[] => {
      if (mainOptions) {
        return formatOptions(mainOptions);
      }

      if (caretOptions) {
        return caretOptions?.map(
          (option): Action => ({
            key: `${option.value || option.label}`,
            onClick: () => {
              onClickOption?.(option.value);
            },
            name: option.label,
            subActions: option.subActions?.map(
              (subAction): Action => ({
                ...subAction,
                name: subAction.name,
                icon: subAction.icon,
                onClick: () => {
                  subAction.onClick({
                    name: option.label,
                    onClick: () => {},
                  });
                },
              }),
            ),
          }),
        );
      }
      return [];
    }, [caretOptions, mainOptions, onClickOption]);

    return (
      <div>
        <Tooltip title={disabledTooltip} interactive>
          <ButtonGroup
            disableRipple
            disableFocusRipple
            variant="contained"
            color="primary"
            aria-label="split button"
            {...buttonGroupProps}
          >
            <Button
              color={buttonGroupProps?.color || 'primary'}
              className={classNames(classes.createButton, {
                [classes.createButtonActive]: open, // when popover is open add hover styles
              })}
              id={htmlId}
              {...(!iconOnly
                ? {
                    startIcon: !isMobile && hasStartIcon ? <PlusIcon /> : null,
                  }
                : {})}
              onClick={onClick || handleClick}
              disabled={isUploading}
              {...rest}
            >
              {(isMobile || iconOnly) && !forceShowLabel ? (
                <PlusIcon />
              ) : (
                children
              )}
              {isUploading && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={30}
                />
              )}
            </Button>
            {caretOptions && (
              <Button
                color="primary"
                size="small"
                aria-label="caret options"
                aria-controls="caret-options-menu"
                aria-haspopup="true"
                className={classNames(classes.createButton, {
                  [classes.createButtonActive]: open,
                })}
                onClick={handleClick}
              >
                <ChevronDownIcon />
              </Button>
            )}
          </ButtonGroup>
        </Tooltip>
        <BaseMenu
          variant={dropdownVariant}
          menuTitle={dropDownTitle}
          addOptionLabel={actionOptionTitle}
          onAddOption={onClickOptionAction}
          menuProps={{
            id: 'options-menu',
            anchorEl,
            getContentAnchorEl: null,
            keepMounted: true,
            open,
            onClose: handleClose,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
              ...anchorOrigin,
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
              ...transformOrigin,
            },
          }}
          headerActions={getFormattedHeaderActions()}
          actions={getFormattedActions()}
          preventClickEventPropagation
        />
      </div>
    );
  },
);
