import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MusicalNotesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.5 8.51562V8.28124C7.5 7.70155 7.89063 7.22655 8.44688 7.08632L15.2668 5.26288C15.3824 5.23195 15.5035 5.22798 15.6209 5.25128C15.7382 5.27458 15.8487 5.32452 15.9437 5.39725C16.0387 5.46998 16.1157 5.56357 16.1688 5.67078C16.2219 5.77799 16.2497 5.89597 16.25 6.01562V6.87499"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 11.5601V14.6851C16.25 15.2284 15.9012 15.6847 15.3906 15.8569L14.5312 16.1694C13.5195 16.5101 12.5 15.7624 12.5 14.6851C12.4977 14.4083 12.5835 14.138 12.745 13.9133C12.9065 13.6886 13.1354 13.5211 13.3984 13.4351L15.3906 12.7261C15.9012 12.5542 16.25 12.1034 16.25 11.5601ZM16.25 11.5601V2.26554C16.2497 2.20573 16.2357 2.14679 16.2091 2.09325C16.1824 2.03971 16.1438 1.99301 16.0963 1.95674C16.0487 1.92047 15.9935 1.8956 15.9348 1.88406C15.8761 1.87251 15.8156 1.87459 15.7578 1.89014L7.96875 3.98429C7.8329 4.02266 7.71343 4.10462 7.62873 4.21755C7.54403 4.33048 7.4988 4.46813 7.5 4.60929V13.4374C7.5 13.9808 7.15117 14.4374 6.64062 14.6093L4.60938 15.3124C4.06719 15.4952 3.75 15.9851 3.75 16.5624C3.75 17.6398 4.78594 18.3808 5.78125 18.0468L6.64062 17.7343C7.15117 17.5624 7.5 17.1062 7.5 16.5624V13.4374"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
