import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HappyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.1875 10C7.70527 10 8.125 9.58027 8.125 9.0625C8.125 8.54473 7.70527 8.125 7.1875 8.125C6.66973 8.125 6.25 8.54473 6.25 9.0625C6.25 9.58027 6.66973 10 7.1875 10Z"
      fill="currentColor"
    />
    <path
      d="M10.0024 15C8.22813 15 6.73594 13.8465 6.26368 12.2723C6.25047 12.2255 6.24839 12.1762 6.2576 12.1284C6.26681 12.0806 6.28705 12.0356 6.31672 11.997C6.34639 11.9584 6.38466 11.9273 6.42849 11.9061C6.47231 11.885 6.52048 11.8743 6.56915 11.875H13.432C13.4807 11.8743 13.5289 11.885 13.5727 11.9061C13.6165 11.9273 13.6548 11.9584 13.6845 11.997C13.7141 12.0356 13.7344 12.0806 13.7436 12.1284C13.7528 12.1762 13.7507 12.2255 13.7375 12.2723C13.2691 13.8465 11.7766 15 10.0024 15Z"
      fill="currentColor"
    />
    <path
      d="M12.8125 10C13.3303 10 13.75 9.58027 13.75 9.0625C13.75 8.54473 13.3303 8.125 12.8125 8.125C12.2947 8.125 11.875 8.54473 11.875 9.0625C11.875 9.58027 12.2947 10 12.8125 10Z"
      fill="currentColor"
    />
    <path
      d="M10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);
