import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const EyedropIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.2543 7.97705L2.73438 15.4954C2.24844 15.9821 1.875 18.1247 1.875 18.1247C1.875 18.1247 3.99922 17.7696 4.5043 17.2653L12.0234 9.74619"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6766 8.8617L11.1386 5.32368C10.65 4.83506 9.85787 4.835 9.36933 5.32354C8.8808 5.81208 8.88086 6.60421 9.36947 7.09283L12.9075 10.6308C13.3961 11.1195 14.1882 11.1195 14.6768 10.631C15.1653 10.1424 15.1653 9.35031 14.6766 8.8617Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3242 5.50776C11.3242 5.50776 12.1277 5.84253 12.7781 5.19214L14.9137 2.43316C15.6363 1.67495 16.8379 1.70308 17.5676 2.43316C18.2973 3.16284 18.323 4.3398 17.5676 5.08667L14.8066 7.22222C14.1953 7.83355 14.491 8.67612 14.491 8.67612"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.50468 17.2655C4.50468 17.2655 3.4703 17.9429 2.76405 17.237C2.0578 16.5312 2.73475 15.4956 2.73475 15.4956"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
