import React from 'react';
import { TrashIcon } from 'src/legacy/components/Icons';
import { BaseActionsMenuButton, Action } from 'src/legacy/components/Dropdowns';
import { BaseActionRenderProps } from 'src/legacy/components/AgGrid/Renderer/types';

export interface ProductPriceTableRowModal {
  id: string;
  amount: number;
  currency: string;
  type: string;
  intervalCount: number;
  interval: string;
  status: string;
  createdAt: string;
  isUsed: boolean;
}

type EntityName = 'price' | 'product';

export interface ProductPriceActionsRendererProps
  extends BaseActionRenderProps {
  data: ProductPriceTableRowModal;
  handleClickRemove: (data: ProductPriceTableRowModal) => void;
  entityName: EntityName;
  isDeleteDisabled?: boolean;
}

export const PriceActionRenderer = ({
  data,
  handleClickRemove,
  onActionMenuClick,
  entityName,
  isDeleteDisabled,
}: ProductPriceActionsRendererProps) => {
  const actions: Action[] = [
    {
      name: `Delete`,
      onClick: () => handleClickRemove(data),
      icon: <TrashIcon />,
      isDelete: true,
      isDisabled: isDeleteDisabled || data.isUsed,
      ...(data.isUsed && {
        tooltipLabel: `You can’t delete this ${entityName} because it has been used before (e.g. in invoice, subscription or payment link)`,
      }),
      ...(isDeleteDisabled && {
        tooltipLabel: `Each product must have at least one ${entityName}`,
      }),
    },
  ];

  return (
    <BaseActionsMenuButton
      onActionMenuClick={onActionMenuClick}
      actions={actions}
    />
  );
};
