import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CalenderIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <rect width="20" height="20" rx="3" fill="currentColor" />
    <path
      d="M13.9645 4.75634H13.1568V4.27172C13.1568 4.00422 12.9397 3.78711 12.6722 3.78711C12.4047 3.78711 12.1876 4.00422 12.1876 4.27172V4.75634H7.9876V4.27172C7.9876 4.00422 7.77049 3.78711 7.50298 3.78711C7.23547 3.78711 7.01837 4.00422 7.01837 4.27172V4.75634H6.21067C4.64827 4.75634 3.7876 5.61702 3.7876 7.17942V13.964C3.7876 15.5264 4.64827 16.3871 6.21067 16.3871H13.9645C15.5269 16.3871 16.3876 15.5264 16.3876 13.964V7.17942C16.3876 5.61702 15.5269 4.75634 13.9645 4.75634ZM6.21067 5.72557H7.01837V6.21019C7.01837 6.47769 7.23547 6.6948 7.50298 6.6948C7.77049 6.6948 7.9876 6.47769 7.9876 6.21019V5.72557H12.1876V6.21019C12.1876 6.47769 12.4047 6.6948 12.6722 6.6948C12.9397 6.6948 13.1568 6.47769 13.1568 6.21019V5.72557H13.9645C14.9835 5.72557 15.4184 6.16043 15.4184 7.17942V7.66403H4.75683V7.17942C4.75683 6.16043 5.19169 5.72557 6.21067 5.72557ZM13.9645 15.4179H6.21067C5.19169 15.4179 4.75683 14.983 4.75683 13.964V8.63326H15.4184V13.964C15.4184 14.983 14.9835 15.4179 13.9645 15.4179Z"
      fill="white"
    />
  </SvgIcon>
);
