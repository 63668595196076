import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SparklesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.1531 10.2699L8.45308 5.85035C8.42757 5.78403 8.38256 5.72699 8.32398 5.68676C8.26541 5.64653 8.19602 5.625 8.12496 5.625C8.0539 5.625 7.98451 5.64653 7.92593 5.68676C7.86735 5.72699 7.82234 5.78403 7.79683 5.85035L6.09683 10.2699C6.07917 10.3158 6.05208 10.3575 6.01729 10.3923C5.9825 10.4271 5.9408 10.4542 5.89488 10.4718L1.47535 12.1718C1.40903 12.1973 1.35199 12.2424 1.31176 12.3009C1.27153 12.3595 1.25 12.4289 1.25 12.5C1.25 12.571 1.27153 12.6404 1.31176 12.699C1.35199 12.7576 1.40903 12.8026 1.47535 12.8281L5.89488 14.5281C5.9408 14.5457 5.9825 14.5728 6.01729 14.6076C6.05208 14.6424 6.07917 14.6841 6.09683 14.73L7.79683 19.1496C7.82234 19.2159 7.86735 19.2729 7.92593 19.3132C7.98451 19.3534 8.0539 19.3749 8.12496 19.3749C8.19602 19.3749 8.26541 19.3534 8.32398 19.3132C8.38256 19.2729 8.42757 19.2159 8.45308 19.1496L10.1531 14.73C10.1707 14.6841 10.1978 14.6424 10.2326 14.6076C10.2674 14.5728 10.3091 14.5457 10.355 14.5281L14.7746 12.8281C14.8409 12.8026 14.8979 12.7576 14.9382 12.699C14.9784 12.6404 14.9999 12.571 14.9999 12.5C14.9999 12.4289 14.9784 12.3595 14.9382 12.3009C14.8979 12.2424 14.8409 12.1973 14.7746 12.1718L10.355 10.4718C10.3091 10.4542 10.2674 10.4271 10.2326 10.3923C10.1978 10.3575 10.1707 10.3158 10.1531 10.2699Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.21875 2.65625L3.4375 0.625L2.65625 2.65625L0.625 3.4375L2.65625 4.21875L3.4375 6.25L4.21875 4.21875L6.25 3.4375L4.21875 2.65625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6668 4.5832L15.625 1.875L14.5832 4.5832L11.875 5.625L14.5832 6.6668L15.625 9.375L16.6668 6.6668L19.375 5.625L16.6668 4.5832Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
