import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TimerIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M4.41101 5.00009C3.1982 6.35463 2.51918 8.1039 2.50046 9.92196C2.45437 14.0763 5.84617 17.4923 10.0005 17.5001C14.1489 17.5079 17.5005 14.1474 17.5005 10.0001C17.5005 5.91649 14.2368 2.59345 10.1762 2.50009C10.1534 2.49935 10.1306 2.50323 10.1093 2.51148C10.088 2.51974 10.0685 2.5322 10.0521 2.54813C10.0357 2.56406 10.0227 2.58313 10.0138 2.60421C10.0049 2.62528 10.0004 2.64792 10.0005 2.67079V5.93759"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.11674 10.8841L6.0308 6.47006C5.98811 6.40891 5.96831 6.33467 5.97486 6.26038C5.98142 6.18608 6.01391 6.11646 6.06665 6.06372C6.11939 6.01098 6.18901 5.97849 6.2633 5.97193C6.3376 5.96538 6.41183 5.98518 6.47299 6.02788L10.8871 9.11381C11.1578 9.30881 11.3409 9.60255 11.3967 9.9315C11.4526 10.2605 11.3767 10.5982 11.1854 10.8716C10.9942 11.145 10.703 11.3321 10.3749 11.3925C10.0467 11.4529 9.70802 11.3817 9.43198 11.1943C9.30992 11.1097 9.20329 11.0048 9.11674 10.8841Z"
      fill="currentColor"
    />
  </SvgIcon>
);
