import { appAPI, ApiTags, ApiMethods } from '.';

export type GoogleMapPrediction = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  fullAddress: string;
};

export const googleMapApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getGoogleMapsAutocomplete: build.query<
      { predictions: GoogleMapPrediction[] },
      { search: string; country: string }
    >({
      query: ({ search, country }) => ({
        path: `/data/googlemaps?search=${search}&country=${country}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.googlemaps],
    }),
  }),
});

export const { useGetGoogleMapsAutocompleteQuery } = googleMapApi;
