export const HoverNonBorderBackground = '#F1F3F8';
export const HoverNonBorderTableEditableItemBackground = '#EEF0F3';
export const NonHoverBorder = '#DFE1E4';
export const GraySmall = '#6B6F76';
export const BlackHeadings = '#212B36';
export const BlackSmall = '#3C4149';
export const DividersAndCardBorders = '#EFF1F4';
export const NonHoverBlackBorder = '#3D3F45';
export const NonHoverBackground = '#F1F3F8';
export const HoverBorder = '#C9CBCD';
export const HoverBackground = '#F8F9FB';
export const ImageUploaderBorder = '#DFE3E8';
export const white = '#fff';
export const red = '#CC0000';
export const redDark = '#AE0000';
export const green = '#1E9A50';
export const orange = '#FFA500';
export const highlight = 'rgba(255, 165, 0, 0.50)';
export const lightBlue = '#005F8F';
export const yellow = '#FFA500';
export const yellowLight = 'rgba(255, 165, 0, 0.2)';
export const greySoft = '#C4C4C4';
export const black = '#000';
export const LightGray = '#90959D';
export const ModalUnderlay = 'rgba(15, 15, 15, 0.6)';
export const WebLink = '#1164A3';
export const purple = '#5E57E3';
export const CopilotDarkGreen = '#00160E';
export const CopilotLightGreen = '#E3FFEE';
export const CopilotGreen = '#09aa6c';
export const CopilotAdminAuthBackground = '#003F27';
export const PdfPreviewBorder = '#EDEDF0';
export const DarkFont = '#0E0E10';
export const GrayFont = '#60606A';
// severity tags color
export const HighSeverityTagBackgroundColor = 'rgba(255, 0, 0, 0.2)';
export const HighSeverityTagBorderColor = 'rgba(255, 0, 0, 0.3)';
export const MediumSeverityTagTextColor = '#FFA500';
export const MediumSeverityTagBackgroundColor = 'rgba(255, 165, 0, 0.2)';
export const LowSeverityTagTextColor = '#1E9A50';
export const LowSeverityTagBackgroundColor = 'rgba(30, 154, 80, 0.2)';
export const LowSeverityTagBorderColor = 'rgba(9, 170, 108, 0.3)';
export const NeutralSeverityTagBackgroundColor = 'rgba(0, 0, 0, 0.1)';
// integration icon colors
export const QuickbooksIconColor = '#2ca01c';
// Scrollbar color
export const ScrollbarColor = 'rgba(0,0,0,0.5)';

export const DarkFontColor = '#0E0E10';

// multi select options colors map
export const MultiSelectOptionsColors = {
  Default: 'rgba(144, 149, 157, 1)',
  Gray: 'rgba(0, 0, 0, 1)',
  Brown: 'rgba(150, 75, 0, 1)',
  Red: 'rgba(204, 0, 0, 1)',
  Yellow: 'rgba(255, 165, 0, 1)',
  Green: 'rgba(30, 154, 80, 1)',
  Blue: 'rgba(17, 100, 163, 1)',
};
export const FadeTransparentGradient =
  'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(232,232,232,0) 100%)';
export const InternalAccent = BlackHeadings;

// sidebar colors
export const SidebarColors = {
  InternalSidebarBackground: HoverBackground, // sidebar background color for internal user
  InternalSidebarText: InternalAccent, // sidebar text color for internal user
  InternalSidebarItemHover: DividersAndCardBorders, // sidebar item background color when selected or hovered
};
