import { copilotDotCom } from 'src/constants/hostnameConsts';

export const PAYMENT_FEE = {
  CARD: 3.1,
  BANK_DEFAULT: 1,
  MAX_ACH_FEE: 5, // $5
  LIMIT: 500, // $500
};

export enum BILLING_INTERVALS {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTERLY = 'quarterly',
  BIANNUALLY = 'biannually',
  YEAR = 'year',
  SEMIANNUALLY = 'semiannually',
}

export const BILLING_INTERVALS_ITEMS = [
  {
    value: BILLING_INTERVALS.DAY,
    label: 'Daily',
  },
  {
    value: BILLING_INTERVALS.WEEK,
    label: 'Weekly',
  },
  {
    value: BILLING_INTERVALS.MONTH,
    label: 'Monthly',
  },
  {
    value: BILLING_INTERVALS.QUARTERLY,
    label: 'Quarterly',
  },
  {
    value: BILLING_INTERVALS.BIANNUALLY,
    label: 'Biannually',
  },
  {
    value: BILLING_INTERVALS.YEAR,
    label: 'Yearly',
  },
  {
    value: BILLING_INTERVALS.SEMIANNUALLY,
    label: 'Every 2 years',
  },
];

export enum INVOICE_PREVIEW_TYPES {
  CHECKOUT = 'checkout',
  INVOICE = 'invoice',
  EMAIL = 'email',
}

export const BILLING_METHODS = {
  CHARGE_AUTOMATICALLY: 'charge_automatically',
  SEND_INVOICE: 'send_invoice',
};
export const BILLING_METHODS_ITEMS = [
  {
    value: BILLING_METHODS.CHARGE_AUTOMATICALLY,
    label: 'Charge automatically',
  },
  {
    value: BILLING_METHODS.SEND_INVOICE,
    label: 'Send invoice each time',
  },
];

export enum InvoiceStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
}

export enum SubscriptionStatus {
  Active = 'active',
  Unpaid = 'unpaid',
  Incomplete = 'incomplete',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Trialing = 'trialing',
  IncompleteExpired = 'incomplete_expired',
  NotStarted = 'not_started',
}

export const PAYMENT_METHOD = {
  CARD: 'card',
  BANK: 'bank',
};

export const ACCOUNT_TYPE = {
  STANDARD: 'standard',
  EXPRESS: 'express',
};

type CardIconPaths = {
  clear: string;
  contained: string;
};

type PaymentCard = {
  displayName: string;
  path: CardIconPaths;
};

export type StripeEntity = 'stripe_invoice' | 'stripe_subscription';

export const STRIPE_INVOICE = 'stripe_invoice';

export const STRIPE_SUBSCRIPTION = 'stripe_subscription';
export const CARDS_ICONS: Record<string, any> = {
  visa: {
    displayName: 'Visa',
    path: {
      clear: '/images/card-logos/visa.svg',
      contained: '/images/card-logos/contained/visa.svg',
    },
  },
  mastercard: {
    displayName: 'MasterCard',
    path: {
      clear: '/images/card-logos/mastercard.svg',
      contained: '/images/card-logos/contained/mastercard.svg',
    },
  },
  amex: {
    displayName: 'American Express',
    path: {
      clear: '/images/card-logos/amex.svg',
      contained: '/images/card-logos/amex.svg',
    },
  },
  diners: {
    displayName: 'Diners Club',
    path: {
      clear: '/images/card-logos/diners.svg',
      contained: '/images/card-logos/diners.svg',
    },
  },
  jcb: {
    displayName: 'JCB',
    path: {
      clear: '/images/card-logos/jcb.svg',
      contained: '/images/card-logos/jcb.svg',
    },
  },
  discover: {
    displayName: 'Discover',
    path: {
      clear: '/images/card-logos/discover.svg',
      contained: '/images/card-logos/discover.svg',
    },
  },
  unionpay: {
    displayName: 'UnionPay',
    path: {
      clear: '/images/card-logos/unionpay.svg',
      contained: '/images/card-logos/unionpay.svg',
    },
  },
  Visa: {
    displayName: 'Visa',
    path: {
      clear: '/images/card-logos/visa.svg',
      contained: '/images/card-logos/contained/visa.svg',
    },
  },
  MasterCard: {
    displayName: 'MasterCard',
    path: {
      clear: '/images/card-logos/mastercard.svg',
      contained: '/images/card-logos/contained/mastercard.svg',
    },
  },
  'American Express': {
    displayName: 'American Express',
    path: {
      clear: '/images/card-logos/amex.svg',
      contained: '/images/card-logos/amex.svg',
    },
  },
  'Diners Club': {
    displayName: 'Diners Club',
    path: {
      clear: '/images/card-logos/diners.svg',
      contained: '/images/card-logos/diners.svg',
    },
  },
  JCB: {
    displayName: 'JCB',
    path: {
      clear: '/images/card-logos/jcb.svg',
      contained: '/images/card-logos/jcb.svg',
    },
  },
  Discover: {
    displayName: 'Discover',
    path: {
      clear: '/images/card-logos/discover.svg',
      contained: '/images/card-logos/discover.svg',
    },
  },
  UnionPay: {
    displayName: 'UnionPay',
    path: {
      clear: '/images/card-logos/unionpay.svg',
      contained: '/images/card-logos/unionpay.svg',
    },
  },
};

export const PAYMENT_CARDS_ICONS: Record<string, PaymentCard> = {
  Visa: {
    displayName: 'Visa',
    path: {
      clear: '/images/card-logos/visa.svg',
      contained: '/images/card-logos/contained/visa-card.svg',
    },
  },
  MasterCard: {
    displayName: 'MasterCard',
    path: {
      clear: '/images/card-logos/mastercard.svg',
      contained: '/images/card-logos/contained/master-card.svg',
    },
  },
  'American Express': {
    displayName: 'American Express',
    path: {
      clear: '/images/card-logos/amex.svg',
      contained: '/images/card-logos/contained/amex-card.svg',
    },
  },
  Diners: {
    displayName: 'Diners Club',
    path: {
      clear: '/images/card-logos/diners.svg',
      contained: '/images/card-logos/contained/diners-card.svg',
    },
  },
  Discover: {
    displayName: 'Discover',
    path: {
      clear: '/images/card-logos/discover.svg',
      contained: '/images/card-logos/contained/discover-card.svg',
    },
  },
  JCB: {
    displayName: 'JCB',
    path: {
      clear: '/images/card-logos/jcb.svg',
      contained: '/images/card-logos/contained/jcb-card.svg',
    },
  },
  UnionPay: {
    displayName: 'UnionPay',
    path: {
      clear: '/images/card-logos/unionpay.svg',
      contained: '/images/card-logos/contained/unionpay-card.svg',
    },
  },
  amex: {
    displayName: 'American Express',
    path: {
      clear: '/images/card-logos/amex.svg',
      contained: '/images/card-logos/contained/amex-card.svg',
    },
  },
  diners: {
    displayName: 'Diners Club',
    path: {
      clear: '/images/card-logos/diners.svg',
      contained: '/images/card-logos/contained/diners-card.svg',
    },
  },
  discover: {
    displayName: 'Discover',
    path: {
      clear: '/images/card-logos/discover.svg',
      contained: '/images/card-logos/contained/discover-card.svg',
    },
  },
  jcb: {
    displayName: 'JCB',
    path: {
      clear: '/images/card-logos/jcb.svg',
      contained: '/images/card-logos/contained/jcb-card.svg',
    },
  },
  mastercard: {
    displayName: 'MasterCard',
    path: {
      clear: '/images/card-logos/mastercard.svg',
      contained: '/images/card-logos/contained/master-card.svg',
    },
  },
  unionpay: {
    displayName: 'UnionPay',
    path: {
      clear: '/images/card-logos/unionpay.svg',
      contained: '/images/card-logos/contained/unionpay-card.svg',
    },
  },
  visa: {
    displayName: 'Visa',
    path: {
      clear: '/images/card-logos/visa.svg',
      contained: '/images/card-logos/contained/visa-card.svg',
    },
  },
};
export enum LEGACY_BILLING_PLANS {
  BASIC_MONTHLY = 'portal_basic_monthly',
  BASIC_YEARLY = 'portal_basic_yearly',
  PRO_MONTHLY = 'portal_pro_monthly',
  PRO_YEARLY = 'portal_pro_yearly',
  STANDARD_MONTHLY = 'standard_monthly',
  STANDARD_ANNUAL = 'standard_annual',
  PROFESSIONAL_MONTHLY = 'pro_monthly',
  PROFESSIONAL_ANNUAL = 'pro_annual',
  ENTERPRISE = 'portal_enterprise',
}

export const PORTAL_TRIAL_PLAN = 'portal_trial';

export const ACTIVE_BILLING_PLANS = {
  STARTER_MONTHLY: 'starter_monthly_39',
  STARTER_ANNUAL: 'starter_annual_348',
  PROFESSIONAL_MONTHLY: 'professional_monthly_89',
  PROFESSIONAL_ANNUAL: 'professional_annual_828',
  ADVANCED_MONTHLY: 'advanced_monthly_139',
  ADVANCED_ANNUAL: 'advanced_annual_1428',
  ENTERPRISE_USER_ANNUAL: 'enterprise_user_annual_included_10_1200',
  ENTERPRISE_PLATFORM_ANNUAL: 'enterprise_platform_annual_24000',
  TRIAL: PORTAL_TRIAL_PLAN,
};

export const ACTIVE_BILLING_PLATFORM_PLANS = {
  STARTER_MONTHLY_PLATFORM: 'starter_monthly_platform',
  STARTER_ANNUAL_PLATFORM: 'starter_annual_platform',
  PROFESSIONAL_MONTHLY_PLATFORM: 'professional_monthly_platform',
  PROFESSIONAL_ANNUAL_PLATFORM: 'professional_annual_platform',
  ADVANCED_MONTHLY_PLATFORM: 'advanced_monthly_platform',
  ADVANCED_ANNUAL_PLATFORM: 'advanced_annual_platform',
};

export const LEGACY_PLAN_FEATURE_LABELS = {
  CLIENT_USERS: 'Clients',
  INTERNAL_USERS: 'Internal users',
  FILE_STORAGE: 'Storage',
  BILLING_MODULE: 'Billing',
  FILE_SHARING_MODULE: 'Files',
  MESSAGING_MODULE: 'Messaging',
  INTAKE_FORMS_MODULE: 'Forms',
  KNOWLEDGE_BASE_MODULE: 'Knowledge base',
  ESIGNATURES: 'eSignatures',
  CUSTOM_DOMAIN: 'Custom domain for portal',
  BRANDED_EMAIL_NOTIFICATIONS: 'Custom domain for email',
  DEDICATED_MANAGER: 'Dedicated manager',
  TEAM_TRAINING: 'Team training',
  CUSTOM_CONTRACTS: 'Custom contracts',
  CRM: 'CRM',
  CUSTOMIZATION: 'White-labeling',
  TEAM_COLLABORATION: 'Team collaboration',
  CUSTOM_ROLES: 'Custom Roles',
  ACCOUNTING_INTEGRATIONS: 'Accounting integrations',
  EXTENSIONS: 'Apps',
  PRIORITY_SUPPORT: 'Priority support',
  DEDICATED_SUCCESS_MANAGER: 'Customer success manager',
  CUSTOM_FIELDS: 'Custom fields',
  CONTRACTS: 'Contracts',
  DATA_MIGRATION: 'Data migrations',
  BUILD_SERVICES: 'Build services',
};

export const ACTIVE_PLAN_FEATURE_LABELS = {
  CLIENT_USERS: 'Total clients',
  FILE_STORAGE: 'Total storage',
  CRM: 'Client management',
  CUSTOM_FIELDS: 'Custom fields',
  TEAM_COLLABORATION: 'Team collaboration',
  CUSTOMIZATION: 'Customization',
  INTERNAL_USERS: 'Internal users',
  BILLING_MODULE: 'Billing',
  FILE_SHARING_MODULE: 'Files',
  MESSAGING_MODULE: 'Messaging',
  INTAKE_FORMS_MODULE: 'Forms',
  KNOWLEDGE_BASE_MODULE: 'Knowledge base',
  CUSTOM_DOMAIN: 'Custom domain for portal',
  BRANDED_EMAIL_NOTIFICATIONS: 'Custom domain for email',
  DEDICATED_MANAGER: 'Dedicated manager',
  TEAM_TRAINING: 'Team training',
  CUSTOM_CONTRACTS: 'Custom contracts',
  CUSTOM_ROLES: 'Custom Roles',
  ACCOUNTING_INTEGRATIONS: 'Accounting integrations',
  EXTENSIONS: 'Apps',
  PRIORITY_SUPPORT: 'Priority support',
  DEDICATED_SUCCESS_MANAGER: 'Customer success manager',
  CONTRACTS: 'Contracts',
  DATA_MIGRATION: 'Data migrations',
  BUILD_SERVICES: 'Build services',
  CREDIT_CARDS: 'Credit cards',
  ACH: 'ACH',
  WHITE_LABEL: 'White labeling',
  AUTOMATION_ZAPIER: 'Automations & Zapier',
  API_ACCESS: 'API Access',
  HIPAA: 'HIPAA compliance with BAA',
  ROLES_PERMISSIONS: 'Custom roles & permissions',
  PARTNER_APPS: 'Partner Apps',
  CUSTOM_APPS: 'Custom Apps',
  INVOICE_PAYMENT:
    'Payments for invoices generated by a recurring subscription',
  INTERNATIONAL_CREDIT_CARD: 'International credit cards',
  CURRENCY_CONVERSION: 'Currency conversion required',
  EMAIL_SUPPORT: 'Email and community support',
  DEDICATED_EXPERT: 'Dedicated expert',
};

export const LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS = {
  CLIENTS_LIMIT:
    'A client user is any client of your business that has their own login access to your portal. Internal users (team members) are not considered client users.',
  INTERNAL_USERS:
    'An internal user is anyone on your team with their own login access to your portal. All internal users in your portal are billed at the same plan rate. Adding multiple internal users lets you take advantage of team features like assigning account managers to clients.',
  FILES_STORAGE_LIMIT:
    'File uploads in the Files module by any user contribute to your file storage limit. When you reach your limit, our team will get in touch and ask that you make space or upgrade your account.',
  BILLING_FUNCTIONALITY:
    'Create one-time invoices and recurring subscriptions in your portal. Give clients a way to seamlessly check out directly from your portal and pay via credit card or ACH (traditional or Plaid). Syncs with QuickBooks.\n\nClients can also self-serve manage payment methods and access their invoice history.',
  FILES_FUNCTIONALITY:
    'Upload files, add links, and stay organized with folders. Advanced controls let you specify whether clients have the same controls or more limited access.',
  USERS_CAN_COMMUNICATE:
    'Securely communicate with clients in an integrated chat experience. Clients can send messages in your portal or reply to messaging email notifications in a seamless experience. ',
  FORMS_FUNCTIONALITY:
    'Streamline the client onboarding experience and data intake with reusable forms.',
  KNOWLEDGE_BASE_FUNCTIONALITY:
    'Create a help desk for your clients to reduce manual support time. With a powerful article editor, use rich text, images, videos, and embeds to create compelling content.\n\nUse tags and custom visibility controls to organize articles and indicate which client can see which content.',
  WHITE_LABELING: 'Remove the “Powered by Copilot” badge.',
  TEAM_COLLABORATION_FUNCTIONALITY:
    'If you manage a large number of clients you might find that some internal users are responsible only for a subset of all clients. For example, if you have account managers you may not want them to see or get notified about all client activity.\n\nOn the CRM you can assign one or more internal users to a client. Only those internal users that are explicitly assigned to a client can see the client and associated data. Similarly, only internal users assigned to a client receive notifications that pertain to that client. ',
  SPECIFY_CUSTOM_ROLES:
    'Custom roles let you specify precisely what information can be accessed by internal users.',
  HOST_PORTAL_ON_OWN_WEBSITE:
    'Host your portal on your own domain. Typically a custom domain URL will look like "portal.<yourcompany>.com".',
  ACCOUNTING_INTEGRATIONS:
    'Accounting integrations let you connect QuickBooks or Xero.',
  USERS_CAN_CONNECT_EXTENSIONS:
    'Embed products like Airtable, ClickUp, Calendly, Google Data Studio, and 1000s of others in your portal. Extensions show in your portal’s sidebar with a custom name and icon.',
  PRIORITY_SUPPORT: 'Receive priority email support 24/5.',
  DEDICATED_SUCCESS_MANAGER:
    'You’ll be assigned a dedicated customer success manager. You’ll also receive a dedicated link to schedule phone and video support meetings.',
  CRM_FUNCTIONALITY:
    'The CRM shows you the name, email, status, and other information of each client.',
  CUSTOM_FIELDS_FUNCTIONALITY:
    'Use custom fields to organize clients on the CRM. There are currently  7 types of custom fields — Phone number, eMail, URL, Text, Number, Address, and Tags.',
  CONTRACTS_FUNCTIONALITY:
    'Upload PDFs and request eSignatures directly in your portal.',
  DATA_MIGRATION_FUNCTIONALITY:
    'Our migration team can help you move data from other systems into your portal.',
  BUILD_SERVICES_FUNCTIONALITY:
    'Work with a team of Portal deployed engineers to design custom features and integrations.',
};

export const ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS = {
  CLIENTS_LIMIT:
    'A client user is any client of your business that has their own login access to your portal. Internal users (team members) are not considered client users.',
  FILES_STORAGE_LIMIT:
    'File uploads in the Files module by any user contribute to your file storage limit.  When you reach your limit, our team will get in touch and ask that you make space or upgrade your account.',
  CRM_FUNCTIONALITY:
    'Client management functionality lets you create, invite, organize, and manage client information.',
  CUSTOM_FIELDS_FUNCTIONALITY:
    'With custom fields, you can add and track custom properties for your clients. For example, you can track locations, addresses, or birthdays.',
  TEAM_COLLABORATION_FUNCTIONALITY:
    'Assign a lead and one or more assignees to each of your clients. This lets you stay organized and gives you full control over which team member can access which client.',
  CUSTOMIZATION:
    'Customizations include the ability to upload you brand assets, design your theme, set up a custom log in screen, and more. ',
  INTERNAL_USERS:
    'An internal user is anyone on your team with their own login access to your portal. All internal users in your portal are billed at the same plan rate. Adding multiple internal users lets you take advantage of team features like assigning account managers to clients.',
  BILLING_FUNCTIONALITY:
    'Create one-time invoices and recurring subscriptions in your portal. Give clients a way to seamlessly check out directly from your portal and pay via credit card or ACH (traditional or Plaid). Syncs with QuickBooks.\n\nClients can also self-serve manage payment methods and access their invoice history.',
  FILES_FUNCTIONALITY:
    'Upload files, add links, and stay organized with folders. Advanced controls let you specify whether clients have the same controls or more limited access.',
  USERS_CAN_COMMUNICATE:
    'Securely communicate with clients in an integrated chat experience. Clients can send messages in your portal or reply to messaging email notifications in a seamless experience. ',
  FORMS_FUNCTIONALITY:
    'Streamline the client onboarding experience and data intake with reusable forms.',
  KNOWLEDGE_BASE_FUNCTIONALITY:
    'Create a help desk for your clients to reduce manual support time. With a powerful article editor, use rich text, images, videos, and embeds to create compelling content.\n\nUse tags and custom visibility controls to organize articles and indicate which client can see which content.',
  WHITE_LABELING: 'Remove the “Powered by Copilot” badge.',
  SPECIFY_CUSTOM_ROLES:
    'Custom roles let you specify precisely what information can be accessed by internal users.',
  HOST_PORTAL_ON_OWN_WEBSITE:
    'Host your portal on your own domain. Typically a custom domain URL will look like "portal.<yourcompany>.com".',
  ACCOUNTING_INTEGRATIONS:
    'Accounting integrations let you connect QuickBooks or Xero.',
  USERS_CAN_CONNECT_EXTENSIONS:
    'Embed products like Airtable, ClickUp, Calendly, Google Data Studio, and 1000s of others in your portal. Extensions show in your portal’s sidebar with a custom name and icon.',
  PRIORITY_SUPPORT: 'Receive priority email support 24/5.',
  DEDICATED_SUCCESS_MANAGER:
    'You’ll be assigned a dedicated customer success manager. You’ll also receive a dedicated link to schedule phone and video support meetings.',
  CLIENT_MANAGEMENT:
    'Client management functionality lets you create, invite, organize, and manage client information.',
  CONTRACTS_FUNCTIONALITY:
    'Upload PDFs and request eSignatures directly in your portal.',
  DATA_MIGRATION_FUNCTIONALITY:
    'Our migration team can help you move data from other systems into your portal.',
  BUILD_SERVICES_FUNCTIONALITY:
    'Work with a team of Portal deployed engineers to design custom features and integrations.',
  CREDIT_CARDS:
    'Payment processing fee for credit cards. You can control whether you want to absorb payment processing fees or pass them on to your client.',
  ACH: 'Payment processing fee for ACH Debit. You can control whether you want to absorb payment processing fees or pass them on to your client. Instant ACH powered by Plaid and traditional ACH with micro-deposit verification are both supported.',
  WHITE_LABEL:
    'With a custom domain you can host your portal on your own URL. With a custom email domain you can send client email notifications from your own email. Powered by Copilot is a small badge that shows in the client experience and can be removed on the Advanced plan. ',
  AUTOMATION_ZAPIER:
    'Use Portal API and Zapier triggers to set up automations that streamline the experience for your clients.',
  API_ACCESS:
    'Use our REST API and Webhooks to set up automations and connect other tools.',
  HIPAA:
    "If you're a covered entity or business associate subject to HIPAA, contact our team to enter into a BAA.",
  ROLES_PERMISSIONS:
    'Create custom roles for your team if you want full control over what functionality each internal user can access in your portal.',
  PARTNER_APPS:
    'Embed products like Airtable, ClickUp, Calendly, Google Data Studio, and 1000s of others in your portal and give clients a true one-stop shop experience.',
  CUSTOM_APPS:
    'A custom app is a web application that can be embedded into your portal and receives information about the current user or company. You can render custom content automatically depending on the client that is signed in.',
  INVOICE_PAYMENT:
    'Payments for invoices that are generated by a subscription may incur an additional fee.',
  INTERNATIONAL_CREDIT_CARD:
    'International credit cards incur an additional 1% fee.',
  CURRENCY_CONVERSION:
    'Credit card payments that require currency conversion incur an additional 1% fee.',
  EMAIL_SUPPORT:
    'Receive support from our community and get answers from our support team. ',
  NEW_PRIORITY_SUPPORT:
    'Receive elevated support from our priority support team.  ',
  DEDICATED_EXPERT:
    'Meet 1:1 with an expert to help you set up your portal, migrate data, set up workflow automations, and more. ',
};

export const ACTIVE_PLAN_FEATURE_TOOLTIP_LEARNMORE_LINKS = {
  TEAM_COLLABORATION_FUNCTIONALITY: `https://www.${copilotDotCom.prod}/guide/internal-user-roles`,
  AUTOMATION_ZAPIER: `https://www.${copilotDotCom.prod}/automations`,
  USERS_CAN_COMMUNICATE: `https://www.${copilotDotCom.prod}/guide/copilot-messages-app`,
  BILLING_FUNCTIONALITY: `https://www.${copilotDotCom.prod}/guide/copilot-billing-app`,
  FILES_FUNCTIONALITY: `https://www.${copilotDotCom.prod}/guide/copilot-files-app`,
  FORMS_FUNCTIONALITY: `https://www.${copilotDotCom.prod}/guide/copilot-forms-app`,
  KNOWLEDGE_BASE_FUNCTIONALITY: `https://www.${copilotDotCom.prod}/guide/copilot-helpdesk-app`,
};

const CustomValue = 'Custom';

export const LIMITED_USERS = {
  basic: '2',
  pro: '5',
  unlimited: 'Unlimited',
};

export const LIMITED_CLIENTS = {
  starter: '50',
  basic: '250',
  pro: '2500',
  advance: '5000',
  unlimited: 'Unlimited',
};

export const LEGACY_PLAN_FEATURES = [
  {
    label: 'Access',
    isGroup: true,
    properties: [
      {
        label: LEGACY_PLAN_FEATURE_LABELS.CLIENT_USERS,
        basic: LIMITED_CLIENTS.basic,
        pro: LIMITED_CLIENTS.pro,
        enterprise: CustomValue,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CLIENTS_LIMIT,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.FILE_STORAGE,
        basic: '10GB',
        pro: '1TB',
        enterprise: CustomValue,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.FILES_STORAGE_LIMIT,
      },
    ],
  },
  {
    label: 'Features',
    isGroup: true,
    properties: [
      {
        label: LEGACY_PLAN_FEATURE_LABELS.CRM,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CRM_FUNCTIONALITY,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.CUSTOM_FIELDS,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CUSTOM_FIELDS_FUNCTIONALITY,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.TEAM_COLLABORATION,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.TEAM_COLLABORATION_FUNCTIONALITY,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.CUSTOM_DOMAIN,
        basic: false,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.HOST_PORTAL_ON_OWN_WEBSITE,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.CUSTOMIZATION,
        basic: false,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.WHITE_LABELING,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.EXTENSIONS,
        basic: false,
        pro: true,
        enterprise: true,
        isSpecific: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.USERS_CAN_CONNECT_EXTENSIONS,
      },
    ],
  },
  {
    label: 'Modules',
    isGroup: true,
    properties: [
      {
        label: LEGACY_PLAN_FEATURE_LABELS.BILLING_MODULE,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.BILLING_FUNCTIONALITY,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.MESSAGING_MODULE,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.USERS_CAN_COMMUNICATE,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.FILE_SHARING_MODULE,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.FILES_FUNCTIONALITY,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.INTAKE_FORMS_MODULE,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.FORMS_FUNCTIONALITY,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.KNOWLEDGE_BASE_MODULE,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.KNOWLEDGE_BASE_FUNCTIONALITY,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.CONTRACTS,
        basic: false,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CONTRACTS_FUNCTIONALITY,
      },
    ],
  },
  {
    label: 'Support',
    isGroup: true,
    properties: [
      {
        label: LEGACY_PLAN_FEATURE_LABELS.PRIORITY_SUPPORT,
        basic: true,
        pro: true,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.PRIORITY_SUPPORT,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.DEDICATED_SUCCESS_MANAGER,
        basic: false,
        pro: false,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.DEDICATED_SUCCESS_MANAGER,
      },
      {
        label: LEGACY_PLAN_FEATURE_LABELS.DATA_MIGRATION,
        basic: false,
        pro: false,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.DATA_MIGRATION_FUNCTIONALITY,
      },
    ],
  },
  {
    label: 'Professional services',
    isGroup: true,
    properties: [
      {
        label: LEGACY_PLAN_FEATURE_LABELS.BUILD_SERVICES,
        basic: false,
        pro: false,
        enterprise: true,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.BUILD_SERVICES_FUNCTIONALITY,
      },
    ],
  },
];

export const ACTIVE_PLAN_FEATURES = [
  {
    label: 'Access',
    isGroup: true,
    properties: [
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CLIENT_USERS,
        starter: LIMITED_CLIENTS.starter,
        pro: '500',
        advance: LIMITED_CLIENTS.advance,
        enterprise: CustomValue,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CLIENTS_LIMIT,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.FILE_STORAGE,
        starter: '50GB',
        pro: '500GB',
        advance: '5TB',
        enterprise: CustomValue,
        tooltipDescription:
          LEGACY_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.FILES_STORAGE_LIMIT,
      },
    ],
  },
  {
    label: 'Features',
    isGroup: true,
    properties: [
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CRM,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CRM_FUNCTIONALITY,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CUSTOM_FIELDS,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CUSTOM_FIELDS_FUNCTIONALITY,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.TEAM_COLLABORATION,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.TEAM_COLLABORATION_FUNCTIONALITY,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CUSTOMIZATION,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CUSTOMIZATION,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.AUTOMATION_ZAPIER,
        starter: false,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.AUTOMATION_ZAPIER,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.WHITE_LABEL,
        starter: '',
        pro: 'Custom domain, Custom email domain',
        advance: `Custom domain, Custom email domain, Remove ‘Powered by Copilot’`,
        enterprise: CustomValue,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.WHITE_LABEL,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.API_ACCESS,
        starter: '',
        pro: 'Professional Rate Limit',
        advance: 'Advanced Rate Limit',
        enterprise: CustomValue,
        tooltipDescription: ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.API_ACCESS,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.HIPAA,
        starter: false,
        pro: false,
        advance: true,
        enterprise: true,
        tooltipDescription: ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.HIPAA,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.ROLES_PERMISSIONS,
        starter: false,
        pro: false,
        advance: 'YES ( Coming soon )',
        enterprise: 'YES ( Coming soon )',
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.ROLES_PERMISSIONS,
      },
    ],
  },
  {
    label: 'Copilot Apps',
    isGroup: true,
    properties: [
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.MESSAGING_MODULE,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.USERS_CAN_COMMUNICATE,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.BILLING_MODULE,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.BILLING_FUNCTIONALITY,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.FILE_SHARING_MODULE,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.FILES_FUNCTIONALITY,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CONTRACTS,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CONTRACTS_FUNCTIONALITY,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.INTAKE_FORMS_MODULE,
        starter: true,
        pro: true,
        enterprise: true,
        advance: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.FORMS_FUNCTIONALITY,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.KNOWLEDGE_BASE_MODULE,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.KNOWLEDGE_BASE_FUNCTIONALITY,
      },
    ],
  },
  {
    label: 'Apps',
    isGroup: true,
    properties: [
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.PARTNER_APPS,
        starter: false,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.PARTNER_APPS,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CUSTOM_APPS,
        starter: false,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CUSTOM_APPS,
      },
    ],
  },
  {
    label: 'Payment processing fees',
    isGroup: true,
    properties: [
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CREDIT_CARDS,
        starter: '3.5% + $0.30',
        pro: '3.2% + $0.30',
        advance: '3.1% + $0.30',
        enterprise: CustomValue,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CREDIT_CARDS,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.ACH,
        starter: '$5 or 1% (whichever is lower)',
        pro: '$5 or 1% (whichever is lower)',
        advance: '$5 or 1% (whichever is lower)',
        enterprise: CustomValue,
        tooltipDescription: ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.ACH,
      },
    ],
  },
  {
    label: 'Additional payment processing fees',
    isGroup: true,
    properties: [
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.INVOICE_PAYMENT,
        starter: '+1%',
        pro: '+0.5%',
        advance: 'Included',
        enterprise: 'Included',
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.INVOICE_PAYMENT,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.INTERNATIONAL_CREDIT_CARD,
        starter: '+1%',
        pro: '+1%',
        advance: '+1%',
        enterprise: CustomValue,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.INTERNATIONAL_CREDIT_CARD,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.CURRENCY_CONVERSION,
        starter: '+1%',
        pro: '+1%',
        advance: '+1%',
        enterprise: CustomValue,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.CURRENCY_CONVERSION,
      },
    ],
  },

  {
    label: 'Support',
    isGroup: true,
    properties: [
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.EMAIL_SUPPORT,
        starter: true,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.EMAIL_SUPPORT,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.PRIORITY_SUPPORT,
        starter: false,
        pro: true,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.NEW_PRIORITY_SUPPORT,
      },
      {
        label: ACTIVE_PLAN_FEATURE_LABELS.DEDICATED_EXPERT,
        starter: false,
        pro: false,
        advance: true,
        enterprise: true,
        tooltipDescription:
          ACTIVE_PLAN_FEATURE_TOOLTIP_DESCRIPTIONS.DEDICATED_EXPERT,
      },
    ],
  },
];

export const LEGACY_MOBILE_PLAN_FEATURES = {
  Standard: [
    '250 clients',
    '10GB storage limit',
    'Messaging module',
    'Files module',
    'Billing module',
    'Forms module',
    'Knowledge base module',
    'Priority Support',
  ],
  Professional: [
    'All features in the Standard plan',
    '2500 clients',
    '1TB storage limit',
    'Billing templates',
    'Custom domain',
    'Accounting integrations',
    'Apps',
  ],
  Enterprise: [
    'All features in the Professional plan',
    LEGACY_PLAN_FEATURE_LABELS.DEDICATED_SUCCESS_MANAGER,
    LEGACY_PLAN_FEATURE_LABELS.DATA_MIGRATION,
    LEGACY_PLAN_FEATURE_LABELS.BUILD_SERVICES,
  ],
};

export const ACTIVE_MOBILE_PLAN_FEATURES = {
  Starter: [
    '200 clients',
    '10GB storage limit',
    'Messaging module',
    'Files module',
    'Billing module',
    'Forms module',
    'Knowledge base module',
    'Email and community support',
  ],
  Professional: [
    'All features in the Starter plan',
    '2000 clients',
    '1TB storage limit',
    'Billing templates',
    'Partner Apps',
    'eSignatures',
    'Custom domain',
    'Accounting integrations',
    'Apps',
  ],
  Advanced: [
    'All features in the Professional plan',
    '20000 clients',
    '20TB storage limit',
    'HIPAA compliance with BAA',
    'Custom roles & permissions',
    'Custom Apps',
    'Priority support',
    'Dedicated expert',
  ],
};

export const PAYMENTS_PER_USER_DESCRIPTION =
  'An internal user is anyone on your team with their own login access to your Portal. All users in your account are billed at the same plan rate per month.';

export const CANCEL_REASON_SOURCES = [
  {
    id: 0,
    label: 'Missing features',
    value: 'missing_features',
    subQuestion: 'What features are currently missing?',
  },
  {
    id: 1,
    label: 'Too difficult to use',
    value: 'too_difficult_to_use',
    subQuestion: 'What features did you find difficult to use?',
  },
  {
    id: 2,
    label: 'Not customizable enough',
    value: 'not_customizable_enough',
    subQuestion: 'What customizations were you unable to make?',
  },
  {
    id: 3,
    label: "Doesn't integrate with other products",
    value: 'doesnt_integrate_with_other_products',
    subQuestion: 'What integrations are missing?',
  },
  {
    id: 4,
    label: 'The price is too high',
    value: 'the_price_is_too_high',
    subQuestion:
      'Do you have any feedback about how we can improve our pricing?',
  },
  {
    id: 5,
    label: 'Found a better alternative',
    value: 'found_a_better_alternative',
    subQuestion: 'Which product are you switching to?',
  },
  {
    id: 6,
    label: 'Not enough support',
    value: 'not_enough_support',
  },
  {
    id: 7,
    label: 'Upgraded by mistake',
    value: 'upgraded_by_mistake',
  },
  {
    id: 8,
    label: 'Company is shutting down or making cuts',
    value: 'company_is_shutting_down_or_making_cuts',
  },
  {
    id: 9,
    label: 'Temporary purchase',
    value: 'temporary_purchase',
  },
  {
    id: 10,
    label: 'My clients didn’t use my portal',
    value: 'clients_not_using_portal',
    subQuestion: 'Do you know why your clients aren’t adopting your portal?',
  },
  {
    id: 12,
    label: 'Building a portal ourselves or with the help of a vendor',
    value: 'building_own_custom_portal',
    subQuestion:
      'What custom features you plan to build that we don’t yet support?',
  },
];
