import { alpha, darken, getLuminance } from '@material-ui/core';
import { RGBColor } from 'react-color';

export default class ColorUtils {
  static ConvertHexToColorResult = (hexCode: string) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex += hex;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return {
      r,
      g,
      b,
      a: 1,
    };
  };

  static ConvertRGBColor = (rgbColor: RGBColor) => {
    const { r, g, b, a } = rgbColor;
    return `rgba(${r},${g},${b},${a})`;
  };

  /**
   * This function returns three shades of darkness
   * for a given color
   * @param color given color
   */
  static GetColorDarknessShades = (color: string) => {
    const isColorTransparent = color === 'transparent';

    if (isColorTransparent) {
      // since there is no light, midDark and dark
      // color available for 'transparent'
      // we can just return the `color` for all the values.
      return {
        light: color,
        midDark: color,
        dark: color,
      };
    }
    const isLightColor = getLuminance(color) > 0.33;
    return {
      light: alpha(color, 0.12),
      midDark: darken(color, isLightColor ? 0.05 : 0.16),
      dark: darken(color, isLightColor ? 0.08 : 0.32),
    };
  };
}
