import { API } from 'src/utils/AmplifyApiUtils';
import {
  FileResponse,
  ClientTypes,
  FileSaveModel,
  CreateFilesResponse,
  FileChannel,
} from 'src/store/files/types';

const appApi = 'AppAPI';
const url = '/files/';

export interface FileBundleData {
  key: string;
  name: string;
  path: string;
  identityID: string;
}

export interface CreateChannelData {
  companyID: string;
  clientUserIDs: string[];
  singleClientId?: string;
}

export const getOwnerIdAndTypeFromFileChannelId = (fileChannelId: string) => {
  const [, companyId, userId] = fileChannelId.split('/');
  return userId === 'none'
    ? { ownerId: companyId, ownerType: ClientTypes.CLIENT_COMPANY }
    : { ownerId: userId, ownerType: ClientTypes.CLIENT_USER };
};

interface GetZippedBundleInput {
  fileData: FileBundleData[];
  bundleName: string;
}

interface FileMetadataResponse {
  data: FileResponse[];
  nextToken: string;
}

export default class FilesClient {
  /**
   * Return file entities based on channel
   * @param channelID which channel to look for files
   */
  static async GetFilesForChannelId(
    channelID: string,
    nextToken: string = '',
    files: FileResponse[] = [],
  ): Promise<FileResponse[]> {
    const { ownerId, ownerType } =
      getOwnerIdAndTypeFromFileChannelId(channelID);
    const response: FileMetadataResponse = await API.get(appApi, url, {
      queryStringParameters: {
        channelID,
        ownerType,
        ownerId,
        limit: 1000,
        nextToken,
      },
    });
    files.push(...response.data);
    if (response.nextToken) {
      return this.GetFilesForChannelId(channelID, response.nextToken, files);
    }
    return files;
  }

  /**
   * Upload file entities to a channel, this can be a folder, link, uploaded file
   * @param channelID which channel this file is for
   * @param files file items to create
   */
  static CreateFilesInChannel(
    channelID: string,
    files: Array<FileSaveModel>,
  ): Promise<CreateFilesResponse> {
    const filesList = files.map((file) => ({
      fields: {
        fileKey: file.key,
        starred: false,
        tags: [],
        path: file.path || '',
        fileUrl: file.url || '',
      },
    }));

    return API.post(appApi, url, {
      queryStringParameters: {
        channelID,
      },
      body: {
        data: filesList,
      },
    });
  }

  static deleteFiles(targetFiles: FileResponse[]) {
    return API.del(appApi, url, {
      body: {
        data: targetFiles,
      },
    });
  }

  static updateFiles(isMoveAction = false) {
    return (targetFiles: FileResponse[]) =>
      API.put(appApi, url, {
        body: {
          data: targetFiles,
        },
        ...(isMoveAction && {
          queryStringParameters: {
            isMoveAction: true,
          },
        }),
      });
  }

  static getZippedBundle(input: GetZippedBundleInput) {
    return API.put(appApi, `/files/download`, {
      body: {
        fileData: input.fileData,
        bundleName: input.bundleName,
      },
    });
  }

  static getDownloadProgress(key: string) {
    return API.get(appApi, `/files/download/progress`, {
      queryStringParameters: {
        key,
      },
    });
  }

  static createChannel(data: CreateChannelData) {
    return API.post(appApi, `/entities/FILE_CHANNEL`, {
      body: data,
    });
  }

  static deleteFileChannel(id: string) {
    return API.del('AppAPI', '/entities/FILE_CHANNEL', {
      body: [id],
    });
  }

  static updateFileChannel(fileChannel: FileChannel) {
    return API.put(appApi, `/entities/FILE_CHANNEL`, {
      body: {
        ...fileChannel,
      },
    });
  }
}
