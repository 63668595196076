import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ShapesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.3641 7.59805C11.4406 6.99154 12.687 6.75641 13.9105 6.92897C15.1341 7.10153 16.2668 7.67218 17.1336 8.55277C18.0005 9.43336 18.5532 10.5749 18.7065 11.801C18.8598 13.0271 18.6051 14.2696 17.9818 15.3365C17.3584 16.4034 16.4011 17.2354 15.2577 17.7039C14.1143 18.1724 12.8484 18.2513 11.6556 17.9285C10.4629 17.6058 9.40959 16.8992 8.65849 15.918C7.90738 14.9369 7.50025 13.7357 7.5 12.5M13.125 12.5H1.25L7.1875 1.875L13.125 12.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
