import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FitnessIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.7863 3.125C11.2504 3.125 10.0004 5.625 10.0004 5.625C10.0004 5.625 8.7504 3.125 6.21446 3.125C4.15352 3.125 2.52149 4.84922 2.5004 6.90664C2.45743 11.1773 5.88829 14.2145 9.64884 16.7668C9.75251 16.8373 9.87501 16.875 10.0004 16.875C10.1258 16.875 10.2483 16.8373 10.352 16.7668C14.1121 14.2145 17.543 11.1773 17.5004 6.90664C17.4793 4.84922 15.8473 3.125 13.7863 3.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 10H6.25L8.125 6.25L10 12.5L11.875 8.75L13.125 11.25H18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
