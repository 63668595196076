import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PricetagsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.7535 1.25H10.9516C10.8023 1.24989 10.659 1.30888 10.5531 1.41406L0.953127 11.0117C0.742976 11.2229 0.625 11.5087 0.625 11.8066C0.625 12.1046 0.742976 12.3904 0.953127 12.6016L5.52344 17.1719C5.73469 17.3821 6.02056 17.5 6.31856 17.5C6.61655 17.5 6.90242 17.3821 7.11367 17.1719L16.7109 7.57813C16.8161 7.47221 16.8751 7.32896 16.875 7.17969V2.375C16.8757 2.22736 16.8472 2.08103 16.7912 1.94444C16.7351 1.80786 16.6526 1.68371 16.5484 1.57914C16.4441 1.47458 16.3202 1.39167 16.1838 1.33518C16.0474 1.27869 15.9012 1.24974 15.7535 1.25Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 5.625C13.5028 5.625 13.2611 5.55169 13.0555 5.41434C12.85 5.27699 12.6898 5.08176 12.5952 4.85336C12.5005 4.62495 12.4758 4.37361 12.524 4.13114C12.5723 3.88866 12.6913 3.66593 12.8661 3.49112C13.0409 3.3163 13.2637 3.19725 13.5061 3.14902C13.7486 3.10079 13.9999 3.12554 14.2284 3.22015C14.4568 3.31476 14.652 3.47498 14.7893 3.68054C14.9267 3.8861 15 4.12777 15 4.375C15 4.70652 14.8683 5.02446 14.6339 5.25888C14.3995 5.4933 14.0815 5.625 13.75 5.625Z"
      fill="currentColor"
    />
    <path
      d="M8.98438 18.75L19.2188 8.51562C19.2696 8.4643 19.3097 8.40328 19.3365 8.33617C19.3634 8.26907 19.3765 8.19726 19.375 8.125V3.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
