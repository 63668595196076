import { SignaturePageImageStatus } from 'src/store/signaturePage/types';
import * as ErrCodes from 'src/constants/errorConsts/errorCodesConsts';

export const GetErrorCodeMessage = (errCode: string) => {
  switch (errCode) {
    case ErrCodes.CommonErrCode.invalidParameter:
      return ErrCodes.CommonErrMessages.invalidParameter;
    case ErrCodes.DomainSettingsAPiStatus.invalidStatus:
      return ErrCodes.DomainSettingsAPiErrors.invalidStatusError;
    case SignaturePageImageStatus.corruptFile:
      return ErrCodes.SignaturePageImageErrors.corruptFileError;
    case SignaturePageImageStatus.fileNotFound:
      return ErrCodes.SignaturePageImageErrors.fileNotFoundError;
    case SignaturePageImageStatus.setupFailed:
      return ErrCodes.SignaturePageImageErrors.fileSetupError;
    case ErrCodes.FormValidationErrorCode.missingName:
      return ErrCodes.FormValidationErrorMessage.missingName;
    case ErrCodes.FormValidationErrorCode.missingQuestion:
      return ErrCodes.FormValidationErrorMessage.missingQuestion;
    case ErrCodes.PaymentErrorCode.cardDeclined:
      return ErrCodes.PaymentErrorMessage.cardDeclined;
    case ErrCodes.UserErrorCode.usernameExists:
    case ErrCodes.UserErrorCode.accountExists:
      return ErrCodes.UserErrorMessage.emailInUse;
    case ErrCodes.UserErrorCode.passwordResetError:
      return ErrCodes.UserErrorMessage.passwordResetErr;
    case ErrCodes.DashboardErrCode.invalidEmbedLink:
      return ErrCodes.DashboardErrorMessages.invalidEmbedLink;
    case ErrCodes.BillingErrorCode.noBankConnected:
      return ErrCodes.BillingErrorMessage.noBankConnected;
    case ErrCodes.BillingErrorCode.noValidBankConnected:
      return ErrCodes.BillingErrorMessage.noValidBankConnected;
    case ErrCodes.BillingErrorCode.noPaymentMethod:
      return ErrCodes.BillingErrorMessage.noPaymentMethod;
    case ErrCodes.BillingErrorCode.cardDeclined:
      return ErrCodes.BillingErrorMessage.cardDeclined;
    case ErrCodes.BillingErrorCode.bankAccountExists:
      return ErrCodes.BillingErrorMessage.bankAccountExists;
    case ErrCodes.BillingErrorCode.subBlockPaymentDelete:
      return ErrCodes.BillingErrorMessage.subBlockPaymentDelete;
    case ErrCodes.BillingErrorCode.amountTooSmall:
      return ErrCodes.BillingErrorMessage.amountTooSmall;
    case ErrCodes.ModuleSettingsErrorCode.invalidModuleOrdering:
      return ErrCodes.ModuleSettingsMessage.invalidModuleOrdering;
    case ErrCodes.ContractErrorCode.clientInputRequired:
      return ErrCodes.ContractErrorMessage.clientInputRequired;
    case ErrCodes.ContractErrorCode.pdfPagesLimitError:
      return ErrCodes.ContractErrorMessage.pdfPagesLimitError;
    case ErrCodes.BillingErrorCode.genericDeclineError:
      return ErrCodes.BillingErrorMessage.genericDecline;
    default:
      return '';
  }
};
