import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BarbellIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.875 10H18.125"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 5.625C16.25 5.27982 15.9702 5 15.625 5C15.2798 5 15 5.27982 15 5.625V14.375C15 14.7202 15.2798 15 15.625 15C15.9702 15 16.25 14.7202 16.25 14.375V5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5.625C5 5.27982 4.72018 5 4.375 5C4.02982 5 3.75 5.27982 3.75 5.625V14.375C3.75 14.7202 4.02982 15 4.375 15C4.72018 15 5 14.7202 5 14.375V5.625Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 7.8125C1.875 7.63991 1.73509 7.5 1.5625 7.5C1.38991 7.5 1.25 7.63991 1.25 7.8125V12.1875C1.25 12.3601 1.38991 12.5 1.5625 12.5C1.73509 12.5 1.875 12.3601 1.875 12.1875V7.8125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 7.8125C18.75 7.63991 18.6101 7.5 18.4375 7.5C18.2649 7.5 18.125 7.63991 18.125 7.8125V12.1875C18.125 12.3601 18.2649 12.5 18.4375 12.5C18.6101 12.5 18.75 12.3601 18.75 12.1875V7.8125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
