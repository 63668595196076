import React from 'react';

export const TableWrapper = ({
  children,
  className = '',
  height = '100%',
  noTopMargin = false,
}: {
  children: React.ReactNode;
  className?: string;
  height?: string | number;
  noTopMargin?: boolean;
}) => {
  return (
    <div
      className={`${noTopMargin ? '' : 'mt-3'} ${className && className}`}
      style={{
        height,
      }}
    >
      {children}
    </div>
  );
};
