import React from 'react';
import { create } from 'jss';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { StylesProvider, jssPreset } from '@material-ui/core';

export const AppProviders = ({
  children,
  insertCss,
}: {
  children: React.ReactNode;
  insertCss: (...args: any[]) => void;
}) => {
  const styleContextValue = React.useMemo(() => ({ insertCss }), [insertCss]);
  const jss = React.useMemo(() => {
    const jssCreateOptions = {
      ...jssPreset(),
    };
    if (typeof document !== 'undefined') {
      const insertionPoint = document.getElementById('jss-insertion-point');
      if (insertionPoint) {
        jssCreateOptions.insertionPoint = insertionPoint;
      }
    }

    return create(jssCreateOptions);
  }, []);
  return (
    <StyleContext.Provider value={styleContextValue}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </StyleContext.Provider>
  );
};
