import { ApiMethods, ApiTags, appAPI } from './index';

export interface GetBlurbResponse {
  text: string;
}

export const welcomeBlurbApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getWelcomeBlurb: build.query<GetBlurbResponse, void>({
      query: () => ({
        path: `/blurb`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.welcomeBlurb],
    }),
  }),
});

export const { useGetWelcomeBlurbQuery } = welcomeBlurbApi;
