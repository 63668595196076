import { createContext } from 'react';

export interface ContractFile {
  fileKey: string;
  pageFileKeys: string[];
  identityId: string;
}

export interface ContractBuilderContext {
  placeholderElement: HTMLButtonElement | null;
  contractPdfInfo: ContractFile;
  isOneOffContract: boolean;
  pageScalerFactor: number;
  setPlaceholderElement: (element: HTMLButtonElement | null) => void;
  setContractPdfInfo: (info: ContractFile) => void;
  setIsOneOffContract: (value: boolean) => void;
  setPageScalerFactor: (value: number) => void;
}

const initialContextValue: ContractBuilderContext = {
  placeholderElement: null,
  contractPdfInfo: {
    fileKey: '',
    pageFileKeys: [],
    identityId: '',
  },
  pageScalerFactor: 1,
  isOneOffContract: false,
  setPlaceholderElement: () => null,
  setContractPdfInfo: () => null,
  setIsOneOffContract: () => null,
  setPageScalerFactor: () => null,
};

export const ContractBuilderContext = createContext(initialContextValue);
