import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortEndHandler,
} from 'react-sortable-hoc';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    sortableElement: {
      // set high zIndex to resolve disappearing item while sorting
      // reference: https://github.com/clauderic/react-sortable-hoc#item-disappearing-when-sorting--css-issues
      zIndex: theme.zIndex.modal + 1,
    },
  }),
);
const fixedItemsKeys = ['no-option-item', 'add-new-option-item'];
const SortableItem = SortableElement(
  ({ children }: SortableElementProps & React.PropsWithChildren<any>) => {
    const classes = useStyles();
    return <Box className={classes.sortableElement}>{children}</Box>;
  },
);

const SortableList = SortableContainer(
  ({ children }: SortableContainerProps & React.PropsWithChildren<any>) => (
    <Box>{children}</Box>
  ),
);
interface SortableMultiSelectListProps {
  onSortTagsOptions: SortEndHandler;
}
export const SortableMultiSelectList = React.forwardRef<
  HTMLDivElement,
  SortableMultiSelectListProps
>(({ children, onSortTagsOptions, ...others }, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} {...others} className={classes.root}>
      <SortableList distance={1} onSortEnd={onSortTagsOptions} lockAxis="y">
        {React.Children.toArray(children).map((child: any, index: number) =>
          !fixedItemsKeys.includes(child.props.children.key as string) ? (
            <SortableItem
              key={child.props.children.key as string}
              index={index}
            >
              {child}
            </SortableItem>
          ) : (
            <Box key={child.props.children.key as string}>{child}</Box>
          ),
        )}
      </SortableList>
    </div>
  );
});
