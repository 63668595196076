import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10 0C4.48606 0 0 4.48606 0 10C0 15.5139 4.48606 20 10 20C15.5139 20 20 15.5139 20 10C20 4.48606 15.5139 0 10 0ZM10 15.3803C9.80983 15.3803 9.62392 15.3239 9.4658 15.2182C9.30767 15.1126 9.18443 14.9624 9.11165 14.7867C9.03888 14.611 9.01984 14.4177 9.05694 14.2312C9.09404 14.0446 9.18562 13.8733 9.32009 13.7388C9.45456 13.6044 9.62589 13.5128 9.81241 13.4757C9.99893 13.4386 10.1923 13.4576 10.368 13.5304C10.5437 13.6032 10.6938 13.7264 10.7995 13.8845C10.9051 14.0427 10.9615 14.2286 10.9615 14.4188C10.9615 14.6738 10.8602 14.9183 10.6799 15.0987C10.4996 15.279 10.255 15.3803 10 15.3803ZM11.0442 5.70962L10.7683 11.575C10.7683 11.779 10.6872 11.9747 10.543 12.1189C10.3987 12.2632 10.2031 12.3442 9.99904 12.3442C9.79503 12.3442 9.59937 12.2632 9.45511 12.1189C9.31085 11.9747 9.22981 11.779 9.22981 11.575L8.95385 5.7125V5.7101C8.9478 5.5692 8.97033 5.42853 9.02008 5.29657C9.06983 5.1646 9.14577 5.04407 9.24333 4.94223C9.3409 4.84039 9.45806 4.75935 9.58777 4.70399C9.71747 4.64862 9.85705 4.62008 9.99808 4.62008C10.1391 4.62008 10.2787 4.64862 10.4084 4.70399C10.5381 4.75935 10.6553 4.84039 10.7528 4.94223C10.8504 5.04407 10.9263 5.1646 10.9761 5.29657C11.0258 5.42853 11.0484 5.5692 11.0423 5.7101L11.0442 5.70962Z"
      fill="currentColor"
    />
  </SvgIcon>
);
