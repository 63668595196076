import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MedkitIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 4.375H3.125C2.08947 4.375 1.25 5.21447 1.25 6.25V16.25C1.25 17.2855 2.08947 18.125 3.125 18.125H16.875C17.9105 18.125 18.75 17.2855 18.75 16.25V6.25C18.75 5.21447 17.9105 4.375 16.875 4.375Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 4.375V3.125C5.625 2.79348 5.7567 2.47554 5.99112 2.24112C6.22554 2.0067 6.54348 1.875 6.875 1.875H13.125C13.4565 1.875 13.7745 2.0067 14.0089 2.24112C14.2433 2.47554 14.375 2.79348 14.375 3.125V4.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8.125V14.375"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 11.25H6.875"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
