import { User } from 'src/constants/dataTypes';

export interface ResourceField {
  question: string;
  tags: string[];
  content: string;
}

export interface Resource {
  id: string;
  ownerId: string;
  updatedDate?: string;
  createdDate?: string;
  fields: ResourceField;
  owner: User | {};
}

export interface DataState {
  resources: Resource[];
  tags: string[];
  isLoading: boolean;
  isLoaded: boolean;
  isCreating: boolean;
  error: string;
}

export const LOAD_RESOURCES_REQUEST = 'LOAD_RESOURCES_REQUEST';
export const LOAD_RESOURCES_DONE = 'LOAD_RESOURCES_DONE';
export const LOAD_RESOURCES_FAIL = 'LOAD_RESOURCES_FAIL';
export const ADD_RESOURCES_REQUEST = 'ADD_RESOURCES_REQUEST';
export const ADD_RESOURCES_DONE = 'ADD_RESOURCES_DONE';
export const ADD_RESOURCES_FAIL = 'ADD_RESOURCES_FAIL';

export const UPDATE_RESOURCES_REQUEST = 'UPDATE_RESOURCES_REQUEST';
export const UPDATE_RESOURCES_DONE = 'UPDATE_RESOURCES_DONE';
export const UPDATE_RESOURCES_FAIL = 'UPDATE_RESOURCES_FAIL';

export const DELETE_RESOURCES_REQUEST = 'DELETE_RESOURCES_REQUEST';
export const DELETE_RESOURCES_DONE = 'DELETE_RESOURCES_DONE';
export const DELETE_RESOURCES_FAIL = 'DELETE_RESOURCES_FAIL';

export const ADD_TAGS = 'ADD_TAGS';

interface StartLoadResourcesAction {
  type: typeof LOAD_RESOURCES_REQUEST;
}

export interface LoadResourcesDoneAction {
  type: typeof LOAD_RESOURCES_DONE;
  resources: Resource[];
  users: User[];
}

interface LoadResourcesFailAction {
  type: typeof LOAD_RESOURCES_FAIL;
  error: string;
}

interface StartAddResourcesAction {
  type: typeof ADD_RESOURCES_REQUEST;
  error: string;
}

interface AddResourcesFailAction {
  type: typeof ADD_RESOURCES_FAIL;
  error: string;
}

interface AddResourcesDoneAction {
  type: typeof ADD_RESOURCES_DONE;
  payload: Resource[];
}

interface StartUpdateResourcesAction {
  type: typeof UPDATE_RESOURCES_REQUEST;
}

interface UpdateResourcesFailAction {
  type: typeof UPDATE_RESOURCES_FAIL;
  error: string;
}

interface UpdateResourcesDoneAction {
  type: typeof UPDATE_RESOURCES_DONE;
  payload: Resource;
}

interface StartDeleteResourcesAction {
  type: typeof DELETE_RESOURCES_REQUEST;
}

interface DeleteResourcesFailAction {
  type: typeof DELETE_RESOURCES_FAIL;
  error: string;
}

interface DeleteResourcesDoneAction {
  type: typeof DELETE_RESOURCES_DONE;
  payload: string;
}

export interface AddTagsAction {
  type: typeof ADD_TAGS;
  tags: string[];
}

export type DataActionTypes =
  | StartLoadResourcesAction
  | LoadResourcesDoneAction
  | LoadResourcesFailAction
  | StartAddResourcesAction
  | AddResourcesFailAction
  | AddResourcesDoneAction
  | StartUpdateResourcesAction
  | UpdateResourcesFailAction
  | UpdateResourcesDoneAction
  | StartDeleteResourcesAction
  | DeleteResourcesDoneAction
  | DeleteResourcesFailAction
  | AddTagsAction;
