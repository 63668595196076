import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ConstructIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.0578 7.19175C16.859 7.3893 16.59 7.50018 16.3098 7.50018C16.0295 7.50018 15.7605 7.3893 15.5617 7.19175L14.6836 6.31323C14.486 6.11451 14.375 5.84564 14.375 5.56538C14.375 5.28512 14.486 5.01625 14.6836 4.81753L16.6715 2.82964C16.6753 2.82582 16.6781 2.82112 16.6797 2.81595C16.6813 2.81079 16.6816 2.80531 16.6806 2.8C16.6796 2.79469 16.6773 2.7897 16.674 2.78547C16.6706 2.78124 16.6663 2.7779 16.6613 2.77573C15.3785 2.22651 13.7148 2.50581 12.6586 3.55464C11.6476 4.55815 11.5902 6.06518 11.9605 7.38276C12.0132 7.56992 12.013 7.76798 11.9602 7.95507C11.9073 8.14216 11.8037 8.31099 11.6609 8.44292L4.90311 14.7226C4.74182 14.867 4.61167 15.0428 4.52063 15.2393C4.4296 15.4357 4.37958 15.6487 4.37365 15.8651C4.36771 16.0815 4.40598 16.2969 4.48611 16.498C4.56624 16.6992 4.68655 16.8818 4.83968 17.0349C4.99282 17.188 5.17556 17.3082 5.37674 17.3882C5.57791 17.4682 5.7933 17.5064 6.00972 17.5003C6.22615 17.4943 6.43907 17.4441 6.63546 17.353C6.83185 17.2619 7.00759 17.1316 7.15194 16.9703L13.4859 10.2007C13.6164 10.0603 13.7827 9.95807 13.967 9.90508C14.1512 9.85208 14.3464 9.85031 14.5316 9.89995C15.8387 10.2492 17.3242 10.1835 18.3234 9.19917C19.3973 8.14136 19.6289 6.27182 19.1086 5.2019C19.1062 5.19721 19.1028 5.19314 19.0985 5.19003C19.0943 5.18693 19.0894 5.18487 19.0842 5.18403C19.079 5.18319 19.0737 5.1836 19.0687 5.18521C19.0637 5.18682 19.0592 5.1896 19.0555 5.19331L17.0578 7.19175Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.75038 11.0935C8.06757 10.4295 7.75467 10.1205 7.53944 9.91034C7.43173 9.80333 7.36101 9.66471 7.33761 9.51469C7.31421 9.36467 7.33935 9.21109 7.40936 9.07636C7.44627 9.01011 7.49229 8.94936 7.54608 8.89589L8.15858 8.29863C8.22196 8.23513 8.29652 8.18388 8.3785 8.14745C8.50793 8.09017 8.65156 8.07302 8.79084 8.09823C8.93011 8.12343 9.05862 8.18984 9.15975 8.28886C9.37264 8.4955 9.76249 8.8748 10.4648 9.55917"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3857 11.3789C13.9854 12.8672 15.9256 14.6316 16.6826 15.2621C16.7392 15.3088 16.7855 15.3668 16.8183 15.4324C16.8512 15.498 16.8699 15.5697 16.8734 15.643C16.8768 15.7163 16.8649 15.7895 16.8384 15.8579C16.8119 15.9263 16.7713 15.9884 16.7193 16.0402L15.4154 17.3438C15.3624 17.3956 15.2993 17.4361 15.2301 17.4628C15.1608 17.4895 15.0869 17.5019 15.0128 17.4991C14.9386 17.4963 14.8658 17.4785 14.7988 17.4467C14.7318 17.4149 14.6719 17.3697 14.6229 17.3141C13.9771 16.5648 12.2365 14.6926 10.7451 13.1344"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.677381 7.5586L1.82621 6.43594C1.84376 6.41869 1.86459 6.40513 1.88747 6.39607C1.91035 6.38702 1.93481 6.38264 1.95941 6.38321C2.009 6.38267 2.05683 6.4016 2.09262 6.43594V6.43594C2.13422 6.47709 2.18465 6.50823 2.24008 6.52698C2.29552 6.54572 2.3545 6.5516 2.41254 6.54414C2.56215 6.52774 2.72191 6.48164 2.82543 6.38321C3.0598 6.15664 2.78871 5.71016 2.99496 5.42461C3.2308 5.11202 3.48903 4.81697 3.76762 4.5418C4.00199 4.31211 4.93324 3.43555 6.49808 2.80547C6.99852 2.60319 7.53331 2.49947 8.07308 2.5C8.95551 2.5 9.63558 2.89063 9.88012 3.11211C10.0269 3.25176 10.1612 3.40389 10.2817 3.5668C10.1641 3.52345 10.0439 3.48732 9.92191 3.4586C9.66529 3.40416 9.40209 3.38758 9.14066 3.40938C8.61996 3.45196 7.99183 3.69297 7.65629 3.95625C7.11332 4.38594 6.88012 4.96094 6.8434 5.70274C6.81683 6.2543 6.94965 6.56602 8.25355 7.87031C8.29812 7.92006 8.32164 7.98516 8.31916 8.0519C8.31668 8.11864 8.2884 8.18182 8.24027 8.22813L7.52855 8.93125C7.47894 8.9799 7.41241 9.00744 7.34293 9.00809C7.27345 9.00875 7.20642 8.98247 7.1559 8.93477C6.29887 8.07774 5.72426 7.64219 5.39808 7.44414C5.07191 7.2461 4.80941 7.19024 4.68324 7.17656C4.43544 7.15201 4.18613 7.20482 3.96957 7.32774C3.93157 7.34861 3.89683 7.37493 3.86644 7.40586C3.81573 7.45828 3.77589 7.52023 3.74925 7.58814C3.7226 7.65604 3.70966 7.72854 3.71119 7.80147C3.71272 7.87439 3.72867 7.9463 3.75813 8.01302C3.7876 8.07975 3.82998 8.13998 3.88285 8.19024L3.94965 8.25274C3.96699 8.26959 3.98077 8.28975 3.99018 8.31203C3.9996 8.3343 4.00445 8.35824 4.00445 8.38242C4.00445 8.40661 3.9996 8.43054 3.99018 8.45282C3.98077 8.47509 3.96699 8.49526 3.94965 8.51211L2.80199 9.63281C2.78461 9.65042 2.76385 9.66433 2.74096 9.67373C2.71807 9.68313 2.69353 9.68781 2.66879 9.6875C2.6192 9.68799 2.57139 9.66907 2.53558 9.63477L0.677381 7.8211C0.64375 7.78567 0.625 7.73869 0.625 7.68985C0.625 7.641 0.64375 7.59402 0.677381 7.5586V7.5586Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
