import React, { useContext } from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import clsx from 'clsx';
import { PortalConfigContext, SidebarThemeContext } from 'src/context';
import { TruncatedText } from 'src/legacy/components/UI';
import {
  typography13MediumStyle,
  typography15MediumStyle,
} from 'src/legacy/components/Text';

export const LOGO_SIZE = 25;

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  portalName: {
    color: theme.palette.common.white,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  logo: {
    width: '100%',
  },
  logoContainer: {
    width: `${LOGO_SIZE}px`,
    height: `${LOGO_SIZE}px`,
    backgroundColor: 'transparent',
    marginRight: theme.spacing(1),
  },
  previewAvatar: {
    height: '19px',
    width: '19px',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  noCompanyLogoContainer: {
    width: `${LOGO_SIZE}px`,
    height: `${LOGO_SIZE}px`,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...typography15MediumStyle,
  },
}));
export interface LogoProps {
  isPreviewLogo?: boolean;
  iconImagePreviewUrl?: string;
  portalNamePreview?: string;
  portalNamePreviewColor?: string;
  portalLogo?: React.ReactNode;
  logoTextColor?: string; // prop to override the portal name text color
}

const Logo: React.FC<LogoProps> = ({
  isPreviewLogo,
  iconImagePreviewUrl,
  portalNamePreview,
  portalLogo = null,
  portalNamePreviewColor,
  logoTextColor = '',
}) => {
  const classes = useStyles();
  const portalConfig = useContext(PortalConfigContext);
  const sidebarTheme = useContext(SidebarThemeContext);
  const imageUrl = isPreviewLogo
    ? iconImagePreviewUrl
    : portalConfig.assets.logo.iconImageUrl;
  const portalName = isPreviewLogo ? portalNamePreview : portalConfig.name;

  // for customization page client preview use
  // preview color else use sidebar text color
  const portalNameColor = isPreviewLogo
    ? portalNamePreviewColor
    : sidebarTheme.sidebarTextColor;
  return (
    <div className={classes.container}>
      {portalLogo ||
        (imageUrl && (
          <Avatar
            variant="rounded"
            className={clsx(classes.logoContainer, {
              [classes.previewAvatar]: isPreviewLogo,
            })}
          >
            <img className={classes.logo} src={imageUrl} alt="Logo" />
          </Avatar>
        ))}
      <TruncatedText
        showTitle
        styleProps={{
          ...typography13MediumStyle,
          color: logoTextColor || portalNameColor,
          ...(isPreviewLogo ? { fontSize: '6px', lineHeight: '16px' } : {}),
        }}
        text={portalName || ''}
        maxChars={16.5}
      />
    </div>
  );
};

export default Logo;
