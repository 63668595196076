import React, { useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { shallowEqual } from 'react-redux';
import { Grid, Button, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Stripe from 'stripe';
import { PortalConfigContext } from 'src/context';
import UsersClient from 'src/clients/UsersClient';
import { ACCOUNT_TYPE } from 'src/constants';
import { BaseTextField } from 'src/legacy/components/TextField';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { GraySmall } from 'src/theme/colors';

import {
  AccountStatusData,
  VerificationStatus,
} from 'src/legacy/components/StripeConnectForm/VerificationStatus';
import { GetStripeAccountVerificationStatus } from 'src/utils/BillingUtils';
import { useAppSelector } from 'src/hooks/useStore';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import Autocomplete from 'src/components/Autocomplete';

interface CountryOptionType {
  code: string;
  label: string;
}

const expressCoOptions = [
  { label: 'Australia', code: 'AU' },
  { label: 'Canada', code: 'CA' },
  { label: 'United Kingdom', code: 'GB' },
  { label: 'United States', code: 'US' },
];

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    marginTop: theme.spacing(2),
  },
  connectButtonContainer: {
    marginTop: theme.spacing(4.5),
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const getDefaultCountryCode = () => {
  const localeString = navigator.language;
  let components = localeString.split('_');
  if (components.length === 2) {
    return components.pop();
  }
  components = localeString.split('-');
  if (components.length === 2) {
    return components.pop();
  }
  return localeString;
};

const schema = z.object({
  businessName: z.string(),
  accountType: z.enum([ACCOUNT_TYPE.EXPRESS]),
  country: z.string().max(2, 'Two-letter country code (e.g., US or CA).'),
});

type Schema = z.infer<typeof schema>;

export const StripeConnectForm: React.FC = () => {
  const classes = useStyles();
  const portalConfig = useContext(PortalConfigContext);
  const { loading, cognitoUser, paymentInfo } = useAppSelector(
    (state) => ({
      loading: state.settings.loading,
      cognitoUser: state.user.instance,
      paymentInfo: state.payments.paymentInfo,
    }),
    shallowEqual,
  );
  const backToPortalDestinationPath = useAppSelector(
    (state) => state.ui.backToPortalDestinationPath,
  );

  const {
    id,
    address: paymentAddress,
    businessName,
    accountId,
  } = paymentInfo || {};

  const { country } = paymentAddress || {
    country: getDefaultCountryCode()?.toUpperCase(),
  };

  const [accountStatus, setAccountStatus] = React.useState<AccountStatusData>({
    accountType: '',
    loginUrl: '',
    verificationStatus: '',
    accountName: '',
    currency: '',
    accountCountry: '',
    businessName: '',
  });

  const [isVerifying, setIsVerifying] = React.useState(true);

  const checkAccount = async (stripeAccountId: string) => {
    if (stripeAccountId === 'restricted') {
      // if accountID is restricted, then we don't need load account and check
      // any further status and directly set status to restricted
      setAccountStatus({
        accountType: '',
        loginUrl: '',
        verificationStatus: 'restricted',
        accountName: '',
        currency: '',
        accountCountry: '',
        businessName: '',
      });
      setIsVerifying(false);
      return;
    }

    setIsVerifying(true);
    const result = await UsersClient.getAccount(stripeAccountId);
    if (result && result.data) {
      let stripeLoginLink = '';
      const {
        payouts_enabled,
        type,
        requirements,
        external_accounts,
        default_currency,
        country: accountCountry,
        business_profile,
        charges_enabled,
      } = result.data;

      if (type === ACCOUNT_TYPE.EXPRESS) {
        try {
          const loginUrlResult = await UsersClient.getAccountLoginLink(
            stripeAccountId,
          );
          if (loginUrlResult && loginUrlResult.data) {
            stripeLoginLink = loginUrlResult.data.url;
          }
        } catch (e) {
          setIsVerifying(false);
        }
      }

      let accountName = 'Connected accounts';
      if (external_accounts && external_accounts.data.length > 0) {
        const [externalAccount] = external_accounts.data;
        if (externalAccount.object === 'bank_account') {
          accountName = externalAccount.bank_name || 'Bank';
        } else if (externalAccount.object === 'card') {
          accountName = externalAccount.brand;
        }
      }
      const verificationStatus = GetStripeAccountVerificationStatus(
        payouts_enabled,
        charges_enabled,
        requirements as Stripe.Account.Requirements,
      );

      if (verificationStatus === 'onboarding') {
        try {
          const onboardingLink = await UsersClient.getAccountOnboardingLink(
            stripeAccountId,
          );
          if (onboardingLink && onboardingLink.data) {
            stripeLoginLink = onboardingLink.data.url;
          }
        } catch (e) {
          console.error('Error getting onboarding link', e);
        }
      }

      setAccountStatus({
        accountType: type,
        loginUrl: stripeLoginLink,
        verificationStatus,
        accountName,
        currency: default_currency || '',
        accountCountry: accountCountry || '',
        businessName: business_profile?.name || '',
      });
    }
    setIsVerifying(false);
  };

  React.useEffect(() => {
    if (accountId && !loading) {
      checkAccount(accountId);
    } else {
      setAccountStatus({
        accountType: '',
        loginUrl: '',
        verificationStatus: '',
        accountName: '',
        currency: '',
        accountCountry: '',
        businessName: '',
      });
      setIsVerifying(false);
    }
  }, [accountId, loading]);

  const renderFormGrid = ({
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldValue,
  }: FormikProps<Schema>) => (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BaseTextField
              sizeVariant="medium"
              error={Boolean(touched.businessName && errors.businessName)}
              fullWidth
              helperText={touched.businessName && errors.businessName}
              label="Registered Company Name"
              name="businessName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.businessName}
              variant="outlined"
              placeholder="Infinity & Beyond LLC"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="country-code-select"
              onChange={(_, value) =>
                setFieldValue(
                  'country',
                  (value as CountryOptionType)?.code || '',
                )
              }
              value={
                expressCoOptions.find(
                  (option) => option.code === values.country,
                ) || null
              }
              options={expressCoOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <BaseTextField
                  {...params}
                  sizeVariant="medium"
                  error={Boolean(touched.country && errors.country)}
                  fullWidth
                  data-testid="country-select-id"
                  helperText={touched.country && errors.country}
                  label={
                    <>
                      <BaseTypography fontType="13Medium" component="span">
                        Country
                      </BaseTypography>
                      <BaseTypography
                        component="span"
                        style={{ color: GraySmall }}
                      >
                        {' '}
                        - The currency of your bank account will be used for
                        billing clients
                      </BaseTypography>
                    </>
                  }
                  onBlur={handleBlur}
                  variant="outlined"
                  placeholder="Select billing country"
                  inputProps={{
                    ...params.inputProps,
                    isautocomplete: 'true',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.connectButtonContainer}>
        <Button
          variant="contained"
          type="submit"
          disabled={loading}
          color="primary"
        >
          Connect bank
        </Button>
      </div>
    </>
  );

  const handleSubmit = async (values: Schema) => {
    if (!cognitoUser) {
      return;
    }

    const stripeOauthUrl = await UsersClient.GetStripeOauthUrl(
      {
        id,
        email: cognitoUser.attributes.email,
        account: accountId,
        ...values,
      },
      portalConfig,
      backToPortalDestinationPath,
    );
    window.location.assign(stripeOauthUrl.data);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        businessName: accountStatus.businessName || businessName || '',
        country: country || accountStatus.accountCountry || '',
        accountType: ACCOUNT_TYPE.EXPRESS,
      }}
      validationSchema={toFormikValidationSchema(schema)}
      onSubmit={handleSubmit}
    >
      {(formProps) =>
        loading || isVerifying ? (
          <Skeleton variant="text" />
        ) : (
          <form onSubmit={formProps.handleSubmit}>
            {!accountId ? (
              renderFormGrid(formProps)
            ) : (
              <VerificationStatus
                statusData={accountStatus}
                onConnectExpressClick={formProps.submitForm}
              />
            )}
          </form>
        )
      }
    </Formik>
  );
};
