import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const HourglassIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5.6877 18.125H14.3119C15.0854 18.125 15.7006 17.4887 15.6174 16.7555C15.0803 12.0312 11.8748 12.1094 11.8748 10C11.8748 7.89062 15.1213 8.00781 15.617 3.24453C15.6951 2.51094 15.0854 1.875 14.3119 1.875H5.6877C4.91426 1.875 4.30606 2.51094 4.38262 3.24453C4.87832 8.00781 8.12481 7.85156 8.12481 10C8.12481 12.1484 4.91934 12.0312 4.38262 16.7555C4.29903 17.4887 4.91426 18.125 5.6877 18.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4105 16.875H6.607C5.99763 16.875 5.82575 16.1719 6.2531 15.7359C7.28747 14.6875 9.37536 13.9367 9.37536 12.7344V8.75C9.37536 7.97461 7.89099 7.38281 6.97263 6.125C6.82107 5.91758 6.8363 5.625 7.22146 5.625H12.7968C13.1254 5.625 13.1965 5.91523 13.0468 6.12305C12.1418 7.38281 10.6254 7.9707 10.6254 8.75V12.7344C10.6254 13.927 12.8015 14.5703 13.766 15.7371C14.1547 16.2074 14.0187 16.875 13.4105 16.875Z"
      fill="currentColor"
    />
  </SvgIcon>
);
