import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CopyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16 16.625V7.375C16 6.06332 14.8607 5 13.4554 5H3.54464C2.13928 5 1 6.06332 1 7.375V16.625C1 17.9367 2.13928 19 3.54464 19H13.4554C14.8607 19 16 17.9367 16 16.625Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16 16H16.6667C17.285 15.9981 17.8774 15.7341 18.3146 15.2657C18.7518 14.7973 18.9982 14.1625 19 13.5V3.85714C18.9979 3.10007 18.7163 2.37464 18.2166 1.83931C17.717 1.30398 17.0399 1.00224 16.3333 1L7.33333 1C6.71504 1.00188 6.12257 1.26588 5.68536 1.73431C5.24816 2.20275 5.00176 2.83754 5 3.5V4.57143"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
