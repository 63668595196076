import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const RefreshIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.35286 1.98232C7.82286 2.09141 7.3065 2.25232 6.81832 2.45959C6.33468 2.66414 5.86741 2.91778 5.4265 3.21415C4.99104 3.50869 4.58468 3.84417 4.21832 4.21235C3.84923 4.57963 3.51286 4.98781 3.21741 5.42326C2.92104 5.86235 2.66832 6.33053 2.46377 6.81235C2.25559 7.30144 2.09559 7.81781 1.98559 8.34963C1.87377 8.89508 1.82013 9.45781 1.82195 10.0205H0.00195313L2.72923 13.6342L5.4565 10.0205H3.64013C3.63832 9.58053 3.68014 9.14053 3.76741 8.71599C3.85286 8.30326 3.97741 7.90144 4.13923 7.52144C4.29741 7.14599 4.49559 6.78235 4.72468 6.44144C4.95377 6.10235 5.21559 5.78508 5.50377 5.49781C5.78923 5.21145 6.1065 4.94872 6.44468 4.72145C6.7865 4.49054 7.15013 4.29326 7.52741 4.13417C7.9065 3.97236 8.30832 3.84871 8.71922 3.76326C9.1365 3.6778 9.56832 3.63415 10.002 3.63415C10.4356 3.63415 10.8674 3.6778 11.2838 3.76326C11.6965 3.84871 12.0983 3.97236 12.4792 4.13417C12.8547 4.29326 13.2183 4.49054 13.5592 4.71963C13.8983 4.94963 14.2156 5.21235 14.5038 5.49963L15.7865 4.21326C15.4183 3.84508 15.011 3.5096 14.5756 3.21324C14.1365 2.91778 13.6683 2.66414 13.1865 2.45959C12.6956 2.25141 12.1792 2.09141 11.6483 1.98232C11.112 1.87232 10.5574 1.81596 10.002 1.81596C9.4465 1.81596 8.89195 1.87232 8.35286 1.98232Z"
      fill="currentColor"
    />
    <path
      d="M11.652 18.0177C12.182 17.9086 12.6983 17.7477 13.1865 17.5404C13.6701 17.3359 14.1374 17.0823 14.5783 16.7859C15.0138 16.4914 15.4201 16.1559 15.7865 15.7877C16.1556 15.4204 16.492 15.0123 16.7874 14.5768C17.0838 14.1377 17.3365 13.6695 17.541 13.1877C17.7492 12.6986 17.9092 12.1823 18.0192 11.6504C18.131 11.105 18.1847 10.5423 18.1829 9.97954H20.0029L17.2756 6.3659L14.5483 9.97954H16.3647C16.3665 10.4195 16.3247 10.8595 16.2374 11.2841C16.152 11.6968 16.0274 12.0986 15.8656 12.4786C15.7074 12.8541 15.5092 13.2177 15.2801 13.5586C15.051 13.8977 14.7892 14.215 14.501 14.5023C14.2156 14.7886 13.8983 15.0514 13.5601 15.2786C13.2183 15.5095 12.8547 15.7068 12.4774 15.8659C12.0983 16.0277 11.6965 16.1514 11.2856 16.2368C10.8683 16.3223 10.4365 16.3659 10.0029 16.3659C9.56923 16.3659 9.13741 16.3223 8.72104 16.2368C8.30832 16.1514 7.9065 16.0277 7.52559 15.8659C7.15013 15.7068 6.7865 15.5095 6.44559 15.2804C6.1065 15.0504 5.78923 14.7877 5.50104 14.5004L4.21832 15.7868C4.5865 16.155 4.99377 16.4904 5.42923 16.7868C5.86832 17.0823 6.3365 17.3359 6.81832 17.5404C7.30923 17.7486 7.82559 17.9086 8.3565 18.0177C8.89286 18.1277 9.44741 18.1841 10.0029 18.1841C10.5583 18.1841 11.1129 18.1277 11.652 18.0177Z"
      fill="currentColor"
    />
  </SvgIcon>
);
