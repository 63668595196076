import React from 'react';
import clsx from 'clsx';
import {
  SwipeableDrawer,
  Drawer,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { toggleSecondarySidebar } from 'src/store/ui/actions';
import { SecondarySideBarType } from 'src/store/ui/types';
import {
  DESKTOP_APP_BAR_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
  BANNER_HEIGHT,
} from 'src/constants/uiConsts';

export const drawerWidth = 368;
export const itemHeight = 55;
const DRAWER_CONTAINER_WIDTH = drawerWidth + 72;

interface SpacingProps {
  showBanner: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileDrawerRoot: {
      // otherwise overriding will not work
      zIndex: 2,
      top: '64px !important',
      '& > .MuiBackdrop-root': {
        top: 64,
      },
    },
    drawerMobilePaper: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 0.25, 1, 2),
        maxHeight: `calc(100% - ${MOBILE_APP_BAR_HEIGHT}px)`,
        top: (props: SpacingProps) =>
          MOBILE_APP_BAR_HEIGHT + (props.showBanner ? BANNER_HEIGHT : 0),
      },
      [theme.breakpoints.up('sm')]: {
        top: (props: SpacingProps) =>
          DESKTOP_APP_BAR_HEIGHT + (props.showBanner ? BANNER_HEIGHT : 0),
      },
      boxShadow: 'none',
      right: 0,
      '& .portal-chat': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          height: 'calc(100% - 118px)',
          '& >div': {
            width: '100%',
          },
        },
        '& .str-chat__channel-list-messenger__main': {
          [theme.breakpoints.down('xs')]: {
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
          padding: 0,
        },
      },
    },
    desktopDrawerRoot: {
      // width should be same when noSpace prop is true
      // to maintain the vertical border alignment
      width: DRAWER_CONTAINER_WIDTH,
      flexShrink: 0,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    drawerDesktopPaper: {
      border: 'none',
      position: 'relative',
      width: DRAWER_CONTAINER_WIDTH,
      height: '-webkit-fill-available',
      overflow: 'hidden',
    },
    noSidebar: {
      display: 'none',
    },
  }),
);

interface SecondarySidebarProps {
  hidden?: boolean;
}

export const SecondarySidebar: React.FC<SecondarySidebarProps> = ({
  children,
  hidden = false,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down('xs'));
  const swipeableDrawerState = useSelector(
    (state: RootState) => state.ui.secondarySideBar,
  );
  const bannerOptions = useSelector(
    (state: RootState) => state.ui.bannerOptions,
  );

  const classes = useStyles({
    showBanner: Boolean(bannerOptions),
  });

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      const secondarySidebarParams: SecondarySideBarType = {
        isOpen: open,
      };
      dispatch(toggleSecondarySidebar(secondarySidebarParams));
    };

  return (
    <>
      {/* Mobile secondary sidebar */}
      {isSmallWidth && (
        <SwipeableDrawer
          variant="persistent"
          anchor="left"
          open={Boolean(swipeableDrawerState.isOpen)}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          classes={{
            root: clsx(classes.mobileDrawerRoot, {
              [classes.noSidebar]: hidden,
            }),
            paper: classes.drawerMobilePaper,
          }}
        >
          {children}
        </SwipeableDrawer>
      )}
      {/* Desktop secondary sidebar */}
      {!isSmallWidth && (
        <Drawer
          variant="permanent"
          classes={{
            root: clsx(classes.desktopDrawerRoot, {
              [classes.noSidebar]: hidden,
            }),
            paper: classes.drawerDesktopPaper,
          }}
        >
          {children}
        </Drawer>
      )}
    </>
  );
};
