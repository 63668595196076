export const DEFAULT_FILTER_RESULTS_LIMIT = 5;

export const COMPANY_TYPE = 'company';
export const CLIENT_TYPE = 'client';
export const TEAM_TYPE = 'team';

export const GROUPBY_INFO = [
  { type: COMPANY_TYPE, groupLabel: 'Client Companies' },
  { type: CLIENT_TYPE, groupLabel: 'Clients' },
];
export const GROUP_OPTION_MAPPER = {
  company: 'Recent companies',
  client: 'Recent clients',
  title: '',
  'no-option': '',
  text: '',
} as const;
export const isGroupOption = (
  key: string,
): key is keyof typeof GROUP_OPTION_MAPPER => key in GROUP_OPTION_MAPPER;
export type GroupType = 'company' | 'team' | 'client';
