import * as React from 'react';

interface RowDividerProps {
  children?: React.ReactNode;
  className?: string;
}

export const RowDivider = ({ children, className = '' }: RowDividerProps) => {
  // If no children, just render a simple divider line
  if (!children) {
    return <div className={`w-full h-px bg-gray-200 ${className}`} />;
  }

  // With children, render a divider with content in the middle
  return (
    <div className={`w-full flex items-center ${className}`}>
      <div className="flex-grow h-px bg-gray-200" />
      <div className="px-4">{children}</div>
      <div className="flex-grow h-px bg-gray-200" />
    </div>
  );
};
