import { InitialConnectAppFormValues } from 'src/legacy/components/ExtensionPage/ConnectManualApp';
import {
  Client as ClientType,
  Company as CompanyType,
  URLValidationRegex,
} from 'src/constants';
import { ExtensionItem, ExtensionItemFields } from 'src/store/dashboard/types';
import { EmbedErrors } from 'src/constants/errorConsts/errorCodesConsts';
import { z } from 'zod';

export enum EmbedChannelTypes {
  Client = 'client',
  Company = 'company',
}

export const applyQueryParams = (
  url: string | URL,
  queryParams: Record<string, string>[],
  currentClient?: ClientType,
  currentCompany?: CompanyType,
): string => {
  if (!queryParams?.length) {
    return url.toString();
  }

  try {
    const urlObj = new URL(url.toString());

    queryParams.forEach(({ key, value }) => {
      let resolvedValue: string = '';
      if (value.startsWith('client') && currentClient) {
        const [, clientKey] = value.split('.');
        if (clientKey === 'id') {
          if (currentClient?.[clientKey]) {
            resolvedValue = currentClient?.[clientKey];
          }
        } else if (
          clientKey === 'email' ||
          clientKey === 'givenName' ||
          clientKey === 'familyName'
        ) {
          if (currentClient?.fields?.[clientKey]) {
            resolvedValue = currentClient?.fields?.[clientKey];
          }
        } else if (z.string().uuid().safeParse(clientKey.trim()).success) {
          resolvedValue = currentClient?.fields?.customFields?.[clientKey];
        }
      }

      if (value.startsWith('company') && currentCompany) {
        const [, companyKey] = value.split('.');
        if (currentCompany && companyKey === 'id') {
          resolvedValue = currentCompany?.[companyKey] ?? '';
        } else if (companyKey === 'name') {
          resolvedValue = currentCompany?.fields?.[companyKey] ?? '';
        }
      }

      if (resolvedValue) {
        urlObj.searchParams.set(key, resolvedValue);
      }
    });

    return urlObj.toString();
  } catch (ex) {
    // If URL parsing fails, return the original URL
    return url.toString();
  }
};

const autoSizeElementTypes = ['div', 'iframe'];

export const createIFrame = (embedURL: string) =>
  `<iframe src="${embedURL}"></iframe>`;

interface CreateEmbedBodyInput {
  embedCode: string;
  autoSize: boolean;
  customAppParams?: {
    // query params to add to the embed url
    companyId: string;
    clientId?: string; // client id is optional for company channels when viewing as internal user
  };
  queryParams?: Record<string, string>[];
  currentClient?: ClientType;
  currentCompany?: CompanyType;
}

export const createEmbedBody = ({
  embedCode,
  autoSize,
  customAppParams,
  queryParams,
  currentClient,
  currentCompany,
}: CreateEmbedBodyInput) => {
  const urlRegex = new RegExp(URLValidationRegex);
  let embedSnippet = embedCode;
  if (embedCode.match(urlRegex)) {
    try {
      const url = new URL(embedCode);
      // when customAppParams is provided append the params to the url
      if (customAppParams) {
        Object.entries(customAppParams).forEach(([key, value]) => {
          url.searchParams.set(key, value ?? '');
        });
      }

      // get full url and create Iframe snippet
      embedSnippet = createIFrame(url.toString());
    } catch (ex) {
      return EmbedErrors.errorRenderExtension;
    }
  }

  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(embedSnippet, 'text/html');
  const iframe = htmlDoc.body.querySelector('iframe');
  let queryParamsUrl: InstanceType<typeof URL> | undefined;
  try {
    queryParamsUrl = new URL(iframe?.src ?? '');
  } catch (ex) {
    // nothing
  }

  if (queryParams && queryParamsUrl) {
    const updatedUrl = applyQueryParams(
      queryParamsUrl,
      queryParams,
      currentClient,
      currentCompany,
    );

    if (iframe) {
      iframe.setAttribute('src', updatedUrl);
    }
  }

  // override iframes that have `no` scrolling behavior
  // the explicit check for scrolling=no is recommended since this property
  // is deprecated and we dont want to set the value if it does not need to be
  Array.from(htmlDoc.body.children).forEach((elem) => {
    const elemTagName = elem.tagName.toLowerCase();
    if (elemTagName === 'iframe') {
      const iframeScrollingAttributeValue = elem.getAttribute('scrolling');
      if (iframeScrollingAttributeValue === 'no') {
        (elem as HTMLIFrameElement).scrolling = 'auto';
      }
    }
  });

  if (!autoSize) {
    return htmlDoc.body.innerHTML;
  }

  let resizeHeight = '100%';
  const resizeAbleElements = Array.from(htmlDoc.body.children).filter((elem) =>
    autoSizeElementTypes.includes(elem.tagName.toLowerCase()),
  );
  if (resizeAbleElements.length > 1) {
    // If there are more than one resize-able element, we'll set the height to
    // 50%, to show multiple elements on the page
    resizeHeight = '50%';
  }
  Array.from(htmlDoc.body.children).forEach((elem) => {
    const elemTagName = elem.tagName.toLowerCase();
    if (!autoSizeElementTypes.includes(elemTagName)) {
      return;
    }
    //
    if (elemTagName === 'div') {
      (elem as HTMLDivElement).style.height = resizeHeight;
      (elem as HTMLDivElement).style.width = '100%';
    }
    if (elemTagName === 'iframe') {
      (elem as HTMLIFrameElement).height = resizeHeight;
      (elem as HTMLIFrameElement).width = '100%';
    }
  });

  return htmlDoc.body.innerHTML;
};

export const DefaultChannelTypes = [
  {
    label: 'Individual client channels',
    value: EmbedChannelTypes.Client,
  },

  {
    label: 'Company channels',
    value: EmbedChannelTypes.Company,
  },
];

/**
 * Method to format the created channel using useChannelForm form
 * @param formData { membersType: is the selected channel of company or client, membersId: list of client ids}
 * @returns
 */
export const formatExtensionChannelMembers = (
  formData: InitialConnectAppFormValues,
) => {
  const extensionItemFields: Partial<ExtensionItemFields> = {};

  const clientMemberIDs: Record<string, boolean> = {};
  const { memberIDs, membersType } = formData.fields;
  if (!memberIDs) return extensionItemFields;

  if (membersType === 'company') {
    // a company
    extensionItemFields.companyID = memberIDs.at(0);
  } else {
    // non-company extension channels are built from a list of clientIDs of the same company
    memberIDs.forEach((clientID) => {
      clientMemberIDs[clientID] = true;
    });
  }
  extensionItemFields.memberIds = clientMemberIDs;
  return extensionItemFields;
};

/**
 * Method to check is the newly created channel already existed or not
 * @param dashboardItems list of all dashboard items i.e. app channels
 * @param item new selected channel that we are checking for
 * @returns
 */
export const findExistingDashboardItem = (
  dashboardItems: ExtensionItem[],
  item: Partial<ExtensionItem>,
) => {
  const dashboardMembersMap = item.fields?.memberIds || {};
  const newDashboardMemberIds = Object.keys(dashboardMembersMap);
  const existingChannel = dashboardItems.find((extensionChannelItem) => {
    // check extension configId matches
    const extensionConfigIdMatches =
      extensionChannelItem.fields.extensionConfigId ===
      item.fields?.extensionConfigId;
    const extItemCompanyID = extensionChannelItem.fields.companyID;
    if (extItemCompanyID) {
      // if the new extension channel has a companyID matches with an existing channel with companyID we return that existing channel otherwise skip
      return (
        extensionConfigIdMatches && extItemCompanyID === item.fields?.companyID
      );
    }
    // if dashboard extension matches item and channelMembers in current
    const existingDashboardMemberIds = Object.keys(
      extensionChannelItem.fields.memberIds || {},
    );
    return (
      extensionConfigIdMatches &&
      // check channel members in extension match item members
      existingDashboardMemberIds.length === newDashboardMemberIds.length &&
      existingDashboardMemberIds.every(
        (dashboardMemberId) => dashboardMembersMap[dashboardMemberId],
      )
    );
  });

  return existingChannel;
};
