import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PaperPlaneIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M2.07458 7.81003L17.6996 1.89635C17.756 1.87479 17.8174 1.87002 17.8764 1.8826C17.9354 1.89518 17.9895 1.92459 18.0322 1.96726C18.0749 2.00994 18.1043 2.06405 18.1169 2.12307C18.1294 2.1821 18.1247 2.2435 18.1031 2.29987L12.1894 17.9249C12.1659 17.9842 12.1248 18.0349 12.0716 18.0702C12.0184 18.1055 11.9556 18.1236 11.8918 18.1221C11.828 18.1207 11.7662 18.0997 11.7146 18.0621C11.6631 18.0245 11.6243 17.9719 11.6035 17.9116L8.97068 11.4237C8.93998 11.3318 8.88832 11.2482 8.81978 11.1797C8.75124 11.1111 8.6677 11.0595 8.57576 11.0288L2.08787 8.39831C2.02675 8.37797 1.9734 8.33925 1.93514 8.28743C1.89687 8.23562 1.87556 8.17324 1.8741 8.10884C1.87265 8.04445 1.89113 7.98117 1.92702 7.92768C1.96291 7.87419 2.01445 7.8331 2.07458 7.81003V7.81003Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9688 2.03125L8.86719 11.1328"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
