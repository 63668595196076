import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FilmIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.0312 3.75H2.96875C2.36469 3.75 1.875 4.23969 1.875 4.84375V15.1562C1.875 15.7603 2.36469 16.25 2.96875 16.25H17.0312C17.6353 16.25 18.125 15.7603 18.125 15.1562V4.84375C18.125 4.23969 17.6353 3.75 17.0312 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M17.0312 13.125H16.0938C15.4897 13.125 15 13.6147 15 14.2188V15.1562C15 15.7603 15.4897 16.25 16.0938 16.25H17.0312C17.6353 16.25 18.125 15.7603 18.125 15.1562V14.2188C18.125 13.6147 17.6353 13.125 17.0312 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M17.0312 10H16.0938C15.4897 10 15 10.4897 15 11.0938V12.0312C15 12.6353 15.4897 13.125 16.0938 13.125H17.0312C17.6353 13.125 18.125 12.6353 18.125 12.0312V11.0938C18.125 10.4897 17.6353 10 17.0312 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M17.0312 6.875H16.0938C15.4897 6.875 15 7.36469 15 7.96875V8.90625C15 9.51031 15.4897 10 16.0938 10H17.0312C17.6353 10 18.125 9.51031 18.125 8.90625V7.96875C18.125 7.36469 17.6353 6.875 17.0312 6.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M17.0312 3.75H16.0938C15.4897 3.75 15 4.23969 15 4.84375V5.78125C15 6.38531 15.4897 6.875 16.0938 6.875H17.0312C17.6353 6.875 18.125 6.38531 18.125 5.78125V4.84375C18.125 4.23969 17.6353 3.75 17.0312 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M3.90625 13.125H2.96875C2.36469 13.125 1.875 13.6147 1.875 14.2188V15.1562C1.875 15.7603 2.36469 16.25 2.96875 16.25H3.90625C4.51031 16.25 5 15.7603 5 15.1562V14.2188C5 13.6147 4.51031 13.125 3.90625 13.125Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M3.90625 10H2.96875C2.36469 10 1.875 10.4897 1.875 11.0938V12.0312C1.875 12.6353 2.36469 13.125 2.96875 13.125H3.90625C4.51031 13.125 5 12.6353 5 12.0312V11.0938C5 10.4897 4.51031 10 3.90625 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M3.90625 6.875H2.96875C2.36469 6.875 1.875 7.36469 1.875 7.96875V8.90625C1.875 9.51031 2.36469 10 2.96875 10H3.90625C4.51031 10 5 9.51031 5 8.90625V7.96875C5 7.36469 4.51031 6.875 3.90625 6.875Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M3.90625 3.75H2.96875C2.36469 3.75 1.875 4.23969 1.875 4.84375V5.78125C1.875 6.38531 2.36469 6.875 2.96875 6.875H3.90625C4.51031 6.875 5 6.38531 5 5.78125V4.84375C5 4.23969 4.51031 3.75 3.90625 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M13.9062 3.75H6.09375C5.48969 3.75 5 4.23969 5 4.84375V8.90625C5 9.51031 5.48969 10 6.09375 10H13.9062C14.5103 10 15 9.51031 15 8.90625V4.84375C15 4.23969 14.5103 3.75 13.9062 3.75Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M13.9062 10H6.09375C5.48969 10 5 10.4897 5 11.0938V15.1562C5 15.7603 5.48969 16.25 6.09375 16.25H13.9062C14.5103 16.25 15 15.7603 15 15.1562V11.0938C15 10.4897 14.5103 10 13.9062 10Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
